import { LAUNCH_FLYOUT_STATUS } from 'src/constants';
import { COEData } from 'src/threejs/models/COE';
import { GroundObject, LaunchWindow } from 'src/types';
import { Group } from 'three';
import { create } from 'zustand';
import { subscribeWithSelector } from 'zustand/middleware';
import { immer } from 'zustand/middleware/immer';

type GroundObjectEditProperties = {
  id: number;
  name: string;
  latitude: number;
  longitude: number;
  altitude: number;
  minElevationAngle: number;
  sensorRange: number;
  coneShaderOpacity: number;
  coneWireframeOpacity: number;
  coneShaderColor: string;
  coneWireframeColor: string;
  locationColor: string;

  // used by launch designer when computing a launch ground object
  orbitLaunchAltitude?: number;
  inclination?: COEData['inclination'];
  eccentricity?: COEData['eccentricity'];
  rightAscensionOfAscendingNode?: COEData['rightAscensionOfAscendingNode'];
};

export type GroundObjectEditType = Partial<GroundObject> & GroundObjectEditProperties;

interface GroundObjectStoreState {
  isLaunchEditMode: keyof typeof LAUNCH_FLYOUT_STATUS; // overrides active ground object from being edited by launch designer
  setLaunchEditMode: (newLaunchEditMode: keyof typeof LAUNCH_FLYOUT_STATUS) => void;

  // currently used for FOV cones to track the world position of the ground object items, since there are multiple
  // transformations being applied to the ground object items, this way of deriving location in world space
  // reduces the amount of redundancy in computing their lat/lon w/ transforms to world position coords
  groundObjectItemRefs: Record<number, Group | null>;
  setGroundObjectItemRefs: (newGroundObjectItemRef: Record<number, Group | null>) => void;

  launchEditActiveWindows: null | LaunchWindow[];
  setLaunchEditActiveWindows: (activeWindows: null | LaunchWindow[]) => void;
  activeLaunchWindow: null | LaunchWindow;
  setActiveLaunchWindow: (newLaunchWindow: null | LaunchWindow) => void;

  groundObjectEdit: GroundObjectEditType | null;
  setGroundObjectEdit: (newVal: GroundObjectEditType | null) => void;

  hasErrorLaunchLocationInvalid: boolean;
}

export const useGroundObjectLaunchEditStore = create<GroundObjectStoreState>()(
  subscribeWithSelector(
    immer((set, get) => ({
      groundObjectItemRefs: {},
      setGroundObjectItemRefs: (newGroundObjectItemRef: Record<number, Group | null>) => {
        set((state) => {
          state.groundObjectItemRefs = newGroundObjectItemRef;
        });
      },
      isLaunchEditMode: LAUNCH_FLYOUT_STATUS.CLOSED,
      setLaunchEditMode: (newLaunchEditMode: keyof typeof LAUNCH_FLYOUT_STATUS) => {
        set((state) => {
          state.isLaunchEditMode = newLaunchEditMode;
        });
      },
      launchEditActiveWindows: null,
      setLaunchEditActiveWindows: (activeWindows: null | LaunchWindow[]) => {
        set((state) => {
          state.launchEditActiveWindows = activeWindows;
        });
      },
      activeLaunchWindow: null,
      setActiveLaunchWindow: (newLaunchWindow: null | LaunchWindow) => {
        set((state) => {
          state.activeLaunchWindow = newLaunchWindow;
        });
      },

      groundObjectEdit: null,
      setGroundObjectEdit: (newVal: GroundObjectEditType | null) => {
        set((state) => {
          state.groundObjectEdit = newVal;

          // check for valid ground object location
          if (newVal && newVal.latitude !== undefined && newVal.inclination !== undefined) {
            if (newVal?.inclination < 90) {
              state.hasErrorLaunchLocationInvalid =
                Math.abs(newVal?.latitude) > newVal?.inclination;
            } else {
              state.hasErrorLaunchLocationInvalid =
                Math.abs(newVal?.latitude) > 180 - newVal?.inclination;
            }
          } else {
            state.hasErrorLaunchLocationInvalid = false;
          }
        });
      },

      hasErrorLaunchLocationInvalid: false,
    })),
  ),
);
