import { Svg } from '@react-three/drei';
import { useEffect, useState } from 'react';
import { scaleFactor } from 'src/constants';
import { useGroundObjectLaunchEditStore } from 'src/threejs/components/GroundObjects/GroundObjectLaunchEditStore';
import { Group, MeshBasicMaterial, Vector3 } from 'three';
import { degToRad } from 'three/src/math/MathUtils';

const SVG_PATH_Background = '<svg><circle cx="0" cy="0" r="0.075" /></svg>';

// path copied from https://github.com/mui/material-ui/blob/master/packages/mui-icons-material/lib/RocketLaunchRounded.js
const SVG_PATH_RocketLaunchRounded =
  '<svg><path d="M9.19 6.35c-2.04 2.29-3.44 5.58-3.57 5.89l-2.26-.97c-.65-.28-.81-1.13-.31-1.63l3.01-3.01c.47-.47 1.15-.68 1.81-.55l1.32.27zm1.49 10.16c.3.3.74.38 1.12.2 1.16-.54 3.65-1.81 5.26-3.42 4.59-4.59 4.63-8.33 4.36-9.93-.07-.4-.39-.72-.79-.79-1.6-.27-5.34-.23-9.93 4.36-1.61 1.61-2.87 4.1-3.42 5.26-.18.38-.09.83.2 1.12l3.2 3.2zm6.97-1.7c-2.29 2.04-5.58 3.44-5.89 3.57l.97 2.26c.28.65 1.13.81 1.63.31l3.01-3.01c.47-.47.68-1.15.55-1.81l-.27-1.32zm-8.71 2.6c.2 1.06-.15 2.04-.82 2.71-.77.77-3.16 1.34-4.71 1.64-.69.13-1.3-.48-1.17-1.17.3-1.55.86-3.94 1.64-4.71.67-.67 1.65-1.02 2.71-.82 1.17.22 2.13 1.18 2.35 2.35zM13 9c0-1.1.9-2 2-2s2 .9 2 2-.9 2-2 2-2-.9-2-2z" /></svg>';

export const LaunchPreviewGroundObject = () => {
  const [iconRef, setIconRef] = useState<Group | null>(null);

  const groundObjectLatitude = useGroundObjectLaunchEditStore(
    (state) => state.groundObjectEdit?.latitude,
  );
  const groundObjectLongitude = useGroundObjectLaunchEditStore(
    (state) => state.groundObjectEdit?.longitude,
  );

  const hasErrorLaunchLocationInvalid = useGroundObjectLaunchEditStore(
    (state) => state.hasErrorLaunchLocationInvalid,
  );

  useEffect(() => {
    if (iconRef && groundObjectLatitude !== undefined && groundObjectLongitude !== undefined) {
      iconRef.position.setFromSphericalCoords(
        1.02,
        degToRad(groundObjectLatitude - 90),
        degToRad(groundObjectLongitude + 180),
      );

      const rotationX = (groundObjectLatitude / 90) * Math.PI * 0.5;
      const rotationY = (groundObjectLongitude / 360) * Math.PI * 2;

      iconRef.rotation.set(0, 0, 0);
      iconRef.rotateOnWorldAxis(new Vector3(1, 0, 0), -rotationX);
      iconRef.rotateOnWorldAxis(new Vector3(0, 1, 0), rotationY);
    }
  }, [groundObjectLatitude, groundObjectLongitude, iconRef]);

  return (
    <group
      ref={setIconRef}
      scale={0.5}
    >
      <group>
        <Svg
          src={SVG_PATH_Background}
          fillMaterial={
            new MeshBasicMaterial({
              color: hasErrorLaunchLocationInvalid ? 'red' : 'black',
            })
          }
        />

        <Svg
          src={SVG_PATH_RocketLaunchRounded}
          scale={24 * scaleFactor}
          position={[-0.045, 0.045, 0.005]}
          fillMaterial={
            new MeshBasicMaterial({
              color: 'white',
            })
          }
        />
      </group>
    </group>
  );
};
