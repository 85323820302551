// import './wdyr'; // import to enable whyDidYouRender
import { Auth0Provider } from '@auth0/auth0-react';
import { createRoot } from 'react-dom/client';
// import reportWebVitals from './reportWebVitals';
import CssBaseline from '@mui/material/CssBaseline';
import { RouterProvider } from 'react-router-dom';
import { router } from './pages/App/routes/Router';

const domain = process.env.REACT_APP_AUTH0_DOMAIN as string;
const clientId = process.env.REACT_APP_AUTH0_CLIENT_ID as string;
const audience = process.env.REACT_APP_AUTH0_AUDIENCE;

const container = document.getElementById('root');
const root = createRoot(container!);
root.render(
  <Auth0Provider
    domain={domain}
    clientId={clientId}
    authorizationParams={{
      redirect_uri: window.location.origin,
      audience: audience,
    }}
    onRedirectCallback={(appState) => {
      if (appState?.returnTo) {
        router.navigate(appState.returnTo, { replace: true });
      }
    }}
    // only set cacheLocation="localstorage" in development for easier testing
    // since it's potentially dangerous in production.
    cacheLocation={process.env.NODE_ENV === 'development' ? 'localstorage' : undefined}
  >
    <CssBaseline />
    <RouterProvider router={router}></RouterProvider>
  </Auth0Provider>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
