import { useCallback, useEffect, useRef } from 'react';

export function useDebouncedCallback<T extends (...args: any) => any>(
  callback: T,
  ms: number,
): [(...parameters: Parameters<T>) => void, () => void] {
  const timeoutRef = useRef<number | undefined>();
  const callbackRef = useRef<Function>(callback);
  useEffect(() => {
    callbackRef.current = callback;
  }, [callback]);
  const debouncedCallback = useCallback(
    (...args: any) => {
      clearTimeout(timeoutRef.current);
      timeoutRef.current = window.setTimeout(() => {
        callbackRef.current(...args);
      }, ms);
    },
    [ms],
  );
  function cancelTimeout() {
    clearTimeout(timeoutRef.current);
  }
  return [debouncedCallback, cancelTimeout];
}
