import { useEffect, useState } from 'react';
import { useIsPropagating } from 'src/hooks/OrbitHooks';
import useOnPageChange from 'src/hooks/useOnPageChange';
import PropagatedCacheManager, {
  DataRecievedEventPlayload,
} from 'src/models/PropagatedCacheManager';

/**
 * Returns a boolean that indicates when the orbit path should be shown.
 * The hook will only return true when there is at least 1 state vector in the
 * cache for a specific orbit.
 **/
function useShowOrbitPath(id: number) {
  const isPropagating = useIsPropagating();
  const [show, setShow] = useState(false);

  useOnPageChange(() => {
    const alreadyHasCache = PropagatedCacheManager.getCacheForOrbit(id);

    if (alreadyHasCache) {
      setShow(true);
    } else {
      setShow(false);
    }
  });

  useEffect(() => {
    function onDataRecieved({ orbitId }: DataRecievedEventPlayload) {
      if (orbitId === id) {
        setShow(true);
      }
    }

    PropagatedCacheManager.addEventListener('recieveddata', onDataRecieved);

    return function cleanup() {
      PropagatedCacheManager.removeEventListener('recieveddata', onDataRecieved);
    };
  }, [id]);

  return isPropagating ? show : false;
}

export default useShowOrbitPath;
