import { Box, styled } from '@mui/material';
import { ReactComponent as LogoIcon } from 'src/assets/Slingshot_Logo_2020_with_text.svg';

export const StatusContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: ${(props) => props.theme.palette.background.default};
  color: ${(props) => props.theme.palette.text.primary};
`;

export const StatusContainerFullScreen = styled(StatusContainer)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 1200;
`;

export const StatusBox = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: #303030;
  box-shadow: inset 41px 41px 82px #212121, inset -41px -41px 82px #3f3f3f;
  border-radius: 40px;
  width: 600px;
  height: 420px;
  padding: 38px;
  box-sizing: border-box;
`;

export const StatusInnerBox = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  background: #303030;
  border: 1px solid #3f3f3f;
  box-sizing: border-box;
  box-shadow: 41px 41px 82px #212121;
  border-radius: 30px;
  width: 460px;
  height: 214px;
`;

export const StatusLogoContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 160px;
  height: 60px;
  background: #303030;
  border: 1px solid #3f3f3f;
  box-sizing: border-box;
  box-shadow: 41px 41px 82px #212121;
  border-radius: 30px;
  margin-bottom: 38px;
`;

export const StatusLogo = styled(LogoIcon)``;
