import { DataGrid } from '@mui/x-data-grid';
import { useSocketState } from 'src/core/hooks';
import { getDeviceMemory } from 'src/utilities/windowUtils';

export const DeveloperTechnicalInfo = () => {
  const socketState = useSocketState();

  const columns = [
    { field: 'key', headerName: 'Property', flex: 1 },
    { field: 'value', headerName: 'Value', flex: 2 },
  ];

  const data = [
    {
      id: 0,
      key: 'WebSocket Connection',
      value: socketState,
    },
    {
      id: 1,
      key: 'WebSocket Connection',
      value: `${getDeviceMemory()}Gib of RAM`,
    },
    {
      id: 2,
      key: 'CPUs',
      value: window.navigator.hardwareConcurrency,
    },
  ];

  return (
    <>
      <DataGrid
        autoHeight
        columns={columns}
        rows={data}
        density="compact"
        sortModel={[{ field: 'key', sort: 'asc' }]}
        hideFooter
      />
    </>
  );
};
