import {
  AddLocationAltOutlined,
  RadarRounded,
  RocketLaunchRounded,
  UploadRounded,
} from '@mui/icons-material';
import { Grid, SvgIcon } from '@mui/material';
import { useCallback, useState } from 'react';
import { ReactComponent as SatelliteIcon } from 'src/assets/satellite.svg';
import { DEFAULT_GROUND_OBJECT_LOCATION } from 'src/constants';
import { useFetchNewPropagation } from 'src/core/hooks';
import {
  useCreateGroundObject,
  useCurrentGroundObjects,
  useSelectGroundObject,
} from 'src/hooks/GroundObjectHooks';
import {
  useCreateOrbitDefaultMutation,
  useCurrentOrbits,
  useIsPropagating,
  useSelectOrbit,
} from 'src/hooks/OrbitHooks';
import { useCurrentPage } from 'src/hooks/PageHooks';
import { SETTINGS_NAMES, useIsFeatureEnabled, useSettingsStore } from 'src/pages/Settings/store';
import { GroundObject, GroundObjectCategory } from 'src/types';
import { useEffectOnce } from 'usehooks-ts';
import { ModalEphemTLEPage } from '../../ModalEphemTLEPage';
import { SlideOutPanel } from '../SlideoutPanel/SlideOutPanel';
import { SlideoutPanelItem } from '../SlideoutPanel/SlideoutPanelItem';
import { SlideoutPanelLabel } from '../SlideoutPanel/SlideoutPanelLabel';
import { getIterativeNameFromList } from '../../../../utilities/StringUtils';

interface SlideOutPanelObjectProps {
  addPanelOpen: boolean;
  onClickOutside?: () => void;
  ignoreClassName?: string;
}

export const SlideOutPanelObject = ({
  addPanelOpen,
  onClickOutside,
  ignoreClassName,
}: SlideOutPanelObjectProps) => {
  const createOrbitDefaultMutation = useCreateOrbitDefaultMutation();
  const currentPageOrbits = useCurrentOrbits();

  const currentPage = useCurrentPage();
  const [objCounter, setObjCounter] = useState(
    currentPageOrbits?.length ? currentPageOrbits.length : 0,
  );

  const isPropagating = useIsPropagating();
  const setCurrentOrbit = useSelectOrbit();
  const fetchNewPropagation = useFetchNewPropagation();
  /**
   * Creates a new object
   */
  const onCreateNewObject = useCallback(async () => {
    if (currentPage) {
      let newObjNumber = objCounter + 1;
      // needed for initial page load of new page creation since local obj counter state
      // does not update with the auto created orbit object
      if (currentPageOrbits && currentPageOrbits.length > objCounter) {
        newObjNumber = currentPageOrbits.length + 1;
      }
      setObjCounter(newObjNumber);
      const result = await createOrbitDefaultMutation.mutateAsync({});
      setCurrentOrbit(result.orbits[0].id);
      if (isPropagating && currentPageOrbits && currentPage) {
        fetchNewPropagation(result.orbits[0]);
      }
    }
  }, [
    createOrbitDefaultMutation,
    currentPage,
    currentPageOrbits,
    fetchNewPropagation,
    isPropagating,
    objCounter,
    setCurrentOrbit,
  ]);

  // prefetch the geolocation so its in the cache
  const position = useSettingsStore((state) => state.position);
  const setPosition = useSettingsStore((state) => state.setPosition);
  useEffectOnce(() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (newPosition) => {
          setPosition(newPosition);
        },
        (error) => {
          console.warn('geolcation error:', error);
        },
        {
          maximumAge: 60 * 60 * 1000,
          timeout: 15 * 1000,
        },
      );
    }
  });

  const setCurrentGroundObject = useSelectGroundObject();
  const currentPageGroundObjects = useCurrentGroundObjects();
  const createGroundObjMutation = useCreateGroundObject();

  /**
   * Creates a new ground station object
   */
  const onCreateNewGroundObj = useCallback(
    async (_e: any, category?: GroundObjectCategory) => {
      if (currentPage) {
        let nameOriginal = '';
        if (category === GroundObjectCategory.LAUNCH_PAD) {
          nameOriginal = 'Launch Site';
        } else if (category === GroundObjectCategory.LOCATION) {
          nameOriginal = 'Location';
        } else {
          nameOriginal = 'Ground Object';
        }

        const namesExisting =
          currentPageGroundObjects?.map((groundObject) => groundObject.name) || [];

        const newGroundObjName = getIterativeNameFromList(nameOriginal, namesExisting);

        const newGroundObject: Partial<GroundObject> = {
          pageId: currentPage.id,
          additionalProperties: null,
          name: newGroundObjName,
          latitude: DEFAULT_GROUND_OBJECT_LOCATION.latitude,
          longitude: DEFAULT_GROUND_OBJECT_LOCATION.longitude,
          altitude: DEFAULT_GROUND_OBJECT_LOCATION.altitude,
          category: category ?? GroundObjectCategory.SENSOR,
          ...position, // spread the geolocated info if there
        };
        if (category !== GroundObjectCategory.LOCATION) {
          newGroundObject.minElevationAngle = DEFAULT_GROUND_OBJECT_LOCATION.minElevationAngle;
          newGroundObject.sensorRange = DEFAULT_GROUND_OBJECT_LOCATION.sensorRange;
        }
        const res = await createGroundObjMutation.mutateAsync(newGroundObject);

        setCurrentGroundObject(res.id as number);
        return res;
      }
    },
    [
      position,
      currentPage,
      setCurrentGroundObject,
      createGroundObjMutation,
      currentPageGroundObjects,
    ],
  );

  const isFeatureEphemerisEnabled = useIsFeatureEnabled(SETTINGS_NAMES.FEATURE_EPHEMERIS_ENABLED);

  const isFeatureLaunchEnabled = useIsFeatureEnabled(SETTINGS_NAMES.FEATURE_LAUNCH_ENABLED);

  const importLabel = isFeatureEphemerisEnabled ? 'TLE/Ephemeris' : 'TLE';

  const [modalTLEOpen, setModalTLEOpen] = useState(false);

  const toggleModalTLEOpen = useCallback(() => {
    setModalTLEOpen(!modalTLEOpen);
  }, [modalTLEOpen]);

  return (
    <>
      <Grid
        position="absolute"
        left="100%"
        bottom="0"
        overflow="hidden"
      >
        <SlideOutPanel
          title="Add Object"
          open={addPanelOpen}
          onClickOutside={onClickOutside}
          ignoreClassName={ignoreClassName}
        >
          <SlideoutPanelLabel>Space</SlideoutPanelLabel>
          <div onClick={onCreateNewObject}>
            <SlideoutPanelItem>
              <SvgIcon
                component={SatelliteIcon}
                inheritViewBox
                fontSize="inherit"
              />
              Space Object
            </SlideoutPanelItem>
          </div>

          <SlideoutPanelLabel>Ground</SlideoutPanelLabel>
          <div onClick={(e) => onCreateNewGroundObj(e, GroundObjectCategory.SENSOR)}>
            <SlideoutPanelItem>
              <RadarRounded fontSize="inherit" />
              Radar
            </SlideoutPanelItem>
          </div>

          {isFeatureLaunchEnabled && (
            <div onClick={(e) => onCreateNewGroundObj(e, GroundObjectCategory.LAUNCH_PAD)}>
              <SlideoutPanelItem>
                <RocketLaunchRounded fontSize="inherit" />
                Launch
              </SlideoutPanelItem>
            </div>
          )}

          <div onClick={(e) => onCreateNewGroundObj(e, GroundObjectCategory.LOCATION)}>
            <SlideoutPanelItem>
              <AddLocationAltOutlined fontSize="inherit" />
              Location
            </SlideoutPanelItem>
          </div>

          <SlideoutPanelLabel>Import</SlideoutPanelLabel>
          <div onClick={toggleModalTLEOpen}>
            <SlideoutPanelItem>
              <UploadRounded fontSize="inherit" />
              {importLabel}
            </SlideoutPanelItem>
          </div>
        </SlideOutPanel>
      </Grid>

      <ModalEphemTLEPage
        isOpen={modalTLEOpen}
        close={toggleModalTLEOpen}
      />
    </>
  );
};
