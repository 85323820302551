import { ExpandMoreRounded } from '@mui/icons-material';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  FormControlLabel,
  FormGroup,
  Grid,
  Slider,
  Switch,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { DEFAULT_ADDITIONAL_PROPERTIES_PAGE } from 'src/constants';
import { ACCORDION_STATES } from 'src/enums';
import { useCurrentPage, useUpdateCurrentPageAdditionalProperties } from 'src/hooks/PageHooks';
import { SETTINGS_NAMES, useSettingsStore } from 'src/pages/Settings/store';
import { LabelWithTooltip } from 'src/pages/Shared/LabelWithTooltip';
import { PageAdditionalProperties } from 'src/types';
import { addOrRemove } from 'src/utilities/ArrayUtils';
import { useDebounce } from 'usehooks-ts';

export const EARTH_LIGHTING = {
  SUN_INTENSITY: {
    DEFAULT: 3,
    MAXIMUM: 6,
    MINIMUM: 0,
  },
  NIGHT_INTENSITY: {
    DEFAULT: 1.5,
    MAXIMUM: 0,
    MINIMUM: 3,
  },
};

export function EarthLightingOptions() {
  const currentPage = useCurrentPage();
  const updateCurrentPageAdditionalProperties = useUpdateCurrentPageAdditionalProperties();
  const additionalProperties =
    currentPage?.additionalProperties || DEFAULT_ADDITIONAL_PROPERTIES_PAGE;

  const isSunLightingEnabled = additionalProperties.lightingEnabled;
  const isSunVectorVisible = additionalProperties.visSunVector;
  const lightingNightDarkness = additionalProperties.lightingNight;
  const lightingSunBrightness = additionalProperties.lightingSun;

  const accordionStates =
    useSettingsStore((state) => state.custom[SETTINGS_NAMES.EXPANDED_ACCORDIONS]) ||
    Object.values(ACCORDION_STATES);

  const setSetting = useSettingsStore((state) => state.setCustom);

  const [sliderLightingSun, setSliderLightingSun] = useState(lightingSunBrightness);
  const [sliderLightingNight, setSliderLightingNight] = useState(lightingNightDarkness);

  const debouncedSliderLightingSun = useDebounce(sliderLightingSun, 125);
  const debouncedSliderLightingNight = useDebounce(sliderLightingNight, 125);

  useEffect(() => {
    updateCurrentPageAdditionalProperties({ lightingSun: debouncedSliderLightingSun });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedSliderLightingSun]);
  useEffect(() => {
    updateCurrentPageAdditionalProperties({ lightingNight: debouncedSliderLightingNight });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedSliderLightingNight]);

  const handleChange = (changes: Partial<PageAdditionalProperties>) => {
    updateCurrentPageAdditionalProperties(changes);
  };

  const handleLightingNightChange = (_: Event, value: number | number[]) => {
    if (typeof value === 'number') {
      setSliderLightingNight(value);
    }
  };

  const handleLightingSunChange = (_: Event, value: number | number[]) => {
    if (typeof value === 'number') {
      setSliderLightingSun(value);
    }
  };

  const isExpanded =
    accordionStates.indexOf(ACCORDION_STATES.ACCORDION_EXPANDED_EARTH_LIGHTING) >= 0;

  const marks = [
    {
      value: 0,
      label: '0',
    },
    {
      value: 10,
    },
    {
      value: 20,
    },
    {
      value: 30,
    },
    {
      value: 40,
    },
    {
      value: 50,
    },
    {
      value: 60,
    },
    {
      value: 70,
    },
    {
      value: 80,
    },
    {
      value: 90,
    },
    {
      value: 100,
      label: '100',
    },
  ];

  const toggleAccordionState = (name: string) => {
    setSetting(SETTINGS_NAMES.EXPANDED_ACCORDIONS, addOrRemove(accordionStates, name));
  };

  return (
    <Accordion
      expanded={isExpanded}
      onChange={() => toggleAccordionState(ACCORDION_STATES.ACCORDION_EXPANDED_EARTH_LIGHTING)}
    >
      <AccordionSummary
        style={{
          flexDirection: 'row-reverse',
        }}
        expandIcon={<ExpandMoreRounded />}
        aria-controls="inspector-panel-visual-lighting"
        id="inspector-panel-visual-lighting"
      >
        <LabelWithTooltip
          label="Lighting"
          title="Enable the Sun-pointing vector and edit the visual lighting effects of the Sun."
        />
      </AccordionSummary>
      <AccordionDetails>
        <Grid
          container
          direction="column"
          px={2}
          gap={1}
        >
          <Grid item>
            <FormGroup>
              <FormControlLabel
                control={
                  <Switch
                    checked={isSunVectorVisible}
                    onChange={() => handleChange({ visSunVector: !isSunVectorVisible })}
                    size="small"
                  />
                }
                label={
                  <LabelWithTooltip
                    label="Sun Vector"
                    title="Show the vector pointing from the center of Earth towards the Sun."
                  />
                }
              />
            </FormGroup>
          </Grid>
          <Grid item>
            <FormGroup>
              <FormControlLabel
                control={
                  <Switch
                    checked={isSunLightingEnabled}
                    onChange={() => handleChange({ lightingEnabled: !isSunLightingEnabled })}
                    size="small"
                  />
                }
                label={
                  <LabelWithTooltip
                    label="Enable Sun Lighting"
                    title="Show the lighting effects on Earth's surface from the Sun."
                  />
                }
              />
            </FormGroup>
          </Grid>
          {isSunLightingEnabled && (
            <>
              <Grid
                item
                pt={2}
              >
                <LabelWithTooltip
                  label="Sun Brightness"
                  title="Adjust the intensity of the brightness from the Sun\'s lighting."
                />

                <Slider
                  valueLabelDisplay="auto"
                  aria-label="custom thumb label"
                  defaultValue={50}
                  value={sliderLightingSun}
                  onChange={handleLightingSunChange}
                  size="small"
                  marks={marks}
                />
              </Grid>

              <Grid item>
                <LabelWithTooltip
                  label="Night Darkness"
                  title="Adjust the intensity of the shadows cast from the Sun's lighting."
                />

                <Slider
                  valueLabelDisplay="auto"
                  aria-label="custom thumb label"
                  defaultValue={50}
                  value={sliderLightingNight}
                  onChange={handleLightingNightChange}
                  size="small"
                  marks={marks}
                />
              </Grid>
            </>
          )}
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
}
