import { Vector3 } from 'three';
import { create } from 'zustand';
import { immer } from 'zustand/middleware/immer';

interface ManeuverStoreState {
  colors: Record<number, string>;
  setColor: (id: number, color: string) => void;

  maneuverEditRIC: Vector3;
  setManeuverRIC: (newVal: Vector3) => void;

  hasErrorEarth: boolean;
  setHasErrorEarth: (value: boolean) => void;

  hasErrorEscape: boolean;
  setHasErrorEscape: (value: boolean) => void;

  hasErrorPropagationData: boolean;
  setHasErrorPropagationData: (value: boolean) => void;
}

export const useManeuverStore = create<ManeuverStoreState>()(
  immer((set, _get) => ({
    colors: {},
    setColor: (id: number, color: string) => {
      set((state) => {
        state.colors[id] = color;
      });
    },

    maneuverEditRIC: new Vector3(0, 0, 0),
    setManeuverRIC: (newVal: Vector3) => {
      set((state) => {
        state.maneuverEditRIC = newVal;
      });
    },

    hasErrorEarth: false,
    setHasErrorEarth: (value: boolean) => {
      set((state) => {
        state.hasErrorEarth = value;
      });
    },

    hasErrorEscape: false,
    setHasErrorEscape: (value: boolean) => {
      set((state) => {
        state.hasErrorEscape = value;
      });
    },

    hasErrorPropagationData: false,
    setHasErrorPropagationData: (value: boolean) => {
      set((state) => {
        state.hasErrorPropagationData = value;
      });
    },
  })),
);
