import { useCallback, useMemo } from 'react';
import { EARTH_ORIGIN, VISUAL_AID_HEX } from 'src/constants';
import { Vector3 } from 'three';
import { ArrowVector } from '../../ArrowVector';
import { createGetOrbitAOP } from '../../OrbitManager/store/getters';
import { use3DOrbitContext } from '../context';

interface EccentricityVectorProps {
  orbitId: number;
}

export const EccentricityVector = ({ orbitId }: EccentricityVectorProps) => {
  const getOrbitAOP = useMemo(() => createGetOrbitAOP(orbitId), [orbitId]);
  const getAopDestination = useCallback(
    () => new Vector3(getOrbitAOP().z, getOrbitAOP().x, getOrbitAOP().y),
    [getOrbitAOP],
  );

  const { selected } = use3DOrbitContext();

  return (
    <ArrowVector
      labelShort="e"
      active={selected}
      staticOrigin={EARTH_ORIGIN}
      color={VISUAL_AID_HEX}
      label="Eccentricity Vector"
      getDestination={getAopDestination}
    />
  );
};
