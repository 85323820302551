import { useMutation, useQueryClient } from '@tanstack/react-query';
import { getActivePageId, useRouteStore } from 'src/pages/App/routes/store';
import { fetchApi } from 'src/services/api';
import { Maneuver } from 'src/types';
import { useApiSnackbarError } from './SnackbarHooks';

export function useCreateManeuver() {
  const pageId = useRouteStore(getActivePageId);

  const apiSnackbarError = useApiSnackbarError();

  const queryClient = useQueryClient();
  return useMutation(
    ({
      name,
      orbitId,
      timestamp,
      deltaVelocity,
      radialComponent,
      inTrackComponent,
      crossTrackComponent,
      currentSpeed,
    }: Partial<Maneuver>) => {
      return fetchApi('/v2/maneuvers', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          name,
          orbitId,
          timestamp,
          deltaVelocity,
          radialComponent,
          inTrackComponent,
          crossTrackComponent,
          currentSpeed,
        }),
      });
    },
    {
      onError: () => {
        apiSnackbarError('Failed to create maneuver.');
      },
      onSuccess: async () => {
        await queryClient.invalidateQueries(['orbitsForPage', Number(pageId)]);
      },
    },
  );
}

export function useUpdateManeuver() {
  const pageId = useRouteStore(getActivePageId);

  const apiSnackbarError = useApiSnackbarError();

  const queryClient = useQueryClient();
  return useMutation(
    ({
      id,
      name,
      orbitId,
      timestamp,
      deltaVelocity,
      radialComponent,
      inTrackComponent,
      crossTrackComponent,
      currentSpeed,
    }: Maneuver) => {
      return fetchApi(`/v2/maneuvers/${id}`, {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          name,
          orbitId,
          timestamp,
          deltaVelocity,
          radialComponent,
          inTrackComponent,
          crossTrackComponent,
          currentSpeed,
        }),
      });
    },
    {
      onError: () => {
        apiSnackbarError('Failed to update maneuver.');
      },
      onSuccess: async () => {
        await queryClient.invalidateQueries(['orbitsForPage', Number(pageId)]);
      },
    },
  );
}

export function useDeleteManeuver() {
  const pageId = useRouteStore(getActivePageId);

  const apiSnackbarError = useApiSnackbarError();

  const queryClient = useQueryClient();
  return useMutation(
    ({ id }: Maneuver) => {
      return fetchApi(`/v2/maneuvers/${id}`, {
        method: 'DELETE',
        headers: { 'Content-Type': 'application/json' },
      });
    },
    {
      onError: () => {
        apiSnackbarError('Failed to delete maneuver.');
      },
      onSuccess: async () => {
        await queryClient.invalidateQueries(['orbitsForPage', Number(pageId)]);
      },
    },
  );
}
