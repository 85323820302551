import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { DEFAULT_ADDITIONAL_PROPERTIES_PAGE } from 'src/constants';
import { router } from 'src/pages/App/routes/Router';
import {
  getActiveCapabilityId,
  getActiveNotebookId,
  getActivePageId,
  useRouteStore,
} from 'src/pages/App/routes/store';
import { useViewingWindowsStore } from 'src/pages/Notebook/components/ViewingWindowsStore';
import { fetchApi } from 'src/services/api';
import { Page, PageAdditionalProperties } from 'src/types';
import { useCreateOrbitDefaultMutation } from './OrbitHooks';
import { useApiSnackbarError } from './SnackbarHooks';

const deserializePage = (page: any): Page => {
  return {
    ...page,
    startTime: new Date(page.startTime),
    endTime: new Date(page.endTime),
    additionalProperties: {
      ...DEFAULT_ADDITIONAL_PROPERTIES_PAGE,
      ...page.additionalProperties,
    },
  };
};

export function usePage(pageId?: number) {
  const apiSnackbarError = useApiSnackbarError();

  const { data } = useQuery<Page>(
    ['page', pageId],
    async () => {
      const page = await fetchApi(`/v2/pages/${pageId}`);

      return deserializePage(page);
    },
    {
      enabled: Boolean(pageId),
      onError: () => {
        apiSnackbarError('Failed to get page.');
      },
    },
  );

  return data;
}

// TODO: JIRA LAB-1815 issue for removing this query and replacing need
// with a POST response from createNotebook.mutateAsync
export function usePagesForNotebookQuery() {
  const apiSnackbarError = useApiSnackbarError();

  return useMutation(
    async (notebookId: number) => {
      const pages = await fetchApi(`/v2/pages?notebookId=${notebookId}`, {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' },
      });

      return pages.map(deserializePage);
    },
    {
      onError: () => {
        apiSnackbarError('Failed to get page(s).');
      },
    },
  );
}

export function usePages() {
  const capabilityId = useRouteStore(getActiveCapabilityId);
  const notebookId = useRouteStore(getActiveNotebookId);
  const pageId = useRouteStore(getActivePageId);

  const apiSnackbarError = useApiSnackbarError();

  const { data } = useQuery<Page[]>(
    ['pagesForNotebook', notebookId],
    async () => {
      const pages = await fetchApi(`/v2/pages?notebookId=${notebookId}`);

      return pages.map(deserializePage);
    },
    {
      enabled: Boolean(notebookId),
      onError: () => {
        apiSnackbarError('Failed to get page(s).');
      },
      onSuccess: (currentPages) => {
        const pageDeleted = currentPages.every((page) => {
          return page.id !== Number(pageId);
        });
        // if the notebook page was deleted, redirect to first page in notebook
        if (pageDeleted) {
          // if it has a capability id, redirect to shared id page, otherwise redirect to owned notebook page
          if (capabilityId) {
            router.navigate(`/shared/${capabilityId}/notebook/${notebookId}/${currentPages[0].id}`);
          } else {
            router.navigate(`/notebook/${notebookId}/${currentPages[0].id}`);
          }
        }
      },
    },
  );

  return data;
}

export function useCurrentPages() {
  return usePages() || [];
}

export function useCurrentPage() {
  const pageId = useRouteStore(getActivePageId);
  return usePage(pageId);
}

export function useUpdatePageMutation() {
  const notebookId = useRouteStore(getActiveNotebookId);

  const invalidateWindowsAll = useViewingWindowsStore((state) => state.invalidateWindowsAll);

  const apiSnackbarError = useApiSnackbarError();

  const queryClient = useQueryClient();
  return useMutation(
    (pageUpdate: Page) => {
      return fetchApi(`/v2/pages/${pageUpdate.id}`, {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(pageUpdate),
      });
    },
    {
      onError: () => {
        apiSnackbarError('Failed to update page.');
      },
      onSuccess: (_, variables) => {
        queryClient.invalidateQueries(['page', variables.id]);
        queryClient.invalidateQueries(['pagesForNotebook', notebookId]);
        // since page updates will cause TLE's to have updated COE props, need to refetch
        queryClient.invalidateQueries(['orbitsForPage', variables.id]);

        // if a pages start or end time change, all viewing windows must be re-requested
        invalidateWindowsAll();
      },
    },
  );
}

export function useUpdatePageAdditionalProperties() {
  const updatePage = useUpdatePageMutation();

  return (page: Page, changes: Partial<PageAdditionalProperties>) => {
    if (page) {
      const update: Page = {
        ...page,
        additionalProperties: {
          ...DEFAULT_ADDITIONAL_PROPERTIES_PAGE,
          ...page.additionalProperties,
          ...changes,
        },
      };
      updatePage.mutateAsync(update);
    }
  };
}

export function useUpdateCurrentPageAdditionalProperties() {
  const updatePageAdditionalProperties = useUpdatePageAdditionalProperties();
  const currentPage = useCurrentPage();

  return (changes: Partial<PageAdditionalProperties>) => {
    if (currentPage) {
      updatePageAdditionalProperties(currentPage, changes);
    }
  };
}

type CreatePage = Partial<Page>;

export function useCreatePageMutation() {
  const notebookId = useRouteStore(getActiveNotebookId);

  const apiSnackbarError = useApiSnackbarError();
  const createDefaultOrbitMutation = useCreateOrbitDefaultMutation();

  const queryClient = useQueryClient();
  return useMutation(
    (page: CreatePage) => {
      return fetchApi('/v2/pages', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(page),
      });
    },
    {
      onError: () => {
        apiSnackbarError('Failed to create page.');
      },
      onSuccess: async (page, variables) => {
        await createDefaultOrbitMutation.mutateAsync({ requestedPageId: page.id });
        queryClient.invalidateQueries(['pagesForNotebook', notebookId]);
      },
    },
  );
}

export function useDeletePageMutation() {
  const notebookId = useRouteStore(getActiveNotebookId);

  const apiSnackbarError = useApiSnackbarError();

  const queryClient = useQueryClient();
  return useMutation(
    (page: Page) => {
      return fetchApi(`/v2/pages/${page.id}`, {
        method: 'DELETE',
        headers: { 'Content-Type': 'application/json' },
      });
    },
    {
      onError: () => {
        apiSnackbarError('Failed to delete page.');
      },
      onSuccess: (_, variables) => {
        queryClient.invalidateQueries(['pagesForNotebook', notebookId]);
      },
    },
  );
}

export function useIsValidTimelineRange() {
  const page = useCurrentPage();

  if (!page) return true;
  return 299999 < +page.endTime - +page.startTime;
}

type GrantCopy = { notebookId: number; emails: string[] };

export function useGrantCopyCapabilityMutation() {
  const apiSnackbarError = useApiSnackbarError();

  return useMutation(
    (grantCopy: GrantCopy) => {
      return fetchApi(`/v2/folders/${grantCopy.notebookId}/capabilities`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(
          grantCopy.emails.map((email) => ({
            accessType: 'COPY',
            sourceId: grantCopy.notebookId,
            userEmail: email,
          })),
        ),
      });
    },
    {
      onError: () => {
        apiSnackbarError('Failed to share notebook.');
      },
    },
  );
}
