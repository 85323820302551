import {
  DownloadRounded,
  FiberManualRecordRounded,
  VideoCameraFrontRounded,
  VideocamRounded,
} from '@mui/icons-material';
import { Grid, SvgIcon, Tooltip } from '@mui/material';
import { useState } from 'react';
import { useReactMediaRecorder } from 'react-media-recorder';
import useAppStore, { SETTINGS_NAMES_TRANSIENT } from 'src/core/store';
import { SETTINGS_NAMES, useSettingsStore } from 'src/pages/Settings/store';
import { millisToLocaleFilename } from 'src/utilities/DateTimeUtils';
import { MenuBarIconButton } from '../components/MenuBar.styled';
import { ModalVideoDownload } from './ModalVideoDownload';
import { Timer } from './Timer';
// eslint-disable-next-line max-len
import { ReactComponent as VideoCameraFrontOffRounded } from 'src/assets/video_camera_front_off.svg';

const getBestMediaRecorderMimeTypeVideo = (): string => {
  if (!MediaRecorder) {
    return 'unsupported';
  }
  // rank the preferred ones higher in the list
  const mimeTypesToTest = [
    'video/mp4;codecs=h265',
    'video/mp4;codecs=h264',
    'video/mp4',
    'video/webm;codecs=vp9',
    // 'video/webm;codecs=vp8', // firefox supports it, but then fails to use it :(
    'video/webm',
  ];

  const mimeType = mimeTypesToTest.find((mimeType) => {
    return MediaRecorder.isTypeSupported(mimeType);
  });

  return mimeType || 'unsupported';
};

export const MenuBarRecording = () => {
  const [modalOpen, setModalOpen] = useState(false);
  const [downloadName, setDownloadName] = useState('');

  const [timeStart, setTimeStart] = useState(-1);
  const [duration, setDuration] = useState(0);
  const [onMouseOver, setOnMouseOver] = useState(false);

  const mimeType = getBestMediaRecorderMimeTypeVideo();

  const setSettingTransient = useAppStore((state) => state.setSetting);

  const cameraEnabled = useSettingsStore(
    (state) => state.settings[SETTINGS_NAMES.VIDEO_CAMERA_ENABLED],
  );
  const setSetting = useSettingsStore((state) => state.setSetting);

  const doOnStart = () => {
    setTimeStart(Date.now());
  };

  const doOnStop = (blobUrl: string) => {
    const downloadFilename = [
      'slingshot-laboratory',
      millisToLocaleFilename(Date.now(), 'utc').replaceAll(':', '-').replaceAll(' ', '_'),
    ].join('-');

    setDownloadName(downloadFilename);
    setModalOpen(true);
  };

  const { clearBlobUrl, mediaBlobUrl, startRecording, status, stopRecording } =
    useReactMediaRecorder({
      mediaRecorderOptions: {
        mimeType: mimeType,
      },
      screen: true,
      stopStreamsOnStop: true,
      onStart: doOnStart,
      onStop: doOnStop,
    });

  const handleRecordClick = () => {
    if (status === 'recording') {
      setDuration((Date.now() - timeStart) / 1000);
      setSettingTransient(SETTINGS_NAMES_TRANSIENT.VIDEO_RECORDING_ENABLED, false);
      stopRecording();
    } else {
      setDuration(0);
      setSettingTransient(SETTINGS_NAMES_TRANSIENT.VIDEO_RECORDING_ENABLED, true);
      clearBlobUrl();
      startRecording();
    }
  };

  const handleCameraClick = () => {
    setSetting(SETTINGS_NAMES.VIDEO_CAMERA_ENABLED, !cameraEnabled);
  };

  const showBackground = duration !== 0 || onMouseOver || status === 'recording';

  return (
    <>
      <ModalVideoDownload
        isOpen={modalOpen}
        close={() => setModalOpen(false)}
        downloadName={downloadName}
        mediaUrl={mediaBlobUrl!}
        duration={duration}
        mimeType={mimeType}
      />
      <Grid
        display="grid"
        gridAutoFlow="column"
        alignItems="center"
        sx={{
          background: showBackground ? (theme) => theme.palette.background.default : '',
          borderRadius: 16,
        }}
        onMouseOver={() => setOnMouseOver(true)}
        onMouseOut={() => setOnMouseOver(false)}
      >
        <Tooltip
          enterDelay={1000} // delay the tip to help prevent showing in recording
          title={status === 'recording' ? 'Stop the recording' : 'Start a video recording'}
        >
          <MenuBarIconButton
            disableRipple
            onClick={handleRecordClick}
          >
            <VideocamRounded htmlColor={cameraEnabled || status === 'recording' ? 'white' : ''} />
          </MenuBarIconButton>
        </Tooltip>

        {mediaBlobUrl && (
          <Tooltip title="Open Download Options">
            <MenuBarIconButton onClick={() => setModalOpen(true)}>
              <DownloadRounded />
            </MenuBarIconButton>
          </Tooltip>
        )}

        {(onMouseOver || status === 'recording') && (
          <Tooltip title="Toggle local camera">
            <MenuBarIconButton
              onClick={handleCameraClick}
              sx={{
                color: cameraEnabled ? 'white' : '',
              }}
            >
              {cameraEnabled ? (
                <VideoCameraFrontRounded />
              ) : (
                <SvgIcon
                  component={VideoCameraFrontOffRounded}
                  inheritViewBox
                />
              )}
            </MenuBarIconButton>
          </Tooltip>
        )}

        {status === 'recording' && (
          <Grid
            display="grid"
            gridAutoFlow="column"
            alignItems="center"
            gap={1}
            p={1}
          >
            <FiberManualRecordRounded color="error" />
            <Timer timeStart={timeStart} />
          </Grid>
        )}
      </Grid>
    </>
  );
};
