import { QuestionMarkRounded } from '@mui/icons-material';
import { alpha, Grid, styled } from '@mui/material';

export const TLEInfoBtn = styled(QuestionMarkRounded)`
  border-radius: 50%;
  background-color: ${(props) => props.theme.palette.success.main};
  padding: 5px;
  font-size: 28px;
  cursor: pointer;
`;

export const TLEDiagramImg = styled('img')`
  max-width: 100%;
  max-height: 100%;
`;

export const EphemTLETextInputStyles = {
  style: {
    fontFeatureSettings: '"tnum"',
  },
};

export const EphemUploadBorderStyle = styled(Grid)`
  display: flex;
  cursor: pointer;
  border: 2px dashed;
  justify-content: center;
  border-color: ${(props) =>
    alpha(props.theme.palette.action.active, props.theme.palette.action.activatedOpacity)};
  :hover {
    border-color: ${(props) => props.theme.palette.primary.main};
  }
`;

export const EphemFileBorder = styled(Grid)`
  border-color: ${(props) => props.theme.palette.primary.main};
`;
