import { useFrame } from '@react-three/fiber';
import { useMemo } from 'react';
import { createGetOrbitAOP } from 'src/threejs/components/OrbitManager/store/getters';
import { MarkerProps } from 'src/types';
import { Vector3 } from 'three';
import Marker from '../../../Marker';
import { use3DOrbitContext } from '../../context';
import AopMarkerHandle from './AopMarkerHandle';

/** The AOP marker */
const AopMarker = ({ handle, setHandle, marker, setMarker }: MarkerProps) => {
  const { id, size } = use3DOrbitContext();
  const getOrbitAOP = useMemo(() => createGetOrbitAOP(id), [id]);

  const scale = useMemo(() => new Vector3().setScalar(size / 50), [size]);

  useFrame(() => {
    const { x, y, z } = getOrbitAOP();

    if (marker) {
      marker.position.set(x, y, z);
    }
  });

  return (
    <>
      <Marker
        marker={marker}
        setMarker={setMarker}
        markerName="AOP Marker"
        scale={scale}
      >
        <sphereGeometry />
        <meshBasicMaterial
          color={0xfc8800}
          transparent={true}
          opacity={0.75}
          depthWrite={false}
        />
      </Marker>
      <AopMarkerHandle
        handle={handle}
        setHandle={setHandle}
      />
    </>
  );
};

export default AopMarker;
