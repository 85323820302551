const pause = (duration: number) => {
  return new Promise((resolve) => setTimeout(resolve, duration));
};

/**
 * Retries a promise x number of times with a delay in between each retry.
 * @param func The function to retry
 * @param delay The delay in ms between each retry
 * @param retries The number of times to retry
 * @param initial The number of times to retry, but stores so we can display
 * @returns The promise result
 */
async function retry<Result>(
  func: () => Promise<Result>,
  delay: number,
  retries: number,
  initial?: number,
): Promise<Result> {
  if (retries > 1) {
    try {
      const result = await func();

      return result;
    } catch (error) {
      await pause(delay);

      const result = await retry<Result>(func, delay, retries - 1, initial ?? retries);

      return result;
    }
  } else {
    throw new Error(`Could not resolve promise after retrying ${initial} times.`);
  }
}

export default retry;
