import { useThree } from '@react-three/fiber';
import { useCallback, useEffect, useRef } from 'react';
import { useIsPropagating, useOrbit, useSelectOrbit } from 'src/hooks/OrbitHooks';
import { PolyPath } from 'src/threejs/models/Polypath';
import { R3MouseEvent, R3PointerEvent } from 'src/threejs/types';
import { Mesh, TubeGeometry, Vector3 } from 'three';
import use3DOrbitStore from '../../OrbitManager/store/store';
import { useActiveManipulator } from '../../ViewportManager/store';
import { use3DOrbitContext } from '../context';

type InvisibleOrbitProps = {
  tubularSegments: number;
  radius: { selected: number; hidden: number };
  radiusSegments: number;
  closed: boolean;
  transparent: boolean;
  opacity: number;
  orbitColor: string;
};

/** The invisble orbit used for clicking and selecting an orbit. */
const InvisibleOrbit = ({
  tubularSegments,
  radius,
  radiusSegments,
  closed,
  transparent,
  opacity,
  orbitColor,
}: InvisibleOrbitProps) => {
  const { gl } = useThree();
  const { name, id, selected } = use3DOrbitContext();
  const [activeManipulator] = useActiveManipulator();
  const isPropagating = useIsPropagating();
  const selectOrbit = useSelectOrbit();

  const enablePointerEvents = () => {
    if (selected) return false;
    if (activeManipulator) return false;
    return true;
  };

  const handlePointerOver = useCallback(
    (e: R3PointerEvent) => {
      e.stopPropagation();
      gl.domElement.style.cursor = 'pointer';
    },
    [gl.domElement.style],
  );

  const handlePointerOut = useCallback(
    (e: R3PointerEvent) => {
      e.stopPropagation();
      gl.domElement.style.cursor = 'default';
    },
    [gl.domElement.style],
  );

  const handleDoubleClick = useCallback(
    (e: R3MouseEvent) => {
      e.stopPropagation();
      selectOrbit(id);
    },
    [id, selectOrbit],
  );

  const handlePointerMove = useCallback((e: R3PointerEvent) => {
    e.stopPropagation();
  }, []);

  const ref = useRef<Mesh>(null);
  const orbit = useOrbit(id);
  const trackLength3D = orbit?.additionalProperties?.trackLength3D;

  useEffect(() => {
    const updateGeometry = (vertices: Vector3[]) => {
      if (ref.current && vertices.length > 0) {
        ref.current.geometry.dispose();
        const usedVertices = [...vertices];
        let actuallyClosed = closed;
        if (trackLength3D && trackLength3D > 0) {
          usedVertices.splice(usedVertices.length * trackLength3D);
          actuallyClosed = false;
        }
        const orbitPath = new PolyPath(usedVertices);
        ref.current.geometry = new TubeGeometry(
          orbitPath,
          tubularSegments,
          selected ? radius.selected : radius.hidden,
          radiusSegments,
          actuallyClosed,
        );
      }
    };

    // Update geometry when a dependency from props changes
    updateGeometry(use3DOrbitStore.getState().orbits[id].verts);

    // Update geometry when orbit's vertices change
    return use3DOrbitStore.subscribe((state) => state.orbits[id].verts, updateGeometry);
  }, [
    id,
    closed,
    selected,
    radius.hidden,
    radiusSegments,
    radius.selected,
    trackLength3D,
    tubularSegments,
  ]);

  return (
    <mesh
      name={`${name} Invisible Orbit Mesh`}
      onPointerOver={enablePointerEvents() ? handlePointerOver : undefined}
      onPointerOut={enablePointerEvents() ? handlePointerOut : undefined}
      onPointerMove={enablePointerEvents() ? handlePointerMove : undefined}
      onDoubleClick={enablePointerEvents() ? handleDoubleClick : undefined}
      visible={selected}
      ref={ref}
      renderOrder={2}
    >
      <meshBasicMaterial
        color={orbitColor}
        transparent={transparent}
        opacity={opacity}
        name={`${name} Invisible Orbit Material`}
        visible={!isPropagating}
      />
    </mesh>
  );
};

export default InvisibleOrbit;
