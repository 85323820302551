import { useMemo } from 'react';
import { useViewingWindowsStore } from 'src/pages/Notebook/components/ViewingWindowsStore';
import { GroundObject, ViewingWindow } from 'src/types';
import { Group } from 'three';
import { OrbitLoSArrow } from './OrbitLoSArrow';

interface OrbitLoSArrowsProps {
  groundObjectGroup: Group;
  groundObject: GroundObject;
}

export const OrbitLoSArrows = ({ groundObjectGroup, groundObject }: OrbitLoSArrowsProps) => {
  const viewingWindows = useViewingWindowsStore(
    (state) => state.groundObjectWindows[groundObject.id]?.windows || [],
  );

  const orbitViewingWindows: Record<number, ViewingWindow[]> = useMemo(() => {
    const orbitViewingWindows: Record<number, ViewingWindow[]> = {};

    viewingWindows?.forEach((window) => {
      if (!orbitViewingWindows[window.orbitId]) orbitViewingWindows[window.orbitId] = [];

      orbitViewingWindows[window.orbitId].push(window);
    });
    return orbitViewingWindows;
  }, [viewingWindows]);

  if (!viewingWindows) {
    return null;
  }

  return (
    <>
      {Object.entries(orbitViewingWindows).map(
        ([orbitId, orbitWindows]) =>
          orbitWindows.length > 0 && (
            <OrbitLoSArrow
              key={orbitId}
              orbitId={+orbitId}
              orbitViewingWindows={orbitWindows}
              groundObjectGroup={groundObjectGroup}
            />
          ),
      )}
    </>
  );
};
