import { Slider as MuiSlider, styled } from '@mui/material';

interface SliderProps {
  progress: number;
}

export const Slider = styled(MuiSlider)<SliderProps>(
  ({ progress }) => `
  .MuiSlider-rail::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: ${progress}%;
    background: #f1592b;
    opacity: 1;
  }
`,
);
