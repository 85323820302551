import { useCallback, useMemo, useState } from 'react';
import MarkerManipulator, { MarkerMoveProps } from 'src/threejs/components/MarkerManipulator';
import {
  createGetOrbitCOE,
  createGetOrbitData,
} from 'src/threejs/components/OrbitManager/store/getters';
import { use3DOrbitRAAN } from 'src/threejs/components/OrbitManager/store/hooks';
import useCamera from 'src/threejs/hooks/useCamera';
import { COE } from 'src/threejs/models/COE';
import ManipulatorPlaneModel from 'src/threejs/models/ManipulatorPlane';
import { MarkerHandleProps } from 'src/types';
import { positiveDegAngle } from 'src/utilities/MathUtils';
import { AdditiveBlending, Group, Matrix4, Mesh, Shape, Sprite, Vector3 } from 'three';
import { use3DOrbitContext } from '../../context';

// Arc Handle
const arcShape = new Shape()
  .absarc(0, -5, 5.83, (59.04 * Math.PI) / 180, (120.96 * Math.PI) / 180, false)
  .lineTo(-4, 0.08)
  .lineTo(-3.6, 1)
  .absarc(0, -5, 7, (120.96 * Math.PI) / 180, (59.04 * Math.PI) / 180, true)
  .lineTo(4, 0.08)
  .lineTo(3, 0);

const extrudeSettings = {
  depth: 0.25,
  bevelEnabled: false,
  bevelSegments: 1,
  steps: 1,
  bevelSize: 1,
  bevelThickness: 1,
};

const config = {
  raanHandleOffset: 0.15,
  raanHandleRotationSpeed: 5,
};

const RaanMarkerHandle = ({ handle, setHandle }: MarkerHandleProps) => {
  const { id } = use3DOrbitContext();
  const raanPosition = use3DOrbitRAAN();
  const camera = useCamera();

  const tempVector = useMemo(() => new Vector3(0, 0, 0), []);
  const tempVector2 = useMemo(() => new Vector3(0, 0, 0), []);

  const tempMatrix = useMemo(() => new Matrix4(), []);
  const getOrbitData = useMemo(() => createGetOrbitData(id), [id]);
  const getOrbitCOE = useMemo(() => createGetOrbitCOE(id), [id]);

  const textSpriteProps = useMemo(
    () => ({
      fontsize: 40,
      borderColor: { r: 255, g: 255, b: 255, a: 1.0 },
      backgroundColor: { r: 255, g: 255, b: 255, a: 0.1 },
      textColor: { r: 0, g: 255, b: 0, a: 0.75 },
      borderThickness: 0,
      sizeAttenuation: false,
    }),
    [],
  );

  const [group, setGroup] = useState<Group | null>(null);
  const [bounds, setBounds] = useState<Mesh | null>(null);
  const [sprite, setSprite] = useState<Sprite | null>(null);
  const [plane, setPlane] = useState<ManipulatorPlaneModel | null>(null);

  const makeSpriteName = (coe: COE) => `Ω: ${coe.rightAscensionOfAscendingNode.toFixed(3)}`;

  const move = useCallback(
    ({ group, pointOnPlaneOffset, tempVector, tempVector2 }: MarkerMoveProps) => {
      const coe = getOrbitCOE();
      const orbitData = getOrbitData();

      tempVector.set(0, 0, 0);

      tempVector2.copy(orbitData.raanPosition).sub(orbitData.primeFocusPosition).normalize();
      tempVector2.cross(group.up).negate().normalize();

      // project along tangent
      tempVector.copy(pointOnPlaneOffset).projectOnVector(tempVector2);

      let rotationangle = tempVector.length() * config.raanHandleRotationSpeed;

      rotationangle *= tempVector.dot(tempVector2) >= 0 ? 1 : -1;

      const rightAscensionOfAscendingNode = positiveDegAngle(
        coe.rightAscensionOfAscendingNode + rotationangle,
      );

      return { rightAscensionOfAscendingNode };
    },
    [getOrbitCOE, getOrbitData],
  );

  const updateBounds = () => {
    if (bounds) {
      const orbitData = getOrbitData();

      // update bounds position
      bounds.position.copy(orbitData.raanPosition);
    }
  };

  const updateSprite = () => {
    if (sprite) {
      sprite.position.copy(raanPosition);
    }
  };

  const updateTransform = () => {
    if (handle && group && plane) {
      const orbitData = getOrbitData();

      tempVector2.copy(orbitData.raanPosition).sub(orbitData.primeFocusPosition).normalize();

      // move handle to offset position
      tempVector.copy(tempVector2).normalize().multiplyScalar(config.raanHandleOffset);
      handle.position.copy(orbitData.raanPosition).add(tempVector);

      // set the handle orientation to rotate with raan
      tempVector.copy(tempVector2).cross(group.up);
      tempMatrix.makeBasis(tempVector, tempVector2, group.up);
      handle.quaternion.setFromRotationMatrix(tempMatrix);

      // rotate the plane for the active handle
      plane.updateTransform(camera, handle);
    }
  };

  return (
    <MarkerManipulator
      move={move}
      textSpriteMessage="raan: "
      textSpriteProps={textSpriteProps}
      makeSpriteName={makeSpriteName}
      markerName="RAAN"
      setHandle={setHandle}
      handle={handle}
      setGroup={setGroup}
      setBounds={setBounds}
      setSprite={setSprite}
      setPlane={setPlane}
      plane={plane}
      group={group}
      scale={0.02}
      updateBounds={updateBounds}
      updateSprite={updateSprite}
      updateTransform={updateTransform}
      manipulatorPlaneRotation
    >
      <extrudeGeometry args={[arcShape, extrudeSettings]} />
      <meshBasicMaterial
        color={0x00ff00}
        depthTest={false}
        depthWrite={false}
        transparent={true}
        opacity={0.5}
        toneMapped={false}
        blending={AdditiveBlending}
      />
    </MarkerManipulator>
  );
};

export default RaanMarkerHandle;
