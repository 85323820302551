import { ClickAwayListener, Grid, Menu, MenuItem } from '@mui/material';
import { ReactNode, useCallback, useState } from 'react';
import { Folder, Notebook, SharedFolder, SharedNotebook } from 'src/types';

interface ObjectContextMenuProps {
  children: ReactNode;
  item: Folder | Notebook | SharedNotebook | SharedFolder;
}

export const NotebookFolderContextMenu = ({ children, item }: ObjectContextMenuProps) => {
  const [contextMenu, setContextMenu] = useState<{
    mouseX: number;
    mouseY: number;
  } | null>(null);

  const handleContextMenu = useCallback(
    (event: React.MouseEvent) => {
      event.preventDefault();
      if (item.type === 'SHARED-FOLDER') return;

      setContextMenu(
        contextMenu === null
          ? {
              mouseX: event.clientX + 2,
              mouseY: event.clientY - 6,
            }
          : // repeated contextmenu when it is already open closes it with Chrome 84 on Ubuntu
            // Other native context menus might behave different.
            // With this behavior we prevent contextmenu from the backdrop to re-locale existing context menus.
            null,
      );
    },
    [contextMenu, item.type],
  );

  const handleClose = useCallback(() => {
    setContextMenu(null);
  }, []);

  const handleOpenNewTab = useCallback(
    (e: React.SyntheticEvent) => {
      if (item.type === 'FOLDER') {
        window.open(`/folders/${item.id}`, '_blank')?.focus();
      } else if (item.type === 'NOTEBOOK') {
        window.open(`/notebook/${item.id}`, '_blank')?.focus();
      } else if (item.type === 'SHARED-NOTEBOOK') {
        window.open(`/shared/${item.capabilityId}/notebook/${item.id}`, '_blank')?.focus();
      }

      handleClose();
    },
    [handleClose, item],
  );

  return (
    <ClickAwayListener onClickAway={handleClose}>
      <Grid
        container
        width="100%"
        height="100%"
        alignItems="center"
        onContextMenu={handleContextMenu}
        style={{ cursor: 'context-menu' }}
      >
        <Menu
          open={contextMenu !== null}
          onClose={handleClose}
          anchorReference="anchorPosition"
          anchorPosition={
            contextMenu !== null ? { top: contextMenu.mouseY, left: contextMenu.mouseX } : undefined
          }
        >
          <MenuItem onClick={handleOpenNewTab}>Open {item.name} in new tab</MenuItem>
        </Menu>
        {children}
      </Grid>
    </ClickAwayListener>
  );
};
