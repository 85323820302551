import { FeedbackRounded, FolderRounded, PhotoCameraRounded } from '@mui/icons-material';
import { AppBar, Divider, Grid, IconButton, Menu, MenuItem, styled } from '@mui/material';
import { grey } from '@mui/material/colors';
import { useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { ReactComponent as LogoIcon } from 'src/assets/Slingshot_Logo_2020_rev_horiz.svg';
import { useCurrentNotebook } from 'src/hooks/ObjectHooks';
import { createFileName, useScreenshot } from 'src/hooks/useScreenshot';
import { ModalAbout } from 'src/pages/App/components/ModalAbout';
import { DeveloperMenu } from 'src/pages/Developer/DeveloperContainer';
import { MenuBarLayout } from 'src/pages/Notebook/components/LayoutEngine/MenuBarLayout';
import { millisToLocaleFilename } from 'src/utilities/DateTimeUtils';
import { buildVersion } from 'src/utilities/buildInfo/buildInfo';
import { MenuBarRecording } from '../Recording/MenuBarRecording';
import { getActiveNotebookId, getActivePageId, useRouteStore } from '../routes/store';
import MakeCopyButton from './MakeCopyButton';
import { MenuBarIconButton, StyledLink } from './MenuBar.styled';
import { MenuBarProfile } from './MenuBarProfile';
import { MenuBarUserGuides } from './MenuBarUserGuides';
import { ObjectSearch } from './ObjectSearch';

export const FolderIcon = styled(FolderRounded)`
  color: ${grey[700]};
`;

const FolderLink = () => {
  const currentNotebook = useCurrentNotebook();
  const path = currentNotebook?.path ?? [];
  const [lastFolder] = path.slice(-1);
  const notebookId = useRouteStore(getActiveNotebookId);

  return notebookId ? (
    <Link to={`/folders/${lastFolder?.id ?? ''}`}>
      <IconButton size="large">
        <FolderIcon />
      </IconButton>
    </Link>
  ) : (
    <></>
  );
};

export default function MenuBar() {
  const pageId = useRouteStore(getActivePageId);

  const [feedbackMenuOpen, setFeedbackMenuOpen] = useState(false);
  const bugReportIconRef = useRef<HTMLButtonElement>(null);

  const [modalAboutOpen, setModalAboutOpen] = useState(false);

  const { takeScreenshot } = useScreenshot('image/png', 1);

  const getImage = () => {
    takeScreenshot(document.body).then((image: string) => {
      const name = [
        'slingshot-laboratory',
        millisToLocaleFilename(new Date().getTime()).replaceAll(':', '-').replaceAll(' ', '_'),
      ];
      const a = document.createElement('a');
      a.href = image;
      a.download = createFileName('png', name.join('_'));
      a.click();
    });
  };

  const hasRecordingCapabilities = !!navigator?.mediaDevices?.getDisplayMedia;

  const newLine = '%0D%0A';
  const divider = '————————————————';
  const emailBody = `&body=${newLine}${newLine}${divider}${newLine}Slingshot Laboratory: v${process.env.REACT_APP_VERSION}${newLine}Build: ${buildVersion}${newLine}${divider}${newLine}${newLine}`;

  return (
    <AppBar
      elevation={0}
      position="static"
    >
      <ModalAbout
        open={modalAboutOpen}
        show={setModalAboutOpen}
      />

      <Grid
        container
        display="grid"
        gridAutoFlow="column"
        gridAutoColumns="1fr"
        gap={1}
        px={1}
      >
        <Grid
          display="grid"
          gridAutoFlow="column"
          alignItems="center"
          justifyContent="start"
        >
          <Grid px={1}>
            <Link to="/folders">
              <LogoIcon
                style={{
                  height: 40,
                  display: 'block',
                }}
              />
            </Link>
          </Grid>

          <ObjectSearch />

          <FolderLink />

          <MenuBarUserGuides />

          <MenuBarIconButton
            ref={bugReportIconRef}
            onClick={() => setFeedbackMenuOpen(true)}
          >
            <FeedbackRounded />
          </MenuBarIconButton>

          {bugReportIconRef.current && (
            <Menu
              anchorEl={bugReportIconRef.current}
              keepMounted
              open={feedbackMenuOpen}
              onClose={() => setFeedbackMenuOpen(false)}
            >
              <MenuItem>
                <StyledLink
                  href={`mailto:lab-feedback@slingshotaerospace.com?subject=LAB - Feedback - ${emailBody}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Send us feedback
                </StyledLink>
              </MenuItem>

              <MenuItem>
                <StyledLink
                  href={`mailto:lab-support@slingshotaerospace.com?subject=LAB - Report An Issue - ${emailBody}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Report an issue
                </StyledLink>
              </MenuItem>

              <Divider />

              <MenuItem disabled>Support documentation</MenuItem>

              <Grid
                display="grid"
                gridAutoFlow="row"
                px={2}
              >
                <MenuItem>
                  <StyledLink
                    href="/docs/FAQs"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    FAQs
                  </StyledLink>
                </MenuItem>
                <MenuItem>
                  <StyledLink
                    href="/docs/SystemRequirements"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    System Requirements
                  </StyledLink>
                </MenuItem>
                <MenuItem>
                  <StyledLink
                    href="/docs/TermsOfService"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Terms of Service
                  </StyledLink>
                </MenuItem>
                <MenuItem>
                  <StyledLink
                    href="/docs/EULA"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    EULA
                  </StyledLink>
                </MenuItem>
              </Grid>

              <Divider sx={{ my: 1 }} />

              <MenuItem>
                <StyledLink
                  href="https://slingshotaerospace.com/privacy-policy"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Privacy Policy
                </StyledLink>
              </MenuItem>

              <MenuItem onClick={() => setModalAboutOpen(true)}>
                About Slingshot Laboratory
              </MenuItem>
            </Menu>
          )}

          {hasRecordingCapabilities && <MenuBarRecording />}

          <MenuBarIconButton onClick={getImage}>
            <PhotoCameraRounded />
          </MenuBarIconButton>

          <DeveloperMenu />
        </Grid>

        {/* menubar center */}
        <Grid
          item
          display="grid"
          gridAutoFlow="column"
          alignItems="center"
          justifyContent="center"
        >
          <MakeCopyButton />
        </Grid>

        {/* menubar right side */}
        <Grid
          item
          display="grid"
          gridAutoFlow="column"
          alignItems="center"
          justifyContent="end"
        >
          {!isNaN(pageId) && <MenuBarLayout />}

          <Grid
            display="grid"
            gridAutoFlow="column"
            gap={1}
            px={1}
          >
            <MenuBarProfile />
          </Grid>
        </Grid>
      </Grid>
    </AppBar>
  );
}
