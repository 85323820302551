import { ReactComponent as SatelliteIcon } from 'src/assets/satellite.svg';
import { AddRounded } from '@mui/icons-material';
import { Button, Dialog, DialogActions, DialogContent, SvgIcon } from '@mui/material';
import { addDays } from 'date-fns';
import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { SpaceGlassContainerBase } from 'src/components/SpaceGlassControl';
import { LABObject } from 'src/enums';
import { useSetGroups } from 'src/hooks/GroupHooks';
import { useCreateObjectMutation, useObjectHierarchy } from 'src/hooks/ObjectHooks';
import { useSetOrbits } from 'src/hooks/OrbitHooks';
import {
  useCurrentPage,
  usePagesForNotebookQuery,
  useUpdatePageMutation,
} from 'src/hooks/PageHooks';
import { ModalHeader } from 'src/pages/Shared/ModalSpaceGlass';
import { Folder, SpaceObject } from 'src/types';
import { getTleEpochDate } from 'src/utilities/DateTimeUtils';
import { getActiveFolderId, useRouteStore } from '../App/routes/store';

type ModalImportCatalogObjectProps = {
  show: boolean;
  onClose: VoidFunction;
  objectToImport: SpaceObject;
  importTLE: (spaceObject: SpaceObject, newPageId?: number) => Promise<any>;
};

export function ModalImportCatalogObject(props: ModalImportCatalogObjectProps) {
  const currentPage = useCurrentPage();
  const navigate = useNavigate();
  const createNotebook = useCreateObjectMutation();
  const updatePage = useUpdatePageMutation();
  const getPages = usePagesForNotebookQuery();

  const folderId = useRouteStore(getActiveFolderId);
  const data = useObjectHierarchy(folderId);
  const currentFolder = data as Folder;

  const setOrbits = useSetOrbits();
  const setGroups = useSetGroups();

  const onSubmit = useCallback(async () => {
    const newNotebook = await createNotebook.mutateAsync({
      parentId: currentFolder.id,
      type: LABObject.NOTEBOOK,
      name: `Imported ${props.objectToImport.OBJECT_NAME} Notebook`,
      notes: 'Notes',
    });

    if (newNotebook?.updates) {
      const objectStartTime = getTleEpochDate(props.objectToImport.USER_DEFINED_TLE_LINE1);

      const [update] = newNotebook.updates;

      // TODO: JIRA LAB-1815 issue regarding replacing this query with POST response from createNotebook.mutateAsync
      const [newPage] = await getPages.mutateAsync(update.objectId);

      if (!newPage) return;

      updatePage.mutate({
        ...newPage,
        name: `Imported ${props.objectToImport.OBJECT_NAME} Page`,
        startTime: objectStartTime,
        endTime: addDays(objectStartTime, 1),
      });

      setOrbits(undefined);
      setGroups(undefined);

      const newOrbitId = await props.importTLE(props.objectToImport, newPage.id);
      navigate(`/notebook/${update.objectId}/${newPage.id}/${newOrbitId}`);
    }

    props.onClose();
  }, [
    createNotebook,
    getPages,
    updatePage,
    currentFolder.id,
    navigate,
    props,
    setGroups,
    setOrbits,
  ]);

  if (currentPage) return null;

  return (
    <>
      <Dialog
        open={props.show}
        onClose={props.onClose}
        fullWidth={true}
        aria-labelledby="max-width-dialog-title"
        maxWidth="md"
        PaperComponent={SpaceGlassContainerBase}
      >
        <ModalHeader
          title={`Importing ${props.objectToImport.OBJECT_NAME}`}
          onClose={props.onClose}
          icon={
            <SvgIcon
              component={SatelliteIcon}
              inheritViewBox
              fontSize="small"
            />
          }
        />

        <DialogContent max-width="fit-content">
          Create new notebook and import {props.objectToImport.OBJECT_NAME} from catalog?
        </DialogContent>

        <DialogActions>
          <Button onClick={props.onClose}>CANCEL</Button>

          <Button
            onClick={onSubmit}
            startIcon={<AddRounded />}
            variant="contained"
          >
            CREATE NOTEBOOK AND IMPORT
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
