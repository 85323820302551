import MontserratItalic from 'src/assets/fonts/Montserrat-Italic-VariableFont_wght.ttf';
import Montserrat from 'src/assets/fonts/Montserrat-VariableFont_wght.ttf';
import StaticMontserratBold from 'src/assets/fonts/Montserrat-Bold.ttf';
import StaticMontserratBoldItalic from 'src/assets/fonts/Montserrat-BoldItalic.ttf';
import StaticInterBoldItalic from 'src/assets/fonts/Inter-BoldItalic.woff';

export const FONTS = {
  Montserrat: Montserrat,
  MontserratItalic: MontserratItalic,
  StaticMontserratBold,
  StaticMontserratBoldItalic,
  StaticInterBoldItalic,
};
