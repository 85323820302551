import { useFrame } from '@react-three/fiber';
import { useMemo } from 'react';
import RaanMarkerHandle from 'src/threejs/components/Orbit/markers/RaanMarker/RaanMarkerHandle';
import { createGetOrbitRAAN } from 'src/threejs/components/OrbitManager/store/getters';
import { MarkerProps } from 'src/types';
import { Euler, Vector3 } from 'three';
import Marker from '../../../Marker';
import { use3DOrbitContext } from '../../context';

/** The RAAN marker */
const RaanMarker = ({ handle, setHandle, marker, setMarker }: MarkerProps) => {
  const { id } = use3DOrbitContext();
  const getOrbitRAAN = useMemo(() => createGetOrbitRAAN(id), [id]);

  const rotation = useMemo(() => new Euler(Math.PI / 2, 0, 0), []);

  const scale = useMemo(() => new Vector3(0.045, 0.045, 0.005), []);

  useFrame(() => {
    const { x, y, z } = getOrbitRAAN();

    if (marker) {
      marker.position.set(x, y, z);
    }
  });

  return (
    <>
      <Marker
        marker={marker}
        setMarker={setMarker}
        markerName="RAAN Marker"
        rotation={rotation}
        scale={scale}
      >
        <torusGeometry args={[1.1, 1, 3, 18]} />
        <meshBasicMaterial
          color={0x00ff00}
          transparent={true}
          opacity={0.35}
          depthWrite={true}
        />
      </Marker>
      <RaanMarkerHandle
        handle={handle}
        setHandle={setHandle}
      />
    </>
  );
};

export default RaanMarker;
