import useAppStore from 'src/core/store';
import { DismissNotificationAction } from 'src/threejs/hooks/useNotifications';

export const useApiSnackbarError = () => {
  const enqueueSnackbar = useAppStore((state) => state.snackbarEnqueue);

  return (message: string) => {
    if (enqueueSnackbar) {
      enqueueSnackbar(message, {
        action: DismissNotificationAction,
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'center',
        },
        preventDuplicate: true,
        variant: 'error',
      });
    }
  };
};
