import {
  ArrowBackIosNewRounded,
  ArrowForwardIosRounded,
  ControlCameraRounded,
} from '@mui/icons-material';
import { TabContext, TabList } from '@mui/lab';
import { Grid, IconButton } from '@mui/material';
import { SyntheticEvent, useCallback, useEffect, useState } from 'react';
import { ReactComponent as IconContact } from 'src/assets/icons/iconContact.svg';
import { ReactComponent as IconLiveData } from 'src/assets/icons/iconLiveData.svg';
import { ReactComponent as IconManeuvers } from 'src/assets/icons/iconManeuvers.svg';
import { ReactComponent as IconProperties } from 'src/assets/icons/iconProperties.svg';
import { ReactComponent as IconVisual } from 'src/assets/icons/iconVisual.svg';
import { ORBIT_TYPES } from 'src/constants';
import { usePlayState } from 'src/core/hooks';
import { useCurrentGroundObject } from 'src/hooks/GroundObjectHooks';
import { useCurrentGroup } from 'src/hooks/GroupHooks';
import { useCurrentOrbit } from 'src/hooks/OrbitHooks';
import { useIsReadOnly } from 'src/hooks/SharedNotebookHooks';
import theme, { COLOR_BORDER } from 'src/pages/App/Theme';
import {
  getActiveCapabilityId,
  getActiveSpecialId,
  useRouteStore,
} from 'src/pages/App/routes/store';
import { GroundObjectCategory } from 'src/types';
import { EarthLightingOptions } from '../EarthLightingOptions';
import { EarthVisualAidsOptions } from '../EarthVisualAidsOptions';
import { GroundObjectShaderColorPicker } from '../GroundObjectShaderColorPicker';
import { GroundObjectVisibilities } from '../GroundObjectVisibilities';
import { GroundObjectWireframeColorPicker } from '../GroundObjectWireframeColorPicker';
import { GroundTrackObjectSizeOptions } from '../GroundTrackObjectSizeOptions';
import { GroundTrackOptions } from '../GroundTrackOptions';
import { GroundTrackShapeOptions } from '../GroundTrackShapeOptions';
import { LiveDataPanel } from '../LiveDataPanel/LiveDataPanel';
import { LocationColorPicker } from '../LocationColorPicker';
import { ObjectColorPicker } from '../ObjectColorPicker';
import { OrbitTrackOptions } from '../OrbitTrackOptions';
import { OrbitVisualAidVisibilities } from '../OrbitVisualAidVisibilities';
import { SpaceObjectColorPicker } from '../SpaceObjectColorPicker';
import { SpaceObjectShapeOptions } from '../SpaceObjectShapeOptions';
import { SpaceObjectSizeOptions } from '../SpaceObjectSizeOptions';
import { SpaceSensorVisualOptions } from '../SpaceSensorVisualOptions';
import { TabPanelGroundObjectContact } from '../TabPanelGroundObjectContact';
import { TabPanelGroundObjectProperties } from '../TabPanelGroundObjectProperties';
import { TabPanelGroupProperties } from '../TabPanelGroupProperties';
import { TabPanelOrbitManeuvers } from '../TabPanelOrbitManeuvers';
import { TabPanelOrbitProperties } from '../TabPanelOrbitProperties';
import { TabPanelOrbitSensors } from '../TabPanelOrbitSensors';
import { InspectorTab } from './InspectorTab';
import { InspectorTabPanel } from './InspectorTabPanel';
import useAppStore from 'src/core/store';

export const InspectorTabs = () => {
  const currentGroundObject = useCurrentGroundObject();
  const currentGroup = useCurrentGroup();
  const currentOrbit = useCurrentOrbit();
  const playState = usePlayState();
  const isReadOnly = useIsReadOnly();
  const capabilityId = useRouteStore(getActiveCapabilityId);
  const specialId = useRouteStore(getActiveSpecialId);

  const [hasTabProperties, setHasTabProperties] = useState(false);
  const [hasTabVisual, setHasTabVisual] = useState(false);
  const [hasTabLiveData, setHasTabLiveData] = useState(false);
  const [hasTabManeuvers, setHasTabManeuvers] = useState(false);
  const [hasTabContact, setHasTabContact] = useState(false);
  const [hasTabSensor, setHasTabSensor] = useState(false);

  const [tabValue, setTabValue] = useState(isReadOnly ? 'liveData' : 'properties');
  const [isExpanded, setIsExpanded] = useState(false);

  const handleChange = useCallback(
    (event: SyntheticEvent, newValue: string) => {
      if (!isReadOnly) {
        setTabValue(newValue);
      }
      const { setActiveInspectorTab } = useAppStore.getState();
      setActiveInspectorTab(newValue);
    },
    [isReadOnly],
  );

  const handleExpandCLick = useCallback(() => {
    setIsExpanded(!isExpanded);
  }, [isExpanded]);

  useEffect(() => {
    setHasTabProperties(!!(currentOrbit || currentGroup || currentGroundObject));
  }, [currentOrbit, currentGroup, currentGroundObject]);

  useEffect(() => {
    setHasTabVisual(!!(currentOrbit || specialId || currentGroundObject));
  }, [currentOrbit, specialId, currentGroundObject]);

  useEffect(() => {
    setHasTabLiveData(!!currentOrbit);
  }, [currentOrbit]);

  useEffect(() => {
    setHasTabSensor(!!currentOrbit);
  }, [currentOrbit]);

  useEffect(() => {
    setHasTabManeuvers(
      !!currentOrbit &&
        [ORBIT_TYPES.COE, ORBIT_TYPES.STATE_VECTORS].includes(currentOrbit.orbitType),
    );
  }, [currentOrbit]);

  useEffect(() => {
    setHasTabContact(
      !!currentGroundObject &&
        [GroundObjectCategory.OTHER, GroundObjectCategory.SENSOR].includes(
          currentGroundObject.category,
        ),
    );
  }, [currentGroundObject]);

  // When switching to read only mode from a shared link, force live data view.
  useEffect(() => {
    if (tabValue === 'properties' && capabilityId) {
      setTabValue('liveData');
    }
  }, [tabValue, capabilityId]);

  useEffect(() => {
    if (hasTabLiveData) {
      if (playState === 'playing' || playState === 'loading') {
        setTabValue('liveData');
      }
    }
  }, [hasTabLiveData, playState]);

  useEffect(() => {
    if (currentOrbit?.id) {
      setTabValue('properties');
    }
  }, [currentOrbit?.id]);

  useEffect(() => {
    if (currentGroup?.id) {
      setTabValue('properties');
    }
  }, [currentGroup?.id]);

  useEffect(() => {
    if (currentGroundObject?.id) {
      setTabValue('properties');
    }
  }, [currentGroundObject?.id]);

  // when a special item is activated, switch to visual tab (only option for now)
  useEffect(() => {
    if (specialId) {
      setTabValue('visual');
    }
  }, [specialId]);

  return (
    <TabContext value={tabValue}>
      <Grid
        container
        display="grid"
        gridAutoFlow="column"
        gridAutoColumns="1fr auto"
        height="100%"
        overflow="hidden"
      >
        <Grid
          item
          sx={{ overflow: 'auto' }}
        >
          <InspectorTabPanel value="properties">
            {currentOrbit && <TabPanelOrbitProperties />}
            {currentGroup?.id && <TabPanelGroupProperties key={currentGroup.id} />}
            {currentGroundObject?.id && (
              <TabPanelGroundObjectProperties key={currentGroundObject.id} />
            )}
          </InspectorTabPanel>

          <InspectorTabPanel value="visual">
            {currentOrbit && (
              <>
                <ObjectColorPicker />
                <OrbitVisualAidVisibilities />

                <SpaceSensorVisualOptions />

                <GroundTrackOptions />
                <OrbitTrackOptions />
                <SpaceObjectShapeOptions />
                <SpaceObjectSizeOptions />
                <GroundTrackShapeOptions />
                <GroundTrackObjectSizeOptions />
                <SpaceObjectColorPicker />
              </>
            )}
            {currentGroundObject &&
              currentGroundObject.category === GroundObjectCategory.LOCATION && (
                <>
                  <LocationColorPicker />
                </>
              )}
            {currentGroundObject &&
              currentGroundObject.category !== GroundObjectCategory.LAUNCH_PAD &&
              currentGroundObject.category !== GroundObjectCategory.LOCATION && (
                <>
                  <GroundObjectVisibilities />
                  <GroundObjectShaderColorPicker />
                  <GroundObjectWireframeColorPicker />
                </>
              )}
            {specialId && (
              <>
                <EarthVisualAidsOptions />
                <EarthLightingOptions />
              </>
            )}
          </InspectorTabPanel>

          <InspectorTabPanel value="liveData">
            <LiveDataPanel />
          </InspectorTabPanel>

          <InspectorTabPanel value="maneuvers">
            <TabPanelOrbitManeuvers />
          </InspectorTabPanel>

          <InspectorTabPanel value="contact">
            <TabPanelGroundObjectContact />
          </InspectorTabPanel>

          <InspectorTabPanel value="sensors">
            <TabPanelOrbitSensors />
          </InspectorTabPanel>
        </Grid>

        <Grid
          item
          display="grid"
          gridAutoFlow="row"
          gridAutoRows="min-content 1fr"
          sx={{
            background: theme.palette.background.paper,
            borderLeft: `1px solid ${COLOR_BORDER}`,
          }}
        >
          <Grid
            item
            justifyContent="end"
            display="grid"
            m={1}
          >
            <IconButton onClick={handleExpandCLick}>
              {isExpanded ? <ArrowForwardIosRounded /> : <ArrowBackIosNewRounded />}
            </IconButton>
          </Grid>

          <TabList
            onChange={handleChange}
            orientation="vertical"
            TabIndicatorProps={{
              sx: {
                background: 'none',
              },
            }}
          >
            {hasTabProperties && (
              <InspectorTab
                value="properties"
                label="Properties"
                valueCurrentTab={tabValue}
                isExpanded={isExpanded}
                onChange={handleChange}
                icon={
                  <IconProperties
                    height="20"
                    width="20"
                  />
                }
              />
            )}

            {hasTabVisual && (
              <InspectorTab
                value="visual"
                label="Visual"
                valueCurrentTab={tabValue}
                isExpanded={isExpanded}
                onChange={handleChange}
                icon={
                  <IconVisual
                    height="20"
                    width="20"
                  />
                }
              />
            )}

            {hasTabLiveData && (
              <InspectorTab
                value="liveData"
                label="LiveData"
                valueCurrentTab={tabValue}
                isExpanded={isExpanded}
                onChange={handleChange}
                icon={
                  <IconLiveData
                    height="20"
                    width="20"
                  />
                }
              />
            )}

            {hasTabManeuvers && (
              <InspectorTab
                value="maneuvers"
                label="Maneuvers"
                valueCurrentTab={tabValue}
                isExpanded={isExpanded}
                onChange={handleChange}
                icon={
                  <IconManeuvers
                    height="20"
                    width="20"
                  />
                }
              />
            )}

            {hasTabContact && (
              <InspectorTab
                value="contact"
                label="Contact"
                valueCurrentTab={tabValue}
                isExpanded={isExpanded}
                onChange={handleChange}
                icon={
                  <IconContact
                    height="20"
                    width="20"
                  />
                }
              />
            )}

            {hasTabSensor && (
              <InspectorTab
                value="sensors"
                label="Sensors"
                valueCurrentTab={tabValue}
                isExpanded={isExpanded}
                onChange={handleChange}
                icon={
                  <ControlCameraRounded
                    height="20"
                    width="20"
                  />
                }
              />
            )}
          </TabList>
        </Grid>
      </Grid>
    </TabContext>
  );
};
