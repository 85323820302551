import { ExpandMoreRounded as ExpandMoreIcon } from '@mui/icons-material';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  FormControlLabel,
  FormGroup,
  Grid,
  Switch,
} from '@mui/material';
import { useCallback } from 'react';
import { useCurrentOrbit, useUpdateOrbitAdditionalProperties } from 'src/hooks/OrbitHooks';
import { OrbitAdditionalProperties } from 'src/types';

const ToggleSwitch = ({
  checked,
  onChange,
  label,
}: {
  checked: boolean;
  onChange: () => void;
  label: string;
}) => (
  <>
    <FormControlLabel
      control={
        <Switch
          size="small"
          checked={checked}
          onChange={onChange}
        />
      }
      label={label}
    />
  </>
);

export function OrbitVisualAidVisibilities() {
  const updateOrbitAdditionalProperties = useUpdateOrbitAdditionalProperties();
  const currentOrbit = useCurrentOrbit();

  const getVisibilityStatus = useCallback(
    (visualAidName: keyof OrbitAdditionalProperties): boolean => {
      if (!currentOrbit?.additionalProperties) return false;
      return (currentOrbit?.additionalProperties[visualAidName] as boolean) || false;
    },
    [currentOrbit?.additionalProperties],
  );

  const vectorVisualAids = Object.entries({
    Position: 'visPositionVec',
    Velocity: 'visVelocityVec',
    'Angular Momentum': 'visAngularMomentumVec',
    'Ascending Node': 'visAscendingNodeVec',
    Eccentricity: 'visEccentricityVec',
  } as Record<string, keyof OrbitAdditionalProperties>).map(
    ([label, optName]) =>
      [label, optName, getVisibilityStatus(optName)] as [
        string,
        keyof OrbitAdditionalProperties,
        boolean,
      ],
  );

  const nextVectorVisibility = useCallback(
    (optName: keyof OrbitAdditionalProperties, nextStatus: boolean) =>
      ({
        ...currentOrbit?.additionalProperties,
        [optName]: nextStatus,
      } as Partial<OrbitAdditionalProperties>),
    [currentOrbit?.additionalProperties],
  );

  if (!currentOrbit) {
    return null;
  }

  return (
    <Accordion defaultExpanded>
      <AccordionSummary
        style={{
          flexDirection: 'row-reverse',
        }}
        expandIcon={<ExpandMoreIcon />}
        aria-controls="inspector-panel-visual"
        id="inspector-panel-visual"
      >
        Visual Aid Vector Visibilities
      </AccordionSummary>
      <AccordionDetails>
        <Grid
          container
          direction="column"
          px={2}
        >
          {vectorVisualAids.map(([label, optName, status]) => (
            <FormGroup
              key={label}
              sx={{ paddingBottom: 1 }}
            >
              <ToggleSwitch
                label={`${label} Visibility`}
                checked={status}
                onChange={() =>
                  updateOrbitAdditionalProperties(
                    currentOrbit,
                    nextVectorVisibility(optName, !status),
                  )
                }
              />
            </FormGroup>
          ))}
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
}
