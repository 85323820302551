import { Slide } from '@mui/material';
import { useRef } from 'react';
import { LAUNCH_FLYOUT_STATUS } from 'src/constants';
import { useGroundObjectLaunchEditStore } from 'src/threejs/components/GroundObjects/GroundObjectLaunchEditStore';
import { LaunchEditor } from './LaunchEditor';

export const LaunchFlyoutContainer = () => {
  const containerRef = useRef(null);

  const launchFlyoutStatus = useGroundObjectLaunchEditStore((state) => state.isLaunchEditMode);

  return (
    <div
      className="LaunchFlyoutContainer"
      ref={containerRef}
      style={{
        position: 'fixed',
        top: '150px',
        right: '0%',
        zIndex: 1,
      }}
    >
      <Slide
        direction="left"
        in={launchFlyoutStatus !== LAUNCH_FLYOUT_STATUS.CLOSED}
        container={containerRef.current}
        mountOnEnter
        unmountOnExit
      >
        <div>
          <LaunchEditor />
        </div>
      </Slide>
    </div>
  );
};
