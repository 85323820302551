import { AddRounded, DeleteRounded, EditRounded } from '@mui/icons-material';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
} from '@mui/material';
import { capitalize } from 'lodash';
import { DateTime } from 'luxon';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { SpaceGlassContainerBase } from 'src/components/SpaceGlassControl';
import { LABModalAction, LABModalActionType, LABObject, LABObjectType } from 'src/enums';
import { onChangeObjectName } from 'src/hooks/ModalObjectOps';
import {
  ObjectUpdate,
  useCopyFolderOrNotebook,
  useCreateObjectMutation,
  useDeleteObjectMutation,
  useUpdateObjectMutation,
} from 'src/hooks/ObjectHooks';
import { useCreateOrbitDefaultMutation } from 'src/hooks/OrbitHooks';
import { usePagesForNotebookQuery } from 'src/hooks/PageHooks';
import { ConfirmCopyModal } from 'src/pages/App/components/MakeCopyButton';
import { ModalHeader } from 'src/pages/Shared/ModalSpaceGlass';
import { CurrentFolder, Folder, Notebook } from 'src/types';

type FolderModalProps = {
  action: LABModalActionType;
  parent: CurrentFolder;
  onClose: () => void;
  onSuccess: (data: any) => void;
  selected: Folder | Notebook | null;
};

type ConfirmModalProps = {
  open: boolean;
  onClose: () => void;
  onSubmit: () => Promise<any>;
};

function ConfirmModal({ open, onClose, onSubmit }: ConfirmModalProps) {
  const [failed, setFailed] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    setFailed(false);
    setLoading(false);
  }, [open, setFailed, setLoading]);

  const handleSubmit = async () => {
    setLoading(true);
    try {
      await onSubmit();
      setLoading(false);
      onClose();
    } catch (err) {
      setLoading(false);
      setFailed(true);
    }
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullWidth={true}
      aria-labelledby="max-width-dialog-confirm-delete "
      data-test="confirm-delete-modal"
      PaperComponent={SpaceGlassContainerBase}
    >
      <ModalHeader
        onClose={onClose}
        title={failed ? 'Error' : 'Are You Sure?'}
        icon={<DeleteRounded />}
      />
      <DialogContent>
        {loading && 'loading'}
        {!loading &&
          (failed ? 'Something went wrong. Try again later' : 'All content will be lost forever')}
      </DialogContent>
      <DialogActions>
        <Button
          disabled={loading}
          onClick={onClose}
        >
          CANCEL
        </Button>
        {!loading && !failed && (
          <Button
            onClick={handleSubmit}
            color="error"
            variant="contained"
          >
            DELETE FOREVER
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
}

/**
 * Modal PopUp For File Object
 */
export function FolderModal({ action, parent, onClose, onSuccess, selected }: FolderModalProps) {
  const navigate = useNavigate();

  const deleteObjectMutation = useDeleteObjectMutation();
  const createObjectMutation = useCreateObjectMutation();
  const updateObjectMutation = useUpdateObjectMutation();
  const copyMutation = useCopyFolderOrNotebook();

  const [open, setOpen] = useState<boolean>(false);
  const [deleteMode, setDeleteMode] = useState<boolean>(false);
  const [title, setTitle] = useState<string>('None');
  const [submitTitle, setSubmitText] = useState('Submit');

  // Default name format - Notebook {mm/dd/yy hh:mm}
  const date = DateTime.local();
  const tdStr = date.toFormat('D HH:mm');

  // Folder Object Data
  const [objType, setType] = useState<LABObjectType | null>(null);
  const [parentFolderId, setParentFolderId] = useState<number | null>(null);
  const [name, setName] = useState<string | null>(null);

  const [showType, setShowType] = useState<boolean>(false);
  const [showParentProps, setShowParent] = useState<boolean>(false);
  const [showDelete, setShowDelete] = useState<boolean>(false);

  const typeList = [LABObject.NOTEBOOK, LABObject.FOLDER];

  const isDirty = useMemo(() => {
    if (selected?.name !== name) {
      return true;
    }
    if (parent?.id !== parentFolderId) return true;

    return false;
  }, [selected, parent, name, parentFolderId]);

  const nameError = useMemo(() => {
    if (name?.length === 0) return true;

    return false;
  }, [name]);

  /**
   * Initialises The Modal State Based On The Action Type
   */
  useEffect(() => {
    setType(LABObject.NOTEBOOK);
    setParentFolderId(parent ? parent.id : null);

    if (action !== LABModalAction.NULL) {
      setTitle(action);
      setSubmitText(LABModalAction.EDIT ? 'Save' : action);
      setOpen(true);
      setShowType(action === LABModalAction.CREATE);
      setShowParent(parent.type === LABObject.FOLDER);
      setShowDelete(action === LABModalAction.EDIT);
    } else {
      setOpen(false);
    }

    switch (action) {
      case LABModalAction.CREATE:
        setName(`Notebook ${tdStr}`);
        break;

      case LABModalAction.EDIT:
        if (selected) {
          setName(selected.name);
          setType(selected.type);
        }
        break;

      case LABModalAction.COPY:
        break;

      case LABModalAction.NULL:
      default:
        break;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [action]);

  const getPages = usePagesForNotebookQuery();
  const createDefaultOrbitMutation = useCreateOrbitDefaultMutation();

  // Submits The Data From The Form
  const onSubmit = async () => {
    if (action === LABModalAction.COPY && selected) {
      try {
        const result = await copyMutation.mutateAsync(selected.id);
        onSuccess(result);
      } catch (err) {
        navigate('/');
        onClose();
      }
    } else if (name && objType) {
      let result;

      switch (action) {
        case LABModalAction.CREATE:
          result = await createObjectMutation.mutateAsync({
            parentId: parent.id,
            type: objType,
            name,
            notes: 'Notes',
          });
          if (objType === LABObject.NOTEBOOK && result?.updates) {
            const [update] = result.updates;
            const [newPage] = await getPages.mutateAsync(update.objectId);
            if (!newPage) return;

            const newPageId = newPage.id;
            const res = await createDefaultOrbitMutation.mutateAsync({
              requestedPageId: newPageId,
            });

            navigate(`/notebook/${update.objectId}/${newPageId}/${res.orbits[0]}`);
          }
          onSuccess(result);
          break;

        case LABModalAction.EDIT:
          if (selected) {
            const updates: ObjectUpdate = {
              objectId: selected.id,
              type: objType,
              notes: 'Notes',
              name,
            };
            if (parentFolderId && parentFolderId !== parent.id) {
              updates.previousObjectId = parentFolderId;
            }
            result = updateObjectMutation.mutateAsync(updates);
            onSuccess(result);
          }
          break;
      }
    }
  };

  // Deletes The Object
  const onDelete = async () => {
    if (selected) {
      const result = await deleteObjectMutation.mutateAsync({ id: selected.id });
      onSuccess(result);
    }
  };

  // Sets The Folder Object's Type And Default Name
  const setFolderObjectType = (
    event: React.ChangeEvent<{
      name?: string;
      value: unknown;
    }>,
  ) => {
    setType(event.target.value as LABObjectType);
    setName(event.target.value === LABObject.NOTEBOOK ? `Notebook ${tdStr}` : `Folder ${tdStr}`);
  };

  const renderEditModeContent = () => (
    <DialogContent>
      {/* Object Type */}
      {showType && (
        <FormControl
          fullWidth
          data-test="object-type-select"
          sx={{
            mt: 1,
            mb: 2,
          }}
        >
          <FormLabel>Type</FormLabel>
          <RadioGroup
            value={objType}
            onChange={setFolderObjectType}
            aria-label="object-type"
            name="object-type-radio"
            sx={{
              pl: 1.5,
            }}
          >
            {typeList.map((type, index) => {
              return (
                <FormControlLabel
                  key={`object-type-${type}`}
                  value={type}
                  label={capitalize(type)}
                  control={<Radio />}
                />
              );
            })}
          </RadioGroup>
        </FormControl>
      )}

      {/* Parent Object */}
      {showParentProps && (
        <FormControl
          fullWidth
          data-test="object-type-select"
          variant="standard"
          sx={{
            mt: 1,
            mb: 2,
          }}
        >
          <InputLabel>Parent Folder</InputLabel>
          <Select
            value={parentFolderId}
            onChange={(e) => setParentFolderId(Number(e.target.value as string))}
          >
            <MenuItem
              key="CurrentFolder"
              value={parent ? parent.id : -1}
            >
              Current
            </MenuItem>
            {parent?.children
              ?.filter((folder) => folder.type === LABObject.FOLDER && folder.id !== selected?.id) //don't show an option for self
              .map((folder) => {
                return (
                  <MenuItem
                    key={folder.name}
                    value={folder.id}
                  >
                    {folder.name}
                  </MenuItem>
                );
              })}
          </Select>
        </FormControl>
      )}

      {/* Name */}
      <FormControl
        fullWidth
        data-test="object-type-select"
        sx={{
          mt: 1,
          mb: 2,
        }}
      >
        <TextField
          variant="standard"
          error={nameError}
          id="filled-error"
          label="Name"
          value={name || ''}
          helperText={nameError ? 'Create a valid name' : null}
          onChange={(event) => {
            onChangeObjectName(event, setName);
          }}
        />
        <FormHelperText id="folderObjectNameHelper"></FormHelperText>
      </FormControl>
    </DialogContent>
  );

  const actionDisabled = useMemo(() => {
    return nameError || (LABModalAction.EDIT && !isDirty);
  }, [nameError, isDirty]);

  const modalBody = (() => {
    return renderEditModeContent();
  })();

  const getIcon = useCallback((iconType: LABModalActionType) => {
    let modalIcon = null;
    switch (iconType) {
      case LABModalAction.DELETE:
        modalIcon = <DeleteRounded />;
        break;
      case LABModalAction.EDIT:
        modalIcon = <EditRounded />;
        break;
      case LABModalAction.CREATE:
        modalIcon = <AddRounded />;
        break;
      default:
        break;
    }
    return modalIcon;
  }, []);

  const onDeleteClick = useCallback(() => setDeleteMode(true), []);

  return action === LABModalAction.COPY ? (
    <ConfirmCopyModal
      onClose={onClose}
      onSubmit={onSubmit}
      open={open && !deleteMode}
    />
  ) : (
    <>
      <Dialog
        open={open && !deleteMode}
        onClose={onClose}
        fullWidth={true}
        aria-labelledby="max-width-dialog-title"
        data-test="folder-modal"
        transitionDuration={0}
        PaperComponent={SpaceGlassContainerBase}
        onKeyPress={(event) => {
          if (event.key === 'Enter' && !nameError) {
            onSubmit();
          }
        }}
      >
        <ModalHeader
          title={title}
          onClose={onClose}
          icon={getIcon(action)}
        />
        {modalBody}
        {/* Actions */}
        <DialogActions>
          {/* Delete */}
          {showDelete && (
            <FormControl style={{ marginRight: 'auto' }}>
              <Button
                onClick={onDeleteClick}
                startIcon={<DeleteRounded />}
              >
                DELETE
              </Button>
            </FormControl>
          )}
          <Button onClick={onClose}>CANCEL</Button>
          <Button
            onClick={onSubmit}
            variant="contained"
            color="primary"
            data-test="folder-modal-confirm-button"
            disabled={actionDisabled}
          >
            {submitTitle}
          </Button>
        </DialogActions>
      </Dialog>
      <ConfirmModal
        open={deleteMode}
        onClose={() => setDeleteMode(false)}
        onSubmit={onDelete}
      />
    </>
  );
}
