import { ExpandMoreRounded as ExpandMoreIcon } from '@mui/icons-material';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  Switch,
  Typography,
} from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import { formatDuration, intervalToDuration } from 'date-fns';
import { useEffect, useMemo, useState } from 'react';
import { GROUND_OBJECT_TOOLTIP_INFO, ORBIT_TYPES } from 'src/constants';
import useAppStore from 'src/core/store';
import { useCurrentGroundObject, useUpdateGroundObject } from 'src/hooks/GroundObjectHooks';
import { useCurrentOrbits, useIsPropagating } from 'src/hooks/OrbitHooks';
import { useCurrentPage } from 'src/hooks/PageHooks';
import PropagatedCacheManager from 'src/models/PropagatedCacheManager';
import theme from 'src/pages/App/Theme';
import { LabelWithTooltip } from 'src/pages/Shared/LabelWithTooltip';
import { GroundObject, ViewingWindow } from 'src/types';
import { millisToLocale } from 'src/utilities/DateTimeUtils';
import { ModalViewingWindows } from './ModalViewingWindows';
import { OBJECT_TYPES } from './ObjectListPanel';
import { ObjectPanelDisplay } from './ObjectPanelDisplay';
import { ObjectSummaryBody } from './ObjectPanelDisplay.styled';
import { SelectGroupOrObject, SelectGroupOrObjectItem } from './SelectGroupOrObject';
import { WINDOWS_STATUS, WINDOWS_TYPES, useViewingWindowsStore } from './ViewingWindowsStore';

const ContactToggle = ({
  checked,
  onChange,
  label,
  tooltipTitle,
}: {
  checked: boolean;
  onChange: () => void;
  label: string;
  tooltipTitle: string;
}) => (
  <>
    <FormControlLabel
      sx={{ m: 0 }}
      control={
        <Switch
          size="small"
          checked={checked}
          onChange={onChange}
        />
      }
      label={
        <LabelWithTooltip
          label={label}
          title={tooltipTitle}
        />
      }
    />
  </>
);

const GroundObjectViewingWindows = ({
  currentGroundObject,
}: {
  currentGroundObject: GroundObject;
}) => {
  const isFetching = useViewingWindowsStore(
    (state) =>
      state.groundObjectWindows[currentGroundObject.id].status !== WINDOWS_STATUS.COMPLETED,
  );
  const viewingWindows = useViewingWindowsStore(
    (state) => state.groundObjectWindows[currentGroundObject.id].windows || [],
  );

  const currentOrbits = useCurrentOrbits();
  const currentPage = useCurrentPage();

  const isPropagating = useIsPropagating();

  const [contactWindowOpen, setContactWindowOpen] = useState(false);
  if (!viewingWindows?.length) return null;

  const {
    timelines: { updateTimelineRange },
  } = useAppStore.getState();

  return (
    <>
      <Grid
        display="grid"
        justifyContent="center"
      >
        <ModalViewingWindows
          show={contactWindowOpen}
          onClose={() => {
            setContactWindowOpen(false);
          }}
          type={WINDOWS_TYPES.GROUND_OBJECT}
          object={currentGroundObject}
          viewingWindows={viewingWindows}
        />

        <Button
          size="small"
          variant="contained"
          onClick={() => setContactWindowOpen(true)}
        >
          Generate Text report
        </Button>
      </Grid>
      {isFetching ? (
        <CircularProgress />
      ) : (
        viewingWindows.map((win: ViewingWindow, index) => {
          const dur = intervalToDuration({
            start: win.startTime,
            end: win.endTime,
          });
          const displayedOrbit = currentOrbits?.find((orb) => orb.id === win.orbitId);
          return (
            <ObjectPanelDisplay
              key={`${win.startTime}-${win.endTime}`}
              headerRow={millisToLocale(win.startTime.getTime(), 'utc')}
              titleRow={`Window ${index + 1}`}
              accentColor={win.color || 'white'}
              props={{
                onClick: async () => {
                  if (!isPropagating && currentPage && currentOrbits) {
                    await PropagatedCacheManager.propagateTimeline(
                      currentPage,
                      currentOrbits,
                      true,
                    );
                  }
                  updateTimelineRange({
                    playState: 'seeking',
                    currentTime: win.startTime.getTime(),
                    currentTimePreviewMode: win.startTime.getTime(),
                  });
                },
                style: { cursor: 'pointer' },
              }}
            >
              {displayedOrbit && (
                <Typography color="white">Object: {displayedOrbit.name}</Typography>
              )}
              <ObjectSummaryBody>
                Azimuth Range:
                {` ${win.startAzimuth}`} - {win.endAzimuth} Deg
              </ObjectSummaryBody>
              <ObjectSummaryBody>
                Max Elevation Angle: {win.maxElevationAngle} Deg
              </ObjectSummaryBody>
              <ObjectSummaryBody>
                End: {millisToLocale(win.endTime.getTime(), 'utc')}
              </ObjectSummaryBody>
              <ObjectSummaryBody>Duration: {formatDuration(dur)}</ObjectSummaryBody>
            </ObjectPanelDisplay>
          );
        })
      )}
    </>
  );
};

export const TabPanelGroundObjectContact = () => {
  const currentGroundObject = useCurrentGroundObject();

  const currentOrbits = useCurrentOrbits();

  const updateGroundObj = useUpdateGroundObject();

  const currentTargetIllumination = useMemo(
    () => currentGroundObject?.eventProperties.TARGET_ILLUMINATED || false,
    [currentGroundObject?.eventProperties.TARGET_ILLUMINATED],
  );

  const currentGroundObjDarkness = useMemo(
    () => currentGroundObject?.eventProperties.GROUND_OBJECT_REQUIRES_DARKNESS || false,
    [currentGroundObject?.eventProperties.GROUND_OBJECT_REQUIRES_DARKNESS],
  );

  const nextTargetIllumination: Partial<GroundObject> = useMemo(
    () => ({
      id: currentGroundObject?.id,
      eventProperties: {
        TARGET_ILLUMINATED: !currentTargetIllumination,
        GROUND_OBJECT_REQUIRES_DARKNESS: currentGroundObjDarkness,
      },
    }),
    [currentGroundObject?.id, currentTargetIllumination, currentGroundObjDarkness],
  );

  const nextGroundObjDarkness: Partial<GroundObject> = useMemo(
    () => ({
      id: currentGroundObject?.id,
      eventProperties: {
        TARGET_ILLUMINATED: currentTargetIllumination,
        GROUND_OBJECT_REQUIRES_DARKNESS: !currentGroundObjDarkness,
      },
    }),
    [currentGroundObject?.id, currentTargetIllumination, currentGroundObjDarkness],
  );

  const [contactHasEphemeris, setContactHasEphemeris] = useState(false);
  useEffect(() => {
    const hasEphemeris = (arrOrbitIds: number[]) => {
      return currentOrbits?.some((orbit) => {
        return arrOrbitIds.includes(orbit.id) && orbit.orbitType === ORBIT_TYPES.EPHEMERIS;
      });
    };
    if (currentGroundObject?.targetOrbitId) {
      setContactHasEphemeris(!!hasEphemeris([currentGroundObject?.targetOrbitId]));
    } else if (currentGroundObject?.targetGroupId) {
      setContactHasEphemeris(
        !!hasEphemeris(
          currentOrbits
            ?.filter((orbit) => orbit.groupId === currentGroundObject.targetGroupId)
            .map((orbit) => orbit.id) || [],
        ),
      );
    }
  }, [currentOrbits, currentGroundObject?.targetGroupId, currentGroundObject?.targetOrbitId]);

  const selectedItem = useMemo(() => {
    if (currentGroundObject?.targetGroupId) {
      return {
        type: OBJECT_TYPES.GROUP,
        id: currentGroundObject.targetGroupId,
      };
    } else if (currentGroundObject?.targetOrbitId) {
      return {
        type: OBJECT_TYPES.ORBIT,
        id: currentGroundObject.targetOrbitId,
      };
    }
    return null;
  }, [currentGroundObject?.targetOrbitId, currentGroundObject?.targetGroupId]);

  const onTargetChange = (result: SelectGroupOrObjectItem) => {
    const requestObj: Partial<GroundObject> = {
      id: currentGroundObject?.id,
      targetGroupId: null,
      targetOrbitId: null,
    };

    if (result.type === OBJECT_TYPES.GROUP) {
      requestObj.targetGroupId = Number(result.id);
    } else if (result.type === OBJECT_TYPES.ORBIT) {
      requestObj.targetOrbitId = Number(result.id);
    }
    updateGroundObj.mutate(requestObj);
  };

  if (!currentGroundObject) return <div>No ground object select</div>;

  return (
    <FormControl
      variant="standard"
      fullWidth
    >
      <Accordion defaultExpanded>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          style={{
            flexDirection: 'row-reverse',
          }}
          aria-controls="inspector-panel-details"
          id="inspector-panel-details"
        >
          Target:
        </AccordionSummary>
        <AccordionDetails sx={{ px: 2 }}>
          <FormControl
            variant="standard"
            fullWidth
          >
            <SelectGroupOrObject
              includeGroups
              includeOrbits
              selectedItem={selectedItem}
              onChange={onTargetChange}
            />
          </FormControl>

          {contactHasEphemeris && (
            <Typography
              color={theme.palette.warning.dark}
              pt={1}
            >
              Target contains orbit(s) with ephemeris data, these will not be considered in viewing
              window calculations.
            </Typography>
          )}
        </AccordionDetails>
      </Accordion>
      <Accordion defaultExpanded>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          style={{
            flexDirection: 'row-reverse',
          }}
          aria-controls="inspector-panel-details"
          id="inspector-panel-details"
        >
          Viewing Constraints:
        </AccordionSummary>
        <AccordionDetails sx={{ px: 2 }}>
          <FormGroup>
            <ContactToggle
              label="Target Illuminated"
              tooltipTitle={GROUND_OBJECT_TOOLTIP_INFO.targetIlluminationInfo}
              checked={currentTargetIllumination}
              onChange={() => updateGroundObj.mutate(nextTargetIllumination)}
            />
            <ContactToggle
              label="Ground Station in Darkness"
              tooltipTitle={GROUND_OBJECT_TOOLTIP_INFO.groundObjectDarkness}
              onChange={() => updateGroundObj.mutate(nextGroundObjDarkness)}
              checked={currentGroundObjDarkness}
            />
          </FormGroup>
        </AccordionDetails>
      </Accordion>
      <Accordion defaultExpanded>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          style={{
            flexDirection: 'row-reverse',
          }}
          aria-controls="inspector-panel-details"
          id="inspector-panel-details"
        >
          Viewing Windows:
        </AccordionSummary>
        <AccordionDetails sx={{ px: 2 }}>
          {currentGroundObject.targetGroupId || currentGroundObject.targetOrbitId ? (
            <GroundObjectViewingWindows currentGroundObject={currentGroundObject} />
          ) : (
            <Grid textAlign="center">Select a target to show viewing windows</Grid>
          )}
        </AccordionDetails>
      </Accordion>
    </FormControl>
  );
};
