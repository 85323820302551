import { Breadcrumbs, Grid, Link } from '@mui/material';
import { useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { highContrast } from 'src/pages/App/Theme';
import { CurrentFolder, Path } from 'src/types';

type FolderBreadcrumbsProps = {
  folder: CurrentFolder;
  onChangeFolder: (id: number) => void;
};

const ROOT_FOLDER_PERSONAL: CurrentFolder = {
  id: -1,
  type: 'FOLDER',
  name: 'Personal',
  path: [],
  children: [],
};
const ROOT_FOLDER_SHARED: CurrentFolder = {
  id: -2,
  type: 'FOLDER',
  name: 'Shared',
  path: [],
  children: [],
};

/**
 * Breadcrumbs component for folder view
 * @param {*} props
 */
export const FolderBreadcrumbs = ({ folder, onChangeFolder }: FolderBreadcrumbsProps) => {
  const navigate = useNavigate();
  const location = useLocation();

  const inSharedFolder = useMemo(
    () => location.pathname.startsWith('/shared'),
    [location.pathname],
  );

  /**
   * remove the initial personal item and replace with either shared or custom personal path
   */
  const pathItems = [...folder.path];
  pathItems.shift();
  if (inSharedFolder) {
    pathItems.unshift(ROOT_FOLDER_SHARED);
  } else {
    pathItems.unshift(ROOT_FOLDER_PERSONAL);
  }

  return (
    <Grid
      display="grid"
      gridAutoFlow="column"
      alignItems="center"
      gap={1}
      sx={{
        mt: 2,
        mb: 1,
      }}
    >
      <Breadcrumbs aria-label="breadcrumb">
        {pathItems.map((breadCrumb: Path) => {
          return (
            <Link
              key={breadCrumb.id}
              onClick={(event) => {
                // event.preventDefault();
                // Link to root folder (Personal Lab).
                if (breadCrumb.id === ROOT_FOLDER_PERSONAL.id) {
                  return navigate('/');
                } else if (breadCrumb.id === ROOT_FOLDER_SHARED.id) {
                  return navigate('/shared');
                } else {
                  onChangeFolder(breadCrumb.id);
                }
              }}
              underline="hover"
              sx={{
                color: highContrast.palette.text.secondary,
                cursor: 'pointer',
              }}
            >
              {breadCrumb.name}
            </Link>
          );
        })}
        {folder && (
          <Link
            onClick={() => {
              onChangeFolder(folder.id);
            }}
            underline="hover"
            sx={{
              color: highContrast.palette.text.secondary,
              cursor: 'pointer',
            }}
          >
            {folder.name}
          </Link>
        )}
      </Breadcrumbs>
    </Grid>
  );
};
