import { styled } from '@mui/material';

export const Root = styled('div')`
  background: ${(props) => props.theme.palette.background.default};
  color: ${(props) => props.theme.palette.text.primary};
  height: 100vh;
  width: 100%;
  display: grid;
  grid-template-rows: min-content minmax(0, 1fr);
  overflow: auto;

  &.withDevTools {
    grid-template-rows: min-content minmax(0, 3fr) minmax(0, 2fr);
  }
`;
