import { COLOR_SLINGSHOT_ORANGE } from 'src/constants';
import { ViewingWindow } from 'src/types';
import tinycolor from 'tinycolor2';
import { create } from 'zustand';
import { immer } from 'zustand/middleware/immer';

export const WINDOWS_STATUS = {
  REQUESTED: 'requested',
  COMPLETED: 'completed',
  INVALIDATED: 'invalidated',
};

export const WINDOWS_TYPES = {
  GROUND_OBJECT: 'ground_object',
  SPACE_SENSOR: 'space_sensor',
};

export type WindowType = (typeof WINDOWS_TYPES)[keyof typeof WINDOWS_TYPES];

type ContactWindows = {
  status: string;
  windows: ViewingWindow[];
  targetGroupId: Array<number | null>; // make it easier to invalidate if something in group changes
  targetObjectId: Array<number | null>; // to help invalidation of target object changes
};

interface ViewingWindowsStoreState {
  groundObjectWindows: Record<number, ContactWindows>;
  spaceSensorWindows: Record<number, ContactWindows>;

  initWindows: (
    type: WindowType,
    id: number,
    targetGroupId: Array<number | null>,
    targetObjectId: Array<number | null>,
  ) => void;
  setWindows: (type: WindowType, id: number, viewingWindows: ViewingWindow[]) => void;
  removeWindows: (type: WindowType, id: number) => void;
  invalidateWindows: (type: WindowType, id: number) => void;
  invalidateWindowsAll: (type?: WindowType) => void;
  invalidateWindowsAllByGroupId: (type: WindowType, groupId: number | null) => void;
  invalidateWindowsAllByObjectid: (type: WindowType, objectId: number | null) => void;
}

export const useViewingWindowsStore = create<ViewingWindowsStoreState>()(
  immer((set, get) => ({
    groundObjectWindows: {},
    spaceSensorWindows: {},

    initWindows: (type, id, targetGroupId, targetObjectId) => {
      set((state) => {
        const cache =
          type === WINDOWS_TYPES.GROUND_OBJECT
            ? state.groundObjectWindows
            : state.spaceSensorWindows;

        cache[id] = {
          status: WINDOWS_STATUS.REQUESTED,
          windows: [],
          targetGroupId: targetGroupId,
          targetObjectId: targetObjectId,
        };
      });
    },
    setWindows: (type, id, viewingWindows) => {
      set((state) => {
        const cache =
          type === WINDOWS_TYPES.GROUND_OBJECT
            ? state.groundObjectWindows
            : state.spaceSensorWindows;

        deserializeViewingWindows(viewingWindows).forEach((viewingWindow) => {
          const windows = [...cache[id].windows, viewingWindow];
          windows.sort((a, b) => a.startTime.getTime() - b.startTime.getTime());
          cache[id].windows = windows;
        });
        cache[id].status = WINDOWS_STATUS.COMPLETED;
      });
    },
    removeWindows: (type, id) => {
      set((state) => {
        const cache =
          type === WINDOWS_TYPES.GROUND_OBJECT
            ? state.groundObjectWindows
            : state.spaceSensorWindows;

        delete cache[id];
      });
    },
    invalidateWindows: (type, id) => {
      set((state) => {
        const cache =
          type === WINDOWS_TYPES.GROUND_OBJECT
            ? state.groundObjectWindows
            : state.spaceSensorWindows;

        if (cache[id]) {
          cache[id].status = WINDOWS_STATUS.INVALIDATED;
        }
      });
    },
    invalidateWindowsAll: (type) => {
      set((state) => {
        if (!type || type === WINDOWS_TYPES.GROUND_OBJECT) {
          Object.values(state.groundObjectWindows).forEach((value) => {
            value.status = WINDOWS_STATUS.INVALIDATED;
          });
        }
        if (!type || type === WINDOWS_TYPES.SPACE_SENSOR) {
          Object.values(state.spaceSensorWindows).forEach((value) => {
            value.status = WINDOWS_STATUS.INVALIDATED;
          });
        }
      });
    },
    invalidateWindowsAllByGroupId(type, groupId) {
      set((state) => {
        const cache =
          type === WINDOWS_TYPES.GROUND_OBJECT
            ? state.groundObjectWindows
            : state.spaceSensorWindows;

        Object.values(cache).forEach((value) => {
          if (value.targetGroupId.includes(groupId)) {
            value.status = WINDOWS_STATUS.INVALIDATED;
          }
        });
      });
    },
    invalidateWindowsAllByObjectid(type, objectId) {
      set((state) => {
        const cache =
          type === WINDOWS_TYPES.GROUND_OBJECT
            ? state.groundObjectWindows
            : state.spaceSensorWindows;

        Object.values(cache).forEach((value) => {
          if (value.targetObjectId.includes(objectId)) {
            value.status = WINDOWS_STATUS.INVALIDATED;
          }
        });
      });
    },
  })),
);

const deserializeViewingWindows = (viewingWindows: ViewingWindow[]): ViewingWindow[] => {
  const initialColor = COLOR_SLINGSHOT_ORANGE;

  return viewingWindows?.map((viewingWindow, index) => {
    const windowColor = tinycolor(initialColor)
      .spin((index / viewingWindows.length) * 360)
      .desaturate(50)
      .toString();

    return {
      ...viewingWindow,
      startTime: new Date(viewingWindow.startTime),
      endTime: new Date(viewingWindow.endTime),
      maxElevationAngleTime: new Date(viewingWindow.maxElevationAngleTime),
      color: windowColor,
    };
  });
};
