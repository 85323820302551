/**
 * Checks to see in an element exists in an array
 */
export function ExistsInArray<A = any>(arr: A[], comparer: A): boolean {
  if (arr === undefined || arr.length === 0) {
    return false;
  }

  for (const element of arr) {
    if (element === comparer) {
      return true;
    }
  }
  return false;
}

/**
 * Adds an element to the array if it does not already exist
 */
export function AddToArray<A = any>(arr: A[], element: A): A[] {
  if (arr === undefined) {
    return [element];
  }

  if (ExistsInArray(arr, element)) {
    return arr;
  }

  arr.push(element);
  return arr;
}

/**
 * Removes an element from array
 */
export function RemoveFromArray<A = any>(arr: A[], elements: A[]): A[] {
  let element = null;
  let L = elements.length;
  let ax;

  if (arr === undefined || arr.length === 0) {
    return arr;
  }

  while (arr.length && L) {
    element = elements[--L];
    ax = arr.indexOf(element);
    while (ax !== -1) {
      arr.splice(ax as unknown as number, 1);
      ax = arr.indexOf(element);
    }
  }

  return arr;
}

/**
 * Compares two objects by a key value
 */
function descendingComparator(
  a: Record<string, any>,
  b: Record<string, any>,
  orderBy: string,
): number {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

/**
 * Returns the comparator by asc or desc
 */
export function getComparator(order: 'asc' | 'desc', orderBy: string) {
  return order === 'desc'
    ? (a: Record<string, any>, b: Record<string, any>) => descendingComparator(a, b, orderBy)
    : (a: Record<string, any>, b: Record<string, any>) => -descendingComparator(a, b, orderBy);
}

/**
 * Sorts an array by a key value
 */
export function stableSort<A = any>(array: A[], comparator: (a: A, b: A) => number): A[] {
  const stabilizedThis: [A, number][] = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

/**
 * Returns items that are unique to the first array
 * similar to a left join in a relational db
 */
export function leftJoin(left: any[], right: any[], comparer?: string) {
  const key = comparer ?? 'id';

  return left.filter((leftItem: any) => {
    return !right.some((rightItem: any) => leftItem[key] === rightItem[key]);
  });
}

/**
 * Sort functions to take an array of objects with name property and sorty it by locale
 */
export const sortByName = <T extends { name: string }>(a: T, b: T) => a.name.localeCompare(b.name);
export function sortByNameAlphabetical<T extends { name: string }>(obj: T[]) {
  return obj.sort(sortByName);
}

/**
 * Toggles a value's presence in an array.
 */
export function addOrRemove<A = any>(arr: A[], value: any): any[] {
  if (arr === undefined) {
    return [value];
  }

  return arr.includes(value) ? arr.filter((a: A) => a !== value) : [...arr, value];
}
