import React, { useRef } from 'react';
import { DEFAULT_ADDITIONAL_PROPERTIES_PAGE } from 'src/constants';
import { useCurrentPage } from 'src/hooks/PageHooks';
import { EARTH_LIGHTING } from 'src/pages/Notebook/components/EarthLightingOptions';
import { AmbientLight } from 'three';

const SkyLight = () => {
  const currentPage = useCurrentPage();

  const additionalProperties =
    currentPage?.additionalProperties || DEFAULT_ADDITIONAL_PROPERTIES_PAGE;

  const lightingEnabled = additionalProperties.lightingEnabled;
  const lightingNight = additionalProperties.lightingNight;

  const lightAmbientRef = useRef<AmbientLight>(null);

  const lightAmbient = lightAmbientRef.current;

  if (lightAmbient) {
    lightAmbient.intensity = EARTH_LIGHTING.NIGHT_INTENSITY.DEFAULT;

    if (lightingEnabled) {
      const mNight =
        (EARTH_LIGHTING.NIGHT_INTENSITY.MAXIMUM - EARTH_LIGHTING.NIGHT_INTENSITY.MINIMUM) / 100; // slider delta is 100
      lightAmbient.intensity = mNight * lightingNight + EARTH_LIGHTING.NIGHT_INTENSITY.MINIMUM;
    }
  }

  return (
    <ambientLight
      name="ambience"
      color="white"
      ref={lightAmbientRef}
    />
  );
};

export default React.memo(SkyLight);
