import { useEffect, useMemo } from 'react';
import { useClearPropagations, useFetchPropagations } from 'src/core/hooks';
import { useCurrentOrbits, useIsPropagating } from 'src/hooks/OrbitHooks';

/**
 * ManeuverRepropagator
 * purpose: to watch an orbits maneuvers and when any detail changes, if we are propagatin
 * restart propagation
 */

export const ManeuverRepropagator = () => {
  const clearPropagations = useClearPropagations();
  const refetchPropagations = useFetchPropagations();
  const currentOrbits = useCurrentOrbits();
  const isPropagating = useIsPropagating();

  const allPageManeuvers = useMemo(() => {
    const flattenedOrbitManeuvers = currentOrbits
      ? currentOrbits
          .sort((a, b) => a.id - b.id)
          ?.flatMap((orb) => orb.maneuvers.map((m) => m.updatedTimestamp))
      : [];
    return JSON.stringify(flattenedOrbitManeuvers);
  }, [currentOrbits]);

  // ignoring linting exhaustive deps to make this effect only happen when maneuvers change
  // on current orbit
  useEffect(() => {
    if (isPropagating) {
      clearPropagations();
      refetchPropagations();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allPageManeuvers]);

  return null;
};
