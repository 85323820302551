/**
 * Sets Folder Object's Name (Limit: 50 Characters)
 */
export const DEFAULT_MAX_OBJECT_NAME_LENGTH = 50;

export function onChangeObjectName(
  event: any,
  setName: Function,
  maxLength: number = DEFAULT_MAX_OBJECT_NAME_LENGTH,
) {
  if (event.target.value.length > maxLength) {
    event.preventDefault();
  } else {
    setName(event.target.value);
  }
}
