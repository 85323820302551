import { Spherical, Vector2, Vector3 } from 'three';

/** takes the satellite position and returns uv coordinate in texture */
function getUVCoordsFromPointProjection(
  pos: Vector3,
  earthPos: Vector3,
  earthRot: number,
): { uv: Vector2 } {
  // get surface position on Earth Fixed coords
  const earthRadius = 1;
  const tempVector = new Vector3();
  const tempSphrCoord = new Spherical();

  tempVector.copy(pos).sub(earthPos).normalize().multiplyScalar(earthRadius);

  // transform the input position by earth rotation here so it's inertial frame
  tempVector.applyAxisAngle(new Vector3(0, 1, 0), -earthRot);

  // convert position to spherical coordinates
  tempSphrCoord.setFromVector3(tempVector);

  // phi is lattitude, theta is longitude
  //const phi = radToDeg(tempSphrCoord.phi);
  //const theta = radToDeg(tempSphrCoord.theta);

  const lat = 180 * (0.5 - tempSphrCoord.phi / Math.PI);
  const lon = 180 * (tempSphrCoord.theta / Math.PI);

  const u = lon / 360 + 0.5;
  const v = Math.abs(lat / 180 - 0.5);

  return { uv: new Vector2(u, v) };
}

export default getUVCoordsFromPointProjection;
