import { styled } from '@mui/material';

export const ObjectSummaryBody = styled('h6')`
  margin: 0;
  color: ${(props) => props.theme.palette.grey[600]};
`;

export const ObjectTitleInfo = styled('h3')`
  margin: 0;
  padding-top: ${(props) => props.theme.spacing(1)};
`;
