import { useThree } from '@react-three/fiber';
import { MeshLineGeometry, MeshLineMaterial, raycast } from 'meshline';
import { memo, useEffect, useMemo } from 'react';
import { ORBIT_COLOR_DEFAULT } from 'src/constants';
import { Color, DoubleSide, Mesh, NormalBlending, Vector2, Vector3 } from 'three';

type OrbitPathLineProps = {
  visible: boolean;
  color?: string;
  start: number;
  index: number;
  size: number;
  orbitPathDataPoints: Vector3[];
  name: string;
  meshRef: (ref: Mesh<MeshLineGeometry, MeshLineMaterial>) => void;
};

const OrbitPathLine = ({
  visible,
  color = ORBIT_COLOR_DEFAULT,
  start,
  size,
  index,
  orbitPathDataPoints,
  meshRef,
  name,
}: OrbitPathLineProps) => {
  const {
    gl: { domElement },
  } = useThree();

  const geometry = useMemo(() => {
    const line = new MeshLineGeometry();
    const dataPoints = orbitPathDataPoints.slice(start, start + size);

    line.setPoints(dataPoints);

    return line;
  }, [orbitPathDataPoints, size, start]);

  const material = useMemo(() => {
    const res = new Vector2(domElement.clientWidth, domElement.clientHeight);
    const style = {
      useMap: 0,
      color: new Color(color),
      transparent: true, // need to be true for reveal to work
      opacity: 1,
      resolution: res,
      sizeAttenuation: 0,
      depthTest: true,
      blending: NormalBlending,
      lineWidth: 4,
      side: DoubleSide,
      depthWrite: false,
      visible: true,
    };
    const material = new MeshLineMaterial(style);
    material.uniforms.visibility.value = 0;
    return material;

    // We only need referenceFrame when initially creating the material. The update
    // is handled by the effect below.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [domElement]);

  useEffect(() => {
    material.color = new Color(color);
    material.needsUpdate = true;
  }, [color, material]);

  useEffect(() => {
    geometry.name = `${name} Orbit Path Geometry (Line ${index})`;
    material.name = `${name} Orbit Path Material (Line ${index})`;
  }, [geometry, index, material, name]);

  return (
    <mesh
      ref={meshRef}
      geometry={geometry}
      material={material}
      name={`${name} Orbit Path Mesh (Line ${index})`}
      raycast={raycast}
      visible={visible}
    />
  );
};

export default memo(OrbitPathLine, (prevProps, nextProps) => {
  return (
    prevProps.size === nextProps.size &&
    prevProps.visible === nextProps.visible &&
    prevProps.color === nextProps.color &&
    prevProps.start === nextProps.start &&
    prevProps.index === nextProps.index &&
    prevProps.name === nextProps.name
  );
});
