import { IconButton } from '@mui/material';

type ToggleProps = {
  checked: boolean;
  onChange: (checked: boolean) => void;
  onIcon: React.ReactNode;
  offIcon: React.ReactNode;
};

export function Toggle({ checked, onChange, onIcon, offIcon }: ToggleProps) {
  return (
    <IconButton
      color="primary"
      onClick={(event) => {
        event.stopPropagation();
        onChange(!checked);
      }}
      size="large"
    >
      {checked ? onIcon : offIcon}
    </IconButton>
  );
}
