import { useEffect, useRef } from 'react';
import { getPlayState, getUpdateTimelineRange } from 'src/core/getters';
import { useCurrentPage } from 'src/hooks/PageHooks';
import PropagatedCacheManager from 'src/models/PropagatedCacheManager';
import { Clock } from 'three';
import { getMaxAvailableTime } from '../components/OrbitManager/store/getters';
import { getNextFrameTime } from '../utils/selectors';
import { useDebouncedCallback } from 'src/hooks/useDebouncedCallback';
import { SETTINGS_NAMES, useSettingsStore } from 'src/pages/Settings/store';

/** Controls playing the timeline, setting loading when buffering and stopping it when finished. */
function usePlayTimeline() {
  const currentPage = useCurrentPage();
  const currentFrameRef = useRef<number>(-1);

  const requestNextTimelineFrame = (playFn: () => void) => requestAnimationFrame(playFn);

  const isTargetMaxFramerateEnabled = useSettingsStore(
    (state) => state.settings[SETTINGS_NAMES.MAX_FRAMERATE_ENABLED],
  );
  const targetMaxFramerate = useSettingsStore((state) => state.framerate);
  const [debouncedRAF]: any = useDebouncedCallback(
    requestNextTimelineFrame,
    1000 / targetMaxFramerate,
  );

  useEffect(() => {
    const clock = new Clock();

    const playTimeline = () => {
      const delta = clock.getDelta();
      const playState = getPlayState();
      const updateTimelineRange = getUpdateTimelineRange();
      const nextTime = getNextFrameTime(delta);
      const endTime = currentPage?.endTime.getTime() ?? nextTime;
      const maxAvailableTime = getMaxAvailableTime();

      const currentTime = nextTime > endTime ? endTime : nextTime;

      if (maxAvailableTime) {
        const canPlay = nextTime < maxAvailableTime * 1000;
        const bufferingComplete = PropagatedCacheManager.getPropagationProgress() >= 100;

        if (canPlay) {
          if (playState === 'loading') {
            updateTimelineRange({
              currentTime,
              playState: 'playing',
            });
          } else if (playState === 'seeking') {
            updateTimelineRange({
              playState: 'stopped',
            });
          } else if (playState === 'playing') {
            updateTimelineRange({
              currentTime,
            });
          }
        } else {
          if (playState === 'playing') {
            if (bufferingComplete) {
              updateTimelineRange({
                currentTime,
                playState: 'stopped',
              });
            } else {
              updateTimelineRange({
                currentTime,
                playState: 'loading',
              });
            }
          } else if (playState === 'seeking') {
            updateTimelineRange({
              playState: 'stopped',
            });
          }
        }
      }

      const { settings } = useSettingsStore.getState();
      const isTargetMaxFramerateEnabled = settings[SETTINGS_NAMES.MAX_FRAMERATE_ENABLED];
      if (isTargetMaxFramerateEnabled) {
        currentFrameRef.current = debouncedRAF(playTimeline);
      } else {
        currentFrameRef.current = requestAnimationFrame(playTimeline);
      }
    };

    playTimeline();

    return function cleanup() {
      cancelAnimationFrame(currentFrameRef.current);
    };
  }, [currentPage?.endTime, debouncedRAF, isTargetMaxFramerateEnabled]);
}

export default usePlayTimeline;
