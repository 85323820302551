import { useFrame } from '@react-three/fiber';
import { useMemo, useRef } from 'react';
import { earthradius, EARTH_ORIGIN, VISUAL_AID_HEX } from 'src/constants';
import useAppStore from 'src/core/store';
import { useIsPropagating } from 'src/hooks/OrbitHooks';
import { COE } from 'src/threejs/models/COE';
import { convertKeplarianToCartesian } from 'src/utilities/Keplerian';
import { Vector3 } from 'three';
import { ArrowVector } from '../../ArrowVector';
import { createGetOrbitCOE } from '../../OrbitManager/store/getters';
import use3DOrbitStore from '../../OrbitManager/store/store';
import { use3DOrbitContext } from '../context';

interface PositionVelocityVectorsProps {
  orbitId: number;
  positionVis: boolean;
  velocityVis: boolean;
}

const VELOCITY_BASE_LENGTH = 0.5;
const VELOCITY_SCALE_LENGTH = 0.35;

export const PositionVelocityVectors = ({
  orbitId,
  positionVis,
  velocityVis,
}: PositionVelocityVectorsProps) => {
  const getOrbitCOE = useMemo(() => createGetOrbitCOE(orbitId), [orbitId]);

  // used to cache the previous orbit COE data to determine if
  // state vector conversion needs to be run again since it is run every frame
  const orbitCOE = useRef(getOrbitCOE());

  // used to store the current state vector that is based on COE parameters in preview mode
  // and based on the active state vector while in propagation mode
  const stateVector = useRef(convertKeplarianToCartesian(getOrbitCOE()));

  const isPropagating = useIsPropagating();
  const activeSv = use3DOrbitStore((state) => state.orbits[orbitId].activeStateVector);
  const scaleFactor = 1 / earthradius;

  const currentPlayState = useAppStore((state) => state.timelines.timelineRange.playState);

  useFrame(() => {
    const newCoe = getOrbitCOE();
    const { current } = orbitCOE;

    // only utilize active state vector if loading is finished and propagating
    // otherwise position will hang in prior activeSv positon while loading
    if (
      isPropagating &&
      activeSv &&
      (currentPlayState === 'playing' || currentPlayState === 'stopped')
    ) {
      const propagationStateVector = {
        x_velocity: activeSv.x_velocity / 1000,
        y_velocity: activeSv.y_velocity / 1000,
        z_velocity: activeSv.z_velocity / 1000,
        x_position: activeSv.x_position / 1000,
        y_position: activeSv.y_position / 1000,
        z_position: activeSv.z_position / 1000,
      };
      orbitCOE.current = new COE({
        ...activeSv.coe,
        epoch: activeSv.epoch,
      });

      stateVector.current = propagationStateVector;
    } else if (
      current.eccentricity !== newCoe.eccentricity ||
      current.semiMajorAxis !== newCoe.semiMajorAxis ||
      current.trueAnomaly !== newCoe.trueAnomaly ||
      current.inclination !== newCoe.inclination ||
      current.rightAscensionOfAscendingNode !== newCoe.rightAscensionOfAscendingNode ||
      current.argumentOfPeriapsis !== newCoe.argumentOfPeriapsis
    ) {
      stateVector.current = convertKeplarianToCartesian(newCoe);
      orbitCOE.current = newCoe;
    }
  });

  const getVelocityDestination = () =>
    new Vector3(
      stateVector.current.x_velocity,
      stateVector.current.y_velocity,
      stateVector.current.z_velocity,
    );

  const getPositionDestination = () =>
    new Vector3(
      stateVector.current.x_position! * scaleFactor,
      stateVector.current.y_position! * scaleFactor,
      stateVector.current.z_position! * scaleFactor,
    );

  const getVelocityLength = () =>
    getVelocityDestination().length() * VELOCITY_SCALE_LENGTH + VELOCITY_BASE_LENGTH;

  const { selected } = use3DOrbitContext();

  return (
    <>
      {positionVis && (
        <ArrowVector
          labelShort="R"
          active={selected}
          staticOrigin={EARTH_ORIGIN}
          color={VISUAL_AID_HEX}
          label="Position Vector"
          getDestination={getPositionDestination}
        />
      )}
      {velocityVis && (
        <ArrowVector
          labelShort="V"
          active={selected}
          getLength={getVelocityLength}
          color={VISUAL_AID_HEX}
          label="Velocity Vector"
          getOrigin={getPositionDestination}
          getDestination={getVelocityDestination}
        />
      )}
    </>
  );
};
