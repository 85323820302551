import { useAuth0 } from '@auth0/auth0-react';
import { DataGrid } from '@mui/x-data-grid';

export function UserInfo() {
  const { user } = useAuth0();

  if (user) {
    const columns = [
      { field: 'key', headerName: 'Property', flex: 1 },
      { field: 'value', headerName: 'Value', flex: 2 },
    ];

    const data = Object.entries(user).map(([key, value], index) => {
      return {
        id: index,
        key,
        value,
      };
    });

    return (
      <DataGrid
        autoHeight
        columns={columns}
        rows={data}
        density="compact"
        sortModel={[{ field: 'key', sort: 'asc' }]}
        hideFooter
      />
    );
  }

  return null;
}
