import { ExpandMoreRounded as ExpandMoreIcon } from '@mui/icons-material';
import {
  Accordion,
  AccordionSummary,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Grid,
  Switch,
} from '@mui/material';
import { useCallback, useEffect, useState } from 'react';
import {
  DEFAULT_ADDITIONAL_PROPERTIES_SPACE_SENSORS,
  DEFAULT_SPACE_SENSOR,
  SWATCH_PRESETS,
} from 'src/constants';
import { useCurrentOrbit } from 'src/hooks/OrbitHooks';
import { useCreateSpaceSensor, useUpdateSpaceSensor } from 'src/hooks/SpaceSensorsHooks';
import { useDebouncedCallback } from 'src/hooks/useDebouncedCallback';
import { useSpaceSensorsStore } from 'src/threejs/components/SpaceSensors/SpaceSensorsStore';
import { SpaceSensor } from 'src/types';
import { clamp } from 'three/src/math/MathUtils';
import { useEffectOnce } from 'usehooks-ts';
import { AccordionDetails } from './MuiStyleds/AccordionDetails';
import { ObjectPropertySlider } from './ObjectPropertySlider';
import { ColorSelector } from './Settings/ColorSelector';

export const SpaceSensorVisualOptions = () => {
  const currentOrbit = useCurrentOrbit();
  const createSpaceSensor = useCreateSpaceSensor();
  const updateSpaceSensor = useUpdateSpaceSensor();

  const spaceSensor = currentOrbit?.spaceSensors[0] || null;

  // if no sensors, create one
  useEffectOnce(() => {
    if (currentOrbit && currentOrbit.spaceSensors.length < 1) {
      createSpaceSensor.mutate({
        orbitId: currentOrbit.id,
        ...DEFAULT_SPACE_SENSOR,
      });
    }
  });

  const setEditingSpaceSensor = useSpaceSensorsStore((state) => state.setEditignSpaceSensor);
  const updateEditingSpaceSensor = useSpaceSensorsStore((state) => state.updateEditingSpaceSensor);
  const removeEditingSpaceSensor = useSpaceSensorsStore((state) => state.removeEditingSpaceSensor);

  // Set the sensor as being edited in the store, and remove when unmounted
  useEffect(() => {
    if (spaceSensor) {
      setEditingSpaceSensor(spaceSensor);
    }
    return () => {
      if (spaceSensor) {
        removeEditingSpaceSensor(spaceSensor.id);
      }
    };
  }, [removeEditingSpaceSensor, setEditingSpaceSensor, spaceSensor]);

  const minOpacity = 0;
  const maxOpacity = 100;

  // setup state for FOR
  const [visFORConeShader, setVisFORConeShader] = useState(
    spaceSensor?.additionalProperties?.visFORConeShader ??
      DEFAULT_ADDITIONAL_PROPERTIES_SPACE_SENSORS.visFORConeShader,
  );
  const [visFORConeWireframe, setVisFORConeWireframe] = useState(
    spaceSensor?.additionalProperties?.visFORConeWireframe ??
      DEFAULT_ADDITIONAL_PROPERTIES_SPACE_SENSORS.visFORConeWireframe,
  );

  const [forConeShaderColor, setForConeShaderColor] = useState(
    spaceSensor?.additionalProperties?.forConeShaderColor ??
      DEFAULT_ADDITIONAL_PROPERTIES_SPACE_SENSORS.forConeShaderColor,
  );
  const [forConeShaderOpacity, setForConeShaderOpacity] = useState(
    spaceSensor?.additionalProperties?.forConeShaderOpacity ??
      DEFAULT_ADDITIONAL_PROPERTIES_SPACE_SENSORS.forConeShaderOpacity,
  );
  const [forConeWireframeColor, setForConeWireframeColor] = useState(
    spaceSensor?.additionalProperties?.forConeWireframeColor ??
      DEFAULT_ADDITIONAL_PROPERTIES_SPACE_SENSORS.forConeWireframeColor,
  );
  const [forConeWireframeOpacity, setForConeWireframeOpacity] = useState(
    spaceSensor?.additionalProperties?.forConeWireframeOpacity ??
      DEFAULT_ADDITIONAL_PROPERTIES_SPACE_SENSORS.forConeWireframeOpacity,
  );

  // setup state for FOV
  const [visFOVConeShader, setVisFOVConeShader] = useState(
    spaceSensor?.additionalProperties?.visFOVConeShader ??
      DEFAULT_ADDITIONAL_PROPERTIES_SPACE_SENSORS.visFOVConeShader,
  );
  const [visFOVConeWireframe, setVisFOVConeWireframe] = useState(
    spaceSensor?.additionalProperties?.visFOVConeWireframe ??
      DEFAULT_ADDITIONAL_PROPERTIES_SPACE_SENSORS.visFOVConeWireframe,
  );

  const [fovConeShaderColor, setFovConeShaderColor] = useState(
    spaceSensor?.additionalProperties?.fovConeShaderColor ??
      DEFAULT_ADDITIONAL_PROPERTIES_SPACE_SENSORS.fovConeShaderColor,
  );
  const [fovConeShaderOpacity, setFovConeShaderOpacity] = useState(
    spaceSensor?.additionalProperties?.fovConeShaderOpacity ??
      DEFAULT_ADDITIONAL_PROPERTIES_SPACE_SENSORS.fovConeShaderOpacity,
  );
  const [fovConeWireframeColor, setFovConeWireframeColor] = useState(
    spaceSensor?.additionalProperties?.fovConeWireframeColor ??
      DEFAULT_ADDITIONAL_PROPERTIES_SPACE_SENSORS.fovConeWireframeColor,
  );
  const [fovConeWireframeOpacity, setFovConeWireframeOpacity] = useState(
    spaceSensor?.additionalProperties?.fovConeWireframeOpacity ??
      DEFAULT_ADDITIONAL_PROPERTIES_SPACE_SENSORS.fovConeWireframeOpacity,
  );

  const useSaveSpaceSensor = useCallback(
    (update: Partial<SpaceSensor>) => {
      updateSpaceSensor.mutate(update);
    },
    [updateSpaceSensor],
  );

  const [saveCurrentSpaceSensor] = useDebouncedCallback(useSaveSpaceSensor, 500);

  const saveSpaceSensor = (update: any) => {
    const partial: Partial<SpaceSensor> = {
      ...spaceSensor,
      additionalProperties: { ...spaceSensor?.additionalProperties, ...update },
    };
    saveCurrentSpaceSensor(partial);
    updateEditingSpaceSensor(partial);
  };

  return (
    <Accordion defaultExpanded>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>Observation Cones</AccordionSummary>

      {/* FIELD OF REGARD */}
      <AccordionDetails>
        <Accordion defaultExpanded>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>Field of Regard</AccordionSummary>

          <AccordionDetails>
            <Grid
              display="grid"
              gridAutoFlow="row"
              px={2}
              gap={1}
            >
              <FormGroup>
                <FormControlLabel
                  control={
                    <Switch
                      checked={visFORConeShader}
                      onChange={() => {
                        const value = !visFORConeShader;
                        setVisFORConeShader(value);
                        saveSpaceSensor({
                          visFORConeShader: value,
                        });
                      }}
                      size="small"
                    />
                  }
                  label="Cone Shader"
                />
              </FormGroup>

              <FormGroup>
                <FormControlLabel
                  control={
                    <Switch
                      checked={visFORConeWireframe}
                      onChange={() => {
                        const value = !visFORConeWireframe;
                        setVisFORConeWireframe(value);
                        saveSpaceSensor({
                          visFORConeWireframe: value,
                        });
                      }}
                      size="small"
                    />
                  }
                  label="Cone Wireframe"
                />
              </FormGroup>
            </Grid>

            <FormGroup sx={{ pl: 1, py: 1 }}>
              <FormLabel>Cone Color</FormLabel>
            </FormGroup>
            <ColorSelector
              currentColor={forConeShaderColor}
              presets={SWATCH_PRESETS}
              setColor={(newColor: string) => {
                saveSpaceSensor({
                  forConeShaderColor: newColor,
                });
                setForConeShaderColor(newColor);
              }}
            />

            <Grid sx={{ pt: 1 }}>
              <ObjectPropertySlider
                value={`${forConeShaderOpacity}`}
                propertyName="Cone Opacity"
                textErrState={(fieldNum) => fieldNum < minOpacity || fieldNum > maxOpacity}
                textFieldProps={{
                  helperText: `Number ranging from ${minOpacity} to ${maxOpacity}`,
                }}
                sliderProps={{
                  min: minOpacity,
                  max: maxOpacity,
                  style: { padding: 1 },
                }}
                onChange={(item: string) => {
                  const opacityVal = clamp(parseInt(item), minOpacity, maxOpacity);
                  saveSpaceSensor({
                    forConeShaderOpacity: opacityVal,
                  });
                  setForConeShaderOpacity(opacityVal);
                }}
              />
            </Grid>

            <FormGroup sx={{ pl: 1, py: 1 }}>
              <FormLabel>Wireframe Color</FormLabel>
            </FormGroup>
            <ColorSelector
              currentColor={forConeWireframeColor}
              presets={SWATCH_PRESETS}
              setColor={(newColor: string) => {
                saveSpaceSensor({
                  forConeWireframeColor: newColor,
                });
                setForConeWireframeColor(newColor);
              }}
            />

            <Grid sx={{ pt: 1 }}>
              <ObjectPropertySlider
                value={`${forConeWireframeOpacity}`}
                propertyName="Cone Opacity"
                textErrState={(fieldNum) => fieldNum < minOpacity || fieldNum > maxOpacity}
                textFieldProps={{
                  helperText: `Number ranging from ${minOpacity} to ${maxOpacity}`,
                }}
                sliderProps={{
                  min: minOpacity,
                  max: maxOpacity,
                  style: { padding: 1 },
                }}
                onChange={(item: string) => {
                  const opacityVal = clamp(parseInt(item), minOpacity, maxOpacity);
                  saveSpaceSensor({
                    forConeWireframeOpacity: opacityVal,
                  });
                  setForConeWireframeOpacity(opacityVal);
                }}
              />
            </Grid>
          </AccordionDetails>
        </Accordion>
      </AccordionDetails>

      {/* FIELD OF VIEW */}
      <AccordionDetails>
        <Accordion defaultExpanded>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>Field of View</AccordionSummary>

          <AccordionDetails>
            <Grid
              display="grid"
              gridAutoFlow="row"
              px={2}
              gap={1}
            >
              <FormGroup>
                <FormControlLabel
                  control={
                    <Switch
                      checked={visFOVConeShader}
                      onChange={() => {
                        const value = !visFOVConeShader;
                        setVisFOVConeShader(value);
                        saveSpaceSensor({
                          visFOVConeShader: value,
                        });
                      }}
                      size="small"
                    />
                  }
                  label="Cone Shader"
                />
              </FormGroup>

              <FormGroup>
                <FormControlLabel
                  control={
                    <Switch
                      checked={visFOVConeWireframe}
                      onChange={() => {
                        const value = !visFOVConeWireframe;
                        setVisFOVConeWireframe(value);
                        saveSpaceSensor({
                          visFOVConeWireframe: value,
                        });
                      }}
                      size="small"
                    />
                  }
                  label="Cone Wireframe"
                />
              </FormGroup>
            </Grid>

            <FormGroup sx={{ pl: 1, py: 1 }}>
              <FormLabel>Cone Color</FormLabel>
            </FormGroup>
            <ColorSelector
              currentColor={fovConeShaderColor}
              presets={SWATCH_PRESETS}
              setColor={(newColor: string) => {
                saveSpaceSensor({
                  fovConeShaderColor: newColor,
                });
                setFovConeShaderColor(newColor);
              }}
            />

            <Grid sx={{ pt: 1 }}>
              <ObjectPropertySlider
                value={`${fovConeShaderOpacity}`}
                propertyName="Cone Opacity"
                textErrState={(fieldNum) => fieldNum < minOpacity || fieldNum > maxOpacity}
                textFieldProps={{
                  helperText: `Number ranging from ${minOpacity} to ${maxOpacity}`,
                }}
                sliderProps={{
                  min: minOpacity,
                  max: maxOpacity,
                  style: { padding: 1 },
                }}
                onChange={(item: string) => {
                  const opacityVal = clamp(parseInt(item), minOpacity, maxOpacity);
                  saveSpaceSensor({
                    fovConeShaderOpacity: opacityVal,
                  });
                  setFovConeShaderOpacity(opacityVal);
                }}
              />
            </Grid>

            <FormGroup sx={{ pl: 1, py: 1 }}>
              <FormLabel>Wireframe Color</FormLabel>
            </FormGroup>
            <ColorSelector
              currentColor={fovConeWireframeColor}
              presets={SWATCH_PRESETS}
              setColor={(newColor: string) => {
                saveSpaceSensor({
                  fovConeWireframeColor: newColor,
                });
                setFovConeWireframeColor(newColor);
              }}
            />

            <Grid sx={{ pt: 1 }}>
              <ObjectPropertySlider
                value={`${fovConeWireframeOpacity}`}
                propertyName="Cone Opacity"
                textErrState={(fieldNum) => fieldNum < minOpacity || fieldNum > maxOpacity}
                textFieldProps={{
                  helperText: `Number ranging from ${minOpacity} to ${maxOpacity}`,
                }}
                sliderProps={{
                  min: minOpacity,
                  max: maxOpacity,
                  style: { padding: 1 },
                }}
                onChange={(item: string) => {
                  const opacityVal = clamp(parseInt(item), minOpacity, maxOpacity);
                  saveSpaceSensor({
                    fovConeWireframeOpacity: opacityVal,
                  });
                  setFovConeWireframeOpacity(opacityVal);
                }}
              />
            </Grid>
          </AccordionDetails>
        </Accordion>
      </AccordionDetails>
    </Accordion>
  );
};
