import { Box, Divider, Grid } from '@mui/material';
import { DEFAULT_ADDITIONAL_PROPERTIES_ORBIT } from 'src/constants';
import { useCurrentOrbit } from 'src/hooks/OrbitHooks';
import { LiveDataDisplayMode } from 'src/pages/Notebook/components/LiveDataPanel/LiveDataDisplayMode';
import { LiveDataMode } from 'src/types';
import { LiveDataCartesian } from './LiveDataCartesian';
import { LiveDataKeplerian } from './LiveDataKeplerian';
import { LiveDataRelative } from './LiveDataRelative';

export const LiveDataPanel = () => {
  const currentOrbit = useCurrentOrbit();
  const currentLiveDataMode =
    currentOrbit?.additionalProperties?.liveDataMode ||
    DEFAULT_ADDITIONAL_PROPERTIES_ORBIT.liveDataMode;

  return (
    <Box
      component="div"
      sx={{
        p: 1,
      }}
    >
      <Grid
        pb={1}
        container
        alignItems="center"
      >
        <Grid
          pr={1}
          sx={{ fontSize: 12, fontWeight: 'bold' }}
        >
          Display Type:
        </Grid>
        <LiveDataDisplayMode />
      </Grid>
      <Divider />
      {currentLiveDataMode === LiveDataMode.Keplerian && (
        <LiveDataKeplerian key={`Kep${currentOrbit?.id}`} />
      )}
      {currentLiveDataMode === LiveDataMode.Cartesian && (
        <LiveDataCartesian key={`Cart${currentOrbit?.id}`} />
      )}
      {currentLiveDataMode === LiveDataMode.Relative && (
        <LiveDataRelative key={`Rel${currentOrbit?.id}`} />
      )}
    </Box>
  );
};
