import React from 'react';
import { AXES_HELPER_LENGTHS, DEFAULT_ADDITIONAL_PROPERTIES_VIEWPORT } from 'src/constants';
import { OrbitRenderOrder } from 'src/enums';
import { useViewportContext } from 'src/threejs/components/Viewport/context';
import { useViewportReferenceFrame } from 'src/threejs/components/ViewportManager/store';
import { Euler } from 'three';
import { AxesHelper } from '../AxesHelper';
import { InfinitePlanarGrid } from '../InfinitePlanarGrid';

const CoordinateRef = () => {
  const { isRIC, isECI } = useViewportReferenceFrame();
  const { viewport } = useViewportContext();
  const viewportAdditionalProperties =
    viewport?.additionalProperties || DEFAULT_ADDITIONAL_PROPERTIES_VIEWPORT;
  const isGridOn = viewportAdditionalProperties.visGrid;

  return (
    <group
      visible={isECI || isRIC}
      rotation={new Euler(0, Math.PI, 0)}
    >
      {isECI && isGridOn && <InfinitePlanarGrid />}
      <AxesHelper
        hideAxisHeads={isRIC}
        args={[AXES_HELPER_LENGTHS]}
        renderOrder={OrbitRenderOrder.AXES_HELPER}
        name="CoordinateRef"
      />
    </group>
  );
};

export default React.memo(CoordinateRef);
