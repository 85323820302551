import { useSnackbar } from 'notistack';
import { Button } from '@mui/material';
import { DismissNotificationAction } from 'src/threejs/hooks/useNotifications';

export const CopyToClipboardButton = ({ copyText }: { copyText: string }) => {
  const { enqueueSnackbar } = useSnackbar();

  const handleClick = () => {
    enqueueSnackbar('Copied to clibboard', {
      action: DismissNotificationAction,
      anchorOrigin: {
        vertical: 'top',
        horizontal: 'center',
      },
      preventDuplicate: true,
      variant: 'success',
    });
    navigator.clipboard.writeText(copyText);
  };

  return (
    <>
      <Button
        onClick={handleClick}
        color="primary"
        variant="contained"
      >
        Copy
      </Button>
    </>
  );
};
