import { Box, Fade } from '@mui/material';
import { Children, ReactElement } from 'react';
import { useElementSizeWithResizeObserver } from 'src/hooks/useElementSizeWithResizeObserver';
import theme from 'src/pages/App/Theme';
import { LayoutEngineItem } from './LayoutEngineItem';
import { LayoutEngineItemState } from './layoutStore';

export const GAP_SIZE = theme.spacing(0.5);

export type LayoutState = Record<string, LayoutEngineItemState>;

interface LayoutEngineProps {
  children: ReactElement | ReactElement[];
  layout: LayoutState;
  onLayoutChange: (updates: Record<string, Partial<LayoutEngineItemState>>) => void;
  isLayoutMinified?: boolean;
}

export const LayoutEngine = ({
  children,
  layout,
  onLayoutChange,
  isLayoutMinified = false,
}: LayoutEngineProps) => {
  const [containerRef, { width, height }] = useElementSizeWithResizeObserver();

  const nextZIndex = Math.max(...Object.values(layout).map((layout) => layout.zIndex)) + 1;

  return (
    <div
      className="LayoutEngine"
      style={{
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        padding: GAP_SIZE,
        pointerEvents: 'none',
      }}
    >
      <div
        ref={containerRef}
        style={{
          position: 'relative',
          width: '100%',
          height: '100%',
        }}
      >
        {Children.map(children, (child, index) => {
          const maxConstraints = child.props['data-maxConstraints'] || [250, 250];
          const minConstraints = child.props['data-minConstraints'] || [250, 250];

          if (child.key && layout[child.key]) {
            return (
              <Fade
                in={!isLayoutMinified}
                mountOnEnter
                unmountOnExit
              >
                <Box component="div">
                  <LayoutEngineItem
                    key={child.key}
                    name={child.key as string}
                    itemLayout={layout[child.key]}
                    maxConstraints={maxConstraints}
                    minConstraints={minConstraints}
                    onLayoutChange={onLayoutChange}
                    nextZIndex={nextZIndex}
                    parentHeight={height}
                    parentWidth={width}
                  >
                    {child}
                  </LayoutEngineItem>
                </Box>
              </Fade>
            );
          }
          return null;
        })}
      </div>
    </div>
  );
};
