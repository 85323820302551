import { useEffect, useState } from 'react';
import { COLORS_LAUNCH_PREVIEW } from 'src/constants';
import { useGroundObjectLaunchEditStore } from 'src/threejs/components/GroundObjects/GroundObjectLaunchEditStore';
import { DoubleSide, EllipseCurve, LatheGeometry, Mesh, MeshBasicMaterial } from 'three';
import { degToRad } from 'three/src/math/MathUtils';

export const LaunchPreviewBand = () => {
  const [bandRef, setBandRef] = useState<Mesh | null>(null);

  const orbitInclination = useGroundObjectLaunchEditStore(
    (state) => state.groundObjectEdit?.inclination,
  );

  useEffect(() => {
    if (bandRef && orbitInclination !== undefined) {
      let maxAbsoluteLatitude =
        Math.abs(orbitInclination) < 90
          ? Math.abs(orbitInclination)
          : Math.abs(orbitInclination - 180);
      maxAbsoluteLatitude = degToRad(maxAbsoluteLatitude);

      const curve = new EllipseCurve(
        0,
        0,
        1.01,
        1.01,
        maxAbsoluteLatitude,
        -maxAbsoluteLatitude,
        true,
        0,
      );

      const points = curve.getPoints(64);
      const geometry = new LatheGeometry(points, 128);

      bandRef.geometry.dispose();
      bandRef.geometry = geometry;
    }
  }, [bandRef, orbitInclination]);

  const material = new MeshBasicMaterial({
    transparent: true,
    opacity: 0.5,
    color: COLORS_LAUNCH_PREVIEW.GREEN,
    side: DoubleSide,
    depthWrite: false,
  });

  return (
    <group>
      <mesh
        ref={setBandRef}
        name="Launch Preview - Latitude Validity Band"
        material={material}
      />
    </group>
  );
};
