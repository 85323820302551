import { ExpandMoreRounded as ExpandMoreIcon } from '@mui/icons-material';
import { Accordion, AccordionDetails, AccordionSummary } from '@mui/material';
import { useEffect, useState } from 'react';
import { DEFAULT_ADDITIONAL_PROPERTIES_GROUND_OBJECTS, SWATCH_PRESETS } from 'src/constants';
import {
  useCurrentGroundObject,
  useUpdateGroundObjectAdditionalProperties,
} from 'src/hooks/GroundObjectHooks';
import { useDebouncedCallback } from 'src/hooks/useDebouncedCallback';
import {
  GroundObjectEditType,
  useGroundObjectLaunchEditStore,
} from 'src/threejs/components/GroundObjects/GroundObjectLaunchEditStore';
import { GroundObjectWireframeOpacity } from './GroundObjectWireframeOpacity';
import { ColorSelector } from './Settings/ColorSelector';

export function GroundObjectWireframeColorPicker() {
  const currentGroundObject = useCurrentGroundObject();

  const updateGroundObjAdditionalProperties = useUpdateGroundObjectAdditionalProperties();

  const [currentColor, setColor] = useState(
    currentGroundObject?.additionalProperties
      ? currentGroundObject.additionalProperties.coneWireframeColor
      : DEFAULT_ADDITIONAL_PROPERTIES_GROUND_OBJECTS.coneWireframeColor,
  );

  const setObjectColor = (newColor: string) => {
    if (currentGroundObject) {
      const changes = { coneWireframeColor: newColor };
      updateGroundObjAdditionalProperties(currentGroundObject, changes);
    }
  };
  const [saveCurrentOrbitDebounced] = useDebouncedCallback(setObjectColor, 500);

  useEffect(() => {
    if (!currentGroundObject?.id) return;
    const { groundObjectEdit, setGroundObjectEdit } = useGroundObjectLaunchEditStore.getState();
    if (!groundObjectEdit) return;

    const update: GroundObjectEditType = {
      ...groundObjectEdit,
      coneWireframeColor: currentColor,
    };
    setGroundObjectEdit(update);
  }, [currentColor, currentGroundObject?.id]);

  if (!currentGroundObject) {
    return null;
  }

  return (
    <Accordion defaultExpanded>
      <AccordionSummary
        style={{
          flexDirection: 'row-reverse',
        }}
        expandIcon={<ExpandMoreIcon />}
        aria-controls="inspector-panel-visual"
        id="inspector-panel-visual"
      >
        Cone Wireframe Color
      </AccordionSummary>
      <AccordionDetails>
        <ColorSelector
          currentColor={currentColor}
          presets={SWATCH_PRESETS}
          setColor={(newColor: string) => {
            saveCurrentOrbitDebounced(newColor);
            setColor(newColor);
          }}
        />
        <GroundObjectWireframeOpacity />
      </AccordionDetails>
    </Accordion>
  );
}
