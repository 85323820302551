import { Button, Dialog, DialogActions, DialogContent } from '@mui/material';
import { WINDOWS_TYPES, WindowType } from './ViewingWindowsStore';
import { SpaceGlassContainerBase } from 'src/components/SpaceGlassControl';
import { ModalHeader } from 'src/pages/Shared/ModalSpaceGlass';
import { InfoRounded } from '@mui/icons-material';
import { CopyToClipboardButton } from './CopyToClipboard';
import { GroundObject, GroupObject, OrbitObject, SpaceSensor, ViewingWindow } from 'src/types';
import theme from 'src/pages/App/Theme';
import { formatDuration, intervalToDuration } from 'date-fns';
import { millisToLocale } from 'src/utilities/DateTimeUtils';
import { useCurrentGroups } from 'src/hooks/GroupHooks';
import { useCurrentOrbits } from 'src/hooks/OrbitHooks';
import { useCurrentGroundObjects } from 'src/hooks/GroundObjectHooks';

interface ModalViewingWindowsProps {
  show: boolean;
  onClose: VoidFunction;

  type: WindowType;
  object: GroundObject | SpaceSensor;
  viewingWindows: ViewingWindow[];
}

export const ModalViewingWindows = ({
  show,
  onClose,
  type,
  object,
  viewingWindows,
}: ModalViewingWindowsProps) => {
  const currentGroups = useCurrentGroups() || [];
  const currentOrbits = useCurrentOrbits() || [];
  const currentGroundObjects = useCurrentGroundObjects() || [];

  const title = type === WINDOWS_TYPES.GROUND_OBJECT ? 'Ground Object' : 'Space Sensor';

  const textReport =
    type === WINDOWS_TYPES.GROUND_OBJECT
      ? getTextReportForGroundObject(
          object as GroundObject,
          currentGroups,
          currentOrbits,
          currentGroundObjects,
          viewingWindows,
        )
      : getTextReportForSpaceSensor(
          object as SpaceSensor,
          currentGroups,
          currentOrbits,
          currentGroundObjects,
          viewingWindows,
        );

  return (
    <Dialog
      fullWidth
      maxWidth="sm"
      open={show}
      onClose={onClose}
      PaperComponent={SpaceGlassContainerBase}
    >
      <ModalHeader
        title={`${title} Text Report`}
        onClose={onClose}
        icon={<InfoRounded />}
      />

      <DialogContent
        sx={{
          border: `solid 1px ${theme.palette.grey['600']}`,
          borderRadius: 1,
          padding: 1,
          margin: 3,
        }}
      >
        <div
          style={{
            whiteSpace: 'pre',
            fontFeatureSettings: '"tnum"',
          }}
        >
          {textReport}
        </div>
      </DialogContent>

      <DialogActions>
        <CopyToClipboardButton copyText={textReport} />
        <Button
          color="secondary"
          variant="contained"
          onClick={onClose}
        >
          CLOSE
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const getTextReportForGroundObject = (
  groundObject: GroundObject,
  currentGroups: GroupObject[],
  currentOrbits: OrbitObject[],
  currentGroundObjects: GroundObject[],
  viewingWindows: ViewingWindow[],
): string => {
  const viewingConstraints = [];
  if (groundObject.eventProperties.GROUND_OBJECT_REQUIRES_DARKNESS) {
    viewingConstraints.push('Ground Station in Darkness');
  }
  if (groundObject.eventProperties.TARGET_ILLUMINATED) {
    viewingConstraints.push('Target Illuminated');
  }

  let displayedObjectOrGroup = '';
  if (groundObject.targetOrbitId) {
    const targetObject = currentOrbits.find((obj) => obj.id === groundObject.targetOrbitId);
    if (targetObject) {
      displayedObjectOrGroup = `Target Object: ${targetObject.name}`;
    }
  }
  if (groundObject.targetGroupId) {
    const targetGroup = currentGroups.find((group) => group.id === groundObject.targetGroupId);
    const targetObjects = currentGroundObjects.filter(
      (obj) => obj.groupId === groundObject.targetGroupId,
    );
    if (targetGroup) {
      displayedObjectOrGroup = `Target Objects: ${targetGroup.name}${targetObjects
        .map((obj) => `\n           -- ${obj.name}`)
        .join('')}`;
    }
  }

  const viewingWindowReports = viewingWindows
    .map((win, idx) => {
      const dur = intervalToDuration({
        start: win.startTime,
        end: win.endTime,
      });
      const objectName = currentOrbits?.find((orb) => orb.id === win.orbitId)?.name;
      return `Window ${idx + 1}
Object: ${objectName ?? 'not found'}
Start: ${millisToLocale(win.startTime.getTime(), 'utc')}
Azimuth Range: ${win.startAzimuth} - ${win.endAzimuth} Deg
Max Elevation Angle: ${win.maxElevationAngle} Deg
End: ${millisToLocale(win.endTime.getTime(), 'utc')}
Duration: ${formatDuration(dur)}`;
    })
    .join('\n\n');

  const textReport = `Contact Windows For: ${groundObject.name}
Latitude: ${groundObject.latitude.toFixed(2)} Deg
Longitude: ${groundObject.longitude.toFixed(2)} Deg
Altitude: ${groundObject.altitude} km
Min Elevation Angle: ${groundObject.minElevationAngle} Deg
Sensor Range: ${groundObject.sensorRange} km
Viewing Constraints: ${
    viewingConstraints.length > 0 ? viewingConstraints.join(', ').trimEnd() : 'none'
  }
  
${displayedObjectOrGroup}

${viewingWindowReports}
`;

  return textReport;
};

const getTextReportForSpaceSensor = (
  spaceSensor: SpaceSensor,
  currentGroups: GroupObject[],
  currentOrbits: OrbitObject[],
  currentGroundObjects: GroundObject[],
  viewingWindows: ViewingWindow[],
): string => {
  const orbit = currentOrbits.find((orb) => orb.id === spaceSensor.orbitId);

  if (!orbit) {
    return '';
  }

  const viewingConstraints = [];
  if (spaceSensor.targetIlluminated) {
    viewingConstraints.push('Target Illuminated');
  }

  let displayedObjectOrGroup = '';
  if (spaceSensor.targetGroundObjectId) {
    const targetObject = currentGroundObjects.find(
      (obj) => obj.id === spaceSensor.targetGroundObjectId,
    );
    if (targetObject) {
      displayedObjectOrGroup = `Target Object: ${targetObject.name}`;
    }
  }
  if (spaceSensor.targetGroupId) {
    const targetGroup = currentGroups.find((group) => group.id === spaceSensor.targetGroupId);
    const targetObjects = currentGroundObjects.filter(
      (obj) => obj.groupId === spaceSensor.targetGroupId,
    );
    if (targetGroup) {
      displayedObjectOrGroup = `Target Objects: ${targetGroup.name}${targetObjects
        .map((obj) => `\n           -- ${obj.name}`)
        .join('')}`;
    }
  }

  const viewingWindowReports = viewingWindows
    .map((win, idx) => {
      const dur = intervalToDuration({
        start: win.startTime,
        end: win.endTime,
      });
      const objectName = currentGroundObjects?.find((obj) => obj.id === win.groundObjectId)?.name;
      return `Window ${idx + 1}
Object: ${objectName ?? 'not found'}
Start: ${millisToLocale(win.startTime.getTime(), 'utc')}
Range: ${win.startRange} - ${win.endRange} km
Elevation Angle: ${win.maxElevationAngle} Deg
End: ${millisToLocale(win.endTime.getTime(), 'utc')}
Duration: ${formatDuration(dur)}`;
    })
    .join('\n\n');

  const textReport = `Contact Windows For: ${orbit.name}
Ground Elevation Angle: ${spaceSensor.minGroundElevationAngle} Deg
Field of View: ${spaceSensor.fieldOfView} deg
Viewing Constraints: ${
    viewingConstraints.length > 0 ? viewingConstraints.join(', ').trimEnd() : 'none'
  }

${displayedObjectOrGroup}

${viewingWindowReports}
`;

  return textReport;
};
