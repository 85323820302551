import { AddRounded } from '@mui/icons-material';
import { Button } from '@mui/material';
import {
  createRef,
  RefObject,
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useState,
} from 'react';
import { PanelHeader } from 'src/components/PanelHeader';
import { ScrollableContainer } from 'src/components/ScrollableContainer';
import { SpaceGlassPanel } from 'src/components/SpaceGlassPanel';
import useAppStore from 'src/core/store';
import { LABModalAction, LABModalActionType } from 'src/enums';
import { useCurrentNotebook } from 'src/hooks/ObjectHooks';
import { useCurrentPage, useCurrentPages } from 'src/hooks/PageHooks';
import { useIsReadOnly } from 'src/hooks/SharedNotebookHooks';
import { router } from 'src/pages/App/routes/Router';
import {
  getActiveCapabilityId,
  getActiveNotebookId,
  getActivePageId,
  useRouteStore,
} from 'src/pages/App/routes/store';
import { sortByNameAlphabetical } from 'src/utilities/ArrayUtils';
import { ObjectItemPage } from './ListPanel/ObjectItemPage';
import { TreeBranch } from './ListPanel/TreeDND/TreeBranch';
import ModalNotebookPage from './ModalNotebookPage';

export default function PagesListPanel() {
  const currentPageId = useRouteStore(getActivePageId);
  const currentNotebookId = useRouteStore(getActiveNotebookId);
  const currentCapabilityId = useRouteStore(getActiveCapabilityId);

  const currentPage = useCurrentPage();
  const currentNotebook = useCurrentNotebook();
  const currentPages = useCurrentPages();
  const isReadOnly = useIsReadOnly();

  const [modalType, setModalType] = useState<LABModalActionType>(LABModalAction.NULL);

  useEffect(() => {
    if (currentPage) {
      const {
        pages: { setCurrentPageId },
        timelines: { createTimelineForPage },
      } = useAppStore.getState();

      setCurrentPageId(currentPage?.id);

      if (currentPage?.id) {
        createTimelineForPage(currentPage);
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage]);

  /**
   * Opens the create page modal
   */
  const onOpenCreateModal = useCallback(() => {
    setModalType(LABModalAction.CREATE);
  }, []);

  /**
   * Resets the modal action type
   */
  const onCloseModal = useCallback(() => {
    setModalType(LABModalAction.NULL);
  }, []);

  const pageRefs = useMemo(() => {
    const refs: Record<number, RefObject<HTMLDivElement>> = {};
    currentPages.forEach((page) => {
      refs[page.id] = createRef<HTMLDivElement>();
    });
    return refs;
  }, [currentPages]);

  // begin scrolling down to the currently selected notebook page automatically
  useLayoutEffect(() => {
    if (currentPage?.id) {
      if (pageRefs[currentPage.id]) {
        pageRefs[currentPage.id]?.current?.scrollIntoView({ behavior: 'smooth', block: 'start' });
      }
    }
  }, [currentPage, pageRefs]);

  const handleOnSelect = useCallback(
    (pageId: number) => {
      let baseURL = `/notebook/${currentNotebookId}/${pageId}`;
      if (currentCapabilityId) {
        baseURL = `/shared/${currentCapabilityId}${baseURL}`;
      }
      router.navigate(`${baseURL}`);
    },
    [currentCapabilityId, currentNotebookId],
  );

  return (
    <SpaceGlassPanel
      header={<PanelHeader title={currentNotebook?.name} />}
      footer={
        !isReadOnly && (
          <div
            style={{
              display: 'grid',
              justifyContent: 'end',
            }}
          >
            <Button
              color="primary"
              variant="rounded"
              onClick={onOpenCreateModal}
            >
              <AddRounded />
            </Button>
          </div>
        )
      }
    >
      <ModalNotebookPage
        action={modalType}
        onClose={onCloseModal}
      />

      <ScrollableContainer>
        {sortByNameAlphabetical(currentPages).map((page) => {
          return (
            <div
              key={page.id}
              ref={pageRefs[page.id]}
            >
              <TreeBranch
                id={page.id}
                type={'page'}
                active={page.id === currentPageId}
                onSelect={() => handleOnSelect(page.id)}
                main={<ObjectItemPage page={page} />}
              />
            </div>
          );
        })}
      </ScrollableContainer>
    </SpaceGlassPanel>
  );
}
