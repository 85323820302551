import { produce } from 'immer';
import { create } from 'zustand';

interface RouteStoreState {
  capabilityId?: number;
  folderId?: number;
  groupId?: number;
  groundObjId?: number;
  notebookId?: number;
  objectId?: number;
  groundObjectId?: number;
  pageId?: number;
  specialId?: string; // items like earth, sun, etc.
  setActiveId: (param: string, id: number | string | undefined) => void;
}

export const useRouteStore = create<RouteStoreState>()((set, _get) => ({
  setActiveId: (param: string, id: number | string | undefined) => {
    set(
      produce((state) => {
        state[param] = id || undefined; // undefined ensures the returns don't convert to 0 when cast as Number()
      }),
    );
  },
}));

export const getActiveCapabilityId = (state: RouteStoreState) => Number(state.capabilityId);
export const getActiveFolderId = (state: RouteStoreState) => Number(state.folderId);
export const getActiveGroupId = (state: RouteStoreState) => Number(state.groupId);
export const getActiveGroundObjectId = (state: RouteStoreState) => Number(state.groundObjectId);
export const getActiveNotebookId = (state: RouteStoreState) => Number(state.notebookId);
export const getActiveObjectId = (state: RouteStoreState) => Number(state.objectId);
export const getActivePageId = (state: RouteStoreState) => Number(state.pageId);
export const getActiveSpecialId = (state: RouteStoreState) => state.specialId;
