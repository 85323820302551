import { PerspectiveCamera, useTexture } from '@react-three/drei';
import { useFrame } from '@react-three/fiber';
import { useEffect, useState } from 'react';
import {
  AXES_HELPER_LENGTHS,
  DEFAULT_ADDITIONAL_PROPERTIES_PAGE,
  DEFAULT_ADDITIONAL_PROPERTIES_VIEWPORT,
} from 'src/constants';
import { getCurrentTime } from 'src/core/getters';
import { OrbitRenderOrder } from 'src/enums';
import { useCurrentPage } from 'src/hooks/PageHooks';
import { router } from 'src/pages/App/routes/Router';
import {
  getActiveCapabilityId,
  getActiveNotebookId,
  getActivePageId,
  useRouteStore,
} from 'src/pages/App/routes/store';
import { LaunchPreviewEarth } from 'src/pages/Notebook/components/Launch/LaunchPreviewEarth';
import { useViewportContext } from 'src/threejs/components/Viewport/context';
import getEarthRotation from 'src/threejs/math/getEarthRotation';
import { Euler, Object3D } from 'three';
import { AxesHelper } from '../AxesHelper';
import { GroundObjects } from '../GroundObjects/GroundObjects';
import { GroundTracks } from '../GroundTracks/GroundTracks';
import { InfinitePlanarGrid } from '../InfinitePlanarGrid';
import { SubsatellitePoints } from '../SubsatellitePoints/SubsatellitePoints';
import useViewportStore, {
  useViewportId,
  useViewportReferenceFrame,
} from '../ViewportManager/store';
import { Graticule } from './Graticule';
import { TopoCountries } from './TopoCountries';

const radius = 1;
const widthSegments = 128;
const heightSegments = 64;

const Earth = () => {
  const capabilityId = useRouteStore(getActiveCapabilityId);
  const notebookId = useRouteStore(getActiveNotebookId);
  const pageId = useRouteStore(getActivePageId);

  const [colorMap, bumpMap, specularMap] = useTexture([
    '/textures/earth/4k/earthmap.jpg',
    '/textures/earth/4k/earthbump.jpg',
    '/textures/earth/4k/earthspec.jpg',
  ]);

  const [sphere, setSphere] = useState<Object3D | null>(null);

  const { isECEF } = useViewportReferenceFrame();

  const currentPage = useCurrentPage();

  const additionalProperties =
    currentPage?.additionalProperties || DEFAULT_ADDITIONAL_PROPERTIES_PAGE;

  const pageEarthVisible = additionalProperties.visEarth;
  const visLatLongLines = additionalProperties.visLatLongLines;
  const visCountries = additionalProperties.visCountries;

  const earthAnimation = () => {
    if (sphere) {
      sphere.rotation.y = getEarthRotation(getCurrentTime());
    }
  };

  useFrame(earthAnimation);

  const handleEarthDoubleClick = () => {
    const newURL = `/notebook/${notebookId}/${pageId}/special/earth`;

    if (capabilityId) {
      router.navigate(`/shared/${capabilityId}${newURL}}`);
    } else {
      router.navigate(newURL);
    }
  };

  const viewportId = useViewportId();
  const { setCameraECEF } = useViewportStore.getState().viewports[viewportId];
  const [cameraECEFLocal, setCameraECEFLocal] = useState(null);

  useEffect(() => {
    // cant set ref directly into zustand store so use effect to store it when initially set in local state
    if (cameraECEFLocal) {
      setCameraECEF(cameraECEFLocal);
    }
  }, [setCameraECEF, cameraECEFLocal]);

  const { viewport } = useViewportContext();

  if (!currentPage || !currentPage.id) return <></>;
  const viewportAdditionalProperties =
    viewport?.additionalProperties || DEFAULT_ADDITIONAL_PROPERTIES_VIEWPORT;
  const isGridOn = viewportAdditionalProperties.visGrid;

  return (
    <group ref={setSphere}>
      <PerspectiveCamera
        name="Camera ECEF"
        makeDefault={isECEF}
        ref={setCameraECEFLocal}
      />
      <group rotation={[0, Math.PI, 0]}>
        <AxesHelper
          renderOrder={OrbitRenderOrder.EARTH_REF_GRID}
          name="CoordinateRef"
          args={[AXES_HELPER_LENGTHS]}
          visible={isECEF}
        />
      </group>

      <group
        visible={isECEF}
        rotation={new Euler(0, Math.PI / 1.35, 0)}
      >
        {isGridOn && <InfinitePlanarGrid />}
      </group>

      <TopoCountries visible={visCountries} />

      {visLatLongLines && <Graticule />}

      <GroundTracks />
      <SubsatellitePoints />

      <mesh
        onDoubleClick={handleEarthDoubleClick}
        visible={pageEarthVisible}
        renderOrder={OrbitRenderOrder.ORBIT_EARTH_TOGGLE_ON}
        rotation={[0, -Math.PI / 2, 0]}
      >
        <sphereGeometry args={[radius, widthSegments, heightSegments]} />
        <meshPhongMaterial
          map={colorMap}
          bumpMap={bumpMap}
          bumpScale={0.5}
          specularMap={specularMap}
          shininess={10}
        />
      </mesh>

      <mesh
        onDoubleClick={handleEarthDoubleClick}
        visible={!pageEarthVisible}
      >
        <sphereGeometry args={[radius, widthSegments, heightSegments]} />
        <meshLambertMaterial
          color={0x5c5c5c}
          depthWrite={false}
          transparent
          opacity={0.25}
        />
      </mesh>

      <GroundObjects />

      <LaunchPreviewEarth />
    </group>
  );
};

export default Earth;
