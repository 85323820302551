import { useEffect } from 'react';
import { earthradius, PROPAGATION_STEPS_PER_REVOLUTION, scaleFactor } from 'src/constants';
import { useCameraUtilities } from 'src/hooks/CameraHooks';
import { useGroundObjectLaunchEditStore } from 'src/threejs/components/GroundObjects/GroundObjectLaunchEditStore';
import keplerianOrbitMath from 'src/threejs/math/keplerianOrbit';
import { Vector3 } from 'three';
import { degToRad } from 'three/src/math/MathUtils';

export const LaunchPreviewCamera = () => {
  const { moveCameraTo } = useCameraUtilities();

  const activeLaunchWindow = useGroundObjectLaunchEditStore((state) => state.activeLaunchWindow);

  const orbitalSMA =
    useGroundObjectLaunchEditStore((state) => state.groundObjectEdit?.orbitLaunchAltitude || 0) +
    earthradius;
  const orbitalEccentricity = useGroundObjectLaunchEditStore(
    (state) => state.groundObjectEdit?.eccentricity || 0,
  );
  const orbitalInclination = useGroundObjectLaunchEditStore(
    (state) => state.groundObjectEdit?.inclination || 0,
  );
  const orbitalRAAN = useGroundObjectLaunchEditStore(
    (state) => state.groundObjectEdit?.rightAscensionOfAscendingNode || 0,
  );

  const keplerianElements = {
    argumentOfPeriapsis: 0,
    eccentricity: orbitalEccentricity,
    inclination: degToRad(orbitalInclination),
    rightAscensionOfAscendingNode: degToRad(orbitalRAAN),
    semiMajorAxis: orbitalSMA,
    trueAnomaly: 0,
  };

  const data = keplerianOrbitMath(keplerianElements, PROPAGATION_STEPS_PER_REVOLUTION);

  useEffect(() => {
    if (activeLaunchWindow) {
      const vectorA = data.angleToPositionXform(data.trueAnomalyRangeArray[0], new Vector3());
      const vectorB = data.angleToPositionXform(data.trueAnomalyRangeArray[1], new Vector3());

      const distance = Math.max(data.apogeeDistance, 8000) * scaleFactor;

      const planeNormal = vectorA.cross(vectorB).setLength(distance * 3);

      moveCameraTo(planeNormal);
    }
    // only move the camera when the launch window changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeLaunchWindow]);

  return null;
};
