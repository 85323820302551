import { Grid } from '@mui/material';
import { useAuth0 } from '@auth0/auth0-react';
import { useLocation } from 'react-router-dom';
import { ThemeProvider } from '@mui/material/styles';
import {
  EulaCancelButton,
  EulaContainer,
  EulaContent,
  EulaLogo,
  EulaLogoContainer,
  EulaSubmitButton,
} from './EULA.styled';
import theme from '../App/Theme';
import React, { useState } from 'react';

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

export const EULA = () => {
  const [isChecked, setIsChecked] = useState(false);
  const { logout } = useAuth0();
  const query = useQuery();
  const state = query.get('state');
  const domain = query.get('auth0_domain');
  const handleSubmit = (event: React.FormEvent) => {
    event.preventDefault();
    if (!isChecked) {
      alert('Please accept the terms by checking the box before submitting.');
      return;
    }
    confirmAndRedirectToAuth0();
  };

  const confirmAndRedirectToAuth0 = () => {
    window.location.href = `https://${domain}/continue?state=${state}&confirm=yes`;
  };
  const handleCancel = () => {
    // Navigate back to previous page or any other page you prefer
    logout({
      logoutParams: {
        returnTo: window.location.origin,
      },
    });
  };

  return (
    <ThemeProvider theme={theme}>
      <EulaContainer>
        <EulaContent>
          <EulaLogoContainer>
            <EulaLogo />
          </EulaLogoContainer>
          <Grid
            container
            display="flex"
            alignItems="flex-start"
            flexDirection="column"
          >
            <Grid
              container
              width="100%"
              py={2}
              mb={2}
            >
              <iframe
                title="HTML Document"
                src="/Laboratory-EULA-2023-08-06.html"
                style={{
                  width: '100%',
                  height: '400px',
                  border: '1px solid rgba(255, 255, 255, 0.05)',
                  borderRadius: '0.5rem',
                }}
              />
            </Grid>
            <form
              onSubmit={handleSubmit}
              style={{ width: '100%' }}
            >
              <Grid pb={4}>
                <label>
                  <input
                    type="checkbox"
                    checked={isChecked}
                    onChange={(e) => setIsChecked(e.target.checked)}
                  />
                  <span>I have read the EULA and accept the terms.</span>
                </label>
              </Grid>
              <Grid
                width="100%"
                display="flex"
                flexDirection="row"
                justifyContent="space-between"
              >
                <EulaCancelButton
                  variant="contained"
                  type="button"
                  onClick={handleCancel}
                >
                  Cancel
                </EulaCancelButton>
                <EulaSubmitButton
                  variant="contained"
                  type="submit"
                  aria-label="Submit"
                >
                  Submit
                </EulaSubmitButton>
              </Grid>
            </form>
          </Grid>
        </EulaContent>
      </EulaContainer>
    </ThemeProvider>
  );
};
