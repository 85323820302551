import { CodeOffRounded, CodeRounded } from '@mui/icons-material';
import { Box, IconButton, Tab, Tabs, Typography } from '@mui/material';
import { ReactQueryDevtoolsPanel } from '@tanstack/react-query-devtools';
import { FunctionComponent, SyntheticEvent, useState } from 'react';
import { SETTINGS_NAMES, useSettingsStore } from '../Settings/store';
import { DeveloperOnlyFeatures } from './DeveloperOnlyFeatures';
import { DeveloperPreReleaseFeatures } from './DeveloperPreReleaseFeatures';
import { DeveloperTechnicalInfo } from './DeveloperTechnicalInfo';
import { UserInfo } from './UserInfo';

type TabPanelProps = {
  children: React.ReactNode;
  index: number;
  value: number;
};

function TabPanel(props: TabPanelProps) {
  const { children, value, index } = props;

  return (
    <Box
      component="div"
      hidden={value !== index}
      sx={{
        px: 2,
        pt: 1,
        pb: 0,
        overflow: 'auto',
        height: '100%',
      }}
    >
      {value === index && <Box component="div">{children}</Box>}
    </Box>
  );
}

// For production builds export null results for these components
let DeveloperMenu: FunctionComponent = () => null;
let DeveloperContainer: FunctionComponent = () => null;

// *** IMPORTANT *** //
// NOTE: since this needs to be evaluated at compile time, cannot use an imported function or string to test
//      whether we are in production build.
if (process.env.REACT_APP_ENVIRONMENT_NAME !== 'production') {
  DeveloperMenu = () => {
    const isDeveloperMenuEnabled = useSettingsStore(
      (state) => state.settings[SETTINGS_NAMES.DEVELOPER_MENU_ENABLED],
    );

    const isDeveloperMenuOpen = useSettingsStore(
      (state) => state.settings[SETTINGS_NAMES.DEVELOPER_MENU_OPENED],
    );

    const settings = useSettingsStore();

    if (isDeveloperMenuEnabled) {
      return (
        <IconButton onClick={() => settings.toggleSetting(SETTINGS_NAMES.DEVELOPER_MENU_OPENED)}>
          {isDeveloperMenuOpen ? (
            <CodeOffRounded color="secondary" />
          ) : (
            <CodeRounded color="secondary" />
          )}
        </IconButton>
      );
    }

    return null;
  };

  DeveloperContainer = () => {
    const nameDevTab = SETTINGS_NAMES.DEVELOPER_MENU_TAB;
    const devTab = useSettingsStore((state) => state.custom[nameDevTab]) || 0;

    const setCustom = useSettingsStore((state) => state.setCustom);

    const isDeveloperMenuEnabled = useSettingsStore(
      (state) => state.settings[SETTINGS_NAMES.DEVELOPER_MENU_ENABLED],
    );

    const isDeveloperMenuOpen = useSettingsStore(
      (state) => state.settings[SETTINGS_NAMES.DEVELOPER_MENU_OPENED],
    );

    const [value, setValue] = useState(devTab);

    const handleChange = (_event: SyntheticEvent, newValue: number) => {
      setCustom(nameDevTab, newValue);
      setValue(newValue);
    };

    // Add new TAB contents here:
    const tabs = [
      {
        name: 'Pre-release Features',
        panel: <DeveloperPreReleaseFeatures />,
      },
      {
        name: 'User Information',
        panel: <UserInfo />,
      },
      {
        name: 'React-Query DevTools',
        panel: (
          <ReactQueryDevtoolsPanel
            setIsOpen={() => {}}
            onDragStart={() => {}}
          />
        ),
      },
      {
        name: 'Developer Only Features',
        panel: <DeveloperOnlyFeatures />,
      },
      {
        name: 'Technical Information',
        panel: <DeveloperTechnicalInfo />,
      },
    ];

    // if not in development build or menu closed, return null
    if (isDeveloperMenuEnabled && isDeveloperMenuOpen) {
      return (
        <div
          style={{
            display: 'grid',
            gridTemplateRows: 'min-content 1fr',
          }}
        >
          <Box
            component="div"
            sx={{
              p: 2,
              pb: 0,
              boxShadow: '0px -4px 5px #202020',
            }}
          >
            <Typography
              variant="h5"
              color="secondary"
            >
              Developer Tools
            </Typography>
            <Tabs
              value={value}
              onChange={handleChange}
              textColor="secondary"
              indicatorColor="secondary"
            >
              {tabs.map((tab, index) => (
                <Tab
                  key={index}
                  label={tab.name}
                />
              ))}
            </Tabs>
          </Box>

          <Box
            component="div"
            sx={{
              display: 'flex',
              flexDirection: 'column',
              overflow: 'hidden',
            }}
          >
            {tabs.map((tab, index) => (
              <TabPanel
                key={index}
                value={value}
                index={index}
              >
                {tab.panel}
              </TabPanel>
            ))}
          </Box>
        </div>
      );
    }

    return null;
  };
}

export { DeveloperMenu, DeveloperContainer };
