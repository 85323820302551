import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import SmaPerigeeMarker from 'src/threejs/components/Orbit/markers/SmaPerigeeMarker';
import AopMarker from './markers/AopMarker';
import EccMarker from './markers/EccMarker';
import IncMarker from './markers/IncMarker';
import RaanMarker from './markers/RaanMarker';
import TaMarker from './markers/TaMarker';

import { useThree } from '@react-three/fiber';
import {
  AOP_MARKER_HANDLE_NAME,
  ECC_MARKER_HANDLE_NAME,
  INC_MARKER_HANDLE_NAME,
  MARKER_PRIORITIES,
  RAAN_MARKER_HANDLE_NAME,
  SMA_MARKER_HANDLE_NAME,
  TA_MARKER_HANDLE_NAME,
  UNKNOWN_NAME,
} from 'src/constants';
import useCamera from 'src/threejs/hooks/useCamera';
import { ManipulatorName } from 'src/threejs/types';
import { Mesh, Raycaster, Vector2 } from 'three';

interface MarkerPrioritizerProps {
  isECI: boolean;
  isRIC: boolean;
  isReadOnly: boolean;
  showOrbitPath: boolean;
}

export const MarkerPrioritizer = ({
  isECI,
  isRIC,
  isReadOnly,
  showOrbitPath,
}: MarkerPrioritizerProps) => {
  const { current: raycaster } = useRef(new Raycaster());

  const { gl } = useThree();
  const camera = useCamera();

  const [markerRaan, setMarkerRaan] = useState<Mesh | null>(null);
  const [markerHandleRaan, setMarkerHandleRaan] = useState<Mesh | null>(null);

  const [markerAop, setMarkerAop] = useState<Mesh | null>(null);
  const [markerHandleAop, setMarkerHandleAop] = useState<Mesh | null>(null);
  const [markerEcc, setMarkerEcc] = useState<Mesh | null>(null);
  const [markerHandleEcc, setMarkerHandleEcc] = useState<Mesh | null>(null);
  const [markerInc, setMarkerInc] = useState<Mesh | null>(null);
  const [markerHandleInc, setMarkerHandleInc] = useState<Mesh | null>(null);
  const [markerTa, setMarkerTa] = useState<Mesh | null>(null);
  const [markerHandleTa, setMarkerHandleTa] = useState<Mesh | null>(null);
  const [markerHandleSma, setMarkerHandleSma] = useState<Mesh | null>(null);

  const domElement = gl.domElement as HTMLCanvasElement | undefined;
  const mousePickPosition = useMemo(() => new Vector2(), []);

  const setPickPosition = useCallback(
    (event: MouseEvent) => {
      if (domElement) {
        const rect = domElement.getBoundingClientRect();
        const canvasX = ((event.clientX - rect.left) * domElement.offsetWidth) / rect.width;
        const canvasY = ((event.clientY - rect.top) * domElement.offsetHeight) / rect.height;

        // normalized click position
        mousePickPosition.x = (canvasX / domElement.offsetWidth) * 2 - 1;
        mousePickPosition.y = -(canvasY / domElement.offsetHeight) * 2 + 1;
      }
    },
    [domElement, mousePickPosition],
  );

  const [priorityMarker, setPriority] = useState(UNKNOWN_NAME);
  const [dragging, setDrag] = useState(false);

  const handleDomMouseUp = useCallback(() => {
    setDrag(false);
    setPriority(UNKNOWN_NAME);
  }, []);

  const handlePointerDown = useCallback(
    (event: MouseEvent) => {
      setDrag(true);
      setPickPosition(event);

      if (raycaster.params.Line && raycaster.params.Points) {
        raycaster.params.Line.threshold = 10;
        raycaster.params.Points.threshold = 10;
      }

      // get the hit position on the plane
      if (camera) raycaster.setFromCamera(mousePickPosition, camera);

      const selectedMarkers: ManipulatorName[] = [];

      // begin selection priority by recognizing which marker + marker handle has valid refs
      const markersToRaycast = [
        [markerRaan, markerHandleRaan],
        [markerAop, markerHandleAop],
        [markerEcc, markerHandleEcc],
        [markerInc, markerHandleInc],
        [markerTa, markerHandleTa],
      ]
        .filter(([marker, handle]) => marker && handle)
        .flat();
      if (markerHandleSma) markersToRaycast.push(markerHandleSma);

      // for each valid marker + marker handle ref, find out which ones were selected
      markersToRaycast.forEach((markerOrHandle: Mesh | null) => {
        if (!markerOrHandle) return;
        const intersections = raycaster.intersectObject(markerOrHandle, true);
        if (intersections.length) {
          const [intersectionObject] = intersections;
          const identifiedMarkerAndHandle = Object.keys(MARKER_PRIORITIES).find((name) =>
            intersectionObject.object.name.includes(name),
          );
          if (identifiedMarkerAndHandle)
            selectedMarkers.push(identifiedMarkerAndHandle as ManipulatorName);
        }
      });

      // find highest priority marker/handle out of those selected
      const highestPriorityNumber =
        selectedMarkers.length &&
        Math.max(...selectedMarkers.map((m: ManipulatorName) => MARKER_PRIORITIES[m] || -999));

      // get the marker handle name associated with the selected marker/marker handle
      const highestPrioritySelectedMarker =
        selectedMarkers.length &&
        selectedMarkers.find(
          (name: ManipulatorName) => MARKER_PRIORITIES[name] === highestPriorityNumber,
        );

      // render only the marker (and its handle) that was the highest priority
      setPriority(highestPrioritySelectedMarker || UNKNOWN_NAME);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [raycaster, setPickPosition],
  );

  useEffect(() => {
    if (domElement) {
      domElement.addEventListener('pointerdown', handlePointerDown);
      domElement.addEventListener('mouseup', handleDomMouseUp);

      return function cleanup() {
        if (domElement) {
          domElement.removeEventListener('pointerdown', handlePointerDown);
          domElement.removeEventListener('mouseup', handleDomMouseUp);
        }
      };
    }
  }, [domElement, handlePointerDown, handleDomMouseUp]);

  const RaanMarkerAndHandle = (
    <RaanMarker
      handle={markerHandleRaan}
      setHandle={setMarkerHandleRaan}
      marker={markerRaan}
      setMarker={setMarkerRaan}
    />
  );

  const AopMarkerAndHandle = (
    <AopMarker
      handle={markerHandleAop}
      setHandle={setMarkerHandleAop}
      marker={markerAop}
      setMarker={setMarkerAop}
    />
  );

  const EccMarkerAndHandle = (
    <EccMarker
      handle={markerHandleEcc}
      setHandle={setMarkerHandleEcc}
      marker={markerEcc}
      setMarker={setMarkerEcc}
    />
  );

  const IncMarkerAndHandle = (
    <IncMarker
      handle={markerHandleInc}
      setHandle={setMarkerHandleInc}
      marker={markerInc}
      setMarker={setMarkerInc}
    />
  );

  const SmaMarkerAndHandle = (
    <SmaPerigeeMarker
      handle={markerHandleSma}
      setHandle={setMarkerHandleSma}
    />
  );

  const TaMarkerAndHandle = (
    <TaMarker
      disabled={isRIC}
      handle={markerHandleTa}
      setHandle={setMarkerHandleTa}
      marker={markerTa}
      setMarker={setMarkerTa}
    />
  );

  const handleIsSelected = dragging && priorityMarker !== UNKNOWN_NAME;

  return (
    <>
      {isECI &&
        !isReadOnly &&
        [
          [RAAN_MARKER_HANDLE_NAME, RaanMarkerAndHandle],
          [AOP_MARKER_HANDLE_NAME, AopMarkerAndHandle],
          [ECC_MARKER_HANDLE_NAME, EccMarkerAndHandle],
          [INC_MARKER_HANDLE_NAME, IncMarkerAndHandle],
          [SMA_MARKER_HANDLE_NAME, SmaMarkerAndHandle],
        ].map(([markerHandleName, MarkerAndHandle], i: number) => {
          const mh = <group key={`${i}${markerHandleName}`}>{MarkerAndHandle}</group>;
          return handleIsSelected ? priorityMarker === markerHandleName && mh : mh;
        })}
      {(isECI || isRIC) &&
        !isReadOnly &&
        !showOrbitPath &&
        (handleIsSelected
          ? priorityMarker === TA_MARKER_HANDLE_NAME && TaMarkerAndHandle
          : TaMarkerAndHandle)}
    </>
  );
};
