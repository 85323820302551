import { Box, Grid, Tooltip } from '@mui/material';
import { LiveDataTableItem } from './LiveDataTableItem';
import { StateVectorType } from 'src/types';
import { positiveDegAngle, radToDeg } from 'src/utilities/MathUtils';
import { valuesIn } from 'lodash';
import { useAltitudeSpeedQuery, useCurrentOrbit, useIsPropagating } from 'src/hooks/OrbitHooks';
import { useCurrentPage } from 'src/hooks/PageHooks';
import { useCallback } from 'react';
import { OrbitStoreState } from 'src/threejs/components/OrbitManager/store/types';

type DisplayedSvValues = keyof StateVectorType['coe'];

export const LiveDataKeplerian = () => {
  const currentOrbit = useCurrentOrbit();
  // TODO: once TLE's are propagated correctly, need to reflect that here
  let environment = 'Keplerian';
  let environmentTooltip = 'The environment is NOT being influenced by perturbations';

  const isPropagating = useIsPropagating();
  const hasPertubations = valuesIn(currentOrbit?.perturbations).includes(true);

  if (isPropagating && hasPertubations) {
    environment = 'Perturbed';
    environmentTooltip = 'The environment is being influenced by perturbations';
  }

  const getCOE = useCallback(
    (val: DisplayedSvValues) => {
      return (orbitState: OrbitStoreState) => {
        if (!currentOrbit?.id) return 0;
        const activeSv = orbitState.orbits[currentOrbit.id]?.activeStateVector;
        if (!activeSv) return 0;
        return activeSv.coe[val];
      };
    },
    [currentOrbit?.id],
  );

  const getSpeedAlt = useCallback(
    (val: 'speed' | 'altitude') => {
      return (orbitState: OrbitStoreState) => {
        if (!currentOrbit?.id) return 0;
        const activeSv = orbitState.orbits[currentOrbit.id]?.activeStateVector;
        if (!activeSv) return 0;
        return activeSv[val];
      };
    },
    [currentOrbit?.id],
  );

  const getPosDegAngleCOE = useCallback(
    (val: DisplayedSvValues) => (orbitState: OrbitStoreState) => {
      return positiveDegAngle(radToDeg(getCOE(val)(orbitState)));
    },
    [getCOE],
  );

  const getSmaVal = useCallback(
    (orbitState: OrbitStoreState) => getCOE('semiMajorAxis')(orbitState) / 1000,
    [getCOE],
  );
  const getEccVal = useCallback(
    (orbitState: OrbitStoreState) => getCOE('eccentricity')(orbitState),
    [getCOE],
  );
  const getIncVal = getPosDegAngleCOE('inclination');
  const getRaanVal = getPosDegAngleCOE('rightAscensionOfAscendingNode');
  const getAopVal = getPosDegAngleCOE('argumentOfPeriapsis');
  const getTaVal = getPosDegAngleCOE('trueAnomaly');

  const getSpeed = getSpeedAlt('speed');
  const getAlt = useCallback(
    (orbitState: OrbitStoreState) => getSpeedAlt('altitude')(orbitState) / 1000,
    [getSpeedAlt],
  );

  const selectedOrbit = currentOrbit?.orbit?.length && currentOrbit?.orbit[0];
  let currentCOE;
  if (selectedOrbit) {
    currentCOE = {
      argumentOfPeriapsis: selectedOrbit.argumentOfPeriapsis,
      eccentricity: selectedOrbit.eccentricity,
      inclination: selectedOrbit.inclination,
      rightAscensionOfAscendingNode: selectedOrbit.rightAscensionOfAscendingNode,
      semiMajorAxis: selectedOrbit.semiMajorAxis,
      trueAnomaly: selectedOrbit.trueAnomaly,
    };
  }

  const startTime = useCurrentPage()?.startTime.getTime();

  const { data: reqData } = useAltitudeSpeedQuery(currentCOE, startTime);

  return (
    <Grid
      pt={1}
      container
      flexDirection="column"
    >
      <Tooltip
        title={environmentTooltip}
        arrow
      >
        <div>
          <Box
            component="span"
            sx={{
              fontSize: 12,
              fontWeight: 'bold',
            }}
            pr={1}
          >
            Environment:
          </Box>
          <Box
            component="span"
            sx={{ fontSize: 11 }}
          >
            {environment}
          </Box>
        </div>
      </Tooltip>
      <LiveDataTableItem
        name="Semi-Major Axis (a)"
        unit=""
        headerUnit="km"
        initialValue={currentCOE?.semiMajorAxis ?? 0}
        svValueSelector={getSmaVal}
      />
      <LiveDataTableItem
        name="Eccentricity (e)"
        unit=""
        headerUnit=""
        initialValue={currentCOE?.eccentricity ?? 0}
        svValueSelector={getEccVal}
      />
      <LiveDataTableItem
        name="Inclination (i)"
        unit="°"
        headerUnit="deg"
        initialValue={currentCOE?.inclination ?? 0}
        svValueSelector={getIncVal}
      />
      <LiveDataTableItem
        name="RAAN (Ω)"
        unit="°"
        headerUnit="deg"
        initialValue={currentCOE?.rightAscensionOfAscendingNode ?? 0}
        svValueSelector={getRaanVal}
      />
      <LiveDataTableItem
        name="Argument of Periapsis (ω)"
        unit="°"
        headerUnit="deg"
        initialValue={currentCOE?.argumentOfPeriapsis ?? 0}
        svValueSelector={getAopVal}
      />
      <LiveDataTableItem
        name="True Anomaly (v)"
        unit="°"
        headerUnit="deg"
        initialValue={currentCOE?.trueAnomaly ?? 0}
        svValueSelector={getTaVal}
      />
      <LiveDataTableItem
        name="Altitude"
        unit=""
        headerUnit="km"
        initialValue={Math.abs(reqData?.altitude ?? 0)}
        svValueSelector={getAlt}
      />
      <LiveDataTableItem
        name="Speed"
        unit=""
        headerUnit="km/s"
        initialValue={reqData?.speed ?? 0}
        svValueSelector={getSpeed}
      />
    </Grid>
  );
};
