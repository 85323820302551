/**
 * Returns shortened string (with ellipsis if necessary) trimmed to space after maxLength
 * @param {string} text - string to be shortened
 * @param {number} maxLength - length to shorten to
 */
const DEFAULT_MAX_LENGTH = 20;

export const shortenStringNextSpace = (text: string, maxLength: number = DEFAULT_MAX_LENGTH) => {
  if (text.length <= maxLength) {
    return text;
  }
  const trimmed = text.replace(new RegExp('^(.{' + maxLength + '}[^\\s]*).*', 'g'), '$1');
  if (trimmed === text) {
    return trimmed;
  }
  return trimmed + '…'; // only add the ellipsis if it was shortened
};

export const getIterativeNameFromList = (
  nameOriginal: string,
  namesExisting: string[] = [],
  nameAddition = '',
) => {
  const regex = `/( ${nameAddition} d+$)|( ${nameAddition})/g`; // find if name ends in ' copy ###'
  const nameBase = nameOriginal.replace(regex, '');

  const nameAdditionToUse = nameAddition !== '' ? ` ${nameAddition} ` : ' ';

  let iteration = 1;
  let nameCopy = nameBase + nameAdditionToUse + iteration;
  while (namesExisting?.includes(nameCopy)) {
    iteration++;
    nameCopy = nameBase + nameAdditionToUse + iteration;
  }

  return nameCopy;
};

export type fractionDigitOptions = { maximumFractionDigits: number; minimumFractionDigits: number };

export const formatValue = (
  val: number,
  options: fractionDigitOptions = {
    maximumFractionDigits: 2,
    minimumFractionDigits: 2,
  },
) => `${new Intl.NumberFormat('en-US', options).format(val)}`;
