import { AccordionDetails, FormControlLabel, FormGroup, Switch } from '@mui/material';
import { DEFAULT_ADDITIONAL_PROPERTIES_GROUND_OBJECTS } from 'src/constants';
import {
  useCurrentGroundObject,
  useUpdateGroundObjectAdditionalProperties,
} from 'src/hooks/GroundObjectHooks';
import { GroundObjectAdditionalProperties } from 'src/types';

const GroundObjectConeToggle = ({
  checked,
  onChange,
  label,
}: {
  checked: boolean;
  onChange: () => void;
  label: string;
}) => (
  <>
    <FormControlLabel
      sx={{ m: 0 }}
      control={
        <Switch
          size="small"
          checked={checked}
          onChange={onChange}
        />
      }
      label={<>{label}</>}
    />
  </>
);

export function GroundObjectVisibilityToggle({
  visType,
  optionLabel,
}: {
  visType: keyof Pick<
    GroundObjectAdditionalProperties,
    'visGroundObjConeShader' | 'visGroundObjConeWireframe'
  >;
  optionLabel: string;
}) {
  const currentGroundObj = useCurrentGroundObject();

  const updateGroundObjAdditionalProperties = useUpdateGroundObjectAdditionalProperties();

  const currentVisibility = currentGroundObj?.additionalProperties
    ? currentGroundObj.additionalProperties[visType]
    : DEFAULT_ADDITIONAL_PROPERTIES_GROUND_OBJECTS[visType];

  const setObjectVis = () => {
    if (currentGroundObj) {
      const changes = { [visType]: !currentVisibility };
      updateGroundObjAdditionalProperties(currentGroundObj, changes);
    }
  };

  if (!currentGroundObj) {
    return null;
  }

  return (
    <AccordionDetails>
      <FormGroup>
        <GroundObjectConeToggle
          label={optionLabel}
          checked={currentVisibility}
          onChange={setObjectVis}
        />
      </FormGroup>
    </AccordionDetails>
  );
}
