import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useCallback, useEffect, useMemo, useState } from 'react';
import {
  DEFAULT_ADDITIONAL_PROPERTIES_ORBIT,
  ORBIT_COLOR_DEFAULT,
  ORBIT_TYPES,
} from 'src/constants';
import PropagatedCacheManager from 'src/models/PropagatedCacheManager';
import { router } from 'src/pages/App/routes/Router';
import {
  getActiveCapabilityId,
  getActiveNotebookId,
  getActiveObjectId,
  getActivePageId,
  useRouteStore,
} from 'src/pages/App/routes/store';
import { useManeuverStore } from 'src/pages/Notebook/components/Maneuvers/store';
import { fetchApi } from 'src/services/api';
import { removeOrbit } from 'src/services/OrbitService';
import { initializeOrbitInStore } from 'src/threejs/components/OrbitManager/store/utils';
import {
  CancellablePromise,
  Maneuver,
  OrbitAdditionalProperties,
  OrbitObject,
  OrbitPostResponse,
} from 'src/types';
import { useGroundObjects } from './GroundObjectHooks';
import { useApiSnackbarError } from './SnackbarHooks';
import {
  WINDOWS_TYPES,
  useViewingWindowsStore,
} from 'src/pages/Notebook/components/ViewingWindowsStore';

const tinycolor = require('tinycolor2');

const deserializeOrbit = (orbit: OrbitObject): OrbitObject => {
  const orbitDeserialized = {
    ...orbit,
    additionalProperties: {
      ...DEFAULT_ADDITIONAL_PROPERTIES_ORBIT,
      ...orbit.additionalProperties,
    },
  };

  orbitDeserialized.maneuvers = orbitDeserialized.maneuvers
    .map((maneuver) => {
      return {
        ...maneuver,
        timestamp: new Date(maneuver.timestamp),
      };
    })
    .sort((a, b) => a.timestamp.getTime() - b.timestamp.getTime());

  return orbitDeserialized;
};

export function useOrbitsQuery(pageId?: number) {
  const apiSnackbarError = useApiSnackbarError();

  const setManeuverColor = useManeuverStore((state) => state.setColor);

  return useQuery<OrbitObject[]>(
    ['orbitsForPage', pageId],
    () => {
      const controller = new AbortController();
      const signal = controller.signal;

      const promise = fetchApi(`/v2/orbits?pageId=${pageId}`, { signal }).then((data) => {
        const orbits = data.orbits.map((orbit: OrbitObject) => deserializeOrbit(orbit));

        orbits.forEach((orbit: OrbitObject) => {
          const orbitColor = orbit?.additionalProperties?.color || ORBIT_COLOR_DEFAULT;

          // start off 25% lighter
          let colorInitial = tinycolor(orbitColor).brighten(25);
          let colorTarget = ORBIT_COLOR_DEFAULT;

          if (tinycolor.equals(orbitColor, ORBIT_COLOR_DEFAULT)) {
            colorInitial = tinycolor(orbitColor).darken(25);
            colorTarget = '#333';
          }

          orbit.maneuvers.forEach((maneuver: Maneuver, index: number) => {
            if (maneuver?.id) {
              // step the color mixing out for each maneuver in orbit
              const mixAmount = 100 * (index / orbit.maneuvers.length);
              const maneuverColor = tinycolor.mix(colorInitial, colorTarget, mixAmount).toString();
              setManeuverColor(maneuver.id, maneuverColor);
            }
          });

          initializeOrbitInStore(orbit);
        });
        return orbits;
      }) as CancellablePromise<OrbitObject[]>;

      promise.cancel = () => {
        controller.abort();
      };

      return promise;
    },
    {
      enabled: Boolean(pageId),
      onError: () => {
        apiSnackbarError('Failed to get orbit(s).');
      },
    },
  );
}
export function useOrbits(pageId?: number) {
  const { data } = useOrbitsQuery(pageId);

  return data;
}

export function useCurrentOrbits() {
  const pageId = useRouteStore(getActivePageId);
  return useOrbits(pageId);
}

export function useCurrentOrbit() {
  const objectId = useRouteStore(getActiveObjectId);
  const orbits = useCurrentOrbits();
  return useMemo(() => orbits?.find((orbit) => orbit.id === objectId), [objectId, orbits]);
}

export function useOrbit(id: number) {
  const orbits = useCurrentOrbits();
  return orbits?.find((orbit) => orbit.id === Number(id));
}

export function useSelectOrbit() {
  const capabilityId = useRouteStore(getActiveCapabilityId);
  const notebookId = useRouteStore(getActiveNotebookId);
  const pageId = useRouteStore(getActivePageId);

  return useCallback(
    (orbitId: number | null) => {
      if (capabilityId) {
        router.navigate(
          `/shared/${capabilityId}/notebook/${notebookId}/${pageId}${orbitId ? `/${orbitId}` : ''}`,
        );
      } else {
        router.navigate(`/notebook/${notebookId}/${pageId}${orbitId ? `/${orbitId}` : ''}`);
      }
    },
    [capabilityId, notebookId, pageId],
  );
}

/** Sets new orbits in the react-query cache for the current page id. */
export function useSetOrbits() {
  const pageId = useRouteStore(getActivePageId);
  const queryClient = useQueryClient();

  const setOrbits = useCallback(
    (orbits: OrbitObject[] | undefined) => {
      queryClient.setQueryData(['orbitsForPage', pageId], () => {
        return orbits ? [...orbits] : undefined;
      });
    },
    [pageId, queryClient],
  );

  return setOrbits;
}

export function useCreateOrbitMutation() {
  const currentPageId = useRouteStore(getActivePageId);

  const apiSnackbarError = useApiSnackbarError();

  const queryClient = useQueryClient();
  return useMutation(
    ({
      platformId,
      name,
      notes,
      orbit,
      orbitTLE,
      orbitType,
      perturbations,
      maneuvers,
      groupId,
      additionalProperties,
      initialEpoch,
      stateVectors,
      newPageId,
    }: Partial<OrbitObject & { newPageId?: number }>) => {
      // if a page ID is specified then use that instead of what the current page ID is
      // if needing to create an orbit for a page you are not on
      const pageIdToUse = newPageId || currentPageId;
      const reqBody: Partial<OrbitObject> = {
        platformId,
        name,
        notes,
        orbitType,
        perturbations,
        maneuvers,
        groupId,
        additionalProperties,
        initialEpoch,
        stateVectors,
      };

      if (
        orbit &&
        orbitType !== undefined &&
        [
          ORBIT_TYPES.COE,
          ORBIT_TYPES.STATE_VECTORS,
          ORBIT_TYPES.EPHEMERIS,
          ORBIT_TYPES.LAUNCH,
        ].includes(orbitType)
      ) {
        reqBody.orbit = orbit;
      } else if (orbitType === ORBIT_TYPES.TLE) {
        reqBody.orbitTLE = orbitTLE;
      }

      return fetchApi(`/v2/orbits?pageId=${pageIdToUse}`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          pageId: pageIdToUse,
          orbits: [reqBody],
        }),
      });
    },
    {
      onError: () => {
        apiSnackbarError('Failed to create orbit.');
      },
      onSuccess: (data: OrbitPostResponse) => {
        initializeOrbitInStore(data?.orbits[0]);
        queryClient.invalidateQueries(['orbitsForPage', data.pageId]);
      },
    },
  );
}

export function useCreateOrbitDefaultMutation() {
  const queryClient = useQueryClient();
  const currentPageId = useRouteStore(getActivePageId);

  return useMutation(
    ({ requestedPageId }: { requestedPageId?: number }) => {
      const pageId = requestedPageId || currentPageId;
      return fetchApi(
        `/v2/orbitsDefault?pageId=${pageId}&orbitType=MEO&orbitDataType=${ORBIT_TYPES.COE}`,
        {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
        },
      );
    },
    {
      onSuccess: (data: OrbitPostResponse) => {
        initializeOrbitInStore(data?.orbits[0]);
        queryClient.invalidateQueries(['orbitsForPage', data.pageId]);
      },
    },
  );
}

export function useUpdateOrbitMutation() {
  const pageId = useRouteStore(getActivePageId);
  const currentOrbits = useCurrentOrbits();

  const apiSnackbarError = useApiSnackbarError();

  const invalidateWindows = useViewingWindowsStore((state) => state.invalidateWindows);
  const invalidateWindowsAllByGroupId = useViewingWindowsStore(
    (state) => state.invalidateWindowsAllByGroupId,
  );
  const invalidateWindowsAllByObjectid = useViewingWindowsStore(
    (state) => state.invalidateWindowsAllByObjectid,
  );

  const queryClient = useQueryClient();
  return useMutation(
    (updateReq: Partial<OrbitObject>[] | Partial<OrbitObject>) => {
      const orbit = Array.isArray(updateReq) ? updateReq[0] : updateReq;

      const currentOrbitInfo = currentOrbits?.find((orb) => orb.id === orbit.id);
      const newOrbit = {
        ...currentOrbitInfo,
        ...orbit,
      };

      return fetchApi('/v2/orbits', {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          pageId,
          orbits: [newOrbit],
        }),
      });
    },
    {
      onError: () => {
        apiSnackbarError('Failed to update orbit.');
      },
      onSuccess: async (data) => {
        await queryClient.invalidateQueries(['orbitsForPage', pageId]);

        const orbitUpdated = data.orbits[0];

        // if groupid is changed, need to invalidate the previous one
        const prevOrbit = currentOrbits?.find((orbit) => orbit.id === orbitUpdated.id);
        if (prevOrbit && prevOrbit.groupId !== orbitUpdated.groupId) {
          invalidateWindowsAllByGroupId(WINDOWS_TYPES.GROUND_OBJECT, prevOrbit.groupId);
        }
        // invalidate all ground objects that have new groupid as target
        if (orbitUpdated.groupId) {
          invalidateWindowsAllByGroupId(WINDOWS_TYPES.GROUND_OBJECT, orbitUpdated.groupId);
        }

        // invalidate any sensors that target this object id
        invalidateWindowsAllByObjectid(WINDOWS_TYPES.GROUND_OBJECT, orbitUpdated.id);

        // also, invalidate windows for this orbit/space sensor
        invalidateWindows(WINDOWS_TYPES.SPACE_SENSOR, orbitUpdated.id);
      },
    },
  );
}

export function useUpdateOrbitAdditionalProperties() {
  const updateOrbit = useUpdateOrbitMutation();

  return (orbit: OrbitObject, changes: Partial<OrbitAdditionalProperties>) => {
    if (orbit) {
      const orbitUpdate: Partial<OrbitObject> = {
        ...orbit,
        id: orbit.id,
        name: orbit.name,
        additionalProperties: {
          ...DEFAULT_ADDITIONAL_PROPERTIES_ORBIT,
          ...orbit.additionalProperties,
          ...changes,
        },
      };
      updateOrbit.mutateAsync(orbitUpdate);
    }
  };
}

type DeleteOrbitMutationVariables = {
  orbitId: number;
  pageId: number;
};

export function useDeleteOrbitMutation() {
  const selectOrbit = useSelectOrbit();
  const currentOrbits = useCurrentOrbits();
  const pageId = useRouteStore(getActivePageId);
  const groundObjects = useGroundObjects(pageId);

  const invalidateWindows = useViewingWindowsStore((state) => state.invalidateWindows);

  const apiSnackbarError = useApiSnackbarError();

  const queryClient = useQueryClient();
  return useMutation(
    ({ orbitId }: DeleteOrbitMutationVariables) =>
      fetchApi(`/v2/orbits/${orbitId}`, {
        method: 'DELETE',
      }),
    {
      onError: () => {
        apiSnackbarError('Failed to delete orbit.');
      },
      onSuccess: (_data, { orbitId, pageId }) => {
        const orbitsForPage: OrbitObject[] =
          queryClient.getQueryData(['orbitsForPage', pageId]) ?? [];
        const [reminder, nextOrbit] = removeOrbit(orbitsForPage, orbitId);
        queryClient.setQueryData(['orbitsForPage', pageId], reminder);
        selectOrbit(nextOrbit?.id || null);

        const prevOrbit = currentOrbits?.find((orb) => orb.id === orbitId);

        // invalidate query key for all ground object with a contact target of this orbit or the group
        // that this orbit is apart of
        groundObjects?.forEach((groundObj) => {
          if (
            (prevOrbit && groundObj.targetGroupId === prevOrbit.groupId) || // invalidate the query for the group it used to be part of
            groundObj.targetOrbitId === orbitId
          ) {
            invalidateWindows(WINDOWS_TYPES.GROUND_OBJECT, groundObj.id);
          }
        });
      },
    },
  );
}

export function useIsPropagating() {
  const [isPropagating, setIsPropagating] = useState<boolean>(false);
  const orbits = useCurrentOrbits();

  useEffect(() => {
    const onStart = () => setIsPropagating(true);
    const onClear = () => setIsPropagating(false);
    PropagatedCacheManager.addEventListener('startpropagations', onStart);
    PropagatedCacheManager.addEventListener('recieveddata', onStart);
    PropagatedCacheManager.addEventListener('clearpropagations', onClear);
    return function cleanup() {
      PropagatedCacheManager.removeEventListener('startpropagations', onStart);
      PropagatedCacheManager.removeEventListener('recieveddata', onStart);
      PropagatedCacheManager.removeEventListener('clearpropagations', onClear);
    };
  }, []);

  useEffect(() => {
    setIsPropagating(PropagatedCacheManager.hasCachedData(orbits?.map(({ id }) => id) ?? []));
  }, [orbits]);

  return isPropagating;
}

type AltSpeedRequestCOE = {
  argumentOfPeriapsis: number;
  eccentricity: number;
  inclination: number;
  rightAscensionOfAscendingNode: number;
  semiMajorAxis: number;
  trueAnomaly: number;
};

export function useAltitudeSpeedQuery(
  coe: AltSpeedRequestCOE | undefined,
  time: number | undefined,
) {
  const apiSnackbarError = useApiSnackbarError();

  return useQuery(
    ['altitude-speed', JSON.stringify(time) + JSON.stringify(coe)],
    () =>
      fetchApi(
        `/v2/altitudespeed?${new URLSearchParams([
          ['coe', JSON.stringify(coe)],
          ['time', JSON.stringify(time)],
        ])}`,
      ).then((data) => ({ altitude: data.first, speed: data.second })),
    {
      enabled: !!time && !!coe,
      onError: () => {
        apiSnackbarError('Failed to get altitude and speed.');
      },
    },
  );
}
