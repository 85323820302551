import { FormControl, InputLabel, MenuItem, Select, SelectChangeEvent } from '@mui/material';
import useAppStore from 'src/core/store';

const playbackOptions = [
  { label: 'Real Time', rate: 1 },
  { label: '1 min / sec', rate: 60 },
  { label: '10 min / sec', rate: 60 * 10 },
  { label: '1 hour / sec', rate: 3600 },
  { label: '1 day / sec', rate: 86400 },
  { label: '1 week / sec', rate: 604800 },
];

export const TimelineSpeedSelect = () => {
  const playSpeed = useAppStore((state) => state.timelines.timelineRange.playSpeed);
  const updateTimelineRange = useAppStore((state) => state.timelines.updateTimelineRange);

  const onSetPlaySpeed = (event: SelectChangeEvent<number>) => {
    const newPlaySpeed = event.target.value as number;
    updateTimelineRange({
      playSpeed: newPlaySpeed,
    });
  };

  return (
    <FormControl variant="standard">
      <InputLabel id="timeline-speed-select-label">Speed</InputLabel>
      <Select
        labelId="timeline-speed-select-label"
        id="timeline-speed-select"
        value={playSpeed}
        label="Speed"
        onChange={onSetPlaySpeed}
      >
        {playbackOptions.map((option) => (
          <MenuItem
            key={option.rate}
            value={option.rate}
          >
            {option.label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};
