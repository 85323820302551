import { Grid } from '@mui/material';
import { ReactNode } from 'react';
import { SpaceGlassContainerBase } from './SpaceGlassControl';

interface SpaceGlassPanelProps {
  header?: ReactNode;
  children?: ReactNode;
  footer?: ReactNode;
}

export const SpaceGlassPanel = ({ children, header, footer }: SpaceGlassPanelProps) => {
  return (
    <SpaceGlassContainerBase
      style={{
        display: 'grid',
        height: '100%',
        gridTemplateRows: 'min-content 1fr min-content',
      }}
    >
      <div>{header}</div>

      <div
        style={{
          overflow: 'hidden',
        }}
      >
        {children}
      </div>

      <div>
        {footer && (
          <Grid
            sx={{
              px: 1.5,
              py: 1,
            }}
          >
            {footer}
          </Grid>
        )}
      </div>
    </SpaceGlassContainerBase>
  );
};
