import { Avatar, Grid, Link } from '@mui/material';
import { useState } from 'react';
import { PanelHeader } from 'src/components/PanelHeader';
import { SpaceGlassPanel } from 'src/components/SpaceGlassPanel';
import { Bookmark } from 'src/types';
import { millisToUTC } from 'src/utilities/DateTimeUtils';
import { AVATAR_SIZE } from './Avatar';
import { BookmarkCalloutControls } from './BookmarkCalloutControls';
import { avatarOptions } from './ModalBookmark';

const MESSAGE_LIMIT_INITIALLY = 500;

interface BookmarkCalloutProps {
  bookmark: Bookmark;
  orbitName: string;
}

const getAvatar = (avatarName: string) => {
  return avatarOptions.find((avatar) => avatar.name === avatarName);
};

export function BookmarkCallout({ bookmark, orbitName }: BookmarkCalloutProps) {
  const [showAll, setShowAll] = useState(bookmark.callout.length < MESSAGE_LIMIT_INITIALLY);

  const [stopPointerEvents, setStopPointerEvents] = useState(false);

  const headerTime = ` - ${millisToUTC(new Date(bookmark?.timestamp).getTime())}`;

  const renderContent = () => {
    if (bookmark.callout.length < MESSAGE_LIMIT_INITIALLY) {
      return <span>{bookmark.callout}</span>;
    }
    if (showAll) {
      return <span>{bookmark.callout}</span>;
    } else {
      return <span>{bookmark.callout.substring(0, MESSAGE_LIMIT_INITIALLY)}... </span>;
    }
  };

  return (
    <Grid
      container
      spacing={2}
      wrap="nowrap"
    >
      {bookmark.avatarImageUrl && (
        <Grid item>
          <Avatar
            src={getAvatar(bookmark.avatarImageUrl)?.src}
            sx={{
              width: AVATAR_SIZE,
              height: AVATAR_SIZE,
            }}
          />
        </Grid>
      )}

      <Grid
        item
        sx={{
          minHeight: AVATAR_SIZE,
          maxHeight: '50vh',
          width: 'max-content',
          maxWidth: 600,
        }}
      >
        <SpaceGlassPanel
          header={
            <PanelHeader
              draggable={false}
              title={orbitName}
              rightSide={<Grid sx={{ whiteSpace: 'nowrap' }}>{headerTime}</Grid>}
            />
          }
        >
          <Grid
            container
            justifyContent="space-between"
            sx={{
              overflowY: 'auto',
              width: '100%',
            }}
            wrap="nowrap"
          >
            <Grid
              item
              sx={{
                padding: 1,
                overflowY: 'auto',
                whiteSpace: 'break-spaces',
              }}
              onWheel={(event) => {
                event.stopPropagation();
              }}
              onPointerDown={() => setStopPointerEvents(true)}
              onPointerMove={(event) => {
                if (stopPointerEvents) {
                  event.stopPropagation();
                }
              }}
              onPointerUp={() => setStopPointerEvents(false)}
            >
              {renderContent()}

              {!showAll && (
                <Grid
                  container
                  justifyContent="end"
                >
                  <Grid item>
                    <Link
                      onClick={() => setShowAll(true)}
                      sx={{
                        cursor: 'pointer',
                      }}
                    >
                      See More
                    </Link>
                  </Grid>
                </Grid>
              )}
            </Grid>
          </Grid>
        </SpaceGlassPanel>

        <BookmarkCalloutControls bookmark={bookmark} />
      </Grid>
    </Grid>
  );
}
