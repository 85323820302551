import { createContext, FunctionComponent, memo, useContext, useMemo } from 'react';
import { Viewport } from 'src/types';

type ViewportContextValue = {
  children?: React.ReactNode;
  viewport: Viewport;
};

export const ViewportContext = createContext<ViewportContextValue | null>(null);

/** Provides each viewport with the viewport id */
const ViewportProvider: FunctionComponent<ViewportContextValue> = ({ children, viewport }) => {
  const value = useMemo(() => ({ viewport }), [viewport]);
  return <ViewportContext.Provider value={value}>{children}</ViewportContext.Provider>;
};

export function useViewportContext() {
  const context = useContext(ViewportContext);

  if (context === null) {
    throw new Error();
  }

  return context;
}

export default memo(ViewportProvider) as typeof ViewportProvider;
