import { grey } from '@mui/material/colors';
import { geoCircle, geoEquirectangular, geoGraticule, geoPath } from 'd3-geo';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { century, declination, equationOfTime } from 'solar-calculator';
import earthAlbedoTexURL from 'src/assets/1_earth_4k.jpg';
import earthNightTexURL from 'src/assets/5_night_4k.jpg';
import {
  COLOR_GROUND_OBJECT,
  DEFAULT_ADDITIONAL_PROPERTIES_ORBIT,
  DEFAULT_ADDITIONAL_PROPERTIES_PAGE,
  LATLONG_COLOR,
  LATLONG_COLOR_PRIME,
  PROPAGATION_STEPS_PER_REVOLUTION,
  earthradius,
} from 'src/constants';
import { getPlayState } from 'src/core/getters';
import { useCurrentTime, useTimeline } from 'src/core/hooks';
import { useCurrentGroundObjects } from 'src/hooks/GroundObjectHooks';
import { useCurrentGroups } from 'src/hooks/GroupHooks';
import { useCurrentOrbit, useCurrentOrbits, useIsPropagating } from 'src/hooks/OrbitHooks';
import { useCurrentPage } from 'src/hooks/PageHooks';
import useOnPageChange from 'src/hooks/useOnPageChange';
import useSingleDependencyEffect from 'src/hooks/useSingleDependencyEffect';
import PropagatedCacheManager from 'src/models/PropagatedCacheManager';
import TimelineEventManager from 'src/models/TimelineEventManager';
import theme from 'src/pages/App/Theme';
import { getActiveGroundObjectId, useRouteStore } from 'src/pages/App/routes/store';
import { useViewingWindowsStore } from 'src/pages/Notebook/components/ViewingWindowsStore';
import { MaxGroundTrackBufferSize } from 'src/threejs/components/OrbitManager/store/utils';
import getEarthRotation from 'src/threejs/math/getEarthRotation';
import { getLatLongFromPointProjection } from 'src/threejs/math/getLatLongFromPointProjection';
import getTrueAnomalyPosition from 'src/threejs/math/getTrueAnomalyPosition';
import getUVCoordsFromLatLon from 'src/threejs/math/getUVCoordsFromLatLon';
import getUVCoordsFromPointProjection from 'src/threejs/math/getUVCoordsFromPointProjection';
import {
  Coordinate,
  GroundObject,
  GroundTrackPoint,
  OrbitAdditionalProperties,
  OrbitObject,
  SpaceObjectShape,
  SpaceObjectSize,
  SpaceSensor,
} from 'src/types';
import { float2int, positiveNumInRange } from 'src/utilities/MathUtils';
import {
  TRAJECTORY_TYPES,
  getDirectionWrappingFromThreePoints,
  getNearestPointsToIndex,
} from 'src/utilities/UvMathUtils';
import binarySearchForClosestValue from 'src/utilities/binarySearchForClosestValue';
import { Vector2, Vector3 } from 'three';
import { inverseLerp } from 'three/src/math/MathUtils';
import { useEventListener } from 'usehooks-ts';
import { DrawSpaceSensorLayers, drawSpaceSensor } from '../../Earth2D/drawSpaceSensor';
import { useGroundObjectLaunchEditStore } from '../../GroundObjects/GroundObjectLaunchEditStore';
import { getOrbits } from '../../OrbitManager/store/getters';
import use3DOrbitStore from '../../OrbitManager/store/store';
import { OrbitState } from '../../OrbitManager/store/types';
import { useSpaceSensorsStore } from '../../SpaceSensors/SpaceSensorsStore';
import { renderBall, renderCone, renderCube } from './2DShapes';
import islineCanvasWrap from './islineCanvasWrap';
import { UvPointsStore } from './uvPointsStore';
import { SpaceSensorPointingType } from 'src/enums';
import { drawCountries } from '../../Earth2D/drawCountries';

export const CANVAS_WIDTH = 2048;
export const CANVAS_HEIGHT = CANVAS_WIDTH / 2;
const CANVAS_OVERSIZE = 1.1; // adds 10% width for labels that need to wrap to next rotation

const scaleUvToCanvas = (uv: Vector2) => {
  return uv.clone().multiply(new Vector2(CANVAS_WIDTH, CANVAS_HEIGHT));
};

const LATLONG_THICKNESS = 0.25;
const LATLONG_THICKNESS_PRIME = LATLONG_THICKNESS * 4;
const LATLONG_SPACED_LATITUDE = 10;
const LATLONG_SPACED_LONGITUDE = 10;

const D3_GEO_EPSILON = 1e-6;

const MinSegmentPixelThresholdDistance = 5;
const MaxPreviewGroundTrackPoints = 300;

const earthPosition = new Vector3(0, 0, 0);

// setting up offscreen context to use as drawing board to assemble the layers
const offscreenCanvas = document.createElement('canvas');
if (offscreenCanvas) {
  offscreenCanvas.width = CANVAS_WIDTH * CANVAS_OVERSIZE;
  offscreenCanvas.height = CANVAS_HEIGHT;
}
const offscreenContext = offscreenCanvas.getContext('2d');

// setup a separate canvas to draw the country borders on and store on the side
const canvasCountries = document.createElement('canvas');
if (canvasCountries) {
  canvasCountries.width = CANVAS_WIDTH;
  canvasCountries.height = CANVAS_HEIGHT;
}
const contextCountries = canvasCountries.getContext('2d');

export const geoProjectionEarth2D = geoEquirectangular()
  .translate([CANVAS_WIDTH / 2, CANVAS_HEIGHT / 2])
  .scale(326); // magical number right now

const pathGeo = geoPath(geoProjectionEarth2D, offscreenContext);

// pre-draw the country borders on a separate canvas to just be overlaid when needed
if (contextCountries) {
  drawCountries({ context: contextCountries });
}

/**
 * Takes a canvas reference and returns a function that, when called, will draw
 * the earth texture and ground tracks taking into account the whole app state.
 *  */
export function useEarthTexture(
  canvas?: HTMLCanvasElement,
  drawAllTracks = false,
  is3DView = true,
  showNight = false,
): () => void {
  const currentTime = useCurrentTime();

  const editingSpaceSensors = useSpaceSensorsStore((state) => state.editingSpaceSensors);

  const currentOrbit = useCurrentOrbit();
  const currentOrbits = useCurrentOrbits();
  const [imageEarthLoaded, setImageEarthLoaded] = useState(false);
  const [imageEarthNightLoaded, setImageEarthNightLoaded] = useState(false);

  const currentPageGroups = useCurrentGroups();

  const currentGroundObjects = useCurrentGroundObjects();
  const currentGroundObjectId = useRouteStore(getActiveGroundObjectId);

  const groundObjectEdit = useGroundObjectLaunchEditStore((state) => state.groundObjectEdit);

  const isOrbitVisible = useCallback(
    (orbit: OrbitObject) => {
      const orbitGroup = currentPageGroups?.find((group) => group.id === orbit.groupId);
      const pageOrbitGroupVisible =
        orbit.groupId === null || !!orbitGroup?.additionalProperties?.visOrbits;
      return pageOrbitGroupVisible && !!orbit.additionalProperties?.visOrbit;
    },
    [currentPageGroups],
  );

  const orbitVisibilities: Record<number, boolean> = useMemo(
    () =>
      currentOrbits?.reduce((acc, orbit) => {
        acc[orbit.id] = isOrbitVisible(orbit);
        return acc;
      }, {} as { [index: number]: boolean }) || {},
    [currentOrbits, isOrbitVisible],
  );

  const currentPage = useCurrentPage();

  const currentPageAdditionalProperties =
    currentPage?.additionalProperties || DEFAULT_ADDITIONAL_PROPERTIES_PAGE;

  const currentOrbitAdditionalProperties = useMemo(() => {
    const addProps: Record<number, OrbitAdditionalProperties> = {};
    currentOrbits?.forEach((orbit) => {
      addProps[orbit.id] = orbit.additionalProperties || DEFAULT_ADDITIONAL_PROPERTIES_ORBIT;
    });
    return addProps;
  }, [currentOrbits]);

  const [minPeriod, setMinPeriod] = useState(Infinity);

  // store the shortest period of the orbits to account for step size used during propagation
  useEffect(() => {
    const orbitStates = getOrbits();
    const periods = currentOrbits?.map((orbit) => orbitStates[orbit.id]?.orbitData.period) || [];
    setMinPeriod(Math.min(...periods));
  }, [currentOrbits]);

  const lightingEnabled = currentPageAdditionalProperties.lightingEnabled;
  const lightingSun = currentPageAdditionalProperties.lightingSun;
  const lightingNight = currentPageAdditionalProperties.lightingNight;
  const visLatLongLines = currentPageAdditionalProperties.visLatLongLines;
  const visCountries = currentPageAdditionalProperties.visCountries;

  const pageOrbitLabelsVisible = currentPageAdditionalProperties.visOrbitLabels;

  const uvPointsStore: UvPointsStore = useMemo(() => new UvPointsStore(), []);

  const drawPosition = useMemo(() => new Vector2(), []);

  const tempVector2 = useMemo(() => new Vector2(), []);

  const context = useMemo(() => canvas && canvas.getContext('2d', { alpha: false }), [canvas]);

  const worldMapImage = useMemo(() => {
    const image = document.createElement('img');

    image.addEventListener('load', () => setImageEarthLoaded(true), false);
    image.crossOrigin = '';
    image.src = earthAlbedoTexURL;

    return image;
  }, []);
  const worldMapNightImage = useMemo(() => {
    const image = document.createElement('img');

    image.addEventListener('load', () => setImageEarthNightLoaded(true), false);
    image.crossOrigin = '';
    image.src = earthNightTexURL;

    return image;
  }, []);

  const [offset, setOffset] = useState(new Vector2(0, 0));
  const [dragging, setDragging] = useState<Vector2 | null>(null);
  const [dragStart, setDragStart] = useState(new Vector2(0, 0));

  const handleMouseDown = (event: MouseEvent) => {
    if (event.target === canvas) {
      setDragStart(new Vector2(event.clientX, event.clientY));
      setDragging(new Vector2(0, 0));
    }
  };
  const handleDrag = (event: MouseEvent) => {
    if (dragging) {
      setDragging(new Vector2(dragStart.x - event.clientX, dragStart.y - event.clientY));
    }
  };
  const handleMouseUp = (event: MouseEvent) => {
    if (dragging) {
      setOffset(offset.add(new Vector2(dragStart.x - event.clientX, dragStart.y - event.clientY)));
    }

    setDragging(null);
  };

  useEventListener('mousedown', handleMouseDown);
  useEventListener('mousemove', handleDrag);
  useEventListener('mouseup', handleMouseUp);

  const lastTimeDrawn = useRef<number>(0);

  const isPropagating = useIsPropagating();

  const pageEarthVisible = currentPageAdditionalProperties.visEarth;

  const brightnessMax = 130; // starts washing out too much above this
  const lightingSunMax = 100; // maximum value of the slider
  const lightingSunSplit = 50; // Below this value show night image, Above brighten the earth

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const drawDefault = () => {
    if (offscreenContext) {
      offscreenContext.clearRect(0, 0, CANVAS_WIDTH * CANVAS_OVERSIZE, CANVAS_HEIGHT);

      if (pageEarthVisible) {
        // draw background: earth map into the texture
        if (showNight && lightingEnabled) {
          let brightness = 100;
          if (lightingSun > lightingSunSplit) {
            const slope = (brightnessMax - lightingSunMax) / (lightingSunMax - lightingSunSplit); // deltaY/deltaX
            brightness = slope * lightingSun + (lightingSunMax - brightnessMax + lightingSunMax);
          }
          offscreenContext.filter = `brightness(${brightness}%)`;
        }
        offscreenContext.drawImage(worldMapImage, 0, 0, CANVAS_WIDTH, CANVAS_HEIGHT);
        offscreenContext.filter = 'none';

        if (showNight && lightingEnabled) {
          let alpha = 0;
          if (lightingSun < lightingSunSplit) {
            alpha = 1 - lightingSun / lightingSunSplit;
          }

          offscreenContext.save();
          offscreenContext.globalAlpha = alpha;
          offscreenContext.drawImage(worldMapNightImage, 0, 0, CANVAS_WIDTH, CANVAS_HEIGHT);
          offscreenContext.restore();
        }
      } else {
        offscreenContext.fillStyle = 'rgb(0, 0, 32)';
        offscreenContext.beginPath();
        offscreenContext.rect(0, 0, CANVAS_WIDTH, CANVAS_HEIGHT);
        offscreenContext.fill();
        offscreenContext.closePath();
      }
    }

    if (pageEarthVisible) {
      if (showNight) {
        drawNightShade();
      }

      if (visCountries) {
        offscreenContext?.drawImage(canvasCountries, 0, 0, CANVAS_WIDTH, CANVAS_HEIGHT);
      }

      if (visLatLongLines) {
        drawLatLong();
      }

      drawGroundObjects();
    }
  };

  // This ground track draw routine is called when we scrub in the timeline which could be
  // forwards or backwards, or jump instantaneously from one point in time to another. As
  // a result, it redraws the entire ground track during each call
  const drawGroundTracksOnCanvas = (
    pointsArr: Vector2[],
    orbit: OrbitState,
    shape: SpaceObjectShape,
    size: SpaceObjectSize,
    angle: number,
    previewMode = false,
  ) => {
    if (!offscreenContext) {
      return;
    }

    const selected = currentOrbit?.id === orbit.id;

    // set the linewidth
    offscreenContext.lineWidth = selected ? 4 : 2;

    // Let us dynamically skip points based on the length of the segment
    // to compensate for the timeline playspeed (earth and satellite speed up)
    // do line drawing here
    const max_points = currentOrbitAdditionalProperties[orbit.id].trackLength;

    // when propagating, the periods are used to scale them to each other, this counters that for track length
    const periodFactor = orbit.orbitData.period / minPeriod;

    // by default, use all the points
    let indexStart = 0;
    let indexEnd = pointsArr.length - 1;

    if (max_points > 0) {
      if (previewMode) {
        indexEnd = Math.min(PROPAGATION_STEPS_PER_REVOLUTION * max_points, indexEnd);
      } else {
        indexStart = Math.max(
          Math.floor(
            pointsArr.length - PROPAGATION_STEPS_PER_REVOLUTION * max_points * periodFactor,
          ),
          indexStart,
        );
      }
    }

    if (!currentOrbitAdditionalProperties[orbit.id].visGroundTracks) {
      if (previewMode) {
        indexEnd = 0;
      } else {
        indexStart = indexEnd;
      }
    }

    //start the path
    const orbitColor = currentOrbitAdditionalProperties[orbit.id].color;

    offscreenContext.beginPath();
    offscreenContext.strokeStyle = orbitColor;
    offscreenContext.fillStyle = orbitColor;
    offscreenContext.lineCap = 'round';

    if (previewMode) {
      // draw at the beginning of the line
      drawOrbitPosition(offscreenContext, pointsArr[indexStart], angle, orbitColor, shape, size);
    }

    for (let i = indexStart; i <= indexEnd; i++) {
      // transform uv to canvas pixel position (I've offseted the texture map)
      const x = pointsArr[i].x * CANVAS_WIDTH;
      const y = pointsArr[i].y * CANVAS_HEIGHT;

      // length in pixels long
      const segmentLength = tempVector2.set(x, y).distanceTo(drawPosition);

      // handle special case of first point
      if (i === indexStart) {
        offscreenContext.moveTo(x, y);
        drawPosition.set(x, y);
        if (pointsArr.length !== 1) continue;
      }

      // if the line segment is below threshold length, skip the point to avoid artifacts. BUT, need
      // to accomodate for low playspeeds
      if (segmentLength < MinSegmentPixelThresholdDistance) {
        // skip drawing of current points line segment
        continue;
      }

      // Determine if line segment wraps across border uv
      const { wrap, p1x, p1y, p2x, p2y } = islineCanvasWrap(
        CANVAS_WIDTH,
        drawPosition.x,
        drawPosition.y,
        x,
        y,
      );

      // Handle wrap if true
      if (wrap) {
        // draw extra line segment
        offscreenContext.moveTo(drawPosition.x, drawPosition.y);

        // line to border pixel
        offscreenContext.lineTo(p1x, p1y);

        // set the new start of the next segment
        drawPosition.x = p2x;
        drawPosition.y = p2y;
      }

      // draw a line segment
      offscreenContext.moveTo(drawPosition.x, drawPosition.y);
      offscreenContext.lineTo(x, y);

      // update last position
      drawPosition.set(x, y);
    }

    if (!previewMode) {
      // draw at the end of the line
      drawOrbitPosition(offscreenContext, pointsArr[indexEnd], angle, orbitColor, shape, size);
    }

    offscreenContext.closePath();
    offscreenContext.stroke();
  };

  const drawOrbitPosition = (
    context: CanvasRenderingContext2D,
    point: Vector2,
    angle: number,
    color: string,
    shape: SpaceObjectShape,
    size: SpaceObjectSize,
  ) => {
    if (!point) {
      return;
    }
    const vertex = new Vector2(point.x * CANVAS_WIDTH, point.y * CANVAS_HEIGHT);

    context.strokeStyle = color;
    context.fillStyle = color;
    switch (shape) {
      case SpaceObjectShape.CUBE:
        renderCube(context, vertex, angle, size);
        break;
      case SpaceObjectShape.CONE:
        renderCone(context, vertex, angle, size);
        break;
      default:
        renderBall(context, vertex, size);
        break;
    }
  };

  const drawOrbitLabel = (point: Vector2, orbit: OrbitState) => {
    if (is3DView || !offscreenContext || !point) {
      return;
    }

    const orbitLabelVisible = currentOrbitAdditionalProperties[orbit.id]?.visLabel;

    if (!pageOrbitLabelsVisible || !orbitLabelVisible) {
      return;
    }

    offscreenContext.save();

    const x = float2int(0.5 + point.x * CANVAS_WIDTH);
    const y = float2int(0.5 + point.y * CANVAS_HEIGHT);

    offscreenContext.font = 'bold 18px Montserrat';
    offscreenContext.lineWidth = 2;
    offscreenContext.strokeStyle = grey[900];
    offscreenContext.fillStyle = grey[400];

    if (currentOrbit?.id === orbit.id) {
      offscreenContext.strokeStyle = grey[600];
      offscreenContext.fillStyle = theme.palette.text.primary;
    }

    offscreenContext.strokeText(orbit?.name, x + 10, y - 10);
    offscreenContext.fillText(orbit?.name, x + 10, y - 10);

    offscreenContext.restore();
  };

  const timelineState = useTimeline();

  const [shapeNightCivil, setShapeNightCivil] = useState(geoCircle());

  useEffect(() => {
    const [long, lat] = getSunPositionLongLat(new Date(timelineState.timelineRange.currentTime));
    setShapeNightCivil(
      geoCircle()
        .radius(90)
        .precision(45)
        .center([long + 180, -lat])(),
    );
  }, [timelineState]);

  const drawGroundObjects = () => {
    if (!is3DView && offscreenContext) {
      offscreenContext.save();

      const groundObjects = currentGroundObjects?.filter((obj) => obj.id !== groundObjectEdit?.id);
      if (groundObjectEdit) {
        groundObjects?.push(groundObjectEdit as GroundObject);
      }

      groundObjects?.forEach((groundObject) => {
        if (
          groundObject.additionalProperties?.visGroundObj &&
          (!groundObject.groupId ||
            currentPageGroups?.find((group) => group.id === groundObject.groupId)
              ?.additionalProperties?.visOrbits)
        ) {
          const x = ((groundObject.longitude + 180) / 360) * CANVAS_WIDTH;
          const y = ((-groundObject.latitude + 90) / 180) * CANVAS_HEIGHT;
          const radius = 3;

          offscreenContext.beginPath();
          offscreenContext.arc(x, y, radius, 0, 2 * Math.PI);
          offscreenContext.fillStyle = COLOR_GROUND_OBJECT;
          offscreenContext.fill();

          if (
            currentPageAdditionalProperties.visOrbitLabels &&
            groundObject.additionalProperties?.visLabel
          ) {
            offscreenContext.font = `bold 14px ${theme.typography.fontFamily}`;
            offscreenContext.lineWidth = 2;
            offscreenContext.strokeStyle = grey[900];
            offscreenContext.fillStyle = grey[400];

            if (currentGroundObjectId === groundObject.id) {
              offscreenContext.strokeStyle = grey[600];
              offscreenContext.fillStyle = theme.palette.text.primary;
            }

            const labelOffset = 5;
            offscreenContext.strokeText(groundObject?.name, x + labelOffset, y - labelOffset);
            offscreenContext.fillText(groundObject?.name, x + labelOffset, y - labelOffset);
          }
        }
      });
      offscreenContext.restore();
    }
  };

  const drawNightShade = () => {
    if (offscreenContext) {
      let alpha = 0.5;
      if (lightingEnabled) {
        alpha = lightingNight / 100;
      }

      offscreenContext.save();
      offscreenContext.beginPath();
      pathGeo(shapeNightCivil);
      offscreenContext.closePath();

      offscreenContext.clip();
      offscreenContext.globalAlpha = alpha;

      offscreenContext.drawImage(worldMapNightImage, 0, 0, CANVAS_WIDTH, CANVAS_HEIGHT);
      offscreenContext.restore();
    }
  };

  const drawLatLong = () => {
    if (offscreenContext) {
      offscreenContext.save();

      offscreenContext.beginPath();
      offscreenContext.strokeStyle = LATLONG_COLOR;
      offscreenContext.lineWidth = LATLONG_THICKNESS;
      pathGeo(geoGraticule().step([LATLONG_SPACED_LATITUDE, LATLONG_SPACED_LONGITUDE])());
      offscreenContext.stroke();
      offscreenContext.closePath();

      offscreenContext.beginPath();
      offscreenContext.strokeStyle = LATLONG_COLOR_PRIME;
      offscreenContext.lineWidth = LATLONG_THICKNESS_PRIME;

      pathGeo(
        geoGraticule()
          .extentMajor([
            [-180 - D3_GEO_EPSILON, -90],
            [180 + D3_GEO_EPSILON, 90],
          ])
          .step([180, 90])(),
      );
      offscreenContext.stroke();
      offscreenContext.closePath();

      offscreenContext.restore();
    }
  };

  const getTraceOrbits = () => {
    const orbitStates = getOrbits();
    const allOrbits =
      currentOrbits
        ?.map(({ id }) => orbitStates[id])
        .filter((orbitState) => orbitState !== undefined) || [];

    return drawAllTracks
      ? allOrbits
      : allOrbits?.filter(({ id }) => currentOrbitAdditionalProperties[id].visGroundTracks);
  };

  // This ground track draw routine is called when the timeline is playing and it only
  // tries to draw the latest line segments up to the current true anomaly position
  // that haven't been drawn yet, including skipped points.

  const drawGroundTracksForOrbits = () => {
    const orbits = getTraceOrbits();

    orbits.forEach((orbit) => {
      if (!orbitVisibilities[orbit.id]) return;
      const { orbitPropagationPath, id } = orbit;
      // get the time of the last time we've updated
      // const lastUpdateTime = orbitPathRef.lastTimeSet;
      const lastUpdateTime = lastTimeDrawn.current;

      // get the number of indices of time skipped from the last
      // time we updated.
      const last = binarySearchForClosestValue(
        orbitPropagationPath.timePoints,
        lastUpdateTime,
        (timePoint: number) => timePoint * 1000,
      );

      const lastUpdateIndex = last.low.index;

      const now = binarySearchForClosestValue(
        orbitPropagationPath.timePoints,
        currentTime,
        (timePoint: number) => timePoint * 1000,
      );

      const currentIndex = now.low.index;

      const start = lastUpdateIndex;
      const end = currentIndex;

      const orbitCache = PropagatedCacheManager.getCacheForOrbit(id);

      if (orbitCache) {
        let points = [];
        let potentialPointsForTrajectory: GroundTrackPoint[] | undefined;

        // check for valid index when current time is within state vector range
        if (lastUpdateIndex > -1 && currentIndex > -1) {
          const groundTrackPoints = orbitCache.stateVectors.map((stateVector) => {
            return stateVector.stateVectors[0].groundTrack;
          });

          potentialPointsForTrajectory = getNearestPointsToIndex(
            groundTrackPoints,
            end,
            3,
            TRAJECTORY_TYPES.TRAILING,
          );

          const dataPointsToDraw = groundTrackPoints.slice(start, end);

          dataPointsToDraw.forEach((point) => {
            uvPointsStore.addPoint(id, { pos: point });
          });

          // add actual blended point for current position
          const next = Math.min(end + 1, orbitPropagationPath.timePoints.length - 1);
          const blend = inverseLerp(
            orbitPropagationPath.timePoints[end],
            orbitPropagationPath.timePoints[next],
            currentTime / 1000,
          );
          const currentPosition = new Vector2(
            groundTrackPoints[end].lat,
            groundTrackPoints[end].lon,
          ).lerp(new Vector2(groundTrackPoints[next].lat, groundTrackPoints[next].lon), blend);
          uvPointsStore.addPoint(id, { pos: { lat: currentPosition.x, lon: currentPosition.y } });

          points = uvPointsStore.asArray(id);
        } else {
          // current time is earlier so just use the first point
          const point = orbitCache.stateVectors[0].stateVectors[0].groundTrack;
          const { uv } = getUVCoordsFromLatLon(point.lon, point.lat);

          points = [uv];
        }

        if (potentialPointsForTrajectory) {
          const trajectoryAnglePoints = potentialPointsForTrajectory.map((latlong) => {
            return getUVCoordsFromLatLon(latlong.lon, latlong.lat).uv;
          });
          const trajectoryAngle = getDirectionWrappingFromThreePoints([
            scaleUvToCanvas(trajectoryAnglePoints[0]),
            scaleUvToCanvas(trajectoryAnglePoints[1]),
            scaleUvToCanvas(trajectoryAnglePoints[2]),
          ]);
          // submit array of Vector2(lat,long) for drawing
          drawGroundTracksOnCanvas(
            points,
            orbit,
            currentOrbitAdditionalProperties[orbit.id].spaceObjectShape,
            currentOrbitAdditionalProperties[orbit.id].spaceObjectSize,
            trajectoryAngle,
          );
        }
      }
    });

    orbits.forEach((orbit) => {
      if (!orbitVisibilities[orbit.id]) return;
      const points = uvPointsStore.asArray(orbit.id);
      drawOrbitLabel(points[points.length - 1], orbit);
    });

    lastTimeDrawn.current = currentTime;
  };

  const clearGroundTracks = useCallback(() => {
    const orbitStates = getOrbits();
    const orbits =
      currentOrbits?.map(({ id }) => orbitStates[id]).filter((value) => value !== undefined) ?? [];

    orbits.forEach(({ id }) => {
      uvPointsStore.clear(id);
    });
  }, [currentOrbits, uvPointsStore]);

  const drawPreviewGroundTracks = () => {
    // if coe's have not changed
    // if (isEqual(orbitHash, previousCoes.current)) {
    //   return;
    // }
    const orbits = getTraceOrbits();

    orbits.forEach((orbit) => {
      if (!orbitVisibilities[orbit.id]) return;
      const { coe, orbitData, id } = orbit;
      if (orbitData.hasConditionEarthIntersect) return;

      // get the period of the orbit based on coe's
      const period = orbitData.period; // sec
      const nPoints = MaxPreviewGroundTrackPoints;
      const deltaT = period / nPoints; // virtual increments (sec)

      // get the current rotation of the earth at start of timeline
      let earthRotTime = currentTime;

      // clear the uvTrackPoints queue because they are invalid

      uvPointsStore.clear(id);

      // Need to simulate the orbit going around at the right speed
      // loop based on number of points
      const coeAnim = { ...coe.toRad() }; // actual coe in radians
      let trueAnomalyCurrentAngle = coeAnim.trueAnomaly; // actual
      const position = new Vector3();

      for (let i = 0; i < nPoints; i++) {
        // get the virtual earth rotation
        const earthRot = getEarthRotation(earthRotTime);

        coeAnim.trueAnomaly = trueAnomalyCurrentAngle; // temporary coe
        const { x, y, z, dtheta } = getTrueAnomalyPosition(coeAnim);

        // position is the current calculated true anomaly position
        position.set(x, y, z);

        // angular velocity (rad)
        const angularVelocity = dtheta;

        // as we loop through the points, we need to rotate the point based on time
        // use addPosition to update the queue
        uvPointsStore.addPoint(id, { pos: position, earthPos: earthPosition, earthRot });

        // increment earth rotation for next point
        earthRotTime += deltaT * 1000; // advance time for next point

        // increment the true anomaly for next point
        trueAnomalyCurrentAngle += angularVelocity * deltaT;
      }

      const points = uvPointsStore.asArray(id);

      const trajectoryAngle = getDirectionWrappingFromThreePoints([
        scaleUvToCanvas(points[0]),
        scaleUvToCanvas(points[1]),
        scaleUvToCanvas(points[2]),
      ]);

      // drawing based on canvas
      drawGroundTracksOnCanvas(
        points,
        orbit,
        currentOrbitAdditionalProperties[orbit.id].spaceObjectShape,
        currentOrbitAdditionalProperties[orbit.id].spaceObjectSize,
        trajectoryAngle,
        true,
      );
    });

    // draw orbit labels after tracks
    orbits.forEach((orbit) => {
      if (!orbitVisibilities[orbit.id]) return;
      const points = uvPointsStore.asArray(orbit.id);
      drawOrbitLabel(points[0], orbit);
    });
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const replaceGroundTrackPoints = () => {
    if (!isPropagating) {
      return;
    }

    drawDefault();

    const orbits = getTraceOrbits();

    orbits.forEach((orbit) => {
      const { id, orbitPropagationPath } = orbit;
      uvPointsStore.clear(id);

      const { low } = binarySearchForClosestValue(
        orbitPropagationPath.timePoints,
        currentTime,
        (timePoint: number) => timePoint * 1000,
      );

      // TODO: currently, we lookup the closest position from dataPoints, but the satellite in orbitPath interpolates
      // this means the satellite position is more accurate than the dataPoints position for the currentTime using orbitPropagationPath.
      // This also means playing timeline using the playbutton, and clicking in the timeline, don't give the exact same end groundtrack point.
      // [TechDebt] Do the same interpolation OR use the satellite position at the currentTime drawn so it uses interpolated position at currentime

      if (low.index > -1) {
        const potentialStart = low.index - MaxGroundTrackBufferSize;
        const start = potentialStart < 0 ? 0 : potentialStart;
        const end = low.index <= start ? start + 1 : low.index; // end needs to be at least 1 more than the start
        const dataPointsToDraw = orbitPropagationPath.dataPoints.slice(start, end);
        const timePointsToDraw = orbitPropagationPath.timePoints.slice(start, end);

        const points = dataPointsToDraw.map((point: Vector3, index: number) => {
          // the current time at a given data point
          const time = timePointsToDraw[index] * 1000;

          // get the ground track position for the specified earth rotation time
          const { uv } = getUVCoordsFromPointProjection(
            point,
            earthPosition,
            getEarthRotation(time),
          );

          return uv;
        });

        uvPointsStore.replacePoints(id, points);

        const trajectoryAnglePoints = getNearestPointsToIndex(
          points,
          end,
          3,
          TRAJECTORY_TYPES.TRAILING,
        );

        if (trajectoryAnglePoints) {
          const trajectoryAngle = getDirectionWrappingFromThreePoints([
            scaleUvToCanvas(trajectoryAnglePoints[0]),
            scaleUvToCanvas(trajectoryAnglePoints[1]),
            scaleUvToCanvas(trajectoryAnglePoints[2]),
          ]);

          // drawing based on canvas
          drawGroundTracksOnCanvas(
            points,
            orbit,
            currentOrbitAdditionalProperties[orbit.id].spaceObjectShape,
            currentOrbitAdditionalProperties[orbit.id].spaceObjectSize,
            trajectoryAngle,
          );
        }

        lastTimeDrawn.current = currentTime;
      }
    });
  };

  const orbitsFromStore = use3DOrbitStore((state) => state.orbits);

  const drawSpaceSensors = () => {
    if (!offscreenContext) return;

    const { spaceSensorWindows: spaceSensorViewingWindows } = useViewingWindowsStore.getState();

    const spaceSensorRenders: {
      altitude: number;
      coordinate: Coordinate;
      targetCoordinates: Coordinate[];
      spaceSensor: SpaceSensor;
    }[] = [];

    currentOrbits
      ?.sort((a, b) => {
        // sort such that newer orbits are on top, and the selected orbit is on top of all others
        if (currentOrbit && a.id === currentOrbit.id) {
          return 1; // a should come after b
        } else if (currentOrbit && b.id === currentOrbit.id) {
          return -1; // a should come before b
        } else {
          return a.id - b.id; // sort by id
        }
      })
      .forEach((orbit) => {
        if (!orbitVisibilities[orbit.id]) return;

        orbit.spaceSensors.forEach((spaceSensorDB) => {
          // if we have an editing version, use that, otherwise use the react-query cached from db
          const spaceSensor: SpaceSensor = editingSpaceSensors[spaceSensorDB.id] || spaceSensorDB;

          if (!spaceSensor.enabled) {
            return;
          }

          const orbitFromStore = orbitsFromStore[orbit.id];

          const position: Vector3 = new Vector3();
          if (isPropagating && orbitFromStore.activeStateVector) {
            position.x = orbitFromStore.activeStateVector.y_position;
            position.y = orbitFromStore.activeStateVector.z_position;
            position.z = orbitFromStore.activeStateVector.x_position;
            position.divideScalar(1000);
          } else if (orbitFromStore.stateVectors) {
            position.x = orbitFromStore.stateVectors.yPosition;
            position.y = orbitFromStore.stateVectors.zPosition;
            position.z = orbitFromStore.stateVectors.xPosition;
          }

          const altitude = position.length() - earthradius;
          const coordinate = getLatLongFromPointProjection(
            position,
            earthPosition,
            getEarthRotation(currentTime),
          );

          const targetCoordinates: Coordinate[] = [];
          if (
            spaceSensor.pointingType === SpaceSensorPointingType.TargetTracking &&
            spaceSensorViewingWindows[orbit.id]
          ) {
            const activeViewingWindows = spaceSensorViewingWindows[orbit.id].windows?.filter(
              (w) => w.startTime.getTime() <= currentTime && w.endTime.getTime() >= currentTime,
            );
            if (activeViewingWindows.length > 0) {
              const activeWindowsGroundObjects = currentGroundObjects?.filter((groundObj) =>
                activeViewingWindows.find((w) => w.groundObjectId === groundObj.id),
              );

              activeWindowsGroundObjects?.forEach((groundObj) => {
                targetCoordinates.push({
                  latitude: groundObj.latitude,
                  longitude: groundObj.longitude,
                });
              });
            }
          }

          // push to array, so we can draw all FOR's then all FOV's on top
          spaceSensorRenders.push({
            altitude: altitude,
            coordinate: coordinate,
            targetCoordinates: targetCoordinates,
            spaceSensor: spaceSensor,
          });
        });
      });

    spaceSensorRenders.forEach((spaceSensorRender) => {
      drawSpaceSensor({
        context: offscreenContext,
        altitude: spaceSensorRender.altitude,
        coordinate: spaceSensorRender.coordinate,
        targetCoordinates: spaceSensorRender.targetCoordinates,
        spaceSensor: spaceSensorRender.spaceSensor,
        layers: DrawSpaceSensorLayers.FOR,
      });
    });

    spaceSensorRenders.forEach((spaceSensorRender) => {
      drawSpaceSensor({
        context: offscreenContext,
        altitude: spaceSensorRender.altitude,
        coordinate: spaceSensorRender.coordinate,
        targetCoordinates: spaceSensorRender.targetCoordinates,
        spaceSensor: spaceSensorRender.spaceSensor,
        layers: DrawSpaceSensorLayers.FOV,
      });
    });
  };

  const groundTextureAnimation = () => {
    drawDefault();

    drawSpaceSensors();

    const playState = getPlayState();
    if (playState === 'playing') {
      drawGroundTracksForOrbits();
    }
    if (playState === 'stopped') {
      if (!isPropagating) {
        drawPreviewGroundTracks();
      } else {
        drawGroundTracksForOrbits();
      }
    }

    // with everything drawn on the offscreen canvas, now draw it all on real canvas
    if (context) {
      const shift = offset.clone();
      if (dragging) {
        shift.add(dragging);
      }

      let scale = 1;
      // if the ratio of canvas size is higher than earth texture size
      if (context.canvas.width / context.canvas.height < CANVAS_WIDTH / CANVAS_HEIGHT) {
        scale = context.canvas.width / CANVAS_WIDTH;
      } else {
        scale = context.canvas.height / CANVAS_HEIGHT;
      }

      const shiftX = positiveNumInRange(shift.x, CANVAS_WIDTH * scale);

      // drawn from right to left so that the labels hanging off right side are overlayed on top
      const iterations = Math.ceil(context.canvas.width / (CANVAS_WIDTH * scale));
      const extraWidth = CANVAS_WIDTH * CANVAS_OVERSIZE; // to handle labels/shapes drawn off the edge
      for (let i = iterations; i >= 0; i--) {
        context.drawImage(
          offscreenCanvas,
          0,
          0,
          CANVAS_WIDTH + extraWidth,
          CANVAS_HEIGHT,
          -shiftX + CANVAS_WIDTH * scale * i,
          (context.canvas.height - CANVAS_HEIGHT * scale) * 0.5,
          (CANVAS_WIDTH + extraWidth) * scale,
          CANVAS_HEIGHT * scale,
        );
      }
    }
  };

  useSingleDependencyEffect((loaded) => {
    if (loaded) drawDefault();
  }, imageEarthLoaded);

  useSingleDependencyEffect((loaded) => {
    if (loaded) drawDefault();
  }, imageEarthNightLoaded);

  useOnPageChange(() => {
    drawDefault();
  });

  useSingleDependencyEffect(() => {
    replaceGroundTrackPoints();
  }, [currentOrbit]);

  useEffect(() => {
    TimelineEventManager.addEventListener('scrub', replaceGroundTrackPoints);
    TimelineEventManager.addEventListener('jumpToStart', replaceGroundTrackPoints);
    TimelineEventManager.addEventListener('jumpToEnd', replaceGroundTrackPoints);
    PropagatedCacheManager.addEventListener('startpropagations', clearGroundTracks);
    PropagatedCacheManager.addEventListener('startpropagations', drawDefault);
    PropagatedCacheManager.addEventListener('clearpropagations', clearGroundTracks);

    return function cleanup() {
      TimelineEventManager.removeEventListener('scrub', replaceGroundTrackPoints);
      TimelineEventManager.removeEventListener('jumpToStart', replaceGroundTrackPoints);
      TimelineEventManager.removeEventListener('jumpToEnd', replaceGroundTrackPoints);
      PropagatedCacheManager.removeEventListener('startpropagations', clearGroundTracks);
      PropagatedCacheManager.removeEventListener('startpropagations', drawDefault);
      PropagatedCacheManager.removeEventListener('clearpropagations', clearGroundTracks);
    };
  }, [clearGroundTracks, drawDefault, replaceGroundTrackPoints]);

  return groundTextureAnimation;
}

function getSunPositionLongLat(date: Date) {
  const now = date.valueOf();
  const day = new Date(+now).setUTCHours(0, 0, 0, 0);
  const t = century(now);
  const longitude = ((day - now) / 864e5) * 360 - 180; // 864e5 = mills per day
  return [longitude - equationOfTime(t) / 4, declination(t)];
}
