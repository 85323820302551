import { ForwardedRef, forwardRef, ForwardRefRenderFunction } from 'react';
import { use3DOrbitContext } from 'src/threejs/components/Orbit/context';
import ManipulatorPlaneModel from 'src/threejs/models/ManipulatorPlane';
import { DoubleSide } from 'three';

type ManipulatorPlaneProps = {
  innerRef?: ForwardedRef<ManipulatorPlaneModel>;
  name: string;
  rotation: boolean;
};

const ManipulatorPlane: ForwardRefRenderFunction<ManipulatorPlaneModel, ManipulatorPlaneProps> = (
  { name, rotation },
  ref,
) => {
  const { name: orbitName } = use3DOrbitContext();

  return (
    <manipulatorPlane
      ref={ref}
      args={[rotation]}
      name={`${orbitName} ${name} Manipulator Plane Mesh`}
    >
      <planeGeometry
        args={[100000, 100000, 2, 2]}
        name={`${orbitName} ${name} Manipulator Plane Geometry`}
      />
      <meshBasicMaterial
        visible={false}
        wireframe
        side={DoubleSide}
        transparent
        opacity={0.1}
        color={0xffff00}
        toneMapped={false}
        name={`${orbitName} ${name} Manipulator Plane Material`}
      />
    </manipulatorPlane>
  );
};

export default forwardRef<ManipulatorPlaneModel, ManipulatorPlaneProps>(ManipulatorPlane);
