import { useLoader } from '@react-three/fiber';
import { Suspense } from 'react';
import starTextureUrl from 'src/assets/NasaSkymap_t4_4096x2048.jpg';
import { BackSide, TextureLoader, sRGBEncoding } from 'three';
interface UniverseProps {
  showStars: boolean;
}

export const Universe = ({ showStars }: UniverseProps) => {
  const [starMapTexture] = useLoader(TextureLoader, [starTextureUrl]);

  // set the color space of the texture
  starMapTexture.encoding = sRGBEncoding;

  // reduce blurring at glancing angles
  starMapTexture.anisotropy = 16;

  return (
    <Suspense fallback={null}>
      <mesh
        name="Universe Mesh"
        visible
        renderOrder={0}
      >
        {showStars ? (
          <meshBasicMaterial
            name="Stars Material"
            map={starMapTexture}
            side={BackSide}
            depthWrite={false}
          />
        ) : (
          <meshStandardMaterial
            color="black"
            side={BackSide}
            depthWrite={false}
          />
        )}
        <sphereGeometry
          args={[100, 50, 50]}
          name="Stars Geometry"
        />
      </mesh>
    </Suspense>
  );
};
