import { useMutation, useQuery, useQueryClient, UseQueryOptions } from '@tanstack/react-query';
import { DateTime } from 'luxon';
import { LABObjectType } from 'src/enums';
import { getActiveNotebookId, useRouteStore } from 'src/pages/App/routes/store';
import { fetchApi } from 'src/services/api';
import {
  Capability,
  Folder,
  Notebook,
  Perturbations,
  SharedFolder,
  SharedNotebook,
} from 'src/types';
import { useApiSnackbarError } from './SnackbarHooks';

export function useObjectHierarchy(id?: number, queryOptions?: UseQueryOptions<Folder | Notebook>) {
  const folderId: string = id && !isNaN(id) ? `/${id}` : '';
  return useQuery<Folder | Notebook>(
    ['objectHierarchy', id],
    () => fetchApi(`/v2/objectHierarchy${folderId}`),
    queryOptions,
  ).data;
}

export type ObjectUpdate = {
  parentId?: number;
  objectId: number;
  name?: string;
  notes?: string;
  type?: LABObjectType;
  perturbations?: Perturbations;
  previousObjectId?: number;
};

export function useUpdateObjectMutation() {
  const apiSnackbarError = useApiSnackbarError();

  const queryClient = useQueryClient();
  return useMutation(
    (objectUpdate: ObjectUpdate) =>
      fetchApi('/v2/objects', {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ updates: [objectUpdate] }),
      }),
    {
      onError: () => {
        apiSnackbarError('Failed to update notebook.');
      },
      onSuccess: async () => {
        await queryClient.invalidateQueries(['objectHierarchy']);
      },
    },
  );
}

type ObjectCreate = {
  parentId: number;
  name?: string;
  type: LABObjectType;
  notes?: string;
};

export function useCreateObjectMutation() {
  const apiSnackbarError = useApiSnackbarError();

  const queryClient = useQueryClient();
  return useMutation(
    ({ parentId, name, type, notes }: ObjectCreate) =>
      fetchApi('/v2/objects', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ updates: [{ previousObjectId: parentId, name, type, notes }] }),
      }),
    {
      onError: () => {
        apiSnackbarError('Failed to create notebook.');
      },
      onSuccess: (data, { parentId }) => {
        const resultObject = data.updates[0];

        // Why invalidate (and subsequently refetch) instead of just injecting the result
        // into the cache? Because the data comes differently and there are properties that
        // are only present when called via /v2/objectHierarchy/:id and not in the
        // result of this operation.
        queryClient.invalidateQueries(['objectHierarchy', resultObject.objectId]);
        queryClient.invalidateQueries(['objectHierarchy', parentId]);
      },
    },
  );
}

type DeleteObjectMutationVariables = {
  id: number;
};

export function useDeleteObjectMutation() {
  const apiSnackbarError = useApiSnackbarError();

  const queryClient = useQueryClient();
  return useMutation(
    ({ id }: DeleteObjectMutationVariables) =>
      fetchApi('/v2/objects', {
        method: 'DELETE',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ updates: [{ objectId: id }] }),
      }),
    {
      onError: () => {
        apiSnackbarError('Failed to delete notebook.');
      },
      onSuccess: () => {
        queryClient.invalidateQueries(['objectHierarchy']);
      },
    },
  );
}

export function useCurrentNotebook() {
  const notebookId = useRouteStore(getActiveNotebookId);
  const currentNotebookQuery = useObjectHierarchy(Number(notebookId), {
    enabled: Boolean(notebookId),
  });
  return currentNotebookQuery as Notebook | undefined;
}

type DeleteCopyCapabilityMutationVariables = {
  id: number;
};

export function useDeleteCopyCapabilityMutation(suppressWarnings = false) {
  const apiSnackbarError = useApiSnackbarError();

  const queryClient = useQueryClient();
  return useMutation(
    ({ id }: DeleteCopyCapabilityMutationVariables) =>
      fetchApi(`/v2/folders/capabilities/${id}`, {
        method: 'DELETE',
      }),
    {
      onError: () => {
        // If this is a bulk action, we don't want a notification for every single failed operation.
        if (!suppressWarnings) {
          apiSnackbarError('Failed to remove user from the shared notebook.');
        }
      },
      onSuccess: () => {
        queryClient.invalidateQueries(['capabilities']);
      },
    },
  );
}

type DeleteAllCopyCapabilitiesMutationVariables = {
  ids: number[];
};

export function useDeleteAllCopyCapabilitiesMutation() {
  const apiSnackbarError = useApiSnackbarError();
  const queryClient = useQueryClient();
  const mutation = useDeleteCopyCapabilityMutation(true);

  return useMutation(
    async ({ ids }: DeleteAllCopyCapabilitiesMutationVariables) => {
      const mutations = ids.map((id: number) => {
        return mutation.mutateAsync({ id });
      });
      await Promise.all(mutations);
    },
    {
      onError: () => {
        apiSnackbarError('Some users could not be removed from the shared notebook.');
      },
      onSuccess: () => {
        queryClient.invalidateQueries(['capabilities']);
      },
    },
  );
}

type GrantCopy = { id: number; emails: string[] };

export function useGrantCopyCapabilityMutation() {
  const queryClient = useQueryClient();
  return useMutation(
    (grantCopy: GrantCopy) => {
      return fetchApi(`/v2/folders/${grantCopy.id}/capabilities`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(
          grantCopy.emails.map((email) => ({
            accessType: 'COPY',
            sourceId: grantCopy.id,
            userEmail: email,
          })),
        ),
      });
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['capabilities']);
      },
    },
  );
}

type CapabilityNotebookRecord = {
  capability: Capability;
  object: SharedNotebook | SharedFolder;
};

const mapGetCapabilitiesPayload = (data: any): CapabilityNotebookRecord => {
  const { folder } = data;
  return {
    capability: data,
    object: {
      sourceEmail: data.sourceEmail,
      ...folder,
      createdTimestamp: DateTime.fromMillis(new Date(folder.createdTimestamp).getTime()).toFormat(
        'yyyy-MM-dd HH:mm:ss',
      ),
      updatedTimestamp: DateTime.fromMillis(new Date(folder.updatedTimestamp).getTime()).toFormat(
        'yyyy-MM-dd HH:mm:ss',
      ),
      type: folder.type === 'FOLDER' ? 'SHARED-FOLDER' : 'SHARED-NOTEBOOK',
    },
  };
};

export function useGetCapabilities() {
  // TODO: implement receiving folders.
  return useQuery<CapabilityNotebookRecord[]>(['capabilities'], () => {
    return fetchApi('/v2/folders/capabilities', {
      method: 'GET',
      headers: { 'Content-Type': 'application/json' },
    }).then((data) => {
      return data.map(mapGetCapabilitiesPayload);
    });
  });
}

export function useGetCapabilitiesForFolder(folderId: number) {
  return useQuery(['capabilitiesFolder', folderId], () => {
    if (isNaN(folderId)) {
      return [];
    }
    return fetchApi(`/v2/folders/${folderId}/capabilities`, {
      method: 'GET',
      headers: { 'Content-Type': 'application/json' },
    }).then((data) => {
      return data;
    });
  }).data;
}

type CapabilityStatusUpdate = {
  capabilityId: number;
  statusUpdate: string;
};

export function useUpdateCapability() {
  const queryClient = useQueryClient();
  return useMutation(
    (update: CapabilityStatusUpdate) =>
      fetchApi(`/v2/folders/capabilities/${update.capabilityId}/status`, {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(update.statusUpdate),
      }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['capabilities']);
      },
    },
  );
}

export const useCopyFolderOrNotebook = () => {
  const apiSnackbarError = useApiSnackbarError();
  const queryClient = useQueryClient();

  return useMutation(
    (objectId: number) =>
      fetchApi(`/v2/folders/${objectId}/copy`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
      }),
    {
      onError: () => {
        apiSnackbarError('Failed to copy notebook.');
      },
      onSuccess: () => {
        queryClient.invalidateQueries(['objectHierarchy']);
      },
    },
  );
};

export function useCheckNotebookCapabilities(notebookId: number, enabled = true) {
  const queryEnabled = enabled && !isNaN(notebookId);
  return useQuery<Capability[]>(
    ['capabilities', notebookId],
    () => fetchApi(`/v2/folders/${notebookId}/capabilities`),
    { enabled: queryEnabled },
  );
}
