import { IconButton, styled, SvgIcon } from '@mui/material';
import { SketchPicker } from 'react-color';
import { SpaceGlassBackground, SpaceGlassStylesBase } from 'src/components/SpaceGlassControl';
import { ORBIT_COLOR_DEFAULT } from 'src/constants';

export const ColorPickerCover = styled('div')`
  position: fixed;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
`;

export const ColorPickerWrapper = styled('div')`
  position: absolute;
  z-index: 25;
`;

export const OrbitColorPicker = styled(SketchPicker)`
  ${SpaceGlassStylesBase}
  background-color: ${SpaceGlassBackground} !important;
`;

export const SwatchSelectorBtn = styled(IconButton)`
  padding: 0;
`;

interface SvgIconWrapperProps {
  selected: boolean;
  selectedcolor?: string;
}
export const SvgIconWrapper = styled(SvgIcon)<SvgIconWrapperProps>(
  ({ selected, selectedcolor = ORBIT_COLOR_DEFAULT }) => `
    border-radius: 50%;
    padding: 2px;
    ${selected && `border: solid 3px ${selectedcolor}`}
`,
);
