import { createSelector } from 'reselect';
import { COE } from 'src/threejs/models/COE';
import { ReferenceFrame, ReferenceFrameType } from 'src/types';
import { OrbitStoreState } from './types';

const baseSelector = (state: OrbitStoreState) => state;

export const selectOrbits = createSelector(baseSelector, (state) => {
  return state.orbits;
});

export const createOrbitStateSelector = (id: number) =>
  createSelector(selectOrbits, (state) => {
    return state[id];
  });

export const createOrbitVerticesStateSelector = (id: number) =>
  createSelector(selectOrbits, (state) => {
    return state[id].vertices;
  });

export const createOrbitTimeStateSelector = (id: number) =>
  createSelector(selectOrbits, (state) => {
    return state[id].orbitTime;
  });

export const createOrbitDataStateSelector = (id: number) =>
  createSelector(selectOrbits, (state) => {
    return state[id].orbitData;
  });

export const createOrbitVertsStateSelector = (id: number) =>
  createSelector(selectOrbits, (state) => {
    return state[id].verts;
  });

export const createOrbitPathSelector = (id: number) =>
  createSelector(selectOrbits, (state) => {
    return state[id].orbitPropagationPath;
  });

export const createOrbitPathDataPointsSelector = (id: number, referenceFrame: ReferenceFrameType) =>
  createSelector(createOrbitPathSelector(id), (state) => {
    if (referenceFrame === ReferenceFrame.ECEF) {
      return state.ecefDataPoints;
    } else if (referenceFrame === ReferenceFrame.ECI) {
      return state.dataPoints;
    }
    return [];
  });

export const createOrbitPathTimePointsSelector = (id: number) =>
  createSelector(createOrbitPathSelector(id), (state) => {
    return state.timePoints;
  });

export const createOrbitIdSelector = (id: number) =>
  createSelector(selectOrbits, (state) => {
    return state[id].id;
  });

export const createUpdateOrbitCOESelector = (id: number) =>
  createSelector(selectOrbits, (state) => {
    return state[id]?.updateOrbitCOE;
  });

export const createUpdateGroupIdSelector = (id: number) =>
  createSelector(selectOrbits, (state) => {
    return state[id]?.updateOrbitGroupId;
  });

export const createSetDisplayedCalloutsSelector = (id: number) =>
  createSelector(selectOrbits, (state) => {
    return state[id]?.setDisplayedCallouts;
  });

export const createOrbitNameSelector = (id: number) =>
  createSelector(selectOrbits, (state) => {
    return state[id].name;
  });

export const createOrbitSizeSelector = (id: number) =>
  createSelector(selectOrbits, (state) => {
    return state[id].size;
  });

export const createAddStateVectorsSelector = (id: number) =>
  createSelector(createOrbitPathSelector(id), (state) => {
    return state.addStateVectors;
  });
export const createResetOrbitPathSelector = (id: number) =>
  createSelector(createOrbitPathSelector(id), (state) => {
    return state.reset;
  });

export const createDeleteOrbitSelector = (id: number) =>
  createSelector(selectOrbits, (state) => {
    return state[id].deleteOrbitState;
  });

export const createOrbitCOESelector = (id: number) =>
  createSelector(selectOrbits, (state) => {
    return state[id].coe;
  });

export const createOrbitDisplayedCalloutsSelector = (id: number) =>
  createSelector(selectOrbits, (state) => {
    return state[id].displayedCallouts;
  });

export const createOrbitMakeKeplerianOrbitSelector = (id: number) =>
  createSelector(selectOrbits, (state) => {
    return state[id].makeKeplerianOrbit;
  });

export const createOrbitRAANSelector = (id: number) =>
  createSelector(selectOrbits, (state) => {
    return state[id].orbitData.raanPosition;
  });

export const createOrbitAOPSelector = (id: number) =>
  createSelector(selectOrbits, (state) => {
    return state[id].orbitData.aopPosition;
  });

export const createOrbitECCSelector = (id: number) =>
  createSelector(selectOrbits, (state) => {
    return state[id].orbitData.semiMinorAxisPosition1;
  });

export const createOrbitINCSelector = (id: number) =>
  createSelector(selectOrbits, (state) => {
    return state[id].orbitData.inclinationRightAnglePosition;
  });

export const createOrbitalPlaneVectorSelector = (id: number) =>
  createSelector(selectOrbits, (state) => {
    return state[id].orbitData.orbitalPlaneVector;
  });

export const createOrbitalPlaneOrientationSelector = (id: number) =>
  createSelector(selectOrbits, (state) => {
    return state[id].orbitData.orbitalPlaneOrientation;
  });

export const createCurrentOrbitECZoomDistanceSelector = (id: number) =>
  createSelector(selectOrbits, (state) => {
    return state[id]?.getECZoomDistance ?? undefined;
  });

export const createCurrentOrbitIntersectsWithEarth = (id: number) =>
  createSelector(selectOrbits, (state) => {
    return state[id]?.orbitData?.hasConditionEarthIntersect ?? false;
  });

export const createCurrentOrbitCOESelector = (id: number) =>
  createSelector(selectOrbits, (state) => {
    return state[id]?.coe;
  });

export const createCurrentOrbitCOEValueSelector = (
  id: number,
  valueSelector: (coe?: COE) => number | undefined,
) => createSelector(selectOrbits, (state) => valueSelector(state[id]?.coe));

export const createCurrentOrbitNameSelector = (id: number) =>
  createSelector(selectOrbits, (state) => {
    return state[id]?.name;
  });

export const createCurrentOrbitPerturbationsSelector = (id: number) =>
  createSelector(selectOrbits, (state) => {
    return state[id]?.perturbations;
  });

export const createOrbitActiveSvSelector = (id: number) =>
  createSelector(selectOrbits, (state) => {
    return state[id].activeStateVector;
  });
