import { useCallback, useMemo } from 'react';
import { AXES_HELPER_LENGTHS, EARTH_ORIGIN, VISUAL_AID_HEX } from 'src/constants';
import { Vector3 } from 'three';
import { ArrowVector } from '../../ArrowVector';
import { createGetOrbitalPlaneVector } from '../../OrbitManager/store/getters';
import { use3DOrbitContext } from '../context';

interface AngularMomentumVectorProps {
  orbitId: number;
}

export const AngularMomentumVector = ({ orbitId }: AngularMomentumVectorProps) => {
  const getOrbitalPlaneVector = useMemo(() => createGetOrbitalPlaneVector(orbitId), [orbitId]);
  const getAngularMomentumDestination = useCallback(
    () =>
      new Vector3(
        getOrbitalPlaneVector().z,
        getOrbitalPlaneVector().x,
        getOrbitalPlaneVector().y,
      ).setLength(1.5),
    [getOrbitalPlaneVector],
  );

  const { selected } = use3DOrbitContext();

  return (
    <ArrowVector
      labelShort="h"
      active={selected}
      color={VISUAL_AID_HEX}
      staticOrigin={EARTH_ORIGIN}
      staticLength={AXES_HELPER_LENGTHS}
      label="Angular Momentum Vector"
      getDestination={getAngularMomentumDestination}
    />
  );
};
