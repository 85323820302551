export const Environments = {
  PRODUCTION: 'production',
  PREFLIGHT: 'preflight',
  DEVELOPMENT: 'development',
  STAGING: 'staging',
} as const;

type EnvStrings = (typeof Environments)[keyof typeof Environments];

const currentEnv = process.env.REACT_APP_ENVIRONMENT_NAME as EnvStrings;

const isEnv = (envName: EnvStrings) => {
  if (!currentEnv) {
    console.error('Please set a REACT_APP_ENVIRONMENT_NAME variable');

    return false;
  }

  if (!isValidEnv(currentEnv)) {
    console.error(
      `${process.env.REACT_APP_ENVIRONMENT_NAME} is not a valid environment variable name`,
      `please use one of these strings ${JSON.stringify(Object.values(Environments))}`,
    );

    return false;
  }

  return currentEnv === envName;
};

const isValidEnv = (env: EnvStrings) => {
  return Object.values(Environments).includes(env);
};

export const notProd = () => {
  return isPreflight() || isDev() || isStaging();
};

export const isProd = () => {
  return isEnv(Environments.PRODUCTION);
};

export const isPreflight = () => {
  return isEnv(Environments.PREFLIGHT);
};

export const isDev = () => {
  return isEnv(Environments.DEVELOPMENT);
};

export const isStaging = () => {
  return isEnv(Environments.STAGING);
};

export const isCypress = () => {
  return 'Cypress' in window;
};

export const isLocal = () => {
  return !isCypress() && !isProd() && !isPreflight() && !isStaging();
};
