import {
  DeleteRounded as DeleteIcon,
  ExpandMoreRounded as ExpandMoreIcon,
} from '@mui/icons-material';
import { Accordion, AccordionDetails, AccordionSummary, Button, Grid } from '@mui/material';
import { useCallback, useState } from 'react';
import { ItemType } from 'src/enums';
import { useCurrentGroundObjects, useUpdateGroundObject } from 'src/hooks/GroundObjectHooks';
import { useCurrentGroup, useUpdateGroupMutation } from 'src/hooks/GroupHooks';
import { useCurrentOrbits, useUpdateOrbitMutation } from 'src/hooks/OrbitHooks';
import { GroupObject } from 'src/types';
import { ModalDeleteObject } from './ModalDeleteObject';
import { InputNameEditor } from './NameInputEditor';
import UngroupOrbitModal from './UngroupOrbitModal';

export const TabPanelGroupProperties = () => {
  const currentGroup = useCurrentGroup();

  const [name, setName] = useState(currentGroup?.name);
  const updateGroupMutation = useUpdateGroupMutation();
  const updateOrbitMutation = useUpdateOrbitMutation();
  const updateGroundObjectMutation = useUpdateGroundObject();

  const [deleteOrbitModalOpen, setDeleteOrbitModal] = useState(false);
  const [ungroupOrbitModalOpen, setUngroupOrbitModal] = useState(false);

  const currentOrbits = useCurrentOrbits();
  const currentGroundObjects = useCurrentGroundObjects();

  const saveGroupName = async () => {
    if (currentGroup?.id) {
      const groupUpdate: Partial<GroupObject> = {
        name: name ?? currentGroup?.name,
        notes: currentGroup?.notes,
        pageId: currentGroup?.pageId,
        id: currentGroup?.id,
        additionalProperties: currentGroup.additionalProperties,
      };
      await updateGroupMutation.mutateAsync(groupUpdate);
    }
  };

  const onUngroupMutation = useCallback(() => {
    if (currentGroup) {
      const groupOrbits = currentOrbits?.filter((orbit) => orbit.groupId === currentGroup.id);
      const groupGroundObjects = currentGroundObjects?.filter(
        (groundObj) => groundObj.groupId === currentGroup.id,
      );
      groupOrbits?.forEach((orbit) => {
        updateOrbitMutation.mutate({ groupId: null, id: orbit.id });
      });
      groupGroundObjects?.forEach((groundObj) => {
        updateGroundObjectMutation.mutate({ groupId: null, id: groundObj.id });
      });
    }
  }, [
    currentGroup,
    currentOrbits,
    updateOrbitMutation,
    currentGroundObjects,
    updateGroundObjectMutation,
  ]);

  return (
    <Grid
      container
      direction="column"
    >
      <Accordion defaultExpanded>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          style={{
            flexDirection: 'row-reverse',
          }}
          aria-controls="inspector-panel-details"
          id="inspector-panel-details"
        >
          Details
        </AccordionSummary>
        <AccordionDetails>
          <InputNameEditor
            name={name ?? null}
            setName={setName}
            label="Group Name"
            placeholder="Group Name"
            saveName={saveGroupName}
          />
        </AccordionDetails>
      </Accordion>

      <Accordion defaultExpanded>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          style={{
            flexDirection: 'row-reverse',
          }}
          aria-controls="inspector-panel-details"
          id="inspector-panel-details"
        >
          Actions
        </AccordionSummary>
        <AccordionDetails>
          <Grid
            container
            direction="column"
            alignItems="center"
          >
            {currentGroup?.id && currentOrbits?.some((orb) => orb.groupId === currentGroup.id) && (
              <Grid
                item
                pb={1}
              >
                <Button
                  color="secondary"
                  variant="contained"
                  onClick={() => setUngroupOrbitModal(true)}
                  aria-label="Ungroup"
                  startIcon={<DeleteIcon />}
                >
                  Ungroup
                </Button>
              </Grid>
            )}
            <Grid item>
              <Button
                color="error"
                variant="contained"
                onClick={() => setDeleteOrbitModal(true)}
                aria-label="Delete Group"
                startIcon={<DeleteIcon />}
              >
                Delete Group
              </Button>
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>

      {deleteOrbitModalOpen && (
        <ModalDeleteObject
          itemType={ItemType.Group}
          open={deleteOrbitModalOpen}
          show={setDeleteOrbitModal}
          item={currentGroup}
        />
      )}
      {currentGroup && ungroupOrbitModalOpen && (
        <UngroupOrbitModal
          open={ungroupOrbitModalOpen}
          show={setUngroupOrbitModal}
          ungroupMutation={onUngroupMutation}
          group={currentGroup}
        />
      )}
    </Grid>
  );
};
