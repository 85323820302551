import { Tooltip, Typography, TypographyPropsVariantOverrides, Zoom } from '@mui/material';
import { Variant } from '@mui/material/styles/createTypography';
import { OverridableStringUnion } from '@mui/types';
import { useEffect, useRef, useState } from 'react';

interface OverflowTipProps {
  text: string;
  variant?: OverridableStringUnion<Variant | 'inherit', TypographyPropsVariantOverrides>;
}

export const OverflowTip = ({ text, variant = 'inherit' }: OverflowTipProps) => {
  const [isOverflowed, setIsOverflow] = useState(false);
  const textElementRef = useRef<any>(null);

  useEffect(() => {
    if (textElementRef.current) {
      setIsOverflow(textElementRef.current.scrollWidth > textElementRef.current.clientWidth);
    }
  }, []);

  return (
    <Tooltip
      title={text}
      arrow
      disableHoverListener={!isOverflowed}
      disableInteractive
      TransitionComponent={Zoom}
    >
      <Typography
        ref={textElementRef}
        variant={variant}
        noWrap
      >
        {text}
      </Typography>
    </Tooltip>
  );
};
