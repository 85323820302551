import { useFrame } from '@react-three/fiber';
import { useMemo, useRef } from 'react';
import { useOrbit } from 'src/hooks/OrbitHooks';
import { DoubleSide, Mesh, Quaternion, Shape, ShapeGeometry, Vector3 } from 'three';
import { createGetOrbitalPlaneVector, createGetOrbitVerts } from '../../OrbitManager/store/getters';
import { use3DOrbitContext } from '../context';

type SelectedOrbitPlaneProps = {
  selected: boolean;
  orbitQuaternion?: Quaternion;
  orbitColor: string;
};

const SelectedOrbitPlane = ({ selected, orbitQuaternion, orbitColor }: SelectedOrbitPlaneProps) => {
  const { name, id } = use3DOrbitContext();

  const orbit = useOrbit(id);
  const visible = !!orbit?.additionalProperties?.visOrbit;

  const getOrbitVerts = useMemo(() => createGetOrbitVerts(id), [id]);
  const getOrbitalPlaneVector = useMemo(() => createGetOrbitalPlaneVector(id), [id]);

  const createGeometry = () => {
    const verts = getOrbitVerts();
    const orbitalPlaneVector = getOrbitalPlaneVector();
    const quaternion = new Quaternion().setFromUnitVectors(
      orbitalPlaneVector,
      new Vector3(0, 0, 1),
    );
    const shape = new Shape();

    verts.forEach((v) => {
      const { x, y } = v.clone().applyQuaternion(quaternion);
      shape.lineTo(x, y);
    });

    const shapeGeometry = new ShapeGeometry(shape);

    shapeGeometry.name = `${name} Selected Orbit Plane Geometry`;

    return shapeGeometry;
  };

  const animate = () => {
    if (ref.current && orbitQuaternion) {
      ref.current.quaternion.copy(orbitQuaternion);
      ref.current.geometry.dispose();
      ref.current.geometry = createGeometry();

      const orbitalPlaneVector = getOrbitalPlaneVector();

      const quaternionBack = new Quaternion().setFromUnitVectors(
        new Vector3(0, 0, 1),
        orbitalPlaneVector,
      );

      ref.current.applyQuaternion(quaternionBack);
    }
  };

  const ref = useRef<Mesh>(null);

  useFrame(animate);

  if (!selected) return null;

  return (
    <mesh
      ref={ref}
      visible={selected && visible}
      name={`${name} Selected Orbit Plane Mesh`}
    >
      <meshBasicMaterial
        side={DoubleSide}
        color={orbitColor}
        opacity={0.06}
        transparent
        name={`${name} Selected Orbit Plane Materal`}
        depthWrite={false}
      />
    </mesh>
  );
};

export default SelectedOrbitPlane;
