import { useCallback, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useCurrentGroundObjects } from 'src/hooks/GroundObjectHooks';
import { useGroundObjectLaunchEditStore } from 'src/threejs/components/GroundObjects/GroundObjectLaunchEditStore';
import { useRouteStore } from './store';

type RouterParams = {
  capabilityId?: string;
  folderId?: string;
  groupId?: string;
  groundObjectId?: string;
  id?: string;
  objid?: string;
  pageid?: string;
  specialId?: string;
};

export const RouteStore = () => {
  const currentGroundObjects = useCurrentGroundObjects();
  const { capabilityId, folderId, groupId, groundObjectId, id, objid, pageid, specialId } =
    useParams<RouterParams>();

  const setActiveId = useRouteStore((state) => state.setActiveId);

  useEffect(() => {
    setActiveId('capabilityId', Number(capabilityId));
  }, [capabilityId, setActiveId]);

  useEffect(() => {
    setActiveId('folderId', Number(folderId));
  }, [folderId, setActiveId]);

  useEffect(() => {
    setActiveId('groupId', Number(groupId));
  }, [groupId, setActiveId]);

  const synchronizeGroundObjectStore = useCallback(
    (objId: number) => {
      // update the ground object store with the latest api data when a ground object is selected for editing in properties panel
      const { setGroundObjectEdit } = useGroundObjectLaunchEditStore.getState();
      if (objId) {
        const currentGroundObject = currentGroundObjects?.find(
          (obj) => (obj.id as number) === objId,
        );
        if (!currentGroundObject) return;
        const { name, latitude, longitude, altitude, minElevationAngle, id, sensorRange } =
          currentGroundObject;
        setGroundObjectEdit({
          id: id as number,
          name,
          latitude,
          longitude,
          altitude,
          sensorRange,
          minElevationAngle,
          coneShaderOpacity: currentGroundObject.additionalProperties!.coneShaderOpacity,
          coneWireframeOpacity: currentGroundObject.additionalProperties!.coneWireframeOpacity,
          coneShaderColor: currentGroundObject.additionalProperties!.coneShaderColor,
          coneWireframeColor: currentGroundObject.additionalProperties!.coneWireframeColor,
          locationColor: currentGroundObject.additionalProperties!.locationColor,
          additionalProperties: currentGroundObject.additionalProperties,
        });
      } else {
        setGroundObjectEdit(null); // unset store when there is no active ground object selected
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [pageid, currentGroundObjects],
  );

  useEffect(() => {
    const objId = Number(groundObjectId);
    setActiveId('groundObjectId', objId);
    synchronizeGroundObjectStore(objId);
  }, [groundObjectId, setActiveId, synchronizeGroundObjectStore]);

  useEffect(() => {
    setActiveId('notebookId', Number(id));
  }, [id, setActiveId]);

  useEffect(() => {
    setActiveId('objectId', Number(objid));
  }, [objid, setActiveId]);

  useEffect(() => {
    setActiveId('pageId', Number(pageid));
  }, [pageid, setActiveId]);

  useEffect(() => {
    setActiveId('specialId', specialId);
  }, [specialId, setActiveId]);

  return null;
};
