import { Lathe, Text } from '@react-three/drei';
import { useFrame } from '@react-three/fiber';
import { ComponentProps, useCallback, useEffect, useMemo, useState } from 'react';
import { ObjectLabel } from 'src/components/ObjectLabel';
import { DEFAULT_ADDITIONAL_PROPERTIES_PAGE } from 'src/constants';
import { FONTS } from 'src/constants-fonts';
import { OrbitRenderOrder } from 'src/enums';
import { useCurrentPage } from 'src/hooks/PageHooks';
import theme from 'src/pages/App/Theme';
import {
  BufferAttribute,
  Group,
  LatheGeometry,
  Mesh,
  MeshBasicMaterial,
  Vector2,
  Vector3,
} from 'three';

interface ArrowVectorProps {
  color?: string;
  staticDirection?: Vector3; // static direction - only updated on render
  staticOrigin?: Vector3; // static origin - only updated on render
  staticLength?: number; // static length - only updated on render
  opacity?: number;
  labelShort?: string;
  labelTextProps?: Partial<ComponentProps<typeof Text>>;
  getVisibility?: () => boolean;
  getDestination?: () => Vector3; // used to update arrow direction AND length as magnitude if getLength and length are not defined every frame
  getLength?: () => number; // used to update arrow length every frame
  getOrigin?: () => Vector3; // used to update arrow origin position every frame
  active?: boolean;
  label?: string;
  labelPosition?: 'point' | 'stem' | 'base'; // positioned at point of arrow, along the main stem of arrow, or at base of stem
  getLabelContent?: () => string; // update text content of the label - function that can update text content every frame
}

const ARROW_VECTOR_BASE_THICKNESS = 0.005;

export const ArrowVector = ({
  color = '#fff',
  staticDirection,
  staticOrigin = new Vector3(0, 0, 0),
  staticLength,
  opacity = 1,
  labelShort,
  getVisibility,
  getDestination,
  getLength,
  getOrigin,
  active = true,
  label = labelShort,
  labelPosition = 'point',
  getLabelContent,
  labelTextProps = {},
}: ArrowVectorProps) => {
  const [groupRef, setGroupRef] = useState<Group | null>();
  const [arrowStem, setArrowStem] = useState<Mesh | null>(null);
  const [arrowPoint, setArrowPoint] = useState<Mesh | null>(null);
  const [textLabel, setLabel] = useState<Group | null>(null);

  const setArrowDirection = useCallback(
    (dir: Vector3) => {
      if (groupRef) {
        const axis = new Vector3();
        axis.set(dir.x, 0, -dir.y).normalize();
        const radians = Math.acos(dir.z);
        groupRef.quaternion.setFromAxisAngle(axis, radians);
      }
    },
    [groupRef],
  );

  const getArrowLength = useCallback(() => {
    let len = 1;
    if (getLength) {
      len = getLength();
    } else if (staticLength !== undefined) {
      len = staticLength;
    } else if (getDestination) {
      len = getDestination().length();
    }
    return len;
  }, [getDestination, getLength, staticLength]);

  // update the lathe geometries of the arrow with a new length
  const updateArrowLength = useCallback(
    (newLength: number) => {
      if (isNaN(newLength) || newLength <= 0) return;

      if (arrowStem) {
        arrowStem.geometry.dispose();
        const newLathe = new LatheGeometry(
          [
            new Vector2(0, 0),
            new Vector2(0.00025 + ARROW_VECTOR_BASE_THICKNESS, 0),
            new Vector2(0.0015 + ARROW_VECTOR_BASE_THICKNESS, newLength * 0.9),
          ],
          8,
        );
        arrowStem.geometry.setAttribute('position', newLathe.getAttribute('position'));
      }
      if (arrowPoint) {
        arrowPoint.geometry.dispose();
        const newLathe = new LatheGeometry(
          [
            new Vector2(0.0015 + ARROW_VECTOR_BASE_THICKNESS, newLength * 0.9),
            new Vector2(0.02 + ARROW_VECTOR_BASE_THICKNESS, newLength * 0.9),
            new Vector2(0, newLength),
          ],
          32,
        );
        arrowPoint.geometry.setAttribute('position', newLathe.getAttribute('position'));
      }
    },
    [arrowPoint, arrowStem],
  );

  useFrame(() => {
    if (getVisibility) {
      const isVisible = getVisibility();
      if (groupRef) groupRef.visible = isVisible;
      if (textLabel) textLabel.visible = isVisible;
    }
    if (getDestination) {
      const dest = getDestination();
      setArrowDirection(dest.normalize());
    }
    updateArrowLength(getArrowLength());
    if (getOrigin && groupRef) {
      const pos = getOrigin();
      groupRef.position.set(pos.y, pos.z, pos.x);
    }
  });

  // only update origin/direction/length on re-render if
  // useFrame functions are not defined
  useEffect(() => {
    if (!getOrigin && groupRef) {
      groupRef.position.set(staticOrigin.y, staticOrigin.z, staticOrigin.x);
    }
    if (!getDestination && staticDirection) {
      setArrowDirection(staticDirection);
    }
    updateArrowLength(getArrowLength());
  }, [
    setArrowDirection,
    updateArrowLength,
    getDestination,
    getArrowLength,
    getOrigin,
    groupRef,
    staticLength,
    staticDirection,
    staticOrigin.x,
    staticOrigin.y,
    staticOrigin.z,
  ]);

  const vertex = new Vector3();

  // calculate the world position of the the base vertex
  // in the geometry of the arrow point/stem lathe
  // and position the label next to it
  const setLabelPositionStart = (arrowPart: Mesh) => {
    if (!textLabel) return;
    const positionAttribute = arrowPart.geometry.getAttribute('position');

    vertex.fromBufferAttribute(positionAttribute as BufferAttribute, 0);
    arrowPart.localToWorld(vertex);

    textLabel.position.copy(vertex);
  };

  const setLabelPositionCenter = (arrowPart: Mesh) => {
    if (!textLabel) return;
    // place in center point of arrow stem
    const { geometry } = arrowPart;
    geometry.computeBoundingBox();

    if (!geometry?.boundingBox) return;
    geometry.boundingBox.getCenter(vertex);
    arrowPart.localToWorld(vertex);

    textLabel.position.copy(vertex);
  };

  // update the position and orientation of the text label
  useFrame(() => {
    if (textLabel) {
      if (labelPosition === 'point' && arrowPoint) {
        setLabelPositionStart(arrowPoint);
      } else if (arrowStem) {
        if (labelPosition === 'stem') {
          setLabelPositionCenter(arrowStem);
        } else if (labelPosition === 'base') {
          setLabelPositionStart(arrowStem);
        }
      }
    }
  });

  const [isHovering, setHovering] = useState(false);
  const currentPage = useCurrentPage();
  const additionalProperties =
    currentPage?.additionalProperties || DEFAULT_ADDITIONAL_PROPERTIES_PAGE;

  const isLongformMode = additionalProperties.longFormLabels;

  // override label on hover if long form labels are enabled at page level
  const labelType = isHovering ? label : labelShort;
  const displayedLabel = useMemo(
    () => (isLongformMode ? label : labelType),
    [isLongformMode, label, labelType],
  );

  // if active prop is utilized and set as false, override opacity selection and dim arrow
  const material = useMemo(
    () =>
      new MeshBasicMaterial({
        transparent: true,
        opacity: active ? opacity : 0.5,
        color: color,
        depthWrite: false,
      }),
    [color, opacity, active],
  );

  const initialArrowLength = getArrowLength();

  return (
    <>
      <group ref={setGroupRef}>
        <Lathe
          ref={setArrowStem}
          material={material}
          name="Arrow Stem"
          args={[
            [
              new Vector2(0, 0),
              new Vector2(0.00025 + ARROW_VECTOR_BASE_THICKNESS, 0),
              new Vector2(0.0015 + ARROW_VECTOR_BASE_THICKNESS, initialArrowLength * 0.9),
            ],
            8,
          ]}
        />
        <Lathe
          material={material}
          ref={setArrowPoint}
          name="Arrow Point"
          args={[
            [
              new Vector2(0.0015 + ARROW_VECTOR_BASE_THICKNESS, initialArrowLength * 0.9),
              new Vector2(0.02 + ARROW_VECTOR_BASE_THICKNESS, initialArrowLength * 0.9),
              new Vector2(0, initialArrowLength),
            ],
            32,
          ]}
        />
      </group>
      {label && (
        <ObjectLabel
          groupProps={{
            onPointerEnter: () => setHovering(true),
            onPointerLeave: () => setHovering(false),
            renderOrder: OrbitRenderOrder.ORBIT_VISUAL_AID_LABEL,
          }}
          textProps={{
            color: theme.palette.text.primary,
            fillOpacity: active ? 1 : 0.7,
            font: FONTS.StaticMontserratBoldItalic,
            ...labelTextProps,
          }}
          labelRef={textLabel}
          name={displayedLabel}
          setLabelRef={setLabel}
          getLabelContent={getLabelContent}
        />
      )}
    </>
  );
};
