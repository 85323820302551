import { Grid, MenuItem, Select, SelectChangeEvent, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useOnboardingMeQuery } from 'src/hooks/OnboardingHooks';
import { StyledLink } from '../components/MenuBar.styled';
import { Users } from './Users';
import { RouteStore } from '../routes/RouteStore';

export const Organizations = () => {
  const [activeOrganization, setActiveOrganization] = useState<string>('');
  const currentOrganizationRoles = useOnboardingMeQuery();

  useEffect(() => {
    if (currentOrganizationRoles && activeOrganization === '') {
      setActiveOrganization(currentOrganizationRoles?.organizations[0].id);
    }
  }, [activeOrganization, currentOrganizationRoles]);

  const handleChange = (event: SelectChangeEvent) => {
    setActiveOrganization(event.target.value as string);
  };

  return (
    <>
      <RouteStore />
      <Grid
        sx={{
          p: '20px 5vw',
          overflow: 'auto',
        }}
      >
        <Grid
          display="grid"
          gridAutoFlow="column"
          justifyContent="space-between"
          alignItems="end"
          sx={{
            pb: 1.5,
          }}
        >
          <Typography variant="h3">Members</Typography>

          <Typography variant="body2">
            To add a new user, please email{' '}
            <StyledLink
              href="mailto:lab-support@slingshotaerospace.com?subject=LAB - New User Request"
              target="_blank"
              rel="noopener noreferrer"
              sx={{ fontWeight: 'bold' }}
            >
              lab-support@slingshotaerospace.com
            </StyledLink>
          </Typography>
        </Grid>
        <Select
          label="Organization"
          variant="standard"
          value={activeOrganization}
          onChange={handleChange}
          sx={{
            mb: 1,
          }}
        >
          {currentOrganizationRoles?.organizations.map((org) => {
            return (
              <MenuItem
                key={org.id}
                value={org.id}
              >
                {org.name}
              </MenuItem>
            );
          })}
        </Select>
        {activeOrganization !== '' && <Users orgId={activeOrganization} />}
      </Grid>
    </>
  );
};
