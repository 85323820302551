import { DoneRounded, PriorityHighRounded, QuestionMarkRounded } from '@mui/icons-material';
import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  FormControlLabel,
  FormLabel,
  Grid,
  IconButton,
  Radio,
  RadioGroup,
  SvgIcon,
  TextField,
} from '@mui/material';
import { addDays, addMilliseconds } from 'date-fns';
import { useCallback, useEffect, useState } from 'react';
import { ReactComponent as SatelliteIcon } from 'src/assets/satellite.svg';
import TleDiagram from 'src/assets/tle_diagram.png';
import { SpaceGlassContainerBase } from 'src/components/SpaceGlassControl';
import {
  DEFAULT_ADDITIONAL_PROPERTIES_ORBIT,
  ORBIT_TYPES,
  PERTURBATIONS_DEFAULTS,
  TLE_PLACEHOLDER_TEXT,
} from 'src/constants';
import { useClearLoadingPropagation } from 'src/core/hooks';
import useAppStore from 'src/core/store';
import {
  useCreateOrbitMutation,
  useCurrentOrbits,
  useIsPropagating,
  useSelectOrbit,
} from 'src/hooks/OrbitHooks';
import { useCurrentPage, useUpdatePageMutation } from 'src/hooks/PageHooks';
import PropagatedCacheManager, { OrbitIdentifier } from 'src/models/PropagatedCacheManager';
import { CreateEditForm } from 'src/pages/Notebook/components/ModelNotebookPage.styled';
import { EphemerisUploadResponse, OrbitObject, OrbitTLE, Page } from 'src/types';
import { getTleEpochDate } from 'src/utilities/DateTimeUtils';
import {
  convertCartesianToKeplerian,
  getOrbitStateFromStateVectorString,
} from 'src/utilities/Keplerian';
import { isValidTLE } from 'src/utilities/TLEUtils';
import { isFileList } from 'src/utilities/TypeUtils';
import { Vector3 } from 'three';
import { radToDeg } from 'three/src/math/MathUtils';
import { getFilename } from '../../utilities/FileUtils';
import { getActiveNotebookId, useRouteStore } from '../App/routes/store';
import theme from '../App/Theme';
import { SETTINGS_NAMES, useIsFeatureEnabled } from '../Settings/store';
import { ModalHeader } from '../Shared/ModalSpaceGlass';
import { ModalEphemDisplay } from './ModalEphemDisplay';
import { EphemTLETextInputStyles, TLEDiagramImg, TLEInfoBtn } from './ModalEphemTLEPage.styled';

type ModalTLEPageProps = {
  isOpen: boolean;
  close: () => void;
  onSubmit?: (page: Partial<Page>, orbit: Partial<OrbitObject>) => void;
};

enum ImportMode {
  TLE = 'TLE',
  Ephemeris = 'Ephemeris',
}

/**
 * Modal popup for importing TLE/Ephemeris set into COE parameters
 */
export const ModalEphemTLEPage = ({ isOpen, close, onSubmit }: ModalTLEPageProps) => {
  const inFolder = !isNaN(useRouteStore(getActiveNotebookId));

  const currentPage = useCurrentPage();

  const [tleUserInput, setTleUserInput] = useState('');

  const importTitle = 'Import and Create Object';
  const tleInfoTitle = 'What is TLE?';
  const submitTitle = inFolder ? 'Import' : 'Create Notebook and Import';

  const clearPropagations = useClearLoadingPropagation();
  const updateTimelineRange = useAppStore((state) => state.timelines.updateTimelineRange);

  /**
   * Closes this modal
   */
  const onClosePageModal = useCallback(() => {
    setTleInfoMode(false);
    close();
  }, [close]);

  // tracks if there is an error with validation
  const [parseErr, setParseErr] = useState(false);
  // if TLE conversion to COE params and COE params are valid, than this will be set
  const [success, setSuccess] = useState(false);
  // determines whether modal is displaying TLE diagram or showing import menu
  const [tleInfoMode, setTleInfoMode] = useState(false);

  const [importMode, setImportMode] = useState<ImportMode>(ImportMode.TLE);

  const ephemInputState = useState('');
  const [ephemInput, setEphemInput] = ephemInputState;
  const ephemFileState = useState<null | FileList>(null);
  const [ephemFile, setEphemFile] = ephemFileState;
  const ephemEndTimeState = useState(false);
  const [adjustEndTimeChecked, setAdjustEndTimeChecked] = ephemEndTimeState;
  const ephemStartTimeState = useState(false);
  const [adjustStartTimeChecked, setAdjustStartTimeChecked] = ephemStartTimeState;

  const ephemResponseState = useState<EphemerisUploadResponse | null>(null);
  const [ephemResponse, setEphemResponse] = ephemResponseState;

  useEffect(() => {
    // TODO: LAB-1792: implement Ephemeris file validation
    if (importMode === ImportMode.TLE) {
      if (tleUserInput === '') {
        setParseErr(false);
        setSuccess(false);
      }

      if (isValidTLE(tleUserInput)) {
        setParseErr(false);
        setSuccess(true);
      } else if (tleUserInput !== '') {
        setParseErr(true);
        setSuccess(false);
      }
    } else if (importMode === ImportMode.Ephemeris) {
      if (
        getOrbitStateFromStateVectorString(ephemInput) ||
        (isFileList(ephemFile) && ephemResponse)
      ) {
        setSuccess(true);
        setParseErr(false);
      } else {
        setSuccess(false);
        setParseErr(false);
      }
    }
  }, [tleUserInput, importMode, ephemFile, ephemInput, ephemResponse]);

  const currentOrbits = useCurrentOrbits();

  useEffect(() => {
    const hasTimeBasedOrbit = currentOrbits?.some((orbit) =>
      [ORBIT_TYPES.TLE, ORBIT_TYPES.EPHEMERIS].includes(orbit.orbitType),
    );
    setAdjustStartTimeChecked(!hasTimeBasedOrbit);
    setAdjustEndTimeChecked(!hasTimeBasedOrbit);
  }, [currentOrbits, setAdjustStartTimeChecked, setAdjustEndTimeChecked]);

  const updatePage = useUpdatePageMutation();

  const isPropagating = useIsPropagating();

  const currentPageOrbits = useCurrentOrbits();
  const createOrbitMutation = useCreateOrbitMutation();
  const setCurrentOrbit = useSelectOrbit();

  const createOrbitTLE = useCallback((): OrbitTLE => {
    const objCount = currentPageOrbits ? currentPageOrbits.length + 1 : 1;

    const tleStringLines = tleUserInput.split(/\r\n|\r|\n/);
    // if the optional title line was not put, deliver orbit name instead
    const includesTitleLine = tleStringLines.length === 3;
    const orbitName = includesTitleLine ? tleStringLines[0] : `TLE input orbit ${objCount}`;

    const tleLine1 = includesTitleLine ? tleStringLines[1] : tleStringLines[0];
    const tleLine2 = includesTitleLine ? tleStringLines[2] : tleStringLines[1];

    // remove 3LE style title name
    const tleObjectName = orbitName.startsWith('0 ')
      ? orbitName.substring(2).trim()
      : orbitName.trim();
    return {
      catalogId: null,
      titleLine: tleObjectName,
      line1: tleLine1,
      line2: tleLine2,
    };
  }, [currentPageOrbits, tleUserInput]);

  const handleImportModeChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setImportMode((event.target as HTMLInputElement).value as ImportMode);
      setSuccess(false);
      setParseErr(false);
      setTleUserInput('');
      setEphemInput('');
      setEphemFile(null);
      setEphemResponse(null);
    },
    [setImportMode, setEphemInput, setEphemFile, setEphemResponse],
  );

  const isFeatureEphemerisEnabled = useIsFeatureEnabled(SETTINGS_NAMES.FEATURE_EPHEMERIS_ENABLED);

  const cleanup = () => {
    setSuccess(false);
    setTleUserInput('');
    setEphemInput('');
    setEphemFile(null);
    onClosePageModal();
    setEphemResponse(null);
  };

  const newOrbitTle = (): Partial<OrbitObject> => {
    const orbitTLE = createOrbitTLE();
    const tleEpochDate = getTleEpochDate(orbitTLE.line1);

    return {
      name: orbitTLE.titleLine,
      notes: 'Notes',
      orbitType: ORBIT_TYPES.TLE,
      orbitTLE: orbitTLE,
      perturbations: PERTURBATIONS_DEFAULTS.TLE,
      groupId: null,
      additionalProperties: null,
      initialEpoch: tleEpochDate.getTime(),
    };
  };

  const newOrbitEphemeris = (): Partial<OrbitObject> => {
    const newOrbit: Partial<OrbitObject> = {
      notes: '',
      perturbations: {},
      groupId: null,
      additionalProperties: DEFAULT_ADDITIONAL_PROPERTIES_ORBIT,
    };

    // handle a state vector pasted
    if (ephemInput !== '') {
      const orbitState = getOrbitStateFromStateVectorString(ephemInput)!;

      const epoch =
        orbitState?.epoch?.toMillis() || currentPage?.startTime.getTime() || new Date().getTime();

      newOrbit.name = 'Imported State Vector Orbit';
      newOrbit.orbitType = ORBIT_TYPES.STATE_VECTORS;
      newOrbit.orbit = [
        {
          ...orbitState.coe,
          epoch: epoch,
        },
      ];
      newOrbit.stateVectors = {
        xPosition: orbitState.position.x,
        yPosition: orbitState.position.y,
        zPosition: orbitState.position.z,
        xVelocity: orbitState.velocity.x,
        yVelocity: orbitState.velocity.y,
        zVelocity: orbitState.velocity.z,
      };
      newOrbit.initialEpoch = epoch;

      console.log(newOrbit.initialEpoch, epoch);
      return newOrbit;
    }

    if (ephemResponse) {
      const firstSV = ephemResponse.states[0];

      const epoch = new Date(firstSV.epoch).getTime();

      const vectorPosition = new Vector3(
        firstSV.state[0],
        firstSV.state[1],
        firstSV.state[2],
      ).divideScalar(1000); // convert to km for storage
      const vectorVelocity = new Vector3(
        firstSV.state[3],
        firstSV.state[4],
        firstSV.state[5],
      ).divideScalar(1000); // convert to km/s for storage

      const coe = convertCartesianToKeplerian(vectorPosition, vectorVelocity);

      coe.argumentOfPeriapsis = radToDeg(coe.argumentOfPeriapsis);
      coe.inclination = radToDeg(coe.inclination);
      coe.rightAscensionOfAscendingNode = radToDeg(coe.rightAscensionOfAscendingNode);
      coe.trueAnomaly = radToDeg(coe.trueAnomaly);

      newOrbit.platformId = ephemResponse.meta.slingshotId;
      newOrbit.name = `Ephemeris - ${getFilename(ephemFile?.[0].name)}`;
      newOrbit.orbitType = ORBIT_TYPES.EPHEMERIS;
      newOrbit.orbit = [
        {
          ...coe,
          epoch: epoch,
        },
      ];
      newOrbit.stateVectors = {
        xPosition: vectorPosition.x,
        yPosition: vectorPosition.y,
        zPosition: vectorPosition.z,
        xVelocity: vectorVelocity.x,
        yVelocity: vectorVelocity.y,
        zVelocity: vectorVelocity.z,
      };

      newOrbit.initialEpoch = epoch;

      return newOrbit;
    }
    return {};
  };

  const pageUpdatesTle = (orbit: Partial<OrbitObject>): Partial<Page> => {
    const pageUpdates: Partial<Page> = {};
    if (adjustStartTimeChecked && orbit.orbitTLE?.line1) {
      pageUpdates.startTime = getTleEpochDate(orbit.orbitTLE.line1);

      if (currentPage) {
        const startEndTimeDiff = currentPage.endTime.getTime() - currentPage.startTime.getTime();
        pageUpdates.endTime = addMilliseconds(pageUpdates.startTime, startEndTimeDiff);
      }
    }
    return pageUpdates;
  };

  const pageUpdatesEphemeris = (orbit: Partial<OrbitObject>): Partial<Page> => {
    const pageUpdates: Partial<Page> = {};
    if (adjustStartTimeChecked && ephemResponse?.states[0].epoch) {
      pageUpdates.startTime = new Date(ephemResponse?.states[0].epoch);

      if (currentPage) {
        const startEndTimeDiff = currentPage.endTime.getTime() - currentPage.startTime.getTime();
        pageUpdates.endTime = addMilliseconds(pageUpdates.startTime, startEndTimeDiff);
      }
    }
    if (adjustEndTimeChecked && ephemResponse?.states[ephemResponse.states.length - 1].epoch) {
      pageUpdates.endTime = new Date(ephemResponse.states[ephemResponse.states.length - 1].epoch);
    }

    // check page duration, if the start/end are same, add a day
    if (
      pageUpdates.startTime &&
      pageUpdates.endTime &&
      pageUpdates.startTime.getTime() === pageUpdates.endTime.getTime()
    ) {
      pageUpdates.endTime = addDays(pageUpdates.startTime, 1);
    }

    return pageUpdates;
  };

  const onClickSubmit = async () => {
    let newOrbit: Partial<OrbitObject> = {};
    let pageUpdates: Partial<Page> = {};

    if (importMode === ImportMode.TLE) {
      newOrbit = newOrbitTle();
      pageUpdates = pageUpdatesTle(newOrbit);
    } else if (importMode === ImportMode.Ephemeris) {
      newOrbit = newOrbitEphemeris();
      pageUpdates = pageUpdatesEphemeris(newOrbit);
    }

    if (onSubmit) {
      onSubmit(pageUpdates, newOrbit);
    } else if (currentPage) {
      const createdOrbit = await createOrbitMutation.mutateAsync(newOrbit);

      setCurrentOrbit(createdOrbit.orbits[0].id);

      let updatedPage: Page | null = null;
      // updating page start time needs to happen after orbit created, so TLE adjustments can happen in correct order
      if (pageUpdates.startTime || pageUpdates.endTime) {
        updatedPage = await updatePage.mutateAsync({
          ...currentPage,
          ...pageUpdates,
        });
      }

      if (!isPropagating && updatedPage) {
        clearPropagations();
        updateTimelineRange({
          currentTime: new Date(updatedPage.startTime).getTime(),
          currentTimePreviewMode: new Date(updatedPage.startTime).getTime(),
        });
      } else if (isPropagating && currentOrbits) {
        const updatedPage = currentPage;

        clearPropagations();

        const { id: newId, name: newName } = createdOrbit.orbits[0];

        let newOrbitIds: Array<OrbitIdentifier> = [];

        if (currentOrbits) {
          newOrbitIds = [
            { id: newId, name: newName },
            ...currentOrbits.map(({ id, name }) => ({ id, name })),
          ];
        }
        PropagatedCacheManager.setPropagatingOrbits(newOrbitIds);

        PropagatedCacheManager.propagateTimeline(
          updatedPage,
          [createdOrbit.orbits[0], ...currentOrbits],
          true,
        );

        const newTime = pageUpdates.startTime?.getTime() || currentPage.startTime.getTime();

        updateTimelineRange({
          currentTime: newTime,
          currentTimePreviewMode: newTime,
          playState: 'stopped',
        });
      }
    }

    cleanup();
  };

  return (
    <>
      <Dialog
        open={isOpen}
        onClose={onClosePageModal}
        fullWidth={true}
        aria-labelledby="max-width-dialog-title"
        maxWidth="md"
        PaperComponent={SpaceGlassContainerBase}
      >
        <ModalHeader
          onClose={onClosePageModal}
          title={tleInfoMode ? tleInfoTitle : importTitle}
          icon={
            tleInfoMode ? (
              <QuestionMarkRounded />
            ) : (
              <SvgIcon
                component={SatelliteIcon}
                inheritViewBox
                fontSize="inherit"
              />
            )
          }
        />

        {isFeatureEphemerisEnabled && !tleInfoMode && (
          <Grid
            p={3}
            pl={4}
            pb={1}
            container
            wrap="wrap"
            flex-direction="row"
            max-width="fit-content"
          >
            <FormLabel sx={{ flex: '1 1 100%' }}>Type</FormLabel>
            <RadioGroup
              sx={{
                display: 'flex',
                justifyContent: 'center',
                flexDirection: 'row',
              }}
              value={importMode}
              name="radio-buttons-import"
              onChange={handleImportModeChange}
              aria-labelledby="importMode-radio-buttons-group-label"
            >
              <FormControlLabel
                value={ImportMode.TLE}
                control={<Radio />}
                label="TLE"
              />
              <FormControlLabel
                value={ImportMode.Ephemeris}
                control={<Radio />}
                label="EPHEMERIS"
              />
            </RadioGroup>
          </Grid>
        )}

        {importMode === ImportMode.TLE && tleInfoMode && (
          <DialogContent
            max-width="fit-content"
            sx={{
              p: 0,
            }}
          >
            <TLEDiagramImg
              alt="TLE information diagram"
              src={TleDiagram}
            />
          </DialogContent>
        )}

        {importMode === ImportMode.Ephemeris && (
          <ModalEphemDisplay
            success={success}
            ephemInputState={ephemInputState}
            ephemFileState={ephemFileState}
            ephemResponseState={ephemResponseState}
          />
        )}

        {importMode === ImportMode.TLE && !tleInfoMode && (
          <DialogContent max-width="fit-content">
            <CreateEditForm
              fullWidth
              variant="standard"
            >
              <Grid
                container
                alignItems="center"
              >
                <Grid
                  item
                  p={1}
                  xs={1}
                >
                  {parseErr && <PriorityHighRounded color="error" />}
                  {success && <DoneRounded color="success" />}
                </Grid>
                <Grid
                  item
                  xs={10}
                >
                  <TextField
                    inputProps={EphemTLETextInputStyles}
                    fullWidth
                    color={success ? 'success' : 'primary'}
                    error={tleUserInput !== '' && parseErr}
                    placeholder={TLE_PLACEHOLDER_TEXT}
                    multiline
                    rows={3}
                    inputRef={(input) => input && success && input.focus()}
                    id="objectName"
                    value={tleUserInput}
                    onChange={(event) => {
                      setTleUserInput(event.target.value);
                    }}
                  />
                </Grid>
              </Grid>
            </CreateEditForm>
            <Grid
              gap={1}
              container
              justifyContent="center"
              alignItems="center"
            >
              <Grid item>Paste your two-line element set (TLE) above.</Grid>
              <IconButton onClick={() => setTleInfoMode(!tleInfoMode)}>
                <TLEInfoBtn />
              </IconButton>
            </Grid>
          </DialogContent>
        )}

        {tleInfoMode ? (
          <Button
            onClick={() => setTleInfoMode(false)}
            variant="contained"
            color="primary"
          >
            Got it
          </Button>
        ) : (
          <DialogActions>
            <Grid
              display="grid"
              gridAutoFlow="column"
              width="100%"
            >
              {inFolder && (
                <Grid
                  display="grid"
                  justifyContent="center"
                  alignItems="end"
                >
                  <FormControlLabel
                    control={
                      <Checkbox
                        size="small"
                        checked={adjustStartTimeChecked}
                        onChange={() => {
                          setAdjustStartTimeChecked(!adjustStartTimeChecked);
                        }}
                      />
                    }
                    label={
                      <DialogContentText color={theme.palette.text.primary}>
                        {`Update Timeline to match ${
                          importMode === ImportMode.TLE ? 'TLE' : 'Ephemeris'
                        } Start time`}
                      </DialogContentText>
                    }
                  />

                  {importMode === ImportMode.Ephemeris && (
                    <Grid item>
                      <FormControlLabel
                        control={
                          <Checkbox
                            size="small"
                            checked={adjustEndTimeChecked}
                            onChange={() => {
                              setAdjustEndTimeChecked(!adjustEndTimeChecked);
                            }}
                          />
                        }
                        label={
                          <DialogContentText color={theme.palette.text.primary}>
                            Update Timeline to match Ephemeris End time
                          </DialogContentText>
                        }
                      />
                    </Grid>
                  )}
                </Grid>
              )}
              <Grid
                display="grid"
                gridAutoFlow="column"
                gap={1}
                justifyContent="end"
                alignItems="end"
              >
                <Button onClick={onClosePageModal}>CANCEL</Button>

                <Button
                  variant="contained"
                  color="primary"
                  disabled={parseErr || !success}
                  onClick={onClickSubmit}
                >
                  {submitTitle}
                </Button>
              </Grid>
            </Grid>
          </DialogActions>
        )}
      </Dialog>
    </>
  );
};
