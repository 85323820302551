import { GroundObject, GroupObject, LabObject, OrbitObject } from 'src/types';

export function isOrbitObject(obj: LabObject): obj is OrbitObject {
  return (obj as OrbitObject).maneuvers !== undefined;
}

export function isGroundObject(obj: LabObject): obj is GroundObject {
  return (obj as GroundObject).latitude !== undefined;
}

export function isGroupObject(obj: LabObject): obj is GroupObject {
  return (obj as GroupObject).additionalProperties?.visOrbits !== undefined;
}

export function isFileList(ephemState: FileList | null): ephemState is FileList {
  return !!(ephemState as FileList)?.[0]?.name;
}
