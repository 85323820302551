import { useAuth0 } from '@auth0/auth0-react';
import { datadogRum } from '@datadog/browser-rum';
import { css, Global } from '@emotion/react';
import { ThemeProvider } from '@mui/material/styles';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { SnackbarProvider } from 'notistack';
import { useEffect } from 'react';
import { FONTS } from 'src/constants-fonts';
import { UserContextProvider } from 'src/contexts/UserContext';
import { ShowServerDown } from 'src/pages/Statuses/';
import { notProd } from 'src/utilities/envHelper';
import { DeveloperContainer } from '../Developer/DeveloperContainer';
import { SettingsManager } from '../Settings/SettingsManager';
import { SETTINGS_NAMES, useSettingsStore } from '../Settings/store';
import { Root } from './App.styled';
import MenuBar from './components/MenuBar';
import { SnackbarStore } from './components/SnackbarStore';
import { GlobalStoreSetup } from './GlobalStoreSetup';
import routes from './routes/Routes';
import theme from './Theme';
import { Token } from './Token';

if (process.env.REACT_APP_DATADOG_RUM_ENABLED) {
  datadogRum.init({
    applicationId: process.env.REACT_APP_DATADOG_RUM_APP_ID || '',
    clientToken: process.env.REACT_APP_DATADOG_RUM_CLIENT_ID || '',
    service: process.env.REACT_APP_DATADOG_RUM_SERVICE,
    env: process.env.REACT_APP_ENVIRONMENT_NAME,
    version: process.env.REACT_APP_VERSION,
    site: 'datadoghq.com',
    sessionSampleRate: 100,
    sessionReplaySampleRate: 20,
    trackUserInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    defaultPrivacyLevel: 'mask-user-input',
  });
}

datadogRum.startSessionReplayRecording();

const GlobalStyle = css`
  *::-webkit-scrollbar {
    background: transparent;
    width: 10px;
    height: 10px;
  }
  *::-webkit-scrollbar-thumb {
    background: ${theme.palette.background.paper};
    border-radius: 100px;
  }
  *::-webkit-scrollbar-corner {
    background: transparent;
  }

  @font-face {
    font-family: 'Montserrat';
    src: local('Montserrat'), url(${FONTS.Montserrat}) format('truetype');
    font-style: none;
  }

  @font-face {
    font-family: 'Montserrat';
    src: local('Montserrat'), url(${FONTS.MontserratItalic}) format('truetype');
    font-style: italic;
    font-synthesis: none;
  }

  body {
    font-family: ${theme.typography.fontFamily};
    font-weight: 400;
  }
`;

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

/**
 * Default app function
 */
export function App() {
  const { error, isAuthenticated, isLoading, loginWithRedirect, logout } = useAuth0();

  const isDeveloperMenuEnabled = useSettingsStore(
    (state) => state.settings[SETTINGS_NAMES.DEVELOPER_MENU_ENABLED],
  );

  const isDeveloperMenuOpen = useSettingsStore(
    (state) => state.settings[SETTINGS_NAMES.DEVELOPER_MENU_OPENED],
  );

  useEffect(() => {
    if (!isLoading && !isAuthenticated) {
      loginWithRedirect({
        appState: { returnTo: window.location.pathname },
      });
    }
  }, [isAuthenticated, isLoading, loginWithRedirect]);

  useEffect(() => {
    if (error) {
      logout();
    }
  }, [error, logout]);

  if (isLoading) {
    return null;
  }

  if (error) {
    return <p>An authentication error occurred: {error.message}</p>;
  }

  return (
    <QueryClientProvider client={queryClient}>
      <ThemeProvider theme={theme}>
        <SettingsManager />
        <ShowServerDown />

        {isAuthenticated && (
          <UserContextProvider>
            <SnackbarProvider>
              <Root
                className={`${
                  notProd() && isDeveloperMenuEnabled && isDeveloperMenuOpen ? 'withDevTools' : ''
                }`}
              >
                <Global styles={GlobalStyle} />
                <SnackbarStore />

                <Token>
                  <MenuBar />

                  <GlobalStoreSetup />

                  {routes}

                  <DeveloperContainer />
                </Token>
              </Root>
            </SnackbarProvider>
          </UserContextProvider>
        )}
      </ThemeProvider>
    </QueryClientProvider>
  );
}
