import { Snackbar, SnackbarContent, alpha, styled } from '@mui/material';

export const SnackbarStyled = styled(Snackbar)`
  background: ${({ theme }) => theme.palette.background.default};
`;

export const SnackbarContentStyled = styled(SnackbarContent)`
  background: ${({ theme }) => alpha(theme.palette.primary.main, 0.3)};
  border-radius: ${({ theme }) => theme.spacing(2)};
`;

export const ClipboardSnackbar = styled(Snackbar)`
  background: ${({ theme }) => theme.palette.success.main};
`;

export const ClipboardSnackbarContent = styled(SnackbarContent)`
  background: ${({ theme }) => theme.palette.success.main};

  .MuiSnackbarContent-message {
    margin: auto;
  }
`;
