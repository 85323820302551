import gsap, { Sine } from 'gsap';
import { TIME_TWEEN_CAMERA_MOVEMENT } from 'src/constants';
import { useViewport } from 'src/threejs/components/ViewportManager/store';
import { Vector3 } from 'three';

export const useCameraUtilities = () => {
  const { controls } = useViewport();

  const moveCameraTo = (cameraPosition: Vector3) => {
    gsap.to(controls.object.position, {
      ...cameraPosition,
      ease: Sine.easeInOut,
      duration: TIME_TWEEN_CAMERA_MOVEMENT,
    });
  };

  return {
    moveCameraTo,
  };
};
