import { useGLTF } from '@react-three/drei';
import { useMemo } from 'react';

interface GroundObjectLaunchProps {
  scale?: number;
}

export const GroundObjectLaunch = ({ scale = 1 }: GroundObjectLaunchProps) => {
  const model = useGLTF('/models/launch_pad.glb');

  const modelLaunch = useMemo(() => model.scene.clone(), [model]);

  return (
    <group
      dispose={null}
      scale={scale * 0.002}
      rotation={[Math.PI / 2, Math.PI / 2, 0]}
    >
      <primitive object={modelLaunch} />
    </group>
  );
};
