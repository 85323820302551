import { CloseRounded as CloseRoundedIcon } from '@mui/icons-material';
import { Grid, SvgIcon } from '@mui/material';
import { ReactNode } from 'react';
import { COLOR_BORDER } from '../App/Theme';
import { OverflowTip } from './OverflowTip';

interface ModalHeaderProps {
  onClose: () => void;
  title: string;
  icon: ReactNode;
}

export const ModalHeader = ({ icon, onClose, title }: ModalHeaderProps) => (
  <Grid
    p={1}
    container
    sx={{
      borderBottom: `1px solid ${COLOR_BORDER}`,
    }}
  >
    <Grid
      item
      container
      alignItems="center"
      justifyContent="space-between"
    >
      <Grid
        item
        display="grid"
        gap={0.5}
        overflow="hidden"
        gridAutoFlow="column"
        alignItems="center"
        sx={{
          fontWeight: 'bold',
        }}
      >
        {icon && <SvgIcon fontSize="inherit">{icon}</SvgIcon>}

        <OverflowTip text={title} />
      </Grid>

      <Grid
        item
        display="flex"
      >
        <CloseRoundedIcon
          onClick={onClose}
          cursor="pointer"
        />
      </Grid>
    </Grid>
  </Grid>
);
