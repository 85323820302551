import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { produce } from 'immer';
import { useCallback, useMemo } from 'react';
import { DEFAULT_ADDITIONAL_PROPERTIES_GROUP } from 'src/constants';
import { router } from 'src/pages/App/routes/Router';
import {
  getActiveCapabilityId,
  getActiveGroupId,
  getActiveNotebookId,
  getActivePageId,
  useRouteStore,
} from 'src/pages/App/routes/store';
import { fetchApi } from 'src/services/api';
import { CancellablePromise, GroupAdditionalProperties, GroupObject } from 'src/types';
import { useApiSnackbarError } from './SnackbarHooks';

const deserializeGroup = (group: GroupObject): GroupObject => {
  return {
    ...group,
    additionalProperties: {
      ...DEFAULT_ADDITIONAL_PROPERTIES_GROUP,
      ...group.additionalProperties,
    },
  };
};

export function useGroupsQuery(pageId?: number) {
  const apiSnackbarError = useApiSnackbarError();

  return useQuery<GroupObject[]>(
    ['groupsForPage', pageId],
    () => {
      const controller = new AbortController();
      const signal = controller.signal;

      const promise = fetchApi(`/v2/groups?pageId=${pageId}`, { signal }).then((data) => {
        return data ? data.map((group: GroupObject) => deserializeGroup(group)) : [];
      }) as CancellablePromise<GroupObject[]>;

      promise.cancel = () => {
        controller.abort();
      };

      return promise;
    },
    {
      enabled: Boolean(pageId),
      onError: () => {
        apiSnackbarError('Failed to get group(s).');
      },
    },
  );
}

export function useGroups(pageId?: number) {
  const { data } = useGroupsQuery(pageId);

  return data;
}

export function useGroup(id: number | null) {
  const groups = useCurrentGroups();
  return groups?.find((group) => group.id === id);
}

export function useCurrentGroup() {
  const groupId = useRouteStore(getActiveGroupId);
  const groups = useCurrentGroups();
  return useMemo(() => groups?.find((group) => group.id === groupId), [groupId, groups]);
}

export function useCurrentGroups() {
  const pageId = useRouteStore(getActivePageId);
  return useGroups(pageId);
}

export function useSelectGroup() {
  const capabilityId = useRouteStore(getActiveCapabilityId);
  const notebookId = useRouteStore(getActiveNotebookId);
  const pageId = useRouteStore(getActivePageId);

  return useCallback(
    (groupId: number | null) => {
      if (capabilityId) {
        const groupIdStr = groupId ? `/${groupId}` : '';
        router.navigate(
          `/shared/${capabilityId}/notebook/${notebookId}/${pageId}/group${groupIdStr}`,
        );
      } else {
        router.navigate(`/notebook/${notebookId}/${pageId}/group${groupId ? `/${groupId}` : ''}`);
      }
    },
    [capabilityId, notebookId, pageId],
  );
}

/** Sets new groups in the react-query cache for the current page id. */
export function useSetGroups() {
  const pageId = useRouteStore(getActivePageId);
  const queryClient = useQueryClient();

  const setGroups = useCallback(
    (groups: GroupObject[] | undefined) => {
      queryClient.setQueryData(['groupsForPage', pageId], () => {
        return groups ? [...groups] : undefined;
      });
    },
    [pageId, queryClient],
  );

  return setGroups;
}

export function useUpdateGroupAdditionalProperties() {
  const updateGroup = useUpdateGroupMutation();

  return (group: GroupObject, changes: Partial<GroupAdditionalProperties>) => {
    if (group) {
      const groupUpdate: Partial<GroupObject> = {
        ...group,
        additionalProperties: {
          ...DEFAULT_ADDITIONAL_PROPERTIES_GROUP,
          ...group.additionalProperties,
          ...changes,
        },
      };
      updateGroup.mutateAsync(groupUpdate);
    }
  };
}

type DeleteGroupMutationVariables = {
  groupId: number;
  pageId: number;
};

export function useDeleteGroupMutation() {
  const apiSnackbarError = useApiSnackbarError();

  const queryClient = useQueryClient();
  return useMutation(
    ({ groupId }: DeleteGroupMutationVariables) =>
      fetchApi(`/v2/groups/${groupId}`, {
        method: 'DELETE',
      }),
    {
      onError: () => {
        apiSnackbarError('Failed to delete group.');
      },
      onSuccess: (data, { groupId, pageId }) => {
        queryClient.invalidateQueries(['groupsForPage', pageId]);
        queryClient.invalidateQueries(['orbitsForPage', pageId]);
        queryClient.invalidateQueries(['groundObjectsForPage', pageId]);
      },
    },
  );
}

export function useCreateGroupMutation() {
  const apiSnackbarError = useApiSnackbarError();

  const queryClient = useQueryClient();
  return useMutation(
    ({ pageId, name, notes, additionalProperties }: Partial<GroupObject>) => {
      const reqBody: Partial<GroupObject> = {
        name,
        notes,
        pageId,
        additionalProperties,
      };

      return fetchApi('/v2/groups', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(reqBody),
      });
    },
    {
      onError: () => {
        apiSnackbarError('Failed to create group.');
      },
      onSuccess: (data, { pageId }) => {
        queryClient.setQueryData(
          ['groupsForPage', pageId],
          produce((groupsForPage: any) => {
            groupsForPage.push(deserializeGroup(data));
          }),
        );
      },
    },
  );
}

export function useUpdateGroupMutation() {
  const pageId = useRouteStore(getActivePageId);
  const queryClient = useQueryClient();
  const currentGroups = useCurrentGroups();

  const apiSnackbarError = useApiSnackbarError();

  return useMutation(
    (incomingGroup: Partial<GroupObject>) => {
      const groupObj = currentGroups?.find((grp) => grp.id === incomingGroup.id);

      const reqBody: Partial<GroupObject> = {
        ...groupObj,
        ...incomingGroup,
      };

      return fetchApi(`/v2/groups/${incomingGroup.id}`, {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(reqBody),
      });
    },
    {
      onError: () => {
        apiSnackbarError('Failed to udpate group.');
      },
      onSuccess: async () => {
        await queryClient.invalidateQueries(['groupsForPage', pageId]);
      },
    },
  );
}
