/** Class storing information about the API */
class API {
  #token = '';
  #userprofile = '';

  /**
   * Set the user email for the API
   * @param {string} profile for the API to be used as userName;
   */
  set userProfile(profile) {
    this.#userprofile = profile;
  }

  /**
   * Get the userprofile for the API
   * @return {string} the users profile
   * */
  get userProfile() {
    return this.#userprofile;
  }

  /**
   * Set the token for the API
   * @param {string} authToken token for the API;
   */
  set token(authToken) {
    this.#token = authToken;
  }

  /**
   * Get the token for the API
   * @return {string} the token value
   */
  get token() {
    return this.#token;
  }
}

export const API_STORE = new API();
