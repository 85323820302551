import { earthradius, muEarth } from 'src/constants';
import { COE } from 'src/threejs/models/COE';
import { Vector3 } from 'three';

function getTrueAnomalyPosition(coe: ReturnType<COE['toRad']>, realWorld?: boolean) {
  // Compute radial distance from the Earth to satellite along the entire orbit using Conic Equation
  // Calculate semi-latus rectum  [a * (1 - ecc^2)]
  const semiLatusRectum = coe.semiMajorAxis * (1 - coe.eccentricity ** 2);

  const theta = coe.trueAnomaly;
  const radius = semiLatusRectum / (1 + coe.eccentricity * Math.cos(theta));

  const rhat = new Vector3(0, 0, 0);
  // ECI equivalent in (Y-up/Z-out coordinates)
  rhat.x =
    Math.sin(coe.rightAscensionOfAscendingNode) * Math.cos(theta + coe.argumentOfPeriapsis) +
    Math.cos(coe.rightAscensionOfAscendingNode) *
      Math.cos(coe.inclination) *
      Math.sin(theta + coe.argumentOfPeriapsis);
  rhat.y = Math.sin(coe.inclination) * Math.sin(theta + coe.argumentOfPeriapsis);
  rhat.z =
    Math.cos(coe.rightAscensionOfAscendingNode) * Math.cos(theta + coe.argumentOfPeriapsis) -
    Math.sin(coe.rightAscensionOfAscendingNode) *
      Math.cos(coe.inclination) *
      Math.sin(theta + coe.argumentOfPeriapsis);

  // currently normalized to unit radius of hearth. TODO: scale manager
  const scaleFactor = 1 / earthradius; // 1e-4;

  // multiply the unit vector by the radius
  rhat.multiplyScalar(radius);

  if (!realWorld) rhat.multiplyScalar(scaleFactor);

  // Calculate Angular Rotation Rate >>>> Motion From Here
  const rsq = radius * radius; // km^2 full scale
  const angularMomentum = Math.sqrt(muEarth * semiLatusRectum); // h = sqrt(mu * p)  km^2/sec?
  const thetadot = angularMomentum / rsq; // h / r^2 is the rate of change of true anomaly

  // calculate mean motion  n = sqrt(mu/a^3)  and mu = n^2*a^3    (angular speed to somplete 1 orbit)
  const meanMotion = Math.sqrt(muEarth / coe.semiMajorAxis ** 3); // 1 rad/sec

  // compute orbital period from n = 2PI/T  or T = 2PI/n
  const period = (2 * Math.PI) / meanMotion; // sec

  // v^2 = GM(2/r - 1/a)
  const velocity = Math.sqrt(muEarth * (2 / radius - 1 / coe.semiMajorAxis)) * 1000;

  return {
    x: rhat.x,
    y: rhat.y,
    z: rhat.z,
    dtheta: thetadot,
    period: period,
    velocity,
  };
}

export default getTrueAnomalyPosition;
