import { styled } from '@mui/material';
import theme from 'src/pages/App/Theme';

export const SpaceGlassBackground =
  'linear-gradient(137.9deg, rgba(8, 65, 95, 0.48) 0.38%, rgba(0, 33, 51, 0.48) 93.81%)';

export const SpaceGlassStylesBase = `
  background: ${SpaceGlassBackground};
  backdrop-filter: blur(7.5px);
  color: ${theme.palette.text.primary};
  border-radius: 17px;
`;

export const SpaceGlassContainerBase = styled('div')`
  ${SpaceGlassStylesBase}
`;
