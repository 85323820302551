import { Grid, Typography } from '@mui/material';
import { Video } from './common/Video';

export const VideosUI = () => {
  return (
    <Grid
      display="grid"
      justifyContent="center"
      alignContent="start"
    >
      <Grid display="grid">
        <Typography
          variant="h3"
          py={2}
        >
          Laboratory UI Video Tutorials
        </Typography>
        <Grid
          display="grid"
          gap={4}
        >
          <Video
            title="Part I - Slingshot Lab Intro"
            src="https://www.youtube.com/embed/O8I2yLp28h8?si=Fg6lLIEcvhd02jLO"
          />
          <Video
            title="Part II - ECEF Viewport"
            src="https://www.youtube.com/embed/ZD-TP4rp6rg?si=7Xn3vHjfA1UxXyCl"
          />
          <Video
            title="Part III - The RIC Frame Viewport"
            src="https://www.youtube.com/embed/kZ9XdOIX_wI?si=nBZzxi9U4iXA8xG4"
          />
        </Grid>
      </Grid>
    </Grid>
  );
};
