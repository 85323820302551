import { useCallback, useEffect } from 'react';
import { useCurrentTime } from 'src/core/hooks';
import PropagatedCacheManager from 'src/models/PropagatedCacheManager';
import { inverseLerp, lerp } from 'three/src/math/MathUtils';
import use3DOrbitStore from './store/store';
import { OrbitState } from './store/types';

export const OrbitLerper = () => {
  const currentTime = useCurrentTime();

  const orbits = use3DOrbitStore((state) => state.orbits);

  const orbitCache = PropagatedCacheManager.getOrbitsCache();

  const lerpOrbits = useCallback((currentTime: number, orbits: Record<number, OrbitState>) => {
    const currentEpochTime = currentTime / 1000;

    Object.entries(orbitCache).forEach(([key, value]) => {
      const deltaFromStart = currentEpochTime - value.stateVectors[0].stateVectors[0].epoch; // first sv timestamp

      const stepSize =
        value.stateVectors[1].stateVectors[0].epoch - value.stateVectors[0].stateVectors[0].epoch;

      // if the time from orbit start is prior to first SV or after last SV, set activeSV to either first or last
      if (deltaFromStart < 0) {
        // we are before the beginning of propagation, use the first
        orbits[parseInt(key, 10)].setActiveStateVector(value.stateVectors[0].stateVectors[0]);
        return;
      } else if (deltaFromStart / stepSize > value.stateVectors.length - 1) {
        // we are after the end of propagated data, use the last
        orbits[parseInt(key, 10)].setActiveStateVector(
          value.stateVectors[value.stateVectors.length - 1].stateVectors[0],
        );
        return;
      }

      const index = deltaFromStart / stepSize;

      const low = Math.floor(index);
      const high = Math.ceil(index);

      const svLow = value.stateVectors[low].stateVectors[0];
      const svHigh = value.stateVectors[high].stateVectors[0];

      let activeSV = {
        ...svLow,
      };

      // if we have 2 diff SV, lerp them
      if (low !== high) {
        const blendTime = inverseLerp(
          value.stateVectors[low].stateVectors[0].epoch,
          value.stateVectors[high].stateVectors[0].epoch,
          currentEpochTime,
        );

        activeSV = {
          ...svLow,
          altitude: lerp(svLow.altitude, svHigh.altitude, blendTime),
          coe: {
            argumentOfPeriapsis: lerp(
              svLow.coe.argumentOfPeriapsis,
              svHigh.coe.argumentOfPeriapsis,
              blendTime,
            ),
            eccentricity: lerp(svLow.coe.eccentricity, svHigh.coe.eccentricity, blendTime),
            inclination: lerp(svLow.coe.inclination, svHigh.coe.inclination, blendTime),
            rightAscensionOfAscendingNode: lerp(
              svLow.coe.rightAscensionOfAscendingNode,
              svHigh.coe.rightAscensionOfAscendingNode,
              blendTime,
            ),
            semiMajorAxis: lerp(svLow.coe.semiMajorAxis, svHigh.coe.semiMajorAxis, blendTime),
            trueAnomaly: lerp(svLow.coe.trueAnomaly, svHigh.coe.trueAnomaly, blendTime),
          },
          ecefStateVector: {
            epoch: currentEpochTime,
            speed: lerp(svLow.ecefStateVector.speed, svHigh.ecefStateVector.speed, blendTime),
            x_position: lerp(
              svLow.ecefStateVector.x_position,
              svHigh.ecefStateVector.x_position,
              blendTime,
            ),
            y_position: lerp(
              svLow.ecefStateVector.y_position,
              svHigh.ecefStateVector.y_position,
              blendTime,
            ),
            z_position: lerp(
              svLow.ecefStateVector.z_position,
              svHigh.ecefStateVector.z_position,
              blendTime,
            ),
            x_velocity: lerp(
              svLow.ecefStateVector.x_velocity,
              svHigh.ecefStateVector.x_velocity,
              blendTime,
            ),
            y_velocity: lerp(
              svLow.ecefStateVector.y_velocity,
              svHigh.ecefStateVector.y_velocity,
              blendTime,
            ),
            z_velocity: lerp(
              svLow.ecefStateVector.z_velocity,
              svHigh.ecefStateVector.z_velocity,
              blendTime,
            ),
          },
          epoch: currentEpochTime,
          groundTrack: {
            lat: lerp(svLow.groundTrack.lat, svHigh.groundTrack.lat, blendTime),
            lon: lerp(svLow.groundTrack.lon + 360, svHigh.groundTrack.lon + 360, blendTime),
          },
          speed: lerp(svLow.speed, svHigh.speed, blendTime),
          step: index,
          sunPosition: {
            x_position: lerp(
              svLow.sunPosition.x_position,
              svHigh.sunPosition.x_position,
              blendTime,
            ),
            y_position: lerp(
              svLow.sunPosition.y_position,
              svHigh.sunPosition.y_position,
              blendTime,
            ),
            z_position: lerp(
              svLow.sunPosition.z_position,
              svHigh.sunPosition.z_position,
              blendTime,
            ),
          },
          x_position: lerp(svLow.x_position, svHigh.x_position, blendTime),
          y_position: lerp(svLow.y_position, svHigh.y_position, blendTime),
          z_position: lerp(svLow.z_position, svHigh.z_position, blendTime),
          x_velocity: lerp(svLow.x_velocity, svHigh.x_velocity, blendTime),
          y_velocity: lerp(svLow.y_velocity, svHigh.y_velocity, blendTime),
          z_velocity: lerp(svLow.z_velocity, svHigh.z_velocity, blendTime),
        };
      }

      orbits[parseInt(key, 10)].setActiveStateVector(activeSV);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    lerpOrbits(currentTime, orbits);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentTime]);

  const checkPropagationData = useCallback(() => {
    lerpOrbits(currentTime, orbits);
  }, [currentTime, lerpOrbits, orbits]);

  useEffect(() => {
    PropagatedCacheManager.addEventListener('seeked', checkPropagationData);
    return function cleanup() {
      PropagatedCacheManager.removeEventListener('seeked', checkPropagationData);
    };
  }, [checkPropagationData]);

  return null;
};
