import { Typography } from '@mui/material';
import { useEffect, useRef } from 'react';
import theme from 'src/pages/App/Theme';

interface VideoProps {
  title: string;
  src: string;
}
export const Video = ({ title, src }: VideoProps) => {
  const refContainer = useRef<HTMLDivElement | null>(null);

  // need to create the iframe element like this so we can force in the credentialless attribute so youtube won't complain
  useEffect(() => {
    const currentDiv = refContainer.current;

    // firefox does not support adding credentialless to iframe elements
    const isFirefox = navigator.userAgent.toLowerCase().indexOf('firefox') >= 0;

    if (currentDiv) {
      if (isFirefox) {
        const element = document.createElement('a');
        element.href = src;
        element.textContent = 'Watch on YouTube';
        element.target = '_blank';
        element.style.color = 'white';
        element.style.paddingLeft = theme.spacing(2);

        currentDiv.appendChild(element);

        return () => {
          currentDiv.removeChild(element);
        };
      } else {
        const element = document.createElement('iframe') as any;
        element.title = title;
        element.src = src;
        element.credentialless = true;
        element.width = 560;
        element.height = 315;
        element.style = { border: 'none' };
        element.style.border = 'none';

        currentDiv.appendChild(element);

        return () => {
          currentDiv.removeChild(element);
        };
      }
    }
  }, [refContainer, src, title]);

  return (
    <div>
      <Typography
        variant="h5"
        pb={1}
      >
        {title}
      </Typography>

      <div ref={refContainer} />
    </div>
  );
};
