import { useCurrentOrbits } from 'src/hooks/OrbitHooks';
import use3DOrbitStore from '../store/store';
import { useCallback, useEffect } from 'react';
import { OrbitState } from '../store/types';
import { CatmullRomCurve3, Matrix4 } from 'three';
import { RicStateVector, useOrbitRicStore } from './orbitRicStore';

// To help with debugging, switch to true for console output
const LOG_OUTPUT = false;
let logger = (...data: any[]) => {
  return;
};
if (LOG_OUTPUT) {
  logger = console.log;
}

interface OrbitRicDataManagerProps {
  originOrbitId: number;
}

export const OrbitRicDataManager = ({ originOrbitId }: OrbitRicDataManagerProps) => {
  const currentOrbits = useCurrentOrbits();
  const orbitDataFromStore = use3DOrbitStore((state) => state.orbits);

  const orbitDataRIC = useOrbitRicStore.getState().orbitData;
  const setOrbitDataRIC = useOrbitRicStore((state) => state.setOrbitData);

  // compute relative data from origin to chaser
  const calculateRICData = useCallback(
    (targetData: OrbitState, chaserData: OrbitState) => {
      if (!targetData || !chaserData) {
        return;
      }
      logger('--> calculateRICData for', chaserData.id, 'relative to', targetData.id);

      const pointsTargetTime = targetData.orbitPropagationPath.timePoints;
      const pointsChaserTime = chaserData.orbitPropagationPath.timePoints;

      if (pointsTargetTime.length <= 0 || pointsChaserTime.length <= 0) {
        return;
      }

      // get the time point of the orbit ending earliest
      const maxReachableTime = Math.min(
        pointsTargetTime[pointsTargetTime.length - 1],
        pointsChaserTime[pointsChaserTime.length - 1],
      );

      // if orbitDataRIC already has computed data and it's last time point is not outside of these times
      if (
        orbitDataRIC?.[targetData.id]?.[chaserData.id]?.stateVectors?.length > 0 &&
        orbitDataRIC[targetData.id][chaserData.id].stateVectors[
          orbitDataRIC[targetData.id][chaserData.id].stateVectors.length - 1
        ].time >= maxReachableTime
      ) {
        return;
      }

      logger('--> calculateRICData - computing');

      const pointsTargetECI = targetData.orbitPropagationPath.dataPoints;
      const pointsChaserECI = chaserData.orbitPropagationPath.dataPoints;

      const velocitiesTarget = targetData.orbitPropagationPath.velocityDataPoints;

      const curveChaser = new CatmullRomCurve3(pointsChaserECI);
      curveChaser.curveType = 'catmullrom';
      curveChaser.tension = 0; // setting to 0, implies no curvature between points

      // adjust the chaser points to match the quantity of target points
      const pointsChaserMatchingTarget = curveChaser.getPoints(pointsTargetECI.length - 1);

      const pointsChaserRIC: RicStateVector[] = [];

      for (let index = 0; index < pointsTargetECI.length; index++) {
        const positionTarget = pointsTargetECI[index];
        const positionChaser = pointsChaserMatchingTarget[index];

        const velocityTarget = velocitiesTarget[index];

        const ricMatrix = new Matrix4();
        const rHat = positionTarget.clone().normalize();
        const cHat = rHat.clone().cross(velocityTarget.normalize());
        const iHat = cHat.clone().cross(rHat);
        ricMatrix.makeBasis(iHat, cHat, rHat);
        ricMatrix.setPosition(positionTarget.clone());

        pointsChaserRIC[index] = {
          time: pointsTargetTime[index],
          sv: positionChaser.applyMatrix4(ricMatrix.invert()),
        };
      }
      setOrbitDataRIC(targetData.id, chaserData.id, pointsChaserRIC);
    },
    [orbitDataRIC, setOrbitDataRIC],
  );

  useEffect(() => {
    currentOrbits?.forEach((orbit) => {
      if (orbit.id !== originOrbitId) {
        calculateRICData(orbitDataFromStore[originOrbitId], orbitDataFromStore[orbit.id]);
      }
    });
  }, [calculateRICData, currentOrbits, orbitDataFromStore, originOrbitId]);

  return null;
};
