import { Vector2 } from 'three';
import { SpaceObjectSize } from '../../../../types';

/**
 * When a vertex is rotated about an origin by an angle in radians, we can compute the new point's coordinates as:
 *
 * 𝑥′=(𝑥−𝑝)cos(𝜃)−(𝑦−𝑞)sin(𝜃)+𝑝
 * 𝑦′=(𝑥−𝑝)sin(𝜃)+(𝑦−𝑞)cos(𝜃)+𝑞
 */
const rotateVertex = (toRotate: Vector2, radians: number, origin: Vector2) => {
  const cosine = Math.cos(radians);
  const sine = Math.sin(radians);
  const x = (toRotate.x - origin.x) * cosine - (toRotate.y - origin.y) * sine + origin.x;
  const y = (toRotate.y - origin.y) * cosine + (toRotate.x - origin.x) * sine + origin.y;
  return new Vector2(x, y);
};

const rotateVertices = (vertices: Array<Vector2>, angle: number, midPoint: Vector2) =>
  vertices.map((vertex) => rotateVertex(vertex, angle, midPoint));

const renderVertices = (context: CanvasRenderingContext2D, vertices: Array<Vector2>) => {
  context.moveTo(vertices[0].x, vertices[0].y);
  for (let x = 1; x < vertices.length; x++) {
    context.lineTo(vertices[x].x, vertices[x].y);
  }
  context.closePath();
  context.fill();
};

const getShapeSize = (spaceObjectSize: SpaceObjectSize) => {
  let size;
  switch (spaceObjectSize) {
    case SpaceObjectSize.LG:
      size = 8;
      break;
    case SpaceObjectSize.MD:
      size = 6;
      break;
    default:
      size = 4;
      break;
  }
  return size;
};

export const renderBall = (
  context: CanvasRenderingContext2D,
  vertex: Vector2,
  size: SpaceObjectSize,
) => {
  context.stroke();
  context.beginPath();
  context.arc(vertex.x, vertex.y, getShapeSize(size), 0, 2 * Math.PI);
  context.fill();
};

/**
 * - Find the 3 vertices around vertex (aligned with x-axis).
 * - Rotate vertices using the angle between vertex and previous vertex.
 * - Render.
 */
export const renderCone = (
  context: CanvasRenderingContext2D,
  vertex: Vector2,
  angle: number,
  spaceObjectSize: SpaceObjectSize,
) => {
  const edgeLength = getShapeSize(spaceObjectSize);

  const vertices: Array<Vector2> = [
    new Vector2(vertex.x + edgeLength, vertex.y),
    new Vector2(vertex.x - edgeLength, vertex.y + edgeLength),
    new Vector2(vertex.x - edgeLength, vertex.y - edgeLength),
  ];

  const rotatedVertices = rotateVertices(vertices, angle, vertex);

  renderVertices(context, rotatedVertices);
};

/**
 * - Find the 4 vertices around vertex (aligned with x-axis).
 * - Rotate them using the angle between vertex and previous vertex.
 * - Render
 */
export const renderCube = (
  context: CanvasRenderingContext2D,
  vertex: Vector2,
  angle: number,
  spaceObjectSize: SpaceObjectSize,
) => {
  const length = getShapeSize(spaceObjectSize);

  const vertices: Array<Vector2> = [
    new Vector2(vertex.x + length, vertex.y + length),
    new Vector2(vertex.x - length, vertex.y + length),
    new Vector2(vertex.x - length, vertex.y - length),
    new Vector2(vertex.x + length, vertex.y - length),
  ];

  const rotatedVertices = rotateVertices(vertices, angle, vertex);

  renderVertices(context, rotatedVertices);
};
