import { Grid, SvgIcon } from '@mui/material';
import { CSSProperties, ReactNode } from 'react';
import { COLOR_BORDER } from 'src/pages/App/Theme';

interface PanelHeaderProps {
  icon?: ReactNode;
  title?: string;
  rightSide?: ReactNode;
  draggable?: boolean;
  style?: CSSProperties;
}

export const PanelHeader = ({
  icon,
  title,
  rightSide,
  draggable = true,
  style = {},
}: PanelHeaderProps) => {
  if (!icon && !title && !rightSide) {
    return null;
  }

  return (
    <Grid
      display="grid"
      gridAutoFlow="column"
      gridTemplateColumns="1fr min-content"
      alignItems="center"
      gap={1}
      px={1.5}
      py={1}
      fontSize={14}
      borderBottom={`1px solid ${COLOR_BORDER}`}
      style={{
        fontVariationSettings: "'wght' 600",
        ...style,
      }}
    >
      <Grid
        className={draggable ? 'handle' : ''}
        display="grid"
        gap={1}
        gridAutoFlow="column"
        alignItems="center"
        justifyContent="start"
        data-draggable-handle={draggable}
        sx={{
          cursor: draggable ? 'move' : 'default',
        }}
      >
        {icon && (
          <div style={{ display: 'grid' }}>
            <SvgIcon fontSize="medium">{icon}</SvgIcon>
          </div>
        )}
        <div>{title}</div>
      </Grid>

      <Grid
        display="grid"
        gridAutoFlow="column"
        gap={1}
      >
        {rightSide}
      </Grid>
    </Grid>
  );
};
