import { GraphQLClient } from 'graphql-request';
import { RawRequestOptions } from 'graphql-request/build/esm/types';
import { API_STORE } from './API_STORE';

async function fetchOrThrow(input: RequestInfo, init?: RequestInit): Promise<Response> {
  const response = await fetch(input, init);
  if (!response.ok) {
    const error = new Error(response.statusText);
    throw error;
  }
  return response;
}

async function parseJSON(response: Response) {
  try {
    const string = await response.text();
    if (string === '') return {};
    return JSON.parse(string);
  } catch (e) {
    return;
  }
}

export async function fetchApi(url: string, options?: RequestInit) {
  const response = await fetchOrThrow(process.env.REACT_APP_LAB_API_URL + url, {
    ...options,
    headers: {
      ...options?.headers,
      userProfile: API_STORE.userProfile,
      Authorization: `Bearer ${API_STORE.token}`,
    },
  });
  const data = await parseJSON(response);
  return data;
}

export class HttpRequestError extends Error {
  statusCode: number;

  constructor(message: string, code: number) {
    super(message);

    this.name = 'HttpRequestError';
    this.statusCode = code;
  }
}

export async function queryGqlApi(request: string, options?: Partial<RawRequestOptions>) {
  const graphQLClient = new GraphQLClient(`${process.env.REACT_APP_SPACE_CATALOG_API_URL}`, {
    errorPolicy: 'all',
    headers: {
      authorization: `Bearer ${API_STORE.token}`,
    },
    ...options,
  });

  const response = await graphQLClient.rawRequest(request);
  if (response?.errors?.length && response?.errors?.length > 0) {
    throw response.errors;
  }
  if (response.status > 399 && response.status < 599) {
    throw new HttpRequestError(`Received ${response.status} status from server`, response.status);
  }
  return response.data;
}
