import { ExpandMoreRounded as ExpandMoreIcon } from '@mui/icons-material';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Checkbox,
  Grid,
} from '@mui/material';
import React from 'react';
import { PERTURBATIONS_DEFAULTS } from 'src/constants';
import { useCurrentOrbit, useIsPropagating } from 'src/hooks/OrbitHooks';
import { useIsReadOnly } from 'src/hooks/SharedNotebookHooks';
import {
  useCurrent3DOrbitPerturbations,
  useSaveCurrentOrbit,
} from 'src/threejs/components/OrbitManager/store/hooks';
import { Perturbations } from 'src/types';
import { Checkboxes, Label } from './PerturbationControl.styled';

interface PerturbationControlProps {
  onClick: () => void;
}

export function PerturbationControl({ onClick }: PerturbationControlProps) {
  const saveCurrentOrbit = useSaveCurrentOrbit();
  const [perturbations, setPerturbations] = useCurrent3DOrbitPerturbations();
  const isPropagating = useIsPropagating();
  const isReadOnly = useIsReadOnly();

  const currentOrbit = useCurrentOrbit();

  // TODO: when TLE's are propagated correctly, need to update this to reflect that it won't
  //       have these COE perturbations
  const basePerturbations = PERTURBATIONS_DEFAULTS.COE;

  if (perturbations === null) {
    setPerturbations(basePerturbations);
  }

  if (!currentOrbit || !perturbations) {
    return null;
  }

  function updatePerturbations(perturbationsChange: Partial<Perturbations>) {
    const newPerturbations: Perturbations = {
      ...perturbations,
      ...perturbationsChange,
    };
    if (currentOrbit) {
      setPerturbations(newPerturbations);
      saveCurrentOrbit();
    }
  }

  const areAllChecked = Object.values(perturbations).every((element) => element === true);

  const toggle = (key: string) => (_: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
    updatePerturbations({ [key]: checked });
  };

  // only display this accordion if we have some perturbations
  if (Object.keys(perturbations).length === 0) {
    return null;
  }

  return (
    <Accordion defaultExpanded>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        style={{
          flexDirection: 'row-reverse',
        }}
        aria-controls="inspector-panel-perturbations"
        id="inspector-panel-perturbations"
      >
        Perturbations
      </AccordionSummary>

      <AccordionDetails onClick={() => onClick()}>
        <Checkboxes>
          {perturbations.j2 !== undefined && (
            <>
              <Checkbox
                disabled={isPropagating || isReadOnly}
                color="primary"
                value="end"
                checked={perturbations.j2}
                onChange={toggle('j2')}
                size="small"
                inputProps={{
                  'aria-label': 'Enable J2',
                }}
              />
              <Label disabled={isPropagating || isReadOnly}>J2</Label>
            </>
          )}

          {perturbations.drag !== undefined && (
            <>
              <Checkbox
                disabled={isPropagating || isReadOnly}
                color="primary"
                value="end"
                checked={perturbations.drag}
                onChange={toggle('drag')}
                size="small"
                inputProps={{
                  'aria-label': 'Enable Drag',
                }}
              />
              <Label disabled={isPropagating || isReadOnly}>Drag</Label>
            </>
          )}

          {perturbations.solarRadiation !== undefined && (
            <>
              <Checkbox
                disabled={isPropagating || isReadOnly}
                color="primary"
                value="end"
                checked={perturbations.solarRadiation}
                onChange={toggle('solarRadiation')}
                size="small"
                inputProps={{
                  'aria-label': 'Enable Solar Radiation',
                }}
              />
              <Label disabled={isPropagating || isReadOnly}>Solar Radiation</Label>
            </>
          )}
        </Checkboxes>
        <Grid
          container
          direction="column"
          alignItems="center"
        >
          {areAllChecked ? (
            <Button onClick={() => updatePerturbations(PERTURBATIONS_DEFAULTS.COE)}>
              Clear All
            </Button>
          ) : (
            <Button
              onClick={() =>
                updatePerturbations({
                  j2: true,
                  drag: true,
                  solarRadiation: true,
                })
              }
            >
              Select All
            </Button>
          )}
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
}
