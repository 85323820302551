import { earthradius } from '../constants';
import { radToDeg, degToRad } from 'three/src/math/MathUtils';

/**
 * Use the formula below to calculate the field of regard.
 *
 * formula: arcSin(cos(groundElevAngle) * (radiusEarth / (radiusEarth + altitude)))
 *
 * @param groundElevAngle in degrees
 * @param altitude in kilometers
 */
export const calculateFieldOfRegard = (groundElevAngle: number, altitude: number): number =>
  radToDeg(
    Math.asin(Math.cos(degToRad(groundElevAngle)) * (earthradius / (earthradius + altitude))),
  );

/**
 * Use the formula below to get the nadir angle of an orbital sensor.
 */
export const getNadirAngleFromElevationAngleAndAltitude = (
  elevationAngle: number,
  altitude: number,
): number => {
  const sineRho = earthradius / (earthradius + altitude);

  const sineEta = Math.cos(degToRad(elevationAngle)) * sineRho;

  return radToDeg(Math.asin(sineEta));
};

/**
 * Calculate the SlantRange give the nadirAngle and altitude
 *
 * @param nadirAngle
 * @param altitude
 * @returns
 */
export const getSlantRangeFromNadirAngleAndAltitude = (
  nadirAngle: number,
  altitude: number,
): number => {
  const eta = degToRad(nadirAngle);
  const sineRho = earthradius / (earthradius + altitude);

  const epsilon = Math.acos(Math.sin(eta) / sineRho);

  const lambda = Math.PI * 0.5 - epsilon - eta;

  const slantRange = earthradius * (Math.sin(lambda) / Math.sin(eta));

  const slantRangeMax = Math.sqrt((earthradius + altitude) ** 2 - earthradius ** 2);

  if (isNaN(slantRange)) {
    return slantRangeMax;
  }
  return slantRange;
};

/**
 * Use the formuala below to calculate the slant range of an orbital sensor, accounting for the curve of the earth.
 *
 * @param elevationAngle in degrees
 * @param altitude in kilometers
 */
export const getSlantRangeFromElevationAngleAndAltitude = (
  elevationAngle: number,
  altitude: number,
): number => {
  const epsilon = degToRad(elevationAngle);
  const sineRho = earthradius / (earthradius + altitude);

  const sineEta = Math.cos(epsilon) * sineRho;

  const lambda = Math.PI * 0.5 - Math.asin(sineEta) - epsilon;

  return earthradius * (Math.sin(lambda) / sineEta);
};

/*
 * Calculate the earth central angle for displaying sensor Field coverage on earth in 2d
 *
 * reference Miro: https://miro.com/app/board/uXjVPic9wPE=/
 *
 * @param altitude
 * @param angleNadirDegrees
 * @returns
 */
export const earthCentralAngleFromAltitudeAndNadirAngle = (
  altitude: number,
  angleNadirDegrees: number,
) => {
  if (altitude < 0) {
    return 0;
  }

  const eta = degToRad(angleNadirDegrees);
  const sineRho = earthradius / (altitude + earthradius);

  const epsilon = Math.acos(Math.sin(eta) / sineRho);

  const lambda = 90 - angleNadirDegrees - radToDeg(epsilon);

  // rho is the angle at which the cone is wider than earth sphere
  const rho = Math.asin(sineRho);
  const lambdaMax = radToDeg(Math.acos(sineRho));

  if (isNaN(lambda) || angleNadirDegrees > radToDeg(rho)) {
    return lambdaMax;
  }

  return lambda;
};
