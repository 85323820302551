import { Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import { useCurrentPage } from 'src/hooks/PageHooks';
import { GroundObject, GroundObjectCategory } from 'src/types';
import {
  useCreateGroundObject,
  useCurrentGroundObjects,
  useSelectGroundObject,
} from '../../hooks/GroundObjectHooks';
import { getIterativeNameFromList } from '../../utilities/StringUtils';
import { ModalObjectDuplicate } from './ModalObjectDuplicate';

type ModalGroundObjectDuplicateProps = {
  show: boolean;
  onClose: VoidFunction;
  groundObject: GroundObject;
};

export function ModalGroundObjectDuplicate({
  show,
  onClose,
  groundObject,
}: ModalGroundObjectDuplicateProps) {
  const currentPage = useCurrentPage();
  const currentGroundObjects = useCurrentGroundObjects();
  const setCurrentGroundObject = useSelectGroundObject();
  const createGroundObject = useCreateGroundObject();

  const onCloseModal = () => {
    onClose();
  };

  const onSubmit = async () => {
    if (groundObject && currentPage) {
      const newGroundObject = {
        name: getIterativeNameFromList(
          groundObject.name,
          currentGroundObjects?.map((currentGroundObject) => currentGroundObject.name),
          'copy',
        ),
        latitude: groundObject.latitude,
        longitude: groundObject.longitude,
        altitude: groundObject.altitude,
        category: groundObject.category,
        pageId: groundObject.pageId,
        minElevationAngle: groundObject.minElevationAngle,
        sensorRange: groundObject.sensorRange,
      };
      const result = await createGroundObject.mutateAsync(newGroundObject);
      setCurrentGroundObject(result.id);
    }

    onCloseModal();
  };

  return (
    <ModalObjectDuplicate
      show={show}
      title={'Duplicate ' + groundObject.name}
      onSubmitFn={onSubmit}
      onCloseFn={onCloseModal}
    >
      <p>
        Duplicate <b>{groundObject.name}</b> with the following parameters:
      </p>

      <Table
        size="small"
        sx={{
          margin: 'auto',
          width: 'auto',
        }}
      >
        <TableHead>
          <TableRow>
            <TableCell>Property</TableCell>
            <TableCell>Value</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow key={groundObject.latitude}>
            <TableCell>Latitude</TableCell>
            <TableCell>{groundObject.latitude} deg</TableCell>
          </TableRow>
        </TableBody>
        <TableBody>
          <TableRow key={groundObject.longitude}>
            <TableCell>Longitude</TableCell>
            <TableCell>{groundObject.longitude} deg</TableCell>
          </TableRow>
        </TableBody>
        <TableBody>
          <TableRow key={groundObject.altitude}>
            <TableCell>Altitude</TableCell>
            <TableCell>{groundObject.altitude} km</TableCell>
          </TableRow>
        </TableBody>

        {(groundObject.category === GroundObjectCategory.OTHER ||
          groundObject.category === GroundObjectCategory.SENSOR) && (
          <>
            <TableBody>
              <TableRow key={groundObject.minElevationAngle}>
                <TableCell>Min Elevation</TableCell>
                <TableCell>{groundObject.minElevationAngle} deg</TableCell>
              </TableRow>
            </TableBody>
            <TableBody>
              <TableRow key={groundObject.sensorRange}>
                <TableCell>Sensor Range</TableCell>
                <TableCell>{groundObject.sensorRange} km</TableCell>
              </TableRow>
            </TableBody>
          </>
        )}
      </Table>
    </ModalObjectDuplicate>
  );
}
