import { DeleteRounded, PriorityHighRounded } from '@mui/icons-material';
import { Button, Dialog, DialogActions, DialogContent, Typography } from '@mui/material';
import { partial } from 'lodash';
import { useCallback } from 'react';
import { SpaceGlassContainerBase } from 'src/components/SpaceGlassControl';
import { ItemType } from 'src/enums';
import { useCurrentGroundObjects, useDeleteGroundObject } from 'src/hooks/GroundObjectHooks';
import { useDeleteGroupMutation } from 'src/hooks/GroupHooks';
import { useCurrentOrbits, useDeleteOrbitMutation } from 'src/hooks/OrbitHooks';
import { getActivePageId, useRouteStore } from 'src/pages/App/routes/store';
import { ModalHeader } from 'src/pages/Shared/ModalSpaceGlass';
import { GroundObject, GroupObject, OrbitObject } from 'src/types';
import { isGroundObject, isGroupObject, isOrbitObject } from '../../../utilities/TypeUtils';

interface IDeleteModalProps {
  open: boolean;
  show: (show: boolean) => void;
  item: OrbitObject | GroupObject | GroundObject | undefined;
  itemType: ItemType;
}

export const ModalDeleteObject = ({ open, show, item, itemType }: IDeleteModalProps) => {
  const pageId = useRouteStore(getActivePageId);
  const currentOrbits = useCurrentOrbits();
  const currentGroundObjects = useCurrentGroundObjects();

  const deleteGroundObject = useDeleteGroundObject();
  const deleteGroupMutation = useDeleteGroupMutation();
  const deleteOrbitMutation = useDeleteOrbitMutation();

  const hide = partial(show, false);

  const deleteErr = useCallback(
    (errPrompt: string) => (
      <Dialog
        fullWidth
        maxWidth="sm"
        open={open}
        onClose={hide}
        PaperComponent={SpaceGlassContainerBase}
      >
        <ModalHeader
          onClose={hide}
          title={`Cannot delete ${item?.name}`}
          icon={<PriorityHighRounded />}
        />
        <DialogContent>
          <Typography variant="body1">{errPrompt}</Typography>
        </DialogContent>
        <DialogActions>
          <Button
            type="button"
            onClick={hide}
          >
            CANCEL
          </Button>
        </DialogActions>
      </Dialog>
    ),
    [hide, open, item?.name],
  );

  const deleteItem = useCallback(() => {
    if (item?.id) {
      if (itemType === ItemType.Orbit) {
        deleteOrbitMutation.mutate({
          orbitId: item.id,
          pageId: pageId,
        });
      } else if (itemType === ItemType.GroundObject) {
        deleteGroundObject.mutate({ id: item.id });
      } else if (itemType === ItemType.Group) {
        deleteGroupMutation.mutate({ groupId: item.id, pageId: pageId });
      }
    }
    hide();
  }, [deleteGroundObject, deleteGroupMutation, deleteOrbitMutation, hide, item, itemType, pageId]);

  if (currentOrbits && currentOrbits?.length < 2 && itemType === ItemType.Orbit) {
    return deleteErr('All pages must have at least one object.');
  } else if (
    currentOrbits &&
    currentOrbits.length > 0 &&
    itemType === ItemType.Group &&
    currentOrbits.every((orbit) => orbit.groupId === item?.id)
  ) {
    return deleteErr('Cannot delete group if all orbit objects on page are within it.');
  }

  const objectNames: string[] = [];
  let message = '';
  if (isGroundObject(item!) && currentOrbits) {
    message = 'It is used as a target in sensors on the following orbits:';
    const groundObject = item;
    currentOrbits.forEach((currentOrbit) => {
      const orbitObject = currentOrbit;
      if (
        orbitObject.spaceSensors.some(
          (spaceSensor) =>
            (spaceSensor.targetGroundObjectId !== null &&
              spaceSensor.targetGroundObjectId === groundObject.id) ||
            (spaceSensor.targetGroupId !== null &&
              spaceSensor.targetGroupId === groundObject.groupId),
        )
      ) {
        objectNames.push(orbitObject.name);
      }
    });
  } else if (isOrbitObject(item!) && currentGroundObjects) {
    message = 'It is used as a target in the following ground objects:';
    currentGroundObjects.forEach((groundObject) => {
      if (item.id === groundObject.targetOrbitId) {
        objectNames.push(groundObject.name);
      }
    });
  } else if (isGroupObject(item!) && currentGroundObjects) {
    message = 'It is used as a target on the following objects:';
    currentGroundObjects.forEach((groundObject) => {
      if (item.id === groundObject.targetGroupId) {
        objectNames.push(groundObject.name);
      }
    });
    currentOrbits?.forEach((currentOrbit) => {
      const orbitObject = currentOrbit;
      if (
        orbitObject.spaceSensors.some(
          (spaceSensor) =>
            spaceSensor.targetGroupId !== null && spaceSensor.targetGroupId === item.id,
        )
      ) {
        objectNames.push(orbitObject.name);
      }
    });
  }

  return (
    <Dialog
      fullWidth
      maxWidth="sm"
      open={open}
      onClose={hide}
      PaperComponent={SpaceGlassContainerBase}
    >
      <ModalHeader
        title="Are You Sure?"
        onClose={hide}
        icon={<DeleteRounded />}
      />
      <DialogContent>
        <Typography variant="body1">
          <b>{item?.name}</b> will be deleted forever.
        </Typography>
        {objectNames.length > 0 && (
          <>
            <Typography
              variant="body1"
              pt={2}
            >
              {message}
            </Typography>
            <ul style={{ margin: 0 }}>
              {objectNames.map((orbitObjectName) => {
                return (
                  <Typography
                    variant="body1"
                    key={item?.name + orbitObjectName}
                  >
                    <li>{orbitObjectName}</li>
                  </Typography>
                );
              })}
            </ul>
          </>
        )}
      </DialogContent>
      <DialogActions>
        <Button
          type="button"
          onClick={hide}
        >
          CANCEL
        </Button>
        <Button
          color="secondary"
          variant="contained"
          onClick={deleteItem}
          aria-label="Delete Item"
        >
          Delete Forever
        </Button>
      </DialogActions>
    </Dialog>
  );
};
