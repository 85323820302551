import { Vector2 } from 'three';

/**
 * Given three points that could potentially wrap around a canvas, two of them will be on one side
 * - compare the relative distances and use the pair that are nearest
 *
 * @param pointA
 * @param pointB
 * @param pointC
 * @returns
 */
export const getDirectionWrappingFromThreePoints = (points: [Vector2, Vector2, Vector2]) => {
  const pointA = points[0];
  const pointB = points[1];
  const pointC = points[2];

  const distAB = pointA.clone().sub(pointB);
  const distBC = pointB.clone().sub(pointC);

  if (distAB.length() < distBC.length()) {
    return pointB.clone().sub(pointA).angle();
  } else {
    return pointC.clone().sub(pointB).angle();
  }
};

export const TRAJECTORY_TYPES = {
  LEADING: 'leading',
  TRAILING: 'trailing',
};

/**
 * Given an array of items, return the nearest quantity of points either before(trailing) or after(leading) from indexTarget
 *
 * @param arrPoints
 * @param indexTarget
 * @param quantity
 * @param direction
 * @returns
 */
export const getNearestPointsToIndex = (
  arrPoints: any[],
  indexTarget: number,
  quantity = 1,
  direction: (typeof TRAJECTORY_TYPES)[keyof typeof TRAJECTORY_TYPES] = 'leading',
) => {
  if (arrPoints.length < quantity) {
    return undefined;
  }

  if (direction === 'leading') {
    // use points following target
    if (indexTarget >= arrPoints.length - quantity) {
      return arrPoints.slice(-3);
    }
    return arrPoints.slice(indexTarget, indexTarget + quantity);
  } else {
    // use points preceding target
    if (indexTarget < quantity) {
      return arrPoints.slice(0, quantity);
    }
    return arrPoints.slice(indexTarget - quantity, indexTarget);
  }
};
