import { Box } from '@mui/material';
import { useEffect, useState } from 'react';

interface TimerProps {
  timeStart: number;
}

export const Timer = ({ timeStart }: TimerProps) => {
  const [time, setTime] = useState('00:00');

  useEffect(() => {
    const timeRunning = () => {
      const now = new Date().getTime();
      const delta = now - timeStart;
      const seconds = Math.floor((delta / 1000) % 60);
      const minutes = Math.floor(delta / (1000 * 60));
      const time = `
                ${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
      setTime(time);
    };

    const interval = setInterval(timeRunning, 1000);

    return () => clearInterval(interval);
  }, [timeStart]);

  return (
    <Box
      component="span"
      sx={{
        fontFeatureSettings: '"tnum"',
        fontSize: '1.1em',
      }}
    >
      {time}
    </Box>
  );
};
