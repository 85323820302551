/**
 * Logging levels
 */
export const LOGLEVEL = {
  INFO: 'info',
  WARNING: 'warning',
  ERROR: 'error',
  SUCCESS: 'success',
};

/**
 * Presets for camera positioning
 */
export const VIEWPRESET = {
  HOME: 'HOME',
  TOP: 'TOP',
  BOTTOM: 'BOTTOM',
  LEFT: 'LEFT',
  RIGHT: 'RIGHT',
};

/**
 * Modal action
 */
export const LABModalAction = {
  NULL: 'NULL',
  CREATE: 'Create',
  EDIT: 'Edit',
  DELETE: 'Delete',
  COPY: 'Copy',
} as const;

export type LABModalActionType = (typeof LABModalAction)[keyof typeof LABModalAction];
export type TLEModalActionType = (typeof LABModalAction)[keyof typeof LABModalAction];

/**
 * LAB Object Type
 */

export const LABObject = {
  NULL: 'NULL',
  FOLDER: 'FOLDER',
  NOTEBOOK: 'NOTEBOOK',
  PAGE: 'PAGE',
  SHARED_FOLDER: 'SHARED-FOLDER',
  SHARED_NOTEBOOK: 'SHARED-NOTEBOOK',
} as const;

export type LABObjectType = (typeof LABObject)[keyof typeof LABObject];

/**
 * LAB Orbit type
 */
export const LABOrbitType = [
  'Custom',
  'LEO - Circular',
  'GEO',
  'MEO',
  'Molniya',
  'Sun Synchronous',
] as const;

/**
 * LAB Web Socket Message types
 */
export const WebSocketMessage = {
  JOIN: 'join',
  PROPAGATE: 'propagate',
  PROPAGATION: 'propagation',
  SESSIONSTART: 'sessionStart',
} as const;

export type WebSocketMessageType = (typeof WebSocketMessage)[keyof typeof WebSocketMessage];

export const COE_PROP_Type = {
  SLIDER: 'slider',
  DATE: 'date',
} as const;

export type LABCoePropType = (typeof COE_PROP_Type)[keyof typeof COE_PROP_Type];

export const OrbitRenderOrder = {
  ORBIT_GRID_HELPER: 2,
  ORBIT_AXES_HELPER: 3,
  ORBIT_MARKER: 4,
  ORBIT_LABEL_TEXT: 999,
  ORBIT_EARTH_TOGGLE_OFF: 50,
  ORBIT_EARTH_TOGGLE_ON: 1,
  ORBIT_VISUAL_AID_LABEL: 0,
  EARTH_REF_GRID: 2,
  AXES_HELPER: 3,
  ORBIT_SPACE_OBJECT: 1,
  GROUND_OBJECT_CONE: 1000,
} as const;

export enum ItemType {
  Group,
  Orbit,
  GroundObject,
}

export const ACCORDION_STATES = {
  ACCORDION_EXPANDED_EARTH_VISUAL: 'accordion_expanded_earth_visual',
  ACCORDION_EXPANDED_EARTH_LIGHTING: 'accordion_expanded_earth_lighting',
};

export enum SpaceSensorPointingType {
  NadirPointing = 'NADIR_POINTING',
  TargetTracking = 'TARGET_TRACKING',
}
