import { ExpandMoreRounded } from '@mui/icons-material';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  FormControlLabel,
  FormGroup,
  Grid,
  Switch,
} from '@mui/material';
import { DEFAULT_ADDITIONAL_PROPERTIES_PAGE } from 'src/constants';
import { useCurrentPage, useUpdateCurrentPageAdditionalProperties } from 'src/hooks/PageHooks';
import { SETTINGS_NAMES, useSettingsStore } from 'src/pages/Settings/store';
import { ACCORDION_STATES } from 'src/enums';
import { PageAdditionalProperties } from 'src/types';
import { addOrRemove } from 'src/utilities/ArrayUtils';
import { LabelWithTooltip } from 'src/pages/Shared/LabelWithTooltip';

export function EarthVisualAidsOptions() {
  const currentPage = useCurrentPage();
  const updateCurrentPageAdditionalProperties = useUpdateCurrentPageAdditionalProperties();
  const additionalProperties =
    currentPage?.additionalProperties || DEFAULT_ADDITIONAL_PROPERTIES_PAGE;

  const accordionStates =
    useSettingsStore((state) => state.custom[SETTINGS_NAMES.EXPANDED_ACCORDIONS]) ||
    Object.values(ACCORDION_STATES);

  const setSetting = useSettingsStore((state) => state.setCustom);

  const areFrameVectorLabelsVisible = additionalProperties.visFrameVectorLabels;
  const areCountriesVisible = additionalProperties.visCountries;
  const areLatLongLinesVisible = additionalProperties.visLatLongLines;
  const areLongFormLabelsVisible = additionalProperties.longFormLabels;

  const handleChange = (changes: Partial<PageAdditionalProperties>) => {
    updateCurrentPageAdditionalProperties(changes);
  };

  const isExpanded = accordionStates.indexOf(ACCORDION_STATES.ACCORDION_EXPANDED_EARTH_VISUAL) >= 0;

  const toggleAccordionState = (name: string) => {
    setSetting(SETTINGS_NAMES.EXPANDED_ACCORDIONS, addOrRemove(accordionStates, name));
  };

  return (
    <Accordion
      expanded={isExpanded}
      onChange={() => toggleAccordionState(ACCORDION_STATES.ACCORDION_EXPANDED_EARTH_VISUAL)}
    >
      <AccordionSummary
        style={{
          flexDirection: 'row-reverse',
        }}
        expandIcon={<ExpandMoreRounded />}
        aria-controls="inspector-panel-visual-lighting"
        id="inspector-panel-visual-lighting"
      >
        <LabelWithTooltip
          label="Visual Aids"
          title="Edit the visibility of Earth based visual aids and enable global display settings."
        />
      </AccordionSummary>
      <AccordionDetails>
        <Grid
          container
          direction="column"
          px={2}
          gap={1}
        >
          <Grid item>
            <FormGroup>
              <FormControlLabel
                control={
                  <Switch
                    checked={areLatLongLinesVisible}
                    onChange={() => handleChange({ visLatLongLines: !areLatLongLinesVisible })}
                    size="small"
                  />
                }
                label={
                  <LabelWithTooltip
                    label="Latitude/Longitude"
                    title="Show the latitude and longitude grid on Earth's surface."
                  />
                }
              />
            </FormGroup>
          </Grid>

          <Grid item>
            <FormGroup>
              <FormControlLabel
                control={
                  <Switch
                    checked={areCountriesVisible}
                    onChange={() => handleChange({ visCountries: !areCountriesVisible })}
                    size="small"
                  />
                }
                label={
                  <LabelWithTooltip
                    label="Country Borders"
                    title="Show the borders of countries on Earth's surface."
                  />
                }
              />
            </FormGroup>
          </Grid>

          <Grid item>
            <FormGroup>
              <FormControlLabel
                control={
                  <Switch
                    checked={areFrameVectorLabelsVisible}
                    onChange={() =>
                      handleChange({ visFrameVectorLabels: !areFrameVectorLabelsVisible })
                    }
                    size="small"
                  />
                }
                label={
                  <LabelWithTooltip
                    label="Frame Vector Labels"
                    title="Show the labels of the coordinate frame axes in 3D viewports."
                  />
                }
              />
            </FormGroup>
          </Grid>

          <Grid item>
            <FormGroup>
              <FormControlLabel
                control={
                  <Switch
                    checked={areLongFormLabelsVisible}
                    onChange={() => handleChange({ longFormLabels: !areLongFormLabelsVisible })}
                    size="small"
                  />
                }
                label={
                  <LabelWithTooltip
                    label="Visual Aid Long Form Labels"
                    title="Show the long form labels on all visual aid vectors. Toggling this off will show the short form labels instead."
                  />
                }
              />
            </FormGroup>
          </Grid>
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
}
