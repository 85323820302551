import { GraphQLError } from 'graphql';
import { gql, RawRequestOptions } from 'graphql-request';
import { useCallback } from 'react';
import { SEARCH_SPACE_CATALOG_TIMEOUT_MS } from 'src/constants';
import { HttpRequestError, queryGqlApi } from 'src/services/api';
import { SpaceObject, SpaceObjectQueryResponseOMM } from 'src/types';
import { useApiSnackbarError } from './SnackbarHooks';

const ommValues = `
OBJECT_ID
NORAD_CAT_ID
OBJECT_NAME
USER_DEFINED_TLE_LINE0
USER_DEFINED_TLE_LINE1
USER_DEFINED_TLE_LINE2
EPOCH
`;

const getSpaceObjectQuery = (closestTime: string, mostRecentN: number) => `
state{
    future: omm(selection:{startTime:"${closestTime}", earliestN:${mostRecentN}}) {
      ${ommValues}
    }
    past: omm(selection:{endTime:"${closestTime}", mostRecentN:${mostRecentN}}) {
      ${ommValues}
    }
}`;

type NoradIdQueryResponse = {
  spaceObjectsByNoradId: SpaceObjectQueryResponseOMM[];
};
type ShortNameQueryResponse = {
  spaceObjectsByShortNameMatch: SpaceObjectQueryResponseOMM[];
};

type RequestTimeoutError = {
  timeoutErrMsg: string;
};

export const useSearchObjectShortNameOrId = () => {
  const apiSnackbarError = useApiSnackbarError();

  // simplify response by removing unneeded keys
  const flattenSpaceObjectResponse = useCallback(
    (
      response: SpaceObjectQueryResponseOMM[],
      queryType: SpaceObject['QUERY_TYPE'],
      startTimeDate: Date,
    ) => {
      return response
        .filter(
          (obj: SpaceObjectQueryResponseOMM) =>
            obj?.state?.future.length > 0 || obj?.state?.past.length > 0,
        )
        .flatMap(({ state: { future, past } }: SpaceObjectQueryResponseOMM) => {
          // if both past and future returned a result, use the page start time delta that is smaller
          if (past.length > 0 && future.length > 0) {
            return Math.abs(startTimeDate.getTime() - new Date(past[0].EPOCH).getTime()) <
              Math.abs(startTimeDate.getTime() - new Date(future[0].EPOCH).getTime())
              ? [...past]
              : [...future];
          }
          return [...past, ...future];
        })
        .map((item) => ({ ...item, QUERY_TYPE: queryType }));
    },
    [],
  );

  const delayPromise = useCallback(
    <T>(t: number, defaultVal: SpaceObject['QUERY_TYPE']): Promise<T> => {
      return new Promise((_res, reject) => {
        const timeoutErr = {
          timeoutErrMsg: `Space catalog search timed out query for '${defaultVal}'`,
        } as RequestTimeoutError;
        setTimeout(reject.bind(null, timeoutErr), t);
      });
    },
    [],
  );

  const timeoutPromise = useCallback(
    <T>(p: Promise<T>, timeoutTime: number, defaultVal: SpaceObject['QUERY_TYPE']): Promise<T> => {
      return Promise.race([p, delayPromise<T>(timeoutTime, defaultVal)]);
    },
    [delayPromise],
  );

  // Async iterable that first streams results for norad id and then moves on to more slow short name regex search
  return async function* (queryValue: string, signal: AbortSignal, startTimeDate: Date) {
    // TODO: LAB-1866 when meta object selectors are implemented, initial query should be a meta object for each
    // matching short name/norad ID initially, then when user selects a specific object to import, query for its OMM
    const startTimeISO = startTimeDate?.toISOString();

    const shortNameQueryPrefix = `spaceObjectsByShortNameMatch(shortNameRegex:"(?i)${queryValue}")`;
    const noradIDQueryPrefix = `spaceObjectsByNoradId(noradIds:${queryValue})`;

    const shortNameRequest = gql`
            {${shortNameQueryPrefix}{
                ${getSpaceObjectQuery(startTimeISO, 1)}
            }}
        `;
    const noradIdRequest = gql`
            {${noradIDQueryPrefix}{
                ${getSpaceObjectQuery(startTimeISO, 1)}
            }}
        `;
    const sig = { signal } as Partial<RawRequestOptions>;

    try {
      if (/^\d+$/.test(queryValue)) {
        // only query norad ID if input is fully numeric
        const noradIdResponses = await timeoutPromise<unknown>(
          queryGqlApi(noradIdRequest, sig),
          SEARCH_SPACE_CATALOG_TIMEOUT_MS,
          'NORAD ID',
        );
        yield flattenSpaceObjectResponse(
          (noradIdResponses as NoradIdQueryResponse)?.spaceObjectsByNoradId || [],
          'NORAD ID',
          startTimeDate,
        );
      }

      const shortNameResponses = await timeoutPromise<unknown>(
        queryGqlApi(shortNameRequest, sig),
        SEARCH_SPACE_CATALOG_TIMEOUT_MS,
        'Short Name',
      );
      yield flattenSpaceObjectResponse(
        (shortNameResponses as ShortNameQueryResponse)?.spaceObjectsByShortNameMatch || [],
        'Short Name',
        startTimeDate,
      );
    } catch (e) {
      const graphqlErr = e as GraphQLError[];
      const requestTimeoutErr = e as RequestTimeoutError;
      const domExceptionErr = e as DOMException;
      const httpRequestErr = e as HttpRequestError;
      const errorPrompt = `Failed search for '${queryValue}' as NORAD ID or object short name in space catalog.`;
      if (graphqlErr[0]?.message) {
        apiSnackbarError(errorPrompt);
        graphqlErr.forEach((err: GraphQLError) => console.error(err));
      } else if (requestTimeoutErr?.timeoutErrMsg) {
        console.error(requestTimeoutErr?.timeoutErrMsg);
        apiSnackbarError(requestTimeoutErr?.timeoutErrMsg);
      } else if (httpRequestErr?.statusCode) {
        console.error(httpRequestErr.message);
        apiSnackbarError(errorPrompt);
      } else {
        if (!domExceptionErr?.ABORT_ERR) console.error(e);
      }
    }
  };
};
