import { useEffect } from 'react';
import { LAUNCH_FLYOUT_STATUS } from 'src/constants';
import { useCurrentOrbit } from 'src/hooks/OrbitHooks';
import PropagatedCacheManager from 'src/models/PropagatedCacheManager';
import { OrbitObject } from 'src/types';
import { useGroundObjectLaunchEditStore } from '../GroundObjects/GroundObjectLaunchEditStore';
import OrbitProvider from '../Orbit/context';
import Orbit from '../Orbit/Orbit';

type OrbitManagerProps = {
  setLoading: (loading: boolean) => void;
  orbits: OrbitObject[];
};

const OrbitManager = ({ setLoading, orbits }: OrbitManagerProps) => {
  const currentOrbit = useCurrentOrbit();

  const launchFlyoutStatus = useGroundObjectLaunchEditStore((state) => state.isLaunchEditMode);

  useEffect(() => {
    if (orbits) {
      const orbitIds = orbits.map(({ id, name }) => ({ id, name }));
      PropagatedCacheManager.setPropagatingOrbits(orbitIds);
    }
  }, [orbits]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setLoading(false);
    }, 1000);

    return function cleanup() {
      clearTimeout(timeout);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // hide all orbits if Launch Editor is shown
  if (launchFlyoutStatus === LAUNCH_FLYOUT_STATUS.CREATING) {
    return null;
  }

  return (
    <>
      {orbits?.map((orbit, index) => (
        <OrbitProvider
          key={orbit.id}
          id={orbit.id}
          name={orbit.name}
          size={1}
          selected={currentOrbit?.id === orbit.id}
        >
          <Orbit
            orbit={orbit}
            index={index}
          />
        </OrbitProvider>
      ))}
    </>
  );
};

export default OrbitManager;
