import { useMemo } from 'react';
import { useFrame, useThree } from '@react-three/fiber';
import {
  createGetOrbitCOE,
  createGetOrbitData,
  createSetOrbitTA,
} from 'src/threejs/components/OrbitManager/store/getters';
import { OrbitGeoObjectType, OrbitObject, SpaceObjectShape } from 'src/types';
import { Euler, Matrix4, Object3D, Vector3 } from 'three';
import { useViewportReferenceFrame } from 'src/threejs/components/ViewportManager/store';
import getTrueAnomalyPosition from 'src/threejs/math/getTrueAnomalyPosition';
import { SpaceObjectScales, SpaceObjectShapes } from './SpaceObjectProperties';
import { SPACE_OBJECT_COLOR_DEFAULT, SPACE_OBJECT_SIZE_DEFAULT } from 'src/constants';

interface OrbitGeoObjectProps {
  orbit: OrbitObject;
  parentMesh: Object3D;
  objectType: OrbitGeoObjectType;
}

// Orbital geometry object always pointing in the direction it is propagating
export const OrbitGeoObject = ({ orbit, parentMesh, objectType }: OrbitGeoObjectProps) => {
  const { camera } = useThree();
  const { isRIC } = useViewportReferenceFrame();

  const { id } = orbit;

  const getOrbitData = useMemo(() => createGetOrbitData(id), [id]);
  const getOrbitCOE = useMemo(() => createGetOrbitCOE(id), [id]);
  const setOrbitTrueAnomaly = useMemo(() => createSetOrbitTA(id), [id]);

  useFrame(() => {
    // dont perform orientation for ground track objects - handled by ground track component
    if (objectType === OrbitGeoObjectType.GroundTrack) {
      return;
    }

    // if current, then we don't have propagation and TA spheres visible
    if (parentMesh) {
      const { x, y, z } = getTrueAnomalyPosition(getOrbitCOE().toRad());
      parentMesh.position.set(x, y, z);
      setOrbitTrueAnomaly(x, y, z);

      // properly set the rotation of the TA marker
      const { orbitalPlaneVector } = getOrbitData();
      const rHat = parentMesh.position.clone().normalize();
      const cHat = orbitalPlaneVector.clone().normalize();
      const iHat = cHat.clone();
      iHat.cross(rHat).normalize();
      const ricMatrix = new Matrix4();
      ricMatrix.makeBasis(iHat, cHat, rHat);
      const euler = new Euler();
      euler.setFromRotationMatrix(ricMatrix);
      parentMesh.setRotationFromEuler(euler);

      let distance = 0;
      if (isRIC) {
        // get camera position in world space for RIC frame
        const cameraWorldPosition = new Vector3();
        camera.getWorldPosition(cameraWorldPosition);
        distance = cameraWorldPosition.distanceTo(parentMesh.position);
      } else {
        distance = camera.position.distanceTo(parentMesh.position);
      }
      // set marker autoscaling with max size limit.  This sets the default round TA marker size (/100)
      const targetScale = distance / 100;
      const size = Math.min(targetScale, 0.05); // this clamps the scale to keep it small when we zoom far out
      parentMesh.scale.set(size, size, size);
    }
  });

  const currentSpaceObjectColor =
    orbit?.additionalProperties?.spaceObjectColor || SPACE_OBJECT_COLOR_DEFAULT;

  let currentSpaceObjectSize =
    orbit?.additionalProperties?.spaceObjectSize || SPACE_OBJECT_SIZE_DEFAULT;

  let currentSpaceObjectShape =
    orbit?.additionalProperties?.spaceObjectShape || SpaceObjectShape.CUBE;

  if (objectType === OrbitGeoObjectType.GroundTrack) {
    currentSpaceObjectSize =
      orbit?.additionalProperties?.groundTrackObjectSize || SPACE_OBJECT_SIZE_DEFAULT;
    currentSpaceObjectShape =
      orbit?.additionalProperties?.groundTrackObjectShape || SpaceObjectShape.CUBE;
  }

  const SpaceObj = SpaceObjectShapes[currentSpaceObjectShape];
  const spaceObjSize = SpaceObjectScales[currentSpaceObjectShape][currentSpaceObjectSize];

  return (
    <SpaceObj
      objScale={spaceObjSize}
      objColor={currentSpaceObjectColor}
    />
  );
};
