import { DEFAULT_SPACE_SENSOR } from 'src/constants';
import { useEffectOnce } from 'usehooks-ts';
import { useCurrentOrbit } from '../../../hooks/OrbitHooks';
import { useCreateSpaceSensor } from '../../../hooks/SpaceSensorsHooks';
import { TabPanelOrbitSensorsEdit } from './TabPanelOrbitSensorsEdit';
import { TabPanelOrbitSensorsWindows } from './TabPanelOrbitSensorsWindows';

export const TabPanelOrbitSensors = () => {
  const currentOrbit = useCurrentOrbit();
  const createSpaceSensor = useCreateSpaceSensor();

  // until support for multiple sensors, default to the first
  const selectedSpaceSensor = currentOrbit?.spaceSensors[0] || null;

  // if no sensors, create one
  useEffectOnce(() => {
    if (currentOrbit && currentOrbit.spaceSensors.length < 1) {
      createSpaceSensor.mutate({
        orbitId: currentOrbit.id,
        ...DEFAULT_SPACE_SENSOR,
      });
    }
  });

  if (!selectedSpaceSensor) {
    return null;
  }

  return (
    <>
      <TabPanelOrbitSensorsEdit spaceSensor={selectedSpaceSensor} />
      <TabPanelOrbitSensorsWindows spaceSensor={selectedSpaceSensor} />
    </>
  );
};
