import { DeleteRounded } from '@mui/icons-material';
import { Button, Dialog, DialogActions, DialogContent, Typography } from '@mui/material';
import { SpaceGlassContainerBase } from 'src/components/SpaceGlassControl';
import { useDeleteManeuver } from 'src/hooks/ManeuverHooks';
import { ModalHeader } from 'src/pages/Shared/ModalSpaceGlass';
import { Maneuver } from 'src/types';

type ModalDeleteManeuverProps = {
  editingManeuver?: Maneuver;
  open: boolean;
  show: (show: boolean) => void;
};

export function ModalDeleteManeuver(props: ModalDeleteManeuverProps) {
  const deleteManeuver = useDeleteManeuver();

  const onConfirmDelete = () => {
    if (props.editingManeuver) {
      deleteManeuver.mutate(props.editingManeuver);
    }
    props.show(false);
  };

  const onClose = () => {
    props.show(false);
  };

  return (
    <Dialog
      fullWidth
      maxWidth="sm"
      onClose={onClose}
      open={props.open}
      PaperComponent={SpaceGlassContainerBase}
    >
      <ModalHeader
        title="Are You Sure?"
        onClose={onClose}
        icon={<DeleteRounded />}
      />
      <DialogContent>
        <Typography variant="body1">This Maneuver will be deleted forever.</Typography>
      </DialogContent>
      <DialogActions>
        <Button
          type="button"
          onClick={onClose}
        >
          CANCEL
        </Button>
        <Button
          color="secondary"
          variant="contained"
          onClick={onConfirmDelete}
          aria-label="Delete Bookmark"
        >
          Delete Forever
        </Button>
      </DialogActions>
    </Dialog>
  );
}
