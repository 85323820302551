import { FormControl, FormControlLabel, FormGroup, Switch, Typography } from '@mui/material';
import { useSettingsStore } from '../Settings/store';

export interface ToggleSettingItem {
  name: string; // value to display next to toggle
  settingName: string; // value from SETTINGS_NAMES constant in settings store
}

interface ToggleSettingsProps {
  title: string;
  settings: ToggleSettingItem[];
}

export const ToggleSettings = ({ title, settings }: ToggleSettingsProps) => {
  const toggleSetting = useSettingsStore((state) => state.toggleSetting);

  const settingsValues = useSettingsStore((state) => state.settings);

  if (settings.length < 1) {
    return null;
  }

  return (
    <FormControl
      variant="standard"
      fullWidth
      sx={{
        pt: 2,
      }}
    >
      {title !== '' && (
        <Typography
          variant="h6"
          pb={1}
        >
          {title}
        </Typography>
      )}
      <FormGroup sx={{ pl: 2 }}>
        {settings.map((toggle) => {
          return (
            <FormControlLabel
              key={toggle.settingName}
              label={toggle.name}
              control={
                <Switch
                  size="small"
                  checked={settingsValues[toggle.settingName]}
                  onChange={() => toggleSetting(toggle.settingName)}
                />
              }
              sx={{
                pb: 1,
              }}
            />
          );
        })}
      </FormGroup>
    </FormControl>
  );
};
