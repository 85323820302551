import { Box, FormControl, MenuItem, Select, SelectChangeEvent } from '@mui/material';
import { useEffect, useState } from 'react';
import { useIsReadOnly } from 'src/hooks/SharedNotebookHooks';
import { SETTINGS_NAMES, useSettingsStore } from 'src/pages/Settings/store';
import { CreateBookmark } from './CreateBookmark';

const MODES = {
  BUILDER: 'builder',
  PLAYBACK: 'playback',
};
const optionsMode = [
  { label: 'Story Builder', value: MODES.BUILDER },
  { label: 'Playing Story', value: MODES.PLAYBACK },
];

export function StoryModeSelection() {
  const isReadOnly = useIsReadOnly();

  const setSetting = useSettingsStore((state) => state.setSetting);
  const isStoryEditable = useSettingsStore(
    (state) => state.settings[SETTINGS_NAMES.STORYBUILDER_EDITABLE],
  );

  const [mode, setMode] = useState(isStoryEditable ? MODES.BUILDER : MODES.PLAYBACK);

  // if in readonly mode, force into playback mode
  useEffect(() => {
    if (isReadOnly) {
      setMode(MODES.PLAYBACK);
    }
  }, [isReadOnly]);

  const onModeChange = (event: SelectChangeEvent<string>) => {
    setMode(event.target.value);
  };

  useEffect(() => {
    let isEnabled = false;
    if (mode === MODES.BUILDER) {
      isEnabled = true;
    }
    setSetting(SETTINGS_NAMES.STORYBUILDER_EDITABLE, isEnabled);
  }, [mode, setSetting]);

  if (isReadOnly) {
    return <></>;
  }

  return (
    <Box
      component="div"
      display="flex"
      alignItems="center"
    >
      {isStoryEditable && <CreateBookmark />}
      <FormControl variant="standard">
        <Select
          id="story-select-label"
          value={mode}
          label="Mode"
          onChange={onModeChange}
        >
          {optionsMode.map((option) => (
            <MenuItem
              key={option.value}
              value={option.value}
            >
              {option.label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  );
}
