import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { fetchApi } from 'src/services/api';
import { OrganizationsRolesForUser, OrganizationUsers } from 'src/types';
import { useApiSnackbarError } from './SnackbarHooks';

export function useOnboardingMeQuery() {
  const apiSnackbarError = useApiSnackbarError();

  const { data } = useQuery<OrganizationsRolesForUser>(
    ['OrgsRolesMe'],
    async () => {
      const orgsRoles = await fetchApi('/v2/onboarding/me');

      return orgsRoles;
    },
    {
      onError: () => {
        apiSnackbarError('Failed to get your information.');
      },
    },
  );

  return data;
}

export function useOnbaordingUsersByOrgIdQuery(orgId: string) {
  const apiSnackbarError = useApiSnackbarError();

  const { data } = useQuery<OrganizationUsers>(
    ['OrgUsers', orgId],
    async () => {
      const orgsRoles = await fetchApi(`/v2/onboarding/${orgId}/users`);

      return orgsRoles;
    },
    {
      onError: () => {
        apiSnackbarError('Failed to get user information.');
      },
    },
  );

  return data;
}

interface DeleteUserFromOrg {
  orgId: string;
  idpUserId: string;
}

export function useOnboardingRemoveUserFromOrg() {
  const apiSnackbarError = useApiSnackbarError();

  const queryClient = useQueryClient();
  return useMutation(
    ({ orgId, idpUserId }: DeleteUserFromOrg) => {
      return fetchApi(`/v2/onboarding/${orgId}/users/${idpUserId}`, {
        method: 'DELETE',
        headers: { 'Content-Type': 'application/json' },
      });
    },
    {
      onError: () => {
        apiSnackbarError('Failed to remove user from organization.');
      },
      onSuccess: async (_, variables) => {
        await queryClient.invalidateQueries(['OrgUsers', variables.orgId]);
      },
    },
  );
}
