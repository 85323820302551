import { ReactNode } from 'react';

interface ScrollableContainerProps {
  children: ReactNode;
}

export const ScrollableContainer = ({ children }: ScrollableContainerProps) => {
  return (
    <div
      style={{
        height: '100%',
        overflowY: 'auto',
      }}
    >
      {children}
    </div>
  );
};
