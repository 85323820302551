type Listener = (...args: any[]) => void;
type Subscribers = Record<string, Listener[]>;

/** A model for publishing and subscribing to events. */
class PubSub {
  private subscribers: Subscribers = {};

  public unsubscribeForAllEvents = () => {
    this.subscribers = {};
  };

  public unsubscribeForEvent = (event: string) => {
    this.subscribers[event] = [];
    delete this.subscribers[event];
  };

  public publish = (event: string, data: any) => {
    if (this.subscribers[event]) {
      this.subscribers[event].forEach((listener) => {
        listener(data);
      });
    }
  };

  private removeListener = (event: string, index: number) => {
    const subscribers = this.subscribers[event];

    if (subscribers) {
      const newSubscribers = [...this.subscribers[event]];
      newSubscribers.splice(index, 1);
      this.subscribers[event] = newSubscribers;
    }
  };

  public subscribe = (event: string, listener: Listener) => {
    if (!this.subscribers[event]) {
      this.subscribers[event] = [];
    }

    const subscribers = [...this.subscribers[event]].concat(listener);

    const index = subscribers.length - 1;

    this.subscribers[event] = subscribers;

    return () => {
      this.removeListener(event, index);
    };
  };

  public unsubscribe = (event: string, listener: Listener) => {
    if (!this.subscribers[event]) {
      console.warn(`You attempted to unsubcribe from an event that doesn't exist: ${event}`);
      return;
    }

    const index = this.subscribers[event].findIndex((func) => {
      return func === listener;
    });

    if (index > -1) {
      this.removeListener(event, index);
    } else {
      console.warn(
        `You attempted to unsubcribe a listener that doesn't exist: ${listener.toString()}`,
      );
    }
  };
}

export default PubSub;
