import { WarningRounded } from '@mui/icons-material';
import { Grid, Tooltip } from '@mui/material';
import { differenceInMilliseconds } from 'date-fns';
import { CONTENT, ORBIT_TYPES, TEXT_TLE_WARNING, WEEK_IN_MILLISECONDS } from 'src/constants';
import { useOrbit } from 'src/hooks/OrbitHooks';
import { useCurrentPage } from 'src/hooks/PageHooks';
import { OverflowTip } from 'src/pages/Shared/OverflowTip';
import use3DOrbitStore from 'src/threejs/components/OrbitManager/store/store';
import { getTleEpochDate, millisToLocale } from 'src/utilities/DateTimeUtils';
import { isOrbitObject } from 'src/utilities/TypeUtils';

interface ObjectItemOrbitNameProps {
  id: number;
}

export const ObjectItemOrbitName = ({ id }: ObjectItemOrbitNameProps) => {
  const orbitData = useOrbit(id);
  const currentPage = useCurrentPage();

  const orbitConditionEscapeVelocity = use3DOrbitStore(
    (state) => state.orbits[id!]?.orbitData?.hasConditionEscapeVelocity,
  );

  const orbitTimePoints = use3DOrbitStore(
    (state) => state.orbits[id!]?.orbitPropagationPath.timePoints,
  );

  const isOrbitObj = orbitData && isOrbitObject(orbitData);

  const outdatedTLE =
    isOrbitObj &&
    currentPage?.startTime &&
    orbitData.orbitType === ORBIT_TYPES.TLE &&
    Math.abs(
      differenceInMilliseconds(getTleEpochDate(orbitData.orbitTLE.line1!), currentPage.startTime),
    ) > WEEK_IN_MILLISECONDS;

  // if we have an ephemeris orbit, display warning/error about undisplayed data
  let orbitEphemerisTimeErrorState: 'none' | 'warning' | 'error' = 'none';
  let orbitEphemerisTimeErroToolTip = <></>;

  if (
    isOrbitObj &&
    orbitData.orbitType === ORBIT_TYPES.EPHEMERIS &&
    orbitTimePoints[0] &&
    currentPage?.startTime &&
    currentPage?.endTime
  ) {
    let orbitEphemerisTimeErrorString = '';
    const ephemTimeStart = orbitTimePoints[0] * 1000;
    const ephemTimeEnd = orbitTimePoints[orbitTimePoints.length - 1] * 1000;
    const pageTimeStart = new Date(currentPage.startTime).getTime();
    const pageTimeEnd = new Date(currentPage.endTime).getTime();

    if (ephemTimeStart > pageTimeEnd || ephemTimeEnd < pageTimeStart) {
      orbitEphemerisTimeErrorState = 'error';
      orbitEphemerisTimeErrorString = 'Orbit data is entirely outside of page time range.';
    } else if (ephemTimeStart < pageTimeStart && ephemTimeEnd > pageTimeEnd) {
      orbitEphemerisTimeErrorState = 'warning';
      orbitEphemerisTimeErrorString =
        'Orbit data begins before page start time and continues beyond page end time.';
    } else if (ephemTimeStart < pageTimeStart) {
      orbitEphemerisTimeErrorState = 'warning';
      orbitEphemerisTimeErrorString = 'Orbit data begins before page start time.';
    } else if (ephemTimeEnd > pageTimeEnd) {
      orbitEphemerisTimeErrorState = 'warning';
      orbitEphemerisTimeErrorString = 'Orbit data continues beyond page end time.';
    }

    if (orbitEphemerisTimeErrorState !== 'none') {
      orbitEphemerisTimeErroToolTip = (
        <>
          <div>{orbitEphemerisTimeErrorString}</div>
          <div>Start: {millisToLocale(ephemTimeStart, 'UTC')}</div>
          <div>End: {millisToLocale(ephemTimeEnd, 'UTC')}</div>
        </>
      );
    }
  }

  if (!orbitData) {
    return null;
  }

  return (
    <Grid
      display="grid"
      gridAutoFlow="column"
      alignItems="center"
      justifyContent="space-between"
      gap={1.5}
    >
      <OverflowTip text={orbitData.name} />

      <Grid>
        {orbitConditionEscapeVelocity && (
          <Tooltip
            placement="top"
            title={CONTENT.ERRORS.ORBIT.ESCAPE_VELOCITY}
          >
            <WarningRounded
              color="warning"
              sx={{
                display: 'grid',
                fontSize: 12,
              }}
            />
          </Tooltip>
        )}

        {outdatedTLE && (
          <Tooltip
            placement="top"
            title={TEXT_TLE_WARNING}
          >
            <WarningRounded
              color="warning"
              sx={{
                display: 'grid',
                fontSize: 12,
              }}
            />
          </Tooltip>
        )}

        {orbitEphemerisTimeErrorState !== 'none' && (
          <Tooltip
            placement="top"
            title={orbitEphemerisTimeErroToolTip}
          >
            <WarningRounded
              color={orbitEphemerisTimeErrorState}
              sx={{
                display: 'grid',
                fontSize: 12,
              }}
            />
          </Tooltip>
        )}
      </Grid>
    </Grid>
  );
};
