import { createSelector } from 'reselect';
import { AppStoreState } from './types';

const baseSelector = (state: AppStoreState) => state;

export const selectTimelineState = createSelector(baseSelector, (state) => {
  return state.timelines;
});

export const selectActiveTimeline = createSelector(selectTimelineState, (state) => {
  return state.currentPageId
    ? state.cache[state.currentPageId] ?? state.timelineRange
    : state.timelineRange;
});

export const selectCurrentTime = createSelector(selectActiveTimeline, (state) => state.currentTime);

export const selectPlaySpeed = createSelector(selectActiveTimeline, (state) => state.playSpeed);

export const selectPlayState = createSelector(selectActiveTimeline, (state) => state.playState);

export const selectUpdateTimelineRange = createSelector(
  baseSelector,
  (state) => state.timelines.updateTimelineRange,
);
