import { Button, styled, TextField, Typography } from '@mui/material';

export const EmptyInspectorMessage = styled('div')`
  display: flex;
  align-items: center;
  height: 100%;
  padding: ${({ theme }) => theme.spacing(1)};
`;

export const COEHeaderTypography = styled(Typography)`
  font-weight: bold;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 12em;
`;

export const COENotationTypography = styled(Typography)`
  font-style: italic;
  letter-spacing: 0.02em;
`;

export const StyledButton = styled(Button)`
  white-space: nowrap;
  width: fit-content;
  height: fit-content;
  font-size: 9px;
`;

export const TLETextDisableInput = styled(TextField)((props) => ({
  '.MuiInputBase-input.Mui-disabled': {
    WebkitTextFillColor: props.theme.palette.text.primary,
    color: props.theme.palette.text.primary,
    fontSize: 'smaller',
    fontFeatureSettings: '"tnum"',
  },
}));

export const TabPanelWarningLabel = styled(Typography)`
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.palette.warning.main};
  padding: ${({ theme }) => theme.spacing(1)} 0;
`;
