import { createBrowserRouter, useRouteError } from 'react-router-dom';
import { App } from '../App';
import { ServerDown } from 'src/pages/Statuses';
import theme from '../Theme';
import { ThemeProvider } from '@mui/material/styles';
import { EULA } from 'src/pages/Shared/EULA';

const ErrorBoundary = () => {
  const error = useRouteError();
  console.error('Error caught by react router:');
  console.error(error);

  return (
    <ThemeProvider theme={theme}>
      <ServerDown />
    </ThemeProvider>
  );
};

export const router = createBrowserRouter([
  // match everything with "*"
  { path: '*', element: <App />, errorElement: <ErrorBoundary /> },
  { path: 'EULA', element: <EULA />, errorElement: <ErrorBoundary /> },
]);
