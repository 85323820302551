import { Mark } from '@mui/base';
import {
  ContentCopyRounded,
  DeleteRounded as DeleteIcon,
  ExpandMoreRounded as ExpandMoreIcon,
} from '@mui/icons-material';
import { Accordion, AccordionDetails, AccordionSummary, Button, Grid } from '@mui/material';
import { useCallback, useState } from 'react';
import {
  ALTITUDE_RANGE_KM_MAX,
  ALTITUDE_RANGE_KM_MIN,
  DEFAULT_GROUND_OBJECT_LOCATION,
  LATITUDE_RANGE_DEG_MAX,
  LATITUDE_RANGE_DEG_MIN,
  LONGITUDE_RANGE_DEG_MAX,
  LONGITUDE_RANGE_DEG_MIN,
  MIN_ELEVATION_RANGE_DEG_MAX,
  MIN_ELEVATION_RANGE_DEG_MIN,
  SENSOR_RANGE_KM_MAX,
  SENSOR_RANGE_KM_MIN,
} from 'src/constants';
import { ItemType } from 'src/enums';
import { useCurrentGroundObject, useUpdateGroundObject } from 'src/hooks/GroundObjectHooks';
import { useCurrentLaunchEvents } from 'src/hooks/LaunchHooks';
import { useDebouncedCallback } from 'src/hooks/useDebouncedCallback';
import {
  GroundObjectEditType,
  useGroundObjectLaunchEditStore,
} from 'src/threejs/components/GroundObjects/GroundObjectLaunchEditStore';
import { GroundObject, GroundObjectCategory } from 'src/types';
import { ModalDeleteObject } from './ModalDeleteObject';
import { InputNameEditor } from './NameInputEditor';
import { ObjectPropertySlider } from './ObjectPropertySlider';
import { ModalGroundObjectDuplicate } from '../ModalGroundObjectDuplicate';

export type GroundObjectEditableProperties = keyof Pick<
  GroundObject,
  'latitude' | 'longitude' | 'altitude' | 'minElevationAngle' | 'sensorRange'
>;
export const zeroValMark: Mark = { value: 0, label: '0' };

export const TabPanelGroundObjectProperties = () => {
  const currentGroundObject = useCurrentGroundObject();

  const [name, setName] = useState(currentGroundObject?.name);
  // check undefined since 0 is falsy
  const latState = useState(
    currentGroundObject?.latitude !== undefined
      ? currentGroundObject.latitude.toString()
      : DEFAULT_GROUND_OBJECT_LOCATION.latitude.toString(),
  );
  const lonState = useState(
    currentGroundObject?.longitude !== undefined
      ? currentGroundObject.longitude.toString()
      : DEFAULT_GROUND_OBJECT_LOCATION.longitude.toString(),
  );
  const altState = useState(
    currentGroundObject?.altitude !== undefined
      ? currentGroundObject.altitude.toString()
      : DEFAULT_GROUND_OBJECT_LOCATION.altitude.toString(),
  );
  const minElevationState = useState(
    currentGroundObject?.minElevationAngle !== undefined &&
      currentGroundObject?.minElevationAngle !== null
      ? currentGroundObject.minElevationAngle.toString()
      : DEFAULT_GROUND_OBJECT_LOCATION.minElevationAngle.toString(),
  );

  const sensorRangeState = useState(
    currentGroundObject?.sensorRange !== undefined && currentGroundObject?.sensorRange !== null
      ? currentGroundObject.sensorRange.toString()
      : DEFAULT_GROUND_OBJECT_LOCATION.sensorRange.toString(),
  );

  const updateGroundObject = useUpdateGroundObject();

  const [duplicateModalOpen, setDuplicateModal] = useState(false);
  const [deleteModalOpen, setDeleteModal] = useState(false);

  const useSaveCurrentGroundObject = useCallback(
    (update: Partial<GroundObject>) => {
      updateGroundObject.mutate(update);
    },
    [updateGroundObject],
  );

  const [saveCurrentGroundObject] = useDebouncedCallback(useSaveCurrentGroundObject, 500);

  const onChangeObjectProperty = useCallback(
    (item: string, objPropertyName: GroundObjectEditableProperties) => {
      if (!currentGroundObject?.id) return;

      const update: Partial<GroundObject> = {
        id: currentGroundObject.id,
        [objPropertyName]: parseFloat(item),
      };
      saveCurrentGroundObject(update);
    },
    [currentGroundObject?.id, saveCurrentGroundObject],
  );

  const updateGroundObjectState = useCallback(
    (val: number, objType: keyof GroundObjectEditType) => {
      if (!currentGroundObject?.id) return;

      const { groundObjectEdit, setGroundObjectEdit } = useGroundObjectLaunchEditStore.getState();
      if (!groundObjectEdit) return;

      const update: GroundObjectEditType = {
        ...groundObjectEdit,
        name: currentGroundObject.name, // only send the new name when api object is updated
        id: currentGroundObject.id,
        [objType]: val,
      };
      setGroundObjectEdit(update);
    },
    [currentGroundObject],
  );

  const groundObjectProperties = [
    {
      propertyName: 'Latitude',
      state: latState,
      units: 'deg',
      min: LATITUDE_RANGE_DEG_MIN,
      max: LATITUDE_RANGE_DEG_MAX,
      marks: [
        {
          value: LATITUDE_RANGE_DEG_MIN,
          label: `${LATITUDE_RANGE_DEG_MIN}`,
        },
        zeroValMark,
        {
          value: LATITUDE_RANGE_DEG_MAX,
          label: `${LATITUDE_RANGE_DEG_MAX}`,
        },
      ],
      objPropertyName: 'latitude' as GroundObjectEditableProperties,
    },
    {
      propertyName: 'Longitude',
      state: lonState,
      units: 'deg',
      min: LONGITUDE_RANGE_DEG_MIN,
      max: LONGITUDE_RANGE_DEG_MAX,
      marks: [
        {
          value: LONGITUDE_RANGE_DEG_MIN,
          label: `${LONGITUDE_RANGE_DEG_MIN}`,
        },
        zeroValMark,
        {
          value: LONGITUDE_RANGE_DEG_MAX,
          label: `${LONGITUDE_RANGE_DEG_MAX}`,
        },
      ],
      objPropertyName: 'longitude' as GroundObjectEditableProperties,
    },
    {
      propertyName: 'Altitude',
      state: altState,
      units: 'km',
      min: ALTITUDE_RANGE_KM_MIN,
      max: ALTITUDE_RANGE_KM_MAX,
      marks: [
        {
          value: ALTITUDE_RANGE_KM_MIN,
          label: `${ALTITUDE_RANGE_KM_MIN}`,
        },
        {
          value: ALTITUDE_RANGE_KM_MAX,
          label: `${ALTITUDE_RANGE_KM_MAX}`,
        },
      ],
      objPropertyName: 'altitude' as GroundObjectEditableProperties,
    },
  ];

  if (
    currentGroundObject?.category === GroundObjectCategory.SENSOR ||
    currentGroundObject?.category === GroundObjectCategory.OTHER
  ) {
    groundObjectProperties.push(
      {
        propertyName: 'Min Elevation',
        state: minElevationState,
        units: 'deg',
        min: MIN_ELEVATION_RANGE_DEG_MIN,
        max: MIN_ELEVATION_RANGE_DEG_MAX,
        marks: [
          {
            value: MIN_ELEVATION_RANGE_DEG_MIN,
            label: `${MIN_ELEVATION_RANGE_DEG_MIN}`,
          },
          {
            value: MIN_ELEVATION_RANGE_DEG_MAX,
            label: `${MIN_ELEVATION_RANGE_DEG_MAX}`,
          },
        ],
        objPropertyName: 'minElevationAngle' as GroundObjectEditableProperties,
      },
      {
        propertyName: 'Sensor Range',
        state: sensorRangeState,
        units: 'km',
        min: SENSOR_RANGE_KM_MIN,
        max: SENSOR_RANGE_KM_MAX,
        marks: [
          {
            value: SENSOR_RANGE_KM_MIN,
            label: `${SENSOR_RANGE_KM_MIN}`,
          },
          {
            value: SENSOR_RANGE_KM_MAX,
            label: `${SENSOR_RANGE_KM_MAX}`,
          },
        ],
        objPropertyName: 'sensorRange' as GroundObjectEditableProperties,
      },
    );
  }

  const currentLaunchEvents = useCurrentLaunchEvents();
  const isLaunchEventGroundObject = currentLaunchEvents?.find(
    (event) => event.launchSiteId === currentGroundObject?.id,
  );
  let categoryName = 'radar';
  let labelName = 'Ground Object Name';
  if (currentGroundObject?.category === GroundObjectCategory.LAUNCH_PAD) {
    categoryName = 'launch site';
  } else if (currentGroundObject?.category === GroundObjectCategory.LOCATION) {
    categoryName = 'location';
    labelName = 'Location Name';
  }

  return (
    <Grid
      container
      direction="column"
    >
      <Accordion defaultExpanded>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          style={{
            flexDirection: 'row-reverse',
          }}
          aria-controls="inspector-panel-details"
          id="inspector-panel-details"
        >
          Details
        </AccordionSummary>
        <AccordionDetails>
          <Grid
            sx={{
              pb: 2,
            }}
          >
            <InputNameEditor
              name={name ?? null}
              setName={setName}
              label={labelName}
              placeholder={labelName}
              saveName={() =>
                updateGroundObject.mutateAsync({
                  name,
                  id: currentGroundObject?.id,
                })
              }
            />
          </Grid>
          {!isLaunchEventGroundObject && (
            <>
              {groundObjectProperties.map(
                ({ propertyName, state, units, min, max, marks, objPropertyName }, idx) => {
                  const [stateVal, setState] = state;
                  return (
                    <ObjectPropertySlider
                      value={stateVal}
                      key={`${propertyName}`}
                      propertyName={propertyName}
                      textErrState={(fieldNum) => fieldNum < min || fieldNum > max}
                      textFieldUnits={units}
                      textFieldProps={{
                        helperText: `Number ranging from ${min} to ${max}`,
                      }}
                      sliderProps={{
                        min: min,
                        max: max,
                        marks: marks,
                      }}
                      onChange={(item: string) => {
                        setState(item);
                        updateGroundObjectState(parseFloat(item), objPropertyName);
                        onChangeObjectProperty(item, objPropertyName);
                      }}
                    />
                  );
                },
              )}
            </>
          )}
        </AccordionDetails>
      </Accordion>

      <Accordion defaultExpanded>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          style={{
            flexDirection: 'row-reverse',
          }}
          aria-controls="inspector-panel-details"
          id="inspector-panel-details"
        >
          Actions
        </AccordionSummary>
        <AccordionDetails>
          <Grid
            container
            direction="column"
            alignItems="center"
          >
            <Button
              variant="contained"
              onClick={() => setDuplicateModal(true)}
              aria-label={'Duplicate ' + categoryName}
              startIcon={<ContentCopyRounded />}
              sx={{
                m: 2,
              }}
            >
              Duplicate {categoryName}
            </Button>
            <Button
              color="error"
              variant="contained"
              onClick={() => setDeleteModal(true)}
              aria-label={'Delete ' + categoryName}
              startIcon={<DeleteIcon />}
              sx={{
                m: 2,
              }}
            >
              Delete {categoryName}
            </Button>
          </Grid>
        </AccordionDetails>
      </Accordion>

      {duplicateModalOpen && (
        <ModalGroundObjectDuplicate
          groundObject={currentGroundObject!}
          show={duplicateModalOpen}
          onClose={() => setDuplicateModal(false)}
        />
      )}
      {deleteModalOpen && (
        <ModalDeleteObject
          itemType={ItemType.GroundObject}
          open={deleteModalOpen}
          show={setDeleteModal}
          item={currentGroundObject}
        />
      )}
    </Grid>
  );
};
