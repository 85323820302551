import { CreateRounded as CreateIcon } from '@mui/icons-material';
import { FormControl, IconButton, InputAdornment, TextField } from '@mui/material';
import { useRef } from 'react';
import { onChangeObjectName } from 'src/hooks/ModalObjectOps';

export const InputNameEditor = ({
  label,
  placeholder,
  saveName,
  name,
  setName,
}: {
  label: string;
  placeholder: string;
  saveName: () => void;
  name: string | null;
  setName: (name: string) => void;
}) => {
  const nameRef = useRef<HTMLInputElement>(null);

  if (typeof name !== 'string') {
    return null;
  }

  return (
    <FormControl fullWidth>
      <TextField
        type="text"
        label={label}
        fullWidth
        placeholder={placeholder}
        value={name}
        onChange={(event) => {
          onChangeObjectName(event, setName);
        }}
        onKeyPress={(e) => {
          if (e.key === 'Enter') {
            e.preventDefault();
            nameRef.current?.blur();
          }
        }}
        error={!name}
        helperText={!name && `${placeholder} is required`}
        inputProps={{
          'aria-label': placeholder,
          onBlur: () => saveName(),
        }}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton size="large">
                <CreateIcon style={{ color: 'white' }} />
              </IconButton>
            </InputAdornment>
          ),
        }}
        inputRef={nameRef}
      />
    </FormControl>
  );
};
