import { MeshProps, useFrame } from '@react-three/fiber';
import { Dispatch, SetStateAction } from 'react';
import { useIsPropagating, useOrbit } from 'src/hooks/OrbitHooks';
import useCamera from 'src/threejs/hooks/useCamera';
import { Mesh, Vector3 } from 'three';
import { use3DOrbitContext } from '../Orbit/context';

type MarkerProps = {
  marker: Mesh | null | undefined;
  setMarker: Dispatch<SetStateAction<Mesh | null>> | undefined;
  scaleFactor?: number;
  markerName: string;
} & MeshProps;

/** A generic marker for all orbit markers to use. */
function Marker({
  marker,
  markerName,
  setMarker,
  visible,
  children,
  scaleFactor = 1,
  ...rest
}: MarkerProps) {
  const { id, name, selected } = use3DOrbitContext();

  const orbit = useOrbit(id);
  const orbitVisible = !!orbit?.additionalProperties?.visOrbit;

  const isPropagating = useIsPropagating();
  const camera = useCamera();

  useFrame(() => {
    if (marker) {
      const cameraPosition = new Vector3();
      const markerPosition = new Vector3();
      camera.getWorldPosition(cameraPosition);
      marker.getWorldPosition(markerPosition);
      const distance = cameraPosition.distanceTo(markerPosition);

      // this sets the size of the coe position markers that sit on the orbit (/200 makes it small)
      const targetScale = distance / 200;
      const scale = Math.min(targetScale, 0.03) * scaleFactor;

      marker.scale.set(scale, scale, scale);
    }
  });

  // Only apply that logic if visible is not explicitely defined
  const meshVisible = visible ?? (selected && orbitVisible && !isPropagating);

  return (
    <mesh
      {...rest}
      ref={setMarker}
      name={`${name} ${markerName} Mesh`}
      visible={meshVisible}
    >
      {children}
    </mesh>
  );
}

export default Marker;
