import { BackspaceRounded } from '@mui/icons-material';
import { Button, Dialog, DialogActions, DialogContent } from '@mui/material';
import { SpaceGlassContainerBase } from 'src/components/SpaceGlassControl';
import { useClearPropagations } from 'src/core/hooks';
import { ModalHeader } from 'src/pages/Shared/ModalSpaceGlass';

type ModalProps = {
  show: boolean;
  onClose: VoidFunction;
};

export function ModalClearPropagation(props: ModalProps) {
  const clearPropagations = useClearPropagations();

  const onCloseModal = () => {
    props.onClose();
  };

  const onSubmit = () => {
    clearPropagations();

    onCloseModal();
  };

  return (
    <>
      <Dialog
        open={props.show}
        onClose={onCloseModal}
        fullWidth={true}
        aria-labelledby="max-width-dialog-title"
        maxWidth="md"
        PaperComponent={SpaceGlassContainerBase}
      >
        <ModalHeader
          title={'Clear Propagation?'}
          onClose={onCloseModal}
          icon={<BackspaceRounded />}
        />

        <DialogContent max-width="fit-content">
          In order to modify parameters, please clear the propagation.
        </DialogContent>

        <DialogActions>
          <Button onClick={onCloseModal}>CANCEL</Button>

          <Button
            onClick={onSubmit}
            startIcon={<BackspaceRounded />}
            variant="contained"
          >
            CLEAR PROPAGATION
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
