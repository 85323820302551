import { Accordion, AccordionSummary } from '@mui/material';
import { ExpandMoreRounded as ExpandMoreIcon } from '@mui/icons-material';
import { GroundObjectVisibilityToggle } from './GroundObjectVisibilityToggle';

export const GroundObjectVisibilities = () => {
  return (
    <Accordion defaultExpanded>
      <AccordionSummary
        style={{
          flexDirection: 'row-reverse',
        }}
        expandIcon={<ExpandMoreIcon />}
        aria-controls="inspector-panel-visual"
        id="inspector-panel-visual"
      >
        Ground Object Cone
      </AccordionSummary>
      <GroundObjectVisibilityToggle
        visType="visGroundObjConeShader"
        optionLabel="Cone Shader"
      />
      <GroundObjectVisibilityToggle
        visType="visGroundObjConeWireframe"
        optionLabel="Cone Wireframe"
      />
    </Accordion>
  );
};
