import { Curve, Vector3 } from 'three';

// inheret from Three.js Curve class
export class PolyPath extends Curve<Vector3> {
  public points: Vector3[];

  constructor(points: Vector3[]) {
    super();
    this.points = points;
  }

  getPoint(t: number) {
    // t = [0,1]
    const points = this.points;
    const idxT = (points.length - 1) * t;
    const idx1 = Math.floor(idxT);
    const idx2 = idx1 < points.length - 1 ? idx1 + 1 : idx1;

    const weight = idxT - idx1; // lerp weight based on t
    return new Vector3().copy(points[idx1]).lerp(points[idx2], weight);
  }

  updatePoints(points: Vector3[]) {
    this.points = points;
  }
}
