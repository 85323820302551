import { DeleteRounded } from '@mui/icons-material';
import { Button, Tooltip } from '@mui/material';
import { useCallback, useEffect, useState } from 'react';
import { ItemType } from 'src/enums';
import { useCurrentGroundObject } from 'src/hooks/GroundObjectHooks';
import { useCurrentGroup } from 'src/hooks/GroupHooks';
import { useCurrentOrbit, useCurrentOrbits } from 'src/hooks/OrbitHooks';
import { GroundObject, GroupObject, OrbitObject } from 'src/types';
import { isOrbitObject } from '../../../../utilities/TypeUtils';
import { ModalDeleteObject } from '../ModalDeleteObject';

interface DeleteObjectButtonProps {
  disabled?: boolean;
}
export const DeleteObjectButton = ({ disabled = false }: DeleteObjectButtonProps) => {
  const currentOrbit = useCurrentOrbit();
  const currentOrbits = useCurrentOrbits();
  const currentGroundObject = useCurrentGroundObject();
  const currentGroup = useCurrentGroup();

  const [item, setItem] = useState<OrbitObject | GroupObject | GroundObject | undefined>();
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);

  const [itemType, setItemType] = useState<ItemType>();

  useEffect(() => {
    if (currentOrbit) {
      setItem(currentOrbit);
      setItemType(ItemType.Orbit);
    } else if (currentGroundObject) {
      setItem(currentGroundObject);
      setItemType(ItemType.GroundObject);
    } else if (currentGroup) {
      setItem(currentGroup);
      setItemType(ItemType.Group);
    } else {
      setItem(undefined);
      setItemType(undefined);
    }
  }, [currentOrbit, currentGroundObject, currentGroup]);

  const onClickDelete = useCallback(() => {
    setDeleteModalOpen(true);
  }, []);

  const isDisabled =
    (currentOrbit && isOrbitObject(currentOrbit) && currentOrbits && currentOrbits.length <= 1) ||
    itemType === undefined ||
    disabled;

  return (
    <>
      {!isDisabled && (
        <ModalDeleteObject
          itemType={itemType}
          open={deleteModalOpen}
          show={setDeleteModalOpen}
          item={item}
        />
      )}

      <Tooltip
        title="Delete Object"
        placement="top"
        disableInteractive
      >
        <span>
          <Button
            color="primary"
            variant="rounded"
            onClick={onClickDelete}
            disabled={isDisabled}
          >
            <DeleteRounded fontSize="small" />
          </Button>
        </span>
      </Tooltip>
    </>
  );
};
