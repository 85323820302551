import { CircularProgress, styled, Typography } from '@mui/material';
import { common } from '@mui/material/colors';

const LoaderWrapper = styled('div')({
  width: '100%',
  height: '100%',
  position: 'absolute',
  top: 0,
  left: 0,
  background: '#000000',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  zIindex: 1000,
  flexDirection: 'column',
});

export const Loader = () => {
  return (
    <LoaderWrapper>
      <Typography>Building Scene...</Typography>
      <CircularProgress
        size={48}
        thickness={4}
        sx={{
          m: 1,
          color: common.white,
        }}
      />
    </LoaderWrapper>
  );
};
