/* eslint-disable */
// @ts-nocheck
// Forked from source code of d3-geo v3.1.0
// -- https://github.com/d3/d3-geo/blob/main/src/graticule.js
// - all var's changed to const's to avoid sonar complaints
// - all commas in-line converted to ;
// - {} wrapped multiline if blocks

import {range} from "d3-array";
/* BEGIN FORK CHANGE: use js Math functions since these aren't exported
import {abs, ceil, epsilon} from "./math.js";
*/
const abs = Math.abs;
const ceil = Math.ceil;
const epsilon = 1e-6;
/* END FORK CHANGE */

function graticuleX(y0, y1, dy) {
  const y = range(y0, y1 - epsilon, dy).concat(y1);
  return function(x) { return y.map(function(y) { return [x, y]; }); };
}

function graticuleY(x0, x1, dx) {
  const x = range(x0, x1 - epsilon, dx).concat(x1);
  return function(y) { return x.map(function(x) { return [x, y]; }); };
}

export default function graticule() {
  let x1, x0, X1, X0,
      y1, y0, Y1, Y0,
      dx = 10, dy = dx, DX = 90, DY = 360,
      x, y, X, Y,
      precision = 2.5;

  function graticule() {
    return {type: "MultiLineString", coordinates: lines()};
  }

  function lines() {
    return range(ceil(X0 / DX) * DX, X1, DX).map(X)
        .concat(range(ceil(Y0 / DY) * DY, Y1, DY).map(Y))
        .concat(range(ceil(x0 / dx) * dx, x1, dx).filter(function(x) { return abs(x % DX) > epsilon; }).map(x))
        .concat(range(ceil(y0 / dy) * dy, y1, dy).filter(function(y) { return abs(y % DY) > epsilon; }).map(y));
  }

  graticule.lines = function() {
    return lines().map(function(coordinates) { return {type: "LineString", coordinates: coordinates}; });
  };

  graticule.outline = function() {
    return {
      type: "Polygon",
      coordinates: [
        X(X0).concat(
        Y(Y1).slice(1),
        X(X1).reverse().slice(1),
        Y(Y0).reverse().slice(1))
      ]
    };
  };

  graticule.extent = function(_) {
    if (!arguments.length) return graticule.extentMinor();
    return graticule.extentMajor(_).extentMinor(_);
  };

  graticule.extentMajor = function(_) {
    if (!arguments.length) return [[X0, Y0], [X1, Y1]];
    X0 = +_[0][0]; X1 = +_[1][0];
    Y0 = +_[0][1]; Y1 = +_[1][1];
    if (X0 > X1) {_ = X0; X0 = X1; X1 = _;}
    if (Y0 > Y1) {_ = Y0; Y0 = Y1; Y1 = _;}
    return graticule.precision(precision);
  };

  graticule.extentMinor = function(_) {
    if (!arguments.length) return [[x0, y0], [x1, y1]];
    x0 = +_[0][0]; x1 = +_[1][0];
    y0 = +_[0][1]; y1 = +_[1][1];
    if (x0 > x1) {_ = x0; x0 = x1; x1 = _;}
    if (y0 > y1) {_ = y0; y0 = y1; y1 = _;}
    return graticule.precision(precision);
  };

  graticule.step = function(_) {
    if (!arguments.length) return graticule.stepMinor();
    return graticule.stepMajor(_).stepMinor(_);
  };

  graticule.stepMajor = function(_) {
    if (!arguments.length) return [DX, DY];
    DX = +_[0]; DY = +_[1];
    return graticule;
  };

  graticule.stepMinor = function(_) {
    if (!arguments.length) return [dx, dy];
    dx = +_[0]; dy = +_[1];
    return graticule;
  };

  graticule.precision = function(_) {
    if (!arguments.length) return precision;
    precision = +_;
    x = graticuleX(y0, y1, 90);
    y = graticuleY(x0, x1, precision);
    X = graticuleX(Y0, Y1, 90);
    Y = graticuleY(X0, X1, precision);

/* BEGIN FORK CHANGE: redefine to use the precision */
    x = graticuleX(y0, y1, precision);
    X = graticuleX(Y0, Y1, precision);
/* END FORK CHANGE */

    return graticule;
  };

  return graticule
      .extentMajor([[-180, -90 + epsilon], [180, 90 - epsilon]])
      .extentMinor([[-180, -80 - epsilon], [180, 80 + epsilon]]);
}

export function graticule10() {
  return graticule()();
}