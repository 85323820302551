import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { DEFAULT_ADDITIONAL_PROPERTIES_BOOKMARK } from 'src/constants';
import { getActivePageId, useRouteStore } from 'src/pages/App/routes/store';
import { fetchApi } from 'src/services/api';
import { Bookmark, BookmarkAdditionalProperties } from 'src/types';
import { useApiSnackbarError } from './SnackbarHooks';

const deserializeBookmark = (bookmark: Bookmark): Bookmark => {
  return {
    ...bookmark,
    timestamp: new Date(bookmark.timestamp),
    additionalProperties: {
      ...DEFAULT_ADDITIONAL_PROPERTIES_BOOKMARK,
      ...bookmark.additionalProperties,
    },
  };
};

function useBookmarksQuery(pageId?: number) {
  const apiSnackbarError = useApiSnackbarError();

  return useQuery<Bookmark[]>(
    ['bookmarksForPage', pageId],
    async () => {
      const bookmarks = await fetchApi(`/v2/bookmarks?pageId=${pageId}`);

      return bookmarks.map((bookmark: any) => deserializeBookmark(bookmark));
    },
    {
      enabled: Boolean(pageId),
      onError: () => {
        apiSnackbarError('Failed to get bookmark(s).');
      },
    },
  );
}

export function useBookmarks(pageId?: number) {
  const { data } = useBookmarksQuery(pageId);
  return data;
}

export function useCurrentBookmark() {
  const pageId = useRouteStore(getActivePageId);
  return useBookmarks(pageId);
}

export function useBookmark(id?: number) {
  const bookmarks = useCurrentBookmark();
  return bookmarks?.find((bookmark) => bookmark.id === Number(id));
}

export function useCreateBookmark() {
  const pageId = useRouteStore(getActivePageId);

  const apiSnackbarError = useApiSnackbarError();

  const queryClient = useQueryClient();
  return useMutation(
    ({ orbitId, timestamp, callout, avatarImageUrl }: Partial<Bookmark>) => {
      return fetchApi('/v2/bookmarks', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          timestamp,
          callout,
          avatarImageUrl,
          orbitId,
        }),
      });
    },
    {
      onError: () => {
        apiSnackbarError('Failed to create bookmark.');
      },
      onSuccess: async () => {
        await queryClient.invalidateQueries(['bookmarksForPage', Number(pageId)]);
      },
    },
  );
}

export function useUpdateBookmark() {
  const pageId = useRouteStore(getActivePageId);

  const apiSnackbarError = useApiSnackbarError();

  const queryClient = useQueryClient();
  return useMutation(
    ({
      id,
      orbitId,
      timestamp,
      callout,
      avatarImageUrl,
      additionalProperties,
    }: Partial<Bookmark>) => {
      return fetchApi(`/v2/bookmarks/${id}`, {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          orbitId,
          timestamp,
          callout,
          avatarImageUrl,
          additionalProperties,
        }),
      });
    },
    {
      onError: () => {
        apiSnackbarError('Failed to update bookmark.');
      },
      onSuccess: async () => {
        await queryClient.invalidateQueries(['bookmarksForPage', Number(pageId)]);
      },
    },
  );
}

export function useDeleteBookmark() {
  const pageId = useRouteStore(getActivePageId);

  const apiSnackbarError = useApiSnackbarError();

  const queryClient = useQueryClient();
  return useMutation(
    ({ id }: Bookmark) => {
      return fetchApi(`/v2/bookmarks/${id}`, {
        method: 'DELETE',
        headers: { 'Content-Type': 'application/json' },
      });
    },
    {
      onError: () => {
        apiSnackbarError('Failed to delete bookmark.');
      },
      onSuccess: async () => {
        await queryClient.invalidateQueries(['bookmarksForPage', Number(pageId)]);
      },
    },
  );
}

export function useUpdateBookmarkAdditionalProperties() {
  const updateBookmark = useUpdateBookmark();

  return (bookmark: Bookmark, changes: Partial<BookmarkAdditionalProperties>) => {
    if (bookmark) {
      const update: Partial<Bookmark> = {
        ...bookmark,
        additionalProperties: {
          ...DEFAULT_ADDITIONAL_PROPERTIES_BOOKMARK,
          ...bookmark.additionalProperties,
          ...changes,
        },
      };
      updateBookmark.mutateAsync(update);
    }
  };
}
