import { ORBIT_TYPES, PROPAGATION_STEPS_PER_REVOLUTION } from 'src/constants';
import { WebSocketMessage } from 'src/enums';
import WebSocketManager from 'src/services/WebSocketManager';
import use3DOrbitStore from 'src/threejs/components/OrbitManager/store/store';
import { COEData } from 'src/threejs/models/COE';
import { Maneuver, OrbitObject, OrbitTLE, Page, Perturbations, StateVectorData } from 'src/types';
import binarySearchForClosestValue from 'src/utilities/binarySearchForClosestValue';
import { Vector3 } from 'three';
import { inverseLerp } from 'three/src/math/MathUtils';
import PubSub from './PubSub';

export const BUFFER_SIZE = 3000;

type OrbitId = number;

type OrbitCache = {
  /** The current number of state vectors in the cache for an orbit. */
  length: number;
  /** The state vectors. */
  stateVectors: StateVectorData[];
  /** The total number of steps in the total propagation. */
  totalSteps: number;
  /** The number of state vectors that need to be in the cache before an orbit is ready to play. */
  readyAmount: number;
  /** A flag indicating if there are enough state vectors to play the orbit smoothly. */
  canPlay: boolean;
  /** The latest timestamp at which there is data in the cache to support playback at that time. */
  maxAvailableTime: number;
  /** The name of the orbit. */
  name: string;
};

type OrbitCacheRecord = Record<OrbitId, OrbitCache>;

type Cache = {
  /** The total number of state vectors in the cache accross all orbits */
  length: number;
  /** The orbit state vector cache for a page. */
  orbits: OrbitCacheRecord;
  /** A flag indicating if we have hit the max memory allowed. */
  maxMemoryReached: boolean;
  /** A flag indicating if we can play the currently propagated orbits. */
  canPlayPropagatedOrbits: boolean;
};

export type OrbitIdentifier = {
  id: number;
  name: string;
};

export const PropagatorErrorMessageType = {
  ATMOSPHERIC_REENTRY: 'ATMOSPHERIC_REENTRY',
};

type PropagatedCacheManagerEventType =
  | 'canplay'
  | 'canplaythrough'
  | 'seeked'
  | 'recieveddata'
  | 'progress'
  | 'memorylimitreached'
  | 'startpropagations'
  | 'clearpropagations'
  | 'orbitschange';

export type CanPlayThroughEventPlayload = {
  orbitId: number;
  name: string;
};

export type HandleProgressEventPlayload = {
  orbitId: number;
  data: StateVectorData;
};

export type SeekedEventPlayload = {
  orbitId: number;
  stateVectors: StateVectorData[];
  start: number;
  end: number;
};

export type DataRecievedEventPlayload = {
  orbitId: number;
  totalSteps: number;
};

type PropagationOrbit = {
  id: number;
  perturbations: Perturbations;
  startTimestamp: Date;
  endTimestamp: Date;
  orbitType: string;
  orbit?: COEData;
  orbitTLE?: OrbitTLE;
  maneuvers?: Maneuver[];
};

type PropagationOrbits = {
  stepSize: number;
  orbits: Array<PropagationOrbit>;
};

// Not standard in every browser: https://developer.mozilla.org/en-US/docs/Web/API/Performance/memory
const jsHeapSizeLimit = window.performance.memory?.jsHeapSizeLimit;
const totalJSHeapSize = window.performance.memory?.totalJSHeapSize;

/** The model for managing propagated orbit caches on a page basis. Do not import. Import the global instance via `default`. */
export class PropagatedCacheManagerModel {
  private cache: Cache = {
    length: 0,
    orbits: {},
    maxMemoryReached: false,
    canPlayPropagatedOrbits: false,
  };

  private pubSub = new PubSub();

  // based on orbit duration? longer orbits have larger seek size?
  private seekSize: number = BUFFER_SIZE;

  // A rough estimate of the size (in bytes) of a state vector via chrome memory profiler
  private roughSizeOfStateVector = 244;

  // max memory consumption we will maintain in the cache
  private maxMemorySize =
    jsHeapSizeLimit && totalJSHeapSize ? jsHeapSizeLimit - totalJSHeapSize / 2 : 1e9;

  private maxNumberOfStateVectors = this.maxMemorySize / this.roughSizeOfStateVector;

  /** The id's of the orbits that are currently being propagated.s */
  private propagatingOrbits: OrbitIdentifier[] = [];

  constructor() {
    this.pubSub.subscribe('progress', this.handleProgress);
  }

  addEventListener = (
    type: PropagatedCacheManagerEventType,
    listener: (...args: any[]) => void,
  ) => {
    this.pubSub.subscribe(type, listener);
  };

  /** Adds a state vector to the cache for a specific orbit within a page. */
  addStateVectorToCache = (data: StateVectorData) => {
    const orbitId = Number(data.id);

    if (this.cache.length >= this.maxNumberOfStateVectors) {
      if (!this.cache.maxMemoryReached) {
        this.cache.maxMemoryReached = true;
        this.pubSub.publish('memorylimitreached', undefined);
      }
    } else {
      if (!this.cache.orbits[orbitId]) {
        this.createInitialOrbitCache(orbitId, data);
      }

      if (data.errorMessage === PropagatorErrorMessageType.ATMOSPHERIC_REENTRY) {
        this.cache.orbits[orbitId].totalSteps = this.cache.orbits[orbitId].stateVectors.length - 1;
        this.cache.orbits[orbitId].readyAmount = this.cache.orbits[orbitId].length;
      } else {
        this.cache.length++;
        this.pushStateVectorSorted(orbitId, data);
      }

      const numberOfStateVectors = this.cache.orbits[orbitId].stateVectors.length;

      const lastStateVector = this.cache.orbits[orbitId].stateVectors[numberOfStateVectors - 1];

      this.cache.orbits[orbitId].length = this.cache.orbits[orbitId].stateVectors.length;
      this.cache.orbits[orbitId].maxAvailableTime = lastStateVector.stateVectors[0].epoch;

      const orbitCache = this.cache.orbits[orbitId];
      const length = orbitCache.length;
      const readyAmount = orbitCache.readyAmount;
      const enoughStateVectors = length >= readyAmount;
      const canPlay = orbitCache.canPlay;

      if (enoughStateVectors && !canPlay) {
        this.cache.orbits[orbitId].canPlay = true;
      }

      this.pubSub.publish('progress', { orbitId, data });
    }

    const isFirstStateVector = this.cache.orbits[orbitId].length === 1;

    if (isFirstStateVector) {
      const totalSteps = this.cache.orbits[orbitId].stateVectors[0].stateVectors[0].totalSteps;
      this.pubSub.publish('recieveddata', { orbitId, totalSteps });
    }
  };

  /** Checks if we have at least 1 state vector for a given orbit. */
  checkIfOrbitAlreadyPropagated = (orbitId: number): boolean => {
    const orbitCache = this.cache.orbits[orbitId];

    if (orbitCache) {
      return orbitCache.length > 0;
    }

    return false;
  };

  /** Checks if we have at least 1 state vector for all currently propagating orbits */
  checkIfAllCurrentOrbitsPropagated = (): boolean => {
    const propagatingOrbits = this.findPropagatingOrbitsInCache();

    if (!propagatingOrbits.length) return false;

    return propagatingOrbits.every(({ length }) => length > 0);
  };

  /** Checks if all of the currently propagating orbits have enough state vectors to start playing. */
  checkIfCanPlayAllOrbits = (): boolean => {
    const orbitsCache = this.findPropagatingOrbitsInCache();

    if (!orbitsCache.length) return false;

    const canPlayPropagatedOrbits = orbitsCache.every((orbitCache) => {
      return orbitCache.canPlay === true;
    });

    return canPlayPropagatedOrbits;
  };

  /** Checks if we have recieved all of the state vectors for every orbit for a given page. */
  checkIfCanPlayThrough = (orbitId: number) => {
    const orbitsCache = this.cache.orbits[orbitId];

    return orbitsCache?.canPlay ?? false;
  };

  /** Removes propagation data for currently propagating orbits. */
  clearCurrentlyPropagatingOrbits = () => {
    this.propagatingOrbits.forEach(({ id }) => {
      const cache = this.getCacheForOrbit(id);

      if (cache) {
        this.deleteOrbitCache(id);
        this.cache.length -= cache.length;
      }
    });
    this.pubSub.publish('clearpropagations', [...this.propagatingOrbits]);
    this.cache.canPlayPropagatedOrbits = false;
  };

  /** Creates the initial entry in the cache for an orbit within a page. */
  private createInitialOrbitCache = (orbitId: number, data: StateVectorData) => {
    const totalSteps = data.stateVectors[0].totalSteps;
    const orbit = this.propagatingOrbits.find(({ id }) => id === orbitId);

    this.cache.orbits[orbitId] = {
      length: 0,
      stateVectors: [],
      totalSteps,
      readyAmount: totalSteps < BUFFER_SIZE ? totalSteps : BUFFER_SIZE,
      canPlay: false,
      maxAvailableTime: data.stateVectors[0].epoch,
      name: orbit?.name ?? `Orbit with id: ${orbitId}`,
    };

    // this.cache.pages[pageId].totalSteps += totalSteps;
  };

  /** Deletes the entire cache for a specific orbit within a page.  */
  deleteOrbitCache = (orbitId: number) => {
    delete this.cache.orbits[orbitId];
  };

  /** Should be called when unmounting a Notebook */
  deleteEntireCache = () => {
    this.cache = {
      length: 0,
      orbits: {},
      maxMemoryReached: false,
      canPlayPropagatedOrbits: false,
    };
  };

  /** Returns the entire cache for all pages and orbits. */
  getCache = () => {
    return this.cache;
  };

  getOrbitsCache = () => {
    return this.cache.orbits;
  };

  /** Returns the state vector cache for a given orbit. */
  getCacheForOrbit = (orbitId: number): OrbitCache | null => {
    return this.cache.orbits[orbitId] ?? null;
  };

  getMetadataForAllPropagatingOrbits = () => {
    const orbitCache = this.findPropagatingOrbitsInCache();

    return orbitCache.reduce(
      (result, orbitCache) => {
        return {
          length: result.length + orbitCache.length,
          totalSteps: result.totalSteps + orbitCache.totalSteps,
          readyAmount: result.readyAmount + orbitCache.readyAmount,
        };
      },
      { length: 0, totalSteps: 0, readyAmount: 0 },
    );
  };

  /** Returns the progress for the least propagated orbit. */
  getPropagationProgress = () => {
    const orbitCache = this.findPropagatingOrbitsInCache();

    if (orbitCache.length === 0) return 0;

    return (
      Math.min(
        ...orbitCache.map(({ length, totalSteps }) => {
          return length / totalSteps;
        }),
      ) * 100
    );
  };

  /** Returns the current seek size. */
  getSeekSize = () => {
    return this.seekSize;
  };

  /** Finds the state vector whose epoch most closesly matches a desired timestamp via binary search. */
  findClosestStateVectorForTime = (time: number) => {
    const orbitCaches = this.findPropagatingOrbitsInCache();

    let closestStateVector: StateVectorData | undefined;

    for (const element of orbitCaches) {
      const orbitCache = element;

      const {
        high: { item: stateVector },
      } = binarySearchForClosestValue(orbitCache.stateVectors, time, (stateVector) => {
        return stateVector.stateVectors[0].epoch * 1000;
      });

      if (!closestStateVector) {
        closestStateVector = stateVector;
      } else if (
        stateVector &&
        stateVector.stateVectors[0].epoch < closestStateVector.stateVectors[0].epoch
      ) {
        closestStateVector = stateVector;
      }
    }

    return closestStateVector;
  };

  /** Finds the state vector whose epoch most closesly matches a desired timestamp via binary search. */
  findClosestStateVectorForTimeByOrbit = (time: number, orbitId: number) => {
    const orbitCache = this.getCacheForOrbit(orbitId);

    if (orbitCache) {
      const {
        low: { item: stateVectorLow },
        high: { item: stateVectorHigh },
      } = binarySearchForClosestValue(orbitCache.stateVectors, time, (stateVector) => {
        return stateVector.stateVectors[0].epoch * 1000;
      });

      return stateVectorLow ?? stateVectorHigh;
    }

    return null;
  };

  /** Finds the state vectors whose epochs most closesly matches a desired timestamp via binary search
   *  and lerps the position and velocity vectors to the timestamp in between.
   */
  findLerpedStateVectorForTimeByOrbit = (time: number, orbitId: number) => {
    const orbitCache = this.getCacheForOrbit(orbitId);

    if (orbitCache) {
      const {
        low: { item: stateVectorLow },
        high: { item: stateVectorHigh },
      } = binarySearchForClosestValue(orbitCache.stateVectors, time, (sv) => {
        return sv.stateVectors[0].epoch * 1000;
      });

      if (!stateVectorLow) {
        return stateVectorHigh;
      }
      if (!stateVectorHigh) {
        return stateVectorLow;
      }

      // get the percentage the actual time is between 2 epochs
      const blendTime = inverseLerp(
        stateVectorLow.stateVectors[0].epoch,
        stateVectorHigh.stateVectors[0].epoch,
        time / 1000,
      );

      // clone the low into return value
      const stateVector = structuredClone(stateVectorLow);

      // lerp the positions based on the blendTime
      const position = new Vector3(
        stateVectorLow.stateVectors[0].x_position,
        stateVectorLow.stateVectors[0].y_position,
        stateVectorLow.stateVectors[0].z_position,
      ).lerp(
        new Vector3(
          stateVectorHigh.stateVectors[0].x_position,
          stateVectorHigh.stateVectors[0].y_position,
          stateVectorHigh.stateVectors[0].z_position,
        ),
        blendTime,
      );

      // lerp the velocities based on the blendTime
      const velocity = new Vector3(
        stateVectorLow.stateVectors[0].x_velocity,
        stateVectorLow.stateVectors[0].y_velocity,
        stateVectorLow.stateVectors[0].z_velocity,
      ).lerp(
        new Vector3(
          stateVectorHigh.stateVectors[0].x_velocity,
          stateVectorHigh.stateVectors[0].y_velocity,
          stateVectorHigh.stateVectors[0].z_velocity,
        ),
        blendTime,
      );

      stateVector.stateVectors[0] = {
        ...stateVector.stateVectors[0],
        x_position: position.x,
        y_position: position.y,
        z_position: position.z,
        x_velocity: velocity.x,
        y_velocity: velocity.y,
        z_velocity: velocity.z,
        speed: velocity.length() / 1000,
      };

      return stateVector;
    }

    return null;
  };

  /** Returns an array of all of the currently propagating orbits caches. */
  findPropagatingOrbitsInCache = () => {
    return Object.keys(this.cache.orbits)
      .filter((orbitId) => {
        return this.propagatingOrbits.find(({ id }) => id === Number(orbitId)) !== undefined;
      })
      .map((orbitId) => {
        return this.cache.orbits[Number(orbitId)];
      });
  };

  /** Handles the progress event every time a new state vector is recieved from the web socket. */
  private handleProgress = ({ orbitId }: HandleProgressEventPlayload) => {
    const orbitCache = this.cache.orbits[orbitId];
    const name = orbitCache.name;

    const totalSteps = orbitCache.totalSteps;
    const length = orbitCache.length;
    const readyAmount = orbitCache.readyAmount;

    const canPlay = this.checkIfCanPlayAllOrbits();

    const recievedNewVectors = (length - readyAmount) % this.seekSize === 0;

    const recievedLastVectors = length === totalSteps;

    const seeked = recievedNewVectors || recievedLastVectors;

    // Once the buffer has reached a certain length, we tell it's subscribers that the buffer is ready
    // this event should only be called once for a given page
    if (canPlay && !this.cache.canPlayPropagatedOrbits) {
      this.cache.canPlayPropagatedOrbits = canPlay;
      this.pubSub.publish('canplay', undefined);
    }

    // Every `seekSize` amount of data points  we publish a subscribe event
    if (seeked) {
      const seekSizeOfLastBatch = (length - readyAmount) % this.seekSize;
      const seekSize = recievedLastVectors ? -seekSizeOfLastBatch : -this.seekSize;

      this.pubSub.publish('seeked', {
        orbitId,
        stateVectors: orbitCache.stateVectors.slice(seekSize),
        start: length + seekSize, // seek size is negative
        end: length,
      });
    }

    // When we have all of the state vectors for all of the orbits, we
    // publish the canplaythrough event
    if (recievedLastVectors && this.checkIfCanPlayThrough(orbitId)) {
      this.pubSub.publish('canplaythrough', { orbitId, name });
    }
  };

  // Hack to do a sorted insert into the cache to ensure state vectors are in the correct order
  // We need to verify that the backend is sending state vectors in order
  private pushStateVectorSorted = (orbitId: number, data: StateVectorData) => {
    const length = this.cache.orbits[orbitId].stateVectors.length;
    const stateVectors = this.cache.orbits[orbitId].stateVectors;

    if (length === 0) {
      stateVectors.push(data);
    } else {
      const lastStateVectorStep = stateVectors[length - 1].stateVectors[0].step;
      const newStateVectorStep = data.stateVectors[0].step;

      const isNextStep = newStateVectorStep === lastStateVectorStep + 1;

      if (isNextStep) {
        stateVectors.push(data);
      } else {
        const { low, high } = binarySearchForClosestValue(
          stateVectors,
          data.stateVectors[0].step,
          (stateVector) => stateVector.stateVectors[0].step,
        );

        if (low.item) {
          stateVectors.splice(low.index + 1, 0, data);
        } else if (high.item) {
          stateVectors.splice(high.index, 0, data);
        } else {
          stateVectors.push(data);
        }
      }
    }
  };

  removeEventListener = (
    type: PropagatedCacheManagerEventType,
    listener: (...args: any[]) => void,
  ) => {
    this.pubSub.unsubscribe(type, listener);
  };

  /** Resets the `PropagatedCacheManager` back to it's initial state to free up memory. */
  reset = () => {
    this.deleteEntireCache();
  };

  hasCachedData = (orbitIds: number[]) => {
    return this.cache && orbitIds.some((id) => this.cache.orbits[id]);
  };

  getEqualStepSize = () => {
    const { orbits } = use3DOrbitStore.getState();
    const periods = this.propagatingOrbits.map((orbit) => orbits[orbit.id].orbitData.period);
    return Math.floor(Math.min(...periods) / PROPAGATION_STEPS_PER_REVOLUTION);
  };

  propagateTimeline = async (page: Page, orbits: OrbitObject[], equalStepSize = false) => {
    const stepSize = equalStepSize ? this.getEqualStepSize() : -1;

    const orbitStates = Object.values(use3DOrbitStore.getState().orbits);

    for (const element of this.propagatingOrbits) {
      const { id } = element;

      const orbit = orbits.find((orbitObject) => orbitObject.id === id);

      if (orbit) {
        let orbitStartTime = page.startTime;
        if (orbit.orbitType === ORBIT_TYPES.LAUNCH) {
          orbitStartTime = orbit.maneuvers[0].timestamp;
        }

        const orbitStateFound = orbitStates.find((orbitState) => orbitState.id === id);
        const perturbations = orbitStateFound ? orbitStateFound.perturbations : orbit.perturbations;

        const data: PropagationOrbits = {
          stepSize,
          orbits: [
            {
              id: orbit.id,
              perturbations: perturbations,
              startTimestamp: orbitStartTime,
              endTimestamp: page.endTime,
              orbitType: orbit.orbitType,
              maneuvers: orbit.maneuvers.map((maneuver) => {
                return {
                  ...maneuver,
                  radialComponent: maneuver.radialComponent * 1000,
                  inTrackComponent: maneuver.inTrackComponent * 1000,
                  crossTrackComponent: maneuver.crossTrackComponent * 1000,
                };
              }),
            },
          ],
        };

        switch (orbit.orbitType) {
          case ORBIT_TYPES.COE:
          case ORBIT_TYPES.LAUNCH:
          case ORBIT_TYPES.STATE_VECTORS:
            data.orbits[0].orbit = orbit.orbit[0];
            break;
          case ORBIT_TYPES.TLE:
            data.orbits[0].orbitTLE = orbit.orbitTLE;
            break;
          default:
            break;
        }

        await WebSocketManager.sendMessage(WebSocketMessage.PROPAGATE, data);
      }
    }

    this.pubSub.publish('startpropagations', [...this.propagatingOrbits]);
  };

  /** Manually sets the max number of state vectors allow in the cache. */
  setMaxNumberOfStateVectors = (maxNumberOfStateVectors: number) => {
    this.maxNumberOfStateVectors = maxNumberOfStateVectors;
  };

  /** Locally caches the orbits that are currently propagating. */
  setPropagatingOrbits = (orbits: OrbitIdentifier[]) => {
    const { orbits: orbitsStore } = use3DOrbitStore.getState();

    // filter out orbits that have unpropagatable path
    this.propagatingOrbits = orbits.filter(
      (orbit) => !orbitsStore[orbit.id].orbitData.hasConditionEscapeVelocity,
    );

    // Each time the orbits change we update whether or not we can play
    // the animation in case the orbits have already been propagated.
    this.cache.canPlayPropagatedOrbits = this.checkIfCanPlayAllOrbits();

    this.pubSub.publish('orbitschange', this.getPropagationProgress());
  };

  getPropagatingOrbits = () => {
    return [...this.propagatingOrbits];
  };
}

/** The Global Cache Management/Buffering System for Propagated Orbits in a Notebook. */
const PropagatedCacheManager = new PropagatedCacheManagerModel();

(window as any).PropagatedCacheManager = PropagatedCacheManager;

export default PropagatedCacheManager;
