import { useFrame, useThree } from '@react-three/fiber';
import { useMemo, useState } from 'react';
import {
  DEFAULT_ADDITIONAL_PROPERTIES_VIEWPORT,
  GRID_HELPER_SCALES,
  RIC_GRID_HELPER_LENGTHS,
  RIC_REF_GRID_1_KM_DIST,
  RIC_REF_GRID_DIVISORS,
  RIC_REF_GRID_SCALE,
} from 'src/constants';
import { OrbitRenderOrder } from 'src/enums';
import { Vector3 } from 'three';
import { AxesHelper } from '../AxesHelper';
import { useViewportContext } from '../Viewport/context';
import { useViewportSetRicRefGridSize } from '../ViewportManager/store';

interface IRICReferenceGridProps {
  isRicTarget: boolean;
}

export const RICReferenceGrid = ({ isRicTarget }: IRICReferenceGridProps) => {
  const gridColor: string = useMemo(() => '#fff', []);

  const { camera } = useThree();

  const [gridSize, setGridSize] = useState(GRID_HELPER_SCALES[RIC_REF_GRID_1_KM_DIST].sizeGrid);

  const gridScaleKeys = useMemo(() => Object.keys(GRID_HELPER_SCALES).map(Number), []);

  const setRicRefGridSize = useViewportSetRicRefGridSize();

  useFrame(() => {
    if (!isRicTarget) return;
    let distance = 0;
    const cameraWorldPosition = new Vector3();
    camera.getWorldPosition(cameraWorldPosition);
    distance = camera.position.distanceTo(new Vector3(0, 0, 0));

    // find the closest distanced grid scale to the current camera distance from RIC space object
    const entryKey: number = gridScaleKeys.reduce((prev, curr) =>
      Math.abs(curr - distance) < Math.abs(prev - distance) ? curr : prev,
    );
    setGridSize(GRID_HELPER_SCALES[entryKey].sizeGrid);
    setRicRefGridSize(GRID_HELPER_SCALES[entryKey].scaleValue);
  });

  const { viewport } = useViewportContext();
  const viewportAdditionalProperties =
    viewport?.additionalProperties || DEFAULT_ADDITIONAL_PROPERTIES_VIEWPORT;
  const isGridOn = viewportAdditionalProperties.visGrid;

  return (
    <group
      scale={RIC_REF_GRID_SCALE}
      visible={isRicTarget}
      rotation={[0, Math.PI, 0]}
    >
      <AxesHelper
        args={[RIC_GRID_HELPER_LENGTHS]}
        name="CoordinateRef"
        renderOrder={OrbitRenderOrder.ORBIT_AXES_HELPER}
      />
      {isGridOn && (
        <gridHelper
          args={[gridSize, RIC_REF_GRID_DIVISORS, gridColor, gridColor]}
          material-depthWrite={false}
          name="Grid"
          renderOrder={OrbitRenderOrder.ORBIT_GRID_HELPER}
        />
      )}
    </group>
  );
};
