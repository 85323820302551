import { useCallback, useMemo } from 'react';
import { EARTH_ORIGIN, VISUAL_AID_HEX } from 'src/constants';
import { Vector3 } from 'three';
import { ArrowVector } from '../../ArrowVector';
import { createGetOrbitRAAN } from '../../OrbitManager/store/getters';
import { use3DOrbitContext } from '../context';

interface AscendingNodeVectorProps {
  orbitId: number;
}

export const AscendingNodeVector = ({ orbitId }: AscendingNodeVectorProps) => {
  const getOrbitRaanPosition = useMemo(() => createGetOrbitRAAN(orbitId), [orbitId]);
  const getRaanDestination = useCallback(
    () => new Vector3(getOrbitRaanPosition().z, getOrbitRaanPosition().x, getOrbitRaanPosition().y),
    [getOrbitRaanPosition],
  );

  const { selected } = use3DOrbitContext();

  return (
    <ArrowVector
      labelShort="n"
      active={selected}
      staticOrigin={EARTH_ORIGIN}
      color={VISUAL_AID_HEX}
      label="Ascending Node Vector"
      getDestination={getRaanDestination}
    />
  );
};
