import gsap from 'gsap';
import { MeshLineGeometry, MeshLineMaterial } from 'meshline';
import { useEffect, useState } from 'react';
import {
  earthradius,
  ORBIT_COLOR_DEFAULT,
  PROPAGATION_STEPS_PER_REVOLUTION,
  scaleFactor,
} from 'src/constants';
import { useCurrentTime } from 'src/core/hooks';
import { useGroundObjectLaunchEditStore } from 'src/threejs/components/GroundObjects/GroundObjectLaunchEditStore';
import getEarthRotation from 'src/threejs/math/getEarthRotation';
import keplerianOrbitMath from 'src/threejs/math/keplerianOrbit';
import { CubicBezierCurve3, Mesh, Vector3 } from 'three';
import { degToRad, inverseLerp, lerp } from 'three/src/math/MathUtils';

export const LaunchPreviewOrbit = () => {
  const [meshRef, setMeshRef] = useState<Mesh | null>(null);

  const currentTime = useCurrentTime();
  const earthRotation = getEarthRotation(currentTime);

  const orbitalSMA =
    useGroundObjectLaunchEditStore((state) => state.groundObjectEdit?.orbitLaunchAltitude || 0) +
    earthradius;
  const orbitalEccentricity = useGroundObjectLaunchEditStore(
    (state) => state.groundObjectEdit?.eccentricity || 0,
  );
  const orbitalInclination = useGroundObjectLaunchEditStore(
    (state) => state.groundObjectEdit?.inclination || 0,
  );
  const orbitalRAAN = useGroundObjectLaunchEditStore(
    (state) => state.groundObjectEdit?.rightAscensionOfAscendingNode || 0,
  );

  const groundLatitude = useGroundObjectLaunchEditStore(
    (state) => state.groundObjectEdit?.latitude || 0,
  );
  const groundLongitude = useGroundObjectLaunchEditStore(
    (state) => state.groundObjectEdit?.longitude || 0,
  );

  const activeLaunchWindow = useGroundObjectLaunchEditStore((state) => state.activeLaunchWindow);

  useEffect(() => {
    const keplerianElements = {
      argumentOfPeriapsis: 0,
      eccentricity: orbitalEccentricity,
      inclination: degToRad(orbitalInclination),
      rightAscensionOfAscendingNode: degToRad(orbitalRAAN),
      semiMajorAxis: orbitalSMA,
      trueAnomaly: 0,
    };

    const data = keplerianOrbitMath(keplerianElements, PROPAGATION_STEPS_PER_REVOLUTION);

    let verts: Vector3[] = [];

    data.rArray.forEach((radius, index) => {
      const rhat = new Vector3(0, 0, 0);
      const theta = data.trueAnomalyRangeArray[index];
      data.angleToPositionXform(theta, rhat);
      rhat.multiplyScalar(radius).multiplyScalar(scaleFactor);

      verts[index] = rhat;
    });

    if (activeLaunchWindow) {
      // get percentage between min/max sma
      const percentageSMA = inverseLerp(earthradius, 50000, orbitalSMA);

      // shift the verts around by an offset to draw arc from ground location to start of offset
      const orbitRotationOffsetFromGroundLocation = lerp(
        Math.PI * 0.15,
        Math.PI * 0.55,
        percentageSMA,
      );
      const groundRotationTheta =
        degToRad(groundLongitude) +
        earthRotation -
        degToRad(orbitalRAAN) +
        orbitRotationOffsetFromGroundLocation;
      const groundThetaNormalized = (groundRotationTheta + 2 * Math.PI) % (Math.PI * 2);
      const dataIndex = data.trueAnomalyRangeArray.findIndex(
        (value) => value > groundThetaNormalized,
      );
      verts = verts.concat(verts.splice(0, dataIndex));

      // find trueAnomaly nearest to the longitude
      const vectorGroundPosition = new Vector3().setFromSphericalCoords(
        1,
        degToRad(groundLatitude - 90),
        degToRad(groundLongitude + 180) + earthRotation,
      );

      const vectorGroundPositionControlStrength = lerp(
        1.01,
        data.rArray[dataIndex] * scaleFactor * 0.25,
        percentageSMA,
      );
      const vectorGroundPositionControl = vectorGroundPosition
        .clone()
        .setLength(vectorGroundPositionControlStrength);

      const vectorOrbitControlDirection = verts[0].clone().sub(verts[verts.length - 1]);
      const vectorOrbitControlDirectionStrength = lerp(10, 25, percentageSMA);
      vectorOrbitControlDirection.setLength(
        vectorOrbitControlDirection.length() * vectorOrbitControlDirectionStrength,
      );
      const vectorOrbitControl = verts[0].clone().sub(vectorOrbitControlDirection);

      const curve = new CubicBezierCurve3(
        vectorGroundPosition,
        vectorGroundPositionControl,
        vectorOrbitControl,
        verts[0],
      );
      const points = curve.getPoints(50);

      verts = points.concat(verts);
    }

    if (meshRef) {
      meshRef.geometry.dispose();
      const geometry = new MeshLineGeometry();
      geometry.setPoints(verts);
      meshRef.geometry = geometry;

      const gsapContainer = {
        dashOffset: 1,
      };

      if (meshRef.geometry.index) {
        gsap.fromTo(
          gsapContainer,
          {
            dashOffset: 1,
          },
          {
            dashOffset: 0,
            duration: 60,
            repeat: -1,
            ease: 'none',
            onUpdate: () => {
              const material: MeshLineMaterial = meshRef.material as MeshLineMaterial;
              material.dashOffset = gsapContainer.dashOffset;
            },
          },
        );
      }
    }
  }, [
    activeLaunchWindow,
    earthRotation,
    groundLatitude,
    groundLongitude,
    meshRef,
    orbitalInclination,
    orbitalEccentricity,
    orbitalRAAN,
    orbitalSMA,
  ]);

  return (
    <group>
      <mesh
        name="Launch Preview Orbit"
        ref={setMeshRef}
      >
        <meshLineMaterial
          lineWidth={0.025}
          color={ORBIT_COLOR_DEFAULT}
          dashArray={1 / 2 ** 6}
          transparent={true}
        />
      </mesh>
    </group>
  );
};
