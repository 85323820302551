import { earthradius, muEarth } from 'src/constants';
import { COE } from 'src/threejs/models/COE';
import { linspace } from 'src/utilities/MathUtils';
import { Matrix4, Quaternion, Vector3 } from 'three';

/** Common math functions for keplerian orbits when give a set of COE values. */
function keplerianOrbitMath(coe: ReturnType<COE['toRad']>, numSegments: number) {
  /*********************VARIABLES*********************/

  /** Create sequence of subdivided angles starting at true anomaly and ending a 2PI radians after */
  const trueAnomalyRangeArray = linspace(
    coe.trueAnomaly,
    coe.trueAnomaly + 2 * Math.PI,
    numSegments,
  );

  /** foci to sma distance (c) = ae  so + sma should be perigee from earth dist */
  //const apogeeDistance = coe.semiMajorAxis * (1 + coe.eccentricity);

  /** Currently normalized to unit radius of hearth. TODO: scale manager */
  const scaleFactor = 1 / earthradius;

  /** foci to perigee */
  const perigeeDistance = coe.semiMajorAxis * (1 - coe.eccentricity);

  const semiLatusRectum = coe.semiMajorAxis * (1 - coe.eccentricity ** 2);

  /** Calculate mean motion  n = sqrt(mu/a^3)  and mu = n^2*a^3    (angular speed to somplete 1 orbit) */
  const meanMotion = Math.sqrt(muEarth / coe.semiMajorAxis ** 3);

  /** Compute orbital period from n = 2PI/T  or T = 2PI/n */
  const period = (2 * Math.PI) / meanMotion;

  const linearEccentricity = coe.semiMajorAxis * coe.eccentricity;

  /** Calculate radius r from polar angles  [r = a(1-ecc^2) / 1 + e*cos(theta)] */
  const rArray = trueAnomalyRangeArray.map((theta: number) => {
    return semiLatusRectum / (1 + coe.eccentricity * Math.cos(theta));
  });

  const radPerigee = semiLatusRectum / (1 + coe.eccentricity);
  const radApogee = semiLatusRectum / (1 + coe.eccentricity * -1);

  /** thstar = aol - aop; */
  const th = Math.PI * 2 - coe.argumentOfPeriapsis;

  /** calc right angle position to raan at radius at raan+pi/2 */
  const rInc = semiLatusRectum / (1 + coe.eccentricity * Math.cos(th + Math.PI / 2));

  const semiMinorAxisDistance = Math.sqrt(radApogee * radPerigee);

  const altitudeOfPerigee = radPerigee - earthradius;

  /*********************FUNCTIONS*********************/

  const angleToPositionXform = (theta: number, v: Vector3) => {
    /* ECI coordinates (Z-up/X-out)
        rhat.x = Math.cos(coe.raan) * Math.cos(theta + coe.argumentOfPeri`gee) - Math.sin(coe.raan) * Math.cos(coe.inclination) * Math.sin(theta + coe.argumentOfPerigee);
        rhat.y = Math.sin(coe.raan) * Math.cos(theta + coe.argumentOfPerigee) + Math.cos(coe.raan) * Math.cos(coe.inclination) * Math.sin(theta + coe.argumentOfPerigee);
        rhat.z = Math.sin(coe.inclination) * Math.sin(theta + coe.argumentOfPerigee);
        */
    // ECI equivalent in (Y-up/Z-out coordinates)
    v.x =
      Math.sin(coe.rightAscensionOfAscendingNode) * Math.cos(theta + coe.argumentOfPeriapsis) +
      Math.cos(coe.rightAscensionOfAscendingNode) *
        Math.cos(coe.inclination) *
        Math.sin(theta + coe.argumentOfPeriapsis);
    v.y = Math.sin(coe.inclination) * Math.sin(theta + coe.argumentOfPeriapsis);
    v.z =
      Math.cos(coe.rightAscensionOfAscendingNode) * Math.cos(theta + coe.argumentOfPeriapsis) -
      Math.sin(coe.rightAscensionOfAscendingNode) *
        Math.cos(coe.inclination) *
        Math.sin(theta + coe.argumentOfPeriapsis);
    return v;
  };

  /**  Orbit points of interests **/
  const majorAxisVector = (targetVector: Vector3, primeFocusPosition: Vector3) => {
    // find major axis
    return angleToPositionXform(0, targetVector).sub(primeFocusPosition).normalize();
  };

  const perigeePosition = (targetVector: Vector3, majorAxisVector: Vector3) => {
    return targetVector
      .copy(majorAxisVector)
      .multiplyScalar(radPerigee)
      .multiplyScalar(scaleFactor);
  };

  /** find surface at perigee, get the major maxis, multiply by earth radius*/
  const surfaceAtPerigeePosition = (targetVector: Vector3, majorAxisVector: Vector3) => {
    return targetVector.copy(majorAxisVector).multiplyScalar(earthradius * scaleFactor);
  };

  const apogeePosition = (targetVector: Vector3, majorAxisVector: Vector3) => {
    return targetVector
      .copy(majorAxisVector)
      .negate()
      .multiplyScalar(radApogee)
      .multiplyScalar(scaleFactor);
  };

  /** Find center of ellipse */
  const ellipseCenterPosition = (
    targetVector: Vector3,
    majorAxisVector: Vector3,
    primeFocusPosition: Vector3,
  ) => {
    return targetVector
      .copy(majorAxisVector)
      .negate()
      .add(primeFocusPosition)
      .multiplyScalar(linearEccentricity)
      .multiplyScalar(scaleFactor);
  };

  /** Save this position vector (this should be the same length as the semi-latus rectum) */
  const semiMinorAxisPosition2 = (
    targetVector: Vector3,
    orbitalPlaneVector: Vector3,
    primeFocusPosition: Vector3,
  ) => {
    return targetVector
      .copy(angleToPositionXform(Math.PI * 0.25, orbitalPlaneVector.clone())) //get vector at 90 degrees
      .sub(primeFocusPosition)
      .negate();
  };

  /** find orbital plane vector */
  const orbitalPlaneVector = (
    targetVector: Vector3,
    primeFocusPosition: Vector3,
    majorAxisVector: Vector3,
  ) => {
    return angleToPositionXform(Math.PI * 0.25, targetVector)
      .sub(primeFocusPosition)
      .normalize()
      .cross(majorAxisVector)
      .negate()
      .normalize();
  };

  const minorAxisVector = (
    targetVector: Vector3,
    orbitalPlaneVector: Vector3,
    majorAxisVector: Vector3,
  ) => {
    // this is kinda wierd as I'm going backwards to what we actually had.  Technically not wrong.
    return targetVector.copy(orbitalPlaneVector).cross(majorAxisVector).normalize();
  };

  const semiMinorAxisPosition1 = (
    targetVector: Vector3,
    minorAxisVector: Vector3,
    ellipseCenterPosition: Vector3,
  ) => {
    return targetVector
      .copy(minorAxisVector)
      .multiplyScalar(semiMinorAxisDistance)
      .multiplyScalar(scaleFactor)
      .add(ellipseCenterPosition);
  };

  const orbitalPlaneOrientation = (
    targetQuaternion: Quaternion,
    majorAxisVector: Vector3,
    minorAxisVector: Vector3,
    orbitalPlaneVector: Vector3,
  ) => {
    const tempMatrix = new Matrix4();
    const tempVector = new Vector3(); // flip majorAxisVector
    tempVector.copy(majorAxisVector).negate();
    tempMatrix.makeBasis(minorAxisVector, tempVector, orbitalPlaneVector);
    return targetQuaternion.setFromRotationMatrix(tempMatrix);
  };

  const raanPositionUnitVector = (v: Vector3, aol = 0) => {
    v.x =
      Math.sin(coe.rightAscensionOfAscendingNode) * Math.cos(aol) +
      Math.cos(coe.rightAscensionOfAscendingNode) * Math.cos(coe.inclination) * Math.sin(aol);
    v.y = Math.sin(coe.inclination) * Math.sin(aol);
    v.z =
      Math.cos(coe.rightAscensionOfAscendingNode) * Math.cos(aol) -
      Math.sin(coe.rightAscensionOfAscendingNode) * Math.cos(coe.inclination) * Math.sin(aol);
    return v;
  };

  const raanPosition = (targetVector: Vector3) => {
    const r = semiLatusRectum / (1 + coe.eccentricity * Math.cos(th));

    return targetVector
      .copy(raanPositionUnitVector(new Vector3()))
      .normalize()
      .multiplyScalar(r)
      .multiplyScalar(scaleFactor);
  };

  const inclinationRightAnglePosition = (
    targetVector: Vector3,
    orbitalPlaneVector: Vector3,
    raanPos: Vector3,
  ) => {
    return targetVector
      .copy(orbitalPlaneVector)
      .cross(raanPos)
      .normalize()
      .multiplyScalar(rInc * scaleFactor);
  };

  const aopPosition = (targetVector: Vector3) => {
    const th2 = 0; //coe.argumentOfPeriapsis;
    const r2 = semiLatusRectum / (1 + coe.eccentricity * Math.cos(th2));

    return targetVector
      .copy(raanPositionUnitVector(new Vector3(), coe.argumentOfPeriapsis))
      .normalize()
      .multiplyScalar(r2)
      .multiplyScalar(scaleFactor);
  };

  return {
    altitudeOfPerigee,
    angleToPositionXform,
    aopPosition,
    /** foci to sma distance (c) = ae  so + sma should be perigee from earth dist */
    apogeeDistance: radApogee,
    apogeePosition,
    ellipseCenterPosition,
    inclinationRightAnglePosition,
    /**  Orbit points of interests **/
    majorAxisVector,
    /** Calculate mean motion  n = sqrt(mu/a^3)  and mu = n^2*a^3    (angular speed to somplete 1 orbit) */
    meanMotion,
    minorAxisVector,
    orbitalPlaneOrientation,
    orbitalPlaneVector,
    /** Compute orbital period from n = 2PI/T  or T = 2PI/n */
    period,
    /** foci to perigee */
    perigeeDistance,
    perigeePosition,
    raanPosition,
    raanPositionUnitVector,
    /** Currently normalized to unit radius of hearth. TODO: scale manager */
    scaleFactor,
    /** Compute radial distance from the Earth to satellite along the entire orbit using Conic Equation Calculate semi-latus rectum  [a * (1 - ecc^2)] */
    semiLatusRectum,
    semiMinorAxisDistance,
    semiMinorAxisPosition1,
    semiMinorAxisPosition2,
    surfaceAtPerigeePosition,
    trueAnomalyRangeArray,
    /** Calculate radius r from polar angles  [r = a(1-ecc^2) / 1 + e*cos(theta)] */
    rArray,
  };
}

export default keplerianOrbitMath;
