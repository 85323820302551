import { useFrame } from '@react-three/fiber';
import { useCallback, useEffect, useState } from 'react';
import {
  DEFAULT_ADDITIONAL_PROPERTIES_ORBIT,
  GROUND_TRACK_OBJECT_SCALE_FACTOR,
  ORBIT_TYPES,
  PROPAGATION_STEPS_PER_REVOLUTION,
} from 'src/constants';
import { useIsPropagating } from 'src/hooks/OrbitHooks';
import { useCurrentPage } from 'src/hooks/PageHooks';
import PropagatedCacheManager from 'src/models/PropagatedCacheManager';
import { getActiveGroupId, getActiveObjectId, useRouteStore } from 'src/pages/App/routes/store';
import { OrbitGeoObject } from 'src/pages/Notebook/components/OrbitGeoObject';
import getEarthRotation from 'src/threejs/math/getEarthRotation';
import getTrueAnomalyPosition from 'src/threejs/math/getTrueAnomalyPosition';
import { OrbitGeoObjectType, OrbitObject, StateVectorType } from 'src/types';
import { CatmullRomCurve3, Group, Mesh, TubeGeometry, Vector3 } from 'three';
import use3DOrbitStore from '../OrbitManager/store/store';
import { convertLatLongToCartesian } from '../../math/convertLatLongToCartesian';

const GROUND_TRACK_DISTANCE_FROM_ORIGIN = 1.005;

interface GroundTrackProps {
  orbit: OrbitObject;
}

export const GroundTrack = ({ orbit }: GroundTrackProps) => {
  const currentOrbitId = useRouteStore(getActiveObjectId);
  const currentGroupId = useRouteStore(getActiveGroupId);

  const [pointsPreview, setPointsPreview] = useState<Vector3[]>([]);
  const [pointsPropagating, setPointsPropagating] = useState<Vector3[]>([]);

  const orbitAdditionalProperties =
    orbit.additionalProperties || DEFAULT_ADDITIONAL_PROPERTIES_ORBIT;

  const isPropagating = useIsPropagating();
  const currentPage = useCurrentPage();

  const orbitState = use3DOrbitStore((state) => state.orbits[orbit.id]);

  const orbitCache = PropagatedCacheManager.getCacheForOrbit(orbit.id);

  const activeSV = use3DOrbitStore((state) => state.orbits[orbit.id]?.activeStateVector || null);

  const trackLength = orbitAdditionalProperties.trackLength;

  const [refMesh, setRefMesh] = useState<Mesh | null>(null);
  const [refObject, setRefObject] = useState<Group | null>(null);

  // update the local state array of points for the preview ground track
  useEffect(() => {
    // make sure we have a start time and a period
    if (currentPage?.startTime && orbitState?.orbitData.period) {
      const points = [];

      const coeToUse = { ...orbitState.coe.toRad() };
      let trueAnomalyCurrent = coeToUse.trueAnomaly;

      const period = orbitState.orbitData.period;
      const deltaT = period / orbitState.orbitData.numSegments;

      let earthRotTime = currentPage?.startTime.getTime();

      for (let i = 1; i < orbitState.orbitData.numSegments; i++) {
        const earthRot = getEarthRotation(earthRotTime);

        coeToUse.trueAnomaly = trueAnomalyCurrent;
        const { x, y, z, dtheta } = getTrueAnomalyPosition(coeToUse);

        const currentVert = new Vector3(x, y, z);
        currentVert
          .applyAxisAngle(new Vector3(0, 1, 0), -earthRot)
          .setLength(GROUND_TRACK_DISTANCE_FROM_ORIGIN);
        points.push(currentVert);
        earthRotTime += deltaT * 1000;

        trueAnomalyCurrent += dtheta * deltaT;
      }

      setPointsPreview(points);
    }
  }, [
    currentPage?.startTime,
    orbitState?.coe,
    orbitState?.orbitData.numSegments,
    orbitState?.orbitData.period,
  ]);

  const updatePointsPreview = useCallback(() => {
    let indexEnd = pointsPreview.length;
    if (trackLength > 0) {
      indexEnd = Math.min(Math.floor(PROPAGATION_STEPS_PER_REVOLUTION * trackLength), indexEnd);
    }

    const points = pointsPreview.slice(0, indexEnd);

    if (points.length > 1) {
      // need 2 points to make a curve
      const curve = new CatmullRomCurve3(points, false, 'catmullrom', 0.0);

      if (refMesh) {
        refMesh.geometry.dispose();
        refMesh.geometry = new TubeGeometry(curve, points.length, 0.005, 3, false);
        refMesh.visible = true;
      }
    } else {
      if (refMesh) {
        refMesh.visible = false;
      }
    }

    // look at the next point in the path from the starting point when in preview mode
    // for when the shape is a cone or another shape
    if (points.length > 1 && refObject && refMesh) {
      refObject.position.copy(points[0]);

      const currentPositionInPath = new Vector3().copy(points[0]);
      const nextPositionInPath = new Vector3().copy(points[1]);

      // need to apply any transforms to get true world position
      currentPositionInPath.applyMatrix4(refMesh.matrixWorld);
      nextPositionInPath.applyMatrix4(refMesh.matrixWorld);

      const dirVec = new Vector3()
        .subVectors(currentPositionInPath, nextPositionInPath)
        .normalize();

      const positionToLook = nextPositionInPath.clone().sub(dirVec);

      refObject.lookAt(positionToLook);
    }
  }, [trackLength, pointsPreview, refMesh, refObject]);

  useEffect(() => {
    if (orbitCache?.stateVectors) {
      const points: Vector3[] = [];

      // start at 1 since 0 and 1 have same epoch
      for (let i = 1; i < orbitCache.stateVectors.length; i++) {
        const sv = orbitCache.stateVectors[i];
        if (sv) {
          const point = convertLatLongToCartesian(
            sv.stateVectors[0].groundTrack.lat,
            sv.stateVectors[0].groundTrack.lon,
          );
          points.push(point.setLength(GROUND_TRACK_DISTANCE_FROM_ORIGIN));
        }
      }

      setPointsPropagating(points);
    }
  }, [orbitCache, orbitCache?.length, orbitCache?.stateVectors]);

  const updatePoints = useCallback(
    (activeSV: StateVectorType) => {
      let indexStart = 0;

      if (trackLength > 0) {
        indexStart = Math.max(
          Math.floor(activeSV.step - PROPAGATION_STEPS_PER_REVOLUTION * trackLength),
          indexStart,
        );
      }
      const indexEnd = Math.min(pointsPropagating.length, Math.floor(activeSV.step));

      const points = pointsPropagating.slice(indexStart, indexEnd);

      const currentPoint = convertLatLongToCartesian(
        activeSV.groundTrack.lat,
        activeSV.groundTrack.lon,
      ).setLength(GROUND_TRACK_DISTANCE_FROM_ORIGIN);

      points.push(currentPoint);

      if (points.length > 1) {
        // need 2 points to make a curve
        const curve = new CatmullRomCurve3(points, false, 'catmullrom', 0.0);

        if (refMesh) {
          refMesh.geometry.dispose();
          refMesh.geometry = new TubeGeometry(curve, points.length, 0.005, 3, false);
          refMesh.visible = true;
        }
      } else {
        if (refMesh) {
          refMesh.visible = false;
        }
      }

      if (refObject) {
        const start = points[points.length - 1];
        refObject.position.set(start.x, start.y, start.z);

        // While propagating, apply direction vector to forward movement to make shape always face
        // the direction of propagation
        if (refMesh && points[points.length - 2]) {
          const currentPosInPath = new Vector3().copy(start);
          const previousPosInPath = new Vector3().copy(points[points.length - 2]);

          // need to apply any transforms to get true world position
          currentPosInPath.applyMatrix4(refMesh.matrixWorld);
          previousPosInPath.applyMatrix4(refMesh.matrixWorld);

          const dirVec = new Vector3().subVectors(currentPosInPath, previousPosInPath).normalize();
          const positionToLook = currentPosInPath.clone().add(dirVec);

          refObject.lookAt(positionToLook);
        }
      }
    },
    [trackLength, pointsPropagating, refMesh, refObject],
  );

  useFrame(() => {
    if (isPropagating) {
      if (activeSV) {
        updatePoints(activeSV);
      }
    } else {
      updatePointsPreview();
    }
  });

  // launch orbits are used for the payloads, so when not propagating, it's only used for position, no track to see
  if (!isPropagating && orbit.orbitType === ORBIT_TYPES.LAUNCH) {
    return null;
  }

  return (
    <>
      <mesh ref={setRefMesh}>
        <meshBasicMaterial
          color={orbitAdditionalProperties.color}
          transparent
          opacity={currentOrbitId === orbit.id || currentGroupId === orbit.groupId ? 1 : 0.5}
        />
      </mesh>
      <group
        ref={setRefObject}
        scale={GROUND_TRACK_OBJECT_SCALE_FACTOR}
      >
        {/* Make cone face proper direction for ground track */}
        <group rotation={[Math.PI / 2, 0, Math.PI / 2]}>
          <OrbitGeoObject
            objectType={OrbitGeoObjectType.GroundTrack}
            orbit={orbit}
            parentMesh={refObject as Group}
          />
        </group>
      </group>
    </>
  );
};
