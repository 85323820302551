import { produce } from 'immer';
import { DEFAULT_AXES_PROPERTIES } from 'src/constants';
import { AxesProperties } from 'src/types';
import { create } from 'zustand';
import { persist } from 'zustand/middleware';

/*
  SETTINGS STORE that is intended to persist across sessions
*/

export const SETTINGS_NAMES = {
  LAYOUT_MINIFIED: 'layout-minifed',
  DEVELOPER_MENU_ENABLED: 'developer-menu-enabled',
  DEVELOPER_MENU_OPENED: 'developer-menu-opened',
  DEVELOPER_MENU_TAB: 'developer-menu-tab',
  STORYBUILDER_EDITABLE: 'storybuilder_editable',
  STATS_OVERLAY_ENABLED: 'stats_overlay_enabled',
  PERF_OVERLAY_ENABLED: 'perf_overlay_enabled',
  MANEUVERS_FLYOUT_STATUS: 'maneuvers_flyout_status',
  VECTOR_HELPER_POSITION_ENABLED: 'vector_helper_position_enabled',
  VECTOR_HELPER_VELOCITIES_ENABLED: 'vector_helper_velocities_enabled',
  VIDEO_CAMERA_ENABLED: 'video_camera_enabled',
  FEATURE_EPHEMERIS_ENABLED: 'feature_ephemeris_enabled',
  FEATURE_LAUNCH_ENABLED: 'feature_launch_enabled',
  EXPANDED_ACCORDIONS: 'expanded_accordions',
  MAX_FRAMERATE_ENABLED: 'max_framerate_enabled',
};

interface SettingsStoreState {
  settings: Record<string, boolean>;
  initialize: (name: string) => void;
  toggleSetting: (name: string) => void;
  setSetting: (name: string, value: boolean) => void;

  framerate: number;
  setFramerate: (newFramerate: number) => void;

  axesSettings: Record<string, AxesProperties>;
  setAxesSetting: (
    axes: keyof typeof DEFAULT_AXES_PROPERTIES,
    changes: Partial<AxesProperties>,
  ) => void;

  custom: Record<string, any>;
  setCustom: (name: string, setting: any) => void;

  position: Partial<GeolocationPosition>;
  setPosition: (position: GeolocationPosition) => void;
}

export const useSettingsStore = create<SettingsStoreState>()(
  persist(
    (set, _get) => ({
      settings: {
        [SETTINGS_NAMES.MAX_FRAMERATE_ENABLED]: true,
      },
      initialize: (name: string) => {
        set(
          produce((state) => {
            state.settings[name] = false;
          }),
        );
      },
      toggleSetting: (name: string) => {
        set(
          produce((state) => {
            state.settings[name] = !state.settings[name];
          }),
        );
      },
      setSetting: (name: string, value: boolean) => {
        set(
          produce((state) => {
            state.settings[name] = value;
          }),
        );
      },

      framerate: 120,
      setFramerate: (newFramerate: number) =>
        set(
          produce((state) => {
            state.framerate = newFramerate;
          }),
        ),

      axesSettings: DEFAULT_AXES_PROPERTIES,
      setAxesSetting: (
        axes: keyof typeof DEFAULT_AXES_PROPERTIES,
        changes: Partial<AxesProperties>,
      ) => {
        set(
          produce((state) => {
            state.axesSettings[axes] = {
              ...state.axesSettings[axes],
              ...changes,
            };
          }),
        );
      },

      custom: {},
      setCustom: (name: string, setting: any) => {
        set(
          produce((state) => {
            state.custom[name] = setting;
          }),
        );
      },

      position: {},
      setPosition: (position: GeolocationPosition) => {
        set(
          produce((state) => {
            state.position = {
              altitude: position.coords.altitude || 0,
              latitude: position.coords.latitude || 0,
              longitude: position.coords.longitude || 0,
            };
          }),
        );
      },
    }),
    {
      name: 'settings',
    },
  ),
);

export const useIsFeatureEnabled = (setting: string) => {
  const settings: Record<string, boolean> = useSettingsStore((state) => state.settings);

  // place settings here to be permanently disabled
  const forcedFeaturesFalse: Array<string> = [];

  // place settings here to be permanently enabled
  const forcedFeaturesTrue: Array<string> = [
    SETTINGS_NAMES.FEATURE_EPHEMERIS_ENABLED,
    SETTINGS_NAMES.FEATURE_LAUNCH_ENABLED,
  ];

  if (forcedFeaturesFalse.includes(setting)) {
    return false;
  }
  if (forcedFeaturesTrue.includes(setting)) {
    return true;
  }

  return settings[setting] || false;
};
