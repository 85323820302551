import { Grid } from '@mui/material';
import { ReactNode } from 'react';
import theme, { COLOR_BORDER } from 'src/pages/App/Theme';

interface SlideoutPanelItemProps {
  children: ReactNode;
}

export const SlideoutPanelItem = ({ children }: SlideoutPanelItemProps) => {
  return (
    <Grid
      display="grid"
      gridAutoFlow="column"
      gap={1}
      alignItems="center"
      justifyContent="start"
      fontSize={12}
      borderLeft={`1px solid ${COLOR_BORDER}`}
      whiteSpace="nowrap"
      py={1}
      pl={1}
      sx={{
        cursor: 'pointer',
        fontVariationSettings: "'wght' 500",
        '&:hover': {
          color: theme.palette.primary.main,
        },
      }}
    >
      {children}
    </Grid>
  );
};
