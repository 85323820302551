import { FormControl, Grid, MenuItem, Select, SelectChangeEvent } from '@mui/material';
import { SyntheticEvent, useCallback } from 'react';
import {
  COE_ORBIT_TYPE_DISPLAY,
  DEFAULT_ADDITIONAL_PROPERTIES_ORBIT,
  RELATIVE_ORBIT_TYPE_DISPLAY,
  SV_ORBIT_TYPE_DISPLAY,
} from 'src/constants';
import { useCurrentOrbit, useUpdateOrbitAdditionalProperties } from 'src/hooks/OrbitHooks';
import { LiveDataMode } from 'src/types';

export const LiveDataDisplayMode = () => {
  const currentOrbit = useCurrentOrbit();
  const updateOrbitAdditionalProperties = useUpdateOrbitAdditionalProperties();

  const currentLiveDataMode =
    currentOrbit?.additionalProperties?.liveDataMode ||
    DEFAULT_ADDITIONAL_PROPERTIES_ORBIT.liveDataMode;

  const handleChange = useCallback(
    (event: SelectChangeEvent) => {
      if (currentOrbit) {
        const changes = { liveDataMode: event.target.value as LiveDataMode };
        updateOrbitAdditionalProperties(currentOrbit, changes);
      }
    },
    [updateOrbitAdditionalProperties, currentOrbit],
  );

  const onSelectInteract = useCallback(
    (e: SyntheticEvent<Element, Event>) => e.stopPropagation(),
    [],
  );

  return (
    <Grid item>
      <FormControl variant="standard">
        <Select
          value={currentLiveDataMode}
          onOpen={onSelectInteract}
          onClose={onSelectInteract}
          onChange={handleChange}
          label="Live Data Display Mode"
        >
          <MenuItem value={LiveDataMode.Keplerian}>{COE_ORBIT_TYPE_DISPLAY}</MenuItem>
          <MenuItem value={LiveDataMode.Cartesian}>{SV_ORBIT_TYPE_DISPLAY}</MenuItem>
          <MenuItem value={LiveDataMode.Relative}>{RELATIVE_ORBIT_TYPE_DISPLAY}</MenuItem>
        </Select>
      </FormControl>
    </Grid>
  );
};
