import { useAuth0 } from '@auth0/auth0-react';
import { ReactNode, useEffect, useState } from 'react';
import { API_STORE } from 'src/services/API_STORE';

interface TokenProps {
  children: ReactNode;
}
export const Token = ({ children }: TokenProps) => {
  const { getAccessTokenSilently, user } = useAuth0();

  const [token, setToken] = useState<string | null>(null);

  useEffect(() => {
    (async () => {
      try {
        const newToken = await getAccessTokenSilently();
        setToken(newToken);

        if (user) {
          API_STORE.userProfile = user.name + ',' + user.email;
          API_STORE.token = newToken;
          setToken(newToken);
        }
      } catch (e) {
        console.error('error', e);
      }
    })();
  }, [getAccessTokenSilently, user]);

  if (!token) {
    return null;
  }

  return <>{children}</>;
};
