import { useFrame } from '@react-three/fiber';
import { useRef, useState } from 'react';
import { scaleFactor } from 'src/constants';
import { DoubleSide, LatheGeometry, Mesh, Vector2 } from 'three';

export type SensorConeRotationVector = [number, number, number];

export interface SensorConeProps {
  getConeShaderColor: () => string;
  getConeShaderEnabled: () => boolean;
  getConeShaderOpacity: () => number;
  getConeWireframeColor: () => string;
  getConeWireframeEnabled: () => boolean;
  getConeWireframeOpacity: () => number;
  domeEnabled: boolean;
  getHeight: () => number;
  getBaseRadius: () => number;
  rotation?: SensorConeRotationVector;
  renderOrder?: number;
}

export const SensorCone = ({
  getConeShaderColor,
  getConeShaderEnabled,
  getConeShaderOpacity,
  getConeWireframeColor,
  getConeWireframeEnabled,
  getConeWireframeOpacity,
  domeEnabled,
  getHeight,
  getBaseRadius,
  rotation = [0, 0, 0],
  renderOrder,
}: SensorConeProps) => {
  const [meshRef, setMeshRef] = useState<Mesh | null>(null);
  const [meshWireframeRef, setMeshWireframeRef] = useState<Mesh | null>(null);
  const baseRadiusRef = useRef(-999);
  const heightRef = useRef(-999);

  useFrame(() => {
    const newBaseRadius = getBaseRadius();
    const newHeight = getHeight();

    // only recompute if any of the states have changed
    if (baseRadiusRef.current !== newBaseRadius || heightRef.current !== newHeight) {
      baseRadiusRef.current = newBaseRadius;
      heightRef.current = newHeight;

      const points = createPoints(newBaseRadius, newHeight);

      if (meshRef) {
        meshRef.geometry.dispose();
        meshRef.geometry = new LatheGeometry(points, 128);
        meshRef.visible = getConeShaderEnabled();
      }
      if (meshWireframeRef) {
        meshWireframeRef.geometry.dispose();
        meshWireframeRef.geometry = new LatheGeometry(points, 32);
        meshWireframeRef.visible = getConeWireframeEnabled();
      }
    }
  });

  const createPoints = (baseRadius: number, height: number) => {
    const points: Vector2[] = [
      new Vector2(0, 0), // origin
      new Vector2(baseRadius, height).multiplyScalar(scaleFactor), // point of cone
    ];

    if (domeEnabled) {
      const slantRange = Math.sqrt(baseRadius ** 2 + height ** 2);
      const coneAngle = Math.atan(baseRadius / height);
      const segments = Math.floor((16 / Math.PI) * coneAngle) + 4; // minimum of 4 seg, 12 if height is 0

      for (let i = 1; i <= segments; i++) {
        const tmpAngle = coneAngle - (i / segments) * coneAngle;

        points.push(
          new Vector2(Math.sin(tmpAngle), Math.cos(tmpAngle))
            .multiplyScalar(slantRange)
            .multiplyScalar(scaleFactor),
        );
      }
      points.push(new Vector2(0, slantRange).multiplyScalar(scaleFactor));
    } else {
      points.push(new Vector2(0, height).multiplyScalar(scaleFactor));
    }
    return points;
  };

  return (
    <group
      rotation={rotation}
      renderOrder={renderOrder}
    >
      <mesh
        ref={setMeshRef}
        visible={getConeShaderEnabled()}
      >
        <meshBasicMaterial
          depthWrite={false}
          side={DoubleSide}
          transparent
          color={getConeShaderColor()}
          opacity={getConeShaderOpacity()}
        />
      </mesh>

      <mesh
        ref={setMeshWireframeRef}
        visible={getConeWireframeEnabled()}
        scale={domeEnabled ? 1.004 : 1}
      >
        <meshBasicMaterial
          depthWrite={false}
          side={DoubleSide}
          transparent
          color={getConeWireframeColor()}
          opacity={getConeWireframeOpacity()}
          wireframe
        />
      </mesh>
    </group>
  );
};
