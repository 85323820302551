import { ContentCopyRounded } from '@mui/icons-material';
import { Button, Dialog, DialogActions, DialogContent } from '@mui/material';
import { SpaceGlassContainerBase } from 'src/components/SpaceGlassControl';
import { ModalHeader } from 'src/pages/Shared/ModalSpaceGlass';
import { ReactNode } from 'react';

type ModalObjectDuplicateProps = {
  title: string;
  show: boolean;
  onSubmitFn: VoidFunction;
  onCloseFn: VoidFunction;
  children: ReactNode;
};

export const ModalObjectDuplicate = ({
  title,
  show,
  onSubmitFn,
  onCloseFn,
  children,
}: ModalObjectDuplicateProps) => {
  return (
    <Dialog
      open={show}
      onClose={onCloseFn}
      fullWidth={true}
      aria-labelledby="max-width-dialog-title"
      maxWidth="md"
      PaperComponent={SpaceGlassContainerBase}
    >
      <ModalHeader
        title={title}
        onClose={onCloseFn}
        icon={<ContentCopyRounded />}
      />
      <DialogContent>{children}</DialogContent>
      <DialogActions>
        <Button onClick={onCloseFn}>CANCEL</Button>
        <Button
          onClick={onSubmitFn}
          startIcon={<ContentCopyRounded />}
          variant="contained"
        >
          DUPLICATE
        </Button>
      </DialogActions>
    </Dialog>
  );
};
