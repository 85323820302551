import { Grid, IconButton, Tooltip } from '@mui/material';
import { UnfoldLessRounded, UnfoldMoreRounded } from '@mui/icons-material';

import { COLOR_BORDER } from 'src/pages/App/Theme';
import { SETTINGS_NAMES, useSettingsStore } from 'src/pages/Settings/store';

interface ExpandCollapseHeadersSwitchProps {
  accordionList: string[];
}

// Stubbed until it can be determined the best way to add it to the screen.
export const ExpandCollapseHeadersSwitch = ({
  accordionList,
}: ExpandCollapseHeadersSwitchProps) => {
  // Get all the expanded accordions for the site.
  // If there is nothing, default it to the list that was passed in.
  const allExpandedAccordions =
    useSettingsStore((state) => state.custom[SETTINGS_NAMES.EXPANDED_ACCORDIONS]) || accordionList;

  const setSetting = useSettingsStore((state) => state.setCustom);

  const collapseAccordions = () => {
    // Since the allExpandedAccordions includes everything for the site, we want to make sure we only update these particular ones.
    const updatedState = allExpandedAccordions.filter((a: string) => !accordionList.includes(a));
    setSetting(SETTINGS_NAMES.EXPANDED_ACCORDIONS, updatedState);
  };

  const expandAccordions = () => {
    // Since the allExpandedAccordions includes everything for the site, we want to make sure we only update these particular ones.
    // Use a set because it removes duplicates.
    const updatedState = [...new Set([...allExpandedAccordions, ...accordionList])];
    setSetting(SETTINGS_NAMES.EXPANDED_ACCORDIONS, updatedState);
  };

  return (
    <Grid
      display="grid"
      gridAutoFlow="column"
      alignItems="center"
    >
      <IconButton
        size="small"
        sx={{ py: 0 }}
        onClick={expandAccordions}
      >
        <Tooltip title="Expand All">
          <UnfoldMoreRounded fontSize="inherit" />
        </Tooltip>
      </IconButton>

      <div
        style={{
          height: '100%',
          width: '1px',
          background: `${COLOR_BORDER}`,
        }}
      />

      <IconButton
        size="small"
        sx={{ py: 0 }}
        onClick={collapseAccordions}
      >
        <Tooltip title="Collapse All">
          <UnfoldLessRounded fontSize="inherit" />
        </Tooltip>
      </IconButton>
    </Grid>
  );
};
