import { ThreeEvent, useFrame, useThree } from '@react-three/fiber';
import React, {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useIsPropagating, useUpdateOrbitMutation } from 'src/hooks/OrbitHooks';
import { useCurrentPage } from 'src/hooks/PageHooks';
import ManipulatorPlane from 'src/threejs/components/ManipulatorPlane';
import { use3DOrbitContext } from 'src/threejs/components/Orbit/context';
import { createGetOrbitCOE } from 'src/threejs/components/OrbitManager/store/getters';
import {
  use3DOrbitCoeUpdater,
  useSaveOrbit,
} from 'src/threejs/components/OrbitManager/store/hooks';
import useViewportStore, {
  useActiveManipulator,
} from 'src/threejs/components/ViewportManager/store';
import useCamera from 'src/threejs/hooks/useCamera';
import { COE, COEData } from 'src/threejs/models/COE';
import ManipulatorPlaneModel from 'src/threejs/models/ManipulatorPlane';
import { ManipulatorName } from 'src/threejs/types';
import { makeTextSprite, TextSpriteParameters } from 'src/threejs/utils/makeTextSprite';
import { clamp } from 'src/utilities/MathUtils';
import { DoubleSide, Group, Mesh, Object3D, Raycaster, Sprite, Vector2, Vector3 } from 'three';
import { useViewportContext } from '../Viewport/context';

export type MarkerMoveProps = {
  group: Group;
  tempVector: Vector3;
  tempVector2: Vector3;
  pointOnPlaneOffset: Vector3;
  pointOnPlaneEnd: Vector3;
  positionPlaneLast: Vector3;
  pointOnPlaneStart: Vector3;
  positionStart: Vector3;
  mousePickPosition: Vector2;
  upVector: Vector3;
};

export type UpdateTransformProps = {
  upVector: Vector3;
  dragging: boolean;
};

export type UpdateSpriteProps = {
  tempVector2: Vector3;
};

type MarkerManipulatorProps = {
  /** Handles moving the manipulator, the return result will be used to update the orbit's COE values. */
  move: (props: MarkerMoveProps) => Partial<COEData>;
  markerName: ManipulatorName;
  textSpriteMessage: string;
  textSpriteProps: TextSpriteParameters;
  setHandle?: Dispatch<SetStateAction<Mesh | null>>;
  handle?: Mesh | null;
  setGroup: Dispatch<SetStateAction<Group | null>>;
  setBounds: Dispatch<SetStateAction<Mesh | null>>;
  setSprite: Dispatch<SetStateAction<Sprite | null>>;
  setPlane: Dispatch<SetStateAction<ManipulatorPlaneModel | null>>;
  group: Group | null;
  plane: ManipulatorPlaneModel | null;
  makeSpriteName: (coe: COE) => string;
  scale: number;
  manipulatorPlaneRotation?: boolean;
  updateBounds: () => void;
  updateSprite: (props: UpdateSpriteProps) => void;
  updateTransform: (props: UpdateTransformProps) => void;
  onDragEnd?: () => void;
  onDragStart?: () => void;
  children: React.ReactNode;
};

const config = {
  handleMinScale: 0.1,
  handleMaxScale: 20,
};

const raycaster = new Raycaster();

/** The base class for all marker manipulators, handles raycasting, mouse/pointer events, updating the cache and auto saving. */
const MarkerManipulator = ({
  markerName,
  move,
  textSpriteMessage,
  textSpriteProps,
  setHandle,
  handle,
  setGroup,
  setBounds,
  setSprite,
  setPlane,
  group,
  plane,
  makeSpriteName,
  scale,
  manipulatorPlaneRotation = false,
  updateBounds,
  updateSprite,
  updateTransform,
  onDragEnd = () => {},
  onDragStart = () => {},
  children,
}: MarkerManipulatorProps) => {
  const { name, id, selected } = use3DOrbitContext();
  const [activeManipulator, setActiveManipulator] = useActiveManipulator();
  const camera = useCamera();
  const { gl } = useThree();
  const updateOrbitCoe = use3DOrbitCoeUpdater();
  const updateOrbit = useUpdateOrbitMutation();
  const getOrbitCOE = useMemo(() => createGetOrbitCOE(id), [id]);
  const isPropagating = useIsPropagating();
  const currentPage = useCurrentPage();

  const [showHandle, setShowHandle] = useState(false);
  const saveOrbit = useSaveOrbit(id);

  const tempVector = useMemo(() => new Vector3(0, 0, 0), []);
  const tempVector2 = useMemo(() => new Vector3(0, 0, 0), []);
  const mousePickPosition = useMemo(() => new Vector2(), []);
  const positionStart = useMemo(() => new Vector3(0, 0, 0), []);
  const pointOnPlaneStart = useMemo(() => new Vector3(0, 0, 0), []);
  const positionPlaneLast = useMemo(() => new Vector3(0, 0, 0), []);
  const pointOnPlaneEnd = useMemo(() => new Vector3(0, 0, 0), []);
  const pointOnPlaneOffset = useMemo(() => new Vector3(0, 0, 0), []);
  const upVector = useMemo(() => new Vector3(0, 1, 0), []);

  const spriteScale = useMemo(() => new Vector3(0.2, 0.1, 1), []);
  const spritePosition = useMemo(() => new Vector3(0, 0, 0), []);

  const dragging = useRef<boolean>(false);

  const { viewport } = useViewportContext();
  const setMarkerActive = useViewportStore((state) => state.viewports[viewport.id].setMarkerActive);

  const enablePointerEvents = () => {
    // We disable all pointer events while propagating
    if (isPropagating) return false;

    if (selected) {
      // If there is an active manipulator then when disabled all pointer events
      // for manipulatops that are not the current active one
      if (activeManipulator) {
        return activeManipulator === markerName;
      }
      // If there is not an active manipulator, then all manipulators
      // for the currently selected orbit have pointer events
      return true;
    }

    // We disable all pointer events for non-selected orbits
    return false;
  };

  const domElement = gl.domElement as HTMLCanvasElement | undefined;

  const spriteMaterial = useMemo(
    () => makeTextSprite(textSpriteMessage, textSpriteProps),
    [textSpriteMessage, textSpriteProps],
  );

  const [textSprite, setTextSprite] = useState<Sprite | null>(null);

  const updateSpriteText = () => {
    if (textSprite) {
      if (!textSprite.material.map) return;

      const coe = getOrbitCOE();
      const context = textSprite.material.map.image.getContext('2d');
      const canvas = textSprite.material.map.image;

      context.clearRect(0, 0, canvas.width, canvas.height);
      context.fillText(`${makeSpriteName(coe)}`, 50, 50);

      textSprite.material.map.needsUpdate = true;
    }
  };

  const moveManipulator = useCallback(() => {
    if (group) {
      const coeUpdates = move({
        group,
        tempVector,
        tempVector2,
        pointOnPlaneOffset,
        pointOnPlaneEnd,
        pointOnPlaneStart,
        positionPlaneLast,
        positionStart,
        mousePickPosition,
        upVector,
      });

      updateOrbitCoe(coeUpdates);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [group, updateOrbitCoe]);

  const intersectObjectWithRay = useCallback(
    (object: Object3D, raycaster: Raycaster, includeInvisible: boolean) => {
      const allIntersections = raycaster.intersectObject(object, true);

      for (const element of allIntersections) {
        if (element.object.visible || includeInvisible) {
          return element;
        }
      }
      return false;
    },
    [],
  );

  const setPickPosition = useCallback(
    (event: MouseEvent) => {
      if (domElement) {
        const rect = domElement.getBoundingClientRect();
        const canvasX = ((event.clientX - rect.left) * domElement.offsetWidth) / rect.width;
        const canvasY = ((event.clientY - rect.top) * domElement.offsetHeight) / rect.height;

        // normalized click position
        mousePickPosition.x = (canvasX / domElement.offsetWidth) * 2 - 1;
        mousePickPosition.y = -(canvasY / domElement.offsetHeight) * 2 + 1;
      }
    },
    [domElement, mousePickPosition],
  );

  const handleBoundsPointerOver = useCallback(() => {
    if (!dragging.current) {
      if (domElement) {
        domElement.style.cursor = 'grab';
        setShowHandle(true);
      }
    }
  }, [domElement]);

  const handleBoundsPointerOut = useCallback(() => {
    if (!dragging.current) {
      if (domElement) {
        domElement.style.cursor = 'default';
        setShowHandle(false);
      }
    }
  }, [domElement]);

  const handleBoundsPointerUp = useCallback(() => {
    dragging.current = false;
    if (domElement) {
      domElement.style.cursor = 'grab';
      setMarkerActive(false);
    }
  }, [setMarkerActive, domElement]);

  const handleDomMouseLeave = useCallback(() => {
    dragging.current = false;
    if (domElement) {
      domElement.style.cursor = 'default';
      setShowHandle(false);
      setMarkerActive(false);
    }
  }, [setMarkerActive, domElement]);

  const handleDomMouseUp = useCallback(() => {
    const wasDragging = dragging.current === true;

    // If we were just dragging and the user has stopped
    // then we need to save their changes
    if (wasDragging && currentPage?.startTime && currentPage?.endTime) {
      saveOrbit();
    }

    if (domElement) {
      domElement.style.cursor = 'default';
      dragging.current = false;
      setMarkerActive(false);
      setShowHandle(false);
      setActiveManipulator(null);
      onDragEnd();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    setMarkerActive,
    getOrbitCOE,
    domElement,
    id,
    name,
    setActiveManipulator,
    updateOrbit,
    currentPage?.startTime,
    currentPage?.endTime,
  ]);

  const handleDomMouseMove = useCallback(
    (event: MouseEvent) => {
      if (dragging.current && plane && handle) {
        setPickPosition(event);

        if (raycaster.params.Line && raycaster.params.Points) {
          raycaster.params.Line.threshold = 10;
          raycaster.params.Points.threshold = 10;
        }

        // get the hit position on the plane
        if (camera) raycaster.setFromCamera(mousePickPosition, camera);

        const planeIntersect = intersectObjectWithRay(plane, raycaster, true);
        if (planeIntersect) {
          // get current mouse drag plane hit position
          pointOnPlaneEnd.copy(planeIntersect.point).sub(plane.position);

          // get the total delta position on the plane (this is delta from mouse down position)
          pointOnPlaneOffset.copy(pointOnPlaneEnd).sub(pointOnPlaneStart);

          moveManipulator();
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [camera, handle, intersectObjectWithRay, plane, raycaster, setPickPosition],
  );

  const handleHandlePointerDown = useCallback(
    (event: ThreeEvent<PointerEvent>) => {
      if (!handle || !plane) return;

      setPickPosition(event.nativeEvent);

      // get the hit position on the plane
      if (camera) raycaster.setFromCamera(mousePickPosition, camera);

      const planeIntersect = intersectObjectWithRay(plane, raycaster, true);

      if (planeIntersect) {
        positionStart.copy(handle.position); // start position of handle

        pointOnPlaneStart // click position on plane
          .copy(planeIntersect.point)
          .sub(plane.position);

        positionPlaneLast.copy(pointOnPlaneStart); // store last position
      }

      setActiveManipulator(markerName);
      dragging.current = true;
      if (domElement) domElement.style.cursor = 'grabbing';
      setMarkerActive(true);

      onDragStart();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      camera,
      setMarkerActive,
      domElement,
      handle,
      intersectObjectWithRay,
      markerName,
      mousePickPosition,
      plane,
      pointOnPlaneStart,
      positionPlaneLast,
      positionStart,
      setActiveManipulator,
      setPickPosition,
    ],
  );

  const handleHandleClick = useCallback(() => {
    if (plane && handle) {
      // rotate the plane for the active handle
      plane.updateTransform(camera, handle);
    }
  }, [camera, handle, plane]);

  useEffect(() => {
    if (domElement) {
      domElement.addEventListener('mouseleave', handleDomMouseLeave);
      domElement.addEventListener('mouseup', handleDomMouseUp);
      domElement.addEventListener('mousemove', handleDomMouseMove);

      return function cleanup() {
        if (domElement) {
          domElement.removeEventListener('mouseleave', handleDomMouseLeave);
          domElement.removeEventListener('mouseup', handleDomMouseUp);
          domElement.removeEventListener('mousemove', handleDomMouseMove);
        }
      };
    }
  }, [domElement, handleDomMouseLeave, handleDomMouseMove, handleDomMouseUp]);

  useFrame(() => {
    if (handle) {
      handle.getWorldPosition(tempVector);

      // This handles autosizing of the manipulator handles
      const scaleFactor =
        tempVector.distanceTo(camera.position) *
        Math.min((1.9 * Math.tan((Math.PI * camera.fov) / 360)) / camera.zoom, 1);

      // don't let handle get too big or too small
      const maxScaleFactor = clamp(scaleFactor, config.handleMinScale, config.handleMaxScale);

      handle.scale.set(1, 1, 1).multiplyScalar(maxScaleFactor * scale);

      // calculate text offset vector
      tempVector2
        .copy(handle.position)
        .sub(camera.position)
        .cross(upVector)
        .normalize()
        .multiplyScalar(0.5);

      updateSpriteText();
      updateSprite({ tempVector2 });
      updateBounds();
      updateTransform({ dragging: dragging.current, upVector });
    }
  });

  useEffect(() => {
    if (textSprite) {
      setSprite(textSprite);
    }
  }, [setSprite, textSprite]);

  return (
    <group
      ref={setGroup}
      name={`${name} ${markerName} Marker Manipulator Group`}
      visible={isPropagating ? false : selected}
    >
      <mesh
        ref={setBounds}
        name={`${name} ${markerName} Marker Manipulator Bounds`}
        onPointerOver={enablePointerEvents() ? handleBoundsPointerOver : undefined}
        onPointerOut={enablePointerEvents() ? handleBoundsPointerOut : undefined}
        onPointerUp={enablePointerEvents() ? handleBoundsPointerUp : undefined}
        visible={false}
      >
        <sphereGeometry args={[0.5, 12, 12]} />
        <meshBasicMaterial
          color={0xffff00}
          side={DoubleSide}
        />
      </mesh>
      <mesh
        ref={setHandle}
        userData={{ name: 'raan' }}
        name={`${name} ${markerName} Marker Manipulator Handle`}
        /* dont check enablePointerEvents() for pointer down
            since MarkerPrioritizer takes care of rendering priority marker
        */
        onPointerDown={handleHandlePointerDown}
        onClick={enablePointerEvents() ? handleHandleClick : undefined}
        visible={showHandle}
      >
        {children}
      </mesh>
      <ManipulatorPlane
        name={markerName}
        ref={setPlane}
        rotation={manipulatorPlaneRotation}
      />
      <sprite
        ref={setTextSprite}
        args={[spriteMaterial]}
        position={spritePosition}
        scale={spriteScale}
        name={`${name} ${markerName} Marker Manipulator Text Sprite`}
        visible={showHandle}
      />
    </group>
  );
};

export default MarkerManipulator;
