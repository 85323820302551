import { useCallback, useMemo, useRef, useState } from 'react';
import { earthradius } from 'src/constants';
import MarkerManipulator, { MarkerMoveProps } from 'src/threejs/components/MarkerManipulator';
import {
  UpdateSpriteProps,
  UpdateTransformProps,
} from 'src/threejs/components/MarkerManipulator/MarkerManipulator';
import AltHandle from 'src/threejs/components/Orbit/markers/SmaPerigeeMarker/AltHandle';
import {
  createGetOrbitCOE,
  createGetOrbitData,
} from 'src/threejs/components/OrbitManager/store/getters';
import useCamera from 'src/threejs/hooks/useCamera';
import { COE } from 'src/threejs/models/COE';
import ManipulatorPlaneModel from 'src/threejs/models/ManipulatorPlane';
import { MarkerHandleProps } from 'src/types';
import { AdditiveBlending, Group, Mesh, Sprite, Vector3 } from 'three';
import { use3DOrbitContext } from '../../context';

const config = {
  incHandleOffset: 0.4,
  smaHandlePerigeeOffset: 0.7, // .25,
  incHandleRotationSpeed: 3,
};

const SmaPerigeeHandle = ({ handle, setHandle }: MarkerHandleProps) => {
  const { id } = use3DOrbitContext();
  const camera = useCamera();

  const tempVector = useMemo(() => new Vector3(0, 0, 0), []);
  const tempVector3 = useMemo(() => new Vector3(0, 0, 0), []);

  const getOrbitCOE = useMemo(() => createGetOrbitCOE(id), [id]);
  const getOrbitData = useMemo(() => createGetOrbitData(id), [id]);

  const showAlt = useRef<boolean>(false);

  const textSpriteProps = useMemo(
    () => ({
      fontsize: 40,
      borderColor: { r: 255, g: 255, b: 255, a: 1.0 },
      backgroundColor: { r: 255, g: 255, b: 255, a: 0.1 },
      textColor: { r: 255, g: 255, b: 0, a: 0.75 },
      borderThickness: 0,
      sizeAttenuation: false,
    }),
    [],
  );

  const [group, setGroup] = useState<Group | null>(null);
  const [bounds, setBounds] = useState<Mesh | null>(null);
  const [sprite, setSprite] = useState<Sprite | null>(null);
  const [plane, setPlane] = useState<ManipulatorPlaneModel | null>(null);

  const makeSpriteName = (coe: COE) => `a: ${coe.semiMajorAxis.toFixed(1)}`;

  const updateHandlePosition = useCallback(
    (upVector: Vector3) => {
      const orbitData = getOrbitData();
      if (!handle) return;
      // offset handle from perigee position
      tempVector.copy(orbitData.majorAxisVector).multiplyScalar(config.smaHandlePerigeeOffset);

      // move handle to offset position
      handle.position.copy(orbitData.perigeePosition).add(tempVector);

      // use the y-up vector because the handle is a y-up cone and align to sma
      handle.quaternion.setFromUnitVectors(upVector, orbitData.majorAxisVector.clone().normalize());
    },
    [getOrbitData, handle, tempVector],
  );

  const move = useCallback(
    ({ pointOnPlaneOffset, tempVector, tempVector2, positionStart, upVector }: MarkerMoveProps) => {
      const coe = getOrbitCOE();
      const orbitData = getOrbitData();
      //   const handle = handleRef.current;

      // modify offset so along the major axis vector
      pointOnPlaneOffset.projectOnVector(orbitData.majorAxisVector);

      // delta move the handle along the new line vector
      tempVector3.copy(pointOnPlaneOffset).add(positionStart);

      // offset vector from perigee pos (this causes offset)
      tempVector2.copy(orbitData.majorAxisVector).multiplyScalar(config.smaHandlePerigeeOffset);

      // ** update orbit semi major axis
      tempVector.copy(tempVector3).sub(orbitData.primeFocusPosition);
      tempVector.sub(tempVector2); // account for the offset

      const perigeeDist = tempVector.length();

      // TODO: consolidate constants
      const scaleFactor = earthradius;

      // coe should be true scale
      const perigeeUpdate = perigeeDist * scaleFactor;

      // calculate new semi major axis
      const semiMajorAxis = perigeeUpdate / (1 - coe.eccentricity);

      return { semiMajorAxis };
    },
    [getOrbitCOE, getOrbitData, tempVector3],
  );

  const updateBounds = () => {
    if (bounds && handle) {
      const orbitData = getOrbitData();

      tempVector.copy(orbitData.majorAxisVector).multiplyScalar(config.smaHandlePerigeeOffset);

      // bounds.position.copy(orbit.orbitData.perigeePosition);
      bounds.position.copy(orbitData.perigeePosition).add(tempVector); // move bounds to offsetted handle position
    }
  };

  const updateSprite = ({ tempVector2 }: UpdateSpriteProps) => {
    if (sprite && handle) {
      const orbitData = getOrbitData();

      tempVector.copy(orbitData.majorAxisVector).normalize().multiplyScalar(0.5);

      // move sma text
      sprite.position.copy(handle.position).add(tempVector2).add(tempVector);
    }
  };

  const updateTransform = ({ upVector }: UpdateTransformProps) => {
    if (handle && plane) {
      updateHandlePosition(upVector);

      // rotate the plane for the active handle
      plane.updateTransform(camera, handle);
    }
  };

  return (
    <>
      <MarkerManipulator
        move={move}
        textSpriteMessage="sma: "
        textSpriteProps={textSpriteProps}
        makeSpriteName={makeSpriteName}
        markerName="SMA"
        setHandle={setHandle}
        handle={handle}
        setGroup={setGroup}
        setBounds={setBounds}
        setSprite={setSprite}
        setPlane={setPlane}
        plane={plane}
        group={group}
        scale={0.2}
        updateBounds={updateBounds}
        updateSprite={updateSprite}
        updateTransform={updateTransform}
        onDragEnd={() => (showAlt.current = false)}
        onDragStart={() => (showAlt.current = true)}
      >
        <coneGeometry args={[0.1, 0.2, 4, 1]} />
        <meshBasicMaterial
          color={0xffff00}
          depthTest={false}
          depthWrite={false}
          transparent={true}
          opacity={0.5}
          toneMapped={false}
          blending={AdditiveBlending}
        />
      </MarkerManipulator>
      <AltHandle visible={showAlt} />
    </>
  );
};

export default SmaPerigeeHandle;
