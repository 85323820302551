import { SpaceObjectShape, SpaceObjectSize } from 'src/types';

const ObjMaterial = ({ color }: { color: string }) => (
  <meshBasicMaterial
    opacity={0.85}
    depthWrite={false}
    transparent
    color={color}
  />
);

export const SpaceObjectScales: Record<SpaceObjectShape, Record<SpaceObjectSize, any>> = {
  [SpaceObjectShape.BALL]: {
    [SpaceObjectSize.SM]: [1, 9],
    [SpaceObjectSize.MD]: [2, 18],
    [SpaceObjectSize.LG]: [4, 27],
  },
  [SpaceObjectShape.CONE]: {
    [SpaceObjectSize.SM]: [1.3, 3, 15],
    [SpaceObjectSize.MD]: [2.2, 5.5, 18],
    [SpaceObjectSize.LG]: [4, 8, 18],
  },
  [SpaceObjectShape.CUBE]: {
    [SpaceObjectSize.SM]: [2, 2, 2],
    [SpaceObjectSize.MD]: [3, 3, 3],
    [SpaceObjectSize.LG]: [4, 4, 4],
  },
};

export const SpaceObjectShapes: Record<
  SpaceObjectShape,
  React.FC<{
    objScale: any;
    objColor: string;
    isPropagating?: boolean;
  }>
> = {
  [SpaceObjectShape.BALL]: (props) => (
    <mesh
      renderOrder={props.isPropagating ? 1 : 999}
      scale={props.isPropagating ? [0.5, 0.5, 0.5] : [1, 1, 1]}
    >
      <sphereGeometry args={props.objScale} />
      <ObjMaterial color={props.objColor} />
    </mesh>
  ),
  [SpaceObjectShape.CONE]: (props) => (
    <mesh
      renderOrder={props.isPropagating ? 1 : 999}
      rotation={[0, 0, -Math.PI / 2]}
      scale={props.isPropagating ? [0.5, 0.5, 0.5] : [1, 1, 1]}
    >
      <coneGeometry args={props.objScale} />
      <ObjMaterial color={props.objColor} />
    </mesh>
  ),
  [SpaceObjectShape.CUBE]: (props) => (
    <mesh
      renderOrder={props.isPropagating ? 1 : 999}
      scale={props.isPropagating ? [0.5, 0.5, 0.5] : [1, 1, 1]}
    >
      <boxGeometry args={props.objScale} />
      <ObjMaterial color={props.objColor} />
    </mesh>
  ),
};
