import { useMutation, useQueryClient } from '@tanstack/react-query';
import { getActivePageId, useRouteStore } from 'src/pages/App/routes/store';
import { fetchApi } from 'src/services/api';
import { useApiSnackbarError } from './SnackbarHooks';
import { SpaceSensor } from '../types';
import { SpaceSensorPointingType } from 'src/enums';
import {
  WINDOWS_TYPES,
  useViewingWindowsStore,
} from 'src/pages/Notebook/components/ViewingWindowsStore';

export function useCreateSpaceSensor() {
  const pageId = useRouteStore(getActivePageId);

  const apiSnackbarError = useApiSnackbarError();

  const queryClient = useQueryClient();
  return useMutation(
    ({
      orbitId,
      minGroundElevationAngle,
      fieldOfView,
      targetIlluminated,
      enabled,
      targetGroundObjectId,
      additionalProperties,
      pointingType = SpaceSensorPointingType.NadirPointing,
    }: Partial<SpaceSensor>) => {
      return fetchApi('/v2/space-sensors', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          orbitId,
          minGroundElevationAngle,
          fieldOfView,
          targetIlluminated,
          enabled,
          targetGroundObjectId,
          additionalProperties,
          pointingType,
        }),
      });
    },
    {
      onError: () => {
        apiSnackbarError('Failed to create space sensor.');
      },
      onSuccess: async () => {
        await queryClient.invalidateQueries(['orbitsForPage', Number(pageId)]);
      },
    },
  );
}

export function useUpdateSpaceSensor() {
  const pageId = useRouteStore(getActivePageId);

  const invalidateWindows = useViewingWindowsStore((state) => state.invalidateWindows);

  const apiSnackbarError = useApiSnackbarError();

  const queryClient = useQueryClient();
  return useMutation(
    (updates: Partial<SpaceSensor>) => {
      return fetchApi(`/v2/space-sensors/${updates.id}`, {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          ...updates,
        }),
      });
    },
    {
      onError: () => {
        apiSnackbarError('Failed to update space sensor.');
      },
      onSuccess: async (data) => {
        await queryClient.invalidateQueries(['orbitsForPage', Number(pageId)]);

        invalidateWindows(WINDOWS_TYPES.SPACE_SENSOR, data.orbitId);
      },
    },
  );
}
