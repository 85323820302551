import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { fetchApi } from 'src/services/api';
import { Position } from 'src/types';
import { useApiSnackbarError } from './SnackbarHooks';

export function useSunPosition(time: Date, options?: UseQueryOptions<Position>, ignore?: boolean) {
  const apiSnackbarError = useApiSnackbarError();

  return useQuery<Position>(
    ['sunPosition', time.toISOString()],
    () =>
      ignore
        ? Promise.resolve()
        : fetchApi(
            `/v2/sunPosition?${new URLSearchParams({
              time: time.toISOString(),
            })}`,
          ),
    {
      onError: () => {
        apiSnackbarError('Failed to get sun position.');
      },
      ...options,
    },
  ).data;
}
