import { useAuth0 } from '@auth0/auth0-react';
import { LogoutRounded, ManageAccountsRounded, PersonRounded } from '@mui/icons-material';
import { Avatar, Button, ButtonBase, Menu, MenuItem } from '@mui/material';
import { grey } from '@mui/material/colors';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import { useOnboardingMeQuery } from 'src/hooks/OnboardingHooks';

export const MenuBarProfile = () => {
  const { isAuthenticated, logout } = useAuth0();

  const myOrgsRoles = useOnboardingMeQuery();

  const isAnAdmin =
    myOrgsRoles && myOrgsRoles.organizations.some((org) => org.roles.includes('admin'));

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleOpen = (event: React.MouseEvent<HTMLButtonElement | HTMLDivElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Avatar
        component={ButtonBase}
        onClick={handleOpen}
        sx={{
          height: 36,
          width: 36,
          bgcolor: grey[700],
        }}
      >
        <PersonRounded />
      </Avatar>

      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={open}
        onClose={handleClose}
      >
        {isAnAdmin && (
          <MenuItem
            component={Link}
            to="/settings/"
          >
            <Button
              size="small"
              startIcon={<ManageAccountsRounded />}
            >
              Manage Users
            </Button>
          </MenuItem>
        )}

        <MenuItem onClick={handleClose}>
          {isAuthenticated && (
            <Button
              size="small"
              startIcon={<LogoutRounded />}
              onClick={() =>
                logout({
                  logoutParams: {
                    returnTo: window.location.origin,
                  },
                })
              }
            >
              Logout
            </Button>
          )}
        </MenuItem>
      </Menu>
    </>
  );
};
