import { EditRounded, TextFieldsRounded } from '@mui/icons-material';
import { SvgIcon } from '@mui/material';
import { useState } from 'react';
import { LABModalAction, LABModalActionType } from 'src/enums';
import { useUpdatePageAdditionalProperties } from 'src/hooks/PageHooks';
import { Page, PageAdditionalProperties } from 'src/types';
import ModalNotebookPage from '../ModalNotebookPage';
import { ObjectItem } from './ObjectItem';
import { ToggleObjectAdditionalProperty } from './ToggleObjectAdditionalProperty';

interface ObjectItemPageProps {
  page: Page;
}

export const ObjectItemPage = ({ page }: ObjectItemPageProps) => {
  const [modalType, setModalType] = useState<LABModalActionType>(LABModalAction.NULL);

  const updatePageAdditionalProperties = useUpdatePageAdditionalProperties();

  const handleChange = (changes: Partial<PageAdditionalProperties>) => {
    updatePageAdditionalProperties(page, changes);
  };

  return (
    <>
      <ModalNotebookPage
        action={modalType}
        onClose={() => setModalType(LABModalAction.NULL)}
        editingPage={page}
      />
      <ObjectItem
        name={page.name}
        autoHideItemOptions
        itemOptions={[
          <ToggleObjectAdditionalProperty
            tooltip="Object Label Visibility"
            enabled={!!page.additionalProperties?.visOrbitLabels}
            key="visibility"
            icon={{
              icon: <TextFieldsRounded />,
            }}
            onChange={() => {
              handleChange({
                visOrbitLabels: !page.additionalProperties?.visOrbitLabels,
              });
            }}
          />,

          <SvgIcon
            key="edit"
            onClick={(event) => {
              event.stopPropagation();
              setModalType(LABModalAction.EDIT);
            }}
            sx={{
              display: 'grid',
              fontSize: 15,
              cursor: 'pointer',
            }}
          >
            <EditRounded />
          </SvgIcon>,
        ]}
      />
    </>
  );
};
