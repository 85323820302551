import {
  PublicRounded,
  VisibilityOffRounded,
  VisibilityRounded,
  WorkspacesRounded,
} from '@mui/icons-material';
import { Grid } from '@mui/material';
import { useCallback, useEffect, useMemo } from 'react';
import { DEFAULT_ADDITIONAL_PROPERTIES_PAGE } from 'src/constants';
import {
  useCurrentGroundObject,
  useCurrentGroundObjects,
  useUpdateGroundObject,
} from 'src/hooks/GroundObjectHooks';
import {
  useCurrentGroup,
  useCurrentGroups,
  useUpdateGroupAdditionalProperties,
} from 'src/hooks/GroupHooks';
import {
  useCurrentOrbit,
  useCurrentOrbits,
  useSelectOrbit,
  useUpdateOrbitMutation,
} from 'src/hooks/OrbitHooks';
import { useCurrentPage, useUpdateCurrentPageAdditionalProperties } from 'src/hooks/PageHooks';
import { router } from 'src/pages/App/routes/Router';
import {
  getActiveCapabilityId,
  getActiveGroundObjectId,
  getActiveGroupId,
  getActiveNotebookId,
  getActiveObjectId,
  getActivePageId,
  getActiveSpecialId,
  useRouteStore,
} from 'src/pages/App/routes/store';
import { OverflowTip } from 'src/pages/Shared/OverflowTip';
import use3DOrbitStore from 'src/threejs/components/OrbitManager/store/store';
import {
  GroundObject,
  GroupAdditionalProperties,
  GroupObject,
  OrbitObject,
  PageAdditionalProperties,
} from 'src/types';
import { sortByNameAlphabetical } from 'src/utilities/ArrayUtils';
import { isGroundObject, isOrbitObject } from 'src/utilities/TypeUtils';
import { ObjectItem } from './ListPanel/ObjectItem';
import { ToggleObjectAdditionalProperty } from './ListPanel/ToggleObjectAdditionalProperty';
import { TreeBranchGround } from './ListPanel/TreeBranchGround';
import { TreeBranchOrbit } from './ListPanel/TreeBranchOrbit';
import { TreeBranch, TreeBranchDrop } from './ListPanel/TreeDND/TreeBranch';

export const OBJECT_TYPES = {
  GROUND: 'ground',
  GROUP: 'group',
  ORBIT: 'orbit',
  SPECIAL: 'special',
};

export const ObjectListPanel = ({ onCreateNewGroup }: any) => {
  const currentPageOrbits = useCurrentOrbits();
  const currentPageGroundObjects = useCurrentGroundObjects();
  const currentPageGroundObject = useCurrentGroundObject();
  const currentOrbit = useCurrentOrbit();
  const currentGroup = useCurrentGroup();
  const setCurrentOrbit = useSelectOrbit();
  const currentPageGroups = useCurrentGroups();
  const updateOrbitMutation = useUpdateOrbitMutation();
  const updateGroundObjectMutation = useUpdateGroundObject();
  const currentPage = useCurrentPage();
  const activeSpecialId = useRouteStore(getActiveSpecialId);
  const orbitsInStore = use3DOrbitStore((state) => state.orbits);
  const updateCurrentPageAdditionalProperties = useUpdateCurrentPageAdditionalProperties();

  /* utilities to update PAGE additionalProperties */
  const additionalPropertiesPage =
    currentPage?.additionalProperties || DEFAULT_ADDITIONAL_PROPERTIES_PAGE;

  const pageEarthVisible = additionalPropertiesPage.visEarth;

  const handleChangeAdditionalPropertiesPage = (changes: Partial<PageAdditionalProperties>) => {
    updateCurrentPageAdditionalProperties(changes);
  };

  /* utilities to update GROUP additionalProperties */
  const updateGroupAdditionalProperties = useUpdateGroupAdditionalProperties();

  const handleChangeAdditionalPropertiesGroup = (
    group: GroupObject,
    changes: Partial<GroupAdditionalProperties>,
  ) => {
    updateGroupAdditionalProperties(group, changes);
  };

  useEffect(() => {
    const selectedOrbitInCurrentOrbits =
      currentOrbit && currentPageOrbits?.map((orbit) => orbit.id).includes(currentOrbit.id);
    if (
      !activeSpecialId &&
      !currentGroup &&
      !currentPageGroundObject &&
      currentPageOrbits &&
      currentPageOrbits.length > 0 &&
      (!currentOrbit || !selectedOrbitInCurrentOrbits)
    ) {
      setCurrentOrbit(currentPageOrbits[0].id);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage, currentPageOrbits]);

  const updateGroupIdOrbitInStore = useCallback(
    (orbitId: number, groupId: number | null) => {
      orbitsInStore[orbitId].updateOrbitGroupId(groupId);
    },
    [orbitsInStore],
  );

  const addObjectToGroup = useCallback(
    (objToUpdate: OrbitObject | GroundObject, newGroupId: number | null) => {
      if (currentPage) {
        const { id, name } = objToUpdate;
        if (!id) return;

        const objUpdate: Partial<OrbitObject> | Partial<GroundObject> = {
          id,
          name,
          groupId: newGroupId,
        };

        if (isOrbitObject(objToUpdate)) {
          updateGroupIdOrbitInStore(id, newGroupId);
          updateOrbitMutation.mutate(objUpdate as Partial<OrbitObject>);
        } else if (isGroundObject(objToUpdate)) {
          updateGroundObjectMutation.mutate(objUpdate as GroundObject);
        }
      }
    },
    [currentPage, updateOrbitMutation, updateGroundObjectMutation, updateGroupIdOrbitInStore],
  );

  const createNewGroupWithObjects = useCallback(
    async (objects: (OrbitObject | GroundObject)[]) => {
      const newGroup = await onCreateNewGroup();
      const newGroupId = newGroup.id;

      const orbitUpdates: Partial<OrbitObject>[] = objects.filter(isOrbitObject).map((obj) => ({
        id: obj.id,
        name: obj.name,
        groupId: newGroupId,
      }));

      const groundObjectUpdates: Partial<GroundObject>[] = objects
        .filter(isGroundObject)
        .map((obj) => ({
          id: obj.id,
          name: obj.name,
          groupId: newGroupId,
        }));

      if (orbitUpdates) {
        orbitUpdates.forEach((orbitObj: Partial<OrbitObject>) => {
          updateGroupIdOrbitInStore(orbitObj.id!, newGroupId);
          updateOrbitMutation.mutate(orbitObj);
        });
      }
      if (groundObjectUpdates) {
        groundObjectUpdates.forEach((groundObj: Partial<GroundObject>) =>
          updateGroundObjectMutation.mutate(groundObj),
        );
      }

      return null;
    },
    [onCreateNewGroup, updateOrbitMutation, updateGroundObjectMutation, updateGroupIdOrbitInStore],
  );

  const getGroupOrbitIndexer = useCallback((objects: (OrbitObject | GroundObject)[]) => {
    const newIndexer: Record<number, (OrbitObject | GroundObject)[]> = {};
    objects.forEach((obj) => {
      if (!obj.groupId) return;
      if (!newIndexer[obj.groupId]) {
        newIndexer[obj.groupId] = [];
      }
      newIndexer[obj.groupId].push(obj);
    });
    Object.values(newIndexer).forEach(sortByNameAlphabetical);
    return newIndexer;
  }, []);

  const orbitsAndGroundObjects: (OrbitObject | GroundObject)[] = useMemo(
    () => [...(currentPageOrbits || []), ...(currentPageGroundObjects || [])],
    [currentPageOrbits, currentPageGroundObjects],
  );
  const groupObjects = getGroupOrbitIndexer(orbitsAndGroundObjects || []);
  const grouplessObjects = useMemo(
    () => orbitsAndGroundObjects.filter((obj) => !obj.groupId),
    [orbitsAndGroundObjects],
  );

  const currentObjectId = useRouteStore(getActiveObjectId);
  const currentGroupId = useRouteStore(getActiveGroupId);
  const currentGroundId = useRouteStore(getActiveGroundObjectId);
  const currentSpecialId = useRouteStore(getActiveSpecialId);
  const currentNotebookId = useRouteStore(getActiveNotebookId);
  const currentPageId = useRouteStore(getActivePageId);
  const currentCapabilityId = useRouteStore(getActiveCapabilityId);

  const isActive = useCallback(
    (type: string, id: number | string) => {
      if (
        (type === 'ground' && currentGroundId === id) ||
        (type === 'group' && currentGroupId === id) ||
        (type === 'orbit' && currentObjectId === id) ||
        (type === 'special' && currentSpecialId === id)
      ) {
        return true;
      }
    },
    [currentGroundId, currentGroupId, currentObjectId, currentSpecialId],
  );

  const handleOnSelect = useCallback(
    (path: string) => {
      let baseURL = `/notebook/${currentNotebookId}/${currentPageId}`;
      if (currentCapabilityId) {
        baseURL = `/shared/${currentCapabilityId}${baseURL}`;
      }

      if (router.state.location.pathname === `${baseURL}/${path}`) {
        router.navigate(baseURL);
      } else {
        router.navigate(`${baseURL}/${path}`);
      }
    },
    [currentCapabilityId, currentNotebookId, currentPageId],
  );

  const getObjectFromTypeAndId = (type: string, id: string | number) => {
    if (type === OBJECT_TYPES.ORBIT) {
      return currentPageOrbits?.find((obj) => `${obj.id}` === `${id}`);
    } else if (type === OBJECT_TYPES.GROUND) {
      return currentPageGroundObjects?.find((obj) => `${obj.id}` === `${id}`);
    } else if (type === OBJECT_TYPES.GROUP) {
      return currentPageGroups?.find((obj) => `${obj.id}` === `${id}`);
    }
  };

  const handleOnDrop = ({ dragId, dragType, dropId, dropType }: TreeBranchDrop) => {
    const objDrag = getObjectFromTypeAndId(dragType, dragId) as OrbitObject | GroundObject;
    const objDrop = getObjectFromTypeAndId(dropType, dropId);

    if (objDrag) {
      if (dropType === OBJECT_TYPES.SPECIAL) {
        addObjectToGroup(objDrag, null);
      } else if (dropType === OBJECT_TYPES.GROUP) {
        if (objDrop?.id) {
          addObjectToGroup(objDrag, objDrop.id);
        }
      } else if ([OBJECT_TYPES.ORBIT, OBJECT_TYPES.GROUND].includes(dragType)) {
        if (objDrop) {
          createNewGroupWithObjects([objDrag, objDrop as OrbitObject | GroundObject]);
        }
      }
    }
  };

  const handleOnExpand = (groupId: number) => {
    const group = getObjectFromTypeAndId(OBJECT_TYPES.GROUP, groupId) as GroupObject;
    if (group?.additionalProperties) {
      handleChangeAdditionalPropertiesGroup(group, {
        expanded: !group.additionalProperties.expanded,
      });
    }
  };

  return (
    <>
      <TreeBranch
        id="earth"
        type={OBJECT_TYPES.SPECIAL}
        droppable
        active={isActive('special', 'earth')}
        onDrop={handleOnDrop}
        onSelect={() => handleOnSelect('special/earth')}
        main={
          <ObjectItem
            name="Earth"
            itemOptions={[
              <ToggleObjectAdditionalProperty
                tooltip="Earth Visibility"
                enabled={pageEarthVisible}
                key="visibility"
                icon={{
                  iconOn: <VisibilityRounded />,
                  iconOff: <VisibilityOffRounded />,
                }}
                onChange={() =>
                  handleChangeAdditionalPropertiesPage({ visEarth: !pageEarthVisible })
                }
              />,
            ]}
          />
        }
        icon={<PublicRounded />}
        style={{
          height: '100%',
        }}
      >
        {currentPageGroups &&
          sortByNameAlphabetical(currentPageGroups)?.map((group) => {
            return (
              <TreeBranch
                key={`${OBJECT_TYPES.GROUP}_${group.id}`}
                id={group.id}
                type={OBJECT_TYPES.GROUP}
                active={isActive('group', group.id)}
                onDrop={handleOnDrop}
                onExpand={handleOnExpand}
                onSelect={() => handleOnSelect(`group/${group.id}`)}
                droppable
                expandable
                expanded={group.additionalProperties?.expanded}
                main={
                  <ObjectItem
                    name={
                      <Grid
                        display="grid"
                        gridAutoFlow="column"
                        alignItems="baseline"
                        gap={1}
                        pr={1}
                      >
                        <OverflowTip text={group.name} />

                        <span
                          style={{
                            opacity: 0.5,
                            fontStyle: 'italic',
                          }}
                        >
                          ({groupObjects[group.id]?.length || 0})
                        </span>
                      </Grid>
                    }
                    itemOptions={[
                      <ToggleObjectAdditionalProperty
                        tooltip="Group Visibility"
                        enabled={!!group.additionalProperties?.visOrbits}
                        key="visibility"
                        icon={{
                          iconOn: <VisibilityRounded />,
                          iconOff: <VisibilityOffRounded />,
                        }}
                        onChange={() => {
                          handleChangeAdditionalPropertiesGroup(group, {
                            visOrbits: !group.additionalProperties?.visOrbits,
                          });
                        }}
                      />,
                    ]}
                  />
                }
                icon={<WorkspacesRounded />}
              >
                {groupObjects[group.id]?.map((child) => {
                  if (isOrbitObject(child)) {
                    return (
                      <TreeBranchOrbit
                        key={`${OBJECT_TYPES.ORBIT}_${child.id}`}
                        type={OBJECT_TYPES.ORBIT}
                        active={isActive('orbit', child.id)}
                        onDrop={handleOnDrop}
                        onSelect={() => handleOnSelect(`${child.id}`)}
                        group={group}
                        orbitObject={child}
                      />
                    );
                  }

                  if (isGroundObject(child)) {
                    return (
                      <TreeBranchGround
                        key={`${OBJECT_TYPES.GROUND}_${child.id}`}
                        type={OBJECT_TYPES.GROUND}
                        active={isActive('ground', child.id || '')}
                        onDrop={handleOnDrop}
                        onSelect={() => handleOnSelect(`ground-object/${child.id}`)}
                        group={group}
                        groundObject={child}
                      />
                    );
                  }

                  return null;
                })}
              </TreeBranch>
            );
          })}

        {sortByNameAlphabetical(grouplessObjects).map((child) => {
          if (isOrbitObject(child)) {
            return (
              <TreeBranchOrbit
                key={`${OBJECT_TYPES.ORBIT}_${child.id}`}
                type={OBJECT_TYPES.ORBIT}
                active={isActive('orbit', child.id)}
                onDrop={handleOnDrop}
                onSelect={() => handleOnSelect(`${child.id}`)}
                orbitObject={child}
              />
            );
          }

          if (isGroundObject(child)) {
            return (
              <TreeBranchGround
                key={`${OBJECT_TYPES.GROUND}_${child.id}`}
                type={OBJECT_TYPES.GROUND}
                active={isActive('ground', child.id || '')}
                onDrop={handleOnDrop}
                onSelect={() => handleOnSelect(`ground-object/${child.id}`)}
                groundObject={child}
              />
            );
          }

          return null;
        })}
      </TreeBranch>
    </>
  );
};
