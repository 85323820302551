import { RocketLaunchRounded } from '@mui/icons-material';
import { Grid } from '@mui/material';
import { LAUNCH_FLYOUT_STATUS } from 'src/constants';
import { useUpdateTimelineRange } from 'src/core/hooks';
import { useCurrentOrbits, useIsPropagating } from 'src/hooks/OrbitHooks';
import { useCurrentPage } from 'src/hooks/PageHooks';
import PropagatedCacheManager from 'src/models/PropagatedCacheManager';
import { useGroundObjectLaunchEditStore } from 'src/threejs/components/GroundObjects/GroundObjectLaunchEditStore';
import { SlideOutPanel } from '../SlideoutPanel/SlideOutPanel';
import { SlideoutPanelItem } from '../SlideoutPanel/SlideoutPanelItem';

interface SlideOutPanelEventsProps {
  addPanelOpen: boolean;
  onClickOutside?: () => void;
  ignoreClassName?: string;
}

export const SlideOutPanelEvents = ({
  addPanelOpen,
  onClickOutside,
  ignoreClassName,
}: SlideOutPanelEventsProps) => {
  const currentPage = useCurrentPage();
  const currentOrbits = useCurrentOrbits();
  const setLaunchEditMode = useGroundObjectLaunchEditStore((state) => state.setLaunchEditMode);
  const updateTimelineRange = useUpdateTimelineRange();

  const isPropagating = useIsPropagating();

  const onCreateNewLaunch = () => {
    if (!isPropagating && currentPage?.startTime) {
      updateTimelineRange({
        currentTime: currentPage.startTime.getTime(),
        currentTimePreviewMode: currentPage.startTime.getTime(),
        playState: 'seeking',
      });
      if (currentPage && currentOrbits) {
        PropagatedCacheManager.propagateTimeline(currentPage, currentOrbits, true);
      }
    }

    setLaunchEditMode(LAUNCH_FLYOUT_STATUS.CREATING);
  };

  return (
    <Grid
      position="absolute"
      left="100%"
      bottom="0"
      overflow="hidden"
    >
      <SlideOutPanel
        title="Add Event"
        open={addPanelOpen}
        onClickOutside={onClickOutside}
        ignoreClassName={ignoreClassName}
      >
        <div onClick={onCreateNewLaunch}>
          <SlideoutPanelItem>
            <RocketLaunchRounded fontSize="inherit" />
            Launch
          </SlideoutPanelItem>
        </div>
      </SlideOutPanel>
    </Grid>
  );
};
