import { Vector2 } from 'three';

function getUVCoordsFromLatLon(lon: number, lat: number) {
  const u = lon / 360 + 0.5;
  const v = Math.abs(lat / 180 - 0.5);

  return { uv: new Vector2(u, v) };
}

export default getUVCoordsFromLatLon;
