import { ExpandMoreRounded as ExpandMoreIcon } from '@mui/icons-material';
import { Accordion, AccordionDetails, AccordionSummary, Grid } from '@mui/material';
import { OrbitTrackLengthSlider } from './OrbitTrackLengthSlider';

export function OrbitTrackOptions() {
  return (
    <Accordion defaultExpanded>
      <AccordionSummary
        style={{
          flexDirection: 'row-reverse',
        }}
        expandIcon={<ExpandMoreIcon />}
        aria-controls="inspector-panel-visual"
        id="inspector-panel-visual"
      >
        3D Track
      </AccordionSummary>
      <AccordionDetails>
        <Grid
          container
          px={2}
        >
          <OrbitTrackLengthSlider is3D />
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
}
