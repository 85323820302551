import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { DEFAULT_ADDITIONAL_PROPERTIES_VIEWPORT } from 'src/constants';
import { getActivePageId, useRouteStore } from 'src/pages/App/routes/store';
import { fetchApi } from 'src/services/api';
import useViewportStore from 'src/threejs/components/ViewportManager/store';
import { Viewport, ViewportAdditionalProperties } from 'src/types';
import { useApiSnackbarError } from './SnackbarHooks';

const deserializeViewport = (viewport: Viewport): Viewport => {
  return {
    ...viewport,
    additionalProperties: {
      ...DEFAULT_ADDITIONAL_PROPERTIES_VIEWPORT,
      ...viewport.additionalProperties,
    },
  };
};

function useViewportsQuery(pageId: number) {
  const createViewportInStore = useViewportStore((state) => state.createViewport);

  const apiSnackbarError = useApiSnackbarError();

  return useQuery<Viewport[]>(
    ['viewportsForPage', pageId],
    async () => {
      const results = await fetchApi(`/v2/viewports?pageId=${pageId}`);

      // initialize viewport in local store
      results.forEach((viewport: Viewport) => createViewportInStore(viewport));

      // should always be sorted by position for convenience
      return results
        .map((viewport: Viewport) => deserializeViewport(viewport))
        .sort((a: Viewport, b: Viewport) => a.position - b.position);
    },
    {
      enabled: Boolean(pageId),
      onError: () => {
        apiSnackbarError('Failed to get viewport(s).');
      },
    },
  );
}

export function useViewports(pageId: number) {
  const { data } = useViewportsQuery(pageId);
  return data;
}

export function useCurrentViewports() {
  const pageId = useRouteStore(getActivePageId);
  return useViewports(pageId);
}

export function useCreateViewport() {
  const pageId = useRouteStore(getActivePageId);

  const apiSnackbarError = useApiSnackbarError();

  const queryClient = useQueryClient();
  return useMutation(
    ({ position, type }: Partial<Viewport>) => {
      return fetchApi('/v2/viewports', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          pageId,
          position,
          type,
        }),
      });
    },
    {
      onError: () => {
        apiSnackbarError('Failed to create viewport.');
      },
      onSuccess: async () => {
        await queryClient.invalidateQueries(['viewportsForPage', pageId]);
      },
    },
  );
}

export function useUpdateViewport() {
  const pageId = useRouteStore(getActivePageId);

  const apiSnackbarError = useApiSnackbarError();

  const queryClient = useQueryClient();
  return useMutation(
    ({ id, type, position, additionalProperties }: Partial<Viewport>) => {
      return fetchApi(`/v2/viewports/${id}`, {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          pageId,
          position,
          type,
          additionalProperties,
        }),
      });
    },
    {
      onError: () => {
        apiSnackbarError('Failed to update viewport.');
      },
      onSuccess: async () => {
        await queryClient.invalidateQueries(['viewportsForPage', pageId]);
      },
    },
  );
}

export function useDeleteViewport() {
  const pageId = useRouteStore(getActivePageId);

  const apiSnackbarError = useApiSnackbarError();

  const queryClient = useQueryClient();
  return useMutation(
    ({ id }: Partial<Viewport>) => {
      return fetchApi(`/v2/viewports/${id}`, {
        method: 'DELETE',
        headers: { 'Content-Type': 'application/json' },
      });
    },
    {
      onError: () => {
        apiSnackbarError('Failed to delete viewport.');
      },
      onSuccess: async () => {
        await queryClient.invalidateQueries(['viewportsForPage', pageId]);
      },
    },
  );
}

export function useUpdateViewportAdditionalProperties() {
  const updateViewport = useUpdateViewport();

  return (viewport: Viewport, changes: Partial<ViewportAdditionalProperties>) => {
    if (viewport) {
      const viewportUpdate: Partial<Viewport> = {
        ...viewport,
        additionalProperties: {
          ...DEFAULT_ADDITIONAL_PROPERTIES_VIEWPORT,
          ...viewport.additionalProperties,
          ...changes,
        },
      };
      updateViewport.mutateAsync(viewportUpdate);
    }
  };
}
