import { useFrame } from '@react-three/fiber';
import { MeshLineGeometry } from 'meshline';
import { useState } from 'react';
import { ORBIT_COLOR_DEFAULT } from 'src/constants';
import { getCurrentTime } from 'src/core/getters';
import { Mesh, Vector3 } from 'three';
import { inverseLerp } from 'three/src/math/MathUtils';
import { useOrbitRicStore } from './orbitRicStore';

interface OrbitPathRicProps {
  orbitIdOrigin: number;
  orbitIdTarget: number;
  orbitPathColor?: string;
}

export const OrbitPathRic = ({
  orbitIdOrigin,
  orbitIdTarget,
  orbitPathColor = ORBIT_COLOR_DEFAULT,
}: OrbitPathRicProps) => {
  const [meshRef, setMeshRef] = useState<Mesh | null>(null);

  const orbitData = useOrbitRicStore((state) => state.orbitData?.[orbitIdOrigin]?.[orbitIdTarget]);

  const points = orbitData?.stateVectors.map((ricSV) => {
    return ricSV.sv;
  });
  const times = orbitData?.stateVectors.map((ricSV) => {
    return ricSV.time;
  });

  const orbitTimeStart = orbitData?.stateVectors[0].time || 0;

  const stepSize = orbitData?.stateVectors[1].time - orbitData?.stateVectors[0].time;

  useFrame(() => {
    if (points) {
      const currentTime = getCurrentTime() / 1000;
      const endIndex = Math.min(
        Math.floor((currentTime - orbitTimeStart) / stepSize),
        points.length - 1,
      );
      const nextIndex = Math.min(endIndex + 1, points.length - 1);
      const blend = inverseLerp(times[endIndex], times[nextIndex], currentTime);

      const pointCurrent = new Vector3().lerpVectors(points[endIndex], points[nextIndex], blend);

      if (meshRef) {
        meshRef.geometry.dispose();

        const geometry = new MeshLineGeometry();
        geometry.setPoints([...points.slice(0, endIndex), pointCurrent]);
        meshRef.geometry = geometry;
      }
    }
  });

  if (!orbitData) {
    return null;
  }

  return (
    <mesh ref={setMeshRef}>
      <meshLineMaterial
        lineWidth={0.0025}
        color={orbitPathColor}
        sizeAttenuation={false}
      />
    </mesh>
  );
};
