import { useRef, useCallback, useEffect, forwardRef } from 'react';
import { Grid, SxProps, Theme } from '@mui/material';
import { useCurrentOrbit, useIsPropagating } from 'src/hooks/OrbitHooks';
import PropagatedCacheManager from 'src/models/PropagatedCacheManager';
import use3DOrbitStore from 'src/threejs/components/OrbitManager/store/store';
import { OrbitStoreState } from 'src/threejs/components/OrbitManager/store/types';
import theme from 'src/pages/App/Theme';

type LiveDataTableItemProps = {
  /** The header name to be displayed to the user. */
  name: string;
  /** The unit to be displayed accross from the header. */
  headerUnit: string;
  /** The unit to be displayed for the COE values and the difference. */
  unit: string;
  /** The initial value which is more or less represented by the preview mode value for the orbit */
  initialValue: number;
  /** The function which gets the newest value to display from the global store's active state vector */
  svValueSelector: (orbitState: OrbitStoreState) => number;
};

interface TableItemNumberProps {
  styles?: SxProps<Theme>;
  children?: React.ReactNode;
}

const TableItemNumber = forwardRef((props: TableItemNumberProps, ref: any) => {
  return (
    <Grid
      sx={{
        fontSize: 12,
        fontFeatureSettings: '"tnum"',
        ...props.styles,
      }}
      ref={ref}
    >
      {props.children}
    </Grid>
  );
});

export const LiveDataTableItem = ({
  name,
  unit,
  headerUnit,
  initialValue,
  svValueSelector,
}: LiveDataTableItemProps) => {
  const currentOrbit = useCurrentOrbit();
  const isPropagating = useIsPropagating();

  const formatValue = useCallback(
    (val: number) =>
      `${new Intl.NumberFormat('en-US', {
        maximumFractionDigits: 2,
        minimumFractionDigits: 2,
      }).format(val)} ${unit ?? ''}`,
    [unit],
  );

  const formatDeltaValue = useCallback(
    (value: number) => {
      const fixedValue = value.toFixed(2);
      const wasFixedToZero = fixedValue === '-0.00' || fixedValue === '0.00';
      const isGreaterThanZero = value > 0;

      if (wasFixedToZero) {
        return formatValue(0);
      } else {
        if (isGreaterThanZero) {
          return `+${formatValue(value)}`;
        } else {
          return formatValue(value);
        }
      }
    },
    [formatValue],
  );

  const activeSvValue = useRef<HTMLDivElement | null>(null);
  const svDifference = useRef<HTMLDivElement | null>(null);

  // update the propagation state vector current value and delta display refs
  const onActiveSvChange = useCallback(
    (currentValue: number) => {
      if (activeSvValue?.current)
        activeSvValue.current.innerText = isPropagating ? formatValue(currentValue) : '--';
      if (svDifference?.current)
        svDifference.current.innerText = isPropagating
          ? formatDeltaValue(currentValue - initialValue)
          : '--';
    },
    [isPropagating, initialValue, formatValue, formatDeltaValue],
  );

  useEffect(() => {
    // subscribe to changes made to the active state vector within the global store
    // call the selector function to update the display refs with the most recent version of the value
    return use3DOrbitStore.subscribe(svValueSelector, onActiveSvChange);
  }, [onActiveSvChange, currentOrbit, svValueSelector]);

  useEffect(() => {
    const currentVal = svValueSelector(use3DOrbitStore.getState());
    if (isPropagating) {
      onActiveSvChange(currentVal);
    } else {
      if (activeSvValue?.current) activeSvValue.current.innerText = '--';
      if (svDifference?.current) svDifference.current.innerText = '--';
    }
  }, [isPropagating, onActiveSvChange, svValueSelector]);

  useEffect(() => {
    const resetState = () => {
      if (activeSvValue?.current) activeSvValue.current.innerText = '--';
      if (svDifference?.current) svDifference.current.innerText = '--';
    };

    PropagatedCacheManager.addEventListener('clearpropagations', resetState);

    return function cleanup() {
      PropagatedCacheManager.removeEventListener('clearpropagations', resetState);
    };
  }, [onActiveSvChange]);

  return (
    <Grid
      pt={1}
      flexDirection="column"
      alignItems="space-between"
      container
    >
      <Grid
        container
        justifyContent="space-between"
      >
        <Grid
          fontWeight="bold"
          fontSize={12}
        >
          {name}
        </Grid>
        <Grid
          fontSize={12}
          color={theme.palette.text.secondary}
        >
          {headerUnit}
        </Grid>
      </Grid>
      <Grid
        container
        justifyContent="space-between"
      >
        <Grid
          mr={1}
          color={theme.palette.text.secondary}
          fontSize={12}
        >
          Initial
        </Grid>
        <TableItemNumber styles={{ color: theme.palette.grey[400] }}>
          {formatValue(initialValue)}
        </TableItemNumber>
      </Grid>
      <Grid
        container
        justifyContent="space-between"
      >
        <Grid
          color={theme.palette.text.secondary}
          fontSize={12}
        >
          Current
        </Grid>
        <TableItemNumber
          ref={activeSvValue}
          styles={isPropagating ? { color: 'primary.main' } : { color: theme.palette.grey[400] }}
        />
      </Grid>

      <Grid
        container
        justifyContent="space-between"
      >
        <Grid
          color={theme.palette.text.secondary}
          fontSize={12}
        >
          Delta
        </Grid>
        <TableItemNumber
          ref={svDifference}
          styles={isPropagating ? { color: 'primary.main' } : { color: theme.palette.grey[400] }}
        />
      </Grid>
    </Grid>
  );
};
