import { Coordinate } from 'src/types';
import { Spherical, Vector3 } from 'three';
import { radToDeg } from 'three/src/math/MathUtils';

/** takes the satellite position and returns lat/long coordinate in texture */
export const getLatLongFromPointProjection = (
  pos: Vector3,
  earthPos: Vector3,
  earthRot: number,
): Coordinate => {
  // get surface position on Earth Fixed coords
  const tempVector = new Vector3();
  const tempSphrCoord = new Spherical();

  tempVector.copy(pos).sub(earthPos).normalize(); // assuming earth radius = 1

  // transform the input position by earth rotation here so it's inertial frame
  tempVector.applyAxisAngle(new Vector3(0, 1, 0), -earthRot);

  // convert position to spherical coordinates
  tempSphrCoord.setFromVector3(tempVector);

  const latitude = 90 - radToDeg(tempSphrCoord.phi);
  const longitude = radToDeg(tempSphrCoord.theta);

  return {
    latitude: latitude,
    longitude: longitude,
  };
};
