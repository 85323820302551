import {
  ContentCopyRounded,
  DeleteRounded as DeleteIcon,
  ExpandMoreRounded as ExpandMoreIcon,
  WarningRounded,
} from '@mui/icons-material';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Typography,
} from '@mui/material';
import { differenceInMilliseconds } from 'date-fns';
import { DateTime } from 'luxon';
import { memo, useEffect, useMemo, useState } from 'react';
import {
  COE_ORBIT_TYPE_DISPLAY,
  COE_PARAMETERS,
  CONTENT,
  DATE_TIME_DISPLAY_FORMAT,
  ORBIT_TEMPLATES,
  ORBIT_TEMPLATE_HASHES,
  ORBIT_TYPES,
  SV_ORBIT_TYPE_DISPLAY,
  TEXT_TLE_WARNING,
  TLE_ORBIT_TYPE_DISPLAY,
  WEEK_IN_MILLISECONDS,
} from 'src/constants';
import { ItemType, LABOrbitType } from 'src/enums';
import { useCurrentOrbit, useIsPropagating } from 'src/hooks/OrbitHooks';
import { useCurrentPage } from 'src/hooks/PageHooks';
import { useIsReadOnly } from 'src/hooks/SharedNotebookHooks';
import { useDebouncedCallback } from 'src/hooks/useDebouncedCallback';
import { ModalClearPropagation } from 'src/pages/Notebook/ModalClearPropagation';
import { ModalOrbitDuplicate } from 'src/pages/Notebook/ModalOrbitDuplicate';
import { ModalDeleteObject } from 'src/pages/Notebook/components/ModalDeleteObject';
import {
  useCurrent3DOrbit,
  useCurrent3DOrbitCoeUpdater,
  useCurrent3DOrbitIntersectsWithEarth,
  useCurrent3DOrbitName,
  useSaveCurrentOrbit,
} from 'src/threejs/components/OrbitManager/store/hooks';
import use3DOrbitStore from 'src/threejs/components/OrbitManager/store/store';
import { COE } from 'src/threejs/models/COE';
import { getTleEpochDate } from 'src/utilities/DateTimeUtils';
import { CoeParameter } from './CoeParameter';
import { TLETextDisableInput, TabPanelWarningLabel } from './InspectorPanel.styled';
import { OrbitPropertiesCartesian } from './InspectorPanel/OrbitPropertiesCartesian';
import { InputNameEditor } from './NameInputEditor';
import { PerturbationControl } from './PerturbationControl';
import { useOrbitTypeChange } from './useOrbitTypeChange';

const OrbitTemplateSelect = memo(() => {
  const isPropagating = useIsPropagating();
  const [template, setTemplate] = useState<(typeof LABOrbitType)[number]>(LABOrbitType[0]);
  const [templateUpdate, setTemplateUpdate] = useState(0);
  const currentOrbit = useCurrentOrbit();
  const saveCurrentOrbit = useSaveCurrentOrbit();

  const [current3DOrbit] = useCurrent3DOrbit();
  const updateCOE = useCurrent3DOrbitCoeUpdater();

  const setCOEs = use3DOrbitStore((state) => (updater: any) => {
    state.set((state) => {
      if (currentOrbit) {
        updater(state.orbits[currentOrbit.id].coe);
      }
    });
  });

  useEffect(() => {
    if (currentOrbit && template !== 'Custom') {
      const [
        semiMajorAxis,
        eccentricity,
        inclination,
        rightAscensionOfAscendingNode,
        argumentOfPeriapsis,
        trueAnomaly,
      ] = ORBIT_TEMPLATES[template];

      setCOEs((coe: COE) => {
        Object.assign(coe, {
          semiMajorAxis,
          eccentricity,
          inclination,
          rightAscensionOfAscendingNode,
          argumentOfPeriapsis,
          trueAnomaly,
        });
      });

      if (updateCOE) {
        updateCOE({
          semiMajorAxis,
          eccentricity,
          inclination,
          rightAscensionOfAscendingNode,
          argumentOfPeriapsis,
          trueAnomaly,
        });
      }
      saveCurrentOrbit();
    }

    // Track template updates insted of the template value. This allows to the user to modify a template, get the "Custom" indicator, and then select again the same previous one to reset it.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [templateUpdate]);

  return (
    <FormControl fullWidth>
      <InputLabel id="orbit-template-label">Orbit template</InputLabel>
      <Select
        disabled={isPropagating}
        value={(() => {
          const coe = current3DOrbit?.coe;
          if (!coe) return 'Custom';
          const matchKey = ORBIT_TEMPLATE_HASHES.get(
            [
              coe.semiMajorAxis,
              coe.eccentricity,
              coe.inclination,
              coe.rightAscensionOfAscendingNode,
              coe.argumentOfPeriapsis,
              coe.trueAnomaly,
            ].join(),
          );
          return matchKey ? matchKey : 'Custom';
        })()}
        onChange={(event: SelectChangeEvent<unknown>) => {
          setTemplate(event.target.value as (typeof LABOrbitType)[number]);
          setTemplateUpdate(templateUpdate + 1);
        }}
        labelId="orbit-template-label"
        label="Orbit Template"
      >
        {LABOrbitType.map((type) => (
          <MenuItem
            key={type}
            value={type}
            disabled={type === 'Custom'}
          >
            {type}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
});

export const TabPanelOrbitProperties = () => {
  const isPropagating = useIsPropagating();
  const isReadOnly = useIsReadOnly();
  const currentOrbit = useCurrentOrbit();

  const [deleteOrbitModalOpen, setDeleteOrbitModal] = useState(false);

  const orbitIntersectEarth = useCurrent3DOrbitIntersectsWithEarth();
  const hasConditionEscapeVelocity = use3DOrbitStore(
    (state) => currentOrbit && state.orbits[currentOrbit.id].orbitData.hasConditionEscapeVelocity,
  );

  const saveCurrentOrbit = useSaveCurrentOrbit();
  const [saveCurrentOrbitDebounced] = useDebouncedCallback(saveCurrentOrbit, 500);

  const [modalClearPropVisible, setModalClearPropVisible] = useState(false);
  const [modalOrbitCopyVisible, setModalOrbitCopyVisible] = useState(false);

  const isTle = currentOrbit?.orbitType === ORBIT_TYPES.TLE;
  const isKeplerian = currentOrbit?.orbitType === ORBIT_TYPES.COE;
  const isEphemeris = currentOrbit?.orbitType === ORBIT_TYPES.EPHEMERIS;
  const isLaunchOrbit = currentOrbit?.orbitType === ORBIT_TYPES.LAUNCH;

  const orbitTypeChange = useOrbitTypeChange();
  const onOrbitTypeChange = async (event: SelectChangeEvent) => {
    const newOrbitType = event.target.value as keyof typeof ORBIT_TYPES;
    await orbitTypeChange(newOrbitType);
  };

  const [name, setName] = useCurrent3DOrbitName();

  const checkPropagation = () => {
    if (isPropagating && !isReadOnly) {
      setModalClearPropVisible(true);
    }
  };

  const currentPage = useCurrentPage();
  const tleEpochDate = useMemo(
    () => (isTle ? getTleEpochDate(currentOrbit.orbitTLE.line1) : new Date()),
    [currentOrbit?.orbitTLE?.line1, isTle],
  );
  const outdatedTLE =
    isTle &&
    currentPage?.startTime &&
    Math.abs(differenceInMilliseconds(tleEpochDate, currentPage.startTime)) > WEEK_IN_MILLISECONDS;

  return (
    <>
      <ModalClearPropagation
        show={modalClearPropVisible}
        onClose={() => setModalClearPropVisible(false)}
      />
      {currentOrbit && (
        <ModalOrbitDuplicate
          orbit={currentOrbit}
          show={modalOrbitCopyVisible}
          onClose={() => setModalOrbitCopyVisible(false)}
        />
      )}

      <ModalDeleteObject
        open={deleteOrbitModalOpen}
        show={setDeleteOrbitModal}
        item={currentOrbit}
        itemType={ItemType.Orbit}
      />

      <Grid
        container
        direction="column"
      >
        <Accordion defaultExpanded>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            style={{
              flexDirection: 'row-reverse',
            }}
            aria-controls="inspector-panel-details"
            id="inspector-panel-details"
          >
            Details
          </AccordionSummary>
          <AccordionDetails>
            <InputNameEditor
              name={name}
              setName={setName}
              label="Orbit Name"
              placeholder="Orbit Name"
              saveName={saveCurrentOrbitDebounced}
            />
          </AccordionDetails>
        </Accordion>

        {!isEphemeris && !isLaunchOrbit && (
          <Accordion defaultExpanded>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              style={{
                flexDirection: 'row-reverse',
              }}
              aria-controls="inspector-panel-coe"
              id="inspector-panel-coe"
            >
              <Grid
                container
                maxHeight="max-content"
                justifyContent="space-between"
                alignItems="center"
              >
                <Grid
                  item
                  xs={3}
                  display="flex"
                  gap={0.5}
                >
                  <Grid item>
                    <FormControl variant="standard">
                      <Select
                        value={currentOrbit?.orbitType}
                        onOpen={(e) => e.stopPropagation()}
                        onClose={(e) => e.stopPropagation()}
                        onChange={onOrbitTypeChange}
                        label="Orbit Type"
                      >
                        <MenuItem value={ORBIT_TYPES.COE}>{COE_ORBIT_TYPE_DISPLAY}</MenuItem>
                        <MenuItem value={ORBIT_TYPES.STATE_VECTORS}>
                          {SV_ORBIT_TYPE_DISPLAY}
                        </MenuItem>
                        {currentOrbit?.orbitType === ORBIT_TYPES.TLE && (
                          <MenuItem value={ORBIT_TYPES.TLE}>{TLE_ORBIT_TYPE_DISPLAY}</MenuItem>
                        )}
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>
              </Grid>
            </AccordionSummary>
            <AccordionDetails>
              <Grid
                container
                onClick={checkPropagation}
                flexDirection="column"
              >
                {isTle && (
                  <TLETextDisableInput
                    fullWidth
                    disabled
                    multiline
                    rows={10}
                    id="text-field-TLE-input-display"
                    value={
                      [
                        currentOrbit.orbitTLE.titleLine,
                        currentOrbit.orbitTLE.line1,
                        currentOrbit.orbitTLE.line2,
                      ].join('\n') || 'Invalid TLE'
                    }
                  />
                )}

                {isKeplerian && <OrbitTemplateSelect />}

                {orbitIntersectEarth && (
                  <Typography
                    color="error"
                    my={1}
                    mx={1}
                  >
                    {CONTENT.ERRORS.ORBIT.EARTH_INTERSECT}
                  </Typography>
                )}
                {hasConditionEscapeVelocity && (
                  <Typography
                    color="error"
                    my={1}
                    mx={1}
                  >
                    {CONTENT.ERRORS.ORBIT.ESCAPE_VELOCITY}
                  </Typography>
                )}

                {isKeplerian &&
                  COE_PARAMETERS.map((coeParameter) => (
                    <CoeParameter
                      key={coeParameter.name}
                      name={coeParameter.name}
                      displayName={coeParameter.displayName}
                      notation={coeParameter.notation}
                      tooltipText={coeParameter.tooltipText}
                      type={coeParameter.type}
                      unit={coeParameter.unit}
                      step={coeParameter.step}
                      min={coeParameter.min}
                      max={coeParameter.max}
                      selector={coeParameter.selector}
                      saveCurrentOrbit={saveCurrentOrbitDebounced}
                    />
                  ))}
                {currentOrbit?.orbitType === ORBIT_TYPES.STATE_VECTORS && (
                  <OrbitPropertiesCartesian />
                )}
              </Grid>
            </AccordionDetails>
          </Accordion>
        )}

        {!isEphemeris && !isLaunchOrbit && !isTle && (
          <PerturbationControl onClick={checkPropagation} />
        )}

        {!isEphemeris && isTle && currentOrbit.orbitTLE?.line1 && (
          <Accordion defaultExpanded>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              style={{
                flexDirection: 'row-reverse',
              }}
              aria-controls="TLE-epoch-display"
            >
              TLE Epoch
            </AccordionSummary>
            <AccordionDetails>
              <TLETextDisableInput
                fullWidth
                disabled
                value={DateTime.fromJSDate(tleEpochDate, {
                  zone: 'utc',
                }).toFormat(DATE_TIME_DISPLAY_FORMAT)}
              />
              {outdatedTLE && (
                <>
                  <TabPanelWarningLabel>
                    <WarningRounded
                      color="warning"
                      sx={{ marginRight: 1, width: '16px' }}
                    />
                    {TEXT_TLE_WARNING}
                  </TabPanelWarningLabel>
                </>
              )}
            </AccordionDetails>
          </Accordion>
        )}

        <Grid
          container
          direction="column"
          alignItems="center"
        >
          <Button
            variant="contained"
            onClick={() => setModalOrbitCopyVisible(true)}
            aria-label="Duplicate Orbit"
            startIcon={<ContentCopyRounded />}
            sx={{
              m: 2,
            }}
          >
            Duplicate Orbit
          </Button>
          <Button
            color="error"
            variant="contained"
            onClick={() => setDeleteOrbitModal(true)}
            aria-label="Delete Orbit"
            startIcon={<DeleteIcon />}
            sx={{
              m: 2,
            }}
          >
            Delete Orbit
          </Button>
        </Grid>
      </Grid>
    </>
  );
};
