import { ExpandMoreRounded } from '@mui/icons-material';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  Radio,
  RadioGroup,
  Switch,
  Typography,
} from '@mui/material';
import { useCallback, useMemo, useState } from 'react';
import { earthradius } from 'src/constants';
import { useIsPropagating } from 'src/hooks/OrbitHooks';
import { useUpdateSpaceSensor } from 'src/hooks/SpaceSensorsHooks';
import { useDebouncedCallback } from 'src/hooks/useDebouncedCallback';
import theme from 'src/pages/App/Theme';
import use3DOrbitStore from 'src/threejs/components/OrbitManager/store/store';
import { useSpaceSensorsStore } from 'src/threejs/components/SpaceSensors/SpaceSensorsStore';
import { SpaceSensor } from 'src/types';
import { calculateFieldOfRegard } from 'src/utilities/OrbitUtils';
import { formatValue } from 'src/utilities/StringUtils';
import { Vector3 } from 'three';
import { useEffectOnce } from 'usehooks-ts';
import { OBJECT_TYPES } from './ObjectListPanel';
import { ObjectPropertySlider } from './ObjectPropertySlider';
import { SelectGroupOrObject, SelectGroupOrObjectItem } from './SelectGroupOrObject';
import { SpaceSensorPointingType } from 'src/enums';
import { LabelWithTooltip } from 'src/pages/Shared/LabelWithTooltip';

interface TabPanelOrbitSensorsEditProps {
  spaceSensor: SpaceSensor;
}

export const TabPanelOrbitSensorsEdit = ({ spaceSensor }: TabPanelOrbitSensorsEditProps) => {
  const [checked, setChecked] = useState(spaceSensor.enabled || false);
  const [pointingType, setPointingType] = useState(
    spaceSensor.pointingType || SpaceSensorPointingType.NadirPointing,
  );
  const [minGroundElevationAngle, setMinGroundElevationAngle] = useState(
    spaceSensor.minGroundElevationAngle !== undefined
      ? `${spaceSensor.minGroundElevationAngle}`
      : '10',
  );
  const [fieldOfView, setFieldOfView] = useState(
    spaceSensor.fieldOfView !== undefined ? `${spaceSensor.fieldOfView}` : '10',
  );
  const [targetIlluminated, setTargetIlluminated] = useState(
    spaceSensor.targetIlluminated || false,
  );

  const setEditingSpaceSensor = useSpaceSensorsStore((state) => state.setEditignSpaceSensor);
  const updateEditingSpaceSensor = useSpaceSensorsStore((state) => state.updateEditingSpaceSensor);
  const removeEditingSpaceSensor = useSpaceSensorsStore((state) => state.removeEditingSpaceSensor);

  // Set the sensor as being edited in the store, and remove when unmounted
  useEffectOnce(() => {
    setEditingSpaceSensor(spaceSensor);
    return () => {
      removeEditingSpaceSensor(spaceSensor.id);
    };
  });

  // handle getting active altitude value
  const orbitFromStore = use3DOrbitStore((state) => state.orbits[spaceSensor.orbitId]);
  const position: Vector3 = new Vector3();
  const isPropagating = useIsPropagating();

  if (isPropagating && orbitFromStore.activeStateVector) {
    position.x = orbitFromStore.activeStateVector.y_position;
    position.y = orbitFromStore.activeStateVector.z_position;
    position.z = orbitFromStore.activeStateVector.x_position;
    position.divideScalar(1000);
  } else if (orbitFromStore.stateVectors) {
    position.x = orbitFromStore.stateVectors.yPosition;
    position.y = orbitFromStore.stateVectors.zPosition;
    position.z = orbitFromStore.stateVectors.xPosition;
  }

  const altitude = position.length() - earthradius;
  const altitudeFormatted = formatValue(Math.abs(altitude));

  const fieldOfRegardFormatted = formatValue(
    calculateFieldOfRegard(parseFloat(minGroundElevationAngle), altitude),
  );

  const selectedItem = useMemo(() => {
    if (spaceSensor.targetGroupId) {
      return {
        type: OBJECT_TYPES.GROUP,
        id: spaceSensor.targetGroupId,
      };
    } else if (spaceSensor.targetGroundObjectId) {
      return {
        type: OBJECT_TYPES.GROUND,
        id: spaceSensor.targetGroundObjectId,
      };
    }
    return null;
  }, [spaceSensor.targetGroundObjectId, spaceSensor.targetGroupId]);

  // save updates to sensor
  const updateSpaceSensor = useUpdateSpaceSensor();
  const useSaveSpaceSensor = useCallback(
    (updates: Partial<SpaceSensor>) => {
      updateSpaceSensor.mutate(updates);
    },
    [updateSpaceSensor],
  );

  const [saveCurrentSpaceSensor] = useDebouncedCallback(useSaveSpaceSensor, 500);

  const saveSpaceSensor = (updates: Partial<SpaceSensor>) => {
    const partial: Partial<SpaceSensor> = {
      ...spaceSensor,
      ...updates,
    };
    saveCurrentSpaceSensor(partial);
    updateEditingSpaceSensor(partial);
  };

  const onTargetChange = (result: SelectGroupOrObjectItem) => {
    const requestObj: Partial<SpaceSensor> = {
      ...spaceSensor,
      targetGroupId: null,
      targetGroundObjectId: null,
    };

    if (result.type === OBJECT_TYPES.GROUP) {
      requestObj.targetGroupId = Number(result.id);
    } else if (result.type === OBJECT_TYPES.GROUND) {
      requestObj.targetGroundObjectId = Number(result.id);
    }
    updateSpaceSensor.mutate(requestObj);
  };

  return (
    <Grid
      container
      p={0}
      pt={1}
      justifyContent="left"
    >
      <FormGroup sx={{ paddingBottom: 1, paddingLeft: 2 }}>
        <FormControlLabel
          label={'Observation Capability'}
          control={
            <Switch
              size="small"
              checked={checked}
              onChange={(event) => {
                const isEnabled = !checked;
                setChecked(isEnabled);
                saveSpaceSensor({ enabled: isEnabled });
              }}
            />
          }
        />
      </FormGroup>
      <Typography
        variant="body2"
        fontWeight="bold"
        whiteSpace="nowrap"
        sx={{ paddingLeft: 1, paddingTop: 1, width: '100%' }}
      >
        Pointing Type
      </Typography>
      <RadioGroup
        sx={{
          display: 'flex',
          flexDirection: 'row',
          paddingBottom: 1,
          paddingLeft: 2,
        }}
        value={pointingType}
        name="radio-buttons-import"
        onChange={(event) => {
          const newPointingType = (event.target as HTMLInputElement)
            .value as SpaceSensorPointingType;
          setPointingType(newPointingType);
          saveSpaceSensor({ pointingType: newPointingType });
        }}
        aria-labelledby="importMode-radio-buttons-group-label"
      >
        <FormControlLabel
          value={SpaceSensorPointingType.NadirPointing}
          control={
            <Radio
              size="small"
              disabled={!checked}
            />
          }
          label={
            <LabelWithTooltip
              label="Nadir Pointing"
              title="Sensor points down to the ground directly beneath it, toward the center of the Earth."
            />
          }
        />
        <FormControlLabel
          value={SpaceSensorPointingType.TargetTracking}
          control={
            <Radio
              size="small"
              disabled={!checked}
            />
          }
          label={
            <LabelWithTooltip
              label="Target Tracking"
              title="Sensor points directly to the ground point target."
            />
          }
        />
      </RadioGroup>
      <Grid
        container
        direction="column"
      >
        <ObjectPropertySlider
          disabled={!checked}
          value={`${minGroundElevationAngle}`}
          propertyName="Ground Elevation Angle"
          textErrState={(fieldNum) => fieldNum < 0 || fieldNum > 90}
          textFieldProps={{
            helperText: `Number ranging from ${0} to ${90}`,
          }}
          sliderProps={{
            min: 0,
            max: 90,
            style: { padding: 1 },
          }}
          onChange={(item: string) => {
            setMinGroundElevationAngle(item);
            const newVal = parseFloat(item);
            if (Number.isNaN(newVal)) return;
            saveSpaceSensor({ minGroundElevationAngle: parseFloat(item) });
          }}
        />
        <Grid
          sx={{
            fontSize: 12,
            py: 1,
            px: 2,
          }}
        >
          <Grid
            display="grid"
            gridAutoFlow="row"
            gap={1}
          >
            <Grid
              display="grid"
              gridAutoFlow="column"
              justifyContent="space-between"
            >
              <Grid color={theme.palette.text.secondary}>Altitude:</Grid>
              <Grid
                sx={{
                  fontFeatureSettings: '"tnum"',
                }}
              >
                {altitudeFormatted} km
              </Grid>
            </Grid>

            <Grid
              display="grid"
              gridAutoFlow="column"
              justifyContent="space-between"
            >
              <Grid color={theme.palette.text.secondary}>Field of Regard:</Grid>
              <Grid
                sx={{
                  fontFeatureSettings: '"tnum"',
                }}
              >
                {fieldOfRegardFormatted} deg
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <ObjectPropertySlider
          disabled={!checked}
          value={`${fieldOfView}`}
          propertyName="Field of View"
          textErrState={(fieldNum) => fieldNum < 0 || fieldNum > 90}
          textFieldProps={{
            helperText: `Number ranging from ${0} to ${90}`,
          }}
          sliderProps={{
            min: 0,
            max: 90,
            style: { padding: 1 },
          }}
          onChange={(item: string) => {
            setFieldOfView(item);
            const newVal = parseFloat(item);
            if (Number.isNaN(newVal)) return;
            saveSpaceSensor({ fieldOfView: newVal });
          }}
        />

        {/* Range value and calculations being pushed to future release:
        https://slingshotaero.slack.com/archives/C021L5MGD4Y/p1681306443973989?thread_ts=1681232217.081459&cid=C021L5MGD4Y

        <ObjectPropertySlider
          disabled={!checked}
          value={'8000'}
          propertyName="Sensor Range"
          textErrState={(fieldNum) => fieldNum < 0 || fieldNum > 100000}
          textFieldProps={{
            helperText: `Number ranging from ${0} to ${100000}`,
          }}
          sliderProps={{
            min: 0,
            max: 100000,
            style: { padding: 1 },
          }}
          onChange={(item: string) => {}}
        />
        */}

        <Accordion defaultExpanded>
          <AccordionSummary
            expandIcon={<ExpandMoreRounded />}
            style={{
              flexDirection: 'row-reverse',
            }}
            aria-controls="inspector-panel-details"
            id="inspector-panel-details"
          >
            Observation Target:
          </AccordionSummary>
          <AccordionDetails>
            <FormControl
              variant="standard"
              fullWidth
            >
              <SelectGroupOrObject
                disabled={!checked}
                includeGroups
                includeGroundObjects
                selectedItem={selectedItem}
                onChange={onTargetChange}
              />
            </FormControl>

            <FormGroup sx={{ padding: 1 }}>
              <FormControlLabel
                disabled={!checked}
                control={
                  <Switch
                    size="small"
                    checked={targetIlluminated}
                    onChange={(event) => {
                      const isEnabled = !targetIlluminated;
                      setTargetIlluminated(isEnabled);
                      saveSpaceSensor({ targetIlluminated: isEnabled });
                    }}
                  />
                }
                label={'Target Illuminated'}
              />
            </FormGroup>
          </AccordionDetails>
        </Accordion>
      </Grid>
    </Grid>
  );
};
