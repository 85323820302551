import { IconButton, InputAdornment, Popover, TextField } from '@mui/material';
import { LocalizationProvider, StaticDateTimePicker } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { ChangeEvent, ReactNode, useCallback, useEffect, useRef, useState } from 'react';
import { formatDateWithMs, getDateFromDateStrMs } from 'src/utilities/DateTimeUtils';

// regex allows for a date that is very specifically in the format of "mm-dd-yyy hh:mm:ss.fff" e.g. 10-04-2022 13:39:38.000
const DATE_TIME_REGEX =
  /(0[1-9]|1[0-2])-(0[1-9]|1[0-9]|2[0-9]|3[0-1])-\d{4} ([0-1][0-9]|2[0-3]):([0-5][0-9]):([0-5][0-9])(\.\d{1,3})?/;

interface DateTimePickerProps {
  inputVal: string;
  onChange: (item: Date) => void;
  label?: string;
  pickerIcon: ReactNode;
  disabled?: boolean;
  minDate?: Date;
  maxDate?: Date;
}

const formatErrText = 'Date-time format must be MM-dd-yyyy HH:mm:ss.fff';
const invalidRangeErrText = 'Date must fall between start and/or end time(s)';

export const DateTimePicker = ({
  inputVal,
  onChange,
  label,
  pickerIcon,
  disabled,
  minDate,
  maxDate,
}: DateTimePickerProps) => {
  const [pickerDate, setPicker] = useState<Date | null>(new Date());

  const [open, setOpen] = useState(false);
  const anchorEl = useRef(null);
  const [textFieldVal, setVal] = useState(inputVal);
  const [err, setErr] = useState<null | string>(null);

  const isValidDateTimeRange = useCallback(
    (newDateStr: string) => {
      let validDate = true;
      const currentEpoch = getDateFromDateStrMs(newDateStr).getTime();
      if (minDate) validDate &&= minDate.getTime() <= currentEpoch;
      if (maxDate) validDate &&= maxDate.getTime() >= currentEpoch;

      return validDate;
    },
    [minDate, maxDate],
  );

  useEffect(() => {
    if (!disabled) {
      setPicker(getDateFromDateStrMs(inputVal));
      setVal(inputVal);
    }
  }, [inputVal, isValidDateTimeRange, minDate, disabled]);

  useEffect(() => {
    const valueToCheck = disabled ? inputVal : textFieldVal;
    if (!DATE_TIME_REGEX.test(valueToCheck)) {
      setErr(formatErrText);
    } else if (!isValidDateTimeRange(valueToCheck)) {
      setErr(invalidRangeErrText);
    } else {
      setErr(null);
    }
  }, [disabled, inputVal, isValidDateTimeRange, textFieldVal]);

  const onHandleBlur = useCallback(() => {
    if (DATE_TIME_REGEX.test(textFieldVal) && isValidDateTimeRange(textFieldVal)) {
      // re-format incase 0s were removed off the end
      setVal(formatDateWithMs(getDateFromDateStrMs(textFieldVal)));
      const newDateMs = getDateFromDateStrMs(textFieldVal);
      onChange(newDateMs);
      setPicker(newDateMs);
    } else if (minDate) {
      setVal(formatDateWithMs(minDate));
      onChange(minDate);
      setPicker(minDate);
    }
  }, [isValidDateTimeRange, minDate, onChange, textFieldVal]);

  const onHandleInputChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setVal(e.target.value);
  }, []);

  const onHandlePickerAccept = useCallback(
    (e: Date | null) => {
      setErr(null);
      setOpen(false);
      const newDateMs = getDateFromDateStrMs(formatDateWithMs(e!));
      onChange(newDateMs);
      setPicker(newDateMs);
    },
    [onChange],
  );

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <Popover
        marginThreshold={100}
        open={open}
        anchorEl={anchorEl?.current}
        onClose={() => {
          if (pickerDate) {
            const dateWithMs = formatDateWithMs(pickerDate);
            setVal(dateWithMs);
            onChange(getDateFromDateStrMs(dateWithMs));
          }
          setOpen(false);
        }}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <StaticDateTimePicker
          disabled={disabled}
          value={pickerDate}
          onChange={(newValue) => setPicker(newValue)}
          minDateTime={minDate}
          maxDateTime={maxDate}
          onError={(e) => setErr(e)}
          ampm={false}
          inputFormat="yyy-mm-dd hh:mm:ss"
          mask="____-__-__ __:__:__"
          renderInput={(params) => <TextField {...params} />}
          onAccept={onHandlePickerAccept}
          closeOnSelect
          componentsProps={{
            // dont show OK and Cancel button
            actionBar: {
              actions: [],
            },
          }}
          views={['year', 'month', 'day', 'hours', 'minutes', 'seconds']}
        />
      </Popover>
      <TextField
        sx={{
          width: '215px',
        }}
        disabled={disabled}
        error={!!err}
        helperText={err}
        ref={anchorEl}
        size="small"
        id="outlined-basic"
        label={label}
        onChange={onHandleInputChange}
        onBlur={onHandleBlur}
        value={!disabled ? textFieldVal : inputVal}
        variant="standard"
        inputProps={{
          sx: {
            fontFeatureSettings: '"tnum"',
          },
        }}
        InputProps={{
          endAdornment: (
            <InputAdornment
              position="end"
              sx={{
                m: 0,
              }}
            >
              <IconButton
                size="small"
                onClick={() => {
                  if (!disabled) {
                    setOpen(true);
                  }
                }}
              >
                {pickerIcon}
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
    </LocalizationProvider>
  );
};
