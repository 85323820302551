/* eslint-disable no-unused-vars */

import { Matrix4, Mesh, PerspectiveCamera, Vector3 } from 'three';

class ManipulatorPlane3D extends Mesh {
  public rotate: boolean;
  public updateTransform: (camera: PerspectiveCamera, handle: Mesh) => void;

  constructor(rotation = false) {
    super();

    this.name = 'ManipulatorPlane';
    this.rotate = rotation;

    // utility vectors
    // const unitX = new Vector3(1, 0, 0);
    // const unitY = new Vector3(0, 1, 0);
    // const unitZ = new Vector3(0, 0, 1);

    const xBasis = new Vector3();
    const yBasis = new Vector3();
    const zBasis = new Vector3();

    // const camVector = new Vector3();
    const dirVector = new Vector3();
    const alignVector = new Vector3();
    const tempVector = new Vector3();
    const tempMatrix = new Matrix4();

    // const cameraPosition = new Vector3();
    // const cameraQuaternion = new Quaternion();
    // const cameraScale = new Vector3();
    const eye = new Vector3();

    // Debug Visualizer
    // const axis = new AxesHelper(0.5);
    // const axisMat = new Matrix4();

    this.updateTransform = (camera, handle) => {
      // handle (manipulator) moves but orientation stays the same
      handle.updateMatrixWorld();
      handle.matrixWorld.extractBasis(xBasis, yBasis, zBasis);

      // the Y-Axis points in the direction of handle/major axis so use Y
      eye.copy(camera.position).sub(this.position).normalize();
      alignVector.copy(eye).cross(yBasis);
      dirVector.copy(yBasis).cross(alignVector);

      if (this.rotate === true) {
        // if we are rotating, make the plane parallel to camera
        // camera.matrixWorld.decomponse(pos, rot, scale)

        // this moves the plane to the handle
        this.position.copy(handle.position);

        // this is local so be careful.  Also this doesn't account for panning
        this.quaternion.copy(camera.quaternion);
      } else {
        // click plane aligned to handle but facing camera
        tempMatrix.lookAt(tempVector.set(0, 0, 0), dirVector, alignVector);
        this.quaternion.setFromRotationMatrix(tempMatrix);
      }

      /* // Debug Visualizer
            axisMat.makeBasis(xBasis, yBasis, zBasis);
            if (this.rotate === true) {
              axis.setRotationFromQuaternion(this.quaternion);
            } else {
              axis.setRotationFromMatrix(axisMat);
            }
            axis.position.copy(handle.position);
            this.parent.add(axis); // add manipulator root
            */
    };
  }
}

export default ManipulatorPlane3D;
