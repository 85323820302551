import { LAUNCH_FLYOUT_STATUS } from 'src/constants';
import { useGroundObjectLaunchEditStore } from 'src/threejs/components/GroundObjects/GroundObjectLaunchEditStore';
import { LaunchPreviewGroundObject } from './PreviewElements/LaunchPreviewGroundObject';

export const LaunchPreviewEarth = () => {
  const launchFlyoutStatus = useGroundObjectLaunchEditStore((state) => state.isLaunchEditMode);

  if (launchFlyoutStatus === LAUNCH_FLYOUT_STATUS.CLOSED) {
    return null;
  }

  return (
    <>
      <LaunchPreviewGroundObject />
    </>
  );
};
