import { Grid, styled } from '@mui/material';

export const ControlsContainer = styled(Grid)`
  background: ${(props) => props.theme.palette.background.paper};
  border-radius: 4px;
`;

export const ControlsCamera = styled('div')`
  position: absolute;
  top: 100%;
  left: 0;
  background: ${(props) => props.theme.palette.background.paper};
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  display: flex;
  flex-direction: column;
  box-shadow: 0px 4px 5px #202020;
`;
