import { Slide } from '@mui/material';
import { useRef } from 'react';
import { SETTINGS_NAMES, useSettingsStore } from 'src/pages/Settings/store';
import { ManeuverEditor } from './ManeuverEditor';
import { ManeuverRepropagator } from './Maneuvers/ManeuverRepropagator';

export const ManeuverFlyoutContainer = () => {
  const containerRef = useRef(null);

  const maneuverFlyoutStatus = useSettingsStore(
    (state) => state.custom[SETTINGS_NAMES.MANEUVERS_FLYOUT_STATUS],
  );

  return (
    <div
      className="ManeuverFlyoutContainer"
      ref={containerRef}
      style={{
        position: 'fixed',
        top: '150px',
        right: '0%',
        zIndex: 1,
      }}
    >
      <ManeuverRepropagator />
      <Slide
        direction="left"
        in={typeof maneuverFlyoutStatus !== 'undefined'}
        container={containerRef.current}
        mountOnEnter
        unmountOnExit
      >
        <div>
          <ManeuverEditor />
        </div>
      </Slide>
    </div>
  );
};
