import { AccordionDetails } from '@mui/material';
import { useEffect, useState } from 'react';
import { DEFAULT_ADDITIONAL_PROPERTIES_GROUND_OBJECTS } from 'src/constants';
import {
  useCurrentGroundObject,
  useUpdateGroundObjectAdditionalProperties,
} from 'src/hooks/GroundObjectHooks';
import { useDebouncedCallback } from 'src/hooks/useDebouncedCallback';
import {
  GroundObjectEditType,
  useGroundObjectLaunchEditStore,
} from 'src/threejs/components/GroundObjects/GroundObjectLaunchEditStore';
import { clamp } from 'three/src/math/MathUtils';
import { ObjectPropertySlider } from './ObjectPropertySlider';

export function GroundObjectWireframeOpacity() {
  const currentGroundObject = useCurrentGroundObject();

  const updateGroundObjAdditionalProperties = useUpdateGroundObjectAdditionalProperties();

  const [currentOpacity, setOpacity] = useState(
    currentGroundObject?.additionalProperties
      ? currentGroundObject.additionalProperties.coneWireframeOpacity
      : DEFAULT_ADDITIONAL_PROPERTIES_GROUND_OBJECTS.coneWireframeOpacity,
  );

  const setObjectShaderOpacity = (newOpacity: number) => {
    if (currentGroundObject) {
      const changes = { coneWireframeOpacity: newOpacity };
      updateGroundObjAdditionalProperties(currentGroundObject, changes);
    }
  };

  const [saveObjectAdditionalProperty] = useDebouncedCallback(setObjectShaderOpacity, 500);
  const minOpacity = 0;
  const maxOpacity = 100;

  useEffect(() => {
    if (!currentGroundObject?.id) return;
    const { groundObjectEdit, setGroundObjectEdit } = useGroundObjectLaunchEditStore.getState();
    if (!groundObjectEdit) return;

    const update: GroundObjectEditType = {
      ...groundObjectEdit,
      coneWireframeOpacity: currentOpacity,
    };
    setGroundObjectEdit(update);
  }, [currentOpacity, currentGroundObject?.id]);

  if (!currentGroundObject) {
    return null;
  }

  return (
    <AccordionDetails sx={{ pt: 2 }}>
      <ObjectPropertySlider
        value={`${currentOpacity}`}
        propertyName="Cone Wireframe Opacity"
        textErrState={(fieldNum) => fieldNum < minOpacity || fieldNum > maxOpacity}
        textFieldProps={{
          helperText: `Number ranging from ${minOpacity} to ${maxOpacity}`,
        }}
        sliderProps={{
          min: minOpacity,
          max: maxOpacity,
          style: { padding: 1 },
        }}
        onChange={(item: string) => {
          const opacityVal = clamp(parseInt(item), minOpacity, maxOpacity);
          saveObjectAdditionalProperty(opacityVal);
          setOpacity(opacityVal);
        }}
      />
    </AccordionDetails>
  );
}
