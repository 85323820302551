import { useCallback } from 'react';
import { Divider, Grid, Typography } from '@mui/material';
import { useCurrentOrbit } from 'src/hooks/OrbitHooks';
import { OrbitObjectStateVector, StateVectorType } from 'src/types';
import { LiveDataTableItem } from './LiveDataTableItem';
import { OrbitStoreState } from 'src/threejs/components/OrbitManager/store/types';
import use3DOrbitStore from 'src/threejs/components/OrbitManager/store/store';

type CartesianSvPosType = keyof Pick<StateVectorType, 'x_position' | 'y_position' | 'z_position'>;
type CartesianSvVelType = keyof Pick<StateVectorType, 'x_velocity' | 'y_velocity' | 'z_velocity'>;

export const LiveDataCartesian = () => {
  const currentOrbit = useCurrentOrbit();

  const getPositionVal = useCallback(
    (val: CartesianSvPosType) => {
      return (orbitState: OrbitStoreState) => {
        if (!currentOrbit?.id) return 0;
        const activeSv = orbitState.orbits[currentOrbit.id]?.activeStateVector;
        if (!activeSv) return 0;
        return activeSv[val] / 1000;
      };
    },
    [currentOrbit?.id],
  );

  const getVelocityVal = useCallback(
    (val: CartesianSvVelType) => {
      return (orbitState: OrbitStoreState) => {
        if (!currentOrbit?.id) return 0;
        const activeSv = orbitState.orbits[currentOrbit.id]?.activeStateVector;
        if (!activeSv) return 0;
        return activeSv[val] / 1000;
      };
    },
    [currentOrbit?.id],
  );

  const getXPos = getPositionVal('x_position');
  const getYPos = getPositionVal('y_position');
  const getZPos = getPositionVal('z_position');

  const getXVel = getVelocityVal('x_velocity');
  const getYVel = getVelocityVal('y_velocity');
  const getZVel = getVelocityVal('z_velocity');

  const orbitStateVector: OrbitObjectStateVector = use3DOrbitStore(
    (state) => state.orbits[currentOrbit!.id].stateVectors!,
  );

  return (
    <Grid
      pt={1}
      container
      flexDirection="column"
    >
      <Typography
        variant="h6"
        fontWeight="bold"
      >
        Position
      </Typography>
      <LiveDataTableItem
        unit=""
        headerUnit="km"
        name="X Position"
        svValueSelector={getXPos}
        initialValue={orbitStateVector.xPosition}
      />
      <LiveDataTableItem
        unit=""
        headerUnit="km"
        name="Y Position"
        svValueSelector={getYPos}
        initialValue={orbitStateVector.yPosition}
      />
      <LiveDataTableItem
        unit=""
        headerUnit="km"
        name="Z Position"
        svValueSelector={getZPos}
        initialValue={orbitStateVector.zPosition}
      />
      <Divider sx={{ marginTop: 1, marginBottom: 1 }} />
      <Typography
        variant="h6"
        fontWeight="bold"
      >
        Velocity
      </Typography>
      <LiveDataTableItem
        unit=""
        headerUnit="km"
        name="X Velocity"
        svValueSelector={getXVel}
        initialValue={orbitStateVector.xVelocity}
      />
      <LiveDataTableItem
        unit=""
        headerUnit="km"
        name="Y Velocity"
        svValueSelector={getYVel}
        initialValue={orbitStateVector.yVelocity}
      />
      <LiveDataTableItem
        unit=""
        headerUnit="km"
        name="Z Velocity"
        svValueSelector={getZVel}
        initialValue={orbitStateVector.zVelocity}
      />
    </Grid>
  );
};
