import { SpaceSensor } from 'src/types';
import { create } from 'zustand';
import { immer } from 'zustand/middleware/immer';

interface SpaceSensorsState {
  editingSpaceSensors: Record<number, SpaceSensor>;

  setEditignSpaceSensor: (spaceSensor: SpaceSensor) => void;
  updateEditingSpaceSensor: (updates: Partial<SpaceSensor>) => void;
  removeEditingSpaceSensor: (id: number) => void;
}

export const useSpaceSensorsStore = create<SpaceSensorsState>()(
  immer((set, get) => ({
    editingSpaceSensors: {},

    setEditignSpaceSensor: (spaceSensor) => {
      set((state) => {
        state.editingSpaceSensors[spaceSensor.id] = spaceSensor;
      });
    },

    updateEditingSpaceSensor: (updates) => {
      set((state) => {
        if (updates.id && state.editingSpaceSensors[updates.id]) {
          state.editingSpaceSensors[updates.id] = {
            ...state.editingSpaceSensors[updates.id],
            ...updates,
          };
          if (updates.additionalProperties) {
            state.editingSpaceSensors[updates.id].additionalProperties = {
              ...state.editingSpaceSensors[updates.id],
              ...updates.additionalProperties,
            };
          }
        }
      });
    },

    removeEditingSpaceSensor: (id) => {
      set((state) => {
        delete state.editingSpaceSensors[id];
      });
    },
  })),
);
