interface PDFViewerProps {
  title: string;
  src: string;
}

export const PDFViewer = ({ title, src }: PDFViewerProps) => {
  return (
    <iframe
      title={title}
      src={src}
      width="100%"
      height="100%"
      style={{ border: 'none' }}
    />
  );
};
