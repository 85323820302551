import { Box, Fade, Grid } from '@mui/material';
import { OrbitControls, PerspectiveCamera } from '@react-three/drei';
import { Canvas, extend, useFrame, useThree } from '@react-three/fiber';
import { QueryClientProvider, useQueryClient } from '@tanstack/react-query';
import { MeshLineGeometry, MeshLineMaterial, raycast } from 'meshline';
import { Perf } from 'r3f-perf';
import { useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react';
import { DEFAULT_ADDITIONAL_PROPERTIES_VIEWPORT } from 'src/constants';
import { useCurrentOrbits } from 'src/hooks/OrbitHooks';
import { useElementSizeWithResizeObserver } from 'src/hooks/useElementSizeWithResizeObserver';
import PropagatedCacheManager from 'src/models/PropagatedCacheManager';
import { LaunchPreview } from 'src/pages/Notebook/components/Launch/LaunchPreview';
import { ManeuverPreview } from 'src/pages/Notebook/components/Maneuvers/ManeuverPreview';
import { SETTINGS_NAMES, useSettingsStore } from 'src/pages/Settings/store';
import { GizmoHelper } from 'src/threejs/from_source/GizmoHelper';
import { GizmoViewport } from 'src/threejs/from_source/GizmoViewport';
import { OrbitObject } from 'src/types';
import {
  Line,
  Mesh,
  PerspectiveCamera as ThreePerspectiveCamera,
  Vector3,
  sRGBEncoding,
} from 'three';
import CoordinateRef from '../CoordinateRef';
import Earth from '../Earth';
import { useEarthTexture } from '../GroundTrackTexture/utils/useEarthTexture';
import { Loader } from '../Loader/Loader';
import OrbitManager from '../OrbitManager';
import SkyLight from '../SkyLight';
import Sun from '../Sun';
import { Universe } from '../Universe/Universe';
import ViewportCanvasProvider, { useViewportCanvas } from '../ViewportCanvasProvider';
import ViewportControls from '../ViewportControls';
import useViewportStore, {
  useViewport,
  useViewportId,
  useViewportReferenceFrame,
} from '../ViewportManager/store';
import ViewportProvider, { useViewportContext } from './../../components/Viewport/context';
import ManipulatorPlane from './../../models/ManipulatorPlane';
import { OrbitRicDataManager } from '../OrbitManager/RIC/OrbitDataManager';

// Extend will make custom elements available as a JSX element where the first letter is lower cased i.e. `orbitControls` or `line3D`.
extend({
  OrbitControls,
  GizmoViewport,
  GizmoHelper,
  Line3D: Line,
  MeshLineGeometry,
  MeshLineMaterial,
  raycast,
  ManipulatorPlane,
});

type Viewport2DProps = {
  orbits: OrbitObject[];
};

const Viewport2D = ({ orbits }: Viewport2DProps) => {
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const draw = useEarthTexture(canvasRef.current ?? undefined, true, false, true);
  const drawRef = useRef<any>();

  useEffect(() => {
    if (orbits) {
      const orbitIds = orbits.map(({ id, name }) => ({ id, name }));
      PropagatedCacheManager.setPropagatingOrbits(orbitIds);
    }
  }, [orbits]);

  useEffect(() => {
    drawRef.current = draw;
  }, [draw]);

  useEffect(() => {
    let rafId: number;

    const animation = () => {
      if (drawRef.current) {
        drawRef.current();
      }
      rafId = requestAnimationFrame(animation);
    };
    animation();

    return () => cancelAnimationFrame(rafId);
  }, []);

  const [containerRef, { width, height }] = useElementSizeWithResizeObserver();

  return (
    <Grid
      ref={containerRef}
      display="grid"
      height="100%"
      width="100%"
      alignItems="center"
      justifyContent="center"
      overflow="hidden"
    >
      <canvas
        width={width}
        height={height}
        ref={canvasRef}
      />
    </Grid>
  );
};

type Viewport3DProps = {
  orbits: OrbitObject[];
  canvas: HTMLCanvasElement;
};

const CameraSyncer = ({ interactionCamera }: any) => {
  const { controls } = useViewport();
  const camera = useThree((state) => state.camera);
  const sizeNew = useThree(({ size }) => size);

  // Update camera aspect ratio on resize.
  useLayoutEffect(() => {
    if (interactionCamera) {
      interactionCamera.aspect = sizeNew.width / sizeNew.height;
      interactionCamera.updateProjectionMatrix();
    }
  }, [sizeNew, interactionCamera]);

  useFrame(() => {
    controls?.update();
    camera.copy(interactionCamera);
  });

  return null;
};

type SceneProps = {
  setLoading: (loading: boolean) => void;
  orbits: OrbitObject[];
};

const Scene = ({ setLoading, orbits }: SceneProps) => {
  const { viewport } = useViewportContext();

  const camera = useThree((state) => state.camera);
  const controls = useThree((state) => state.controls);
  const { controls: viewportControls, targetId } = useViewport();

  const viewportAdditionalProperties =
    viewport?.additionalProperties || DEFAULT_ADDITIONAL_PROPERTIES_VIEWPORT;

  const isStarfieldOn = viewportAdditionalProperties.visStarField;

  const { isECI, isRIC } = useViewportReferenceFrame();
  const id = useViewportId();

  const axesSettings = useSettingsStore((state) => state.axesSettings);

  const canvasActive = useViewportStore((state) => state.viewports[id].canvasActive);
  const markerActive = useViewportStore((state) => state.viewports[id].markerActive);

  // This camera, although never used to render, is the main camera. It always
  // lives in world space and is the single source of truth for the camera
  // transform. This camera's transform is copied to the active camera on every
  // frame, which means that setting a manual transform on other cameras won't
  // work and all camera updates need to be applied on this camera.
  const interactionCamera = useMemo(() => {
    const intCamera = new ThreePerspectiveCamera(50, 1, 0.001, 200);
    intCamera.setViewOffset(100, 100, 0, 12, 100, 100);
    return intCamera;
  }, []);

  const [baseSceneMesh, setBaseMesh] = useState<Mesh | null>(null);

  useEffect(() => {
    // Stored globally for reparenting child elements that need base scene orientation
    const { setCamera, setBaseSceneMesh } = useViewportStore.getState().viewports[id];
    if (camera) setCamera(camera);
    if (baseSceneMesh) setBaseSceneMesh(baseSceneMesh);
  }, [camera, id, baseSceneMesh]);

  useEffect(() => {
    if (!controls) return;
    const { setControls } = useViewportStore.getState().viewports[id];
    setControls(controls);
  }, [controls, id]);

  useEffect(() => {
    if (viewportControls) {
      if (isRIC) {
        viewportControls.minDistance = 0;
      } else {
        viewportControls.minDistance = 1.001;
      }
    }
  }, [isRIC, viewportControls]);

  useEffect(() => {
    if (viewportControls) {
      viewportControls.listenToKeyEvents(window);
      // call reset to target the target0 set in controls
      viewportControls.reset();
    }
  }, [viewportControls]);

  useEffect(() => {
    if (viewportControls) {
      viewportControls.reset();
    }
  }, [
    viewportControls,
    viewportAdditionalProperties.cameraPosition,
    viewportAdditionalProperties.cameraTarget,
  ]);

  return (
    <>
      <mesh ref={setBaseMesh} />
      <PerspectiveCamera
        name="Camera ECI"
        makeDefault={isECI}
      />
      <CameraSyncer interactionCamera={interactionCamera} />
      <SkyLight />
      <Sun />
      <CoordinateRef />
      <Earth />
      <Universe showStars={isStarfieldOn} />

      <OrbitManager
        orbits={orbits}
        setLoading={setLoading}
      />

      {isECI && <ManeuverPreview />}

      {isECI && <LaunchPreview />}

      {isRIC && targetId && <OrbitRicDataManager originOrbitId={targetId} />}

      <OrbitControls
        makeDefault
        panSpeed={0.75}
        zoomSpeed={0.5}
        maxDistance={90}
        enableDamping
        dampingFactor={0.2}
        enabled={canvasActive && !markerActive}
        camera={interactionCamera}
        position0={
          new Vector3(
            viewportAdditionalProperties.cameraPosition.x,
            viewportAdditionalProperties.cameraPosition.y,
            viewportAdditionalProperties.cameraPosition.z,
          )
        }
        target0={
          new Vector3(
            viewportAdditionalProperties.cameraTarget.x,
            viewportAdditionalProperties.cameraTarget.y,
            viewportAdditionalProperties.cameraTarget.z,
          )
        }
      />
      <GizmoHelper
        alignment="top-right"
        camera={interactionCamera}
        margin={[45, 45]}
      >
        <GizmoViewport
          axisColors={[axesSettings.Y.color, axesSettings.Z.color, axesSettings.X.color]}
          labels={
            isRIC
              ? [axesSettings.Y.labelRIC, axesSettings.Z.labelRIC, axesSettings.X.labelRIC]
              : [axesSettings.Y.label, axesSettings.Z.label, axesSettings.X.label]
          }
          scale={30}
          font="25px Inter var, Arial, sans-serif"
          axisHeadScale={1.2}
          axisScale={[0.8, 0.1, 0.1]}
        />
      </GizmoHelper>
    </>
  );
};

const Viewport3D = ({ orbits, canvas }: Viewport3DProps) => {
  const { viewport } = useViewportContext();
  const queryClient = useQueryClient();
  const [loading, setLoading] = useState(true);

  const isPerfOverlayEnabled = useSettingsStore(
    (state) => state.settings[SETTINGS_NAMES.PERF_OVERLAY_ENABLED],
  );

  const setCanvasActive = useViewportStore((state) => state.viewports[viewport.id].setCanvasActive);

  return (
    <>
      {loading && <Loader />}
      <Canvas
        onMouseOver={() => setCanvasActive(true)}
        onMouseOut={() => setCanvasActive(false)}
        gl={{
          preserveDrawingBuffer: true,
          outputEncoding: sRGBEncoding,
          useLegacyLights: false,
        }}
      >
        <ViewportProvider viewport={viewport}>
          <ViewportCanvasProvider canvas={canvas}>
            <QueryClientProvider client={queryClient}>
              {isPerfOverlayEnabled && viewport.position === 0 && (
                <Perf
                  antialias={false}
                  style={{
                    top: '50px',
                    right: '50vw',
                    transform: 'translateX(50%)',
                  }}
                />
              )}

              <Scene
                setLoading={setLoading}
                orbits={orbits}
              />
            </QueryClientProvider>
          </ViewportCanvasProvider>
        </ViewportProvider>
      </Canvas>
    </>
  );
};

const Viewport = () => {
  const { is2D } = useViewportReferenceFrame();
  const { canvas } = useViewportCanvas();
  const orbits = useCurrentOrbits();

  const isLayoutMinified = useSettingsStore(
    (state) => state.settings[SETTINGS_NAMES.LAYOUT_MINIFIED],
  );

  if (!orbits) {
    return null;
  }

  return (
    <Box
      component="div"
      sx={{
        height: '100%',
        boxSizing: 'border-box',
        backgroundColor: 'black',
        position: 'relative',
        minWidth: 0,
        minHeight: 0,
      }}
    >
      <Fade
        in={!isLayoutMinified}
        mountOnEnter
        unmountOnExit
      >
        <div>
          <ViewportControls />
        </div>
      </Fade>
      {is2D ? (
        <Viewport2D orbits={orbits} />
      ) : (
        <Viewport3D
          orbits={orbits}
          canvas={canvas}
        />
      )}
    </Box>
  );
};

export default Viewport;
