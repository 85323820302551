import { Line } from '@react-three/drei';
import { pairs } from 'd3-array';
import { MultiLineString, Position } from 'geojson';
import { useMemo } from 'react';
import { LATLONG_COLOR, LATLONG_COLOR_PRIME } from 'src/constants';
import graticule from 'src/threejs/components/Earth/graticule_forked';
import { Vector3 } from 'three';

const LATLONG_THICKNESS = 0.5;
const LATLONG_THICKNESS_PRIME = LATLONG_THICKNESS * 4;

const LATLONG_SPACED_LATITUDE = 10;
const LATLONG_SPACED_LONGITUDE = 10;

const LATLONG_EPSILON = 1e-6;

export const Graticule = () => {
  const points = useMemo(() => {
    return getVector3FromMultiLineString(
      graticule().step([LATLONG_SPACED_LONGITUDE, LATLONG_SPACED_LATITUDE]).precision(1)(),
    );
  }, []);

  const pointsPrimes = useMemo(() => {
    return getVector3FromMultiLineString(
      graticule()
        .extentMajor([
          [-180 - LATLONG_EPSILON, -90],
          [180 + LATLONG_EPSILON, 90],
        ])
        .step([180, 90])(),
    );
  }, []);

  return (
    <>
      <group rotation={[-Math.PI * 0.5, 0, -Math.PI * 0.5]}>
        <Line
          points={points}
          color={LATLONG_COLOR}
          linewidth={LATLONG_THICKNESS}
          transparent
          opacity={0.75}
          polygonOffset
          polygonOffsetFactor={-10}
          polygonOffsetUnits={-1}
          segments
        />

        <Line
          points={pointsPrimes}
          color={LATLONG_COLOR_PRIME}
          linewidth={LATLONG_THICKNESS_PRIME}
          transparent
          opacity={0.75}
          polygonOffset
          polygonOffsetFactor={-10}
          polygonOffsetUnits={-1}
          segments
        />
      </group>
    </>
  );
};

const radius = 1;
const vertex = (point: Position) => {
  const lambda = (point[0] * Math.PI) / 180,
    phi = (point[1] * Math.PI) / 180,
    cosPhi = Math.cos(phi);
  return new Vector3(
    radius * cosPhi * Math.cos(lambda),
    radius * cosPhi * Math.sin(lambda),
    radius * Math.sin(phi),
  );
};

export const getVector3FromMultiLineString = (multilinestring: MultiLineString): Vector3[] => {
  const points: Vector3[] = [];

  multilinestring.coordinates.forEach((line: Position[]) => {
    pairs(line.map(vertex), (a, b) => {
      points.push(a, b);
    });
  });

  return points;
};
