import { useEffect, useMemo, useRef, useState } from 'react';
import { DEFAULT_ADDITIONAL_PROPERTIES_SPACE_SENSORS, earthradius } from 'src/constants';
import useAppStore from 'src/core/store';
import { useIsPropagating } from 'src/hooks/OrbitHooks';
import { SpaceSensor, ViewingWindow } from 'src/types';
import {
  getNadirAngleFromElevationAngleAndAltitude,
  getSlantRangeFromElevationAngleAndAltitude,
  getSlantRangeFromNadirAngleAndAltitude,
} from 'src/utilities/OrbitUtils';
import { Group, Quaternion, Vector3 } from 'three';
import { degToRad } from 'three/src/math/MathUtils';
import { SensorCone } from '../Common/SensorCone';
import use3DOrbitStore from '../OrbitManager/store/store';
import { useSpaceSensorsStore } from './SpaceSensorsStore';
import { useViewingWindowsStore } from 'src/pages/Notebook/components/ViewingWindowsStore';
import { createPortal, useFrame, useThree } from '@react-three/fiber';
import { getCurrentTime } from 'src/core/getters';
import { useGroundObjectLaunchEditStore } from '../GroundObjects/GroundObjectLaunchEditStore';
import { SpaceSensorPointingType } from 'src/enums';

// add a value to the radius to remove the tangentaial surfaces with earth sphere
// ideally would adjust this value based on zoom, as the farther away, the more likely for the z-fighting
const BASE_RADIUS_OFFSET = 25;

export const SpaceSensorCone = ({ spaceSensor }: { spaceSensor: SpaceSensor }) => {
  const isPropagating = useIsPropagating();

  const [groupRef, setGroupRef] = useState<Group | null>(null);
  const [centeredFoVConeRef, setCenteredConeRef] = useState<Group | null>(null);

  useEffect(() => {
    if (groupRef) {
      groupRef.rotation.x = -Math.PI * 0.5;
    }
  }, [groupRef]);

  const spaceSensorWindows = useViewingWindowsStore((state) => state.spaceSensorWindows);
  const targetViewingWindows = spaceSensorWindows[spaceSensor.orbitId];
  const fovConeGroundObjectIds = useMemo(
    () => new Set(targetViewingWindows?.windows.map((obj) => obj.groundObjectId)),
    [targetViewingWindows?.windows],
  );
  const foVConeIds = useMemo(() => [...fovConeGroundObjectIds], [fovConeGroundObjectIds]);

  const foVConeRefs = useRef<(Group | null)[]>([]);

  const isGroundObjInView = (
    groundObjId: number,
    viewingWindows: ViewingWindow[],
    currentTime: number,
  ) => {
    return viewingWindows
      .filter((win) => win.groundObjectId === groundObjId)
      .some(
        (win) => win.startTime.getTime() <= currentTime && win.endTime.getTime() >= currentTime,
      );
  };

  // For calculation of FOV cone direction pointing
  // instantiate objects such as Vector3s outside of the useFrame loop
  // or any other frequently called loop, and reuse them whenever possible
  // the following are re-used for each FOV cone that exists for a space object's contact target
  const targetPosition = new Vector3();
  // This will serve as the Y-axis unit vector (since the cone is y-up)
  const up = new Vector3(0, 1, 0);
  // get an FOV cone's world position
  const meshPosition = new Vector3();
  // This will store the direction vector from the FOV cone to the world origin
  const direction = new Vector3();
  // This will represent the rotation from the Y-axis to the FOV cone direction vector
  const quaternion = new Quaternion();
  // This will store the world position of the groupRef for the FOV cone
  const groupRefPos = new Vector3();

  const yAxis = new Vector3(0, 1, 0);

  useFrame(() => {
    const currentTime = getCurrentTime();

    groupRef!.getWorldPosition(groupRefPos);

    let numberOfVisibleCones = 0;
    foVConeIds.forEach((id, idx) => {
      const currentFOVCone = foVConeRefs.current[idx];
      if (!currentFOVCone) return;
      if (
        spaceSensor.pointingType === SpaceSensorPointingType.TargetTracking &&
        isGroundObjInView(id, targetViewingWindows?.windows, currentTime)
      ) {
        numberOfVisibleCones++;
        currentFOVCone.visible = true;

        // get the world position of the ground object item associated with the FOV cone in order to point at it
        const { groundObjectItemRefs } = useGroundObjectLaunchEditStore.getState();
        if (!groundObjectItemRefs[id]) return;

        const targetRef = groundObjectItemRefs[id];
        targetRef?.getWorldPosition(targetPosition);

        currentFOVCone.position.set(groupRefPos.x, groupRefPos.y, groupRefPos.z);

        currentFOVCone.getWorldPosition(meshPosition);

        // Update the direction vector to represent this current FOV cone's position to the world origin
        direction.subVectors(targetPosition, meshPosition);

        // update the quaternion that represents this current FOV cone's rotation from the Y-axis to the direction vector
        quaternion.setFromUnitVectors(yAxis, direction.normalize());

        // set the "up" vector for the sensor cone
        currentFOVCone.up.copy(up);

        // apply the rotation to the sensor cone
        currentFOVCone.setRotationFromQuaternion(quaternion);
      } else {
        currentFOVCone.visible = false;
      }
    });

    if (centeredFoVConeRef && numberOfVisibleCones === 0) {
      centeredFoVConeRef.visible = true;
    } else if (centeredFoVConeRef) {
      centeredFoVConeRef.visible = false;
    }

    // show only centered cone if not in tracking mode
    if (spaceSensor.pointingType !== SpaceSensorPointingType.TargetTracking) {
      foVConeIds.forEach((id, idx) => {
        const currentFOVCone = foVConeRefs.current[idx];
        if (currentFOVCone) currentFOVCone.visible = false;
      });
      if (centeredFoVConeRef) centeredFoVConeRef.visible = true;
    }
  });

  /**
   * Common functions
   */
  const calculateAltitude = () => {
    const position: Vector3 = new Vector3();
    const { orbits } = use3DOrbitStore.getState();
    const {
      timelines: {
        timelineRange: { isLoading },
      },
    } = useAppStore.getState();

    if (orbits[spaceSensor.orbitId]) {
      if (isPropagating && !isLoading && orbits[spaceSensor.orbitId].activeStateVector) {
        position.x = orbits[spaceSensor.orbitId].activeStateVector!.y_position;
        position.y = orbits[spaceSensor.orbitId].activeStateVector!.z_position;
        position.z = orbits[spaceSensor.orbitId].activeStateVector!.x_position;
        position.divideScalar(1000);
      } else if (orbits[spaceSensor.orbitId].stateVectors) {
        position.x = orbits[spaceSensor.orbitId].stateVectors!.yPosition;
        position.y = orbits[spaceSensor.orbitId].stateVectors!.zPosition;
        position.z = orbits[spaceSensor.orbitId].stateVectors!.xPosition;
      }
    }

    return position.length() - earthradius;
  };

  const calculateFoRegardConeHeight = (angle: number) => {
    const altitude = calculateAltitude();
    const nadirAngle = getNadirAngleFromElevationAngleAndAltitude(angle, altitude);
    const slantRange = getSlantRangeFromElevationAngleAndAltitude(angle, altitude);
    return Math.cos(degToRad(nadirAngle)) * slantRange;
  };

  const calculateFoViewConeHeight = (angle: number) => {
    const altitude = calculateAltitude();
    const nadirAngle = Math.min(getNadirAngleFromElevationAngleAndAltitude(0, altitude), angle);
    const slantRange = getSlantRangeFromNadirAngleAndAltitude(nadirAngle, altitude);
    return Math.cos(degToRad(nadirAngle)) * slantRange;
  };

  const calculateFoRegardConeRadius = (angle: number) => {
    const altitude = calculateAltitude();
    const nadirAngle = getNadirAngleFromElevationAngleAndAltitude(angle, altitude);
    const slantRange = getSlantRangeFromElevationAngleAndAltitude(angle, altitude);
    const currentRadius = Math.sin(degToRad(nadirAngle)) * slantRange;

    return currentRadius + BASE_RADIUS_OFFSET;
  };

  const calculateFoViewConeRadius = (angle: number) => {
    const altitude = calculateAltitude();
    const nadirAngle = Math.min(getNadirAngleFromElevationAngleAndAltitude(0, altitude), angle);
    const slantRange = getSlantRangeFromNadirAngleAndAltitude(nadirAngle, altitude);
    return Math.sin(degToRad(nadirAngle)) * slantRange;
  };

  const calculateFoViewConeRadiusTracking = (angle: number) => {
    const altitude = calculateAltitude() + earthradius;
    const nadirAngle = Math.min(getNadirAngleFromElevationAngleAndAltitude(0, altitude), angle);
    const slantRange = getSlantRangeFromNadirAngleAndAltitude(nadirAngle, altitude);
    return Math.sin(degToRad(nadirAngle)) * slantRange;
  };

  const getConeOptions = () => {
    const { editingSpaceSensors } = useSpaceSensorsStore.getState();
    return editingSpaceSensors[spaceSensor.id]?.additionalProperties;
  };

  /**
   * Field of Regard functions
   */
  const getFoRegardConeHeight = () => {
    const state = useSpaceSensorsStore.getState();
    const spaceSensorEdit = state.editingSpaceSensors[spaceSensor.id] ?? spaceSensor;
    return calculateFoRegardConeHeight(spaceSensorEdit.minGroundElevationAngle);
  };

  const getFoRegardConeRadius = () => {
    const state = useSpaceSensorsStore.getState();
    const spaceSensorEdit = state.editingSpaceSensors[spaceSensor.id] ?? spaceSensor;
    return calculateFoRegardConeRadius(spaceSensorEdit.minGroundElevationAngle);
  };

  const getFoRegardConeShaderColor = () =>
    getConeOptions()?.forConeShaderColor ??
    spaceSensor?.additionalProperties?.forConeShaderColor ??
    DEFAULT_ADDITIONAL_PROPERTIES_SPACE_SENSORS.forConeShaderColor;

  const getFoRegardConeShaderOpacity = () =>
    (getConeOptions()?.forConeShaderOpacity ??
      spaceSensor?.additionalProperties?.forConeShaderOpacity ??
      DEFAULT_ADDITIONAL_PROPERTIES_SPACE_SENSORS.forConeShaderOpacity) / 100;

  const getFoRegardConeShaderEnabled = () =>
    getConeOptions()?.visFORConeShader ??
    spaceSensor?.additionalProperties?.visFORConeShader ??
    DEFAULT_ADDITIONAL_PROPERTIES_SPACE_SENSORS.visFORConeShader;

  const getFoRegardConeWireframeColor = () =>
    getConeOptions()?.forConeWireframeColor ??
    spaceSensor?.additionalProperties?.forConeWireframeColor ??
    DEFAULT_ADDITIONAL_PROPERTIES_SPACE_SENSORS.forConeWireframeColor;

  const getFoRegardConeWireframeEnabled = () =>
    getConeOptions()?.visFORConeWireframe ??
    spaceSensor?.additionalProperties?.visFORConeWireframe ??
    DEFAULT_ADDITIONAL_PROPERTIES_SPACE_SENSORS.visFORConeWireframe;

  const getFoRegardConeWireframeOpacity = () =>
    (getConeOptions()?.forConeWireframeOpacity ??
      spaceSensor?.additionalProperties?.forConeWireframeOpacity ??
      DEFAULT_ADDITIONAL_PROPERTIES_SPACE_SENSORS.forConeWireframeOpacity) / 100;

  /**
   * Field of View functions
   */
  const getFoViewConeHeight = () => {
    const state = useSpaceSensorsStore.getState();
    const spaceSensorEdit = state.editingSpaceSensors[spaceSensor.id] ?? spaceSensor;
    return calculateFoViewConeHeight(spaceSensorEdit.fieldOfView);
  };
  const getFOViewConeHeightTracking = () => {
    return calculateAltitude() + earthradius;
  };

  const getFoViewConeRadius = () => {
    const state = useSpaceSensorsStore.getState();
    const spaceSensorEdit = state.editingSpaceSensors[spaceSensor.id] ?? spaceSensor;
    return calculateFoViewConeRadius(spaceSensorEdit.fieldOfView);
  };
  const getFOViewConeRadiusTracking = () => {
    const state = useSpaceSensorsStore.getState();
    const spaceSensorEdit = state.editingSpaceSensors[spaceSensor.id] ?? spaceSensor;
    return calculateFoViewConeRadiusTracking(spaceSensorEdit.fieldOfView);
  };

  const getFoViewConeShaderColor = () =>
    getConeOptions()?.fovConeShaderColor ??
    spaceSensor?.additionalProperties?.fovConeShaderColor ??
    DEFAULT_ADDITIONAL_PROPERTIES_SPACE_SENSORS.fovConeShaderColor;

  const getFoViewConeShaderOpacity = () =>
    (getConeOptions()?.fovConeShaderOpacity ??
      spaceSensor?.additionalProperties?.fovConeShaderOpacity ??
      DEFAULT_ADDITIONAL_PROPERTIES_SPACE_SENSORS.fovConeShaderOpacity) / 100;

  const getFoViewConeShaderEnabled = () =>
    getConeOptions()?.visFOVConeShader ??
    spaceSensor?.additionalProperties?.visFOVConeShader ??
    DEFAULT_ADDITIONAL_PROPERTIES_SPACE_SENSORS.visFOVConeShader;

  const getFoViewConeWireframeColor = () =>
    getConeOptions()?.fovConeWireframeColor ??
    spaceSensor?.additionalProperties?.fovConeWireframeColor ??
    DEFAULT_ADDITIONAL_PROPERTIES_SPACE_SENSORS.fovConeWireframeColor;

  const getFoViewConeWireframeEnabled = () =>
    getConeOptions()?.visFOVConeWireframe ??
    spaceSensor?.additionalProperties?.visFOVConeWireframe ??
    DEFAULT_ADDITIONAL_PROPERTIES_SPACE_SENSORS.visFOVConeWireframe;

  const getFoViewConeWireframeOpacity = () =>
    (getConeOptions()?.fovConeWireframeOpacity ??
      spaceSensor?.additionalProperties?.fovConeWireframeOpacity ??
      DEFAULT_ADDITIONAL_PROPERTIES_SPACE_SENSORS.fovConeWireframeOpacity) / 100;

  const { scene } = useThree();

  return (
    <>
      {[...fovConeGroundObjectIds].map((id, idx) => {
        // need to reparent the sensor cones so that they no longer inherit
        // transformations from the parent orbit object
        return (
          <group key={id}>
            {createPortal(
              <group
                ref={(group) => {
                  foVConeRefs.current[idx] = group;
                }}
              >
                <SensorCone
                  renderOrder={idx + 1}
                  getBaseRadius={getFOViewConeRadiusTracking}
                  getHeight={getFOViewConeHeightTracking}
                  getConeShaderColor={getFoViewConeShaderColor}
                  getConeShaderEnabled={getFoViewConeShaderEnabled}
                  getConeShaderOpacity={getFoViewConeShaderOpacity}
                  getConeWireframeColor={getFoViewConeWireframeColor}
                  getConeWireframeEnabled={getFoViewConeWireframeEnabled}
                  getConeWireframeOpacity={getFoViewConeWireframeOpacity}
                  domeEnabled={false}
                />
              </group>,
              scene,
            )}
          </group>
        );
      })}

      <group ref={setGroupRef}>
        {/* The 'Centered' FOV cone shows when the space object is not within any viewing windows of its observation target */}
        <group ref={setCenteredConeRef}>
          <SensorCone
            renderOrder={foVConeIds.length}
            getBaseRadius={getFoViewConeRadius}
            getHeight={getFoViewConeHeight}
            getConeShaderColor={getFoViewConeShaderColor}
            getConeShaderEnabled={getFoViewConeShaderEnabled}
            getConeShaderOpacity={getFoViewConeShaderOpacity}
            getConeWireframeColor={getFoViewConeWireframeColor}
            getConeWireframeEnabled={getFoViewConeWireframeEnabled}
            getConeWireframeOpacity={getFoViewConeWireframeOpacity}
            domeEnabled={false}
          />
        </group>

        <SensorCone
          renderOrder={foVConeIds.length + 1}
          getBaseRadius={getFoRegardConeRadius}
          getHeight={getFoRegardConeHeight}
          getConeShaderColor={getFoRegardConeShaderColor}
          getConeShaderEnabled={getFoRegardConeShaderEnabled}
          getConeShaderOpacity={getFoRegardConeShaderOpacity}
          getConeWireframeColor={getFoRegardConeWireframeColor}
          getConeWireframeEnabled={getFoRegardConeWireframeEnabled}
          getConeWireframeOpacity={getFoRegardConeWireframeOpacity}
          domeEnabled={false}
        />
      </group>
    </>
  );
};
