import { Typography } from '@mui/material';
import { useCurrentLaunchEvents } from 'src/hooks/LaunchHooks';
import theme from 'src/pages/App/Theme';
import { ObjectItem } from '../ListPanel/ObjectItem';
import { TreeBranch } from '../ListPanel/TreeDND/TreeBranch';

export const LaunchEventsListPanel = () => {
  const launchEvents = useCurrentLaunchEvents();

  return launchEvents && launchEvents.length > 0 ? (
    <>
      {launchEvents.map((event) => {
        return (
          <TreeBranch
            key={event.id}
            id={event.id}
            type={'event'}
            main={<ObjectItem name={event.name} />}
          />
        );
      })}
    </>
  ) : (
    <div
      style={{
        display: 'grid',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
        height: '100%',
        color: theme.palette.grey[500],
      }}
    >
      <Typography variant="h6">No Events Created</Typography>
    </div>
  );
};
