import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  DEFAULT_ADDITIONAL_PROPERTIES_ORBIT,
  ORBIT_TYPES,
  PERTURBATIONS_DEFAULTS,
} from 'src/constants';
import { useCreateOrbitMutation, useCurrentOrbit } from 'src/hooks/OrbitHooks';
import { getActiveNotebookId, getActivePageId, useRouteStore } from 'src/pages/App/routes/store';
import { useSaveCurrentOrbit } from 'src/threejs/components/OrbitManager/store/hooks';
import use3DOrbitStore from 'src/threejs/components/OrbitManager/store/store';
import { convertKeplarianToCartesian } from 'src/utilities/Keplerian';

export const useOrbitTypeChange = () => {
  const currentOrbit = useCurrentOrbit();
  const saveCurrentOrbit = useSaveCurrentOrbit();
  const navigate = useNavigate();
  const notebookId = useRouteStore(getActiveNotebookId);
  const updateOrbitType = use3DOrbitStore(
    (state) => currentOrbit && state.orbits[currentOrbit.id].updateOrbitType,
  );
  const updateOrbitSV = use3DOrbitStore(
    (state) => currentOrbit && state.orbits[currentOrbit.id].updateOrbitSV,
  );
  const pageId = useRouteStore(getActivePageId);
  const createNewOrbit = useCreateOrbitMutation();
  const createOrbitFromTLE = useCallback(
    async (newOrbitType: keyof typeof ORBIT_TYPES) => {
      if (currentOrbit) {
        const nameAddon = newOrbitType === ORBIT_TYPES.STATE_VECTORS ? 'SV' : newOrbitType;
        const newOrbitName = `${currentOrbit.name.trim()} ${nameAddon}`;

        const orbit = {
          ...currentOrbit,
          name: newOrbitName,
          orbitType: newOrbitType,
          perturbations: {
            ...PERTURBATIONS_DEFAULTS.COE,
            ...currentOrbit.perturbations,
          },
          additionalProperties: {
            ...DEFAULT_ADDITIONAL_PROPERTIES_ORBIT,
            ...currentOrbit.additionalProperties,
            visOrbit: true, // turn the new orbit visibility on
          },
        };
        const newOrbit = await createNewOrbit.mutateAsync(orbit);

        const newOrbitId = newOrbit.orbits[0].id;
        navigate(`/notebook/${notebookId}/${pageId}/${newOrbitId}`);
      }
    },
    [createNewOrbit, currentOrbit, navigate, notebookId, pageId],
  );

  return useCallback(
    async (newOrbitType: keyof typeof ORBIT_TYPES) => {
      if (currentOrbit?.orbitType === ORBIT_TYPES.TLE) {
        // switching from TLE to COE / SV
        await createOrbitFromTLE(newOrbitType);
      } else {
        // switching from COE to SV or vice versa
        if (updateOrbitType) {
          updateOrbitType(newOrbitType);
        }

        // by default, new COE orbits don't have StateVector values set
        let stateVector = currentOrbit?.stateVectors;
        if (!stateVector && currentOrbit?.orbit[0]) {
          const newSV = convertKeplarianToCartesian(currentOrbit?.orbit[0]);
          stateVector = {
            xPosition: newSV.x_position!,
            yPosition: newSV.y_position!,
            zPosition: newSV.z_position!,
            xVelocity: newSV.x_velocity!,
            yVelocity: newSV.y_velocity!,
            zVelocity: newSV.z_velocity!,
          };
          if (updateOrbitSV) {
            updateOrbitSV(stateVector);
          }
        }
        saveCurrentOrbit();
      }
    },
    [
      createOrbitFromTLE,
      currentOrbit?.orbit,
      currentOrbit?.orbitType,
      currentOrbit?.stateVectors,
      saveCurrentOrbit,
      updateOrbitSV,
      updateOrbitType,
    ],
  );
};
