import { useCallback } from 'react';
import { useCurrentGroups } from 'src/hooks/GroupHooks';
import { useCurrentOrbits } from 'src/hooks/OrbitHooks';
import { OrbitObject } from 'src/types';
import { SubsatellitePoint } from './SubsatellitePoint';

export const SubsatellitePoints = () => {
  const currentOrbits = useCurrentOrbits();

  const currentGroups = useCurrentGroups();

  const isVisible = useCallback(
    (orbit: OrbitObject) => {
      if (orbit?.additionalProperties?.visGroundTracks) {
        return false;
      }
      const orbitGroup = currentGroups?.find((group) => group.id === orbit.groupId);
      const spaceSensor = orbit?.spaceSensors[0] || null; // default to first one

      const groupVisible = orbit.groupId === null || orbitGroup?.additionalProperties?.visOrbits;

      return groupVisible && spaceSensor && spaceSensor.enabled;
    },
    [currentGroups],
  );

  return (
    <>
      {currentOrbits?.map((orbit) => {
        if (isVisible(orbit)) {
          return (
            <SubsatellitePoint
              key={orbit.id}
              orbit={orbit}
            />
          );
        }
        return null;
      })}
    </>
  );
};
