import { EditRounded } from '@mui/icons-material';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  FormControl,
  TextField,
} from '@mui/material';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { SpaceGlassContainerBase } from 'src/components/SpaceGlassControl';
import { onChangeObjectName } from 'src/hooks/ModalObjectOps';
import { ModalHeader } from 'src/pages/Shared/ModalSpaceGlass';
import { LayoutStoreItem } from './layoutStore';

type ModalLayoutRenameProps = {
  isOpen: boolean;
  close: () => void;
  layoutToEdit: LayoutStoreItem;
  saveLayout: (updates: Partial<LayoutStoreItem>) => void;
};

export const ModalLayoutRename = ({
  isOpen,
  close,
  layoutToEdit,
  saveLayout,
}: ModalLayoutRenameProps) => {
  const [name, setName] = useState('');

  useEffect(() => {
    setName(layoutToEdit.name);
  }, [layoutToEdit]);

  const isDirty = useMemo(() => {
    return layoutToEdit.layoutOriginal === undefined || layoutToEdit.name !== name;
  }, [layoutToEdit, name]);

  const nameError = useMemo(() => {
    return name?.length === 0;
  }, [name]);

  const onClose = useCallback(() => {
    close();
  }, [close]);

  const onSubmit = () => {
    saveLayout({
      name: name,
    });
    close();
  };

  return (
    <Dialog
      fullWidth
      maxWidth="sm"
      onClose={onClose}
      open={isOpen}
      PaperComponent={SpaceGlassContainerBase}
    >
      <ModalHeader
        title="Rename Layout"
        onClose={onClose}
        icon={<EditRounded />}
      />
      <DialogContent>
        <FormControl
          fullWidth
          sx={{
            my: 1,
          }}
        >
          <TextField
            variant="standard"
            label="Layout Name"
            value={name}
            onChange={(event) => {
              onChangeObjectName(event, setName);
            }}
          />
        </FormControl>
      </DialogContent>

      <DialogActions>
        <Button onClick={onClose}>CANCEL</Button>
        <Button
          onClick={onSubmit}
          variant="contained"
          color="primary"
          disabled={nameError || !isDirty}
        >
          SAVE
        </Button>
      </DialogActions>
    </Dialog>
  );
};
