import { Grid, Typography } from '@mui/material';
import { Video } from './common/Video';

export const VideosAstrodynamics = () => {
  return (
    <Grid
      display="grid"
      justifyContent="center"
      alignContent="start"
    >
      <Grid display="grid">
        <Typography
          variant="h3"
          py={2}
        >
          Laboratory Astrodynamics Video Tutorials
        </Typography>
        <Grid
          display="grid"
          gap={4}
        >
          <Video
            title="Part I - Orienting in Space"
            src="https://www.youtube.com/embed/CXTWWmNObM8?si=uK6f8NH1baF5ifW5"
          />
          <Video
            title="Part II - Orbits & Elements"
            src="https://www.youtube.com/embed/oUm4Q6eZawg?si=WBAVR7YIIoCed7FS"
          />
          <Video
            title="Part III - Orbital Regimes"
            src="https://www.youtube.com/embed/m7M7uot3Tgw?si=Ufo-UCO1A0TM0laz"
          />
          <Video
            title="Part IV - Ground Tracks"
            src="https://www.youtube.com/embed/ZtPFsDMO9U4?si=DhQvLyWEBqXgCOT5"
          />
          <Video
            title="Part V - Orbital Perturbations"
            src="https://www.youtube.com/embed/2yNcpSYTXrQ?si=WQwCi9x9X_MLnGRq"
          />
          <Video
            title="Part VI - Sun Sync Orbits"
            src="https://www.youtube.com/embed/WIg0Tbsz43s?si=7or3Hj18F660zwn9"
          />
          <Video
            title="Part VIIa - Orbital Velocity"
            src="https://www.youtube.com/embed/iVMqpXh4FL8?si=z5F5oLdHmDJaxkHk"
          />
          <Video
            title="Part VIIb - Hohmann Transfers"
            src="https://www.youtube.com/embed/UrOkcueqpRQ?si=fy78QMXnxpaN26Ez"
          />
        </Grid>
      </Grid>
    </Grid>
  );
};
