import {
  CameraIndoorRounded,
  DeleteRounded,
  EditRounded,
  ReplayRounded,
  SaveRounded,
  VideocamRounded,
} from '@mui/icons-material';
import { Grid, IconButton, Tooltip } from '@mui/material';
import gsap from 'gsap';
import { useState } from 'react';
import {
  DEFAULT_ADDITIONAL_PROPERTIES_BOOKMARK,
  DEFAULT_ADDITIONAL_PROPERTIES_VIEWPORT,
  TIME_TWEEN_CAMERA_MOVEMENT,
} from 'src/constants';
import { LABModalAction, LABModalActionType } from 'src/enums';
import { useUpdateBookmarkAdditionalProperties } from 'src/hooks/BookmarkHooks';
import { useIsReadOnly } from 'src/hooks/SharedNotebookHooks';
import { SETTINGS_NAMES, useSettingsStore } from 'src/pages/Settings/store';
import { useViewportContext } from 'src/threejs/components/Viewport/context';
import { useViewport } from 'src/threejs/components/ViewportManager/store';
import { Bookmark, BookmarkAdditionalProperties, Cartesian } from 'src/types';
import { useEffectOnce } from 'usehooks-ts';
import { ControlsCamera, ControlsContainer } from './BookmarkCallout.styled';
import { ModalBookmark } from './ModalBookmark';

interface BookmarkCalloutControlsProps {
  bookmark: Bookmark;
}
export const BookmarkCalloutControls = ({ bookmark }: BookmarkCalloutControlsProps) => {
  const { controls } = useViewport();
  const { viewport } = useViewportContext();
  const isReadOnly = useIsReadOnly();

  const isStoryBuilderEditable = useSettingsStore(
    (state) => state.settings[SETTINGS_NAMES.STORYBUILDER_EDITABLE],
  );

  const updateBookmarkAdditionalProperties = useUpdateBookmarkAdditionalProperties();

  const bookmarkAdditionalProperties =
    bookmark?.additionalProperties || DEFAULT_ADDITIONAL_PROPERTIES_BOOKMARK;

  const cameraOrientation = bookmarkAdditionalProperties.cameraOrientations?.[viewport.id] || {};

  const viewportAdditionalProperties =
    viewport?.additionalProperties || DEFAULT_ADDITIONAL_PROPERTIES_VIEWPORT;

  const [modalType, setModalType] = useState<LABModalActionType>(LABModalAction.NULL);
  const [cameraOptionsVisible, setCameraOptionsVisible] = useState(false);

  const handleEditOnClick = () => {
    setModalType(LABModalAction.EDIT);
  };

  const onCloseModal = () => {
    setModalType(LABModalAction.NULL);
  };

  useEffectOnce(() => {
    cameraOrientationReset();
  });

  const handleChangeAdditionalPropertiesBookmark = (
    changes: Partial<BookmarkAdditionalProperties>,
  ) => {
    updateBookmarkAdditionalProperties(bookmark, changes);
  };

  const moveCameraControlsHome = () => {
    const cameraPosition = viewportAdditionalProperties.cameraPosition;
    const cameraTarget = viewportAdditionalProperties.cameraTarget;
    animateControls(cameraPosition, cameraTarget);
  };

  const animateControls = (position: Cartesian, target: Cartesian) => {
    if (controls) {
      gsap.to(controls.object.position, { ...position, duration: TIME_TWEEN_CAMERA_MOVEMENT });
      gsap.to(controls.target, { ...target, duration: TIME_TWEEN_CAMERA_MOVEMENT });
    }
  };

  const cameraOrientationReset = () => {
    if (cameraOrientation && cameraOrientation.cameraPosition && cameraOrientation.cameraTarget) {
      const cameraPosition = {
        x: cameraOrientation.cameraPosition.x,
        y: cameraOrientation.cameraPosition.y,
        z: cameraOrientation.cameraPosition.z,
      };
      const cameraTarget = {
        x: cameraOrientation.cameraTarget.x,
        y: cameraOrientation.cameraTarget.y,
        z: cameraOrientation.cameraTarget.z,
      };
      animateControls(cameraPosition, cameraTarget);
    }
  };

  const cameraOrientationSave = () => {
    const cameraPosition = {
      x: controls.object.position.x,
      y: controls.object.position.y,
      z: controls.object.position.z,
    };
    const cameraTarget = {
      x: controls.target.x,
      y: controls.target.y,
      z: controls.target.z,
    };
    handleChangeAdditionalPropertiesBookmark({
      ...bookmarkAdditionalProperties,
      cameraOrientations: {
        ...bookmarkAdditionalProperties.cameraOrientations,
        [viewport.id]: {
          cameraPosition: cameraPosition,
          cameraTarget: cameraTarget,
        },
      },
    });
  };

  const cameraOrientationDelete = () => {
    const changes = structuredClone(bookmarkAdditionalProperties);
    delete changes.cameraOrientations[viewport.id];
    handleChangeAdditionalPropertiesBookmark(changes);
    moveCameraControlsHome();
  };

  if (!isStoryBuilderEditable) {
    return null;
  }

  return (
    <>
      <ModalBookmark
        action={modalType}
        editingBookmark={bookmark}
        onClose={onCloseModal}
      />
      <ControlsContainer
        container
        direction="row"
        sx={{
          position: 'absolute',
          top: 54,
          right: 6,
          width: 'auto',
        }}
      >
        {!isReadOnly && (
          <Grid item>
            <IconButton
              aria-label="edit"
              size="small"
              onClick={handleEditOnClick}
            >
              <EditRounded fontSize="inherit" />
            </IconButton>
          </Grid>
        )}
        <Grid item>
          <span
            style={{
              position: 'relative',
              display: 'inline-block',
            }}
            onMouseOut={() => {
              setCameraOptionsVisible(false);
            }}
            onMouseOver={() => {
              setCameraOptionsVisible(true);
            }}
          >
            <IconButton
              aria-label="views"
              size="small"
              disabled
            >
              <VideocamRounded fontSize="inherit" />
            </IconButton>

            <ControlsCamera
              style={{
                visibility: cameraOptionsVisible ? 'visible' : 'hidden',
              }}
            >
              <Tooltip
                disableInteractive
                title="Reset Camera to Viewport Orientation"
              >
                <span>
                  <IconButton
                    onClick={moveCameraControlsHome}
                    size="small"
                  >
                    <CameraIndoorRounded fontSize="inherit" />
                  </IconButton>
                </span>
              </Tooltip>

              <Tooltip
                disableInteractive
                title="Reset Camera to Saved Orientation"
              >
                <span>
                  <IconButton
                    onClick={cameraOrientationReset}
                    size="small"
                  >
                    <ReplayRounded fontSize="inherit" />
                  </IconButton>
                </span>
              </Tooltip>

              {!isReadOnly && (
                <Tooltip
                  disableInteractive
                  title="Save Camera Orientation"
                >
                  <span>
                    <IconButton
                      onClick={cameraOrientationSave}
                      size="small"
                    >
                      <SaveRounded fontSize="inherit" />
                    </IconButton>
                  </span>
                </Tooltip>
              )}

              {!isReadOnly && (
                <Tooltip
                  disableInteractive
                  title="Delete Custom Camera Orientation"
                >
                  <span>
                    <IconButton
                      onClick={cameraOrientationDelete}
                      size="small"
                    >
                      <DeleteRounded fontSize="inherit" />
                    </IconButton>
                  </span>
                </Tooltip>
              )}
            </ControlsCamera>
          </span>
        </Grid>
      </ControlsContainer>
    </>
  );
};
