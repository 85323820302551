function islineCanvasWrap(
  maxWidth: number,
  xStart: number,
  yStart: number,
  xEnd: number,
  yEnd: number,
) {
  const maxEdgeDist = 400; // width of band to check distance from edge
  let isWrap = false;
  let xEdgeEnd = 0;
  let yEdgeEnd = 0;
  let xEdgeStart = 0;
  let yEdgeStart = 0;

  // check if going from left to right crossing (2048->0)
  if (xStart > maxWidth - maxEdgeDist && xEnd < maxEdgeDist) {
    isWrap = true;
    xEdgeEnd = maxWidth;

    const dxTotal = xEdgeEnd - xStart + xEnd;
    const slope = (yEnd - yStart) / dxTotal;

    const dy = (maxWidth - xStart) * slope; // dy = dx * slope
    yEdgeEnd = yStart + dy;
    xEdgeStart = 0;
    yEdgeStart = yEdgeEnd;
  } // check if going from right to left crossing (2048<-0)
  else if (xStart < maxEdgeDist && xEnd > maxWidth - maxEdgeDist) {
    isWrap = true;
    xEdgeEnd = 0;

    const dxTotal = -xStart + (xEnd - maxWidth); // [0 to -200]
    const slope = -(yEnd - yStart) / dxTotal; // +Southward, -Northward

    const dy = xStart * slope; // dy = dx * slope
    yEdgeEnd = yStart + dy;
    xEdgeStart = maxWidth;
    yEdgeStart = yEdgeEnd;
  }

  // uv border
  return { wrap: isWrap, p1x: xEdgeEnd, p1y: yEdgeEnd, p2x: xEdgeStart, p2y: yEdgeStart };
}

export default islineCanvasWrap;
