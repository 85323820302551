import { useFrame } from '@react-three/fiber';
import { MeshLineGeometry, MeshLineMaterial } from 'meshline';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import {
  DEFAULT_ADDITIONAL_PROPERTIES_ORBIT,
  ORBIT_COLOR_DEFAULT,
  PROPAGATION_STEPS_PER_REVOLUTION,
} from 'src/constants';
import { getCurrentTime } from 'src/core/getters';
import useAppStore from 'src/core/store';
import { useCurrentOrbits, useOrbit } from 'src/hooks/OrbitHooks';
import { BUFFER_SIZE } from 'src/models/PropagatedCacheManager';
import getEarthRotation from 'src/threejs/math/getEarthRotation';
import { ReferenceFrame, ReferenceFrameType } from 'src/types';
import binarySearchForClosestValue, {
  BinarySearchForClosestValueResult,
} from 'src/utilities/binarySearchForClosestValue';
import { invlerp } from 'src/utilities/MathUtils';
import { Euler, Group, Matrix4, Mesh, Vector3 } from 'three';
import { use3DOrbitContext } from '../Orbit/context';
import { createGetOrbitPathTimePoints, getOrbits } from '../OrbitManager/store/getters';
import use3DOrbitStore from '../OrbitManager/store/store';
import { OrbitStoreState } from '../OrbitManager/store/types';
import { useViewport } from '../ViewportManager/store';
import OrbitPathLine from './components/OrbitPathLine';

/*
  A chunk represents a section of the orbits path data by defining the startIndex and how many
  points to iterate over for that portion of the fully propagated path.
*/
type ChunkResult = {
  start: number;
  size: number;
};

function virtualChunk(size: number, chunkSize: number, offset = 0): ChunkResult[] {
  const numberOfChunks = Math.ceil((size - offset) / chunkSize);
  return Array.from({ length: numberOfChunks }).map((_, index) => ({
    start: index * chunkSize + offset,
    size: index === numberOfChunks - 1 ? size - chunkSize * index - offset : chunkSize,
  }));
}

type OrbitPathProps = {
  visible: boolean;
  referenceFrame: ReferenceFrameType;
  taMotionGroup?: Group | null;
  updateTaMotion: boolean;
  isChaser?: boolean;
  color?: string;
  orbitObjText: Group | null;
};

export const MESH_LINE_SEGMENT_SIZE = BUFFER_SIZE; // 15000 points per mesh line

/** Takes an array of position/velocity vectors (in ECI) for the target and an
 * array of position vectors (in ECI) for the chaser, and returns a new array of
 * positions for the chaser in the RIC reference frame of the target.
 *
 * @param targetTimePoints - An array of timestamps that correspond to the
 * target position and velocity vectors.
 * @param targetECIPositions - An array of positions for the target in ECI.
 * @param targetECIVelocityVectors - An array of velocity vectors for the target
 * in ECI.
 * @param chaserTimePoints - An array of timestamps that correspond to the
 * chaser positions.
 * @param chaserECIPositions - An array of positions for the chaser in ECI
 * positions.
 */
function getRICPositions(
  targetTimePoints: number[],
  targetECIPositions: Vector3[],
  targetECIVelocityVectors: Vector3[],
  chaserTimePoints: number[],
  chaserECIPositions: Vector3[],
) {
  // Why can't we just use the position/velocity vectors of the target and the
  // chaser's ECI position and convert them to RIC? Why are the time vectors
  // needed? Because the target and chaser state vector arrays operate on
  // potentially different timeframes, because the propagation step size is
  // different for different orbits, so two different orbits might use a
  // different amount of vectors to represent the same timeframe. That means
  // that even though the arrays could be the same size (when we have a batch)
  // they're not equivalent in time. The timestamp at index 5 in
  // targetTimePoints might be different to the timestamp at index 5 on
  // chaserTimePoints. We need to get a new array that gives us the RIC
  // positions, but with the added constraint that the resulting array positions
  // should be on the same timeframe as the target positions. So the position at
  // ricPositions[5] should correspond to the timestamp at targetTimePoints[5].

  const ricPositions: Vector3[] = [];

  if (chaserTimePoints.length === 0 || targetTimePoints.length === 0) {
    return [];
  }

  const isTargetTheLongestTimeFrame =
    targetTimePoints[targetTimePoints.length - 1] > chaserTimePoints[chaserTimePoints.length - 1];

  // maxReachableIndex corresponds to the largest index in the targetTimePoints
  // array that holds a timestamp that is within the time range of the
  // chaserTimePoints array. We need to find what that index is so when getting
  // the RIC positions we don't try to get values that are out of range. If the
  // chaserTimePoints array has the longest timeframe (meaning, its last
  // timestamp goes further than the last timestamp in the targetTimePoints
  // array), then that means the maxReachableIndex is the last index of the
  // targetTimePointsArray. But if targetTimePoints has the longest timeframe,
  // then the max timestamp in chaserTimePoints has an equivalent value
  // somewhere in targetTimePoints.
  let maxReachableIndex = targetTimePoints.length - 1;
  if (isTargetTheLongestTimeFrame) {
    const result = binarySearchForClosestValue(
      targetTimePoints,
      chaserTimePoints[chaserTimePoints.length - 1],
    );
    maxReachableIndex = result.low.index + 1;
  }

  // Let's iterate over all the target points (time/position/velocity) up until
  // the point where we know we will have valid chaser data.
  for (let index = 0; index <= maxReachableIndex; index++) {
    const targetPosition = targetECIPositions[index];
    const targetVelocity = targetECIVelocityVectors[index];
    const targetTime = targetTimePoints[index];

    // Between which values (and which indexes) does this target timestamp fall in the chaserTimePoints array?
    const { low, high } = binarySearchForClosestValue(chaserTimePoints, targetTime);

    const lowItem = low.item;
    const highItem = high.item ?? lowItem;

    const lowPosition = chaserECIPositions[low.index];
    const highPosition = high.item ? chaserECIPositions[high.index] : lowPosition;

    // with the introduction of launch events and ephemeris data, we now have orbit paths that don't have timepoints
    // for every propagation step along the page timeline, so we need to check if we found target time point
    if (!lowPosition || !highPosition) {
      return [];
    }

    // We found that this target timestamp was somewhere between the "low" and
    // "high" values in chaserTimePoints. Now, was it close to "low" or was it
    // closer to "high"? "blend" represents how close it was. If it's 0, it's
    // exactly the same value as low, 1 if it's exactly high, and 0.5 if it's
    // exactly in the middle.
    const blend = invlerp(lowItem!, highItem!, targetTime);

    // Now let's use that blend value to interpolate between the two ECI chaser
    // positions and get the actual position that corresponds to this target
    // timestamp.
    const chaserPosition = new Vector3().copy(lowPosition).lerp(highPosition, blend);

    // And finally convert that into RIC and store.
    const ricMatrix = new Matrix4();
    const rHat = targetPosition.clone().normalize();
    const cHat = rHat.clone().normalize().cross(targetVelocity.normalize());
    const iHat = cHat.clone().cross(rHat);
    ricMatrix.makeBasis(iHat, cHat, rHat);
    ricMatrix.setPosition(targetPosition.clone());
    ricPositions[index] = chaserPosition.applyMatrix4(ricMatrix.invert());
  }

  return ricPositions;
}

const OrbitPath = ({
  referenceFrame,
  visible,
  taMotionGroup,
  updateTaMotion,
  isChaser = false,
  color = ORBIT_COLOR_DEFAULT,
  orbitObjText,
}: OrbitPathProps) => {
  const { id, name } = use3DOrbitContext();
  const { targetId } = useViewport();
  const pathRef = useRef<Group>(null);
  const orbitPathLineRefs = useRef<Mesh<MeshLineGeometry, MeshLineMaterial>[]>([]);

  const selectDataPoints = useCallback(
    (state: OrbitStoreState) => {
      const orbit = state.orbits[id];

      let dataPoints: Vector3[] = [];
      switch (referenceFrame) {
        case ReferenceFrame.ECI:
          dataPoints = orbit.orbitPropagationPath.dataPoints;
          break;
        case ReferenceFrame.ECEF:
          dataPoints = orbit.orbitPropagationPath.ecefDataPoints;
          break;
        case ReferenceFrame.RIC:
          if (targetId) {
            const targetOrbit = state.orbits[targetId];
            const thisOrbitTimePoints = state.orbits[id].orbitPropagationPath.timePoints;
            const targetOrbitTimePoints = targetOrbit.orbitPropagationPath.timePoints;

            const velocityDataPoints = targetOrbit.orbitPropagationPath.velocityDataPoints;
            dataPoints = getRICPositions(
              targetOrbitTimePoints,
              targetOrbit.orbitPropagationPath.dataPoints,
              velocityDataPoints,
              thisOrbitTimePoints,
              orbit.orbitPropagationPath.dataPoints,
            );
          }
          break;
        default:
          break;
      }
      return dataPoints;
    },
    [id, referenceFrame, targetId],
  );

  const targetTimePoints = use3DOrbitStore(
    (state) => targetId && state.orbits[targetId].orbitPropagationPath.timePoints,
  );

  const selectVelocityVectors = useCallback(
    (state: OrbitStoreState) => {
      return state.orbits[id].orbitPropagationPath.velocityDataPoints;
    },
    [id],
  );

  const getOrbitPathTimePoints = useMemo(() => createGetOrbitPathTimePoints(id), [id]);

  const orbitPathDataPoints = use3DOrbitStore(selectDataPoints);
  const orbitPeriod = use3DOrbitStore((state) => state.orbits[id].orbitData.period);

  const currentOrbits = useCurrentOrbits();
  const [minPeriod, setMinPeriod] = useState(Infinity);

  const periodFactor = orbitPeriod / minPeriod;

  const additionalProps = useOrbit(id)?.additionalProperties;

  // store the shortest period of the orbits to account for step size used during propagation
  useEffect(() => {
    const orbitStates = getOrbits();
    const periods = currentOrbits?.map((orbit) => orbitStates[orbit.id]?.orbitData.period) || [];
    setMinPeriod(Math.min(...periods));
  }, [currentOrbits]);

  const trackLength3D =
    additionalProps?.trackLength3D || DEFAULT_ADDITIONAL_PROPERTIES_ORBIT.trackLength3D;

  let startOffset = 0;

  if (trackLength3D > 0) {
    const currentTime = getCurrentTime();

    const timePoints = isChaser ? (targetTimePoints as number[]) : getOrbitPathTimePoints();

    const nearestDataPoints = binarySearchForClosestValue(
      timePoints,
      currentTime / 1000,
      (timePoint) => timePoint,
    );

    // use the low so worst case is line is a little longer than the trackLength3D
    const indexLow = nearestDataPoints.low.index;
    if (indexLow > startOffset) {
      const newIndexStart = Math.floor(
        indexLow - PROPAGATION_STEPS_PER_REVOLUTION * trackLength3D * periodFactor,
      );
      if (newIndexStart > startOffset) {
        startOffset = newIndexStart;
      }
    }
  }

  const numberOfPoints = orbitPathDataPoints.length;
  const chunks = virtualChunk(numberOfPoints, MESH_LINE_SEGMENT_SIZE, startOffset);

  const updatePathDisplay = useCallback(
    (
      index: number,
      val: number,
      low: BinarySearchForClosestValueResult<number>['low'],
      high: BinarySearchForClosestValueResult<number>['high'],
    ) => {
      const currentIndex = index + val;

      for (let i = 0; i < chunks.length; i++) {
        let visibility;
        const chunk = chunks[i];

        const orbitPathLineMesh = orbitPathLineRefs.current[i];
        const chunkSize = i < chunks.length - 1 ? chunk.size + 1 : chunk.size;

        if (chunk.start + chunkSize < currentIndex) {
          // Fully in the past
          visibility = 1;
        } else if (chunk.start > currentIndex) {
          // Fully in the future
          visibility = 0;
        } else {
          // The tip of the line (satellite position) is in this segment. We
          // need to get the right interpolated parameter for the meshline
          // visibility uniform (0.0 - 1.0). This algorithm accounts for
          // repeated or unevenly spaced points.
          const stepSize = 1 / chunkSize;
          const base = (low.index - chunk.start) * stepSize;
          const blend = (high.index - low.index) * stepSize * val;
          visibility = base + blend;
        }

        orbitPathLineMesh.material.uniforms.visibility.value = visibility;
      }
    },
    [chunks],
  );

  const orbitPathTaMotionMarkerAnimation = useCallback(() => {
    const currentTime = getCurrentTime();

    // Do not use the references to these arrays coming from the hooks because
    // they get out of date when this function is called by zustand when there's
    // a change in currentTime. Instead, get them fresh from the zustand store.
    const dataPoints = selectDataPoints(use3DOrbitStore.getState());
    const orbitPathVelocityVectors = selectVelocityVectors(use3DOrbitStore.getState());
    const timePoints = isChaser ? (targetTimePoints! as number[]) : getOrbitPathTimePoints();

    if (!dataPoints.length || !timePoints.length) {
      return;
    }

    // find nearest point based off time
    const { low, high } = binarySearchForClosestValue(timePoints, currentTime, (timePoint) => {
      return timePoint * 1000;
    });

    // clamped low
    const index = Math.max(0, low.index);

    // clamped high
    const current = Math.min(high.index, dataPoints.length - 1);

    // ephemeris orbits that exist outside of the page timeline could not have the matching dataPoints
    if (!dataPoints[index] || !dataPoints[current]) {
      return;
    }

    // inver Lerp last and current to get interpolated point fraction (theoretical index value)
    // val is the 0-1 blend between two points given an input (a)
    const val = invlerp(timePoints[index], timePoints[current], currentTime / 1000);

    const blendPosition = new Vector3().copy(dataPoints[index]).lerp(dataPoints[current], val);

    if (referenceFrame === ReferenceFrame.ECEF) {
      blendPosition.applyAxisAngle(new Vector3(0, 1, 0), getEarthRotation(getCurrentTime()));
    }

    // update true anomaly position - position is the closest datapoint
    if (taMotionGroup && updateTaMotion) {
      taMotionGroup.position.copy(blendPosition);

      const blendVelocity = new Vector3()
        .copy(orbitPathVelocityVectors[index])
        .lerp(orbitPathVelocityVectors[current], val);

      const rHat = blendPosition.clone().normalize();
      const cHat = rHat.clone().normalize().cross(blendVelocity.normalize());
      const iHat = cHat.clone().cross(rHat);

      const ricMatrix = new Matrix4();
      ricMatrix.makeBasis(iHat, cHat, rHat);
      const euler = new Euler();
      euler.setFromRotationMatrix(ricMatrix);
      taMotionGroup.setRotationFromEuler(euler);
      // update orbit object text position during orbit so it stays with marker
      if (orbitObjText) {
        orbitObjText.position.copy(taMotionGroup.position);
      }
    }

    updatePathDisplay(index, val, low, high);
  }, [
    getOrbitPathTimePoints,
    isChaser,
    referenceFrame,
    selectDataPoints,
    selectVelocityVectors,
    taMotionGroup,
    targetTimePoints,
    updatePathDisplay,
    updateTaMotion,
    orbitObjText,
  ]);

  useEffect(() => {
    orbitPathTaMotionMarkerAnimation();
    return useAppStore.subscribe(
      (state) => state.timelines.timelineRange.currentTime,
      orbitPathTaMotionMarkerAnimation,
    );
  }, [orbitPathTaMotionMarkerAnimation]);

  // Rotate path on ECEF just like earth. Alternative approach to consider:
  // reparent to earth.
  useFrame(() => {
    if (pathRef.current) {
      if (referenceFrame === ReferenceFrame.ECEF) {
        pathRef.current.rotation.y = getEarthRotation(getCurrentTime());
      }
    }
  });

  if (numberOfPoints) {
    return (
      <group ref={pathRef}>
        {chunks.map(({ start, size }, index) => (
          <OrbitPathLine
            name={name}
            key={index}
            color={color}
            visible={visible}
            start={start}
            size={index < chunks.length - 1 ? size + 1 : size}
            index={index + 1}
            orbitPathDataPoints={orbitPathDataPoints}
            meshRef={(orbitPathLineMesh: Mesh<MeshLineGeometry, MeshLineMaterial>) => {
              if (orbitPathLineMesh) {
                orbitPathLineRefs.current[index] = orbitPathLineMesh;
              }
            }}
          />
        ))}
      </group>
    );
  }

  return null;
};

export default OrbitPath;
