import { LAUNCH_FLYOUT_STATUS } from 'src/constants';
import { useGroundObjectLaunchEditStore } from 'src/threejs/components/GroundObjects/GroundObjectLaunchEditStore';
import { LaunchPreviewBand } from './PreviewElements/LaunchPreviewBand';
import { LaunchPreviewCamera } from './PreviewElements/LaunchPreviewCamera';
import { LaunchPreviewOrbit } from './PreviewElements/LaunchPreviewOrbit';

export const LaunchPreview = () => {
  const launchFlyoutStatus = useGroundObjectLaunchEditStore((state) => state.isLaunchEditMode);

  if (launchFlyoutStatus === LAUNCH_FLYOUT_STATUS.CLOSED) {
    return null;
  }

  return (
    <>
      <LaunchPreviewBand />

      <LaunchPreviewOrbit />

      <LaunchPreviewCamera />
    </>
  );
};
