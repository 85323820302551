import { Box, Button, Typography } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import {
  StatusBox,
  StatusContainer,
  StatusInnerBox,
  StatusLogo,
  StatusLogoContainer,
} from './Status.styled';

export const NotFound = () => {
  return (
    <StatusContainer>
      <StatusBox>
        <StatusLogoContainer>
          <StatusLogo />
        </StatusLogoContainer>
        <StatusInnerBox>
          <Typography variant="h4">Lost in Space?</Typography>
          <Box
            component="div"
            display="flex"
            alignItems="center"
            width="300px"
            textAlign="center"
            flexDirection="column"
          >
            <Typography variant="h6">
              It looks like you&rsquo;re trying to access a page that doesn&rsquo;t exist.
            </Typography>
          </Box>
          <Button
            component={RouterLink}
            variant="contained"
            color="primary"
            to="/folders"
          >
            RETURN HOME
          </Button>
        </StatusInnerBox>
      </StatusBox>
    </StatusContainer>
  );
};
