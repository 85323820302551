import { AddLocationAltOutlined, RadarRounded, RocketLaunchRounded } from '@mui/icons-material';
import { GroundObject, GroundObjectCategory, GroupObject } from 'src/types';
import { ObjectContextMenu } from './ObjectContextMenu';
import { ObjectItemGround } from './ObjectItemGround';
import { TreeBranch, TreeBranchDrop } from './TreeDND/TreeBranch';

interface TreeBranchGroundProps {
  type: string;
  active?: boolean;
  group?: GroupObject;
  groundObject: GroundObject;
  onDrop?: (onDropProps: TreeBranchDrop) => void;
  onSelect?: () => void;
}

export const TreeBranchGround = ({
  type,
  active,
  group,
  groundObject,
  onDrop,
  onSelect,
}: TreeBranchGroundProps) => {
  let icon;
  switch (groundObject.category) {
    case GroundObjectCategory.LAUNCH_PAD:
      icon = <RocketLaunchRounded />;
      break;
    case GroundObjectCategory.LOCATION:
      icon = <AddLocationAltOutlined />;
      break;
    default:
      icon = <RadarRounded />;
  }

  return (
    <ObjectContextMenu object={groundObject}>
      <TreeBranch
        id={`${groundObject.id}`}
        type={type}
        active={active}
        onDrop={onDrop}
        onSelect={onSelect}
        draggable
        droppable={!group}
        main={
          <ObjectItemGround
            group={group}
            groundObject={groundObject}
          />
        }
        icon={icon}
      />
    </ObjectContextMenu>
  );
};
