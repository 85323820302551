import {
  FolderRounded,
  FolderSharedRounded,
  LibraryBooksRounded,
  PeopleAltRounded,
} from '@mui/icons-material';
import { Box, Button, ButtonProps, Tooltip } from '@mui/material';
import { grey } from '@mui/material/colors';
import { GridColDef } from '@mui/x-data-grid';
import { LABObject } from 'src/enums';
import { millisToLocale } from 'src/utilities/DateTimeUtils';
import { NotebookFolderContextMenu } from '../NotebookFolderContextMenu';

function FolderRowButton(props: ButtonProps) {
  return (
    <Button
      {...props}
      sx={{
        color: 'text.primary',
        backgroundColor: 'background.paper',
        '&:hover': {
          backgroundColor: 'background.paper',
        },
        '&:disabled': {
          color: grey[500],
        },
      }}
    />
  );
}

/**
 * Columns for the folder view data grid
 */
export const folderColumns: GridColDef[] = [
  {
    field: 'isShared',
    headerName: ' ',
    minWidth: 30,
    width: 30,
    renderCell: (params) => {
      let icon = <></>;
      if (params.value) {
        icon = <PeopleAltRounded fontSize="small" />;
      }
      return <NotebookFolderContextMenu item={params.row}>{icon}</NotebookFolderContextMenu>;
    },
  },
  {
    field: 'type',
    headerName: ' ',
    width: 50,
    renderCell: (params) => {
      let icon = <></>;
      if (params.value === LABObject.FOLDER) {
        icon = <FolderRounded />;
      } else if (params.value === LABObject.NOTEBOOK || params.value === 'SHARED-NOTEBOOK') {
        icon = <LibraryBooksRounded />;
      } else if (params.value === 'SHARED-FOLDER') {
        icon = <FolderSharedRounded />;
      }
      return <NotebookFolderContextMenu item={params.row}>{icon}</NotebookFolderContextMenu>;
    },
  },
  {
    field: 'name',
    headerName: 'Name',
    flex: 2,
    renderCell: (params) => (
      <NotebookFolderContextMenu item={params.row}>{params.row.name}</NotebookFolderContextMenu>
    ),
  },
  {
    field: 'sourceEmail',
    headerName: 'Created by',
    flex: 2,
    renderCell: (params) => (
      <NotebookFolderContextMenu item={params.row}>
        {params.row.sourceEmail}
      </NotebookFolderContextMenu>
    ),
  },
  {
    field: 'createdTimestamp',
    headerName: 'Created',
    flex: 1,
    renderCell: (params) => {
      const date = params.value ? millisToLocale(new Date(params.value).getTime(), 'utc') : null;

      return (
        <NotebookFolderContextMenu item={params.row}>
          <Box
            component="div"
            display="flex"
          >
            {date}
          </Box>
        </NotebookFolderContextMenu>
      );
    },
  },
  {
    field: 'updatedTimestamp',
    headerName: 'Last Modified',
    flex: 1,
    renderCell: (params) => {
      const date = params.value ? millisToLocale(new Date(params.value).getTime(), 'utc') : null;

      return (
        <NotebookFolderContextMenu item={params.row}>
          <Box
            component="div"
            display="flex"
          >
            {date}
          </Box>
        </NotebookFolderContextMenu>
      );
    },
  },
  {
    field: 'share',
    headerName: ' ',
    sortable: false,
    width: 100,
    renderCell: (params) => {
      if (['FOLDER', 'NOTEBOOK'].includes(params.row.type)) {
        return (
          <Box
            component="div"
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            <Tooltip
              title={params.row.hasChildFolders ? 'Unable to share folders with subfolders' : ''}
              arrow
              disableInteractive
            >
              <span>
                <FolderRowButton
                  onClick={() => params.row.onShare()}
                  disabled={params.row.hasChildFolders}
                >
                  SHARE
                </FolderRowButton>
              </span>
            </Tooltip>
          </Box>
        );
      } else {
        return <Box component="div" />;
      }
    },
  },
  {
    field: 'edit',
    headerName: ' ',
    sortable: false,
    width: 100,
    renderCell: (params) => {
      return ['SHARED-FOLDER', 'SHARED-NOTEBOOK'].includes(params.row.type) ? (
        <></>
      ) : (
        <Box
          component="div"
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          <FolderRowButton onClick={() => params.row.onEdit()}>EDIT</FolderRowButton>
        </Box>
      );
    },
  },
  {
    field: 'copy',
    headerName: ' ',
    sortable: false,
    width: 100,
    renderCell: (params) => {
      if (params.row.type !== 'SHARED-NOTEBOOK') return <></>;
      return (
        <Box
          component="div"
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          <FolderRowButton onClick={() => params.row.onCopy()}>COPY</FolderRowButton>
        </Box>
      );
    },
  },
];
