import { DeleteRounded, EditRounded } from '@mui/icons-material';
import { Grid, IconButton, Tooltip, darken } from '@mui/material';
import { useState } from 'react';
import useAppStore from 'src/core/store';
import { useCurrentOrbits, useIsPropagating } from 'src/hooks/OrbitHooks';
import { useCurrentPage } from 'src/hooks/PageHooks';
import PropagatedCacheManager from 'src/models/PropagatedCacheManager';
import theme from 'src/pages/App/Theme';

import { SETTINGS_NAMES, useSettingsStore } from 'src/pages/Settings/store';
import { Maneuver } from 'src/types';
import { millisToLocale } from 'src/utilities/DateTimeUtils';
import { ObjectPanelDisplay } from '../ObjectPanelDisplay';
import { ObjectSummaryBody } from '../ObjectPanelDisplay.styled';
import { ModalDeleteManeuver } from './ModalDeleteManeuver';

interface ManeuverSummaryProps {
  color: string;
  maneuver: Maneuver;
}

const ManeuverSummaryControls = ({ maneuver }: { maneuver: Maneuver }) => {
  const [confirmDeleteModalVisible, setConfirmDeleteModalVisible] = useState(false);
  const setCustom = useSettingsStore((state) => state.setCustom);

  const updateTimelineRange = useAppStore((state) => state.timelines.updateTimelineRange);
  const currentManeuverTime = maneuver.timestamp.getTime();

  const currentPage = useCurrentPage();
  const currentOrbits = useCurrentOrbits();

  const isPropagating = useIsPropagating();

  return (
    <>
      <Grid
        item
        p={1}
        sx={{
          backgroundColor: darken(theme.palette.primary.main, 0.6),
          borderRadius: '0 5px 5px 0',
          display: 'flex',
          height: '100%',
          position: 'absolute',
          right: 0,
          top: 0,
        }}
      >
        <Grid
          item
          container
          direction="column"
          sx={{
            height: '100%',
            justifyContent: 'space-between',
            paddingBottom: 0,
            paddingTop: 0,
          }}
        >
          <Grid item>
            <Tooltip
              arrow
              disableInteractive
              title="Edit"
            >
              <IconButton
                aria-label="edit"
                size="small"
                onClick={() => {
                  updateTimelineRange({
                    currentTime: currentManeuverTime,
                    currentTimePreviewMode: currentManeuverTime,
                    playState: 'seeking',
                  });
                  if (!isPropagating && currentPage && currentOrbits) {
                    PropagatedCacheManager.propagateTimeline(currentPage, currentOrbits, true);
                  }
                  setCustom(SETTINGS_NAMES.MANEUVERS_FLYOUT_STATUS, maneuver.id);
                }}
              >
                <EditRounded />
              </IconButton>
            </Tooltip>
          </Grid>
          <Grid item>
            <Tooltip
              arrow
              disableInteractive
              title="Delete"
            >
              <IconButton
                aria-label="delete"
                size="small"
                onClick={() => setConfirmDeleteModalVisible(true)}
              >
                <DeleteRounded />
              </IconButton>
            </Tooltip>
          </Grid>
        </Grid>
      </Grid>
      <ModalDeleteManeuver
        open={confirmDeleteModalVisible}
        show={setConfirmDeleteModalVisible}
        editingManeuver={maneuver}
      />
    </>
  );
};

export const ManeuverSummary = ({ color, maneuver }: ManeuverSummaryProps) => {
  const currentPage = useCurrentPage();

  const maneuverInPageTime =
    currentPage &&
    maneuver.timestamp >= currentPage.startTime &&
    maneuver.timestamp <= currentPage.endTime;

  return (
    <>
      <ObjectPanelDisplay
        headerRow={
          <Grid color={!maneuverInPageTime ? theme.palette.error.main : 'inherit'}>
            {millisToLocale(maneuver.timestamp.getTime(), 'utc')}
            {!maneuverInPageTime && ' (Not in your time period)'}
          </Grid>
        }
        titleRow={maneuver.name}
        accentColor={color}
        panelControls={<ManeuverSummaryControls maneuver={maneuver} />}
      >
        <ObjectSummaryBody>
          Delta V Magnitude: {maneuver.deltaVelocity > 0 && '+'}
          {maneuver.deltaVelocity.toFixed(2)} km/s
        </ObjectSummaryBody>
      </ObjectPanelDisplay>
    </>
  );
};
