import { useSnackbar } from 'notistack';
import { useEffect } from 'react';
import useAppStore from 'src/core/store';

export const SnackbarStore = () => {
  const { enqueueSnackbar } = useSnackbar();

  const setSnackbarEnqueue = useAppStore((state) => state.setSnackbarEnqueue);

  useEffect(() => {
    setSnackbarEnqueue(enqueueSnackbar);
  }, [enqueueSnackbar, setSnackbarEnqueue]);

  return null;
};
