import {
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  Slider,
  Switch,
  Typography,
} from '@mui/material';
import { SETTINGS_NAMES, useSettingsStore } from '../Settings/store';
import { useState } from 'react';
import { useDebouncedCallback } from 'src/hooks/useDebouncedCallback';
import { ModalClearPropagation } from '../Notebook/ModalClearPropagation';
import { useIsPropagating } from 'src/hooks/OrbitHooks';

export const MaxFramerate = () => {
  const maxFramerateEnabled = SETTINGS_NAMES.MAX_FRAMERATE_ENABLED;

  const toggleSetting = useSettingsStore((state) => state.toggleSetting);

  const setFramerate = useSettingsStore((state) => state.setFramerate);

  const isMaxFramerateEnabled =
    useSettingsStore((state) => state.settings[maxFramerateEnabled]) || false;

  const [framerate, setFramerateLocal] = useState(useSettingsStore.getState().framerate);

  const [debouncedSetStore] = useDebouncedCallback((newVal) => setFramerate(newVal), 10);

  const handleFramerateChange = (_e: any, newValue: any) => {
    setFramerateLocal(parseInt(newValue, 10));

    debouncedSetStore(parseInt(newValue, 10));
  };

  const min = 1;
  const max = 120;
  const stepSize = (max - min) / 6;

  const marks = Array.from({ length: 7 }, (_, index) => {
    const value = Math.round((min + index * stepSize) / 10) * 10;
    return { value: value, label: value.toFixed(0) };
  });

  const [modalClearPropVisible, setModalClearPropVisible] = useState(false);
  const isPropagating = useIsPropagating();
  const checkPropagation = () => {
    if (isPropagating) {
      setModalClearPropVisible(true);
    }
  };

  return (
    <>
      <FormControl
        variant="standard"
        fullWidth
        sx={{
          pt: 2,
        }}
      >
        <Typography
          variant="h6"
          pb={1}
        >
          Max Framerate
        </Typography>
        <FormGroup
          sx={{
            pl: 2,
          }}
          onClick={checkPropagation}
        >
          <FormControlLabel
            control={
              <Switch
                size="small"
                disabled={isPropagating}
                checked={isMaxFramerateEnabled}
                onChange={() => {
                  toggleSetting(maxFramerateEnabled);
                }}
              />
            }
            label="Enable Max Framerate"
          />
        </FormGroup>
      </FormControl>
      <Grid
        onClick={checkPropagation}
        container
        item
        xs={3}
        p={1}
      >
        <Slider
          marks={marks}
          valueLabelDisplay="auto"
          min={min}
          max={max}
          value={framerate}
          onChange={handleFramerateChange}
          disabled={!isMaxFramerateEnabled || isPropagating}
        />
      </Grid>
      <ModalClearPropagation
        show={modalClearPropVisible}
        onClose={() => setModalClearPropVisible(false)}
      />
    </>
  );
};
