import { IconButton, Tooltip } from '@mui/material';
import { InfoRounded } from '@mui/icons-material';
import { grey } from '@mui/material/colors';
import { ReactNode } from 'react';

interface LabelWithTooltipProps {
  label: ReactNode;
  title: string;
}

export function LabelWithTooltip({ label, title }: LabelWithTooltipProps) {
  return (
    <>
      <span>{label}</span>
      <Tooltip
        title={title}
        disableInteractive
        arrow
      >
        <span
          style={{
            whiteSpace: 'nowrap',
          }}
        >
          {/* with nowrap above and ZERO WIDTH NO-BREAK SPACE the tooltip icon becomes attached to the last word of the label, so will line-break together */}
          {'\ufeff'}
          <IconButton
            sx={{
              px: 0.5,
              pt: 0,
              pb: 0.5,
            }}
          >
            <InfoRounded
              sx={{
                height: 'auto',
                fontSize: '12px',
                color: grey[500],
              }}
            />
          </IconButton>
        </span>
      </Tooltip>
    </>
  );
}
