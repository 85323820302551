import { muEarth } from 'src/constants';
import { COEData } from 'src/threejs/models/COE';
import { StateVectorType } from 'src/types';
import { Vector3 } from 'three';
import { radToDeg } from 'three/src/math/MathUtils';
import { DateTime } from 'luxon';

const TOLERANCE = 1e-15;

// Rotates vectors from PQW to ECI (xyz) used for position and velocity vectors
const rotatePqwToEci = (origin: Vector3, coe: COEData) => {
  const { inclination, rightAscensionOfAscendingNode, argumentOfPeriapsis } = coe;

  // convert degrees to radians
  const incRad = (Math.PI * inclination) / 180;
  const raanRad = (Math.PI * rightAscensionOfAscendingNode) / 180;
  const aopRad = (Math.PI * argumentOfPeriapsis) / 180;

  const rx =
    origin.x *
      (Math.cos(aopRad) * Math.cos(raanRad) -
        Math.sin(aopRad) * Math.cos(incRad) * Math.sin(raanRad)) -
    origin.y *
      (Math.sin(aopRad) * Math.cos(raanRad) +
        Math.cos(aopRad) * Math.cos(incRad) * Math.sin(raanRad)) +
    origin.z * (Math.sin(raanRad) * Math.sin(incRad));

  const ry =
    origin.x *
      (Math.cos(aopRad) * Math.sin(raanRad) +
        Math.sin(aopRad) * Math.cos(incRad) * Math.cos(raanRad)) +
    origin.y *
      (Math.cos(aopRad) * Math.cos(incRad) * Math.cos(raanRad) -
        Math.sin(aopRad) * Math.sin(raanRad)) -
    origin.z * (Math.cos(raanRad) * Math.sin(incRad));

  const rz =
    origin.x * (Math.sin(aopRad) * Math.sin(incRad)) +
    origin.y * (Math.cos(aopRad) * Math.sin(incRad)) +
    origin.z * Math.cos(incRad);

  return new Vector3(rx, ry, rz);
};

/**
 * Converts Keplarian orbit element into cartesian state vector position and velocity
 * @param {COEData} coe the coe data for the keplarian orbit element
 * @return x,y,z position and velocity entries of StateVectorType
 */
export const convertKeplarianToCartesian = (
  coe: COEData,
  gravitationalParam: number = muEarth,
): Partial<StateVectorType> => {
  const { eccentricity, semiMajorAxis, trueAnomaly } = coe;
  /**
   * formulas referenced from https://space.stackexchange.com/questions/19322/converting-orbital-elements-to-cartesian-state-vectors
   */

  // convert degrees to radians
  const trueAnomalyRadians = (Math.PI * trueAnomaly) / 180;

  const semiLatusRectum = semiMajorAxis * (1 - Math.pow(eccentricity, 2));

  const fixedFrameMagnitude = semiLatusRectum / (1 + eccentricity * Math.cos(trueAnomalyRadians));

  // position vector in PQW coordinate frame
  const positionInPqw = new Vector3(
    fixedFrameMagnitude * Math.cos(trueAnomalyRadians),
    fixedFrameMagnitude * Math.sin(trueAnomalyRadians),
    0,
  );

  // velocity vector in PQW coordinate frame
  const velocityInPqw = new Vector3(
    -Math.sin(trueAnomalyRadians),
    eccentricity + Math.cos(trueAnomalyRadians),
    0,
  ).multiplyScalar(Math.sqrt(gravitationalParam / semiLatusRectum));

  const positionInEci = rotatePqwToEci(positionInPqw, coe);
  const velocityInEci = rotatePqwToEci(velocityInPqw, coe);

  return {
    x_position: positionInEci.x,
    y_position: positionInEci.y,
    z_position: positionInEci.z,
    x_velocity: velocityInEci.x,
    y_velocity: velocityInEci.y,
    z_velocity: velocityInEci.z,
  };
};

export type COEDataOnly = Pick<
  COEData,
  | 'semiMajorAxis'
  | 'eccentricity'
  | 'inclination'
  | 'rightAscensionOfAscendingNode'
  | 'argumentOfPeriapsis'
  | 'trueAnomaly'
>;

export const convertCartesianToKeplerian = (
  position: Vector3,
  velocity: Vector3,
  gravitationalParam: number = muEarth,
): COEDataOnly => {
  /**
   * formulas referenced from https://downloads.rene-schwarz.com/download/M002-Cartesian_State_Vectors_to_Keplerian_Orbit_Elements.pdf
   *
   * additional python code to ref: https://github.com/RazerM/orbital/blob/c4766628e0361d456ca3c32b72d5a3c273f23bad/orbital/utilities.py
   * handles the i=0 and e=0 cases
   *
   * validation form for pos+vel -> keplerian: https://elainecoe.github.io/orbital-mechanics-calculator/calculator.html
   */

  const angularMomentum = position.clone().cross(velocity.clone());

  const nodeVector = new Vector3(0, 0, 1).cross(angularMomentum.clone());

  const eccentricityVector = velocity
    .clone()
    .cross(angularMomentum.clone())
    .divideScalar(gravitationalParam)
    .sub(position.clone().divideScalar(position.length()));

  const eccentricAnomaly = velocity.length() ** 2 / 2 - gravitationalParam / position.length();

  const semiMajorAxis = -gravitationalParam / (2 * eccentricAnomaly);

  const eccentricity = eccentricityVector.length();

  const inclination = Math.acos(angularMomentum.z / angularMomentum.length());

  let rightAscensionOfAscendingNode;
  let argumentOfPeriapsis;
  let trueAnomaly;

  if (Math.abs(inclination) < TOLERANCE) {
    rightAscensionOfAscendingNode = 0;
    if (Math.abs(eccentricity) < TOLERANCE) {
      argumentOfPeriapsis = 0;
    } else {
      /**
       * using the atan2 version below as it handles equatorial case
       * argumentOfPeriapsis = Math.acos(eccentricityVector.x / eccentricityVector.length());
       */
      argumentOfPeriapsis = Math.atan2(eccentricityVector.y, eccentricityVector.x) % (2 * Math.PI);
    }
  } else {
    rightAscensionOfAscendingNode = Math.acos(nodeVector.x / nodeVector.length());
    if (nodeVector.y < 0) {
      rightAscensionOfAscendingNode = 2 * Math.PI - rightAscensionOfAscendingNode;
    }
    argumentOfPeriapsis = Math.acos(
      nodeVector.clone().dot(eccentricityVector.clone()) /
        (nodeVector.length() * eccentricityVector.length()),
    );
  }

  if (Math.abs(eccentricity) < TOLERANCE) {
    if (Math.abs(inclination) < TOLERANCE) {
      trueAnomaly = Math.acos(position.x / position.length());
      if (velocity.x > 0) {
        trueAnomaly = 2 * Math.PI - trueAnomaly;
      }
    } else {
      trueAnomaly = Math.acos(
        nodeVector.clone().dot(position.clone()) / (nodeVector.length() * position.length()),
      );
      if (nodeVector.clone().dot(velocity.clone()) > 0) {
        trueAnomaly = 2 * Math.PI - trueAnomaly;
      }
    }
  } else {
    if (eccentricityVector.z < 0) {
      argumentOfPeriapsis = 2 * Math.PI - argumentOfPeriapsis;
    }
    trueAnomaly = Math.acos(
      eccentricityVector.clone().dot(position.clone()) /
        (eccentricityVector.length() * position.length()),
    );
    if (position.clone().dot(velocity.clone()) < 0) {
      trueAnomaly = 2 * Math.PI - trueAnomaly;
    }
  }

  if (isNaN(argumentOfPeriapsis)) {
    console.warn('argumentOfPeriapsis isNaN');
    argumentOfPeriapsis = 0;
  }
  if (isNaN(trueAnomaly)) {
    console.warn('trueAnomaly isNaN');
    trueAnomaly = 0;
  }

  return {
    argumentOfPeriapsis,
    eccentricity,
    inclination,
    rightAscensionOfAscendingNode,
    semiMajorAxis,
    trueAnomaly,
  };
};

export const computeManeuverVelocity = (
  stateVector: StateVectorType,
  maneuverVelocities: Vector3,
): Vector3 => {
  const positionDir = new Vector3(
    stateVector.y_position / 1000,
    stateVector.z_position / 1000,
    stateVector.x_position / 1000,
  );

  const velocityDir = new Vector3(
    stateVector.y_velocity / 1000,
    stateVector.z_velocity / 1000,
    stateVector.x_velocity / 1000,
  );

  const maneuverDirR = positionDir.clone();

  const maneuverDirC = positionDir.clone().cross(velocityDir);

  const maneuverDirI = maneuverDirC.clone().cross(maneuverDirR);

  maneuverDirR.setLength(maneuverVelocities.x);
  maneuverDirI.setLength(maneuverVelocities.y);
  maneuverDirC.setLength(maneuverVelocities.z);

  return velocityDir.clone().add(maneuverDirR).add(maneuverDirI).add(maneuverDirC);
};

export interface OrbitStateWithKeplerianCartesian {
  coe: COEDataOnly;
  epoch: DateTime | null;
  position: Vector3;
  velocity: Vector3;
}

export const getOrbitStateFromStateVectorString = (
  pastedSV: string,
): OrbitStateWithKeplerianCartesian | undefined => {
  if (!pastedSV) {
    return undefined;
  }
  // split on any number of white spaces, and then filter those results out
  const inputStringParts = pastedSV.split(/(\s+)/).filter((e) => {
    return e.trim().length > 0;
  });

  if (inputStringParts.length < 6 || inputStringParts.length > 7) {
    return undefined;
  }

  let date = null;

  if (inputStringParts.length === 7) {
    // 7 parts means date + pos + vel
    const dateString = inputStringParts.shift();
    date = DateTime.fromFormat(dateString!, 'yyyyoooHHmmss.SSS');
    if (!date.isValid) {
      date = DateTime.fromISO(dateString!);
    }
    if (!date.isValid) {
      return undefined;
    }
  }

  const inputParts = inputStringParts.map((someString) => parseFloat(someString));
  if (inputParts.some((value) => isNaN(value))) {
    return undefined;
  }

  const position = new Vector3(inputParts[0], inputParts[1], inputParts[2]);
  const velocity = new Vector3(inputParts[3], inputParts[4], inputParts[5]);

  const coe = convertCartesianToKeplerian(position, velocity);
  coe.argumentOfPeriapsis = radToDeg(coe.argumentOfPeriapsis);
  coe.inclination = radToDeg(coe.inclination);
  coe.rightAscensionOfAscendingNode = radToDeg(coe.rightAscensionOfAscendingNode);
  coe.trueAnomaly = radToDeg(coe.trueAnomaly);

  return {
    coe,
    epoch: date,
    position,
    velocity,
  };
};
