import { useCallback } from 'react';
import { useCurrentOrbits, useIsPropagating } from 'src/hooks/OrbitHooks';
import { useCurrentPage } from 'src/hooks/PageHooks';
import PropagatedCacheManager, { OrbitIdentifier } from 'src/models/PropagatedCacheManager';
import WebSocketManager from 'src/services/WebSocketManager';
import use3DOrbitStore from 'src/threejs/components/OrbitManager/store/store';
import { OrbitObject } from 'src/types';
import { getUpdateTimelineRange } from './getters';
import {
  selectActiveTimeline,
  selectCurrentTime,
  selectPlaySpeed,
  selectPlayState,
  selectTimelineState,
  selectUpdateTimelineRange,
} from './selectors';
import useAppStore from './store';
import { useOrbitRicStore } from 'src/threejs/components/OrbitManager/RIC/orbitRicStore';

export function useTimeline() {
  return useAppStore(selectTimelineState);
}

export function useActiveTimeline() {
  return useAppStore(selectActiveTimeline);
}

export function useCurrentTime() {
  return useAppStore(selectCurrentTime);
}

export function usePlaySpeed() {
  return useAppStore(selectPlaySpeed);
}

export function usePlayState() {
  return useAppStore(selectPlayState);
}

export function useUpdateTimelineRange() {
  return useAppStore(selectUpdateTimelineRange);
}

export function useFetchPropagations() {
  const currentPage = useCurrentPage();
  const currentOrbits = useCurrentOrbits();
  const isPropagating = useIsPropagating();

  return useCallback(
    (orbitsToPropagate: OrbitObject[] | undefined = currentOrbits) => {
      if (!currentPage?.startTime || !orbitsToPropagate) return;

      const updateTimelineRange = getUpdateTimelineRange();
      updateTimelineRange({
        playState: isPropagating ? 'stopped' : 'loading',
        currentTime: currentPage.startTime.getTime(),
        currentTimePreviewMode: currentPage.startTime.getTime(),
      });
      PropagatedCacheManager.setPropagatingOrbits(orbitsToPropagate);
      PropagatedCacheManager.propagateTimeline(currentPage, orbitsToPropagate, true);
    },
    [isPropagating, currentPage, currentOrbits],
  );
}

// Sends a propagation request for a single new orbit
// can be used if a new orbit is created in panel/imported via catalog while propagating
export function useFetchNewPropagation() {
  const currentOrbits = useCurrentOrbits();
  const fetchPropagations = useFetchPropagations();

  return useCallback(
    (newOrbit: OrbitObject) => {
      if (!currentOrbits) return;

      const { id: newId, name: newName } = newOrbit;
      let newOrbitIds: Array<OrbitIdentifier> = [];
      if (currentOrbits) {
        newOrbitIds = [
          { id: newId, name: newName },
          ...currentOrbits.map(({ id, name }) => ({ id, name })),
        ];
      }
      PropagatedCacheManager.setPropagatingOrbits(newOrbitIds);
      fetchPropagations([newOrbit]);
    },
    [fetchPropagations, currentOrbits],
  );
}

export function useClearPropagations() {
  const currentOrbits = useCurrentOrbits();
  const updateTimelineRange = useUpdateTimelineRange();
  const currentPage = useCurrentPage();

  const clearPropagations = useClearLoadingPropagation();
  const clearOrbitRICStore = useOrbitRicStore.getState().clearStore;

  return useCallback(async () => {
    if (!currentPage || !currentOrbits) {
      return;
    }

    await clearPropagations();

    // Reset timeline
    updateTimelineRange({
      currentTime: currentPage.startTime.getTime(),
      playState: 'stopped',
    });

    clearOrbitRICStore();
  }, [clearOrbitRICStore, currentPage, currentOrbits, updateTimelineRange, clearPropagations]);
}

export function useClearLoadingPropagation() {
  const currentOrbits = useCurrentOrbits();
  const currentPage = useCurrentPage();

  const clearOrbitRICStore = useOrbitRicStore.getState().clearStore;

  return useCallback(async () => {
    if (!currentPage || !currentOrbits) {
      return;
    }

    const currentOrbitsIds = currentOrbits.map((orbit) => orbit.id);

    // Get all of the 3D orbit states for the current orbits
    const current3DOrbits = Object.entries(use3DOrbitStore.getState().orbits).filter(([id]) =>
      currentOrbitsIds.includes(Number(id)),
    );

    // Reset socket subscription
    await WebSocketManager.resetSubscription();

    // Clear cache
    PropagatedCacheManager.clearCurrentlyPropagatingOrbits();

    // Clear propagated orbit paths
    current3DOrbits.forEach(([_, orbitState]) => {
      orbitState.orbitPropagationPath.reset();
    });

    clearOrbitRICStore();
  }, [clearOrbitRICStore, currentPage, currentOrbits]);
}

export function useSocketState() {
  return useAppStore((state) => state.socket);
}
