import { MarkerHandleProps } from 'src/types';
import SmaPerigeeHandle from './SmaPerigeeHandle';

const SmaPerigeeMarker = ({ handle, setHandle }: MarkerHandleProps) => {
  return (
    <SmaPerigeeHandle
      handle={handle}
      setHandle={setHandle}
    />
  );
};

export default SmaPerigeeMarker;
