import { Vector3 } from 'three';
import { COE_PROP_Type } from './enums';
import { coeInfo } from './pages/Notebook/components/coeInfo';
import { COE } from './threejs/models/COE';
import { ManipulatorName, TextLabelXAnchorPos, TextLabelYAnchorPos } from './threejs/types';
import {
  AxesProperties,
  BookmarkAdditionalProperties,
  Cartesian,
  GroundObjectAdditionalProperties,
  GroupAdditionalProperties,
  LiveDataMode,
  OrbitAdditionalProperties,
  PageAdditionalProperties,
  Perturbations,
  SpaceObjectShape,
  SpaceObjectSize,
  SpaceSensor,
  SpaceSensorAdditionalProperties,
  ViewportAdditionalProperties,
} from './types';

// Keplerian Constants
export const earthradius = 6378.137; // km
export const scaleFactor = 1 / earthradius; // 1e-4;
export const muEarth = 3.986e5; // Earth standard gravitational parameter (G*M): 398,600 km^3/s^2

// Values in array represent in order [sma, e, i, raan, w, ta].
export const ORBIT_TEMPLATES: Record<string, number[]> = {
  'LEO - Circular': [6778.14, 0, 72, 30, 0, 0],
  MEO: [26610, 0, 55, 0, 0, 0],
  GEO: [42164, 0, 0, 0, 0, 0],
  'Sun Synchronous': [7083, 0, 98.2, 278.7, 270, 0],
  Molniya: [26610.223, 0.75, 63.45, 280, 270, 23.64],
};

export const ORBIT_TEMPLATE_HASHES = new Map<string, string>();

Object.keys(ORBIT_TEMPLATES).forEach((key) => {
  ORBIT_TEMPLATE_HASHES.set(ORBIT_TEMPLATES[key].join(), key);
});

export const LABEL_SIZE = {
  DEFAULT: 0.12,
  RIC_ACTIVE: 0.015,
  LINE_OF_SIGHT_KM: 0.09,
};

export const EMAIL_REGEX =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

/** Speed of earths rotation in speed in radians per millisecond.
 * This is the same value orekit uses:
 * https://www.orekit.org/static/apidocs/org/orekit/utils/Constants.html#IERS96_EARTH_ANGULAR_VELOCITY
 */
export const earthAngularVelocity = 7.292115e-8; // more accurrate: 7.29211840505999

export const PROPAGATION_STEPS_PER_REVOLUTION = 300;

export const UNKNOWN_NAME: ManipulatorName = 'unknown';
export const AOP_MARKER_NAME = 'AOP Marker';
export const AOP_MARKER_HANDLE_NAME: ManipulatorName = 'AOP';
export const ECC_MARKER_NAME = 'ECC Marker';
export const ECC_MARKER_HANDLE_NAME: ManipulatorName = 'ECC';
export const INC_MARKER_NAME = 'INC Marker';
export const INC_MARKER_HANDLE_NAME: ManipulatorName = 'INC';
export const RAAN_MARKER_NAME = 'RAAN Marker';
export const RAAN_MARKER_HANDLE_NAME: ManipulatorName = 'RAAN';
export const SMA_MARKER_NAME = 'SMA Marker';
export const SMA_MARKER_HANDLE_NAME: ManipulatorName = 'SMA';
export const TA_MARKER_NAME = 'TA Marker';
export const TA_MARKER_HANDLE_NAME: ManipulatorName = 'TA';

export const COE_PARAMETERS = [
  {
    name: 'semiMajorAxis',
    displayName: 'Semimajor Axis',
    step: 1000,
    min: 6379,
    max: 50000,
    unit: 'km',
    value: 6379,
    text: '6379',
    type: COE_PROP_Type.SLIDER,
    tooltipText: coeInfo['SMA'].text,
    notation: coeInfo['SMA'].notation,
    selector: (coe?: COE) => coe?.semiMajorAxis,
    markerAndHandleNames: [SMA_MARKER_HANDLE_NAME, SMA_MARKER_NAME],
  },
  {
    name: 'eccentricity',
    displayName: 'Eccentricity',
    step: 0.01,
    min: 0,
    max: 1,
    unit: '',
    value: 0,
    text: '0',
    type: COE_PROP_Type.SLIDER,
    tooltipText: coeInfo['ECC'].text,
    notation: coeInfo['ECC'].notation,
    selector: (coe?: COE) => coe?.eccentricity,
    markerAndHandleNames: [ECC_MARKER_HANDLE_NAME, ECC_MARKER_NAME],
  },
  {
    name: 'inclination',
    displayName: 'Inclination',
    step: 0.5,
    min: 0,
    max: 180,
    unit: 'deg',
    value: 0,
    text: '0',
    type: COE_PROP_Type.SLIDER,
    tooltipText: coeInfo['INC'].text,
    notation: coeInfo['INC'].notation,
    selector: (coe?: COE) => coe?.inclination,
    markerAndHandleNames: [INC_MARKER_HANDLE_NAME, INC_MARKER_NAME],
  },
  {
    name: 'rightAscensionOfAscendingNode',
    displayName: 'Right Ascension of the Ascending Node',
    step: 1,
    min: 0,
    max: 360,
    unit: 'deg',
    value: 0,
    text: '0',
    type: COE_PROP_Type.SLIDER,
    tooltipText: coeInfo['RAAN'].text,
    notation: coeInfo['RAAN'].notation,
    selector: (coe?: COE) => coe?.rightAscensionOfAscendingNode,
    markerAndHandleNames: [RAAN_MARKER_HANDLE_NAME, RAAN_MARKER_NAME],
  },
  {
    name: 'argumentOfPeriapsis',
    displayName: 'Argument of Periapsis',
    step: 1,
    min: 0,
    max: 360,
    unit: 'deg',
    value: 0,
    text: '0',
    type: COE_PROP_Type.SLIDER,
    tooltipText: coeInfo['AP'].text,
    notation: coeInfo['AP'].notation,
    selector: (coe?: COE) => coe?.argumentOfPeriapsis,
    markerAndHandleNames: [AOP_MARKER_HANDLE_NAME, AOP_MARKER_NAME],
  },
  {
    name: 'trueAnomaly',
    displayName: 'True Anomaly',
    step: 1,
    min: 0,
    max: 360,
    unit: 'deg',
    value: 0,
    text: '0',
    type: COE_PROP_Type.SLIDER,
    tooltipText: coeInfo['TA'].text,
    notation: coeInfo['TA'].notation,
    selector: (coe?: COE) => coe?.trueAnomaly,
    markerAndHandleNames: [TA_MARKER_HANDLE_NAME, TA_MARKER_NAME],
  },
];

interface MarkerPriorityProps {
  [SMA_MARKER_HANDLE_NAME]?: number;
  [SMA_MARKER_NAME]?: number;
  [ECC_MARKER_HANDLE_NAME]?: number;
  [ECC_MARKER_NAME]?: number;
  [INC_MARKER_HANDLE_NAME]?: number;
  [INC_MARKER_NAME]?: number;
  [RAAN_MARKER_HANDLE_NAME]?: number;
  [RAAN_MARKER_NAME]?: number;
  [AOP_MARKER_HANDLE_NAME]?: number;
  [AOP_MARKER_NAME]?: number;
  [TA_MARKER_HANDLE_NAME]?: number;
  [TA_MARKER_NAME]?: number;
  [UNKNOWN_NAME]: number;
}

// marker selection priority is based off of the top to bottom ordering of
// sliders in the inspector window, which is dictated by array indices
// the earlier in array, the higher the priority level for marker/handle selection
export const MARKER_PRIORITIES: MarkerPriorityProps = COE_PARAMETERS.reduce(
  (acc, curr, idx) => {
    const priority = -(idx + 1); // invert index to be negative; higher number will equal higher priority
    const [markerHandleName, markerName] = curr.markerAndHandleNames;
    return { ...acc, [markerHandleName]: priority, [markerName]: priority };
  },
  { [UNKNOWN_NAME]: -999 },
);

export const ORBIT_TYPES = {
  COE: 'COE', // Keplerian
  STATE_VECTORS: 'STATE_VECTORS', //   Cartesian
  TLE: 'TLE',
  EPHEMERIS: 'EPHEMERIS',
  LAUNCH: 'LAUNCH',
};

export const PERTURBATIONS_DEFAULTS: Record<string, Perturbations> = {
  COE: {
    j2: false,
    drag: false,
    solarRadiation: false,
  },
  TLE: {},
};

export const TLE_PLACEHOLDER_TEXT = `ISS (ZARYA)
1 25544U 98067A   08264.51782528 -.00002182  00000-0 -11606-4 0  2927
2 25544  51.6416 247.4627 0006703 130.5360 325.0288 15.72125391563537`;

export const TLE_ORBIT_TYPE_DISPLAY = 'TLE';
export const COE_ORBIT_TYPE_DISPLAY = 'Keplerian Orbit';
export const SV_ORBIT_TYPE_DISPLAY = 'Cartesian Orbit';
export const RELATIVE_ORBIT_TYPE_DISPLAY = 'Relative';

export const ORBIT_COLOR_DEFAULT = '#ffffff';
export const COLOR_EARTH_REFERENCE_GRID = '#525252';
export const SPACE_OBJECT_COLOR_DEFAULT = '#8000ff';
export const SPACE_OBJECT_SHAPE_DEFAULT = SpaceObjectShape.CUBE;
export const SPACE_OBJECT_SIZE_DEFAULT = SpaceObjectSize.SM;
export const GROUND_OBJECT_WIREFRAME_COLOR_DEFAULT = '#ffffff';
export const GROUND_OBJECT_SHADER_COLOR_DEFAULT = '#ffff00';

export const COLOR_GROUND_OBJECT = '#ffffff';

export const COLOR_SLINGSHOT_ORANGE = '#ff5722';

export const LATLONG_COLOR = '#ffffff';
export const LATLONG_COLOR_PRIME = '#dfd187';

export const ORBIT_OBJECT_LABEL_TEXT_POS_LEFT_SCREEN: TextLabelXAnchorPos = 'left';
export const ORBIT_OBJECT_LABEL_TEXT_POS_RIGHT_SCREEN: TextLabelXAnchorPos = 'right';
export const ORBIT_OBJECT_LABEL_TEXT_POS_TOP_SCREEN: TextLabelYAnchorPos = 'top';
export const ORBIT_OBJECT_LABEL_TEXT_POS_BOTTOM_SCREEN: TextLabelYAnchorPos = 'bottom';

export const DEFAULT_ADDITIONAL_PROPERTIES_ORBIT: OrbitAdditionalProperties = {
  color: ORBIT_COLOR_DEFAULT,
  trackLength: -1,
  trackLength3D: -1,
  visGroundTracks: false,
  visLabel: true,
  visOrbit: true,
  spaceObjectColor: SPACE_OBJECT_COLOR_DEFAULT,
  spaceObjectSize: SPACE_OBJECT_SIZE_DEFAULT,
  spaceObjectShape: SPACE_OBJECT_SHAPE_DEFAULT,
  groundTrackObjectSize: SPACE_OBJECT_SIZE_DEFAULT,
  groundTrackObjectShape: SPACE_OBJECT_SHAPE_DEFAULT,
  visPositionVec: false,
  visVelocityVec: false,
  visAngularMomentumVec: false,
  visAscendingNodeVec: false,
  visEccentricityVec: false,
  liveDataMode: LiveDataMode.Keplerian,
};

export const DEFAULT_ADDITIONAL_PROPERTIES_GROUP: GroupAdditionalProperties = {
  expanded: true,
  visOrbits: true,
};

export const DEFAULT_ADDITIONAL_PROPERTIES_GROUND_OBJECTS: GroundObjectAdditionalProperties = {
  visLabel: true,
  visGroundObj: true,
  visGroundObjConeShader: false,
  visGroundObjConeWireframe: false,
  coneShaderColor: GROUND_OBJECT_SHADER_COLOR_DEFAULT,
  coneWireframeColor: GROUND_OBJECT_WIREFRAME_COLOR_DEFAULT,
  coneShaderOpacity: 25,
  coneWireframeOpacity: 100,
  locationColor: GROUND_OBJECT_SHADER_COLOR_DEFAULT,
};

export const DEFAULT_SPACE_SENSOR: Partial<SpaceSensor> = {
  enabled: false,
  minGroundElevationAngle: 10,
  fieldOfView: 10,
  targetIlluminated: false,
};

export const DEFAULT_ADDITIONAL_PROPERTIES_SPACE_SENSORS: SpaceSensorAdditionalProperties = {
  forConeShaderColor: '#FF5722',
  forConeShaderOpacity: 50,
  forConeWireframeColor: '#000000',
  forConeWireframeOpacity: 70,
  visFORConeShader: true,
  visFORConeWireframe: false,

  fovConeShaderColor: '#ffffff',
  fovConeShaderOpacity: 50,
  fovConeWireframeColor: '#000000',
  fovConeWireframeOpacity: 70,
  visFOVConeShader: true,
  visFOVConeWireframe: false,
};

export const DEFAULT_ADDITIONAL_PROPERTIES_PAGE: PageAdditionalProperties = {
  lightingEnabled: false,
  lightingSun: 50,
  lightingNight: 50,
  visEarth: true,
  visOrbitLabels: true,
  visSunVector: true,
  visFrameVectorLabels: false,
  visLatLongLines: true,
  visCountries: true,
  longFormLabels: false,
};

export const DEFAULT_ADDITIONAL_PROPERTIES_VIEWPORT: ViewportAdditionalProperties = {
  visStarField: true,
  visGrid: true,
  cameraPosition: { x: 3, y: 3, z: 3 },
  cameraTarget: { x: 0, y: 0, z: 0 },
};

export const DEFAULT_ADDITIONAL_PROPERTIES_BOOKMARK: BookmarkAdditionalProperties = {
  cameraOrientations: {},
};

export const DEFAULT_AXES_PROPERTIES: Record<string, AxesProperties> = {
  X: {
    label: 'X',
    labelRIC: 'R',
    color: '#ff3653',
  },
  Y: {
    label: 'Y',
    labelRIC: 'I',
    color: '#0adb50',
  },
  Z: {
    label: 'Z',
    labelRIC: 'C',
    color: '#2c8fdf',
  },
};

export const TIME_TWEEN_CAMERA_MOVEMENT = 0.6;

export const RIC_REF_GRID_SCALE = 0.01;
export const SCALE_FACTOR_RIC_REF = scaleFactor / RIC_REF_GRID_SCALE;

export const RIC_REF_GRID_DIVISORS = 10;

export type RICRefGridInfo = {
  sizeGrid: number;
  scaleValue: string;
};

export const RIC_REF_GRID_1_KM_DIST = 0.003;

export const GRID_HELPER_SCALES: Record<number, RICRefGridInfo> = {
  0.0003: {
    sizeGrid: 0.25 * RIC_REF_GRID_DIVISORS * SCALE_FACTOR_RIC_REF,
    scaleValue: '.25km',
  },
  [RIC_REF_GRID_1_KM_DIST]: {
    sizeGrid: 1 * RIC_REF_GRID_DIVISORS * SCALE_FACTOR_RIC_REF,
    scaleValue: '1km',
  },
  0.03: {
    sizeGrid: 10 * RIC_REF_GRID_DIVISORS * SCALE_FACTOR_RIC_REF,
    scaleValue: '10km',
  },
  0.6: {
    sizeGrid: 100 * RIC_REF_GRID_DIVISORS * SCALE_FACTOR_RIC_REF,
    scaleValue: '100km',
  },
  1.2: {
    sizeGrid: 1000 * RIC_REF_GRID_DIVISORS * SCALE_FACTOR_RIC_REF,
    scaleValue: '1,000km',
  },
  10: {
    sizeGrid: 10000 * RIC_REF_GRID_DIVISORS * SCALE_FACTOR_RIC_REF,
    scaleValue: '10,000km',
  },
};

// maneuver flyout constants
export const RIC_VELOCITY_RANGE_MAX = 5;
export const RIC_VELOCITY_RANGE_MIN = -5;

export const SWATCH_PRESETS: Array<Record<'hex', string>> = [
  {
    hex: '#ffe100',
  },
  {
    hex: '#00ff40',
  },
  {
    hex: '#ff0e22',
  },
  {
    hex: '#9a00fa',
  },
  {
    hex: '#002AFF',
  },
  {
    hex: '#fff',
  },
];

export const MANEUVER_FLYOUT_STATUSES = {
  CLOSED: undefined,
  CREATING: -1,
};

export const LAUNCH_FLYOUT_STATUS: Record<string, string> = {
  CLOSED: 'CLOSED',
  CREATING: 'CREATING',
};

export const LATITUDE_RANGE_DEG_MAX = 90;
export const LATITUDE_RANGE_DEG_MIN = -90;
export const LONGITUDE_RANGE_DEG_MAX = 180;
export const LONGITUDE_RANGE_DEG_MIN = -180;
export const ALTITUDE_RANGE_KM_MAX = 19;
export const ALTITUDE_RANGE_KM_MIN = 0;
export const SENSOR_RANGE_KM_MAX = 50000;
export const SENSOR_RANGE_KM_MIN = 0;
export const MIN_ELEVATION_RANGE_DEG_MAX = 90;
export const MIN_ELEVATION_RANGE_DEG_MIN = 0;
export const MAX_CONE_ZOOM_LEVEL = 50;
export const MIN_CONE_ZOOM_LEVEL = 3;

export const DEFAULT_GROUND_OBJECT_LOCATION = {
  latitude: 30.2770989,
  longitude: -97.744925,
  altitude: 0.166,
  minElevationAngle: 10,
  sensorRange: earthradius,
};

export const GROUND_OBJECT_TOOLTIP_INFO = {
  targetIlluminationInfo: `
      When this viewing constraint is enabled,
      the target must be illuminated by the sun
      in order for a valid viewing opportunity to be generated.
  `,
  groundObjectDarkness: `
      When this viewing constraint is enabled,
      the ground station must be in darkness
      in order for a valid viewing opportunity to be generated.
  `,
};

export const CAMERA_POSITION_DEFAULT: Cartesian = {
  x: 3,
  y: 3,
  z: 3,
};

export const CAMERA_POSITION_DEFAULT_RIC: Cartesian = {
  x: 0,
  y: 0,
  z: 0.3,
};

export const ARROWHELPER_LENGTH_SCALE = 0.5;

export const VISUAL_AID_HEX = '#bd7b00';
export const EARTH_ORIGIN = new Vector3(0, 0, 0);

export const AXES_HELPER_LENGTHS = 2;
export const RIC_GRID_HELPER_LENGTHS = 1;
export const VISUAL_AID_LENGTH_DEFAULT = 1;
export const DATE_TIME_DISPLAY_FORMAT = 'MM-dd-yyyy HH:mm:ss.SSS';

export const SEARCH_SPACE_CATALOG_TIMEOUT_MS = 10000;

export const CONVERSION_TOLERANCE = 1e-12;
export const TEXT_TLE_WARNING = 'TLE Epoch is more than 7 days from Page Start Time';

export const DATAGRID_ROWS_PER_PAGE_OPTIONS = [5, 10, 25];
export const DATAGRID_ROWS_PER_PAGE_DEFAULT = DATAGRID_ROWS_PER_PAGE_OPTIONS[2];
export const WEEK_IN_MILLISECONDS = 1000 * 60 * 60 * 24 * 7;
export const MAX_SEARCH_RESULTS = 100;

export const CONTENT = {
  STATUS: {
    ORBIT: {
      LOADING_PROPAGATION: 'This orbit is loading propagation data.',
    },
  },
  ERRORS: {
    ORBIT: {
      EARTH_INTERSECT: 'This orbit intersects with the Earth.',
      ESCAPE_VELOCITY: 'This orbit will reach escape velocity.',
    },
    LAUNCH: {
      LOCATION_INVALID:
        'The launch site latitude is outside the valid range for orbit inclination.',
    },
  },
};

export const GROUND_TRACK_OBJECT_SCALE_FACTOR = 0.02;

export const COLORS_LAUNCH_PREVIEW = {
  WHITE: 0xffffff,
  GREEN: 0x00e425,
};

export const LAUNCH_WINDOWS_COLOR_ACTIVE = '#00D43B';
export const EPHEM_MAX_FILE_SIZE_MB = 32;
export const EPHEM_MAX_FILE_SIZE = EPHEM_MAX_FILE_SIZE_MB * 1024 * 1024; // 32MB in bytes
export const WEBSOCKET_HEARTBEAT_INTERVAL_INCOMING_OUTGOING_MS = 10 * 1000;
