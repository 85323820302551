import { ARROWHELPER_LENGTH_SCALE, earthradius } from 'src/constants';
import { SETTINGS_NAMES, useSettingsStore } from 'src/pages/Settings/store';
import { ArrowVector } from 'src/threejs/components/ArrowVector';
import { StateVectorType } from 'src/types';
import { Vector3 } from 'three';

interface ManeuverHelpersProps {
  stateVector: StateVectorType;
  maneuverVelocities: Vector3;
}

export const ManeuverPreviewHelpers = ({
  stateVector,
  maneuverVelocities,
}: ManeuverHelpersProps) => {
  const namePosition = SETTINGS_NAMES.VECTOR_HELPER_POSITION_ENABLED;
  const nameVelocities = SETTINGS_NAMES.VECTOR_HELPER_VELOCITIES_ENABLED;

  const vectorHelperPositionEnabled =
    useSettingsStore((state) => state.settings[namePosition]) || false;

  const vectorHelperVelocitiesEnabled =
    useSettingsStore((state) => state.settings[nameVelocities]) || false;

  const positionDir = new Vector3(
    stateVector.x_position / 1000,
    stateVector.y_position / 1000,
    stateVector.z_position / 1000,
  );
  const positionOrigin = new Vector3(0, 0, 0);
  const positionLength = positionDir.length() / earthradius;
  const positionHex = '#ff0000';

  const velocityDir = new Vector3(
    stateVector.x_velocity / 1000,
    stateVector.y_velocity / 1000,
    stateVector.z_velocity / 1000,
  );
  const velocityOrigin = new Vector3(
    stateVector.x_position / earthradius / 1000,
    stateVector.y_position / earthradius / 1000,
    stateVector.z_position / earthradius / 1000,
  );
  const velocityLength = velocityDir.length();
  const velocityHex = '#ffff00';

  const maneuverDirR = positionDir.clone();

  const maneuverDirC = positionDir.clone().cross(velocityDir);

  const maneuverDirI = maneuverDirC.clone().cross(maneuverDirR);

  maneuverDirR.setLength(maneuverVelocities.x);
  const maneuverLengthR = maneuverVelocities.x;
  maneuverDirI.setLength(maneuverVelocities.y);
  const maneuverLengthI = maneuverVelocities.y;
  maneuverDirC.setLength(maneuverVelocities.z);
  const maneuverLengthC = maneuverVelocities.z;

  const maneuverHex = '#ff8800';

  const velocityCombined = velocityDir
    .clone()
    .add(maneuverDirR)
    .add(maneuverDirI)
    .add(maneuverDirC);
  const velocityCombinedLength = velocityCombined.length();
  const velocityCombinedHex = '#33ff33';

  return (
    <>
      {vectorHelperPositionEnabled && (
        <ArrowVector
          color={positionHex}
          staticDirection={positionDir.normalize()}
          staticLength={positionLength}
          staticOrigin={positionOrigin}
        />
      )}

      {vectorHelperVelocitiesEnabled && (
        <ArrowVector
          color={velocityHex}
          staticDirection={velocityDir.normalize()}
          staticLength={velocityLength}
          staticOrigin={velocityOrigin}
        />
      )}

      <ArrowVector
        color={maneuverHex}
        staticDirection={maneuverDirR.normalize()}
        staticLength={Math.abs(maneuverLengthR * ARROWHELPER_LENGTH_SCALE)}
        staticOrigin={velocityOrigin}
      />
      <ArrowVector
        color={maneuverHex}
        staticDirection={maneuverDirI.normalize()}
        staticLength={Math.abs(maneuverLengthI * ARROWHELPER_LENGTH_SCALE)}
        staticOrigin={velocityOrigin}
      />
      <ArrowVector
        color={maneuverHex}
        staticDirection={maneuverDirC.normalize()}
        staticLength={Math.abs(maneuverLengthC * ARROWHELPER_LENGTH_SCALE)}
        staticOrigin={velocityOrigin}
      />

      {vectorHelperVelocitiesEnabled && (
        <ArrowVector
          color={velocityCombinedHex}
          staticDirection={velocityCombined.normalize()}
          staticLength={velocityCombinedLength * ARROWHELPER_LENGTH_SCALE}
          staticOrigin={velocityOrigin}
        />
      )}

      <boxHelper />
    </>
  );
};
