import { Draft } from 'immer';
import { useCallback, useMemo } from 'react';
import { useCurrentOrbit, useUpdateOrbitMutation } from 'src/hooks/OrbitHooks';
import { COE } from 'src/threejs/models/COE';
import { Perturbations } from 'src/types';
import { use3DOrbitContext } from '../../Orbit/context';
import { getOrbits } from './getters';
import {
  createAddStateVectorsSelector,
  createCurrentOrbitCOEValueSelector,
  createCurrentOrbitECZoomDistanceSelector,
  createCurrentOrbitIntersectsWithEarth,
  createCurrentOrbitNameSelector,
  createCurrentOrbitPerturbationsSelector,
  createDeleteOrbitSelector,
  createOrbitAOPSelector,
  createOrbitCOESelector,
  createOrbitDataStateSelector,
  createOrbitDisplayedCalloutsSelector,
  createOrbitECCSelector,
  createOrbitINCSelector,
  createOrbitMakeKeplerianOrbitSelector,
  createOrbitRAANSelector,
  createOrbitStateSelector,
  createOrbitVerticesStateSelector,
  createOrbitVertsStateSelector,
  createOrbitalPlaneVectorSelector,
  createResetOrbitPathSelector,
  createSetDisplayedCalloutsSelector,
  createUpdateGroupIdSelector,
  createUpdateOrbitCOESelector,
} from './selectors';
import use3DOrbitStore from './store';
import { OrbitState } from './types';

function use3DOrbitSelector<Data>(selector: (id: number) => Data) {
  const { id } = use3DOrbitContext();
  const memoizedSelector = useMemo(() => selector(id), [selector, id]);
  return memoizedSelector;
}

function useCurrent3DOrbitSelector<Data>(selector: (id: number) => Data) {
  const currentOrbit = useCurrentOrbit();
  const memoizedSelector = useMemo(() => {
    if (currentOrbit?.id) {
      return selector(currentOrbit.id);
    }
    return () => null;
  }, [selector, currentOrbit?.id]);

  return memoizedSelector;
}

export function useCurrent3DOrbit(): [
  OrbitState | null,
  (setter: (orbit: Draft<OrbitState>) => void) => void,
] {
  const currentOrbit = useCurrentOrbit();
  const memoizedSelector = useCurrent3DOrbitSelector(createOrbitStateSelector);
  const state = use3DOrbitStore(memoizedSelector);
  const setState = useCallback(
    (setter: (orbit: Draft<OrbitState>) => void) => {
      const set = use3DOrbitStore.getState().set;
      set((state) => {
        if (currentOrbit?.id) {
          const orbit = state.orbits[currentOrbit.id];
          setter(orbit);
        }
      });
    },
    [currentOrbit?.id],
  );

  return [state, setState];
}

export function use3DOrbit(
  id: number,
): [OrbitState | null, (setter: (orbit: Draft<OrbitState>) => void) => void] {
  const memoizedSelector = use3DOrbitSelector(createOrbitStateSelector);
  const state = use3DOrbitStore(memoizedSelector);
  const setState = useCallback(
    (setter: (orbit: Draft<OrbitState>) => void) => {
      const set = use3DOrbitStore.getState().set;
      set((state) => {
        if (id) {
          const orbit = state.orbits[id];
          setter(orbit);
        }
      });
    },
    [id],
  );

  return [state, setState];
}

export function use3DOrbitState(): OrbitState {
  const selector = use3DOrbitSelector(createOrbitStateSelector);
  return use3DOrbitStore(selector);
}

export function use3DOrbitData() {
  const selector = use3DOrbitSelector(createOrbitDataStateSelector);
  return use3DOrbitStore(selector);
}

export function use3DOrbitVertices() {
  const selector = use3DOrbitSelector(createOrbitVerticesStateSelector);
  return use3DOrbitStore(selector);
}

export function use3DOrbitVerts() {
  const selector = use3DOrbitSelector(createOrbitVertsStateSelector);
  return use3DOrbitStore(selector);
}

export function useDeleteOrbitState() {
  const selector = use3DOrbitSelector(createDeleteOrbitSelector);
  return use3DOrbitStore(selector);
}

export function use3DOrbitCOE() {
  const selector = use3DOrbitSelector(createOrbitCOESelector);
  return use3DOrbitStore(selector);
}

export function useDisplayedCallouts() {
  const selector = use3DOrbitSelector(createOrbitDisplayedCalloutsSelector);
  return use3DOrbitStore(selector);
}

export function useMakeKeplerianOrbit() {
  const selector = use3DOrbitSelector(createOrbitMakeKeplerianOrbitSelector);
  return use3DOrbitStore(selector);
}

export function use3DOrbitRAAN() {
  const selector = use3DOrbitSelector(createOrbitRAANSelector);
  return use3DOrbitStore(selector);
}

export function use3DOrbitAOP() {
  const selector = use3DOrbitSelector(createOrbitAOPSelector);
  return use3DOrbitStore(selector);
}

export function use3DOrbitECC() {
  const selector = use3DOrbitSelector(createOrbitECCSelector);
  return use3DOrbitStore(selector);
}

export function use3DOrbitINC() {
  const selector = use3DOrbitSelector(createOrbitINCSelector);
  return use3DOrbitStore(selector);
}

export function use3DOrbitOrbitalPlaneVector() {
  const selector = use3DOrbitSelector(createOrbitalPlaneVectorSelector);
  return use3DOrbitStore(selector);
}

export function useAddStateVectors() {
  const selector = use3DOrbitSelector(createAddStateVectorsSelector);
  return use3DOrbitStore(selector);
}

export function use3DOrbitResetPropagation() {
  const selector = use3DOrbitSelector(createResetOrbitPathSelector);
  return use3DOrbitStore(selector);
}

export function useCurrentOrbitECZoomDistance() {
  const memoizedSelector = useCurrent3DOrbitSelector(createCurrentOrbitECZoomDistanceSelector);
  return use3DOrbitStore(memoizedSelector);
}

export function useCurrent3DOrbitCOEValue(valueSelector: (coe?: COE) => number | undefined) {
  const selector = useCallback(
    (id: number) => createCurrentOrbitCOEValueSelector(id, valueSelector),
    [valueSelector],
  );
  const memoizedSelector = useCurrent3DOrbitSelector(selector);
  return use3DOrbitStore(memoizedSelector);
}

export function useCurrent3DOrbitIntersectsWithEarth() {
  const memoizedSelector = useCurrent3DOrbitSelector(createCurrentOrbitIntersectsWithEarth);
  return use3DOrbitStore(memoizedSelector);
}

export function use3DOrbitCoeUpdater() {
  const memoizedSelector = use3DOrbitSelector(createUpdateOrbitCOESelector);
  return use3DOrbitStore(memoizedSelector);
}

export function useSetDisplayedCallouts() {
  const memoizedSelector = use3DOrbitSelector(createSetDisplayedCalloutsSelector);
  return use3DOrbitStore(memoizedSelector);
}

export function useCurrent3DOrbitCoeUpdater() {
  const memoizedSelector = useCurrent3DOrbitSelector(createUpdateOrbitCOESelector);
  return use3DOrbitStore(memoizedSelector);
}

export function useCurrent3DOrbitGroupIdUpdater() {
  const memoizedSelector = useCurrent3DOrbitSelector(createUpdateGroupIdSelector);
  return use3DOrbitStore(memoizedSelector);
}

export function useCurrent3DOrbitName(): [string | null, (name: string) => void] {
  const currentOrbit = useCurrentOrbit();
  const nameSelector = useCurrent3DOrbitSelector(createCurrentOrbitNameSelector);
  const name = use3DOrbitStore(nameSelector);

  const setName = useCallback(
    (name: string) => {
      const { set } = use3DOrbitStore.getState();
      set((state) => {
        if (currentOrbit) {
          state.orbits[currentOrbit.id].name = name;
        }
      });
    },
    [currentOrbit],
  );

  return [name, setName];
}

export function useCurrent3DOrbitPerturbations(): [any, (perturbations: Perturbations) => void] {
  const currentOrbit = useCurrentOrbit();
  const selector = useCurrent3DOrbitSelector(createCurrentOrbitPerturbationsSelector);
  const perturbations = use3DOrbitStore(selector);

  const setPerturbations = useCallback(
    (perturbations: Perturbations) => {
      const { set } = use3DOrbitStore.getState();
      set((state) => {
        if (currentOrbit) {
          state.orbits[currentOrbit.id].perturbations = perturbations;
        }
      });
    },
    [currentOrbit],
  );

  return [perturbations, setPerturbations];
}

export function useSaveCurrentOrbit() {
  const currentOrbit = useCurrentOrbit();
  const updateOrbitMutation = useUpdateOrbitMutation();

  return useCallback(() => {
    if (currentOrbit) {
      const orbits = getOrbits();
      const state = orbits[currentOrbit.id];

      return updateOrbitMutation.mutate({
        ...state,
        orbit: [state.coe.getData()],
      });
    }
  }, [currentOrbit, updateOrbitMutation]);
}

export function useSaveOrbit(id: number) {
  const updateOrbitMutation = useUpdateOrbitMutation();

  return useCallback(() => {
    const orbits = getOrbits();
    const state = orbits[id];
    if (state) {
      return updateOrbitMutation.mutate({
        id: state.id,
        name: state.name,
        orbit: [state.coe],
        perturbations: state.perturbations,
      });
    }
  }, [id, updateOrbitMutation]);
}
