import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { getActivePageId, useRouteStore } from 'src/pages/App/routes/store';
import { fetchApi } from 'src/services/api';
import {
  CancellablePromise,
  LaunchEvent,
  LaunchEventRequest,
  LaunchWindowRequest,
} from 'src/types';
import { useApiSnackbarError } from './SnackbarHooks';

export function useCurrentLaunchEvents() {
  const pageId = useRouteStore(getActivePageId);

  const { data } = useLaunchEventsQuery(pageId);
  return data;
}

export function useLaunchEventsQuery(pageId?: number) {
  const apiSnackbarError = useApiSnackbarError();

  return useQuery<LaunchEvent[]>(
    ['launchEventsForPage', pageId],
    () => {
      const controller = new AbortController();
      const signal = controller.signal;

      const promise = fetchApi(`/v2/launches?pageId=${pageId}`, { signal }).then((data) => {
        if (data.length <= 0) return [];
        return data;
      }) as CancellablePromise<LaunchEvent[]>;

      promise.cancel = () => {
        controller.abort();
      };

      return promise;
    },
    {
      enabled: Boolean(pageId),
      onError: () => {
        apiSnackbarError('Failed to get launch event(s).');
      },
    },
  );
}

export function useCreateLaunchEventMutation() {
  const apiSnackbarError = useApiSnackbarError();
  const pageId = useRouteStore(getActivePageId);

  const queryClient = useQueryClient();

  return useMutation(
    (request: Partial<LaunchEventRequest>) => {
      return fetchApi('/v2/launches', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(request),
      });
    },
    {
      onError: () => {
        apiSnackbarError('Failed to create launch.');
      },
      onSuccess: async (data) => {
        queryClient.invalidateQueries(['launchEventsForPage', pageId]);
        // new payload orbit was added so cache needs updating
        await queryClient.invalidateQueries(['orbitsForPage', pageId]);
      },
    },
  );
}

export function useLaunchWindowsActiveEditQuery() {
  const apiSnackbarError = useApiSnackbarError();

  const queryClient = useQueryClient();

  return useMutation(
    (request: LaunchWindowRequest) => {
      const {
        startTimestamp,
        endTimestamp,
        siteLatitude,
        siteLongitude,
        desiredAltitude,
        desiredInclination,
        desiredRAAN,
      } = request;
      return fetchApi('/v2/launch-windows', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          startTimestamp,
          endTimestamp,
          siteLatitude,
          siteLongitude,
          desiredAltitude,
          desiredInclination,
          desiredRAAN,
        }),
      });
    },
    {
      onError: () => {
        apiSnackbarError('Failed to create launch windows for the launch being created.');
      },
      onSuccess: (data) => {
        queryClient.setQueryData(['launchWindowsForActiveEdit'], data);
      },
    },
  );
}
