import { Draft } from 'immer';
import { useMemo } from 'react';
import { GRID_HELPER_SCALES, RIC_REF_GRID_1_KM_DIST } from 'src/constants';
import { useViewportContext } from 'src/threejs/components/Viewport/context';
import { ManipulatorName } from 'src/threejs/types';
import { ReferenceFrame, ReferenceFrameType, Viewport } from 'src/types';
import { Mesh, PerspectiveCamera } from 'three';
import { create } from 'zustand';
import { subscribeWithSelector } from 'zustand/middleware';
import { immer } from 'zustand/middleware/immer';

export type ViewportState = {
  id: string;
  camera?: any;
  cameraECEF: PerspectiveCamera | null;
  cameraRIC: PerspectiveCamera | null;
  ricRefGridSize: string;
  setRicRefGridSize: (newScaleValue: string) => void;
  setCamera: (camera: any) => void;
  setCameraECEF: (camera: PerspectiveCamera) => void;
  setCameraRIC: (camera: PerspectiveCamera) => void;
  controls?: any;
  setControls: (controls: any) => void;
  activeManipulator: null | ManipulatorName;
  setActiveManipulator: (activeManipulator: null | ManipulatorName) => void;
  /** ID of the orbit (or space object) set as the target in this viewport.
   * Only valid in RIC reference frame. */
  targetId: number | null;
  setTargetId: (id: number) => void;
  canvasActive: boolean;
  setCanvasActive: (toggle: boolean) => void;
  markerActive: boolean;
  setMarkerActive: (toggle: boolean) => void;
  baseSceneMesh: Mesh | null;
  setBaseSceneMesh: (baseMesh: Mesh) => void;
};

export const MAX_VIEWPORTS = 3;

interface ViewportStoreState {
  viewports: Record<string, ViewportState>;
  createViewport: (viewport: Viewport) => void;
  removeViewport: (id: string) => void;
}

function createViewportState(
  id: string,
  set: (fn: (draft: Draft<ViewportStoreState>) => void) => void,
): ViewportState {
  return {
    id,
    camera: undefined,
    cameraECEF: null,
    cameraRIC: null,
    ricRefGridSize: GRID_HELPER_SCALES[RIC_REF_GRID_1_KM_DIST].scaleValue,
    setCamera: (camera) => {
      set((state) => {
        state.viewports[id].camera = camera;
      });
    },
    setCameraECEF: (camera) => {
      set((state) => {
        state.viewports[id].cameraECEF = camera;
      });
    },
    setCameraRIC: (camera) => {
      set((state) => {
        state.viewports[id].cameraRIC = camera;
      });
    },
    controls: undefined,
    setRicRefGridSize: (newScaleValue: string) => {
      set((state) => {
        state.viewports[id].ricRefGridSize = newScaleValue;
      });
    },
    setControls: (controls) => {
      set((state) => {
        state.viewports[id].controls = controls;
      });
    },
    activeManipulator: null,
    setActiveManipulator: (activeManipulator) => {
      set((state) => {
        state.viewports[id].activeManipulator = activeManipulator;
      });
    },
    targetId: null,
    setTargetId: (targetId: number) => {
      set((state) => {
        state.viewports[id].targetId = targetId;
      });
    },
    canvasActive: false,
    setCanvasActive: (toggle: boolean) => {
      set((state) => {
        state.viewports[id].canvasActive = toggle;
      });
    },
    markerActive: false,
    setMarkerActive: (toggle: boolean) => {
      set((state) => {
        state.viewports[id].markerActive = toggle;
      });
    },
    baseSceneMesh: new Mesh(),
    setBaseSceneMesh: (baseMesh: Mesh) => {
      set((state) => {
        state.viewports[id].baseSceneMesh = baseMesh;
      });
    },
  };
}

export const useViewportStore = create<ViewportStoreState>()(
  subscribeWithSelector(
    immer((set, get) => ({
      viewports: {},
      createViewport: (viewport) => {
        set((state) => {
          const id = `${viewport.id}`;
          if (!state.viewports[id]) {
            const tempState = createViewportState(id, set);
            const newViewport = { [id]: tempState };
            state.viewports = Object.assign({}, state.viewports, newViewport);
          }
        });
      },
      removeViewport: (id) => {
        set((state) => {
          delete state.viewports[id];
        });
      },
    })),
  ),
);

export function useViewport<T>(selector: (state: ViewportState) => T): T;
export function useViewport(): ViewportState;
export function useViewport(selector?: any): any {
  const { viewport } = useViewportContext();
  return useViewportStore((state) => {
    const viewportState = state.viewports[viewport.id];
    return selector ? selector(viewportState) : viewportState;
  });
}

export function useViewportSetRicRefGridSize() {
  const { viewport } = useViewportContext();
  return useViewportStore((state) => state.viewports[viewport.id].setRicRefGridSize);
}

export function useViewportRicRefGridSize() {
  const { viewport } = useViewportContext();
  return useViewportStore((state) => state.viewports[viewport.id].ricRefGridSize);
}

export function useViewportId() {
  const { viewport } = useViewportContext();
  return viewport.id;
}

export function useActiveManipulator(): [
  null | ManipulatorName,
  (activeManipulator: null | ManipulatorName) => void,
] {
  const { viewport } = useViewportContext();
  const activeManipulator = useViewportStore(
    (state) => state.viewports[viewport.id].activeManipulator,
  );
  const setActiveManipulator = useViewportStore(
    (state) => state.viewports[viewport.id].setActiveManipulator,
  );

  return useMemo(
    () => [activeManipulator, setActiveManipulator],
    [activeManipulator, setActiveManipulator],
  );
}

type ReferenceFrameState = {
  referenceFrame: ReferenceFrameType;
  isECI: boolean;
  isECEF: boolean;
  isRIC: boolean;
  is2D: boolean;
};
export function useViewportReferenceFrame(): ReferenceFrameState {
  const { viewport } = useViewportContext();
  const referenceFrame = viewport.type;
  return {
    referenceFrame,
    isECI: referenceFrame === ReferenceFrame.ECI,
    isECEF: referenceFrame === ReferenceFrame.ECEF,
    isRIC: referenceFrame === ReferenceFrame.RIC,
    is2D: referenceFrame === ReferenceFrame.TWO_DIMENSIONS,
  };
}

export default useViewportStore;
