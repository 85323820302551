import { styled } from '@mui/material';

export const CopyContainer = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  borderRadius: 16,
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0.5),
  paddingLeft: theme.spacing(1),
  gap: theme.spacing(0.5),
  fontSize: 12,
}));

interface CopyButtonProps {
  disabled?: boolean;
}
export const CopyButton = styled('button')<CopyButtonProps>(
  ({ theme, disabled }) => `
    background: #3f8cff;
    border-radius: 12px;
    color: #ffffff;
    text-transform: uppercase;
    padding: 5px 10px;
    font-weight: 600;
    font-size: 12px;
    line-height: 14px;
    border: none;
    cursor: pointer;
    ${
      disabled &&
      `
      background: ${theme.palette.grey['600']};
      color: ${theme.palette.grey['500']};
      cursor: initial;
    `
    }
  `,
);

export const MakeCopyContainer = styled('div')`
  display: flex;
  align-items: center;
  justify-content: center;
`;
