import { FormControl, InputLabel, MenuItem, Select, SelectChangeEvent } from '@mui/material';
import { useCurrentOrbit, useUpdateOrbitAdditionalProperties } from 'src/hooks/OrbitHooks';

interface OrbitTrackLengthSliderProps {
  is3D?: boolean;
}

export function OrbitTrackLengthSlider({ is3D = false }: OrbitTrackLengthSliderProps) {
  const updateOrbitAdditionalProperties = useUpdateOrbitAdditionalProperties();
  const currentOrbit = useCurrentOrbit();

  const handleChange = (event: SelectChangeEvent) => {
    if (currentOrbit) {
      const changes = {
        [is3D ? 'trackLength3D' : 'trackLength']: Number(event.target.value),
      };
      updateOrbitAdditionalProperties(currentOrbit, changes);
    }
  };

  if (!currentOrbit) {
    return null;
  }

  const additionalProps = currentOrbit?.additionalProperties;

  const value = is3D
    ? additionalProps?.trackLength3D?.toString()
    : additionalProps?.trackLength?.toString();

  return (
    <FormControl
      variant="standard"
      fullWidth
    >
      <InputLabel id="orbit-track-length-label">Length</InputLabel>
      <Select
        labelId="demo-simple-select-standard-label"
        id="demo-simple-select-standard"
        value={value || '-1'}
        onChange={handleChange}
        label="Orbit Track Length"
        fullWidth
      >
        <MenuItem value={0.25}>1/4 Period</MenuItem>
        <MenuItem value={0.5}>1/2 Period</MenuItem>
        <MenuItem value={1}>1 Period</MenuItem>
        <MenuItem value={-1}>Maximum</MenuItem>
      </Select>
    </FormControl>
  );
}
