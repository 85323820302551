import {
  PublicOffRounded,
  PublicRounded,
  TextFieldsRounded,
  VisibilityOffRounded,
  VisibilityRounded,
} from '@mui/icons-material';
import { useUpdateOrbitAdditionalProperties } from 'src/hooks/OrbitHooks';
import { GroupObject, OrbitAdditionalProperties, OrbitObject } from 'src/types';
import { ObjectItem } from './ObjectItem';
import { ObjectItemOrbitName } from './ObjectItemOrbitName';
import { ToggleObjectAdditionalProperty } from './ToggleObjectAdditionalProperty';

interface ObjectItemOrbitProps {
  group?: GroupObject;
  orbit: OrbitObject;
}

export const ObjectItemOrbit = ({ group, orbit }: ObjectItemOrbitProps) => {
  const updateOrbitAdditionalProperties = useUpdateOrbitAdditionalProperties();
  const handleChangeAdditionalPropertiesOrbit = (
    orb: OrbitObject,
    changes: Partial<OrbitAdditionalProperties>,
  ) => {
    updateOrbitAdditionalProperties(orb, changes);
  };

  // if orbit is not in a group then default to true
  const hasVisOrbits = group ? !!group.additionalProperties?.visOrbits : true;

  return (
    <ObjectItem
      name={<ObjectItemOrbitName id={orbit.id} />}
      itemOptions={[
        <ToggleObjectAdditionalProperty
          tooltip="Orbit Label Visibility"
          enabled={
            hasVisOrbits &&
            !!orbit.additionalProperties?.visOrbit &&
            !!orbit.additionalProperties?.visLabel
          }
          key="visibilityLabel"
          icon={{
            icon: <TextFieldsRounded />,
          }}
          onChange={() => {
            handleChangeAdditionalPropertiesOrbit(orbit, {
              visLabel: !orbit.additionalProperties?.visLabel,
            });
          }}
        />,

        <ToggleObjectAdditionalProperty
          tooltip="Ground Traces Visibility"
          enabled={!!orbit.additionalProperties?.visGroundTracks}
          key="visibility"
          icon={{
            iconOn: <PublicRounded />,
            iconOff: <PublicOffRounded />,
          }}
          onChange={() => {
            handleChangeAdditionalPropertiesOrbit(orbit, {
              visGroundTracks: !orbit.additionalProperties?.visGroundTracks,
            });
          }}
        />,

        <ToggleObjectAdditionalProperty
          tooltip="Keplerian Orbit Visibility"
          enabled={hasVisOrbits && !!orbit.additionalProperties?.visOrbit}
          key="visibilityOrbit"
          icon={{
            iconOn: <VisibilityRounded />,
            iconOff: <VisibilityOffRounded />,
          }}
          onChange={() => {
            handleChangeAdditionalPropertiesOrbit(orbit, {
              visOrbit: !orbit.additionalProperties?.visOrbit,
            });
          }}
        />,
      ]}
    />
  );
};
