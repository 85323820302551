import { Client, IFrame, messageCallbackType, StompSubscription } from '@stomp/stompjs';
import useAppStore from 'src/core/store';
import { WebSocketMessageType } from 'src/enums';
import PubSub from 'src/models/PubSub';
import { SocketState } from 'src/types';
import retry from 'src/utilities/retry';
import { v4 as uuidv4 } from 'uuid';
import { API_STORE } from './API_STORE';
import { WEBSOCKET_HEARTBEAT_INTERVAL_INCOMING_OUTGOING_MS } from 'src/constants';
import { closeSnackbar } from 'notistack';

const WS_URL_SUFFIX = '/propagation';

const WS_PROPAGATION_URL = `${process.env.REACT_APP_LAB_WSAPI_URL_ROOT}${WS_URL_SUFFIX}`;
const DELAY = 1500;

type WebsocketConfig = {
  onMessageRecieved: messageCallbackType;
  onUnrecoverableError: () => void;
};

const { setSocketState } = useAppStore.getState();

class WebSocketManagerModel {
  private client: Client | undefined;
  private sessionKey = uuidv4();
  private retries = -1;
  private maxRetries = 3;
  private onMessageRecieved: messageCallbackType = () => console.log('implement me');
  private onUnrecoverableError: () => void = () => console.log('implement me');
  private subscription: StompSubscription | undefined;
  private stompErrorPubsub = new PubSub();

  constructor() {
    window.addEventListener('beforeunload', this.disconnectClient);

    const handleVisibilityChange = () => {
      // We need to manage the WebSocket connection based on the browser tab's visibility.
      // This is due to the behavior of the STOMP client in the @stomp/stompjs library:
      // when the tab is inactive, the client's heartbeat mechanism is throttled,
      // which can lead to connection timeouts. Automatically reconnect the WebSocket
      // connection when the tab is not active and client is not connected.
      const isTabActive = document.visibilityState === 'visible';

      if (isTabActive && this.client && !this.client.connected) {
        console.log('Attempting to reconnect propagation');
        closeSnackbar(); // remove existing connection errors
        this.retries = -1;
        this.connectSocket();
      }
    };
    document.addEventListener('visibilitychange', handleVisibilityChange);
  }

  connectSocket = () => {
    this.client = new Client({
      brokerURL: WS_PROPAGATION_URL,
      connectHeaders: {
        'X-Authorization': 'Bearer ' + API_STORE.token,
      },
      heartbeatIncoming: WEBSOCKET_HEARTBEAT_INTERVAL_INCOMING_OUTGOING_MS,
      heartbeatOutgoing: WEBSOCKET_HEARTBEAT_INTERVAL_INCOMING_OUTGOING_MS,
      reconnectDelay: 0, // disable auto-reconnection since it is done manually
      onWebSocketClose: (closeEvent: CloseEvent) => {
        setSocketState(SocketState.disconnected);
        const isTabActive = document.visibilityState === 'visible';
        if (!isTabActive) return; // ignore connection errors if connection timed out due to inactive tab
        if (!closeEvent.wasClean) {
          setSocketState(SocketState.errored);

          const error = new Error(`code: ${closeEvent.code}, reason: ${closeEvent.reason}`);
          console.error(error);
        }
      },
      beforeConnect: () => {
        setSocketState(SocketState.connecting);

        if (this.retries > this.maxRetries) {
          setSocketState(SocketState.disconnecting);

          this.client?.deactivate();
          setSocketState(SocketState.disconnected);
          const error = new Error(
            `Attempted to reconnect too many times for session: ${this.sessionKey}`,
          );
          console.error(error);

          this.onUnrecoverableError();
        }

        this.retries++;
      },
      onWebSocketError: () => {
        setSocketState(SocketState.errored);
      },
    });

    this.client.onConnect = this.subscribeToDestination();

    this.client.onStompError = (frame) => {
      const isTabActive = document.visibilityState === 'visible';
      if (isTabActive) return; // ignore connection errors if connection timed out due to inactive tab
      console.log('Broker reported error: ' + frame.headers['message']);
      this.stompErrorPubsub.publish('propagationerror', frame);
    };

    this.client.onDisconnect = () => {
      console.log('Client disconnected session: ' + this.sessionKey);
      setSocketState(SocketState.disconnected);
    };

    this.client.activate();
  };

  configure = (config: WebsocketConfig) => {
    this.onMessageRecieved = config.onMessageRecieved ?? this.onMessageRecieved;
    this.onUnrecoverableError = config.onUnrecoverableError ?? this.onUnrecoverableError;
  };

  sendMessage = async (type: WebSocketMessageType, data: Record<string, unknown>) => {
    //TODO: remove type being sent from the backend and just send data, LAB-471
    const msg = {
      msgType: type,
      data: data,
    };

    const client = await this.getClient();

    if (!client.connected) {
      this.connectSocket();
    }

    client.publish({
      destination: '/app/propagation',
      headers: { user: API_STORE.userProfile, key: this.sessionKey },
      body: JSON.stringify(msg),
    });
  };

  subscribeToDestination = () => {
    this.sessionKey = uuidv4();
    return async () => {
      setSocketState(SocketState.connecting);
      const client = await this.getClient();
      this.subscription = client.subscribe(
        `/secured/user/queue/specific-user${this.sessionKey}`,
        this.onMessageRecieved,
      );
      this.retries = -1;
      setSocketState(SocketState.connected);
    };
  };

  resetSubscription = async () => {
    setSocketState(SocketState.connecting);
    this.subscription?.unsubscribe();
    await this.subscribeToDestination()();
  };

  disconnectClient = async () => {
    setSocketState(SocketState.disconnecting);
    const client = await this.getClient();
    await client.deactivate();
    setSocketState(SocketState.disconnected);
  };

  subscribeToStompError = (listener: (frame: IFrame) => void) => {
    return this.stompErrorPubsub.subscribe('propagationerror', listener);
  };

  subscribeToConnectError = (listener: () => void) => {
    return this.stompErrorPubsub.subscribe('connectionerror', listener);
  };

  /**
   * Retrieves the current Stomp Client instance or waits for the
   * Stomp Client to connect and then returns the Stomp Client.
   * @returns The Stomp Client
   */
  private getClient = async () => {
    if (this.client?.connected) {
      return this.client;
    } else {
      try {
        const waitForClientToConnect = async () => {
          if (this.client?.connected) {
            return this.client;
          } else {
            throw new Error('Failed to connect client.');
          }
        };

        const client = await retry(waitForClientToConnect, DELAY, 3);

        return client;
      } catch (error: any) {
        setSocketState(SocketState.errored);
        this.stompErrorPubsub.publish('connectionerror', {});
        throw new Error(error);
      }
    }
  };
}

const WebSocketManager = new WebSocketManagerModel();

export default WebSocketManager;
