import { ProviderContext } from 'notistack';
import { Page } from 'src/types';
import { create } from 'zustand';
import { subscribeWithSelector } from 'zustand/middleware';
import { immer } from 'zustand/middleware/immer';
import { AppStoreState, TimelineState } from './types';

const DUMMY_DATE = new Date();

/*
  STORE to share data across the app
*/

export const SETTINGS_NAMES_TRANSIENT = {
  VIDEO_RECORDING_ENABLED: 'video_recording_enabled',
};

export const createInitialTimelineRange = (startTime: Date): TimelineState => ({
  playState: 'stopped',
  playSpeed: 3600,
  currentTime: startTime.getTime(),
  currentTimePreviewMode: startTime.getTime(),
  get isLoading() {
    return this.playState === 'loading';
  },
  get isStopped() {
    return this.playState === 'stopped';
  },
  get isPlaying() {
    return this.playState === 'playing';
  },
  get isSeeking() {
    return this.playState === 'seeking';
  },
});

const useAppStore = create<AppStoreState>()(
  subscribeWithSelector(
    immer((set, get) => ({
      activeInspectorTab: 'none',
      setActiveInspectorTab: (newVal: string) =>
        set((state) => {
          state.activeInspectorTab = newVal;
        }),
      socket: 'idle',
      setSocketState: (socketState) => {
        set((state) => {
          state.socket = socketState;
        });
      },
      pages: {
        currentPageId: undefined,
        setCurrentPageId: (currentPageId?: number) => {
          set((state) => {
            const defaultTimeline = createInitialTimelineRange(DUMMY_DATE);

            state.pages.currentPageId = currentPageId;
            state.timelines.timelineRange = currentPageId
              ? state.timelines.cache[currentPageId] ?? defaultTimeline
              : defaultTimeline;
          });
        },
      },
      timelines: {
        cache: {},
        currentPageId: undefined,

        // This initial value for timelineRange is just a dummy value to make the app render something
        // while we wait for pages data to come back from the server and set this with a real value.
        timelineRange: createInitialTimelineRange(DUMMY_DATE),
        createTimelineForPage: (page: Page) => {
          const { timelines } = get();
          const { cache } = timelines;

          if (!cache[page.id]) {
            set((state) => {
              state.timelines.cache[page.id] = createInitialTimelineRange(page.startTime);
            });
          }
        },
        updateTimelineRange: (timelineUpdates: Partial<TimelineState>) => {
          const {
            pages: { currentPageId },
          } = get();

          if (currentPageId !== undefined) {
            set((state) => {
              const {
                timelines: { cache },
              } = state;

              const currentTimeline = cache[currentPageId]; // || createInitialTimelineRange();

              const newTimeline: TimelineState = {
                ...currentTimeline,
                ...timelineUpdates,
                get isLoading() {
                  return this.playState === 'loading';
                },
                get isStopped() {
                  return this.playState === 'stopped';
                },
                get isPlaying() {
                  return this.playState === 'playing';
                },
                get isSeeking() {
                  return this.playState === 'seeking';
                },
              };

              state.timelines.cache[currentPageId] = newTimeline;
              state.timelines.timelineRange = newTimeline;
            });
          } else {
            console.error('You are trying to update a timeline but no current page id exists.');
          }
        },
      },
      settings: {},
      initialize: (name: string) => {
        set((state) => {
          state.settings[name] = false;
        });
      },
      toggleSetting: (name: string) => {
        set((state) => {
          state.settings[name] = !state.settings[name];
        });
      },
      setSetting: (name: string, value: boolean) => {
        set((state) => {
          state.settings[name] = value;
        });
      },
      snackbarEnqueue: undefined,
      setSnackbarEnqueue: (enqueueSnackbar: ProviderContext['enqueueSnackbar']) => {
        set((state) => {
          state.snackbarEnqueue = enqueueSnackbar;
        });
      },
    })),
  ),
);

export default useAppStore;
