import { Menu, MenuItem } from '@mui/material';
import { ReactNode, useCallback, useState } from 'react';
import { ItemType } from 'src/enums';
import { GroundObject, OrbitObject } from 'src/types';
import { isGroundObject, isOrbitObject } from 'src/utilities/TypeUtils';
import { ModalOrbitDuplicate } from '../../ModalOrbitDuplicate';
import { ModalDeleteObject } from '../ModalDeleteObject';
import { ModalGroundObjectDuplicate } from '../../ModalGroundObjectDuplicate';

interface ObjectContextMenuProps {
  object: OrbitObject | GroundObject;
  children: ReactNode;
}

export const ObjectContextMenu = ({ object, children }: ObjectContextMenuProps) => {
  const [deleteOrbitModalOpen, setDeleteOrbitModal] = useState(false);
  const [modalOrbitCopyVisible, setModalOrbitCopyVisible] = useState(false);
  const [modalGroundObjectCopyVisible, setModalGroundObjectCopyVisible] = useState(false);

  const [contextMenu, setContextMenu] = useState<{
    mouseX: number;
    mouseY: number;
  } | null>(null);

  const handleContextMenu = useCallback(
    (event: React.MouseEvent) => {
      event.preventDefault();
      setContextMenu(
        contextMenu === null
          ? {
              mouseX: event.clientX + 2,
              mouseY: event.clientY - 6,
            }
          : // repeated contextmenu when it is already open closes it with Chrome 84 on Ubuntu
            // Other native context menus might behave different.
            // With this behavior we prevent contextmenu from the backdrop to re-locale existing context menus.
            null,
      );
    },
    [contextMenu],
  );

  const handleClose = useCallback(() => {
    setContextMenu(null);
  }, []);

  const handleSelectDelete = useCallback(() => {
    setDeleteOrbitModal(true);
    handleClose();
  }, [handleClose]);

  const handleSelectDuplicate = useCallback(() => {
    if (isOrbitObject(object)) {
      setModalOrbitCopyVisible(true);
    } else if (isGroundObject(object)) {
      setModalGroundObjectCopyVisible(true);
    }
    handleClose();
  }, [handleClose, object]);

  return (
    <>
      <ModalDeleteObject
        item={object}
        show={setDeleteOrbitModal}
        open={deleteOrbitModalOpen}
        itemType={isOrbitObject(object) ? ItemType.Orbit : ItemType.GroundObject}
      />
      {isOrbitObject(object) && (
        <ModalOrbitDuplicate
          orbit={object}
          show={modalOrbitCopyVisible}
          onClose={() => setModalOrbitCopyVisible(false)}
        />
      )}
      {isGroundObject(object) && (
        <ModalGroundObjectDuplicate
          groundObject={object}
          show={modalGroundObjectCopyVisible}
          onClose={() => setModalGroundObjectCopyVisible(false)}
        />
      )}
      <div
        onContextMenu={handleContextMenu}
        style={{ cursor: 'context-menu' }}
      >
        <Menu
          open={contextMenu !== null}
          onClose={handleClose}
          anchorReference="anchorPosition"
          anchorPosition={
            contextMenu !== null ? { top: contextMenu.mouseY, left: contextMenu.mouseX } : undefined
          }
        >
          {(isOrbitObject(object) || isGroundObject(object)) && (
            <MenuItem onClick={handleSelectDuplicate}>Duplicate {object.name}</MenuItem>
          )}
          <MenuItem onClick={handleSelectDelete}>Delete {object.name}</MenuItem>
        </Menu>
        {children}
      </div>
    </>
  );
};
