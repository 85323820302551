import { TextFieldsRounded, VisibilityOffRounded, VisibilityRounded } from '@mui/icons-material';
import { useUpdateGroundObjectAdditionalProperties } from 'src/hooks/GroundObjectHooks';
import { GroundObject, GroundObjectAdditionalProperties, GroupObject } from 'src/types';
import { ObjectItem } from './ObjectItem';
import { ToggleObjectAdditionalProperty } from './ToggleObjectAdditionalProperty';

interface ObjectItemGroundProps {
  group?: GroupObject;
  groundObject: GroundObject;
}

export const ObjectItemGround = ({ group, groundObject }: ObjectItemGroundProps) => {
  /* utilities to update GROUND OBJECT additionalProperties */
  const updateGroundObjectAdditionProperties = useUpdateGroundObjectAdditionalProperties();
  const handleChangeAdditionalPropertiesGroundObject = (
    obj: GroundObject,
    changes: Partial<GroundObjectAdditionalProperties>,
  ) => {
    updateGroundObjectAdditionProperties(obj, changes);
  };
  // if ground object is not in a group then default to true
  const hasVisGroundObjects = group ? !!group.additionalProperties?.visOrbits : true;

  return (
    <ObjectItem
      name={groundObject.name}
      itemOptions={[
        <ToggleObjectAdditionalProperty
          tooltip="Ground Object Label Visibility"
          enabled={
            hasVisGroundObjects &&
            !!groundObject.additionalProperties?.visGroundObj &&
            !!groundObject.additionalProperties?.visLabel
          }
          key="visibilityOrbit"
          icon={{
            icon: <TextFieldsRounded />,
          }}
          onChange={() => {
            if (hasVisGroundObjects)
              handleChangeAdditionalPropertiesGroundObject(groundObject, {
                visLabel: !groundObject.additionalProperties?.visLabel,
              });
          }}
        />,

        <ToggleObjectAdditionalProperty
          tooltip="Ground Object Visibility"
          enabled={hasVisGroundObjects && !!groundObject.additionalProperties?.visGroundObj}
          key="visibilityLabel"
          icon={{
            iconOn: <VisibilityRounded />,
            iconOff: <VisibilityOffRounded />,
          }}
          onChange={() => {
            if (hasVisGroundObjects)
              handleChangeAdditionalPropertiesGroundObject(groundObject, {
                visGroundObj: !groundObject.additionalProperties?.visGroundObj,
              });
          }}
        />,
      ]}
    />
  );
};
