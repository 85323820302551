import React, { useMemo } from 'react';
import { useOrbit } from 'src/hooks/OrbitHooks';
import { OrbitGeoObject } from 'src/pages/Notebook/components/OrbitGeoObject';
import { MarkerProps, OrbitGeoObjectType } from 'src/types';
import { Vector3 } from 'three';
import Marker from '../../../Marker';
import { use3DOrbitContext } from '../../context';
import TAMarkerHandle from './TAMarkerHandle';

type TaMarkerProps = {
  disabled: boolean;
};

/** The static True Anomaly marker */
const TaMarker = ({
  disabled,
  handle,
  setHandle,
  marker,
  setMarker,
}: TaMarkerProps & MarkerProps) => {
  const { size, id } = use3DOrbitContext();

  const orbit = useOrbit(id);
  const orbitVisible = !!orbit?.additionalProperties?.visOrbit;

  const scale = useMemo(() => new Vector3().setScalar(size / 25), [size]);

  return (
    <>
      <Marker
        marker={marker}
        setMarker={setMarker}
        markerName="TA Marker"
        visible={orbitVisible}
        scale={scale}
      >
        {orbit && marker && (
          <OrbitGeoObject
            orbit={orbit}
            parentMesh={marker}
            objectType={OrbitGeoObjectType.SpaceObject}
          />
        )}
      </Marker>
      {!disabled && (
        <TAMarkerHandle
          handle={handle}
          setHandle={setHandle}
        />
      )}
    </>
  );
};

export default TaMarker;
