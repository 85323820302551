import { Fade, Grid, GridProps } from '@mui/material';
import { ReactNode, useState } from 'react';
import { useIsReadOnly } from 'src/hooks/SharedNotebookHooks';
import theme from 'src/pages/App/Theme';
import { ObjectSummaryBody, ObjectTitleInfo } from './ObjectPanelDisplay.styled';

interface ObjectPanelDisplayProps {
  headerRow?: string | ReactNode;
  titleRow?: string | ReactNode;
  children: ReactNode;
  accentColor: string;
  panelControls?: JSX.Element;
  isActive?: boolean;
  props?: GridProps;
}

export const ObjectPanelDisplay = ({
  accentColor,
  headerRow,
  titleRow,
  children,
  panelControls,
  isActive,
  props,
}: ObjectPanelDisplayProps) => {
  const [mouseOver, setMouseOver] = useState(false);
  const isReadOnly = useIsReadOnly();

  return (
    <Grid
      display="grid"
      mt={2}
      borderRadius="5px"
      borderLeft={`solid 6px ${accentColor}`}
      bgcolor={theme.palette?.background.default}
      onMouseOver={() => setMouseOver(true)}
      onMouseOut={() => setMouseOver(false)}
      {...props}
      position="relative"
      sx={{
        boxShadow: isActive ? `0px 0px 7px 3px ${theme.palette.primary.main}` : '',
        '&:hover': {
          boxShadow: `0px 0px 7px 3px ${theme.palette.primary.main}`,
        },
        ...props?.sx,
      }}
    >
      <Grid
        display="grid"
        gridAutoFlow="row"
        py={1}
        px={2}
      >
        {headerRow && <ObjectSummaryBody>{headerRow}</ObjectSummaryBody>}

        {titleRow && <ObjectTitleInfo>{titleRow}</ObjectTitleInfo>}

        {children}
      </Grid>

      {panelControls && (
        <Fade
          in={!isReadOnly && mouseOver}
          mountOnEnter
          unmountOnExit
        >
          <div>{panelControls}</div>
        </Fade>
      )}
    </Grid>
  );
};
