import {
  AddRounded,
  CameraIndoorRounded,
  GridOffRounded,
  GridOnRounded,
  RemoveRounded,
  ReplayRounded,
  SaveRounded,
  StarBorderRounded,
  StarRounded,
  VideocamRounded,
} from '@mui/icons-material';
import { Grid, MenuItem, Select, SelectChangeEvent, Tooltip } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import { gsap } from 'gsap';
import { useEffect, useState } from 'react';
import { ReactComponent as FrameAllIcon } from 'src/assets/frame_all.svg';
import { ReactComponent as FrameSelectedIcon } from 'src/assets/frame_selected.svg';
import {
  CAMERA_POSITION_DEFAULT,
  CAMERA_POSITION_DEFAULT_RIC,
  DEFAULT_ADDITIONAL_PROPERTIES_VIEWPORT,
  TIME_TWEEN_CAMERA_MOVEMENT,
} from 'src/constants';
import { useOrbits } from 'src/hooks/OrbitHooks';
import { useIsReadOnly } from 'src/hooks/SharedNotebookHooks';
import {
  useCreateViewport,
  useCurrentViewports,
  useDeleteViewport,
  useUpdateViewport,
  useUpdateViewportAdditionalProperties,
} from 'src/hooks/ViewportHooks';
import { getActivePageId, useRouteStore } from 'src/pages/App/routes/store';
import {
  Cartesian,
  ReferenceFrame,
  ReferenceFrameType,
  ViewportAdditionalProperties,
} from 'src/types';
import { Vector3 } from 'three';
import { createGetOrbitState } from '../OrbitManager/store/getters';
import { useCurrentOrbitECZoomDistance } from '../OrbitManager/store/hooks';
import { useViewportContext } from '../Viewport/context';
import useViewportStore, {
  MAX_VIEWPORTS,
  useViewport,
  useViewportId,
  useViewportReferenceFrame,
  useViewportRicRefGridSize,
} from '../ViewportManager/store';
import {
  ButtonControls,
  ControlsCamera,
  ControlsContainer,
  RicRefGridScaleValueDisplay,
} from './ViewportControls.styled';

export default function ViewportControls() {
  const { viewport } = useViewportContext();
  const { controls } = useViewport();
  const pageId = useRouteStore(getActivePageId);
  const orbits = useOrbits(pageId);
  const getCurrentECZoomDistance = useCurrentOrbitECZoomDistance();

  const isReadOnly = useIsReadOnly();

  const [viewportTargetId, setViewportTargetId] = useViewport((viewport) => [
    viewport.targetId,
    viewport.setTargetId,
  ]);
  const { isRIC, is2D } = useViewportReferenceFrame();
  const target = orbits?.find((orbit) => orbit.id === viewportTargetId);

  const viewportId = useViewportId();
  const ricRefScaleValue = useViewportRicRefGridSize();

  const [ricRefGridScaleValue, setRicRefGridScaleValue] = useState(ricRefScaleValue);

  const currentViewports = useCurrentViewports();

  const numberOfViewports = currentViewports?.length || 0;
  const canAddViewport = numberOfViewports < MAX_VIEWPORTS;
  const canRemoveViewport = numberOfViewports > 1;

  const createViewport = useCreateViewport();
  const updateViewport = useUpdateViewport();
  const deleteViewport = useDeleteViewport();

  const updateViewportAdditionalProperties = useUpdateViewportAdditionalProperties();

  const viewportAdditionalProperties =
    viewport?.additionalProperties || DEFAULT_ADDITIONAL_PROPERTIES_VIEWPORT;

  const isStarfieldOn = viewportAdditionalProperties.visStarField;
  const isGridOn = viewportAdditionalProperties.visGrid;

  const [cameraOptionsVisible, setCameraOptionsVisible] = useState(false);

  const handleChangeAdditionalPropertiesViewport = (
    changes: Partial<ViewportAdditionalProperties>,
  ) => {
    updateViewportAdditionalProperties(viewport, changes);
  };

  useEffect(() => {
    return useViewportStore.subscribe(
      (state) => state.viewports[viewportId]?.ricRefGridSize,
      setRicRefGridScaleValue,
    );
  }, [viewportId]);

  const handleModeChange = (event: SelectChangeEvent<unknown>) => {
    const cameraPosition =
      event.target.value === ReferenceFrame.RIC
        ? CAMERA_POSITION_DEFAULT_RIC
        : CAMERA_POSITION_DEFAULT;

    updateViewport.mutate({
      id: viewport.id,
      type: event.target.value as ReferenceFrameType,
      position: viewport.position,
      additionalProperties: {
        ...DEFAULT_ADDITIONAL_PROPERTIES_VIEWPORT,
        ...viewport.additionalProperties,
        cameraPosition,
        cameraTarget: { x: 0, y: 0, z: 0 },
      },
    });
  };

  const handleTargetChange = (event: SelectChangeEvent<unknown>) => {
    setViewportTargetId(event.target.value as number);
  };

  useEffect(() => {
    if (isRIC && orbits && !target) {
      setViewportTargetId(orbits[0].id);
    }
  }, [isRIC, orbits, target, setViewportTargetId]);

  const handleAddViewport = () => {
    createViewport.mutate({
      position: viewport.position + 1,
      type: ReferenceFrame.ECI,
    });
  };
  const handleRemoveViewport = () => {
    deleteViewport.mutate({
      id: viewport.id,
    });
  };

  const moveCameraControlsHome = () => {
    const cameraPosition = isRIC ? CAMERA_POSITION_DEFAULT_RIC : CAMERA_POSITION_DEFAULT;
    const cameraTarget = {
      x: 0,
      y: 0,
      z: 0,
    };
    animateControls(cameraPosition, cameraTarget);
  };

  const moveCameraControlsToFit = (maxSize: number) => {
    if (controls) {
      const fov = controls.object.fov * (Math.PI / 180); // get fov in rad
      const camDistance = Math.abs(maxSize / (2 * Math.sin(fov / 2)));

      const offset = new Vector3();
      offset.copy(controls.object.position).sub(controls.target);
      offset.normalize().multiplyScalar(camDistance);

      const cameraPosition = offset;
      const controlsTarget = { x: 0, y: 0, z: 0 };
      animateControls(cameraPosition, controlsTarget);
    }
  };

  const animateControls = (position: Cartesian, target: Cartesian) => {
    if (controls) {
      gsap.to(controls.object.position, { ...position, duration: TIME_TWEEN_CAMERA_MOVEMENT });
      gsap.to(controls.target, { ...target, duration: TIME_TWEEN_CAMERA_MOVEMENT });
    }
  };

  const cameraOrientationReset = () => {
    const cameraPosition = {
      x: viewportAdditionalProperties.cameraPosition.x,
      y: viewportAdditionalProperties.cameraPosition.y,
      z: viewportAdditionalProperties.cameraPosition.z,
    };
    const cameraTarget = {
      x: viewportAdditionalProperties.cameraTarget.x,
      y: viewportAdditionalProperties.cameraTarget.y,
      z: viewportAdditionalProperties.cameraTarget.z,
    };
    animateControls(cameraPosition, cameraTarget);
  };

  const cameraOrientationSave = () => {
    const cameraPosition = {
      x: controls.object.position.x,
      y: controls.object.position.y,
      z: controls.object.position.z,
    };
    const cameraTarget = {
      x: controls.target.x,
      y: controls.target.y,
      z: controls.target.z,
    };
    handleChangeAdditionalPropertiesViewport({
      cameraPosition: cameraPosition,
      cameraTarget: cameraTarget,
    });
  };

  const stylesIcon = {
    height: '18px',
    width: '18px',
    color: '#A1A1A1',
  };

  const gridTooltipLabel = `Toggle ${isRIC ? 'RIC reference' : 'infinite planar'} grid`;

  return (
    <ControlsContainer className="ViewportControls">
      {orbits && isRIC && (
        <>
          <Grid
            container
            position="relative"
            width="fit-content"
          >
            {isRIC && isGridOn && (
              <Tooltip
                title="Length of 1 grid square"
                placement="top"
                disableInteractive
              >
                <RicRefGridScaleValueDisplay
                  pl={1}
                  pr={1}
                  item
                >
                  {ricRefGridScaleValue}
                </RicRefGridScaleValueDisplay>
              </Tooltip>
            )}
          </Grid>
          <Select
            labelId="ric-target-label"
            id="ric-target"
            value={viewportTargetId || ''}
            onChange={handleTargetChange}
            variant="standard"
          >
            {orbits.map((orbit) => (
              <MenuItem
                key={orbit.id}
                value={orbit.id}
              >
                Origin on {orbit.name}
              </MenuItem>
            ))}
          </Select>
        </>
      )}

      <Select
        labelId="mode-select-label"
        id="mode-select"
        value={viewport.type}
        onChange={handleModeChange}
        variant="standard"
        disabled={isReadOnly}
      >
        <MenuItem value={ReferenceFrame.ECI}>{ReferenceFrame.ECI}</MenuItem>
        <MenuItem value={ReferenceFrame.ECEF}>{ReferenceFrame.ECEF}</MenuItem>
        <MenuItem value={ReferenceFrame.RIC}>{ReferenceFrame.RIC}</MenuItem>
        <MenuItem value={ReferenceFrame.TWO_DIMENSIONS}>2D</MenuItem>
      </Select>

      <ButtonControls>
        <Tooltip
          title={gridTooltipLabel}
          disableInteractive
        >
          <IconButton
            onClick={() => {
              handleChangeAdditionalPropertiesViewport({
                visGrid: !isGridOn,
              });
            }}
            size="small"
          >
            {isGridOn ? (
              <GridOnRounded style={{ height: '18px', width: '18px', color: '#A1A1A1' }} />
            ) : (
              <GridOffRounded style={{ height: '18px', width: '18px', color: '#A1A1A1' }} />
            )}
          </IconButton>
        </Tooltip>

        {!is2D && (
          <Tooltip
            title="Toggle starfield"
            disableInteractive
          >
            <IconButton
              onClick={() => {
                handleChangeAdditionalPropertiesViewport({ visStarField: !isStarfieldOn });
              }}
              size="small"
            >
              {isStarfieldOn ? (
                <StarRounded style={{ height: '18px', width: '18px', color: '#A1A1A1' }} />
              ) : (
                <StarBorderRounded style={{ height: '18px', width: '18px', color: '#A1A1A1' }} />
              )}
            </IconButton>
          </Tooltip>
        )}

        <span
          style={{
            position: 'relative',
            display: 'inline-block',
          }}
          onMouseOut={() => {
            setCameraOptionsVisible(false);
          }}
          onMouseOver={() => {
            setCameraOptionsVisible(true);
          }}
        >
          <IconButton
            size="small"
            disabled
          >
            <VideocamRounded style={stylesIcon} />
          </IconButton>

          <ControlsCamera
            style={{
              visibility: cameraOptionsVisible ? 'visible' : 'hidden',
            }}
          >
            <Tooltip
              title="Reset Camera to Default Orientation"
              disableInteractive
            >
              <span>
                <IconButton
                  onClick={moveCameraControlsHome}
                  size="small"
                >
                  <CameraIndoorRounded style={stylesIcon} />
                </IconButton>
              </span>
            </Tooltip>

            <Tooltip
              title="Reset Camera to Saved Orientation"
              disableInteractive
            >
              <span>
                <IconButton
                  onClick={cameraOrientationReset}
                  size="small"
                >
                  <ReplayRounded style={stylesIcon} />
                </IconButton>
              </span>
            </Tooltip>

            {!isReadOnly && (
              <Tooltip
                title="Save Camera Orientation"
                disableInteractive
              >
                <span>
                  <IconButton
                    onClick={cameraOrientationSave}
                    size="small"
                  >
                    <SaveRounded style={stylesIcon} />
                  </IconButton>
                </span>
              </Tooltip>
            )}
          </ControlsCamera>
        </span>

        {!isRIC && (
          <Tooltip
            title="Zoom to Selected Orbit"
            disableInteractive
          >
            <span>
              <IconButton
                disabled={isRIC}
                onClick={() => {
                  if (getCurrentECZoomDistance) {
                    const radius = getCurrentECZoomDistance();

                    moveCameraControlsToFit(radius * 2);
                  }
                }}
                size="small"
              >
                <FrameAllIcon style={{ height: '18px', width: '18px' }} />
              </IconButton>
            </span>
          </Tooltip>
        )}

        {!isRIC && (
          <Tooltip
            title="Zoom to All Orbits"
            disableInteractive
          >
            <span>
              <IconButton
                disabled={isRIC}
                onClick={() => {
                  let radius = 0;

                  orbits?.forEach((orbit) => {
                    const { getECZoomDistance } = createGetOrbitState(orbit.id)();

                    const curRadius = getECZoomDistance();

                    if (curRadius > radius) {
                      radius = curRadius;
                    }
                  });

                  moveCameraControlsToFit(radius * 2);
                }}
                size="small"
              >
                <FrameSelectedIcon style={{ height: '18px', width: '18px' }} />
              </IconButton>
            </span>
          </Tooltip>
        )}

        {!isReadOnly && canAddViewport && (
          <Tooltip
            title="Add Viewport"
            disableInteractive
          >
            <IconButton
              onClick={handleAddViewport}
              size="small"
            >
              <AddRounded style={{ height: '18px', width: '18px', color: '#A1A1A1' }} />
            </IconButton>
          </Tooltip>
        )}

        {!isReadOnly && canRemoveViewport && (
          <Tooltip
            title="Remove Viewport"
            disableInteractive
          >
            <IconButton
              onClick={handleRemoveViewport}
              size="small"
            >
              <RemoveRounded style={{ height: '18px', width: '18px', color: '#A1A1A1' }} />
            </IconButton>
          </Tooltip>
        )}
      </ButtonControls>
    </ControlsContainer>
  );
}
