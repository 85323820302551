import { DashboardRounded } from '@mui/icons-material';
import { Grid, Menu, MenuItem } from '@mui/material';
import { useRef, useState } from 'react';
import { useCreateLayout, useDeleteLayout, useUpdateLayout } from 'src/hooks/LayoutHooks';
import { MenuBarIconButton } from 'src/pages/App/components/MenuBar.styled';
import { LayoutMinMax } from './LayoutMinMax';
import { LayoutSelection } from './LayoutSelection';
import { LayoutStoreItem, LAYOUT_ACTIVE_ID_DEFAULT, useLayoutStore } from './layoutStore';
import { ModalLayoutRename } from './ModalLayoutRename';

export const MenuBarLayout = () => {
  const iconDashboardRef = useRef<HTMLButtonElement>(null);
  const [dashboardMenuOpen, setDashboardMenuOpen] = useState(false);
  const [modalLayoutRenameOpen, setModalLayoutRenameOpen] = useState(false);

  const createLayout = useCreateLayout();
  const udpateLayout = useUpdateLayout();
  const deleteLayout = useDeleteLayout();

  const layoutActiveId = useLayoutStore((state) => state.layoutActiveId);
  const setLayout = useLayoutStore((state) => state.setLayout);
  const setLayoutActiveId = useLayoutStore((state) => state.setLayoutActiveId);
  const deleteLayoutInStore = useLayoutStore((state) => state.deleteLayout);

  const isLayoutPredefined = useLayoutStore(
    (state) => state.layouts[state.layoutActiveId]?.predefined,
  );

  const isLayoutSaved = useLayoutStore(
    (state) =>
      state.layouts[state.layoutActiveId]?.predefined || state.layouts[state.layoutActiveId]?.saved,
  );

  const layoutToUse = useLayoutStore((state) => {
    return state.layouts[state.layoutActiveId] || {};
  });

  const saveLayout = async (updates: Partial<LayoutStoreItem> = {}) => {
    if (layoutToUse.layoutOriginal) {
      // update the database layout and re-set in store
      const updatedLayout = await udpateLayout.mutateAsync({
        id: layoutToUse.layoutOriginal.id,
        name: layoutToUse.name,
        properties: {
          panels: layoutToUse.layout,
        },
        ...updates,
      });
      setLayout(updatedLayout);
    } else {
      // create and remove custom layout
      const layoutCurrentId = layoutActiveId;
      const newLayout = await createLayout.mutateAsync({
        name: layoutToUse.name,
        properties: {
          panels: layoutToUse.layout,
        },
        ...updates,
      });
      setLayout(newLayout);
      setLayoutActiveId(newLayout.id);
      deleteLayoutInStore(layoutCurrentId);
    }
    setDashboardMenuOpen(false);
  };

  const handleSaveLayout = () => {
    if (!layoutToUse.layoutOriginal) {
      // prompt for name
      handleLayoutRename();
    } else {
      saveLayout();
    }
  };

  const handleLayoutRename = () => {
    setModalLayoutRenameOpen(true);
    setDashboardMenuOpen(false);
  };

  const handleDeleteLayout = () => {
    if (layoutToUse.layoutOriginal !== undefined) {
      deleteLayout.mutate(layoutActiveId as number);
    }
    deleteLayoutInStore(layoutActiveId);
    setLayoutActiveId(LAYOUT_ACTIVE_ID_DEFAULT);
    setDashboardMenuOpen(false);
  };

  const handleRevertLayout = () => {
    if (layoutToUse.layoutOriginal) {
      setLayout(layoutToUse.layoutOriginal);
    }
    setDashboardMenuOpen(false);
  };

  return (
    <>
      <ModalLayoutRename
        isOpen={modalLayoutRenameOpen}
        close={() => setModalLayoutRenameOpen(false)}
        layoutToEdit={layoutToUse}
        saveLayout={saveLayout}
      />

      <Grid
        display="grid"
        gridAutoFlow="column"
        alignItems="center"
        justifyContent="start"
      >
        <MenuBarIconButton
          ref={iconDashboardRef}
          onClick={() => setDashboardMenuOpen(true)}
        >
          <DashboardRounded />
        </MenuBarIconButton>

        <LayoutSelection />

        <LayoutMinMax />

        {
          <Menu
            anchorEl={iconDashboardRef.current}
            keepMounted
            open={dashboardMenuOpen}
            onClose={() => setDashboardMenuOpen(false)}
          >
            {!isLayoutPredefined && (
              <MenuItem
                disabled={isLayoutSaved || !layoutToUse.layoutOriginal}
                onClick={() => handleRevertLayout()}
              >
                Revert Layout Changes
              </MenuItem>
            )}

            {!isLayoutPredefined && (
              <MenuItem
                disabled={isLayoutSaved}
                onClick={() => handleSaveLayout()}
              >
                Save Layout Changes
              </MenuItem>
            )}

            <MenuItem
              disabled={isLayoutPredefined}
              onClick={() => handleLayoutRename()}
            >
              Rename Layout
            </MenuItem>

            <MenuItem
              disabled={isLayoutPredefined}
              onClick={() => handleDeleteLayout()}
            >
              Delete Layout
            </MenuItem>
          </Menu>
        }
      </Grid>
    </>
  );
};
