import { immerable } from 'immer';
import { MathUtils } from 'three';

export type COEDataKeys =
  | 'semiMajorAxis'
  | 'eccentricity'
  | 'inclination'
  | 'rightAscensionOfAscendingNode'
  | 'argumentOfPeriapsis'
  | 'trueAnomaly';

export type COEData = {
  argumentOfPeriapsis: number;
  eccentricity: number;
  epoch: number;
  inclination: number;
  rightAscensionOfAscendingNode: number;
  semiMajorAxis: number;
  trueAnomaly: number;
  mass?: number;
};

/**
 * Stores the data for an orbit
 */
export class COE {
  [immerable] = true;
  public argumentOfPeriapsis: number;
  public eccentricity: number;
  public epoch: number;
  public inclination: number;
  public rightAscensionOfAscendingNode: number;
  public semiMajorAxis: number;
  public trueAnomaly: number;
  public mass?: number;

  constructor(data: COEData) {
    this.argumentOfPeriapsis = data.argumentOfPeriapsis;
    this.eccentricity = data.eccentricity;
    this.epoch = data.epoch;
    this.inclination = data.inclination;
    this.rightAscensionOfAscendingNode = data.rightAscensionOfAscendingNode;
    this.semiMajorAxis = data.semiMajorAxis;
    this.trueAnomaly = data.trueAnomaly;
    this.mass = data.mass ? data.mass : 5;
  }

  isEqual(newCOE: COEData): boolean {
    return (
      newCOE.argumentOfPeriapsis === this.argumentOfPeriapsis &&
      newCOE.eccentricity === this.eccentricity &&
      newCOE.inclination === this.inclination &&
      newCOE.rightAscensionOfAscendingNode === this.rightAscensionOfAscendingNode &&
      newCOE.argumentOfPeriapsis === this.argumentOfPeriapsis &&
      newCOE.trueAnomaly === this.trueAnomaly &&
      newCOE.epoch === this.epoch
    );
  }

  getData(): COEData {
    return {
      semiMajorAxis: this.semiMajorAxis,
      eccentricity: this.eccentricity,
      inclination: this.inclination,
      rightAscensionOfAscendingNode: this.rightAscensionOfAscendingNode,
      argumentOfPeriapsis: this.argumentOfPeriapsis,
      trueAnomaly: this.trueAnomaly,
      epoch: this.epoch,
      mass: this.mass,
    };
  }

  setData(newData: Pick<COEData, COEDataKeys>) {
    const keys = Object.keys(newData) as [COEDataKeys];
    keys.forEach((key) => {
      if (newData[key] !== undefined) {
        this[key] = newData[key];
      }
    });
  }

  toRad(): Pick<
    COEData,
    | 'semiMajorAxis'
    | 'eccentricity'
    | 'inclination'
    | 'rightAscensionOfAscendingNode'
    | 'argumentOfPeriapsis'
    | 'trueAnomaly'
  > {
    // update or coe property with new coe in degrees
    return {
      semiMajorAxis: this.semiMajorAxis,
      eccentricity: this.eccentricity,
      inclination: MathUtils.degToRad(this.inclination),
      rightAscensionOfAscendingNode: MathUtils.degToRad(this.rightAscensionOfAscendingNode),
      argumentOfPeriapsis: MathUtils.degToRad(this.argumentOfPeriapsis),
      trueAnomaly: MathUtils.degToRad(this.trueAnomaly),
    };
  }

  fromRad(data: COEData) {
    this.semiMajorAxis = data.semiMajorAxis;
    this.eccentricity = data.eccentricity;
    this.inclination = MathUtils.radToDeg(data.inclination);
    this.rightAscensionOfAscendingNode = MathUtils.radToDeg(data.rightAscensionOfAscendingNode);
    this.argumentOfPeriapsis = MathUtils.radToDeg(data.argumentOfPeriapsis);
    this.trueAnomaly = MathUtils.radToDeg(data.trueAnomaly);
  }

  hasChanged(newData?: COEData) {
    if (!newData) {
      return false;
    }
    const keys = Object.keys(newData) as [keyof COEData];
    const valuesHaveNotChanged = keys.every((key) => {
      return this[key] === newData[key];
    });
    return !valuesHaveNotChanged;
  }
}
