import { alpha } from '@mui/material';
import { geoCircle, geoPath } from 'd3-geo';
import { Coordinate, SpaceSensor } from 'src/types';
import {
  calculateFieldOfRegard,
  earthCentralAngleFromAltitudeAndNadirAngle,
} from 'src/utilities/OrbitUtils';
import { geoProjectionEarth2D } from '../GroundTrackTexture/utils/useEarthTexture';
import { DEFAULT_ADDITIONAL_PROPERTIES_SPACE_SENSORS } from 'src/constants';

export const DrawSpaceSensorLayers = {
  FOR: 'for',
  FOV: 'fov',
  ALL: 'all',
};

export interface DrawSpaceSensorProps {
  altitude: number;
  context: CanvasRenderingContext2D;
  coordinate: Coordinate;
  targetCoordinates: Coordinate[];
  layers?: (typeof DrawSpaceSensorLayers)[keyof typeof DrawSpaceSensorLayers];
  spaceSensor: SpaceSensor;
}

export const drawSpaceSensor = ({
  altitude,
  context,
  coordinate,
  targetCoordinates,
  layers = DrawSpaceSensorLayers.ALL,
  spaceSensor,
}: DrawSpaceSensorProps) => {
  const pathGeo = geoPath(geoProjectionEarth2D, context);

  context.save();
  context.lineWidth = 4;

  const additionalProperties = {
    ...DEFAULT_ADDITIONAL_PROPERTIES_SPACE_SENSORS,
    ...spaceSensor.additionalProperties,
  };

  // handle the FOR display
  if (
    [DrawSpaceSensorLayers.FOR, DrawSpaceSensorLayers.ALL].includes(layers) &&
    (additionalProperties.visFORConeShader || additionalProperties.visFORConeWireframe)
  ) {
    const radiusFOR = earthCentralAngleFromAltitudeAndNadirAngle(
      altitude,
      calculateFieldOfRegard(spaceSensor.minGroundElevationAngle, altitude),
    );
    const circleFOR = geoCircle()
      .radius(radiusFOR)
      .precision(1)
      .center([coordinate.longitude, coordinate.latitude])();

    context.fillStyle = alpha(
      additionalProperties.forConeShaderColor,
      additionalProperties.forConeShaderOpacity / 100,
    );
    context.strokeStyle = alpha(
      additionalProperties.forConeWireframeColor,
      additionalProperties.forConeWireframeOpacity / 100,
    );
    context.beginPath();

    pathGeo(circleFOR);

    if (additionalProperties.visFORConeShader) {
      context.fill();
    }
    if (additionalProperties.visFORConeWireframe) {
      // commenting out for now as it creates vertical lines at the 180 longitude line when crossing
      // context.stroke();
    }

    context.closePath();
  }

  // handle the FOV display
  if (
    [DrawSpaceSensorLayers.FOV, DrawSpaceSensorLayers.ALL].includes(layers) &&
    (additionalProperties.visFOVConeShader || additionalProperties.visFOVConeWireframe)
  ) {
    const fovCoords = targetCoordinates;
    if (fovCoords.length === 0) {
      // default fov to coordinate if no target viewing windows
      fovCoords.push(coordinate);
    }
    fovCoords.forEach((fovCoords) => {
      const radiusFOV = earthCentralAngleFromAltitudeAndNadirAngle(
        altitude,
        spaceSensor.fieldOfView,
      );
      const circleFOV = geoCircle()
        .radius(radiusFOV)
        .precision(1)
        .center([fovCoords.longitude, fovCoords.latitude])();

      context.fillStyle = alpha(
        additionalProperties.fovConeShaderColor,
        additionalProperties.fovConeShaderOpacity / 100,
      );
      context.strokeStyle = alpha(
        additionalProperties.fovConeWireframeColor,
        additionalProperties.fovConeWireframeOpacity / 100,
      );
      context.beginPath();
      pathGeo(circleFOV);

      if (additionalProperties.visFOVConeShader) {
        context.fill();
      }
      if (additionalProperties.visFOVConeWireframe) {
        // commenting out for now as it creates vertical lines at the 180 longitude line when crossing
        // context.stroke();
      }

      context.closePath();
    });
  }

  context.restore();
};
