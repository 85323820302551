import { ExpandMoreRounded } from '@mui/icons-material';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  CircularProgress,
  Grid,
  Typography,
} from '@mui/material';
import { SpaceSensor } from 'src/types';
import { WINDOWS_STATUS, WINDOWS_TYPES, useViewingWindowsStore } from './ViewingWindowsStore';
import { ObjectPanelDisplay } from './ObjectPanelDisplay';
import { millisToLocale } from 'src/utilities/DateTimeUtils';
import { formatDuration, intervalToDuration } from 'date-fns';
import { ObjectSummaryBody } from './ObjectPanelDisplay.styled';
import { useCurrentGroundObjects } from 'src/hooks/GroundObjectHooks';
import { useState } from 'react';
import { ModalViewingWindows } from './ModalViewingWindows';
import useAppStore from 'src/core/store';
import PropagatedCacheManager from 'src/models/PropagatedCacheManager';
import { useCurrentPage } from 'src/hooks/PageHooks';
import { useCurrentOrbits, useIsPropagating } from 'src/hooks/OrbitHooks';

interface TabPanelOrbitSensorsWindowsProps {
  spaceSensor: SpaceSensor;
}

export const TabPanelOrbitSensorsWindows = ({ spaceSensor }: TabPanelOrbitSensorsWindowsProps) => {
  const [contactWindowOpen, setContactWindowOpen] = useState(false);

  const currentGroundObjects = useCurrentGroundObjects();

  const isFetching = useViewingWindowsStore(
    (state) =>
      state.spaceSensorWindows[spaceSensor.orbitId] &&
      state.spaceSensorWindows[spaceSensor.orbitId].status !== WINDOWS_STATUS.COMPLETED,
  );
  const viewingWindows = useViewingWindowsStore(
    (state) => state.spaceSensorWindows[spaceSensor.orbitId]?.windows || [],
  );

  const hasNoTarget = !spaceSensor.targetGroupId && !spaceSensor.targetGroundObjectId;

  const {
    timelines: { updateTimelineRange },
  } = useAppStore.getState();

  const currentPage = useCurrentPage();
  const currentOrbits = useCurrentOrbits();

  const isPropagating = useIsPropagating();

  return (
    <Accordion defaultExpanded>
      <AccordionSummary expandIcon={<ExpandMoreRounded />}>Observation Windows:</AccordionSummary>
      <AccordionDetails sx={{ px: 2 }}>
        <Grid display="grid">
          {hasNoTarget && <Grid textAlign="center">Select a target to show viewing windows</Grid>}

          {isFetching && <CircularProgress />}

          {!isFetching && !spaceSensor.enabled && (
            <Grid textAlign="center">Sensor is disabled</Grid>
          )}

          {!isFetching && spaceSensor.enabled && viewingWindows.length > 0 && (
            <Grid
              display="grid"
              justifyContent="center"
            >
              <ModalViewingWindows
                show={contactWindowOpen}
                onClose={() => {
                  setContactWindowOpen(false);
                }}
                type={WINDOWS_TYPES.SPACE_SENSOR}
                object={spaceSensor}
                viewingWindows={viewingWindows}
              />

              <Button
                size="small"
                variant="contained"
                onClick={() => setContactWindowOpen(true)}
              >
                Generate Text report
              </Button>
            </Grid>
          )}

          {!isFetching &&
            spaceSensor.enabled &&
            viewingWindows
              .filter((window) => window.spaceSensorId === spaceSensor.id)
              .map((viewingWindow, index) => {
                const dur = intervalToDuration({
                  start: viewingWindow.startTime,
                  end: viewingWindow.endTime,
                });
                const displayedObject = currentGroundObjects?.find(
                  (groundObject) => groundObject.id === viewingWindow.groundObjectId,
                );
                return (
                  <ObjectPanelDisplay
                    key={`${viewingWindow.startTime}-${viewingWindow.endTime}-${viewingWindow.spaceSensorId}}`}
                    headerRow={millisToLocale(viewingWindow.startTime.getTime(), 'utc')}
                    titleRow={`Window ${index + 1}`}
                    accentColor={viewingWindow.color || 'white'}
                    props={{
                      onClick: async () => {
                        if (!isPropagating && currentPage && currentOrbits) {
                          await PropagatedCacheManager.propagateTimeline(
                            currentPage,
                            currentOrbits,
                            true,
                          );
                        }
                        updateTimelineRange({
                          playState: 'seeking',
                          currentTime: viewingWindow.startTime.getTime(),
                          currentTimePreviewMode: viewingWindow.startTime.getTime(),
                        });
                      },
                      style: { cursor: 'pointer' },
                    }}
                  >
                    {displayedObject && <Typography>Object: {displayedObject.name}</Typography>}

                    <ObjectSummaryBody>
                      Range (d):
                      {` ${viewingWindow.startRange}`} - {viewingWindow.endRange} km
                    </ObjectSummaryBody>

                    <ObjectSummaryBody>
                      Elevation Angle (ε): {viewingWindow.maxElevationAngle} Deg
                    </ObjectSummaryBody>

                    <ObjectSummaryBody>
                      End: {millisToLocale(viewingWindow.endTime.getTime(), 'utc')}
                    </ObjectSummaryBody>

                    <ObjectSummaryBody>Duration: {formatDuration(dur)}</ObjectSummaryBody>
                  </ObjectPanelDisplay>
                );
              })}
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
};
