import { createContext, FunctionComponent, memo, useContext, useMemo } from 'react';

type OrbitContextValue = {
  id: number;
  name: string;
  children: React.ReactNode;
  size: number;
  selected: boolean;
};

const OrbitContext = createContext<OrbitContextValue | null>(null);

/** Provides each orbit and all of it's children with access to common orbit properties like id and name. */
const OrbitProvider: FunctionComponent<OrbitContextValue> = ({
  id,
  name,
  children,
  size,
  selected,
}) => {
  const value = useMemo(
    () => ({ id, name, children, size, selected }),
    [id, name, children, size, selected],
  );
  return <OrbitContext.Provider value={value}>{children}</OrbitContext.Provider>;
};

export function use3DOrbitContext() {
  const context = useContext(OrbitContext);

  if (context === null) {
    throw new Error();
  }

  return context;
}

export default memo(OrbitProvider) as typeof OrbitProvider;
