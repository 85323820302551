import { DeleteRounded } from '@mui/icons-material';
import { Button, Dialog, DialogActions, DialogContent, Typography } from '@mui/material';
import { partial } from 'lodash';
import React from 'react';
import { SpaceGlassContainerBase } from 'src/components/SpaceGlassControl';
import { useCurrentPage, useDeletePageMutation } from 'src/hooks/PageHooks';
import { ModalHeader } from 'src/pages/Shared/ModalSpaceGlass';
import { Page } from 'src/types';

interface Props {
  editingPage?: Page;
  open: boolean;
  onClose: VoidFunction;
  show: (show: boolean) => void;
}

const DeletePageModal: React.ComponentType<Props> = (props) => {
  const currentPage = useCurrentPage();
  const deletePage = useDeletePageMutation();

  const hide = partial(props.show, false);

  /**
   * Deletes a page
   */
  const onConfirmDelete = () => {
    if (props.editingPage && currentPage) {
      deletePage.mutate({ ...currentPage, id: props.editingPage.id });
    }
    onClose();
  };

  /**
   * Closes the modal and resets the parent modal
   */
  const onClose = () => {
    props.onClose();
    hide();
  };

  return (
    <Dialog
      fullWidth
      maxWidth="sm"
      open={props.open}
      PaperComponent={SpaceGlassContainerBase}
    >
      <ModalHeader
        title="Are You Sure?"
        onClose={onClose}
        icon={<DeleteRounded />}
      />
      <DialogContent>
        <Typography variant="body1">This page and all orbits will be deleted forever.</Typography>
      </DialogContent>
      <DialogActions>
        <Button
          type="button"
          onClick={onClose}
        >
          CANCEL
        </Button>
        <Button
          color="error"
          variant="contained"
          onClick={onConfirmDelete}
          aria-label="Delete Page"
        >
          Delete Forever
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DeletePageModal;
