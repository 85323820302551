import { Grid } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import { Html } from '@react-three/drei';
import { QueryClientProvider, useQueryClient } from '@tanstack/react-query';
import { useCallback, useEffect } from 'react';
import { getCurrentTime } from 'src/core/getters';
import useAppStore from 'src/core/store';
import { useCurrentBookmark } from 'src/hooks/BookmarkHooks';
import theme from 'src/pages/App/Theme';
import { AVATAR_SIZE } from 'src/pages/Notebook/components/StoryTelling/Avatar';
import { BookmarkCallout } from 'src/pages/Notebook/components/StoryTelling/BookmarkCallout';
import { Bookmark } from 'src/types';
import { use3DOrbitContext } from '../../Orbit/context';
import { useDisplayedCallouts, useSetDisplayedCallouts } from '../../OrbitManager/store/hooks';
import ViewportProvider, { useViewportContext } from '../../Viewport/context';

export const Callouts = () => {
  const { name: orbitName } = use3DOrbitContext();
  const { viewport } = useViewportContext();

  const setDisplayedCallout = useSetDisplayedCallouts();
  const displayedCallouts = useDisplayedCallouts();

  const currentBookmarks = useCurrentBookmark();

  const updateDisplayedCallout = useCallback(() => {
    if (currentBookmarks) setDisplayedCallout(currentBookmarks);
  }, [currentBookmarks, setDisplayedCallout]);

  // when timeline changes time, display callout dialogue boxes for new time
  useEffect(() => {
    updateDisplayedCallout();
    return useAppStore.subscribe(getCurrentTime, updateDisplayedCallout);
  }, [updateDisplayedCallout]);

  const queryClient = useQueryClient();

  // if no callouts, just return
  if (displayedCallouts.length === 0) {
    return null;
  }

  return (
    <Html zIndexRange={[0, 0]}>
      <ViewportProvider viewport={viewport}>
        <QueryClientProvider client={queryClient}>
          <ThemeProvider theme={theme}>
            <Grid
              container
              direction="column"
              spacing={2}
              wrap="nowrap"
              sx={{
                transform: (theme) => `translate(${theme.spacing(3)}, -${AVATAR_SIZE * 0.5}px)`,
              }}
            >
              {displayedCallouts.map((bookmark: Bookmark) => {
                return (
                  <Grid
                    item
                    key={bookmark.id}
                  >
                    <BookmarkCallout
                      bookmark={bookmark}
                      orbitName={orbitName}
                    />
                  </Grid>
                );
              })}
            </Grid>
          </ThemeProvider>
        </QueryClientProvider>
      </ViewportProvider>
    </Html>
  );
};
