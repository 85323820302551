import { ContentCopyRounded } from '@mui/icons-material';
import { Button, Grid } from '@mui/material';
import { MANEUVER_FLYOUT_STATUSES } from 'src/constants';
import { useUpdateTimelineRange } from 'src/core/hooks';
import { useCurrentOrbit, useCurrentOrbits, useIsPropagating } from 'src/hooks/OrbitHooks';
import { useCurrentPage } from 'src/hooks/PageHooks';
import PropagatedCacheManager from 'src/models/PropagatedCacheManager';
import { SETTINGS_NAMES, useSettingsStore } from 'src/pages/Settings/store';
import { Maneuver } from 'src/types';
import { ManeuverSummary } from './Maneuvers/ManeuverSummary';
import { useManeuverStore } from './Maneuvers/store';

export const TabPanelOrbitManeuvers = () => {
  const currentOrbit = useCurrentOrbit();

  const maneuverColors = useManeuverStore((state) => state.colors);

  const setCustom = useSettingsStore((state) => state.setCustom);

  const isPropagating = useIsPropagating();

  const currentPage = useCurrentPage();
  const currentOrbits = useCurrentOrbits();
  const updateTimelineRange = useUpdateTimelineRange();

  return (
    <Grid
      container
      p={0}
      pt={1}
      justifyContent="center"
    >
      {currentOrbit?.maneuvers?.map(
        (maneuver: Maneuver) =>
          maneuver?.id && (
            <ManeuverSummary
              key={maneuver.id}
              color={maneuverColors[maneuver.id]}
              maneuver={maneuver}
            />
          ),
      )}

      <Grid
        item
        pt={1}
        fontSize="small"
        textAlign="center"
      >
        Click Add A Maneuver to adjust this satellites orbit
      </Grid>

      <Grid
        container
        direction="column"
        alignItems="center"
      >
        <Button
          variant="contained"
          aria-label="Add Maneuver"
          startIcon={<ContentCopyRounded />}
          sx={{ m: 2 }}
          onClick={() => {
            if (!isPropagating && currentPage?.startTime) {
              updateTimelineRange({
                currentTime: currentPage.startTime.getTime(),
                currentTimePreviewMode: currentPage.startTime.getTime(),
                playState: 'seeking',
              });
              if (currentPage && currentOrbits) {
                PropagatedCacheManager.propagateTimeline(currentPage, currentOrbits, true);
              }
            }
            setCustom(SETTINGS_NAMES.MANEUVERS_FLYOUT_STATUS, MANEUVER_FLYOUT_STATUSES.CREATING);
          }}
        >
          Add A Maneuver
        </Button>
      </Grid>
    </Grid>
  );
};
