import { Grid } from '@mui/material';
import { ReactNode } from 'react';

interface SlideoutPanelLabelProps {
  children: ReactNode;
}

export const SlideoutPanelLabel = ({ children }: SlideoutPanelLabelProps) => {
  return (
    <Grid
      fontSize={12}
      whiteSpace="nowrap"
      pt={1}
      pb={0.5}
      sx={{
        fontVariationSettings: "'wght' 600",
      }}
    >
      {children}
    </Grid>
  );
};
