import { useCallback, useEffect, useState } from 'react';
import { earthradius } from 'src/constants';
import { useCurrentTime } from 'src/core/hooks';
import { useCurrentViewports } from 'src/hooks/ViewportHooks';
import PropagatedCacheManager, {
  OrbitIdentifier,
  SeekedEventPlayload,
} from 'src/models/PropagatedCacheManager';
import ViewportProvider from 'src/threejs/components/Viewport/context';
import Viewport from 'src/threejs/components/Viewport/Viewport';
import { OrbitLerper } from '../OrbitManager/OrbitLerper';
import use3DOrbitStore from '../OrbitManager/store/store';
import ViewportCanvasProvider from '../ViewportCanvasProvider';
import { Container } from './ViewportManager.styled';

const scaleFactor = 1 / earthradius;
const maxPointCount = 200000;

/** Manages rendering each viewport that a user adds. */
const ViewportManager = () => {
  const currentTime = useCurrentTime();

  const [canvas] = useState(() => document.createElement('canvas'));

  const currentViewports = useCurrentViewports();
  const numberOfViewports = currentViewports?.length || 0;

  const handleSeeked = useCallback(({ orbitId, stateVectors }: SeekedEventPlayload) => {
    const addStateVectorsForOrbit =
      use3DOrbitStore.getState().orbits[orbitId].orbitPropagationPath.addStateVectors;
    addStateVectorsForOrbit(stateVectors, maxPointCount, scaleFactor);
  }, []);

  useEffect(() => {
    PropagatedCacheManager.addEventListener('seeked', handleSeeked);
    return function cleanup() {
      PropagatedCacheManager.removeEventListener('seeked', handleSeeked);
    };
  }, [handleSeeked]);

  useEffect(() => {
    const resetOrbitPathData = (data: OrbitIdentifier[]) => {
      data.forEach(({ id }) => {
        const resetOrbitPathDataForOrbit =
          use3DOrbitStore.getState().orbits[id].orbitPropagationPath.reset;
        resetOrbitPathDataForOrbit();
      });
    };

    PropagatedCacheManager.addEventListener('clearpropagations', resetOrbitPathData);
    return () =>
      PropagatedCacheManager.removeEventListener('clearpropagations', resetOrbitPathData);
  }, []);

  if (!currentTime) {
    return null;
  }

  return (
    <Container
      className="ViewportManager"
      numberofviewports={numberOfViewports}
    >
      <OrbitLerper />
      {currentViewports?.map((viewport) => {
        return (
          <ViewportProvider
            viewport={viewport}
            key={viewport.id}
          >
            <ViewportCanvasProvider canvas={canvas}>
              <Viewport />
            </ViewportCanvasProvider>
          </ViewportProvider>
        );
      })}
    </Container>
  );
};

export default ViewportManager;
