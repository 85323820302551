import { Dispatch, SetStateAction } from 'react';
import { COE, COEData } from 'src/threejs/models/COE';
import { Mesh } from 'three';
import { SpaceSensorPointingType } from './enums';

export type Path = {
  id: number;
  name: string;
};

export type Folder = {
  id: number;
  type: 'FOLDER';
  path: Path[];
  children: Folder[];
  name: string;
  onEdit?: VoidFunction;
  onShare?: VoidFunction;
  createdTimestamp: string;
  updatedTimestamp: string;
};

export type CurrentFolder = Pick<Folder, 'id' | 'type' | 'path' | 'children' | 'name'>;

export interface PageAdditionalProperties {
  lightingEnabled: boolean;
  lightingSun: number;
  lightingNight: number;
  visEarth: boolean;
  visOrbitLabels: boolean;
  visSunVector: boolean;
  visFrameVectorLabels: boolean;
  visLatLongLines: boolean;
  visCountries: boolean;
  longFormLabels: boolean;
}

export type Page = {
  createdTimestamp: string;
  id: number;
  name: string;
  objectId: number;
  folderId: number;
  updatedTimestamp: string;
  perturbations: Perturbations;
  startTime: Date;
  endTime: Date;
  additionalProperties: PageAdditionalProperties | null;
};

export type Perturbations = {
  j2?: boolean;
  drag?: boolean;
  solarRadiation?: boolean;
};

export type Notebook = {
  id: number;
  type: 'NOTEBOOK';
  children: Page[];
  path: Path[];
  name: string;
  onEdit?: VoidFunction;
  onShare?: VoidFunction;
  capabilityId?: number;
};

export type SharedNotebook = {
  id: number;
  type: 'SHARED-NOTEBOOK';
  children: Page[];
  path: Path[];
  name: string;
  onEdit?: VoidFunction;
  onShare?: VoidFunction;
  capabilityId?: number;
};

export type SharedFolder = {
  id: number;
  type: 'SHARED-FOLDER';
  children: Page[];
  path: Path[];
  name: string;
  onEdit?: VoidFunction;
  onShare?: VoidFunction;
};

export type NonNullable<T> = Exclude<T, null | undefined>;

export type Position = {
  x_position: number;
  y_position: number;
  z_position: number;
};

export type OrbitTLE = {
  catalogId: number | null;
  titleLine: string;
  line1: string;
  line2: string;
};

export enum SpaceObjectShape {
  BALL = 'BALL',
  CUBE = 'CUBE',
  CONE = 'CONE',
}

export enum SpaceObjectSize {
  SM = 'SM',
  MD = 'MD',
  LG = 'LG',
}

export enum LiveDataMode {
  Keplerian = 'Keplerian',
  Cartesian = 'Cartesian',
  Relative = 'Relative',
}

export interface OrbitAdditionalProperties {
  color: string;
  trackLength: number;
  trackLength3D: number;
  visGroundTracks: boolean;
  visLabel: boolean;
  visOrbit: boolean;
  spaceObjectColor: string;
  spaceObjectShape: SpaceObjectShape;
  spaceObjectSize: SpaceObjectSize;
  groundTrackObjectShape: SpaceObjectShape;
  groundTrackObjectSize: SpaceObjectSize;
  visPositionVec: boolean;
  visVelocityVec: boolean;
  visAngularMomentumVec: boolean;
  visAscendingNodeVec: boolean;
  visEccentricityVec: boolean;
  liveDataMode: LiveDataMode;
}

export interface GroupAdditionalProperties {
  expanded: boolean;
  visOrbits: boolean;
}

export interface GroundObjectAdditionalProperties {
  visLabel: boolean;
  visGroundObj: boolean;
  visGroundObjConeShader: boolean;
  visGroundObjConeWireframe: boolean;
  coneShaderColor: string;
  coneWireframeColor: string;
  coneShaderOpacity: number;
  coneWireframeOpacity: number;
  locationColor: string;
}

export type OrbitObjectStateVector = {
  xPosition: number;
  yPosition: number;
  zPosition: number;
  xVelocity: number;
  yVelocity: number;
  zVelocity: number;
};

export interface SpaceSensor {
  id: number;
  minGroundElevationAngle: number;
  fieldOfView: number;
  targetIlluminated: boolean;
  pointingType: SpaceSensorPointingType;
  enabled: boolean;
  orbitId: number;
  additionalProperties: SpaceSensorAdditionalProperties | null;
  targetGroundObjectId: number | null;
  targetGroupId: number | null;
}

export interface SpaceSensorAdditionalProperties {
  forConeShaderColor: string;
  forConeShaderOpacity: number;
  forConeWireframeColor: string;
  forConeWireframeOpacity: number;
  visFORConeShader: boolean;
  visFORConeWireframe: boolean;

  fovConeShaderColor: string;
  fovConeShaderOpacity: number;
  fovConeWireframeColor: string;
  fovConeWireframeOpacity: number;
  visFOVConeShader: boolean;
  visFOVConeWireframe: boolean;
}

export type OrbitObject = {
  createdTimestamp: string;
  id: number;
  name: string;
  notes: string;
  orbitTLE: OrbitTLE;
  orbit: ReturnType<COE['getData']>[];
  updatedTimestamp: string;
  userId: string;
  orbitType: string;
  perturbations: Perturbations;
  startTimestamp: number;
  endTimestamp: number;
  groupId: number | null;
  maneuvers: Maneuver[] | [];
  spaceSensors: SpaceSensor[] | [];
  initialEpoch: number;
  additionalProperties: OrbitAdditionalProperties | null;
  stateVectors?: OrbitObjectStateVector;
  platformId?: string;
};

export type OrbitTemplate = {
  name: string;
  notes: null;
  id: null;
  userId: string;
  orbit: COEData[];
  orbitType: string;
  perturbations: Perturbations;
  createdTimestamp: string;
  updatedTimestamp: string;
};

export type ECEFStateVectorType = {
  epoch: number;
  speed: number;
  x_position: number;
  y_position: number;
  z_position: number;
  x_velocity: number;
  y_velocity: number;
  z_velocity: number;
};

export type GroundTrackPoint = {
  lat: number;
  lon: number;
};

export type StateVectorType = {
  x_position: number;
  y_position: number;
  z_position: number;
  x_velocity: number;
  y_velocity: number;
  z_velocity: number;
  epoch: number;
  step: number;
  totalSteps: number;
  sunPosition: Position;
  ecefStateVector: ECEFStateVectorType;
  coe: {
    argumentOfPeriapsis: number;
    eccentricity: number;
    inclination: number;
    rightAscensionOfAscendingNode: number;
    semiMajorAxis: number;
    trueAnomaly: number;
  };
  groundTrack: GroundTrackPoint;
  speed: number;
  altitude: number;
};

export type StateVectorData = {
  id: string;
  userId: string;
  errorMessage: string;
  stateVectors: StateVectorType[];
};

export type Capability = {
  accessType: string;
  createdTimestamp: string;
  id: number;
  sourceEmail: string;
  sourceId: number;
  sourceType: string;
  status: string;
  updatedTimestamp: string;
  userEmail: string;
  userId: number;
};

export const ReferenceFrame = {
  ECI: 'ECI',
  ECEF: 'ECEF',
  RIC: 'RIC',
  TWO_DIMENSIONS: 'TWO_DIMENSIONS',
} as const;

export type ReferenceFrameType = (typeof ReferenceFrame)[keyof typeof ReferenceFrame];

export const SocketState = {
  idle: 'idle',
  connecting: 'connecting',
  connected: 'connected',
  disconnecting: 'disconnecting',
  disconnected: 'disconnected',
  errored: 'errored',
} as const;

export type SocketStateType = (typeof SocketState)[keyof typeof SocketState];

export interface MarkerProps {
  handle?: Mesh | null;
  setHandle?: Dispatch<SetStateAction<Mesh | null>>;
  marker?: Mesh | null;
  setMarker?: Dispatch<SetStateAction<Mesh | null>>;
}

export interface MarkerHandleProps {
  handle?: Mesh | null;
  setHandle?: Dispatch<SetStateAction<Mesh | null>>;
}

export type CancellablePromise<T> = Promise<T> & { cancel: () => void };

export type GroupObject = {
  id: number;
  createdTimestamp: string;
  pageId: number;
  name: string;
  notes: string;
  updatedTimestamp: string;
  additionalProperties: GroupAdditionalProperties | null;
};

export interface CameraOrientation {
  cameraPosition: Cartesian | null;
  cameraTarget: Cartesian | null;
}
export interface BookmarkAdditionalProperties {
  cameraOrientations: Record<number, CameraOrientation>;
}

export type Bookmark = {
  id: number;
  orbitId: number;
  timestamp: Date;
  callout: string;
  avatarImageUrl?: string;
  additionalProperties: BookmarkAdditionalProperties | null;
};

export interface AxesProperties {
  label: string;
  labelRIC: string;
  color: string;
}

export type Maneuver = {
  id?: number;
  name: string;
  deltaVelocity: number;
  radialComponent: number;
  inTrackComponent: number;
  crossTrackComponent: number;
  currentSpeed: number;
  timestamp: Date;
  orbitId: number;
  createdTimestamp?: Date;
  updatedTimestamp?: Date;
};

export enum GroundObjectCategory {
  LAUNCH_PAD = 'LAUNCH_PAD',
  SENSOR = 'SENSOR',
  OTHER = 'OTHER',
  LOCATION = 'LOCATION',
}

export type GroundObject = {
  id: number;
  name: string;
  latitude: number;
  longitude: number;
  altitude: number;
  minElevationAngle: number;
  category: GroundObjectCategory;
  sensorRange: number;
  pageId: number;
  groupId: number | null;
  targetGroupId: number | null;
  targetOrbitId: number | null;
  eventProperties: {
    TARGET_ILLUMINATED: boolean;
    GROUND_OBJECT_REQUIRES_DARKNESS: boolean;
  };
  createdTimestamp?: Date;
  updatedTimestamp?: Date;
  additionalProperties: GroundObjectAdditionalProperties | null;
};

export type LabObject = OrbitObject | GroundObject | GroupObject;

export type ViewingWindow = {
  visibleOrbitId: number;
  orbitId: number;
  spaceSensorId: number;
  groundObjectId: number;
  startTime: Date;
  startElevationAngle: number;
  startAzimuth: number;
  startRange: number;
  endTime: Date;
  endElevationAngle: number;
  endAzimuth: number;
  endRange: number;
  maxElevationAngleTime: Date;
  maxElevationAngle: number;
  maxElevationAngleAzimuth: number;
  maxElevationAngleRange: number;
  color?: string;
};

export type ViewingWindowSelection = {
  timestamp: Date;
  windowType: 'start' | 'end';
  color: string | undefined;
};

export interface ViewingWindowsResponseWS {
  requestedGroundObjectId: null | number;
  requestedOrbitId: null | number;
  windows: ViewingWindow[];
}

export interface Cartesian {
  x: number;
  y: number;
  z: number;
}
export interface ViewportAdditionalProperties {
  visStarField: boolean;
  visGrid: boolean;
  cameraPosition: Cartesian;
  cameraTarget: Cartesian;
}

export type Viewport = {
  id: number;
  pageId: number;
  type: ReferenceFrameType;
  position: number;
  createdTimestamp?: Date;
  updatedTimestamp?: Date;
  additionalProperties: ViewportAdditionalProperties | null;
};

export type SpaceObject = {
  QUERY_TYPE: 'NORAD ID' | 'Short Name';
  OBJECT_ID: string;
  NORAD_CAT_ID: number;
  OBJECT_NAME: string;
  USER_DEFINED_TLE_LINE0: string;
  USER_DEFINED_TLE_LINE1: string;
  USER_DEFINED_TLE_LINE2: string;
  EPOCH: string;
};

export type SpaceObjectQueryResponseOMM = {
  state: {
    future: SpaceObject[];
    past: SpaceObject[];
  };
};

export interface OrbitPostResponse {
  pageId: number;
  orbits: OrbitObject[];
}

interface OrganizationRoles {
  id: string;
  name: string;
  roles: Array<string>;
}

export interface OrganizationsRolesForUser {
  organizations: Array<OrganizationRoles>;
}

export interface OrganizationUser {
  id: string;
  name: string;
  email: string;
}
export interface OrganizationUsers {
  id: string;
  users: Array<OrganizationUser>;
}

export interface UsersCurrent {
  idpUserId: string;
  idpIssuer: string;
  email: string;
  name: string;
  additionalProperties: null;
}

export enum OrbitGeoObjectType {
  SpaceObject = 'SpaceObject',
  GroundTrack = 'GroundTrack',
}

export interface EphemerisUploadResponse {
  meta: {
    slingshotId: string;
    noradID: number;
    createdAt: string;
    ingestSource: string;
    originator: string;
    sourceEpoch: string;
    comment: string;
  };
  physical: {
    startAt: string;
    dragBC: string;
    srpBC: string;
    hbr: string;
  };
  statesDetails: {
    frameOrigin: string;
    stateFrame: string;
    covFrame: string;
    unitSystem: string;
  };
  propModel: {
    gravity: string;
    atmosphere: string;
    otherBodies: string;
    srp: boolean;
    earthTides: boolean;
    thrust: boolean;
    interpMeth: string;
  };
  states: [
    {
      epoch: string;
      state: number[];
      sqrtCov: any[];
    },
  ];
}

export interface LaunchWindowRequest {
  startTimestamp: string;
  endTimestamp: string;
  siteLatitude: number;
  siteLongitude: number;
  desiredAltitude: number;
  azimuth?: number;
  localSiderealTime?: number;
  desiredInclination: number;
  desiredRAAN: number;
}

export interface LaunchWindow {
  launchTime: string;
  launchLength: number;
  totalDeltaV: number;
  launchDeltaV: number;
  insertionDeltaV: number;
  launchType: 'ASCENDING';
  siteLatitude: number;
  siteLongitude: number;
  orbitAltitude: number;
  orbitInclination: number;
  orbitRAAN: number;
  launchAzimuth: number;
  launchLocalSiderealTime: number;
  launchManeuver: Maneuver;
  insertionManeuver: Maneuver;
  payloadCoe: COEData;
}

export interface LaunchEventRequest {
  name: string;
  pageId: number;
  launchSiteId: number;
  payloadOrbit: Partial<OrbitObject>;
}

export interface LaunchEvent {
  id: number;
  name: string;
  additionalProperties: Record<any, any>;
  pageId: number;
  launchSiteId: number;
  payloadOrbitId: number;
}

export interface Launch {
  timestamp: Date;
  isLaunch: true;
}

export interface Coordinate {
  latitude: number;
  longitude: number;
}
