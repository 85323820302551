import { Object3DNode } from '@react-three/fiber';
import { useEffect, useMemo, useRef } from 'react';
import { DEFAULT_ADDITIONAL_PROPERTIES_PAGE } from 'src/constants';
import { OrbitRenderOrder } from 'src/enums';
import { useCurrentPage } from 'src/hooks/PageHooks';
import { useSettingsStore } from 'src/pages/Settings/store';
import { AxisHead } from 'src/threejs/from_source/GizmoViewport';
import { AxesHelper as ThreeAxesHelper, Color, LineBasicMaterial, Vector3 } from 'three';
import { useViewportReferenceFrame } from './ViewportManager/store';

type AxesHelperProps = {
  colors?: [Color, Color, Color];
  hideAxisHeads?: boolean;
} & Object3DNode<ThreeAxesHelper, typeof ThreeAxesHelper>;

export function AxesHelper({ hideAxisHeads = false, colors, ...props }: AxesHelperProps) {
  const ref = useRef<ThreeAxesHelper>(null);

  const axesSettings = useSettingsStore((state) => state.axesSettings);

  const colorsToUse = useMemo(() => {
    return (
      colors ||
      ([
        new Color(axesSettings.Y.color),
        new Color(axesSettings.Z.color),
        new Color(axesSettings.X.color),
      ] as [Color, Color, Color])
    );
  }, [colors, axesSettings]);

  useEffect(() => {
    if (ref.current) {
      ref.current.setColors(...colorsToUse);
      const axesLineMaterialRef = ref.current.material as LineBasicMaterial;
      // prevent z fighting between ECEF + other grid lines and axes helper xyz lines
      axesLineMaterialRef.depthWrite = false;
      axesLineMaterialRef.transparent = true;
    }
  }, [colorsToUse]);

  const { isRIC } = useViewportReferenceFrame();

  const [colorY, colorZ, colorX] = colorsToUse;
  const axisCoordPos = isRIC ? 1 : 2;
  const [xAxisPos, yAxisPos, zAxisPos]: Vector3[] = useMemo(
    () => [
      new Vector3(0, 0, axisCoordPos),
      new Vector3(axisCoordPos, 0, 0),
      new Vector3(0, axisCoordPos, 0),
    ],
    [axisCoordPos],
  );

  type axisHeadItemProps = { position: Vector3; label: string; arcStyle: string };
  const axisHeadsArr: axisHeadItemProps[] = [
    {
      position: xAxisPos,
      label: isRIC ? axesSettings.X.labelRIC : axesSettings.X.label,
      arcStyle: `#${colorX.getHexString()}`,
    },
    {
      position: yAxisPos,
      label: isRIC ? axesSettings.Y.labelRIC : axesSettings.Y.label,
      arcStyle: `#${colorY.getHexString()}`,
    },
    {
      position: zAxisPos,
      label: isRIC ? axesSettings.Z.labelRIC : axesSettings.Z.label,
      arcStyle: `#${colorZ.getHexString()}`,
    },
  ];

  const currentPage = useCurrentPage();

  const additionalProperties =
    currentPage?.additionalProperties || DEFAULT_ADDITIONAL_PROPERTIES_PAGE;

  const pageFrameVectorLabelVisibility = additionalProperties.visFrameVectorLabels;

  return (
    <axesHelper
      {...props}
      ref={ref}
      rotation={[0, Math.PI, 0]}
    >
      <group visible={pageFrameVectorLabelVisibility}>
        {!hideAxisHeads &&
          axisHeadsArr.map((axisHeadItemProps: axisHeadItemProps, i) => {
            return (
              <AxisHead
                key={i}
                labelColor="#000"
                font="24px Arial"
                axisHeadScale={0.2}
                renderOrder={OrbitRenderOrder.AXES_HELPER}
                {...axisHeadItemProps}
              />
            );
          })}
      </group>
    </axesHelper>
  );
}
