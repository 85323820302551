import {
  Grid,
  InputAdornment,
  Slider,
  SliderProps,
  TextField,
  TextFieldProps,
  Typography,
} from '@mui/material';
import { useCallback, useMemo } from 'react';

interface GroundObjectPropertySliderProps {
  value: string;
  propertyName: string;
  textFieldProps?: Partial<TextFieldProps>;
  sliderProps: Partial<SliderProps>;
  textErrState?: (fieldNum: number) => boolean;
  textFieldUnits?: string;
  onChange: (item: string) => void;
  hasError?: boolean;
  disabled?: boolean;
}

export const ObjectPropertySlider = ({
  value,
  propertyName,
  textFieldProps,
  sliderProps,
  textErrState,
  textFieldUnits,
  onChange,
  hasError = false,
  disabled = false,
}: GroundObjectPropertySliderProps) => {
  const { min, max } = sliderProps as { min: number; max: number };

  const fieldNum = useMemo(() => parseFloat(value), [value]);
  const handleBlur = useCallback(() => {
    if (!min || !max) return;

    if (!isNaN(Number(fieldNum))) {
      if (fieldNum < min) {
        onChange(min.toString());
      } else if (fieldNum > max) {
        onChange(max.toString());
      }
    } else {
      onChange('0');
    }
  }, [min, max, fieldNum, onChange]);

  const invalidFieldVal = useMemo(() => Number.isNaN(fieldNum), [fieldNum]);

  const isErrored = hasError || invalidFieldVal || (textErrState && textErrState(fieldNum));

  return (
    <Grid
      px={1}
      container
      alignItems="center"
    >
      <Typography
        variant="body2"
        fontWeight="bold"
        whiteSpace="nowrap"
      >
        {propertyName}
      </Typography>
      <Grid
        item
        minWidth="100%"
      >
        <TextField
          disabled={disabled}
          variant="standard"
          type="number"
          fullWidth
          value={value}
          onChange={(e) => onChange(e.target.value)}
          onBlur={handleBlur}
          error={isErrored}
          inputProps={{
            step: 1,
            min: min || 0,
            max: max || 100,
            'aria-labelledby': `${propertyName}-input-slider`,
            inputMode: 'decimal',
            pattern: '[0-9]*',
          }}
          InputProps={{
            error: isErrored,
            endAdornment: (
              <InputAdornment
                disableTypography
                position="end"
              >
                {textFieldUnits}
              </InputAdornment>
            ),
          }}
          {...textFieldProps}
          helperText={isErrored && textFieldProps?.helperText}
        />
      </Grid>
      <Grid
        item
        width="100%"
        py={1}
      >
        <Slider
          disabled={disabled}
          value={!isNaN(fieldNum) ? fieldNum : 0}
          defaultValue={0}
          onChange={(_e, newVal) => {
            const newValue = Array.isArray(newVal) ? newVal[0] : newVal;
            onChange(newValue.toString());
          }}
          valueLabelDisplay="auto"
          step={0.01}
          color={
            hasError || invalidFieldVal || (textErrState && textErrState(fieldNum))
              ? 'secondary'
              : 'primary'
          }
          size="small"
          tabIndex={-1}
          {...sliderProps} // needs to be last, or it won't override defaults here
        />
      </Grid>
    </Grid>
  );
};
