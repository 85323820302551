import { useCallback, useMemo, useState } from 'react';
import MarkerManipulator, { MarkerMoveProps } from 'src/threejs/components/MarkerManipulator';
import { UpdateSpriteProps } from 'src/threejs/components/MarkerManipulator/MarkerManipulator';
import {
  createGetOrbitCOE,
  createGetOrbitData,
} from 'src/threejs/components/OrbitManager/store/getters';
import useCamera from 'src/threejs/hooks/useCamera';
import { COE } from 'src/threejs/models/COE';
import ManipulatorPlaneModel from 'src/threejs/models/ManipulatorPlane';
import { MarkerHandleProps } from 'src/types';
import { positiveDegAngle } from 'src/utilities/MathUtils';
import { AdditiveBlending, Group, Mesh, Sprite, Vector3 } from 'three';
import { use3DOrbitContext } from '../../context';

const config = {
  taHandleRotationSpeed: 5,
};

const TAMarkerHandle = ({ handle, setHandle }: MarkerHandleProps) => {
  const { id } = use3DOrbitContext();
  const camera = useCamera();

  const tempVector = useMemo(() => new Vector3(0, 0, 0), []);

  const getOrbitCOE = useMemo(() => createGetOrbitCOE(id), [id]);
  const getOrbitData = useMemo(() => createGetOrbitData(id), [id]);

  const textSpriteProps = useMemo(
    () => ({
      fontsize: 40,
      borderColor: { r: 255, g: 255, b: 255, a: 1.0 },
      backgroundColor: { r: 255, g: 255, b: 255, a: 0.1 },
      textColor: { r: 128, g: 0, b: 255, a: 0.75 },
      borderThickness: 0,
      sizeAttenuation: false,
    }),
    [],
  );

  const [group, setGroup] = useState<Group | null>(null);
  const [bounds, setBounds] = useState<Mesh | null>(null);
  const [sprite, setSprite] = useState<Sprite | null>(null);
  const [plane, setPlane] = useState<ManipulatorPlaneModel | null>(null);

  const makeSpriteName = (coe: COE) => `ν: ${coe.trueAnomaly.toFixed(3)}`;

  const move = useCallback(
    ({ pointOnPlaneOffset, tempVector, tempVector2 }: MarkerMoveProps) => {
      const orbitData = getOrbitData();
      const coe = getOrbitCOE();

      tempVector.copy(orbitData.orbitalPlaneVector).normalize();

      // Instead of getting the tangent vector from center of the ellipse
      // make it a tangent of ellipse to get better projection
      tempVector2
        .copy(orbitData.trueAnomalyPosition)
        .sub(orbitData.ellipseCenterPosition)
        .normalize();

      tempVector.cross(tempVector2).normalize();

      // project along tangent
      tempVector2.copy(pointOnPlaneOffset).projectOnVector(tempVector);

      let rotationangle = tempVector2.length() * config.taHandleRotationSpeed;

      rotationangle *= tempVector2.dot(tempVector) >= 0 ? 1 : -1;

      const trueAnomaly = positiveDegAngle(coe.trueAnomaly + rotationangle);

      return { trueAnomaly };
    },
    [getOrbitCOE, getOrbitData],
  );

  const updateBounds = () => {
    if (bounds) {
      const orbitData = getOrbitData();

      // update bounds position
      bounds.position.copy(orbitData.trueAnomalyPosition);
    }
  };

  const updateSprite = ({ tempVector2 }: UpdateSpriteProps) => {
    if (sprite && handle) {
      const orbitData = getOrbitData();

      tempVector
        .copy(orbitData.trueAnomalyPosition)
        .sub(orbitData.ellipseCenterPosition)
        .normalize()
        .multiplyScalar(0.5);

      sprite.position.copy(handle.position).add(tempVector2).add(tempVector);
    }
  };

  const updateTransform = () => {
    if (handle && plane) {
      const orbitData = getOrbitData();

      // update handle position
      handle.position.copy(orbitData.trueAnomalyPosition);

      // rotate the plane for the active handle
      plane.updateTransform(camera, handle);
    }
  };

  return (
    <MarkerManipulator
      move={move}
      textSpriteMessage="ta: "
      textSpriteProps={textSpriteProps}
      makeSpriteName={makeSpriteName}
      markerName="TA"
      setHandle={setHandle}
      handle={handle}
      setGroup={setGroup}
      setBounds={setBounds}
      setSprite={setSprite}
      setPlane={setPlane}
      plane={plane}
      group={group}
      scale={0.25}
      updateBounds={updateBounds}
      updateSprite={updateSprite}
      updateTransform={updateTransform}
      manipulatorPlaneRotation
    >
      <sphereGeometry args={[0.1, 16, 12]} />
      <meshBasicMaterial
        color={0x8000ff}
        depthTest={false}
        depthWrite={false}
        transparent={true}
        opacity={0.5}
        toneMapped={false}
        blending={AdditiveBlending}
      />
    </MarkerManipulator>
  );
};

export default TAMarkerHandle;
