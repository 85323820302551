import { WorkspacesRounded } from '@mui/icons-material';
import { Button, Grid, Tab, Tabs, Tooltip } from '@mui/material';
import { useCallback, useState } from 'react';
import { PanelHeader } from 'src/components/PanelHeader';
import { ScrollableContainer } from 'src/components/ScrollableContainer';
import { SpaceGlassPanel } from 'src/components/SpaceGlassPanel';
import { useCreateGroupMutation, useCurrentGroups } from 'src/hooks/GroupHooks';
import { useCurrentPage } from 'src/hooks/PageHooks';
import { useIsReadOnly } from 'src/hooks/SharedNotebookHooks';
import { SETTINGS_NAMES, useIsFeatureEnabled } from 'src/pages/Settings/store';
import { LaunchEventsListPanel } from '../Launch/LaunchEventsListPanel';
import { AddButton } from '../ListPanel/AddButton';
import { DeleteObjectButton } from '../ListPanel/DeleteObjectButton';
import { ObjectListPanel } from '../ObjectListPanel';
import { SlideOutPanelEvents } from './SlideOutPanelEvents';
import { SlideOutPanelObject } from './SlideOutPanelObject';

interface TabPanelProps {
  children?: React.ReactNode;
  currentTabState: TabState;
  tabValue: TabState;
}

enum TabState {
  Objects = 0,
  Events = 1,
}

function TabPanel(props: TabPanelProps) {
  const { children, currentTabState, tabValue } = props;

  return (
    <div
      hidden={currentTabState !== tabValue}
      style={{
        height: '100%',
        overflow: 'auto',
      }}
    >
      {children}
    </div>
  );
}

export const ObjectsPanel = () => {
  const [currentTabState, setValue] = useState(TabState.Objects);

  const handleTabChange = (_event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
    setAddPanelOpen(false);
  };

  const isReadOnly = useIsReadOnly();

  const [addPanelOpen, setAddPanelOpen] = useState(false);
  const toggleAddPanelOpen = useCallback(() => {
    setAddPanelOpen(!addPanelOpen);
  }, [addPanelOpen]);

  const createGroupMutation = useCreateGroupMutation();
  const currentPageGroups = useCurrentGroups();

  const [groupCounter, setGroupCounter] = useState(
    currentPageGroups?.length ? currentPageGroups.length : 0,
  );
  const currentPage = useCurrentPage();

  /**
   * Creates a new group
   */
  const onCreateNewGroup = useCallback(async () => {
    if (currentPage) {
      let newGroupNumber = groupCounter + 1;

      if (currentPageGroups && currentPageGroups.length > groupCounter) {
        newGroupNumber = currentPageGroups.length + 1;
      }

      setGroupCounter(newGroupNumber);

      return createGroupMutation.mutateAsync({
        pageId: currentPage.id,
        name: `Group ${newGroupNumber}`,
        notes: 'Notes',
        additionalProperties: null,
      });
    }
  }, [currentPage, groupCounter, createGroupMutation, currentPageGroups]);

  const isFeatureLaunchEnabled = useIsFeatureEnabled(SETTINGS_NAMES.FEATURE_LAUNCH_ENABLED);

  const title = isFeatureLaunchEnabled ? '' : 'Objects';

  const classNameIgnoreClickOutsideSlideOutPanel = 'ObjectAddSlideOutPanel';

  return (
    <>
      <SpaceGlassPanel
        header={!isFeatureLaunchEnabled && <PanelHeader title={title} />}
        footer={
          !isReadOnly && (
            <Grid
              display="grid"
              gridAutoFlow="column"
              gap={1.5}
              justifyContent="end"
            >
              {currentTabState === TabState.Objects && (
                <>
                  <DeleteObjectButton />

                  <Tooltip
                    title="Create a new group"
                    placement="top"
                    disableInteractive
                  >
                    <Button
                      color="primary"
                      variant="rounded"
                      onClick={onCreateNewGroup}
                    >
                      <WorkspacesRounded fontSize="small" />
                    </Button>
                  </Tooltip>
                </>
              )}

              <Tooltip
                title="See available actions for this tab"
                placement="top"
                disableInteractive
              >
                <div>
                  <AddButton
                    className={classNameIgnoreClickOutsideSlideOutPanel}
                    active={addPanelOpen}
                    onClick={toggleAddPanelOpen}
                  />
                </div>
              </Tooltip>
            </Grid>
          )
        }
      >
        {isFeatureLaunchEnabled && (
          <Grid
            display="grid"
            gridTemplateRows="min-content 1fr"
            height="100%"
          >
            <Tabs
              className="handle"
              variant="fullWidth"
              value={currentTabState}
              onChange={handleTabChange}
            >
              <Tab label="Objects" />
              <Tab label="Events" />
            </Tabs>
            <TabPanel
              currentTabState={currentTabState}
              tabValue={TabState.Objects}
            >
              <ObjectListPanel onCreateNewGroup={onCreateNewGroup} />
            </TabPanel>
            <TabPanel
              currentTabState={currentTabState}
              tabValue={TabState.Events}
            >
              <LaunchEventsListPanel />
            </TabPanel>
          </Grid>
        )}

        {!isFeatureLaunchEnabled && (
          <ScrollableContainer>
            <ObjectListPanel onCreateNewGroup={onCreateNewGroup} />
          </ScrollableContainer>
        )}
      </SpaceGlassPanel>

      <SlideOutPanelObject
        addPanelOpen={addPanelOpen && currentTabState === TabState.Objects}
        onClickOutside={() => setAddPanelOpen(false)}
        ignoreClassName={classNameIgnoreClickOutsideSlideOutPanel}
      />

      <SlideOutPanelEvents
        addPanelOpen={addPanelOpen && currentTabState === TabState.Events}
        onClickOutside={() => setAddPanelOpen(false)}
        ignoreClassName={classNameIgnoreClickOutsideSlideOutPanel}
      />
    </>
  );
};
