import { AutoStoriesRounded } from '@mui/icons-material';
import { Menu, MenuItem } from '@mui/material';
import { grey } from '@mui/material/colors';
import { useState } from 'react';
import { MenuBarIconButton, StyledLink } from './MenuBar.styled';

export const MenuBarUserGuides = () => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = !!anchorEl;

  const handleOpen = (event: React.MouseEvent<HTMLButtonElement | HTMLDivElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <MenuBarIconButton onClick={handleOpen}>
        <AutoStoriesRounded sx={{ color: grey[700] }} />
      </MenuBarIconButton>

      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={open}
        onClose={handleClose}
      >
        <MenuItem>
          <StyledLink
            href="/docs/userGuide"
            target="_blank"
            rel="noopener noreferrer"
          >
            User Guide
          </StyledLink>
        </MenuItem>

        <MenuItem>
          <StyledLink
            href="/docs/videosAstrodynamics"
            target="_blank"
            rel="noopener noreferrer"
          >
            Astrodynamics Video Tutorials
          </StyledLink>
        </MenuItem>

        <MenuItem>
          <StyledLink
            href="/docs/videosUI"
            target="_blank"
            rel="noopener noreferrer"
          >
            UI Video Tutorials
          </StyledLink>
        </MenuItem>
      </Menu>
    </>
  );
};
