import { DeleteRounded, PriorityHighRounded } from '@mui/icons-material';
import { Button, Dialog, DialogActions, DialogContent, Typography } from '@mui/material';
import { partial } from 'lodash';
import React, { useCallback } from 'react';
import { SpaceGlassContainerBase } from 'src/components/SpaceGlassControl';
import { useCurrentOrbits } from 'src/hooks/OrbitHooks';
import { useCurrentPage } from 'src/hooks/PageHooks';
import { ModalHeader } from 'src/pages/Shared/ModalSpaceGlass';
import { GroupObject } from 'src/types';

interface IUngroupModalProps {
  open: boolean;
  show: (show: boolean) => void;
  group: GroupObject;
  ungroupMutation: () => void;
}

const UngroupOrbitModal: React.ComponentType<IUngroupModalProps> = ({
  open,
  show,
  group,
  ungroupMutation,
}) => {
  const currentPage = useCurrentPage();
  const currentPageOrbits = useCurrentOrbits();

  const hide = partial(show, false);
  const ungroupErr = useCallback(
    (errPrompt: string) => (
      <Dialog
        fullWidth
        maxWidth="sm"
        open={open}
        onClose={hide}
        PaperComponent={SpaceGlassContainerBase}
      >
        <ModalHeader
          onClose={hide}
          title={`Cannot ungroup from ${group.name}.`}
          icon={<PriorityHighRounded />}
        />
        <DialogContent>
          <Typography variant="body1">{errPrompt}</Typography>
        </DialogContent>
        <DialogActions>
          <Button
            type="button"
            onClick={hide}
          >
            CANCEL
          </Button>
        </DialogActions>
      </Dialog>
    ),
    [hide, open, group.name],
  );

  if (!currentPage) {
    return null;
  }

  const ungroupItem = () => {
    if (currentPage && group) {
      hide();
      ungroupMutation();
    }
  };

  if (
    currentPageOrbits &&
    currentPageOrbits?.filter((orbit) => orbit.groupId === group.id).length < 1
  ) {
    return ungroupErr('The group must have at least one object.');
  }

  return (
    <Dialog
      fullWidth
      maxWidth="sm"
      open={open}
      onClose={hide}
      PaperComponent={SpaceGlassContainerBase}
    >
      <ModalHeader
        title="Are You Sure?"
        onClose={hide}
        icon={<DeleteRounded />}
      />
      <DialogContent>
        <Typography variant="body1">
          This will ungroup orbits{' '}
          {currentPageOrbits
            ?.filter((orbit) => orbit.groupId === group.id)
            ?.map((o) => o.name)
            .join(', ')
            .trim()}{' '}
          from the group {group.name}
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button
          type="button"
          onClick={hide}
        >
          CANCEL
        </Button>
        <Button
          color="secondary"
          variant="contained"
          onClick={ungroupItem}
          aria-label="Delete Item"
        >
          Ungroup
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default UngroupOrbitModal;
