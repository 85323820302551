import { ExpandMoreRounded, ReplayRounded } from '@mui/icons-material';
import { Accordion, AccordionDetails, AccordionSummary, Button, Grid } from '@mui/material';
import { DEFAULT_AXES_PROPERTIES } from 'src/constants';
import { useSettingsStore } from 'src/pages/Settings/store';
import { ColorSelector } from './ColorSelector';

export const AxesColors = () => {
  const setAxesSetting = useSettingsStore((state) => state.setAxesSetting);

  const axesSettings = useSettingsStore((state) => state.axesSettings);

  const reset = () => {
    setAxesSetting('X', DEFAULT_AXES_PROPERTIES.X);
    setAxesSetting('Y', DEFAULT_AXES_PROPERTIES.Y);
    setAxesSetting('Z', DEFAULT_AXES_PROPERTIES.Z);
  };

  return (
    <Accordion defaultExpanded>
      <AccordionSummary
        style={{
          flexDirection: 'row-reverse',
        }}
        expandIcon={<ExpandMoreRounded />}
        aria-controls="inspector-panel-visual"
        id="inspector-panel-visual"
      >
        Axes/Gizmo color customization
      </AccordionSummary>

      <AccordionDetails>
        <Grid
          container
          gap={2}
          px={2}
        >
          {['X', 'Y', 'Z'].map((key) => {
            return (
              <Grid
                container
                key={key}
              >
                <Grid item>{`${key}:`}</Grid>
                <Grid item>
                  <ColorSelector
                    currentColor={axesSettings[key].color}
                    presets={[{ hex: DEFAULT_AXES_PROPERTIES[key].color }]}
                    setColor={(color: string) => {
                      return setAxesSetting(key, { color: color });
                    }}
                  />
                </Grid>
              </Grid>
            );
          })}

          <Grid container>
            <Button
              variant="contained"
              size="small"
              onClick={reset}
              startIcon={<ReplayRounded />}
            >
              Reset
            </Button>
          </Grid>
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
};
