import { createContext, FunctionComponent, useContext, useMemo } from 'react';

type ViewportCanvasProviderProps = {
  children?: React.ReactNode;
  canvas: HTMLCanvasElement;
};

const ViewportCanvasProviderContext = createContext<ViewportCanvasProviderProps | undefined>(
  undefined,
);

const ViewportCanvasProvider: FunctionComponent<ViewportCanvasProviderProps> = ({
  children,
  canvas,
}) => {
  const value = useMemo(() => ({ canvas }), [canvas]);

  return (
    <ViewportCanvasProviderContext.Provider value={value}>
      {children}
    </ViewportCanvasProviderContext.Provider>
  );
};

export function useViewportCanvas() {
  const context = useContext(ViewportCanvasProviderContext);

  if (!context) {
    throw new Error('`useViewportCanvas` must be used inside of ViewportCanvasProvider');
  }

  return context;
}

export default ViewportCanvasProvider;
