import { grey } from '@mui/material/colors';
import { Text } from '@react-three/drei';
import { ThreeEvent, useFrame } from '@react-three/fiber';
import gsap, { Sine } from 'gsap';
import { useEffect, useRef, useState } from 'react';
import {
  LAUNCH_FLYOUT_STATUS,
  TIME_TWEEN_CAMERA_MOVEMENT,
  VISUAL_AID_HEX,
  scaleFactor,
} from 'src/constants';
import { FONTS } from 'src/constants-fonts';
import { useCurrentGroundObjects } from 'src/hooks/GroundObjectHooks';
import { useCurrentGroups } from 'src/hooks/GroupHooks';
import { useIsPropagating } from 'src/hooks/OrbitHooks';
import { useCurrentPage } from 'src/hooks/PageHooks';
import theme from 'src/pages/App/Theme';
import { router } from 'src/pages/App/routes/Router';
import {
  getActiveCapabilityId,
  getActiveGroundObjectId,
  getActiveNotebookId,
  getActivePageId,
  useRouteStore,
} from 'src/pages/App/routes/store';
import { GroundObject, GroundObjectCategory } from 'src/types';
import { shortenStringNextSpace } from 'src/utilities/StringUtils';
import { Group, Vector3 } from 'three';
import { ArrowVector } from '../ArrowVector';
import { useViewport, useViewportReferenceFrame } from '../ViewportManager/store';
import { GroundObjectCone } from './GroundObjectCone';
import { GroundObjectDish } from './GroundObjectDish';
import { GroundObjectLaunch } from './GroundObjectLaunch';
import {
  GroundObjectEditType,
  useGroundObjectLaunchEditStore,
} from './GroundObjectLaunchEditStore';
import { GroundObjectLocation } from './GroundObjectLocation';
import { OrbitLoSArrows } from './OrbitLoSArrows';

interface GroundObjectProps {
  groundObject: GroundObject;
}

export const GroundObjectItem = ({ groundObject }: GroundObjectProps) => {
  const activeGroundObjectId = useRouteStore(getActiveGroundObjectId);
  const apiGroundObject = useCurrentGroundObjects()?.find((obj) => obj.id === groundObject.id);

  const capabilityId = useRouteStore(getActiveCapabilityId);
  const notebookId = useRouteStore(getActiveNotebookId);
  const pageId = useRouteStore(getActivePageId);

  const currentPage = useCurrentPage();
  const currentPageGroups = useCurrentGroups();

  let isVisible = true;
  let isLabelVisible = true;
  const isConeVisible =
    groundObject.additionalProperties &&
    (groundObject.additionalProperties.visGroundObjConeShader ||
      groundObject.additionalProperties.visGroundObjConeWireframe);

  if (
    !groundObject.additionalProperties?.visLabel ||
    !currentPage?.additionalProperties?.visOrbitLabels
  ) {
    isLabelVisible = false;
  }
  if (
    !groundObject.additionalProperties?.visGroundObj ||
    (groundObject.groupId &&
      !currentPageGroups?.find((group) => group.id === groundObject.groupId)?.additionalProperties
        ?.visOrbits)
  ) {
    isVisible = false;
    isLabelVisible = false;
  }

  const { controls } = useViewport();
  const { isRIC, isECI, isECEF } = useViewportReferenceFrame();

  const [groupRef, setGroupRef] = useState<Group | null>(null);

  useEffect(() => {
    if (groupRef !== null) {
      const { setGroundObjectItemRefs, groundObjectItemRefs } =
        useGroundObjectLaunchEditStore.getState();
      setGroundObjectItemRefs({ ...groundObjectItemRefs, [groundObject.id]: groupRef });
    }
  }, [groundObject.id, groupRef]);

  const [zoomLevel, setZoomLevel] = useState(0);

  // for tracking when to pan the camera for actively edited ground object
  const groupRefWorldPosition = useRef<Vector3>(new Vector3(0, 0, 0));
  const isPropgating = useIsPropagating();

  const moveCameraControlsToGroundObject = () => {
    if (activeGroundObjectId !== groundObject.id || isPropgating) {
      return;
    }

    if (!isRIC && controls && groupRef) {
      const cameraPosition = new Vector3();
      if (isECI) {
        cameraPosition.copy(groupRef.getWorldPosition(new Vector3()));
      }
      if (isECEF) {
        cameraPosition.copy(groupRef.position);
      }

      // retain the same zoom level the user currently has set
      cameraPosition.setLength(controls.object.position.length());

      gsap.to(controls.object.position, {
        ...cameraPosition,
        ease: Sine.easeInOut,
        duration: TIME_TWEEN_CAMERA_MOVEMENT,
      });
    }
  };

  useFrame(() => {
    const state = useGroundObjectLaunchEditStore.getState();
    let groundObjectEdit = state.groundObjectEdit;

    // use the ground object from react-query instead of the store if not the active ground object
    if (
      state.isLaunchEditMode !== LAUNCH_FLYOUT_STATUS.CLOSED ||
      groundObjectEdit?.id !== groundObject.id
    ) {
      groundObjectEdit = apiGroundObject as GroundObjectEditType;
    }

    if (!groundObjectEdit) return;
    if (!groupRef) return;

    const { latitude, longitude, altitude } = groundObjectEdit;
    const editPos = latLongToVector3(
      latitude,
      longitude,
      1, // 1 represents earth radius, i.e. sea level
      altitude * scaleFactor,
    );
    if (groupRef) {
      groupRef.position.set(editPos.x, editPos.y, editPos.z);
    }

    const rotationX = (latitude / 90) * Math.PI * 0.5;
    const rotationY = (longitude / 360) * Math.PI * 2;

    groupRef.rotation.set(0, 0, 0);
    groupRef.rotateOnWorldAxis(new Vector3(1, 0, 0), -rotationX);
    groupRef.rotateOnWorldAxis(new Vector3(0, 1, 0), rotationY);

    // control when the camera should pan for an actively edited ground object,
    // when first editing or when changing lat,lon,alt, or sensor range
    const currentObjPosition = new Vector3();
    groupRef.getWorldPosition(currentObjPosition);
    if (
      !currentObjPosition
        .clone()
        .normalize()
        .equals(groupRefWorldPosition.current.clone().normalize())
    ) {
      moveCameraControlsToGroundObject();
    }
    groupRefWorldPosition.current = currentObjPosition;
  });

  useFrame(() => {
    setZoomLevel(controls.target.distanceTo(controls.object.position));
  });

  const handleDoubleClick = (event: ThreeEvent<MouseEvent>) => {
    event.stopPropagation();
    const newURL = `/notebook/${notebookId}/${pageId}/ground-object/${groundObject.id}`;

    if (capabilityId) {
      router.navigate(`/shared/${capabilityId}${newURL}}`);
    } else {
      router.navigate(newURL);
    }
  };

  return (
    <>
      <group
        visible={isVisible}
        ref={setGroupRef}
        onDoubleClick={handleDoubleClick}
      >
        {groupRef && (
          <OrbitLoSArrows
            groundObjectGroup={groupRef}
            groundObject={groundObject}
          />
        )}

        {groundObject.category === GroundObjectCategory.LAUNCH_PAD && (
          <GroundObjectLaunch scale={isRIC ? 2.0 : Math.min(zoomLevel, 3)} />
        )}

        {(groundObject.category === GroundObjectCategory.OTHER ||
          groundObject.category === GroundObjectCategory.SENSOR) && (
          <>
            <GroundObjectDish scale={isRIC ? 2.0 : Math.min(zoomLevel, 3)} />

            <group scale={0.125}>
              <ArrowVector
                color={VISUAL_AID_HEX}
                staticDirection={new Vector3(1, 0, 0)}
                staticLength={2}
              />
            </group>
          </>
        )}

        {isConeVisible &&
          (groundObject.category === GroundObjectCategory.OTHER ||
            groundObject.category === GroundObjectCategory.SENSOR) && (
            <GroundObjectCone groundObject={groundObject} />
          )}

        {groundObject.category === GroundObjectCategory.LOCATION && (
          <>
            <GroundObjectLocation
              scale={isRIC ? 2.0 : Math.min(zoomLevel, 3)}
              locationObject={groundObject}
            />
          </>
        )}

        <Text
          visible={isLabelVisible}
          anchorX={-0.04}
          fontSize={isRIC ? 0.025 : Math.min(zoomLevel * 0.0125, 0.06)}
          font={FONTS.StaticMontserratBold}
          outlineBlur={0.005}
          outlineColor={grey[900]}
          outlineWidth={0.00001}
          color={activeGroundObjectId === groundObject.id ? theme.palette.text.primary : grey[400]}
          maxWidth={300}
          whiteSpace="overflowWrap"
          overflowWrap="break-word"
          renderOrder={1}
        >
          {shortenStringNextSpace(groundObject.name)}
        </Text>
      </group>
    </>
  );
};

const latLongToVector3 = (latitude = 0, longitude = 0, radius = 1, height = 0) => {
  const phi = (latitude * Math.PI) / 180;
  const theta = (longitude * Math.PI) / 180;

  const x = (radius + height) * Math.cos(phi) * Math.sin(theta);
  const z = (radius + height) * Math.cos(phi) * Math.cos(theta);
  const y = (radius + height) * Math.sin(phi);

  return new Vector3(x, y, z);
};
