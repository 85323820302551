import { getActiveCapabilityId, useRouteStore } from 'src/pages/App/routes/store';

export function useIsReadOnly() {
  const capabilityId = useRouteStore(getActiveCapabilityId);
  return Boolean(capabilityId);
}

export function useCapabilityId(): number | null {
  const capabilityId = useRouteStore(getActiveCapabilityId);

  if (!capabilityId) {
    return null;
  }
  return +capabilityId;
}
