import { PerspectiveCamera } from '@react-three/drei';
import { useFrame } from '@react-three/fiber';
import { memo, useCallback, useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react';
import { ObjectLabel } from 'src/components/ObjectLabel';
import {
  DEFAULT_ADDITIONAL_PROPERTIES_PAGE,
  ORBIT_COLOR_DEFAULT,
  ORBIT_TYPES,
  PROPAGATION_STEPS_PER_REVOLUTION,
  SPACE_OBJECT_COLOR_DEFAULT,
  SPACE_OBJECT_SIZE_DEFAULT,
} from 'src/constants';
import { FONTS } from 'src/constants-fonts';
import { OrbitRenderOrder } from 'src/enums';
import { useCurrentGroups } from 'src/hooks/GroupHooks';
import { useCurrentOrbits, useIsPropagating } from 'src/hooks/OrbitHooks';
import { useCurrentPage } from 'src/hooks/PageHooks';
import { useIsReadOnly } from 'src/hooks/SharedNotebookHooks';
import theme from 'src/pages/App/Theme';
import {
  SpaceObjectScales,
  SpaceObjectShapes,
} from 'src/pages/Notebook/components/SpaceObjectProperties';
import Marker from 'src/threejs/components/Marker/Marker';
import useViewportStore, {
  useViewport,
  useViewportId,
  useViewportReferenceFrame,
} from 'src/threejs/components/ViewportManager/store';
import useShowOrbitPath from 'src/threejs/hooks/useShowOrbitPath';
import getTrueAnomalyPosition from 'src/threejs/math/getTrueAnomalyPosition';
import { COE } from 'src/threejs/models/COE';
import { GroupObject, OrbitObject, ReferenceFrame, SpaceObjectShape, SpaceSensor } from 'src/types';
import {
  BufferGeometry,
  DynamicDrawUsage,
  Euler,
  Float32BufferAttribute,
  Group,
  Line,
  Matrix4,
  Mesh,
} from 'three';
import {
  createGetOrbitCOE,
  createGetOrbitData,
  createGetOrbitVertices,
} from '../OrbitManager/store/getters';
import { useDisplayedCallouts, useMakeKeplerianOrbit } from '../OrbitManager/store/hooks';
import use3DOrbitStore from '../OrbitManager/store/store';
import OrbitPath from '../OrbitPath';
import { Callouts } from '../OrbitPath/components/Callouts';
import InvisibleOrbit from './components/InvisibleOrbit';
import SelectedOrbitPlane from './components/SelectedOrbitPlane';
import { use3DOrbitContext } from './context';
import { MarkerPrioritizer } from './MarkerPrioritizer';
import { RICReferenceGrid } from './RICReferenceGrid';
import { VectorVisualAids } from './VectorVisualAids';
import { SpaceSensorCone } from '../SpaceSensors/SpaceSensorCone';
import { OrbitPathRic } from '../OrbitManager/RIC/OrbitPathRic';

type OrbitProps = {
  orbit: OrbitObject;
  numberOfPoints?: number;
  index: number;
  size?: number;
  transparent?: boolean;
  opacity?: number;
};

const Orbit = ({
  orbit,
  numberOfPoints = PROPAGATION_STEPS_PER_REVOLUTION,
  size = 1,
  opacity = 0.8,
  transparent = false,
}: OrbitProps) => {
  const currentPage = useCurrentPage();
  const currentPageGroups = useCurrentGroups();

  const currentOrbits = useCurrentOrbits();

  // make a dictionary for easy access of orbit's group object if orbit has a group
  const groupObjectOrbitMappings = useMemo(
    () =>
      currentOrbits
        ? currentOrbits
            .filter((o) => o.groupId !== null)
            .reduce((acc, curr) => {
              const orbitGroup = currentPageGroups?.find((g) => g.id === curr.groupId);
              if (orbitGroup) acc[curr.id] = orbitGroup;
              return acc;
            }, {} as Record<OrbitObject['id'], GroupObject>)
        : {},
    [currentOrbits, currentPageGroups],
  );

  const currentPageAdditionalProperties =
    currentPage?.additionalProperties || DEFAULT_ADDITIONAL_PROPERTIES_PAGE;

  const pageOrbitGroupVisible =
    !groupObjectOrbitMappings[orbit.id] ||
    !!groupObjectOrbitMappings[orbit.id]?.additionalProperties?.visOrbits;

  const getOrbitVisibility = useCallback(
    (orbitId: number) => {
      const orbit = currentOrbits?.find((orbit) => orbit.id === orbitId);
      if (orbit) {
        if (orbit.additionalProperties?.visOrbit) {
          if (orbit.groupId) {
            const group = currentPageGroups?.find((group) => group.id === orbit.groupId);
            if (group?.additionalProperties?.visOrbits) {
              return true;
            }
          } else {
            return true;
          }
        }
      }
      return false;
    },
    [currentOrbits, currentPageGroups],
  );

  const viewportId = useViewportId();
  const { cameraECEF } = useViewportStore.getState().viewports[viewportId];
  const { selected, id } = use3DOrbitContext();
  const visible = pageOrbitGroupVisible && !!orbit.additionalProperties?.visOrbit;
  const textVisible = !!orbit.additionalProperties?.visLabel;

  const [geometry, setGeometry] = useState<BufferGeometry | null>();
  const makeKeplerianOrbit = useMakeKeplerianOrbit();
  const getOrbitVertices = useMemo(() => createGetOrbitVertices(orbit.id), [orbit.id]);
  const { targetId } = useViewport();
  const { isECEF, isRIC, isECI } = useViewportReferenceFrame();
  const isReadOnly = useIsReadOnly();
  const showOrbitPath = useShowOrbitPath(id);

  const [orbitObjText, setOrbitObjText] = useState<Group | null>(null);
  const [taMotionGroup, setTaMotionGroup] = useState<Group | null>(null);
  const [taMotionMarkerMesh, setTaMotionMarkerMesh] = useState<Mesh | null>(null);

  const getOrbitData = useMemo(() => createGetOrbitData(id), [id]);
  const getOrbitCOE = useMemo(() => createGetOrbitCOE(id), [id]);

  useEffect(() => {
    makeKeplerianOrbit(numberOfPoints);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const hasConditionEscapeVelocity = use3DOrbitStore(
    (state) => state.orbits[orbit.id].orbitData.hasConditionEscapeVelocity,
  );

  const activeStateVector = use3DOrbitStore((state) => state.orbits[orbit.id].activeStateVector);

  // Keeps the RIC frame position and orientation in sync with the orbit's COEs
  // when not propagating. When propagating, this is handled by OrbitPath.
  useEffect(() => {
    function setRicOrientationFromTrueAnomaly(coe: COE) {
      if (!showOrbitPath && taMotionGroup && orbitObjText) {
        const { x, y, z } = getTrueAnomalyPosition(coe.toRad());
        taMotionGroup.position.set(x, y, z);

        const { orbitalPlaneVector } = getOrbitData();
        const rHat = taMotionGroup.position.clone().normalize();
        const cHat = orbitalPlaneVector.clone().normalize();
        const iHat = cHat.clone();
        iHat.cross(rHat).normalize();
        const ricMatrix = new Matrix4();
        ricMatrix.makeBasis(iHat, cHat, rHat);
        const euler = new Euler();
        euler.setFromRotationMatrix(ricMatrix);
        taMotionGroup.setRotationFromEuler(euler);

        orbitObjText.position.copy(taMotionGroup.position);
      }
    }

    // Initially execute this effect. Also execute it when any of its deps
    // change. (showOrbitPath is the only one that can change over the lifecycle
    // of the component, the other ones are static and are included in deps just
    // for correctness and to satisfy the linter).
    setRicOrientationFromTrueAnomaly(getOrbitCOE());

    // Also subscribe to the coe and run this effect (outside the react render
    // cycle) when they change.
    return use3DOrbitStore.subscribe(
      (state) => state.orbits[id].coe,
      setRicOrientationFromTrueAnomaly,
    );
  }, [orbitObjText, getOrbitCOE, getOrbitData, id, showOrbitPath, taMotionGroup]);

  const trackLength3D = orbit?.additionalProperties?.trackLength3D;

  const params = useMemo(
    () => ({
      radius: {
        selected: trackLength3D && trackLength3D > 0 ? 0.015 : 0.005,
        hidden: 0.07,
      },
      scale: 1,
      extrusionSegments: numberOfPoints,
      radiusSegments: 6,
      closed: true,
    }),
    [numberOfPoints, trackLength3D],
  );

  const updateBoundingCalc = useCallback(() => {
    geometry?.computeBoundingSphere();
  }, [geometry]);

  const orbitRef = useRef<Line>(null);
  useLayoutEffect(() => {
    if (orbitRef.current) {
      orbitRef.current.scale.setScalar(size);
      orbitRef.current.updateMatrix();
      updateBoundingCalc();
    }
  }, [size, updateBoundingCalc]);

  useFrame(() => {
    const vertices = getOrbitVertices();
    geometry?.setAttribute(
      'position',
      new Float32BufferAttribute(vertices, 3).setUsage(DynamicDrawUsage),
    );
    updateBoundingCalc();
  });

  const isRicTarget = isRIC && targetId === id;

  const [cameraRICLocal, setCameraRICLocal] = useState(null);
  const ricCamera = useMemo(
    () =>
      isRicTarget && (
        <PerspectiveCamera
          name="Camera RIC"
          makeDefault={isRicTarget}
          ref={setCameraRICLocal}
        />
      ),
    [isRicTarget, setCameraRICLocal],
  );

  const { cameraRIC, setCameraRIC } = useViewportStore.getState().viewports[viewportId];

  useEffect(() => {
    // cant set ref directly into zustand store so use effect to store it when initially set in local state
    if (cameraRICLocal) {
      setCameraRIC(cameraRICLocal);
    }
  }, [cameraRICLocal, setCameraRIC]);

  const pageOrbitLabelsVisible = currentPageAdditionalProperties.visOrbitLabels;

  const currentObjectColor = orbit.additionalProperties?.color || ORBIT_COLOR_DEFAULT;

  const currentSpaceObjectShape = useMemo(
    () => orbit?.additionalProperties?.spaceObjectShape || SpaceObjectShape.CUBE,
    [orbit?.additionalProperties?.spaceObjectShape],
  );
  const currentSpaceObjectColor = useMemo(
    () => orbit?.additionalProperties?.spaceObjectColor || SPACE_OBJECT_COLOR_DEFAULT,
    [orbit?.additionalProperties?.spaceObjectColor],
  );
  const currentSpaceObjectSize = useMemo(
    () => orbit?.additionalProperties?.spaceObjectSize || SPACE_OBJECT_SIZE_DEFAULT,
    [orbit?.additionalProperties?.spaceObjectSize],
  );

  const SpaceObj = SpaceObjectShapes[currentSpaceObjectShape];
  const spaceObjSize = SpaceObjectScales[currentSpaceObjectShape][currentSpaceObjectSize];

  const displayedCallouts = useDisplayedCallouts();

  const isOrbitLaunchPayload = orbit.orbitType === ORBIT_TYPES.LAUNCH;

  const isPropagating = useIsPropagating();

  // if we have propagating data but no activeStateVector, nothing to render, i.e. ephemeris
  if (isPropagating && !activeStateVector) {
    return null;
  }

  if (!cameraRIC && !cameraECEF) {
    return null;
  }

  if (!visible) return null;

  return (
    <>
      <line3D
        name={orbit.name}
        ref={orbitRef}
        matrixAutoUpdate={false}
        visible={
          visible && !isECEF && !isRIC && !hasConditionEscapeVelocity && !isOrbitLaunchPayload
        }
      >
        <bufferGeometry
          ref={setGeometry}
          name={`${orbit.name} Buffer Geometry`}
        />
        <lineBasicMaterial
          color={currentObjectColor}
          transparent
          opacity={0.6}
          linewidth={3}
          name={`${orbit.name} Material`}
        />
      </line3D>

      {!isOrbitLaunchPayload && isECI && !hasConditionEscapeVelocity && (
        <>
          <InvisibleOrbit
            transparent={transparent}
            opacity={opacity}
            tubularSegments={params.extrusionSegments}
            radius={params.radius}
            radiusSegments={params.radiusSegments}
            closed={params.closed}
            orbitColor={currentObjectColor}
          />
          <SelectedOrbitPlane
            selected={selected}
            orbitQuaternion={orbitRef.current?.quaternion}
            orbitColor={currentObjectColor}
          />
        </>
      )}

      {(isECI || isECEF) && <VectorVisualAids orbit={orbit} />}

      <ObjectLabel
        name={orbit.name}
        labelRef={orbitObjText}
        setLabelRef={setOrbitObjText}
        textProps={{
          font: FONTS.StaticMontserratBold,
          color: theme.palette.text.primary,
          fillOpacity: selected || isRicTarget ? 1 : 0.7,
          visible:
            displayedCallouts.length === 0 && pageOrbitLabelsVisible && visible && textVisible,
        }}
        groupProps={{
          renderOrder: OrbitRenderOrder.ORBIT_LABEL_TEXT,
        }}
      />

      {selected && [ORBIT_TYPES.COE, ORBIT_TYPES.STATE_VECTORS].includes(orbit.orbitType) && (
        <MarkerPrioritizer
          isECI={isECI}
          isRIC={isRIC}
          isReadOnly={isReadOnly}
          showOrbitPath={showOrbitPath}
        />
      )}

      <group ref={setTaMotionGroup}>
        {isRicTarget && (
          <group>
            {currentOrbits?.map((orbit) => {
              const isVisible = getOrbitVisibility(orbit.id);
              if (!isVisible) {
                return null;
              }
              return (
                <OrbitPathRic
                  key={orbit.id}
                  orbitIdOrigin={id}
                  orbitIdTarget={orbit.id}
                  orbitPathColor={orbit.additionalProperties?.color}
                />
              );
            })}
          </group>
        )}

        {ricCamera}

        <RICReferenceGrid isRicTarget={isRicTarget} />

        <Callouts />

        {orbit.spaceSensors.length > 0 &&
          orbit.spaceSensors.map((sensor: SpaceSensor) => {
            return sensor.enabled ? (
              <SpaceSensorCone
                key={`spaceSensor_${orbit.id}_${sensor.id}`}
                spaceSensor={sensor}
              />
            ) : null;
          })}

        <Marker
          marker={taMotionMarkerMesh}
          setMarker={setTaMotionMarkerMesh}
          markerName="TA Motion Marker (Orbit Path)"
          visible={visible}
          scaleFactor={3}
          renderOrder={OrbitRenderOrder.ORBIT_MARKER}
        >
          <SpaceObj
            isPropagating
            objScale={spaceObjSize}
            objColor={currentSpaceObjectColor}
          />
        </Marker>
      </group>

      {showOrbitPath && (
        <>
          <OrbitPath
            orbitObjText={orbitObjText}
            taMotionGroup={taMotionGroup}
            referenceFrame={ReferenceFrame.ECI}
            updateTaMotion={isECI || isRIC}
            visible={isECI}
            color={currentObjectColor}
          />
          <OrbitPath
            orbitObjText={orbitObjText}
            taMotionGroup={taMotionGroup}
            referenceFrame={ReferenceFrame.ECEF}
            updateTaMotion={isECEF}
            visible={isECEF}
            color={currentObjectColor}
          />
        </>
      )}
    </>
  );
};

export default memo(Orbit);
