import { Box, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import {
  StatusBox,
  StatusContainerFullScreen,
  StatusInnerBox,
  StatusLogo,
  StatusLogoContainer,
} from './Status.styled';

const POLL_DELAY_MS: number = 10 * 1000;
const POLL_URL = `${process.env.REACT_APP_LAB_API_URL}/actuator/health`;

export const ShowServerDown = () => {
  const [alive, setAlive] = useState(true);

  useEffect(() => {
    function fetchData() {
      fetch(POLL_URL)
        .then((response) => response.json())
        .then((data) => {
          if (data.status === 'UP') {
            setAlive(true);
          } else {
            setAlive(false);
          }
        })
        .catch((error) => {
          console.error(error);
          setAlive(false);
        });
    }

    const interval = setInterval(() => {
      fetchData();
    }, POLL_DELAY_MS);

    fetchData();
    return () => clearInterval(interval);
  }, []);

  return alive ? null : <ServerDown />;
};

export const ServerDown = () => {
  return (
    <StatusContainerFullScreen>
      <StatusBox>
        <StatusLogoContainer>
          <StatusLogo />
        </StatusLogoContainer>
        <StatusInnerBox>
          <Typography variant="h4">Oops, we&rsquo;re starry.</Typography>
          <Box
            component="div"
            display="flex"
            alignItems="center"
            width="300px"
            textAlign="center"
            flexDirection="column"
          >
            <Typography variant="h6">We&rsquo;re experiencing an internal error.</Typography>

            <Typography variant="h6">Please try again later.</Typography>
          </Box>
        </StatusInnerBox>
      </StatusBox>
    </StatusContainerFullScreen>
  );
};
