import { SvgIcon } from '@mui/material';
import { ReactComponent as SatelliteIcon } from 'src/assets/satellite.svg';
import { GroupObject, OrbitObject } from 'src/types';
import { ObjectContextMenu } from './ObjectContextMenu';
import { ObjectItemOrbit } from './ObjectItemOrbit';
import { TreeBranch, TreeBranchDrop } from './TreeDND/TreeBranch';

interface TreeBranchOrbitProps {
  type: string;
  active?: boolean;
  group?: GroupObject;
  orbitObject: OrbitObject;
  onDrop?: (onDropProps: TreeBranchDrop) => void;
  onSelect?: () => void;
}

export const TreeBranchOrbit = ({
  type,
  active,
  group,
  orbitObject,
  onDrop,
  onSelect,
}: TreeBranchOrbitProps) => {
  return (
    <ObjectContextMenu object={orbitObject}>
      <TreeBranch
        id={orbitObject.id}
        type={type}
        active={active}
        onDrop={onDrop}
        onSelect={onSelect}
        draggable
        droppable={!group}
        main={
          <ObjectItemOrbit
            group={group}
            orbit={orbitObject}
          />
        }
        icon={
          <SvgIcon
            component={SatelliteIcon}
            inheritViewBox
          />
        }
      />
    </ObjectContextMenu>
  );
};
