import { useFrame } from '@react-three/fiber';
import { MutableRefObject, useMemo, useRef, useState } from 'react';
import { use3DOrbitContext } from 'src/threejs/components/Orbit/context';
import { createGetOrbitData } from 'src/threejs/components/OrbitManager/store/getters';
import { makeTextSprite } from 'src/threejs/utils/makeTextSprite';
import {
  BufferAttribute,
  BufferGeometry,
  DynamicDrawUsage,
  Float32BufferAttribute,
  Group,
  Line,
  LineBasicMaterial,
  Sprite,
  Vector3,
} from 'three';

type AltHandleProps = {
  visible: MutableRefObject<boolean>;
};

const markerName = 'aap';

const AltHandle = ({ visible }: AltHandleProps) => {
  const { id, name } = use3DOrbitContext();

  const [sprite, setSprite] = useState<Sprite | null>(null);

  const getOrbitData = useMemo(() => createGetOrbitData(id), [id]);

  const tempVector = useMemo(() => new Vector3(), []);

  const material = useMemo(() => {
    return new LineBasicMaterial({
      color: 0xaaaaaa,
      linewidth: 5,
      transparent: true,
      opacity: 0.75,
      vertexColors: false,
    });
  }, []);

  const geometry = useMemo(() => {
    const geometry = new BufferGeometry();

    const vertices = [0, 0, 0, 0, 0, 0];
    const aapPosBufferAttr = new Float32BufferAttribute(vertices, 3).setUsage(DynamicDrawUsage);

    geometry.setAttribute('position', aapPosBufferAttr);

    return geometry;
  }, []);

  const spriteMaterial = useMemo(
    () =>
      makeTextSprite('alt: ', {
        fontsize: 40,
        borderColor: { r: 255, g: 255, b: 255, a: 1.0 },
        backgroundColor: { r: 255, g: 255, b: 255, a: 0.1 },
        textColor: { r: 255, g: 255, b: 255, a: 0.75 },
        borderThickness: 0,
        sizeAttenuation: false,
      }),
    [],
  );

  const ref = useRef<Line<BufferGeometry, LineBasicMaterial> | null>(null);

  const groupRef = useRef<Group | null>(null);

  const updateSpriteText = () => {
    if (sprite) {
      if (!sprite.material.map) return;

      const orbitData = getOrbitData();
      const context = sprite.material.map.image.getContext('2d');
      const canvas = sprite.material.map.image;

      context.clearRect(0, 0, canvas.width, canvas.height);
      context.fillText(`alt: ${orbitData.altitudeOfPerigee}`, 50, 50);

      sprite.material.map.needsUpdate = true;
    }
  };

  useFrame(() => {
    const line = ref.current;
    const group = groupRef.current;
    const orbitData = getOrbitData();

    if (group) {
      group.visible = visible.current;
    }

    if (!visible.current) return;

    if (line) {
      const attribute = line.geometry.attributes.position as BufferAttribute;
      const positionArr = attribute.array;

      if (positionArr !== null) {
        const bufferOffset = 3;
        tempVector.copy(orbitData.surfaceAtPerigeePosition);
        tempVector.toArray(positionArr, 0);
        tempVector.copy(orbitData.perigeePosition);
        tempVector.toArray(positionArr, bufferOffset);

        // tell the engine to update the actual WebGL buffer
        attribute.needsUpdate = true;

        line.geometry.computeBoundingSphere();
      }
    }

    if (sprite) {
      updateSpriteText();

      tempVector
        .sub(orbitData.surfaceAtPerigeePosition)
        .multiplyScalar(0.5)
        .add(orbitData.surfaceAtPerigeePosition);

      sprite.position.copy(tempVector);
    }
  });

  return (
    <group
      renderOrder={0}
      ref={groupRef}
    >
      <line3D
        ref={ref}
        geometry={geometry}
        material={material}
      />
      <sprite
        ref={setSprite}
        args={[spriteMaterial]}
        position={new Vector3(0, 0, 0)}
        scale={new Vector3(0.2, 0.1, 1)}
        name={`${name} ${markerName} Marker Manipulator Text Sprite`}
      />
    </group>
  );
};

export default AltHandle;
