import { Line } from '@react-three/drei';
import { useMemo } from 'react';
import { Vector3 } from 'three';
import * as topojson from 'topojson-client';
import { GeometryObject, Topology } from 'topojson-specification';
import topology from 'world-atlas/countries-50m.json';
import { getVector3FromMultiLineString } from './Graticule';

interface TopoCountriesProps {
  visible?: boolean;
}

export const TopoCountries = ({ visible = true }: TopoCountriesProps) => {
  const points = useMemo(() => {
    let pointsFinal: Vector3[] = [];

    topology.objects.countries.geometries.forEach((country) => {
      pointsFinal = pointsFinal.concat(
        getVector3FromMultiLineString(
          topojson.mesh(topology as unknown as Topology, country as GeometryObject),
        ),
      );
    });

    return pointsFinal;
  }, []);

  return (
    <Line
      rotation={[-Math.PI * 0.5, 0, -Math.PI * 0.5]}
      visible={visible}
      points={points}
      color="white"
      linewidth={0.75}
      transparent
      opacity={0.75}
      polygonOffset
      polygonOffsetFactor={-10}
      polygonOffsetUnits={-1}
      segments
    />
  );
};
