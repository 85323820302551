import { BookmarkAddRounded } from '@mui/icons-material';
import { IconButton } from '@mui/material';
import { useEffect, useState } from 'react';
import { useTimeline } from 'src/core/hooks';
import { LABModalAction, LABModalActionType } from 'src/enums';
import { useCurrentOrbit } from 'src/hooks/OrbitHooks';
import { ModalBookmark } from './ModalBookmark';

export function CreateBookmark() {
  const timelineState = useTimeline();
  const currentOrbit = useCurrentOrbit();

  const [disabled, setDisabled] = useState<boolean>(false);
  const [modalType, setModalType] = useState<LABModalActionType>(LABModalAction.NULL);

  const { isPlaying } = timelineState.timelineRange;

  // disable the add button if no current orbit selected
  useEffect(() => {
    setDisabled(!currentOrbit);
  }, [currentOrbit]);

  const handleOnClick = () => {
    setModalType(LABModalAction.CREATE);
    if (isPlaying) {
      timelineState?.updateTimelineRange({
        playState: 'stopped',
      });
    }
  };

  const onCloseModal = () => {
    setModalType(LABModalAction.NULL);
  };

  if (!currentOrbit) {
    return null;
  }

  return (
    <>
      <ModalBookmark
        action={modalType}
        onClose={onCloseModal}
      />
      <IconButton
        aria-label="add-bookmark"
        color="primary"
        onClick={handleOnClick}
        disabled={disabled}
      >
        <BookmarkAddRounded />
      </IconButton>
    </>
  );
}
