export type BinarySearchForClosestValueResult<Data> = {
  low: {
    item: Data | undefined;
    index: number;
  };
  high: {
    item: Data | undefined;
    index: number;
  };
};

/**
 * A searching algorithm for find the closest number to a target number via binary search.
 * Has a worst case time complexity of `O(log n)`.
 */
function binarySearchForClosestValue<Data>(
  sortedArray: Data[],
  targetNumber: number,
  getter: (item: Data) => number = (item: Data) => item as number,
): BinarySearchForClosestValueResult<Data> {
  let low = -1;
  let high = sortedArray.length;

  while (high - low > 1) {
    const mid = Math.round((low + high) / 2);
    const currentNumber = getter(sortedArray[mid]);

    if (currentNumber <= targetNumber) {
      low = mid;
    } else {
      high = mid;
    }
  }

  // if (low > -1 && getter(sortedArray[low]) === targetNumber)
  //   high = low;

  return {
    low: {
      item: sortedArray[low],
      index: low,
    },
    high: {
      item: sortedArray[high],
      index: high,
    },
  };
}

export default binarySearchForClosestValue;
