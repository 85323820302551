import styled from '@emotion/styled';
import { AddRounded } from '@mui/icons-material';
import { Button } from '@mui/material';
import theme from 'src/pages/App/Theme';

const AddRoundedRotatable = styled(AddRounded)`
  transition: transform ${theme.transitions.duration.standard}ms;
  &.active {
    transform: rotate(135deg);
  }
`;

interface AddButtonProps {
  active: boolean;
  onClick: () => void;
  className?: string;
}
export const AddButton = ({ active = false, onClick, className }: AddButtonProps) => {
  return (
    <Button
      className={className}
      color="primary"
      variant="rounded"
      onClick={onClick}
    >
      <AddRoundedRotatable
        className={active ? 'active' : ''}
        style={{
          pointerEvents: 'none',
        }}
      />
    </Button>
  );
};
