import { CloseRounded } from '@mui/icons-material';
import {
  Button,
  CircularProgress,
  Divider,
  FormControl,
  Grid,
  MenuItem,
  Select,
  SelectChangeEvent,
  Tooltip,
  Typography,
} from '@mui/material';
import { Dispatch, SetStateAction, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { PanelHeader } from 'src/components/PanelHeader';
import { ScrollableContainer } from 'src/components/ScrollableContainer';
import { SpaceGlassContainerBase } from 'src/components/SpaceGlassControl';
import {
  ALTITUDE_RANGE_KM_MAX,
  ALTITUDE_RANGE_KM_MIN,
  CONTENT,
  earthradius,
  LATITUDE_RANGE_DEG_MAX,
  LATITUDE_RANGE_DEG_MIN,
  LAUNCH_FLYOUT_STATUS,
  LONGITUDE_RANGE_DEG_MAX,
  LONGITUDE_RANGE_DEG_MIN,
  ORBIT_TYPES,
} from 'src/constants';
import {
  useCreateGroundObject,
  useCurrentGroundObject,
  useCurrentGroundObjects,
  useUpdateGroundObject,
} from 'src/hooks/GroundObjectHooks';
import {
  useCreateLaunchEventMutation,
  useLaunchWindowsActiveEditQuery,
} from 'src/hooks/LaunchHooks';
import { useCurrentOrbits, useUpdateOrbitMutation } from 'src/hooks/OrbitHooks';
import { useCurrentPage } from 'src/hooks/PageHooks';
import PropagatedCacheManager from 'src/models/PropagatedCacheManager';
import { useRouteStore } from 'src/pages/App/routes/store';
import theme from 'src/pages/App/Theme';
import {
  GroundObjectEditType,
  useGroundObjectLaunchEditStore,
} from 'src/threejs/components/GroundObjects/GroundObjectLaunchEditStore';
import use3DOrbitStore from 'src/threejs/components/OrbitManager/store/store';
import {
  GroundObjectCategory,
  LaunchEventRequest,
  LaunchWindow,
  LaunchWindowRequest,
} from 'src/types';
import { useManeuverStore } from '../Maneuvers/store';
import { InputNameEditor } from '../NameInputEditor';
import { ObjectPropertySlider } from '../ObjectPropertySlider';
import { GroundObjectEditableProperties, zeroValMark } from '../TabPanelGroundObjectProperties';
import { LaunchWindows } from './LaunchWindows';

const MENUITEM_CUSTOM = {
  value: 'custom',
  label: 'Custom',
};

export const LaunchEditor = () => {
  const [selectedOrbit, setSelectedOrbit] = useState(MENUITEM_CUSTOM.value);
  const [selectedGroundObject, setSelectedGroundObject] = useState(MENUITEM_CUSTOM.value);

  const currentOrbits = useCurrentOrbits();
  const currentGroundObjects = useCurrentGroundObjects();

  const handleSelectedOrbitChange = (event: SelectChangeEvent) => {
    const orbit = currentOrbits?.find((orbit) => {
      return `${orbit.id}` === `${event.target.value}`;
    });
    if (orbit) {
      const orbitData = orbit.orbit[0];
      setorbitAltitudeState((orbitData.semiMajorAxis - earthradius).toString());
      setInclinationState(orbitData.inclination.toString());
      setRaanState(orbitData.rightAscensionOfAscendingNode.toString());
      setEccentricityState(orbitData.eccentricity.toString());

      setSelectedOrbit(event.target.value);

      const { groundObjectEdit, setGroundObjectEdit } = useGroundObjectLaunchEditStore.getState();
      setGroundObjectEdit({
        ...groundObjectEdit,
        orbitLaunchAltitude: orbitData.semiMajorAxis - earthradius,
        inclination: orbitData.inclination,
        rightAscensionOfAscendingNode: orbitData.rightAscensionOfAscendingNode,
        eccentricity: orbitData.eccentricity,
      } as GroundObjectEditType);
    }
  };

  const handleSelectedGroundObjectChange = (event: SelectChangeEvent) => {
    const groundObject = currentGroundObjects?.find((groundObject) => {
      return `${groundObject.id}` === `${event.target.value}`;
    });
    if (groundObject) {
      setAltitudeState(groundObject.altitude.toString());
      setLatitudeState(groundObject.latitude.toString());
      setLongitudeState(groundObject.longitude.toString());

      setSelectedGroundObject(event.target.value);

      const { groundObjectEdit, setGroundObjectEdit } = useGroundObjectLaunchEditStore.getState();
      setGroundObjectEdit({
        ...groundObjectEdit,
        altitude: groundObject.altitude,
        latitude: groundObject.latitude,
        longitude: groundObject.longitude,
      } as GroundObjectEditType);
    }
  };

  const hasErrorLaunchLocationInvalid = useGroundObjectLaunchEditStore(
    (state) => state.hasErrorLaunchLocationInvalid,
  );

  const launchEditActiveWindows = useGroundObjectLaunchEditStore(
    (state) => state.launchEditActiveWindows,
  );
  const setLaunchEditActiveWindows = useGroundObjectLaunchEditStore(
    (state) => state.setLaunchEditActiveWindows,
  );
  const setLaunchEditMode = useGroundObjectLaunchEditStore((state) => state.setLaunchEditMode);
  const setActiveLaunchWindow = useGroundObjectLaunchEditStore(
    (state) => state.setActiveLaunchWindow,
  );

  const handleClose = useCallback(() => {
    setLaunchEditActiveWindows(null);

    setLaunchEditMode(LAUNCH_FLYOUT_STATUS.CLOSED);
  }, [setLaunchEditActiveWindows, setLaunchEditMode]);

  const hasErrorPropagationData = useManeuverStore((state) => state.hasErrorPropagationData);
  const setHasErrorPropagationData = useManeuverStore((state) => state.setHasErrorPropagationData);

  const PLACEHOLDER_NAME = 'New Launch';
  const [name, setName] = useState(PLACEHOLDER_NAME);

  const createLaunchWindows = useLaunchWindowsActiveEditQuery();

  const currentPage = useCurrentPage();
  const computeLaunch = async () => {
    if (name === '') return;
    if (!currentPage?.startTime || !currentPage?.endTime) return;

    const launchWindowRequest: LaunchWindowRequest = {
      startTimestamp: currentPage.startTime.toISOString(),
      endTimestamp: currentPage.endTime.toISOString(),
      siteLatitude: parseFloat(latitudeStateVal),
      siteLongitude: parseFloat(longitudeStateVal),
      desiredAltitude: parseFloat(orbitAltitudeStateVal),
      desiredInclination: parseFloat(inclinationStateVal),
      desiredRAAN: parseFloat(raanStateVal),
    };
    const launchWindowsResponse = await createLaunchWindows.mutateAsync(launchWindowRequest);

    setLaunchEditActiveWindows(launchWindowsResponse.launchWindows);
  };

  const checkPropagationData = useCallback(() => {
    const progress = PropagatedCacheManager.getPropagationProgress();
    setHasErrorPropagationData(progress < 100);
  }, [setHasErrorPropagationData]);

  useEffect(() => {
    checkPropagationData();
    PropagatedCacheManager.addEventListener('seeked', checkPropagationData);
    PropagatedCacheManager.addEventListener('clearpropagations', handleClose);
    return function cleanup() {
      PropagatedCacheManager.removeEventListener('seeked', checkPropagationData);
      PropagatedCacheManager.removeEventListener('clearpropagations', handleClose);
    };
  }, [checkPropagationData, handleClose]);

  const currentGroundObject = useCurrentGroundObject();

  useEffect(() => {
    // cleanup - since launch flyout overwrites ground object edit store to updates to 3d view,
    // revert back to active ground object id or set to null if there is no active ground object id
    return () => {
      const { setGroundObjectEdit } = useGroundObjectLaunchEditStore.getState();
      if (useRouteStore.getState().groundObjectId) {
        setGroundObjectEdit({
          ...currentGroundObject,
          ...currentGroundObject?.additionalProperties,
          isLaunchEditMode: LAUNCH_FLYOUT_STATUS.CLOSED,
        } as GroundObjectEditType);
      } else {
        setGroundObjectEdit(null);
      }
    };
  }, [currentGroundObject]);

  const orbitAltitudeState = useState('250');
  const inclinationState = useState('0');
  const raanState = useState('0');
  const eccentricityState = useState('0');
  const [orbitAltitudeStateVal, setorbitAltitudeState] = orbitAltitudeState;
  const [inclinationStateVal, setInclinationState] = inclinationState;
  const [raanStateVal, setRaanState] = raanState;
  const [eccentricityStateVal, setEccentricityState] = eccentricityState;

  const coeSliderProperties = useMemo(() => {
    const maxOrbitAltitude = Math.ceil(50000 - earthradius);
    return [
      {
        propertyName: 'Altitude',
        state: orbitAltitudeState,
        units: 'km',
        min: 100,
        max: maxOrbitAltitude,
        marks: [
          {
            value: 100,
            label: `${100}`,
          },
          {
            value: maxOrbitAltitude,
            label: `${maxOrbitAltitude}`,
          },
        ],
        objPropertyName: 'orbitLaunchAltitude' as GroundObjectEditableProperties,
      },
      {
        propertyName: 'Inclination',
        state: inclinationState,
        units: 'deg',
        min: 0,
        max: 180,
        marks: [
          zeroValMark,
          {
            value: 180,
            label: `${180}`,
          },
        ],
        objPropertyName: 'inclination' as GroundObjectEditableProperties,
      },
      {
        propertyName: 'RAAN',
        state: raanState,
        units: 'deg',
        min: 0,
        max: 360,
        marks: [
          zeroValMark,
          {
            value: 360,
            label: `${360}`,
          },
        ],
        objPropertyName: 'rightAscensionOfAscendingNode' as GroundObjectEditableProperties,
      },
    ];
  }, [inclinationState, orbitAltitudeState, raanState]);

  const latitudeState = useState('0');
  const longitudeState = useState('0');
  const altitudeState = useState('0');
  const [latitudeStateVal, setLatitudeState] = latitudeState;
  const [longitudeStateVal, setLongitudeState] = longitudeState;
  const [altitudeStateVal, setAltitudeState] = altitudeState;

  const latLonAltSliderProperties = useMemo(
    () => [
      {
        propertyName: 'Latitude',
        state: latitudeState,
        units: 'deg',
        min: LATITUDE_RANGE_DEG_MIN,
        max: LATITUDE_RANGE_DEG_MAX,
        marks: [
          {
            value: LATITUDE_RANGE_DEG_MIN,
            label: `${LATITUDE_RANGE_DEG_MIN}`,
          },
          zeroValMark,
          {
            value: LATITUDE_RANGE_DEG_MAX,
            label: `${LATITUDE_RANGE_DEG_MAX}`,
          },
        ],
        objPropertyName: 'latitude' as GroundObjectEditableProperties,
      },
      {
        propertyName: 'Longitude',
        state: longitudeState,
        units: 'deg',
        min: LONGITUDE_RANGE_DEG_MIN,
        max: LONGITUDE_RANGE_DEG_MAX,
        marks: [
          {
            value: LONGITUDE_RANGE_DEG_MIN,
            label: `${LONGITUDE_RANGE_DEG_MIN}`,
          },
          zeroValMark,
          {
            value: LONGITUDE_RANGE_DEG_MAX,
            label: `${LONGITUDE_RANGE_DEG_MAX}`,
          },
        ],
        objPropertyName: 'longitude' as GroundObjectEditableProperties,
      },
      {
        propertyName: 'Altitude',
        state: altitudeState,
        units: 'km',
        min: ALTITUDE_RANGE_KM_MIN,
        max: ALTITUDE_RANGE_KM_MAX,
        marks: [
          {
            value: ALTITUDE_RANGE_KM_MIN,
            label: `${ALTITUDE_RANGE_KM_MIN}`,
          },
          zeroValMark,
          {
            value: ALTITUDE_RANGE_KM_MAX,
            label: `${ALTITUDE_RANGE_KM_MAX}`,
          },
        ],
        sliderProps: { disabled: true },
        textFieldProps: { disabled: true },
        objPropertyName: 'altitude' as GroundObjectEditableProperties,
      },
    ],
    [altitudeState, latitudeState, longitudeState],
  );

  const updateGroundObjectState = useCallback(
    (val: number, objType: keyof GroundObjectEditType) => {
      const { groundObjectEdit, setGroundObjectEdit } = useGroundObjectLaunchEditStore.getState();
      if (!groundObjectEdit) return;

      setGroundObjectEdit({
        ...groundObjectEdit,
        name,
        [objType]: val,
      } as GroundObjectEditType);
    },
    [name],
  );

  // initialize ground object when flyout is opened
  useEffect(() => {
    const { setGroundObjectEdit } = useGroundObjectLaunchEditStore.getState();
    setGroundObjectEdit({
      name,
      id: -1,
      latitude: parseFloat(latitudeStateVal),
      longitude: parseFloat(longitudeStateVal),
      altitude: parseFloat(altitudeStateVal),
      orbitLaunchAltitude: parseFloat(orbitAltitudeStateVal),
      inclination: parseFloat(inclinationStateVal),
      rightAscensionOfAscendingNode: parseFloat(raanStateVal),
      eccentricity: parseFloat(eccentricityStateVal),
    } as GroundObjectEditType);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const createGroundObjectMutation = useCreateGroundObject();
  const createLaunchEventMutation = useCreateLaunchEventMutation();
  const updatePayloadOrbitMutation = useUpdateOrbitMutation();
  const updateLaunchEventSiteMutation = useUpdateGroundObject();

  const [isApplyingLaunch, setApplyingLaunch] = useState(false);

  const onApplyLaunch = async (selectedWindow: LaunchWindow) => {
    setApplyingLaunch(true);
    let groundObjId;
    let isPreselected = false;
    // using a pre-selected ground object should not create a new one
    if (!isNaN(parseInt(selectedGroundObject))) {
      groundObjId = parseInt(selectedGroundObject);
      isPreselected = true;
    } else {
      const groundObjRes = await createGroundObjectMutation.mutateAsync({
        name: '',
        latitude: parseFloat(latitudeStateVal),
        longitude: parseFloat(longitudeStateVal),
        altitude: parseFloat(altitudeStateVal),
        category: GroundObjectCategory.LAUNCH_PAD,
        pageId: currentPage!.id,
        minElevationAngle: 0,
        sensorRange: 0,
      });
      groundObjId = groundObjRes.id;
    }

    const nameNotEdited = name === PLACEHOLDER_NAME;
    const launchEventRequest: Partial<LaunchEventRequest> = {
      name: nameNotEdited ? (null as any) : name,
      pageId: currentPage!.id,
      launchSiteId: groundObjId,
      payloadOrbit: {
        name: '',
        orbit: [{ ...selectedWindow.payloadCoe }],
        orbitType: ORBIT_TYPES.LAUNCH,
        maneuvers: [selectedWindow.insertionManeuver, selectedWindow.launchManeuver],
      },
    };
    const launchEventRes = await createLaunchEventMutation.mutateAsync(launchEventRequest);
    // Backend will increment launch event number, which will then be used to update names for payload orbit and launch site
    if (!isPreselected)
      await updateLaunchEventSiteMutation.mutateAsync({
        id: launchEventRes.launchSiteId,
        name: `${launchEventRes.name} Launch Site`,
      });
    await updatePayloadOrbitMutation.mutateAsync({
      id: launchEventRes.payloadOrbit.id,
      name: `${launchEventRes.name} Orbit`,
    });
    const { set } = use3DOrbitStore.getState();
    set((state) => {
      state.orbits[launchEventRes.payloadOrbit.id].name = `${launchEventRes.name} Orbit`;
    });

    setApplyingLaunch(false);

    handleClose();
  };

  const loadingRef = useRef<null | HTMLDivElement>(null);
  const loadingContainerRef = useRef<null | HTMLDivElement>(null);
  useEffect(() => {
    // for adding a loading spinner which that will follow container scrolling while loading the launch windows
    const handleMouseMove = (event: MouseEvent) => {
      loadingRef!.current!.style.top = `${event.clientY}px`;
    };

    const loadingContainer = loadingContainerRef?.current;

    if (!loadingContainer || !loadingRef?.current) return;

    loadingContainer.addEventListener('mousemove', handleMouseMove);
    return () => {
      loadingContainer.removeEventListener('mousemove', handleMouseMove);
    };
  }, []);

  return (
    <SpaceGlassContainerBase
      style={{
        display: 'grid',
        gridTemplateRows: 'min-content 1fr',
        overflow: 'hidden',
        maxHeight: 'calc(100vh - 400px)',
        width: '350px',
        borderBottomRightRadius: 0,
        borderTopRightRadius: 0,
        // darken container while loading spinner shows
        filter: createLaunchWindows.isLoading || isApplyingLaunch ? 'brightness(25%)' : 'none',
      }}
    >
      <PanelHeader
        draggable={false}
        title="Launch Designer"
        rightSide={
          <Grid
            display="flex"
            p={1}
            container
          >
            <CloseRounded
              cursor="pointer"
              onClick={handleClose}
            />
          </Grid>
        }
      />
      <ScrollableContainer>
        <Grid
          container
          position="absolute"
          justifyContent="center"
          ref={loadingContainerRef}
          style={{
            display: createLaunchWindows.isLoading || isApplyingLaunch ? 'flex' : 'none',
          }}
        >
          <Grid item>
            <CircularProgress
              ref={loadingRef}
              size={100}
            />
          </Grid>
        </Grid>
        {launchEditActiveWindows ? (
          <LaunchWindows
            launchWindows={launchEditActiveWindows}
            onBack={() => {
              setLaunchEditActiveWindows(null);
              setActiveLaunchWindow(null);
            }}
            onApplyLaunch={onApplyLaunch}
          />
        ) : (
          <>
            <Grid
              display={isApplyingLaunch ? 'none' : 'initial'}
              container
              overflow="auto"
            >
              <Grid
                item
                p={2}
                xs={12}
              >
                <InputNameEditor
                  name={name ?? null}
                  setName={setName}
                  label="Launch Name"
                  placeholder="Launch Name"
                  saveName={() => void 0}
                />
              </Grid>
            </Grid>

            <Grid
              item
              px={2}
            >
              {hasErrorPropagationData && (
                <Typography
                  color="error"
                  marginBottom={2}
                >
                  {CONTENT.STATUS.ORBIT.LOADING_PROPAGATION}
                </Typography>
              )}

              {hasErrorLaunchLocationInvalid && (
                <Typography
                  color="error"
                  marginBottom={2}
                >
                  {CONTENT.ERRORS.LAUNCH.LOCATION_INVALID}
                </Typography>
              )}
            </Grid>

            <Grid
              px={2}
              pt={1}
              pb={0.5}
              container
              alignItems="center"
              justifyContent="space-between"
            >
              <Grid item>
                <Typography
                  variant="h6"
                  fontWeight="bold"
                >
                  Desired Orbit
                </Typography>
              </Grid>
              <Grid item>
                <FormControl
                  fullWidth
                  variant="standard"
                  size="small"
                >
                  <Select
                    label="Custom"
                    value={selectedOrbit}
                    onChange={handleSelectedOrbitChange}
                  >
                    {selectedOrbit === MENUITEM_CUSTOM.value && (
                      <MenuItem value={MENUITEM_CUSTOM.value}>{MENUITEM_CUSTOM.label}</MenuItem>
                    )}

                    {currentOrbits?.map((orbit) => {
                      const invalidEccentricity = orbit.orbit[0].eccentricity !== 0;
                      if (invalidEccentricity) {
                        return (
                          <Tooltip
                            key={orbit.id}
                            arrow
                            placement="bottom"
                            title="Orbits with non-zero eccentricity are not supported"
                          >
                            <div>
                              <MenuItem
                                value={orbit.id}
                                disabled
                              >
                                {orbit.name}
                              </MenuItem>
                            </div>
                          </Tooltip>
                        );
                      }
                      return (
                        <MenuItem
                          key={orbit.id}
                          value={orbit.id}
                        >
                          {orbit.name}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>

            <Grid px={3}>
              {coeSliderProperties.map(
                ({ propertyName, state, units, min, max, objPropertyName, marks }, idx) => {
                  const [stateVal, setState]: [string, Dispatch<SetStateAction<string>>] = state;
                  return (
                    <ObjectPropertySlider
                      disabled={isApplyingLaunch}
                      value={stateVal}
                      key={`${propertyName}`}
                      propertyName={propertyName}
                      textErrState={(fieldNum) => fieldNum < min || fieldNum > max}
                      textFieldUnits={units}
                      textFieldProps={{
                        helperText: `Number ranging from ${min} to ${max}`,
                      }}
                      sliderProps={{
                        min: min,
                        max: max,
                        marks,
                      }}
                      onChange={(item: string) => {
                        setState(item);
                        updateGroundObjectState(parseFloat(item), objPropertyName);
                        setSelectedOrbit(MENUITEM_CUSTOM.value);
                      }}
                    />
                  );
                },
              )}
              <ObjectPropertySlider
                disabled
                value={eccentricityState[0]}
                key="Eccentricity"
                propertyName="Eccentricity"
                textErrState={(fieldNum) => fieldNum < 0 || fieldNum > 1}
                textFieldUnits=""
                textFieldProps={{
                  helperText: `Number ranging from ${0} to ${1}`,
                }}
                sliderProps={{
                  min: 0,
                  max: 1,
                  step: 0.01,
                  marks: [
                    {
                      value: 0,
                      label: `${0}`,
                    },
                    {
                      value: 1,
                      label: `${1}`,
                    },
                  ],
                }}
                onChange={(item: string) => {
                  setEccentricityState(item);
                  updateGroundObjectState(parseFloat(item), 'eccentricity');
                  setSelectedOrbit(MENUITEM_CUSTOM.value);
                }}
              />
            </Grid>

            <Divider
              sx={{
                margin: `${theme.spacing(2)} ${theme.spacing(1)} ${theme.spacing(
                  2,
                )} ${theme.spacing(1)}`,
              }}
            />

            <Grid
              px={2}
              pt={1}
              pb={0.5}
              container
              alignItems="center"
              justifyContent="space-between"
            >
              <Grid item>
                <Typography
                  variant="h6"
                  fontWeight="bold"
                >
                  Launch Site
                </Typography>
              </Grid>
              <Grid item>
                <FormControl
                  fullWidth
                  variant="standard"
                  size="small"
                >
                  <Select
                    label="Custom"
                    value={selectedGroundObject}
                    onChange={handleSelectedGroundObjectChange}
                  >
                    {selectedGroundObject === MENUITEM_CUSTOM.value && (
                      <MenuItem value={MENUITEM_CUSTOM.value}>{MENUITEM_CUSTOM.label}</MenuItem>
                    )}

                    {currentGroundObjects?.map((groundObject) => {
                      if (groundObject.category === GroundObjectCategory.LAUNCH_PAD) {
                        return (
                          <MenuItem
                            key={groundObject.id}
                            value={groundObject.id}
                          >
                            {groundObject.name}
                          </MenuItem>
                        );
                      }
                      return null;
                    })}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>

            <Grid px={3}>
              {latLonAltSliderProperties.map(
                ({ propertyName, state, units, min, max, marks, objPropertyName }, idx) => {
                  const [stateVal, setState]: [string, Dispatch<SetStateAction<string>>] = state;
                  return (
                    <ObjectPropertySlider
                      disabled={isApplyingLaunch}
                      value={stateVal}
                      key={`${propertyName}`}
                      propertyName={propertyName}
                      textErrState={(fieldNum) => fieldNum < min || fieldNum > max}
                      textFieldUnits={units}
                      textFieldProps={{
                        helperText: `Number ranging from ${min} to ${max}`,
                      }}
                      sliderProps={{
                        min: min,
                        max: max,
                        marks,
                      }}
                      onChange={(item: string) => {
                        setState(item);
                        updateGroundObjectState(parseFloat(item), objPropertyName);
                        setSelectedGroundObject(MENUITEM_CUSTOM.value);
                      }}
                    />
                  );
                },
              )}
            </Grid>

            <Grid
              container
              direction="column"
              alignItems="center"
            >
              <Button
                sx={{ m: 2 }}
                variant="contained"
                aria-label="Compute Launch"
                disabled={hasErrorPropagationData || hasErrorLaunchLocationInvalid}
                onClick={computeLaunch}
              >
                COMPUTE LAUNCH
              </Button>
            </Grid>
          </>
        )}
      </ScrollableContainer>
    </SpaceGlassContainerBase>
  );
};
