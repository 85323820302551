import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useCallback, useMemo } from 'react';
import { DEFAULT_ADDITIONAL_PROPERTIES_GROUND_OBJECTS } from 'src/constants';
import { router } from 'src/pages/App/routes/Router';
import {
  getActiveCapabilityId,
  getActiveGroundObjectId,
  getActiveNotebookId,
  getActivePageId,
  useRouteStore,
} from 'src/pages/App/routes/store';
import { fetchApi } from 'src/services/api';
import { CancellablePromise, GroundObject, GroundObjectAdditionalProperties } from 'src/types';
import { useApiSnackbarError } from './SnackbarHooks';
import {
  WINDOWS_TYPES,
  useViewingWindowsStore,
} from 'src/pages/Notebook/components/ViewingWindowsStore';
import { useGroundObjectLaunchEditStore } from 'src/threejs/components/GroundObjects/GroundObjectLaunchEditStore';

const deserializeGroundObject = (groundObj: GroundObject): GroundObject => {
  return {
    ...groundObj,
    additionalProperties: {
      ...DEFAULT_ADDITIONAL_PROPERTIES_GROUND_OBJECTS,
      ...groundObj.additionalProperties,
    },
  };
};

export function useGroundObjectsQuery(pageId?: number) {
  const apiSnackbarError = useApiSnackbarError();

  return useQuery<GroundObject[]>(
    ['groundObjectsForPage', pageId],
    () => {
      const controller = new AbortController();
      const signal = controller.signal;

      const promise = fetchApi(`/v2/ground-objects?pageId=${pageId}`, { signal }).then((data) => {
        if (data.length <= 0) return [];
        return data.map((groundObj: GroundObject) => deserializeGroundObject(groundObj));
      }) as CancellablePromise<GroundObject[]>;

      promise.cancel = () => {
        controller.abort();
      };

      return promise;
    },
    {
      enabled: Boolean(pageId),
      onError: () => {
        apiSnackbarError('Failed to get ground object(s).');
      },
    },
  );
}

export function useGroundObjects(pageId?: number) {
  const { data } = useGroundObjectsQuery(pageId);

  return data;
}

export function useCurrentGroundObjects() {
  const pageId = useRouteStore(getActivePageId);
  return useGroundObjects(pageId);
}

export function useCurrentGroundObject() {
  const objId = useRouteStore(getActiveGroundObjectId);
  const groundObjects = useCurrentGroundObjects();
  return useMemo(() => groundObjects?.find((obj) => obj.id === objId), [groundObjects, objId]);
}

export function useUpdateGroundObjectAdditionalProperties() {
  const updateGroundObj = useUpdateGroundObject();

  return (obj: GroundObject, changes: Partial<GroundObjectAdditionalProperties>) => {
    if (obj) {
      const objUpdate: Partial<GroundObject> = {
        ...obj,
        id: obj.id,
        name: obj.name,
        additionalProperties: {
          ...DEFAULT_ADDITIONAL_PROPERTIES_GROUND_OBJECTS,
          ...obj.additionalProperties,
          ...changes,
        },
      };
      updateGroundObj.mutateAsync(objUpdate);
    }
  };
}

export function useCreateGroundObject() {
  const pageId = useRouteStore(getActivePageId);

  const apiSnackbarError = useApiSnackbarError();

  const queryClient = useQueryClient();
  return useMutation(
    ({
      name,
      latitude,
      longitude,
      altitude,
      category,
      minElevationAngle,
      sensorRange,
    }: Partial<GroundObject>) => {
      return fetchApi('/v2/ground-objects', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          name,
          latitude,
          longitude,
          altitude,
          category,
          pageId,
          minElevationAngle,
          sensorRange,
        }),
      });
    },
    {
      onError: () => {
        apiSnackbarError('Failed to create ground object.');
      },
      onSuccess: async () => {
        await queryClient.invalidateQueries(['groundObjectsForPage', Number(pageId)]);
      },
    },
  );
}

export function useSelectGroundObject() {
  const capabilityId = useRouteStore(getActiveCapabilityId);
  const notebookId = useRouteStore(getActiveNotebookId);
  const pageId = useRouteStore(getActivePageId);

  return useCallback(
    (groundObjId: number) => {
      let newUrl = `/notebook/${notebookId}/${pageId}/ground-object/${groundObjId}`;
      if (capabilityId) {
        newUrl = `/shared/${capabilityId}` + newUrl;
      }
      router.navigate(newUrl);
    },
    [capabilityId, notebookId, pageId],
  );
}

export function useUpdateGroundObject() {
  const pageId = useRouteStore(getActivePageId);
  const groundObjects = useCurrentGroundObjects();

  const invalidateWindows = useViewingWindowsStore((state) => state.invalidateWindows);
  const invalidateWindowsAllByGroupId = useViewingWindowsStore(
    (state) => state.invalidateWindowsAllByGroupId,
  );
  const invalidateWindowsAllByObjectid = useViewingWindowsStore(
    (state) => state.invalidateWindowsAllByObjectid,
  );

  const apiSnackbarError = useApiSnackbarError();

  const queryClient = useQueryClient();
  return useMutation(
    (incomingObj: Partial<GroundObject>) => {
      const groundObj = groundObjects?.find((obj) => obj.id === incomingObj.id);

      const newGroundObj = {
        ...groundObj,
        ...incomingObj,
      };

      return fetchApi(`/v2/ground-objects/${incomingObj.id}`, {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(newGroundObj),
      });
    },
    {
      onError: () => {
        apiSnackbarError('Failed to update ground object.');
      },
      onSuccess: async (data, variables) => {
        await queryClient.invalidateQueries(['groundObjectsForPage', Number(pageId)]);

        // if groupId changed, need to invalidate the previous one
        const prevGroundObject = groundObjects?.find((groundObject) => groundObject.id === data.id);
        if (prevGroundObject && prevGroundObject.groupId !== data.groupId) {
          invalidateWindowsAllByGroupId(WINDOWS_TYPES.SPACE_SENSOR, prevGroundObject.groupId);
        }
        // invalidate all space sensors that have new groupid as target
        if (data.groupId) {
          invalidateWindowsAllByGroupId(WINDOWS_TYPES.SPACE_SENSOR, data.groupId);
        }

        // invalidate any sensors that target this object id
        invalidateWindowsAllByObjectid(WINDOWS_TYPES.SPACE_SENSOR, data.id);

        // also, invalidate windows for this ground object
        invalidateWindows(WINDOWS_TYPES.GROUND_OBJECT, data.id);
      },
    },
  );
}

export function useDeleteGroundObject() {
  const pageId = useRouteStore(getActivePageId);
  const groundObjects = useCurrentGroundObjects();

  const apiSnackbarError = useApiSnackbarError();

  const invalidateWindows = useViewingWindowsStore((state) => state.invalidateWindows);
  const invalidateWindowsAllByGroupId = useViewingWindowsStore(
    (state) => state.invalidateWindowsAllByGroupId,
  );
  const invalidateWindowsAllByObjectid = useViewingWindowsStore(
    (state) => state.invalidateWindowsAllByObjectid,
  );

  const queryClient = useQueryClient();
  return useMutation(
    ({ id }: Partial<GroundObject>) => {
      return fetchApi(`/v2/ground-objects/${id}`, {
        method: 'DELETE',
        headers: { 'Content-Type': 'application/json' },
      });
    },
    {
      onError: () => {
        apiSnackbarError('Failed to delete ground object.');
      },
      onSuccess: async (data, variables) => {
        if (variables.id) {
          const prevGroundObject = groundObjects?.find(
            (groundObject) => groundObject.id === variables.id,
          );

          // when a ground object is deleted, its accompanying item's mesh's group ref must also be removed from store
          const { groundObjectItemRefs, setGroundObjectItemRefs } =
            useGroundObjectLaunchEditStore.getState();

          const { [variables.id]: deletedItemRef, ...remainingItemRefs } = groundObjectItemRefs;
          setGroundObjectItemRefs(remainingItemRefs);

          await queryClient.invalidateQueries(['groundObjectsForPage', Number(pageId)]);

          if (prevGroundObject) {
            // invalidate all space sensors that have new groupid as target
            if (prevGroundObject.groupId) {
              invalidateWindowsAllByGroupId(WINDOWS_TYPES.SPACE_SENSOR, prevGroundObject.groupId);
            }

            // invalidate any sensors that target this object id
            invalidateWindowsAllByObjectid(WINDOWS_TYPES.SPACE_SENSOR, prevGroundObject.id);

            // also, invalidate windows for this ground object
            invalidateWindows(WINDOWS_TYPES.GROUND_OBJECT, prevGroundObject.id);
          }
        }
      },
    },
  );
}
