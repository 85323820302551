import { Button } from '@mui/material';
import { useSnackbar } from 'notistack';
import { useCallback, useEffect } from 'react';
import { getUpdateTimelineRange } from 'src/core/getters';
import PropagatedCacheManager, {
  CanPlayThroughEventPlayload,
} from 'src/models/PropagatedCacheManager';

export const DismissErrorNotification = (key: string | number, onReconnect: () => void) => {
  const ReconnectSnackbarBtn = () => <Button onClick={onReconnect}>Reconnect</Button>;
  const ReloadSnackbarBtn = () => <Button onClick={() => window.location.reload()}>Refresh</Button>;

  return DismissNotificationAction(
    key,
    <>
      <ReconnectSnackbarBtn />
      <ReloadSnackbarBtn />
    </>,
  );
};

export const DismissNotificationAction = (
  key: string | number,
  otherActions?: null | JSX.Element,
) => {
  const { closeSnackbar } = useSnackbar();

  const onClickDismiss = useCallback(
    (key: string | number) => () => {
      closeSnackbar(key);
    },
    [closeSnackbar],
  );

  return (
    <>
      <Button onClick={onClickDismiss(key)}>Dismiss</Button>
      {otherActions}
    </>
  );
};

/** Triggers notifications for the propagated cache manager. */
export function useNotifications() {
  const { enqueueSnackbar } = useSnackbar();

  const onMaxMemoryReached = useCallback(() => {
    const updateTimelineRange = getUpdateTimelineRange();
    enqueueSnackbar('Out of Memory', {
      action: DismissNotificationAction,
      anchorOrigin: {
        vertical: 'top',
        horizontal: 'right',
      },
      variant: 'error',
    });
    updateTimelineRange({ playState: 'stopped' });
  }, [enqueueSnackbar]);

  const onCanPlayThrough = useCallback(
    ({ name }: CanPlayThroughEventPlayload) => {
      enqueueSnackbar(`Propagation Complete for ${name}`, {
        action: DismissNotificationAction,
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'right',
        },
        variant: 'success',
      });
    },
    [enqueueSnackbar],
  );

  useEffect(() => {
    PropagatedCacheManager.addEventListener('canplaythrough', onCanPlayThrough);
    PropagatedCacheManager.addEventListener('memorylimitreached', onMaxMemoryReached);
    return function cleanup() {
      PropagatedCacheManager.removeEventListener('canplaythrough', onCanPlayThrough);
      PropagatedCacheManager.removeEventListener('memorylimitreached', onMaxMemoryReached);
    };
  }, [onCanPlayThrough, onMaxMemoryReached]);
}
