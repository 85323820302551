import { useState } from 'react';
import { Typography } from '@mui/material';
import { useCurrentOrbit, useIsPropagating } from 'src/hooks/OrbitHooks';
import { useDebouncedCallback } from 'src/hooks/useDebouncedCallback';
import { useSaveCurrentOrbit } from 'src/threejs/components/OrbitManager/store/hooks';
import use3DOrbitStore from 'src/threejs/components/OrbitManager/store/store';
import { OrbitObjectStateVector } from 'src/types';
import { ObjectPropertySlider } from '../ObjectPropertySlider';

const MIN_MAX_PARAMETERS = {
  POSITION_MAX: 50000,
  POSITION_MIN: -50000,
  VELOCITY_MAX: 10,
  VELOCITY_MIN: -10,
};

export const OrbitPropertiesCartesian = () => {
  const currentOrbit = useCurrentOrbit();

  const isPropagating = useIsPropagating();

  const updateOrbitSV = use3DOrbitStore(
    (state) => currentOrbit && state.orbits[currentOrbit.id].updateOrbitSV,
  );

  const [saveCurrentOrbit] = useDebouncedCallback(useSaveCurrentOrbit(), 500);

  const [xPosition, setXPosition] = useState<string>(
    currentOrbit?.stateVectors?.xPosition?.toString() ?? '0',
  );
  const [yPosition, setYPosition] = useState<string>(
    currentOrbit?.stateVectors?.yPosition?.toString() ?? '0',
  );
  const [zPosition, setZPosition] = useState<string>(
    currentOrbit?.stateVectors?.zPosition?.toString() ?? '0',
  );

  const [xVelocity, setXVelocity] = useState<string>(
    currentOrbit?.stateVectors?.xVelocity?.toString() ?? '0',
  );
  const [yVelocity, setYVelocity] = useState<string>(
    currentOrbit?.stateVectors?.yVelocity?.toString() ?? '0',
  );
  const [zVelocity, setZVelocity] = useState<string>(
    currentOrbit?.stateVectors?.zVelocity?.toString() ?? '0',
  );

  const onChangeStateVector = (svUpdates: Partial<OrbitObjectStateVector>) => {
    if (updateOrbitSV) {
      updateOrbitSV(svUpdates);
      saveCurrentOrbit();
    }
  };

  if (!currentOrbit) {
    return null;
  }

  return (
    <>
      <Typography
        variant="h6"
        p={1}
      >
        Position
      </Typography>
      <ObjectPropertySlider
        propertyName="X Position"
        disabled={isPropagating}
        value={xPosition}
        textFieldUnits="km"
        textFieldProps={{
          helperText: `Number ranging from ${MIN_MAX_PARAMETERS.POSITION_MIN} to ${MIN_MAX_PARAMETERS.POSITION_MAX}`,
          onBlur: () => {
            if (isNaN(parseFloat(xPosition))) setXPosition('0');
          },
        }}
        textErrState={(fieldNum) =>
          fieldNum < MIN_MAX_PARAMETERS.POSITION_MIN || fieldNum > MIN_MAX_PARAMETERS.POSITION_MAX
        }
        sliderProps={{
          step: 0.00001,
          min: MIN_MAX_PARAMETERS.POSITION_MIN,
          max: MIN_MAX_PARAMETERS.POSITION_MAX,
          marks: [{ value: 0, label: '0' }],
        }}
        onChange={(newValue) => {
          const value = parseFloat(newValue);
          setXPosition(newValue);
          if (isNaN(value)) return;
          onChangeStateVector({
            xPosition: value,
          });
        }}
      />
      <ObjectPropertySlider
        propertyName="Y Position"
        disabled={isPropagating}
        value={yPosition}
        textFieldUnits="km"
        textFieldProps={{
          helperText: `Number ranging from ${MIN_MAX_PARAMETERS.POSITION_MIN} to ${MIN_MAX_PARAMETERS.POSITION_MAX}`,
          onBlur: () => {
            if (isNaN(parseFloat(yPosition))) setYPosition('0');
          },
        }}
        textErrState={(fieldNum) =>
          fieldNum < MIN_MAX_PARAMETERS.POSITION_MIN || fieldNum > MIN_MAX_PARAMETERS.POSITION_MAX
        }
        sliderProps={{
          step: 0.00001,
          min: MIN_MAX_PARAMETERS.POSITION_MIN,
          max: MIN_MAX_PARAMETERS.POSITION_MAX,
          marks: [{ value: 0, label: '0' }],
        }}
        onChange={(newValue) => {
          const value = parseFloat(newValue);
          setYPosition(newValue);
          if (isNaN(value)) return;
          onChangeStateVector({
            yPosition: value,
          });
        }}
      />
      <ObjectPropertySlider
        propertyName="Z Position"
        disabled={isPropagating}
        value={zPosition}
        textFieldUnits="km"
        textFieldProps={{
          helperText: `Number ranging from ${MIN_MAX_PARAMETERS.POSITION_MIN} to ${MIN_MAX_PARAMETERS.POSITION_MAX}`,
          onBlur: () => {
            if (isNaN(parseFloat(zPosition))) setZPosition('0');
          },
        }}
        textErrState={(fieldNum) =>
          fieldNum < MIN_MAX_PARAMETERS.POSITION_MIN || fieldNum > MIN_MAX_PARAMETERS.POSITION_MAX
        }
        sliderProps={{
          step: 0.00001,
          min: MIN_MAX_PARAMETERS.POSITION_MIN,
          max: MIN_MAX_PARAMETERS.POSITION_MAX,
          marks: [{ value: 0, label: '0' }],
        }}
        onChange={(newValue) => {
          const value = parseFloat(newValue);
          setZPosition(newValue);
          if (isNaN(value)) return;
          onChangeStateVector({
            zPosition: value,
          });
        }}
      />
      <Typography
        variant="h6"
        p={1}
      >
        Velocity
      </Typography>
      <ObjectPropertySlider
        propertyName="X Velocity"
        disabled={isPropagating}
        value={xVelocity}
        textFieldUnits="km/s"
        textFieldProps={{
          helperText: `Number ranging from ${MIN_MAX_PARAMETERS.VELOCITY_MIN} to ${MIN_MAX_PARAMETERS.VELOCITY_MAX}`,
          onBlur: () => {
            if (isNaN(parseFloat(xVelocity))) setXVelocity('0');
          },
        }}
        textErrState={(fieldNum) =>
          fieldNum < MIN_MAX_PARAMETERS.VELOCITY_MIN || fieldNum > MIN_MAX_PARAMETERS.VELOCITY_MAX
        }
        sliderProps={{
          step: 0.00001,
          min: MIN_MAX_PARAMETERS.VELOCITY_MIN,
          max: MIN_MAX_PARAMETERS.VELOCITY_MAX,
          marks: [{ value: 0, label: '0' }],
        }}
        onChange={(newValue) => {
          const value = parseFloat(newValue);
          setXVelocity(newValue);
          if (isNaN(value)) return;
          onChangeStateVector({
            xVelocity: value,
          });
        }}
      />
      <ObjectPropertySlider
        propertyName="Y Velocity"
        disabled={isPropagating}
        value={yVelocity}
        textFieldUnits="km/s"
        textFieldProps={{
          helperText: `Number ranging from ${MIN_MAX_PARAMETERS.VELOCITY_MIN} to ${MIN_MAX_PARAMETERS.VELOCITY_MAX}`,
          onBlur: () => {
            if (isNaN(parseFloat(yVelocity))) setYVelocity('0');
          },
        }}
        textErrState={(fieldNum) =>
          fieldNum < MIN_MAX_PARAMETERS.VELOCITY_MIN || fieldNum > MIN_MAX_PARAMETERS.VELOCITY_MAX
        }
        sliderProps={{
          step: 0.00001,
          min: MIN_MAX_PARAMETERS.VELOCITY_MIN,
          max: MIN_MAX_PARAMETERS.VELOCITY_MAX,
          marks: [{ value: 0, label: '0' }],
        }}
        onChange={(newValue) => {
          const value = parseFloat(newValue);
          setYVelocity(newValue);
          if (isNaN(value)) return;
          onChangeStateVector({
            yVelocity: value,
          });
        }}
      />
      <ObjectPropertySlider
        propertyName="Z Velocity"
        disabled={isPropagating}
        value={zVelocity}
        textFieldUnits="km/s"
        textFieldProps={{
          helperText: `Number ranging from ${MIN_MAX_PARAMETERS.VELOCITY_MIN} to ${MIN_MAX_PARAMETERS.VELOCITY_MAX}`,
          onBlur: () => {
            if (isNaN(parseFloat(zVelocity))) setZVelocity('0');
          },
        }}
        textErrState={(fieldNum) =>
          fieldNum < MIN_MAX_PARAMETERS.VELOCITY_MIN || fieldNum > MIN_MAX_PARAMETERS.VELOCITY_MAX
        }
        sliderProps={{
          step: 0.00001,
          min: MIN_MAX_PARAMETERS.VELOCITY_MIN,
          max: MIN_MAX_PARAMETERS.VELOCITY_MAX,
          marks: [{ value: 0, label: '0' }],
        }}
        onChange={(newValue) => {
          const value = parseFloat(newValue);
          setZVelocity(newValue);
          if (isNaN(value)) return;
          onChangeStateVector({
            zVelocity: value,
          });
        }}
      />
    </>
  );
};
