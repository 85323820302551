import { Draft } from 'immer';
import {
  CONVERSION_TOLERANCE,
  earthradius,
  muEarth,
  PROPAGATION_STEPS_PER_REVOLUTION,
} from 'src/constants';
import { getCurrentTime } from 'src/core/getters';
import keplerianOrbitMath from 'src/threejs/math/keplerianOrbit';
import { COE } from 'src/threejs/models/COE';
import {
  Bookmark,
  OrbitObject,
  OrbitObjectStateVector,
  ReferenceFrame,
  ReferenceFrameType,
  StateVectorType,
} from 'src/types';
import { dateToJulianDay, dateToMillisecondsInJulianDay } from 'src/utilities/DateTimeUtils';
import {
  COEDataOnly,
  convertCartesianToKeplerian,
  convertKeplarianToCartesian,
} from 'src/utilities/Keplerian';
import { MathUtils, Quaternion, Vector3 } from 'three';
import { StoreApi } from 'zustand';
import use3DOrbitStore from './store';
import { EpochDateTime, OrbitState, OrbitStoreState } from './types';

const defaultEpoch: EpochDateTime = {
  year: 2020,
  month: 0,
  day: 1,
  hour: 12,
  minutes: 0,
  seconds: 0,
};

const dateToUTC = (dt: EpochDateTime) =>
  new Date(Date.UTC(dt.year, dt.month, dt.day, dt.hour, dt.minutes, dt.seconds));

export const MaxGroundTrackBufferSize = 10000;

export const createInitialOrbitState = (
  { id, orbit, name, perturbations, orbitType, orbitTLE, groupId, stateVectors }: OrbitObject,
  size: number,
  set: (fn: (draft: Draft<OrbitStoreState>) => void) => void,
  get: StoreApi<OrbitStoreState>['getState'],
): OrbitState => {
  const initEpoch = dateToUTC(defaultEpoch);
  return {
    groupId,
    id,
    name,
    initialized: false,
    perturbations,
    orbitType,
    orbitTLE,
    size,
    vertices: [],
    verts: [],
    displayedCallouts: [],
    coe: new COE(orbit[0]),
    stateVectors: stateVectors,
    getECZoomDistance: () => {
      const { orbits } = get();
      const {
        orbitData: { apogeeDistance },
      } = orbits[id];

      // TODO: Move this to config
      const scaleFactor = 1 / earthradius;
      const minDistance = 8000; // km

      // TODO:
      //  a.  If facing (abs dot) maybe then give more buffer
      //  b.  for large eccentricity, look at the major axis vector and do with camera pos
      //      if away from camera, make the distance smaller
      const dist = Math.max(apogeeDistance, minDistance);

      return dist * scaleFactor;
    },
    orbitPropagationPath: {
      dataPoints: [],
      ecefDataPoints: [],
      velocityDataPoints: [],
      timePoints: [],
      addStateVectors: (stateVectors, maxPointCount, scaleFactor) => {
        set((state) => {
          const orbitPathData = state.orbits[id].orbitPropagationPath;
          const { dataPoints, timePoints, ecefDataPoints, velocityDataPoints } = orbitPathData;

          function addStateVector(
            pos: number[],
            epoch: number,
            referenceFrame: ReferenceFrameType,
          ) {
            // cache the point locally for now
            const addPointData = (x: number, y: number, z: number, epoch: number) => {
              // now swizzle the axes to map into our coords
              const p = new Vector3(y, z, x);
              if (referenceFrame === ReferenceFrame.ECEF) {
                ecefDataPoints.push(p);
              } else if (referenceFrame === ReferenceFrame.ECI) {
                dataPoints.push(p);
              }
            };

            // point is in real-word scale, we need to normalize to our scale
            const x = pos[0] * scaleFactor * 0.001;
            const y = pos[1] * scaleFactor * 0.001;
            const z = pos[2] * scaleFactor * 0.001;

            // velocity is ignored for now until we need it
            // add the new point to the buffer
            addPointData(x, y, z, epoch);
          }

          function addVelocityVector(velocity: number[]) {
            const x = velocity[0] * 0.001;
            const y = velocity[1] * 0.001;
            const z = velocity[2] * 0.001;
            velocityDataPoints.push(new Vector3(y, z, x));
          }

          stateVectors.forEach(({ stateVectors }) => {
            const numVectors = stateVectors.length;

            if (numVectors === 0) {
              return;
            }

            for (const element of stateVectors) {
              const eciPos = [element.x_position, element.y_position, element.z_position];

              const ecefPos = [
                element.ecefStateVector.x_position,
                element.ecefStateVector.y_position,
                element.ecefStateVector.z_position,
              ];
              const velocity = [element.x_velocity, element.y_velocity, element.z_velocity];
              const epoch = element.epoch;

              // add state vectors one at a time
              addStateVector(eciPos, epoch, ReferenceFrame.ECI);
              addStateVector(ecefPos, epoch, ReferenceFrame.ECEF);
              addVelocityVector(velocity);
              timePoints.push(epoch);
            }
          });
        });
      },
      reset: () => {
        set((state) => {
          state.orbits[id].orbitPropagationPath.dataPoints = [];
          state.orbits[id].orbitPropagationPath.ecefDataPoints = [];
          state.orbits[id].orbitPropagationPath.timePoints = [];
        });
      },
    },
    orbitTime: {
      epochDate: initEpoch,
      epochJulianDay: dateToJulianDay(initEpoch),
      epochJulianMilliSec: dateToMillisecondsInJulianDay(initEpoch),
      ellapsedTimeMilliSec: 0,
      animDateTime: new Date(initEpoch.getTime()),
    },
    orbitData: {
      numSegments: PROPAGATION_STEPS_PER_REVOLUTION,
      primeFocusPosition: new Vector3(),
      apogeeDistance: 0.0,
      perigeeDistance: 0.0,
      majorAxisVector: new Vector3(),
      minorAxisVector: new Vector3(),
      apogeePosition: new Vector3(),
      perigeePosition: new Vector3(),
      raanPosition: new Vector3(),
      aopPosition: new Vector3(),
      ellipseCenterPosition: new Vector3(),
      orbitalPlaneVector: new Vector3(),
      orbitalPlaneOrientation: new Quaternion(),
      surfaceAtPerigeePosition: new Vector3(),
      semiMinorAxisDistance: 0.0,
      semiMinorAxisPosition1: new Vector3(),
      semiMinorAxisPosition2: new Vector3(), // not needed (position of semilatus rectum)
      inclinationRightAnglePosition: new Vector3(),
      altitudeOfPerigee: 0.0,
      trueAnomalyPosition: new Vector3(),
      period: 0.0,
      trueAnomalyAnimationPosition: new Vector3(),
      hasConditionEarthIntersect: false,
      hasConditionEscapeVelocity: false,
    },
    makeKeplerianOrbit: (numSegments = PROPAGATION_STEPS_PER_REVOLUTION) => {
      set((state) => {
        const orbitState = state.orbits[id];
        const { orbitData, vertices, verts } = orbitState;

        orbitData.numSegments = numSegments;

        const {
          apogeeDistance,
          perigeeDistance,
          trueAnomalyRangeArray,
          rArray,
          period,
          scaleFactor,
          angleToPositionXform,
          majorAxisVector,
          perigeePosition,
          surfaceAtPerigeePosition,
          altitudeOfPerigee,
          apogeePosition,
          ellipseCenterPosition,
          semiMinorAxisPosition2,
          orbitalPlaneVector,
          semiMinorAxisDistance,
          minorAxisVector,
          semiMinorAxisPosition1,
          orbitalPlaneOrientation,
          raanPosition,
          inclinationRightAnglePosition,
          aopPosition,
        } = keplerianOrbitMath(orbitState.coe.toRad(), numSegments);

        orbitData.apogeeDistance = apogeeDistance;
        orbitData.perigeeDistance = perigeeDistance;
        orbitData.period = period;

        // For each element in rArray, compute the radial unit vector using the coe attitude angles.  Multiply the unit vector by
        // the radial distance in rArray.  The result is the 3D position vector in intertial earth centered coordinates true scale
        rArray.forEach((radius, index) => {
          const rhat = new Vector3(0, 0, 0);

          const theta = trueAnomalyRangeArray[index];

          // get point position for each angle
          angleToPositionXform(theta, rhat);

          // multiply the unit vector by the radius, then scaleFactor
          rhat.multiplyScalar(radius).multiplyScalar(scaleFactor);

          // Not saving as vectors ATM positionVectorECI[index]
          // positionVectorECI[index] = rhat.multiplyScalar(radius).multiplyScalar(scaleFactor);

          // save the position vectors unpacked
          // vertices.push(rhat.x, rhat.y, rhat.z);
          vertices[index * 3] = rhat.x;
          vertices[index * 3 + 1] = rhat.y;
          vertices[index * 3 + 2] = rhat.z;

          // TODO:  rhat is created each time this called and assigned to vertices.
          verts[index] = rhat;
        });

        orbitData.majorAxisVector = majorAxisVector(
          orbitData.majorAxisVector,
          orbitData.primeFocusPosition,
        ).clone();

        orbitData.perigeePosition = perigeePosition(
          orbitData.perigeePosition,
          orbitData.majorAxisVector,
        ).clone();

        orbitData.surfaceAtPerigeePosition = surfaceAtPerigeePosition(
          orbitData.surfaceAtPerigeePosition,
          orbitData.majorAxisVector,
        ).clone();

        orbitData.altitudeOfPerigee = altitudeOfPerigee;

        orbitData.apogeePosition = apogeePosition(
          orbitData.apogeePosition,
          orbitData.majorAxisVector,
        ).clone();

        orbitData.ellipseCenterPosition = ellipseCenterPosition(
          orbitData.ellipseCenterPosition,
          orbitData.majorAxisVector,
          orbitData.primeFocusPosition,
        ).clone();

        orbitData.semiMinorAxisPosition2 = semiMinorAxisPosition2(
          orbitData.semiMinorAxisPosition2,
          orbitData.orbitalPlaneVector,
          orbitData.primeFocusPosition,
        ).clone();

        orbitData.orbitalPlaneVector = orbitalPlaneVector(
          orbitData.orbitalPlaneVector,
          orbitData.primeFocusPosition,
          orbitData.majorAxisVector,
        ).clone();

        orbitData.semiMinorAxisDistance = semiMinorAxisDistance;

        orbitData.minorAxisVector = minorAxisVector(
          orbitData.minorAxisVector,
          orbitData.orbitalPlaneVector,
          orbitData.majorAxisVector,
        ).clone();

        orbitData.semiMinorAxisPosition1 = semiMinorAxisPosition1(
          orbitData.semiMinorAxisPosition1,
          orbitData.minorAxisVector,
          orbitData.ellipseCenterPosition,
        ).clone();

        orbitData.orbitalPlaneOrientation = orbitalPlaneOrientation(
          orbitData.orbitalPlaneOrientation,
          orbitData.majorAxisVector,
          orbitData.minorAxisVector,
          orbitData.orbitalPlaneVector,
        ).clone();

        orbitData.raanPosition = raanPosition(orbitData.raanPosition).clone();

        orbitData.inclinationRightAnglePosition = inclinationRightAnglePosition(
          orbitData.inclinationRightAnglePosition,
          orbitData.orbitalPlaneVector,
          orbitData.raanPosition,
        ).clone();

        orbitData.aopPosition = aopPosition(orbitData.aopPosition).clone();

        orbitData.hasConditionEarthIntersect = orbitData.altitudeOfPerigee <= 100 ? true : false;

        const positionVector = new Vector3(
          orbitState.stateVectors?.xPosition,
          orbitState.stateVectors?.yPosition,
          orbitState.stateVectors?.zPosition,
        );
        const velocityVector = new Vector3(
          orbitState.stateVectors?.xVelocity,
          orbitState.stateVectors?.yVelocity,
          orbitState.stateVectors?.zVelocity,
        );
        const velocityEscapeLimit = Math.sqrt((2 * muEarth) / positionVector.length());
        orbitData.hasConditionEscapeVelocity = velocityVector.length() >= velocityEscapeLimit;
      });
    },
    initializeOrbitState: () => {
      const { orbits } = get();
      const { initialized, makeKeplerianOrbit } = orbits[id];

      if (!initialized) {
        makeKeplerianOrbit();
        set((state) => {
          const orbitCoe = state.orbits[id].coe;

          // also need to update corresponding sv data
          const newSV = convertKeplarianToCartesian(orbitCoe);

          const orbitSV: OrbitObjectStateVector = {
            xPosition: newSV.x_position!,
            yPosition: newSV.y_position!,
            zPosition: newSV.z_position!,
            xVelocity: newSV.x_velocity!,
            yVelocity: newSV.y_velocity!,
            zVelocity: newSV.z_velocity!,
          };
          state.orbits[id].stateVectors = orbitSV;

          state.orbits[id].initialized = true;
        });
      }
    },
    updateOrbitState: (updatedOrbit, numSegments) => {
      const { orbits } = get();
      const { makeKeplerianOrbit, coe } = orbits[id];

      // We only update the orbit if it's coe value's have changed
      // this prevent unneccessary re-renders and mounting/unmounting
      if (coe.hasChanged(updatedOrbit.orbit[0])) {
        // Create a new coe
        set((state) => {
          state.orbits[id].coe = new COE({ ...updatedOrbit.orbit[0] });
        });

        // Update the keplerian orbit values
        makeKeplerianOrbit(numSegments);
      }
    },
    updateOrbitCOE: (coeData) => {
      set((state) => {
        const previousData = state.orbits[id].coe.getData();

        const newCOE = new COE({ ...previousData, ...coeData });
        state.orbits[id].coe = newCOE;

        // also need to update corresponding sv data
        const newSV = convertKeplarianToCartesian(newCOE);

        const orbitSV: OrbitObjectStateVector = {
          xPosition: newSV.x_position!,
          yPosition: newSV.y_position!,
          zPosition: newSV.z_position!,
          xVelocity: newSV.x_velocity!,
          yVelocity: newSV.y_velocity!,
          zVelocity: newSV.z_velocity!,
        };
        state.orbits[id].stateVectors = orbitSV;
      });

      const { orbits } = get();
      const { makeKeplerianOrbit } = orbits[id];

      makeKeplerianOrbit();
    },
    updateOrbitSV: (svData) => {
      set((state) => {
        const newSVData = { ...state.orbits[id].stateVectors, ...svData } as OrbitObjectStateVector;

        // update corresponding coe data
        const newCOE = convertCartesianToKeplerian(
          new Vector3(newSVData.xPosition, newSVData.yPosition, newSVData.zPosition),
          new Vector3(newSVData.xVelocity, newSVData.yVelocity, newSVData.zVelocity),
        );

        Object.entries(newCOE).forEach(([key, value]) => {
          if (Math.abs(value) < CONVERSION_TOLERANCE) {
            newCOE[key as keyof COEDataOnly] = 0;
          }
        });

        newCOE.argumentOfPeriapsis = MathUtils.radToDeg(newCOE.argumentOfPeriapsis);
        newCOE.inclination = MathUtils.radToDeg(newCOE.inclination);
        newCOE.rightAscensionOfAscendingNode = MathUtils.radToDeg(
          newCOE.rightAscensionOfAscendingNode,
        );
        newCOE.trueAnomaly = MathUtils.radToDeg(newCOE.trueAnomaly);

        state.orbits[id].stateVectors = newSVData;

        const previousData = state.orbits[id].coe.getData();
        state.orbits[id].coe = new COE({ ...previousData, ...newCOE });
      });

      const { orbits } = get();
      const { makeKeplerianOrbit } = orbits[id];

      makeKeplerianOrbit();
    },
    updateOrbitType: (orbitType: string) => {
      set((state) => {
        state.orbits[id].orbitType = orbitType;
      });
    },
    updateOrbitGroupId: (groupId: number | null) => {
      set((state) => {
        state.orbits[id].groupId = groupId;
      });
    },
    deleteOrbitState: () => {
      const { deleteOrbitState } = get();

      deleteOrbitState(id);
    },
    setDisplayedCallouts: (currentBookmarks: Bookmark[]) => {
      const currentTime = getCurrentTime();
      set((state) => {
        state.orbits[id].displayedCallouts = [
          ...(currentBookmarks?.filter(
            (mark) => mark.timestamp.getTime() === currentTime && mark.orbitId === id,
          ) ?? []),
        ];
      });
    },
    activeStateVector: null,
    setActiveStateVector: (activeSV: StateVectorType | null) => {
      set((state) => {
        state.orbits[id].activeStateVector = activeSV;
      });
    },
  };
};

export const initializeOrbitInStore = (newOrbit: OrbitObject) => {
  const { createOrbitState } = use3DOrbitStore.getState();
  createOrbitState(newOrbit, 1);
  const { orbits: orbitsState } = use3DOrbitStore.getState();
  if (orbitsState[newOrbit.id]) {
    orbitsState[newOrbit.id].initializeOrbitState();
  }
};
