import { Grid, styled } from '@mui/material';

export const ControlsContainer = styled('div')`
  position: absolute;
  top: 20px;
  right: 80px;
  display: flex;
  align-items: flex-end;
  margin: 8px;
  gap: 8px;
  z-index: 1;
`;

export const ButtonControls = styled('div')`
  background: ${(props) => props.theme.palette.background.paper};
  border-radius: 4px;
  flex-direction: row;
`;

export const RicRefGridScaleValueDisplay = styled(Grid)`
  text-align: center;
  width: 100px;
  border-bottom: solid 1px rgba(255, 255, 255, 0.7);
  font-size: 13px;
  padding-bottom: 3px;
  &:after {
    content: '';
    background: rgba(255, 255, 255, 0.7);
    position: absolute;
    bottom: 0;
    left: 0;
    height: 25%;
    width: 1px;
  }
  &:before {
    content: '';
    background: rgba(255, 255, 255, 0.7);
    position: absolute;
    bottom: 0;
    right: 0;
    height: 25%;
    width: 1px;
  }
`;

export const ControlsCamera = styled('div')`
  position: absolute;
  top: 100%;
  left: 0;
  background: ${(props) => props.theme.palette.background.paper};
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  display: flex;
  flex-direction: column;
  box-shadow: 0px 4px 5px #202020;
`;
