import { Divider, FormControl, Grid, MenuItem, Select, Switch, Typography } from '@mui/material';
import { MutableRefObject, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { muEarth } from 'src/constants';
import { getCurrentTime, getPlayState } from 'src/core/getters';
import useAppStore from 'src/core/store';
import { useCurrentOrbit, useCurrentOrbits, useIsPropagating } from 'src/hooks/OrbitHooks';
import { useSunPosition } from 'src/hooks/SunPositionHooks';
import theme from 'src/pages/App/Theme';
import { LabelWithTooltip } from 'src/pages/Shared/LabelWithTooltip';
import use3DOrbitStore from 'src/threejs/components/OrbitManager/store/store';
import { COE } from 'src/threejs/models/COE';
import { sortByName } from 'src/utilities/ArrayUtils';
import { convertKeplarianToCartesian } from 'src/utilities/Keplerian';
import { radToDeg } from 'src/utilities/MathUtils';
import { getPositionAndVelocityChaserInTargetRIC } from 'src/utilities/OrbitUtilsRIC';
import { formatValue, fractionDigitOptions } from 'src/utilities/StringUtils';
import { Vector3 } from 'three';
import { LiveDataRelativeItem } from './LiveDataRelativeItem';

export const LiveDataRelative = () => {
  const targetOrbit = useCurrentOrbit();
  const currentOrbits = useCurrentOrbits();
  const [isMeters, setMeters] = useState(false);
  const HEADER_UNITS = isMeters ? 'm' : 'km';
  const activeOrbit = useCurrentOrbit();
  const orbitsToDisplay = useMemo(
    () => currentOrbits?.filter((orb) => orb.id !== activeOrbit?.id).sort(sortByName) ?? [],
    [activeOrbit?.id, currentOrbits],
  );
  const [selectedChaserOrbitId, setSelectedChaserOrbitId] = useState(
    `${orbitsToDisplay?.[0]?.id ?? 'none'}`,
  );
  const chaserOrbitId = Number.isNaN(parseInt(selectedChaserOrbitId, 10))
    ? null
    : parseInt(selectedChaserOrbitId, 10);
  const chaserOrbit = chaserOrbitId ? currentOrbits?.find((orb) => orb.id === chaserOrbitId) : null;
  const isPropagating = useIsPropagating();

  const onActiveSvChange = useCallback(
    (
      newVal: number,
      ref: MutableRefObject<HTMLDivElement | null>,
      unit?: string,
      options?: fractionDigitOptions,
    ) => {
      if (ref?.current) ref.current.innerText = `${formatValue(newVal, options)} ${unit ?? ''}`;
    },
    [],
  );

  const positionTarget = useRef<Vector3>(new Vector3());
  const velocityTarget = useRef<Vector3>(new Vector3());
  const positionChaser = useRef<Vector3>(new Vector3());
  const velocityChaser = useRef<Vector3>(new Vector3());
  const positionSun = useRef<Vector3>(new Vector3());

  const currentTime = getCurrentTime();
  const initialSunPosition = useSunPosition(
    new Date(currentTime),
    {
      enabled: getPlayState() === 'stopped',
    },
    currentTime === 0,
  );
  const origin = useMemo(() => new Vector3(0, 0, 0), []);

  const targetCatsOrbitRef = useRef<HTMLDivElement | null>(null);
  const chaserCatsOrbitRef = useRef<HTMLDivElement | null>(null);
  const range = useRef<HTMLDivElement | null>(null);
  const posRadial = useRef<HTMLDivElement | null>(null);
  const posIntrack = useRef<HTMLDivElement | null>(null);
  const posCrosstrack = useRef<HTMLDivElement | null>(null);
  const speed = useRef<HTMLDivElement | null>(null);
  const velRadial = useRef<HTMLDivElement | null>(null);
  const velIntrack = useRef<HTMLDivElement | null>(null);
  const velCrosstrack = useRef<HTMLDivElement | null>(null);

  const handleDisplayUpdate = useCallback(() => {
    // CATS angles
    if (targetCatsOrbitRef?.current && chaserCatsOrbitRef?.current) {
      const catsAngleForChaser = positionTarget.current
        .clone()
        .sub(positionChaser.current)
        .angleTo(positionSun.current.clone().sub(positionChaser.current));
      const catsAngleForTargetMeters = positionChaser.current
        .clone()
        .sub(positionTarget.current)
        .angleTo(positionSun.current.clone().sub(positionTarget.current));

      onActiveSvChange(radToDeg(catsAngleForChaser), targetCatsOrbitRef, '°');
      onActiveSvChange(radToDeg(catsAngleForTargetMeters), chaserCatsOrbitRef, '°');
    }

    // range
    if (range?.current) {
      const rangeVector = new Vector3().subVectors(positionTarget.current, positionChaser.current);
      onActiveSvChange(rangeVector.length(), range, '');
    }

    // ric position and velocity
    if (positionTarget.current && velocityTarget.current && positionChaser && velocityChaser) {
      const gravitationalParam = isMeters ? muEarth * 1000 ** 3 : muEarth;
      const positionAndVelocityChaserInTargetRIC = getPositionAndVelocityChaserInTargetRIC(
        positionTarget.current,
        velocityTarget.current,
        positionChaser.current,
        velocityChaser.current,
        gravitationalParam,
      );

      onActiveSvChange(positionAndVelocityChaserInTargetRIC.position.x, posRadial, '');
      onActiveSvChange(positionAndVelocityChaserInTargetRIC.position.y, posIntrack, '');
      onActiveSvChange(positionAndVelocityChaserInTargetRIC.position.z, posCrosstrack, '');

      onActiveSvChange(positionAndVelocityChaserInTargetRIC.velocity.x, velRadial, '');
      onActiveSvChange(positionAndVelocityChaserInTargetRIC.velocity.y, velIntrack, '');
      onActiveSvChange(positionAndVelocityChaserInTargetRIC.velocity.z, velCrosstrack, '');

      onActiveSvChange(positionAndVelocityChaserInTargetRIC.velocity.length(), speed, '', {
        maximumFractionDigits: 6,
        minimumFractionDigits: 6,
      });
    }
  }, [isMeters, onActiveSvChange]);

  const handleValueUpdate = useCallback(() => {
    if (!targetOrbit?.id || !chaserOrbit?.id) return;

    // if show in meters need to multiple the standard km units by 1000
    const metersFactor = isMeters ? 1000 : 1;

    const currentPlayState = useAppStore.getState().timelines.timelineRange.playState;
    if (isPropagating && (currentPlayState === 'playing' || currentPlayState === 'stopped')) {
      const targetActiveSv = use3DOrbitStore.getState().orbits[targetOrbit.id].activeStateVector;
      const chaserActiveSv = use3DOrbitStore.getState().orbits[chaserOrbit.id].activeStateVector;

      if (targetActiveSv) {
        positionTarget.current = new Vector3(
          targetActiveSv.x_position,
          targetActiveSv.y_position,
          targetActiveSv.z_position,
        ).multiplyScalar(metersFactor / 1000);
        velocityTarget.current = new Vector3(
          targetActiveSv.x_velocity,
          targetActiveSv.y_velocity,
          targetActiveSv.z_velocity,
        ).multiplyScalar(metersFactor / 1000);
        positionSun.current = new Vector3(
          targetActiveSv.sunPosition.x_position,
          targetActiveSv.sunPosition.y_position,
          targetActiveSv.sunPosition.z_position,
        ).multiplyScalar(metersFactor);
      }

      if (chaserActiveSv) {
        positionChaser.current = new Vector3(
          chaserActiveSv.x_position,
          chaserActiveSv.y_position,
          chaserActiveSv.z_position,
        ).multiplyScalar(metersFactor / 1000);
        velocityChaser.current = new Vector3(
          chaserActiveSv.x_velocity,
          chaserActiveSv.y_velocity,
          chaserActiveSv.z_velocity,
        ).multiplyScalar(metersFactor / 1000);
      }
    } else {
      const targetCOE = new COE(targetOrbit.orbit[0]);
      const chaserCOE = new COE(chaserOrbit.orbit[0]);

      if (!targetCOE || !chaserCOE) return;

      const targetSV = convertKeplarianToCartesian(targetCOE);
      if (targetSV) {
        positionTarget.current = new Vector3(
          targetSV.x_position,
          targetSV.y_position,
          targetSV.z_position,
        ).multiplyScalar(metersFactor);
        velocityTarget.current = new Vector3(
          targetSV.x_velocity,
          targetSV.y_velocity,
          targetSV.z_velocity,
        ).multiplyScalar(metersFactor);
      }

      const chaserSV = convertKeplarianToCartesian(chaserCOE);
      if (chaserSV) {
        positionChaser.current = new Vector3(
          chaserSV.x_position,
          chaserSV.y_position,
          chaserSV.z_position,
        ).multiplyScalar(metersFactor);
        velocityChaser.current = new Vector3(
          chaserSV.x_velocity,
          chaserSV.y_velocity,
          chaserSV.z_velocity,
        ).multiplyScalar(metersFactor);
      }

      positionSun.current = initialSunPosition
        ? new Vector3(
            initialSunPosition.x_position,
            initialSunPosition.y_position,
            initialSunPosition.z_position,
          ).multiplyScalar(metersFactor)
        : origin;
    }

    handleDisplayUpdate();
  }, [
    isMeters,
    isPropagating,
    origin,
    initialSunPosition,
    targetOrbit?.id,
    chaserOrbit?.id,
    handleDisplayUpdate,
    targetOrbit?.orbit,
    chaserOrbit?.orbit,
  ]);

  useEffect(() => {
    return useAppStore.subscribe(
      (state) => state.timelines.timelineRange.currentTime,
      () => {
        handleValueUpdate();
      },
    );
  }, [handleValueUpdate, handleDisplayUpdate]);

  // handle initial load and when clearing propagation
  useEffect(() => {
    if (!isPropagating) {
      handleValueUpdate();
    }
  }, [isPropagating, handleValueUpdate]);

  // handle changing observation target or changing between kilometers and meters
  useEffect(() => {
    handleValueUpdate();
  }, [selectedChaserOrbitId, isMeters, handleValueUpdate]);

  if (!targetOrbit?.id) return null;

  return (
    <Grid
      display="grid"
      gridAutoFlow="row"
      alignItems="center"
      pt={1.5}
    >
      <Typography
        variant="h6"
        fontWeight="bold"
        fontSize="small"
      >
        Secondary Object
      </Typography>

      <FormControl
        variant="standard"
        sx={{
          pt: 0.5,
        }}
      >
        <Select
          size="small"
          value={selectedChaserOrbitId}
          onChange={(event) => {
            setSelectedChaserOrbitId(event.target.value);
          }}
          sx={{
            color: theme.palette.text.secondary,
          }}
          label="Secondary Object"
        >
          <MenuItem value="none">None</MenuItem>

          {orbitsToDisplay?.map((orbit) => (
            <MenuItem
              key={orbit.id}
              value={orbit.id}
            >
              {orbit.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      <Typography
        variant="h6"
        fontSize="small"
        fontWeight="bold"
        pt={1.5}
      >
        <LabelWithTooltip
          label="CATS"
          title="Observer’s camera angle-target-sun."
        />
      </Typography>

      <LiveDataRelativeItem
        headerUnit=""
        name={chaserOrbit?.name ?? '--'}
        ref={chaserCatsOrbitRef}
      />
      <LiveDataRelativeItem
        headerUnit=""
        name={targetOrbit?.name ?? '--'}
        ref={targetCatsOrbitRef}
      />

      <Divider sx={{ pt: 1 }} />

      <LiveDataRelativeItem
        headerOnly
        tooltip="Relative distance to secondary object.  Magnitude of relative position vector."
        headerUnit={HEADER_UNITS}
        name="Range"
        ref={range}
      />

      <Grid
        display="grid"
        gridAutoFlow="column"
        alignItems="center"
        justifyContent="space-between"
        pt={1.5}
      >
        <Typography
          variant="h6"
          fontSize="small"
          fontWeight="bold"
        >
          <LabelWithTooltip
            label="Position"
            title="Relative position of secondary object in radial, in-track, cross-track components."
          />
        </Typography>

        <Grid
          fontSize={12}
          color={theme.palette.text.secondary}
        >
          {HEADER_UNITS}
        </Grid>
      </Grid>

      <LiveDataRelativeItem
        headerUnit={HEADER_UNITS}
        name="Radial"
        ref={posRadial}
      />
      <LiveDataRelativeItem
        headerUnit={HEADER_UNITS}
        name="Intrack"
        ref={posIntrack}
      />
      <LiveDataRelativeItem
        headerUnit={HEADER_UNITS}
        name="Crosstrack"
        ref={posCrosstrack}
      />

      <Divider sx={{ pt: 1 }} />

      <LiveDataRelativeItem
        tooltip="Relative speed of secondary object.  Magnitude of relative velocity vector."
        headerOnly
        headerUnit={`${HEADER_UNITS}/s`}
        name="Speed"
        ref={speed}
      />

      <Grid
        display="grid"
        gridAutoFlow="column"
        alignItems="center"
        justifyContent="space-between"
        pt={1.5}
      >
        <Typography
          variant="h6"
          fontSize="small"
          fontWeight="bold"
        >
          <LabelWithTooltip
            label="Velocity"
            title="Relative velocity of secondary object in radial, in-track, cross-track components."
          />
        </Typography>

        <Grid
          fontSize={12}
          color={theme.palette.text.secondary}
        >
          {`${HEADER_UNITS}/s`}
        </Grid>
      </Grid>

      <LiveDataRelativeItem
        headerUnit={`${HEADER_UNITS}/s`}
        name="Radial"
        ref={velRadial}
      />
      <LiveDataRelativeItem
        headerUnit={`${HEADER_UNITS}/s`}
        name="Intrack"
        ref={velIntrack}
      />
      <LiveDataRelativeItem
        headerUnit={`${HEADER_UNITS}/s`}
        name="Crosstrack"
        ref={velCrosstrack}
      />

      <Grid
        display="grid"
        gridAutoFlow="column"
        alignItems="center"
        justifyContent="end"
        pt={1.5}
        gap={0.5}
      >
        <Typography fontSize="small">km/s</Typography>

        <Switch
          size="small"
          checked={isMeters}
          onChange={() => setMeters(!isMeters)}
        />

        <Typography fontSize="small">m/s</Typography>
      </Grid>
    </Grid>
  );
};
