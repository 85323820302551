import { BookmarkAddRounded, BookmarkRounded, DeleteRounded } from '@mui/icons-material';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  FormControl,
  Grid,
  TextField,
  Typography,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { SpaceGlassContainerBase } from 'src/components/SpaceGlassControl';
import { getCurrentTime } from 'src/core/getters';
import { LABModalAction, LABModalActionType } from 'src/enums';
import { useCreateBookmark, useUpdateBookmark } from 'src/hooks/BookmarkHooks';
import { useCurrentOrbit } from 'src/hooks/OrbitHooks';
import { ModalHeader } from 'src/pages/Shared/ModalSpaceGlass';
import { Bookmark } from 'src/types';
import { millisToUTC } from 'src/utilities/DateTimeUtils';
import { Avatar } from './Avatar';
import { ModalDeleteBookmark } from './ModalDeleteBookmark';

import avatar01 from 'src/assets/avatars/Avatar-1.png';
import avatar10 from 'src/assets/avatars/Avatar-10.png';
import avatar11 from 'src/assets/avatars/Avatar-11.png';
import avatar02 from 'src/assets/avatars/Avatar-2.png';
import avatar03 from 'src/assets/avatars/Avatar-3.png';
import avatar04 from 'src/assets/avatars/Avatar-4.png';
import avatar05 from 'src/assets/avatars/Avatar-5.png';
import avatar06 from 'src/assets/avatars/Avatar-6.png';
import avatar07 from 'src/assets/avatars/Avatar-7.png';
import avatar08 from 'src/assets/avatars/Avatar-8.png';
import avatar09 from 'src/assets/avatars/Avatar-9.png';

import avatar12 from 'src/assets/avatars/Adversary.png';
import avatar13 from 'src/assets/avatars/AsteroidOP.png';
import avatar14 from 'src/assets/avatars/Astronaut1.png';
import avatar15 from 'src/assets/avatars/Astronaut2.png';
import avatar16 from 'src/assets/avatars/Astronaut3.png';
import avatar17 from 'src/assets/avatars/AstronautOP1.png';
import avatar18 from 'src/assets/avatars/AstronautOP2.png';
import avatar19 from 'src/assets/avatars/AstronautOP3.png';
import avatar20 from 'src/assets/avatars/AstronautTS1.png';
import avatar21 from 'src/assets/avatars/AstronautTS2.png';
import avatar22 from 'src/assets/avatars/JupiterOP.png';
import avatar23 from 'src/assets/avatars/MeteorShower.png';
import avatar24 from 'src/assets/avatars/Military1.png';
import avatar25 from 'src/assets/avatars/MoonTS.png';
import avatar26 from 'src/assets/avatars/Operator.png';
import avatar27 from 'src/assets/avatars/Orbit.png';
import avatar28 from 'src/assets/avatars/Planetary.png';
import avatar29 from 'src/assets/avatars/RadarDish.png';
import avatar30 from 'src/assets/avatars/RadarDishOP.png';
import avatar31 from 'src/assets/avatars/Satellite_1.png';
import avatar32 from 'src/assets/avatars/SpaceForceGuardian.png';
import avatar33 from 'src/assets/avatars/SpaceForceGuardianOP.png';
import avatar34 from 'src/assets/avatars/SpaceForceGuardianTS.png';
import avatar35 from 'src/assets/avatars/SpaceForceOfficer.png';
import avatar36 from 'src/assets/avatars/SpaceForceOfficerOP.png';
import avatar37 from 'src/assets/avatars/SpaceForceOfficerTS.png';
import avatar38 from 'src/assets/avatars/SputnikOP.png';
import avatar39 from 'src/assets/avatars/SputnikTS.png';
import avatar40 from 'src/assets/avatars/Telescope.png';

import avatar41 from 'src/assets/avatars/HR_Satellite_Render_HD1_LowRes.jpg';
import avatar42 from 'src/assets/avatars/HR_Satellite_Render_HD2_LowRes.jpg';
import avatar43 from 'src/assets/avatars/HR_Satellite_Render_HD3_LowRes.jpg';

export const avatarOptions = [
  {
    name: 'avatar41',
    src: avatar41,
  },
  {
    name: 'avatar42',
    src: avatar42,
  },
  {
    name: 'avatar43',
    src: avatar43,
  },

  {
    name: 'avatar01',
    src: avatar01,
  },
  {
    name: 'avatar02',
    src: avatar02,
  },
  {
    name: 'avatar03',
    src: avatar03,
  },
  {
    name: 'avatar04',
    src: avatar04,
  },
  {
    name: 'avatar05',
    src: avatar05,
  },
  {
    name: 'avatar06',
    src: avatar06,
  },
  {
    name: 'avatar07',
    src: avatar07,
  },
  {
    name: 'avatar08',
    src: avatar08,
  },
  {
    name: 'avatar09',
    src: avatar09,
  },
  {
    name: 'avatar10',
    src: avatar10,
  },
  {
    name: 'avatar11',
    src: avatar11,
  },

  {
    name: 'avatar12',
    src: avatar12,
  },
  {
    name: 'avatar13',
    src: avatar13,
  },
  {
    name: 'avatar14',
    src: avatar14,
  },
  {
    name: 'avatar15',
    src: avatar15,
  },
  {
    name: 'avatar16',
    src: avatar16,
  },
  {
    name: 'avatar17',
    src: avatar17,
  },
  {
    name: 'avatar18',
    src: avatar18,
  },
  {
    name: 'avatar19',
    src: avatar19,
  },

  {
    name: 'avatar20',
    src: avatar20,
  },
  {
    name: 'avatar21',
    src: avatar21,
  },
  {
    name: 'avatar22',
    src: avatar22,
  },
  {
    name: 'avatar23',
    src: avatar23,
  },
  {
    name: 'avatar24',
    src: avatar24,
  },
  {
    name: 'avatar25',
    src: avatar25,
  },
  {
    name: 'avatar26',
    src: avatar26,
  },
  {
    name: 'avatar27',
    src: avatar27,
  },
  {
    name: 'avatar28',
    src: avatar28,
  },
  {
    name: 'avatar29',
    src: avatar29,
  },

  {
    name: 'avatar30',
    src: avatar30,
  },
  {
    name: 'avatar31',
    src: avatar31,
  },
  {
    name: 'avatar32',
    src: avatar32,
  },
  {
    name: 'avatar33',
    src: avatar33,
  },
  {
    name: 'avatar34',
    src: avatar34,
  },
  {
    name: 'avatar35',
    src: avatar35,
  },
  {
    name: 'avatar36',
    src: avatar36,
  },
  {
    name: 'avatar37',
    src: avatar37,
  },
  {
    name: 'avatar38',
    src: avatar38,
  },
  {
    name: 'avatar39',
    src: avatar39,
  },

  {
    name: 'avatar40',
    src: avatar40,
  },
];

type ModalBookmarkProps = {
  editingBookmark?: Bookmark;
  action: LABModalActionType;
  onClose: VoidFunction;
};

export function ModalBookmark(props: ModalBookmarkProps) {
  const currentOrbit = useCurrentOrbit();
  const createBookmark = useCreateBookmark();
  const updateBookmark = useUpdateBookmark();
  const currentTime = getCurrentTime();

  const [modalVisible, setModalVisible] = useState(false);
  const [title, setTitle] = useState('None');
  const [submitTitle, setSubmitText] = useState('Submit');
  const [textValue, setTextValue] = useState('');
  const [avatar, setAvatar] = useState('');
  const [showDelete, setShowDelete] = useState<boolean>(false);
  const [confirmDeleteModalVisible, setConfirmDeleteModalVisible] = useState(false);

  useEffect(() => {
    switch (props.action) {
      case LABModalAction.CREATE:
        setTitle(`Add a Bookmark to ${currentOrbit?.name} @ ${millisToUTC(currentTime)}`);
        setTextValue('');
        setAvatar('');
        setSubmitText(props.action);
        setModalVisible(true);
        setShowDelete(false);
        break;

      case LABModalAction.EDIT:
        setTitle(`Bookmark on ${currentOrbit?.name} @ ${millisToUTC(currentTime)}`);
        setTextValue(props.editingBookmark?.callout || '');
        setAvatar(props.editingBookmark?.avatarImageUrl || '');
        setSubmitText('Save');
        setModalVisible(true);
        setShowDelete(true);
        break;

      default:
        break;
    }
  }, [currentOrbit, currentTime, props.action, props.editingBookmark]);

  const onCloseModalBookmark = () => {
    props.onClose();
    setModalVisible(false);
  };

  const onDelete = () => {
    setConfirmDeleteModalVisible(true);
    setModalVisible(false);
  };

  const onSubmit = () => {
    if (currentOrbit) {
      switch (props.action) {
        case LABModalAction.CREATE:
          createBookmark.mutate({
            orbitId: currentOrbit.id,
            timestamp: new Date(currentTime),
            callout: textValue,
            avatarImageUrl: avatar,
          });
          break;

        case LABModalAction.EDIT:
          if (props.editingBookmark) {
            updateBookmark.mutate({
              id: props.editingBookmark.id,
              orbitId: props.editingBookmark.orbitId,
              timestamp: props.editingBookmark.timestamp,
              callout: textValue,
              avatarImageUrl: avatar,
            });
          }
          break;

        default:
          break;
      }
    }

    onCloseModalBookmark();
  };

  const handleAvatarClick = (avatarOption = { name: '' }) => {
    setAvatar(avatarOption.name);
  };

  return (
    <>
      <ModalDeleteBookmark
        open={confirmDeleteModalVisible}
        show={setConfirmDeleteModalVisible}
        onClose={props.onClose}
        editingBookmark={props.editingBookmark}
      />
      <Dialog
        open={modalVisible}
        onClose={onCloseModalBookmark}
        fullWidth={true}
        aria-labelledby="max-width-dialog-title"
        maxWidth="md"
        PaperComponent={SpaceGlassContainerBase}
      >
        <ModalHeader
          title={title}
          onClose={onCloseModalBookmark}
          icon={LABModalAction.CREATE ? <BookmarkAddRounded /> : <BookmarkRounded />}
        />

        <DialogContent max-width="fit-content">
          <FormControl
            fullWidth
            variant="standard"
          >
            <Grid
              container
              alignItems="center"
            >
              <Grid
                item
                xs={12}
              >
                <TextField
                  fullWidth
                  multiline
                  rows={3}
                  value={textValue}
                  onChange={(event) => {
                    setTextValue(event.target.value);
                  }}
                />
              </Grid>
            </Grid>
          </FormControl>

          <Typography
            variant="h6"
            py={2}
          >
            Bookmark Avatar Image
          </Typography>

          <Grid
            container
            pt={1}
          >
            <Grid
              container
              item
              spacing={2}
              sx={{
                maxHeight: 255,
                overflowY: 'auto',
              }}
            >
              <Grid
                item
                onClick={() => handleAvatarClick()}
              >
                <Avatar selected={avatar === ''}>None</Avatar>
              </Grid>
              {avatarOptions.map((option, index) => {
                return (
                  <Grid
                    item
                    key={`avatar-${index}`}
                    onClick={() => handleAvatarClick(option)}
                  >
                    <Avatar
                      src={option.src}
                      selected={avatar === option.name}
                    />
                  </Grid>
                );
              })}
              {/* // TODO: implement adding custom avatar images
                            <Avatar><AddRounded fontSize="large" /></Avatar> */}
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          {showDelete && (
            <Button
              onClick={onDelete}
              startIcon={<DeleteRounded />}
            >
              DELETE
            </Button>
          )}

          <Button onClick={onCloseModalBookmark}>CANCEL</Button>

          <Button
            onClick={onSubmit}
            variant="contained"
            color="primary"
            disabled={textValue === ''}
          >
            {submitTitle}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
