import React, { useContext } from 'react';

type UserContextProviderProps = {
  children?: React.ReactNode;
};

type UserContextProviderState = {
  userId: number;
  username: string;
  fullname: string;
};

const UserContext = React.createContext<UserContextProviderState | null>(null);

/**
 * Stores user information
 */
class UserContextProvider extends React.Component<
  UserContextProviderProps,
  UserContextProviderState
> {
  constructor(props: {}) {
    super(props);

    this.state = {
      userId: 1,
      username: 'jsmith',
      fullname: 'John Smith',
    };
  }

  render() {
    return <UserContext.Provider value={this.state}>{this.props.children}</UserContext.Provider>;
  }
}

/**
 * A custom hook for using the `UserContext` that will throw an error if used outside
 * of an `UserContext` provider. This prevents from having to check if the `context` is
 * null in typescript.
 */
function useUser(): UserContextProviderState {
  const context = useContext(UserContext);

  if (!context) {
    throw new Error('`useUser` must be used inside of the `UserContext`.');
  }

  return context;
}

export { UserContextProvider, UserContext, useUser };
