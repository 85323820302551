import { useGLTF } from '@react-three/drei';
import gsap, { Linear } from 'gsap';
import { useEffect, useMemo, useState } from 'react';
import { Group } from 'three';

interface GroundObjectDishProps {
  scale?: number;
}

export const GroundObjectDish = ({ scale = 1 }: GroundObjectDishProps) => {
  const [dishRef, setDishRef] = useState<Group | null>(null);

  const model = useGLTF('/models/radio_dish.glb') as any;

  const modelDish = useMemo(() => model.nodes['Radar-Dish001'].clone(), [model]);

  const modelBasePlatform = useMemo(() => model.nodes['Cube001'].clone(), [model]);
  const modelBaseExtras = useMemo(() => model.nodes['Radar-Base001'].clone(), [model]);

  useEffect(() => {
    if (!dishRef) {
      return;
    }

    const gsapContainer = {
      rotation: 0,
    };
    gsap.fromTo(
      gsapContainer,
      {
        rotation: 0,
      },
      {
        rotation: -Math.PI * 2,
        duration: 30,
        repeat: -1,
        ease: Linear.easeNone,
        onUpdate: () => {
          dishRef.rotation.y = gsapContainer.rotation;
        },
      },
    );
  }, [dishRef]);

  return (
    <group
      dispose={null}
      scale={scale * 0.002}
      rotation={[Math.PI / 2, Math.PI / 2, 0]}
    >
      <group ref={setDishRef}>
        <primitive object={modelDish} />
      </group>

      <primitive object={modelBasePlatform} />
      <primitive object={modelBaseExtras} />
    </group>
  );
};
