import { MenuItem, Select, SelectChangeEvent } from '@mui/material';
import { useMemo } from 'react';
import { useCurrentGroundObjects } from 'src/hooks/GroundObjectHooks';
import { useCurrentGroups } from 'src/hooks/GroupHooks';
import { useCurrentOrbits } from 'src/hooks/OrbitHooks';
import { GroundObject, GroundObjectCategory, OrbitObject } from 'src/types';
import { isOrbitObject } from 'src/utilities/TypeUtils';
import { OBJECT_TYPES } from './ObjectListPanel';
import { sortByName } from 'src/utilities/ArrayUtils';

export const OBJECT_DROPDOWN = {
  KEY_NONE: 'none',
  KEY_DELIMITER: '_',
  KEY_UNGROUPED: 'ungrouped',
};

export interface SelectGroupOrObjectItem {
  type: (typeof OBJECT_TYPES)[keyof typeof OBJECT_TYPES];
  id: number;
}

export interface SelectGroupOrObjectProps {
  disabled?: boolean;

  /** Include all ground objects */
  includeGroundObjects?: boolean;
  /** Include ground objects of the categories in array, will override `includeGroundObjects` property */
  includeGroundObjectCategories?: Array<
    (typeof GroundObjectCategory)[keyof typeof GroundObjectCategory]
  >;
  includeGroups?: boolean;
  includeGroupsChildrenMatching?: boolean;
  includeOrbits?: boolean;

  onChange?: (result: SelectGroupOrObjectItem) => void;
  selectedItem?: SelectGroupOrObjectItem | null;
}

export const getValue = (type: (typeof OBJECT_TYPES)[keyof typeof OBJECT_TYPES], id: number) => {
  return `${type}${OBJECT_DROPDOWN.KEY_DELIMITER}${id}`;
};

export const SelectGroupOrObject = ({
  disabled = false,
  includeGroundObjects = false,
  includeGroundObjectCategories,
  includeGroups = false,
  includeGroupsChildrenMatching = true,
  includeOrbits = false,
  onChange,
  selectedItem,
}: SelectGroupOrObjectProps) => {
  const value = selectedItem
    ? getValue(selectedItem.type, selectedItem.id)
    : OBJECT_DROPDOWN.KEY_NONE;

  const currentOrbits = useCurrentOrbits();
  const currentGroundObjects = useCurrentGroundObjects();
  const currentGroups = useCurrentGroups();

  const groupedObjects = useMemo(() => {
    const result: Record<number | string, (OrbitObject | GroundObject)[]> = {};
    result[OBJECT_DROPDOWN.KEY_UNGROUPED] = [];

    if (includeOrbits) {
      currentOrbits?.forEach((object) => {
        if (object.groupId) {
          if (!result[object.groupId]) result[object.groupId] = [];
          result[object.groupId].push(object);
        } else {
          result[OBJECT_DROPDOWN.KEY_UNGROUPED].push(object);
        }
      });
    }

    if (includeGroundObjects || includeGroundObjectCategories) {
      currentGroundObjects?.forEach((object) => {
        if (
          !includeGroundObjectCategories ||
          includeGroundObjectCategories.includes(object.category)
        ) {
          if (object.groupId) {
            if (!result[object.groupId]) result[object.groupId] = [];
            result[object.groupId].push(object);
          } else {
            result[OBJECT_DROPDOWN.KEY_UNGROUPED].push(object);
          }
        }
      });
    }

    // sort each value in the object
    Object.values(result).forEach((objects) => {
      objects.sort(sortByName);
    });

    return result;
  }, [
    currentGroundObjects,
    currentOrbits,
    includeGroundObjectCategories,
    includeGroundObjects,
    includeOrbits,
  ]);

  return (
    <Select
      disabled={disabled}
      fullWidth
      defaultValue={OBJECT_DROPDOWN.KEY_NONE}
      value={value}
      onChange={(event: SelectChangeEvent) => {
        const [objectType, objectId] = event.target.value.split(OBJECT_DROPDOWN.KEY_DELIMITER);
        if (onChange) {
          onChange({
            type: objectType,
            id: parseFloat(objectId),
          });
        }
      }}
    >
      <MenuItem
        key={OBJECT_DROPDOWN.KEY_NONE}
        value={OBJECT_DROPDOWN.KEY_NONE}
      >
        None
      </MenuItem>

      {currentGroups?.sort(sortByName).map((group) => {
        const items = [];
        if (includeGroups || (includeGroupsChildrenMatching && groupedObjects[group.id])) {
          const value = getValue(OBJECT_TYPES.GROUP, group.id);
          items.push(
            <MenuItem
              key={value}
              disabled={!includeGroups && includeGroupsChildrenMatching}
              value={value}
            >
              {group.name}
            </MenuItem>,
          );
        }
        if (includeGroupsChildrenMatching && groupedObjects[group.id]) {
          groupedObjects[group.id].forEach((object) => {
            const objectType = isOrbitObject(object) ? OBJECT_TYPES.ORBIT : OBJECT_TYPES.GROUND;
            const value = getValue(objectType, object.id!);

            items.push(
              <MenuItem
                key={value}
                value={value}
              >
                {`— ${object.name}`}
              </MenuItem>,
            );
          });
        }

        return items;
      })}

      {groupedObjects[OBJECT_DROPDOWN.KEY_UNGROUPED].map((object) => {
        const objectType = isOrbitObject(object) ? OBJECT_TYPES.ORBIT : OBJECT_TYPES.GROUND;
        const value = getValue(objectType, object.id!);

        return (
          <MenuItem
            key={value}
            value={value}
          >
            {object.name}
          </MenuItem>
        );
      })}
    </Select>
  );
};
