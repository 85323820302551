import { styled } from '@mui/material';

export const Checkboxes = styled('div')`
  display: grid;
  grid-template-columns: auto 1fr;
  grid-auto-rows: 24px;
  align-items: center;
`;

interface LabelProps {
  disabled?: boolean;
}
export const Label = styled('div')<LabelProps>(
  ({ theme, disabled }) => `
    font-size: 12px;
    ${
      disabled &&
      `
      color: ${theme.palette.text.disabled};
    `
    }
  `,
);
