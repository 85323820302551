import { Grid, styled } from '@mui/material';

interface ListItemProps {
  isactive?: boolean;
  isdroppable?: boolean;
}
export const PanelListItem = styled('div', {
  shouldForwardProp: (prop) => prop !== 'isactive' && prop !== 'isdroppable',
})<ListItemProps>`
  align-items: center;
  font-size: 12px;
  font-variation-settings: 'wght' 500;
  cursor: pointer;
  margin-bottom: 1px;

  button {
    padding: 4px 8px;
  }

  :hover {
    background: rgba(13, 54, 78, 0.6);
  }

  ${({ isactive }) =>
    isactive &&
    `
    background: rgba(13, 54, 78, 0.6);
  `}

  ${({ isdroppable, theme }) =>
    isdroppable &&
    `
    background: ${theme.palette.success.main};
  `}
`;

export const PanelGroupItem = styled(PanelListItem, {
  shouldForwardProp: (prop) => prop !== 'isactive' && prop !== 'isdroppable',
})<ListItemProps>`
  font-size: 14px;
  font-weight: bold;

  ${({ isdroppable }) =>
    isdroppable &&
    `
    background: none;
  `}
`;

export const GroupContainerStyled = styled(Grid, {
  shouldForwardProp: (prop) => prop !== 'isactive' && prop !== 'isdroppable',
})<Partial<ListItemProps>>`
  ${({ isdroppable, theme }) =>
    isdroppable &&
    `
    background: ${theme.palette.success.main};
  `}
`;

export const ShowOnHover = styled(Grid)`
  .showOnHover {
    visibility: hidden;
    display: none;
  }

  :hover {
    .showOnHover {
      visibility: visible;
      display: block;
    }
  }
`;
