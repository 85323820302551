import { DEFAULT_ADDITIONAL_PROPERTIES_ORBIT } from 'src/constants';
import { OrbitObject } from 'src/types';
import { AngularMomentumVector } from './components/AngularMomentumVector';
import { AscendingNodeVector } from './components/AscendingNodeVector';
import { EccentricityVector } from './components/EccentricityVector';
import { PositionVelocityVectors } from './components/PositionVelocityVectors';

interface VectorVisualAidsProps {
  orbit: OrbitObject;
}

export const VectorVisualAids = ({ orbit }: VectorVisualAidsProps) => {
  const positionVis =
    orbit.additionalProperties?.visPositionVec ||
    DEFAULT_ADDITIONAL_PROPERTIES_ORBIT.visPositionVec;
  const velocityVis =
    orbit.additionalProperties?.visVelocityVec ||
    DEFAULT_ADDITIONAL_PROPERTIES_ORBIT.visVelocityVec;

  const angularMomentumVis =
    orbit.additionalProperties?.visAngularMomentumVec ||
    DEFAULT_ADDITIONAL_PROPERTIES_ORBIT.visAngularMomentumVec;
  const eccentricityVis =
    orbit.additionalProperties?.visEccentricityVec ||
    DEFAULT_ADDITIONAL_PROPERTIES_ORBIT.visEccentricityVec;
  const ascendingNodeVis =
    orbit.additionalProperties?.visAscendingNodeVec ||
    DEFAULT_ADDITIONAL_PROPERTIES_ORBIT.visAscendingNodeVec;

  return (
    <>
      {(positionVis || velocityVis) && (
        <PositionVelocityVectors
          orbitId={orbit.id}
          positionVis={positionVis}
          velocityVis={velocityVis}
        />
      )}
      {angularMomentumVis && <AngularMomentumVector orbitId={orbit.id} />}
      {eccentricityVis && <EccentricityVector orbitId={orbit.id} />}
      {ascendingNodeVis && <AscendingNodeVector orbitId={orbit.id} />}
    </>
  );
};
