import { SETTINGS_NAMES } from '../Settings/store';
import { MaxFramerate } from './MaxFramerate';
import { ToggleSettingItem, ToggleSettings } from './ToggleSettings';

const toggles: ToggleSettingItem[] = [
  {
    name: 'Stats Overlay',
    settingName: SETTINGS_NAMES.STATS_OVERLAY_ENABLED,
  },
  {
    name: 'Perf Overlay',
    settingName: SETTINGS_NAMES.PERF_OVERLAY_ENABLED,
  },
];

export function DeveloperOnlyFeatures() {
  const features = [];

  features.push(<MaxFramerate key="MaxFramerate" />);

  return (
    <>
      <ToggleSettings
        title="Overlay Features"
        settings={toggles}
      />

      {features.length === 0 ? null : features}
    </>
  );
}
