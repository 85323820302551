import { useCallback } from 'react';
import { useCurrentGroups } from 'src/hooks/GroupHooks';
import { useCurrentOrbits } from 'src/hooks/OrbitHooks';
import { OrbitObject } from 'src/types';
import { GroundTrack } from './GroundTrack';

export const GroundTracks = () => {
  const currentOrbits = useCurrentOrbits();

  const currentGroups = useCurrentGroups();

  const isOrbitVisible = useCallback(
    (orbit: OrbitObject) => {
      const orbitGroup = currentGroups?.find((group) => group.id === orbit.groupId);

      const groupVisible = orbit.groupId === null || orbitGroup?.additionalProperties?.visOrbits;

      return (
        groupVisible &&
        orbit.additionalProperties?.visOrbit &&
        orbit.additionalProperties.visGroundTracks
      );
    },
    [currentGroups],
  );

  return (
    <>
      {currentOrbits?.map((orbit) => {
        if (isOrbitVisible(orbit)) {
          return (
            <GroundTrack
              key={orbit.id}
              orbit={orbit}
            />
          );
        }
        return null;
      })}
    </>
  );
};
