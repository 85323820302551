import { useCallback, useMemo, useState } from 'react';
import MarkerManipulator, { MarkerMoveProps } from 'src/threejs/components/MarkerManipulator';
import { UpdateSpriteProps } from 'src/threejs/components/MarkerManipulator/MarkerManipulator';
import {
  createGetOrbitCOE,
  createGetOrbitData,
} from 'src/threejs/components/OrbitManager/store/getters';
import useCamera from 'src/threejs/hooks/useCamera';
import { COE } from 'src/threejs/models/COE';
import ManipulatorPlaneModel from 'src/threejs/models/ManipulatorPlane';
import { MarkerHandleProps } from 'src/types';
import { clamp } from 'src/utilities/MathUtils';
import { AdditiveBlending, Group, Matrix4, Mesh, Shape, Sprite, Vector3 } from 'three';
import { use3DOrbitContext } from '../../context';

const config = {
  incHandleOffset: 0.4,
  smaHandlePerigeeOffset: 0.7, // .25,
  incHandleRotationSpeed: 3,
};

// Arc Handle
const arcShape = new Shape()
  .absarc(0, -5, 5.83, (59.04 * Math.PI) / 180, (120.96 * Math.PI) / 180, false)
  .lineTo(-4, 0.08)
  .lineTo(-3.6, 1)
  .absarc(0, -5, 7, (120.96 * Math.PI) / 180, (59.04 * Math.PI) / 180, true)
  .lineTo(4, 0.08)
  .lineTo(3, 0);

const extrudeSettings = {
  depth: 0.25,
  bevelEnabled: false,
  bevelSegments: 1,
  steps: 1,
  bevelSize: 1,
  bevelThickness: 1,
};

const IncMarkerHandle = ({ handle, setHandle }: MarkerHandleProps) => {
  const { id } = use3DOrbitContext();
  const camera = useCamera();

  const tempVector = useMemo(() => new Vector3(0, 0, 0), []);
  const tempVector2 = useMemo(() => new Vector3(0, 0, 0), []);
  const tempVector3 = useMemo(() => new Vector3(0, 0, 0), []);
  const tempMatrix = useMemo(() => new Matrix4(), []);

  const getOrbitCOE = useMemo(() => createGetOrbitCOE(id), [id]);
  const getOrbitData = useMemo(() => createGetOrbitData(id), [id]);

  const textSpriteProps = useMemo(
    () => ({
      fontsize: 40,
      borderColor: { r: 255, g: 255, b: 255, a: 1.0 },
      backgroundColor: { r: 255, g: 255, b: 255, a: 0.1 },
      textColor: { r: 0, g: 255, b: 255, a: 0.75 },
      borderThickness: 0,
      sizeAttenuation: false,
    }),
    [],
  );

  const [group, setGroup] = useState<Group | null>(null);
  const [bounds, setBounds] = useState<Mesh | null>(null);
  const [sprite, setSprite] = useState<Sprite | null>(null);
  const [plane, setPlane] = useState<ManipulatorPlaneModel | null>(null);

  const makeSpriteName = (coe: COE) => `i: ${coe.inclination.toFixed(3)}`;

  const move = useCallback(
    ({ pointOnPlaneOffset, tempVector, tempVector2 }: MarkerMoveProps) => {
      const coe = getOrbitCOE();
      const orbitData = getOrbitData();

      tempVector2.copy(orbitData.orbitalPlaneVector).normalize();

      //Project along plane normal
      tempVector.copy(pointOnPlaneOffset).projectOnVector(tempVector2);

      // get the magnitude
      let rotationangle = tempVector.length() * config.incHandleRotationSpeed;

      // adjust for the direction
      rotationangle *= tempVector.dot(tempVector2) >= 0 ? 1 : -1;

      const inclination = clamp(coe.inclination + rotationangle, 0, 180);

      return { inclination };
    },
    [getOrbitCOE, getOrbitData],
  );

  const updateBounds = () => {
    if (bounds && handle) {
      const orbitData = getOrbitData();

      tempVector
        .copy(orbitData.inclinationRightAnglePosition)
        .normalize()
        .multiplyScalar(config.smaHandlePerigeeOffset);

      bounds.position.copy(orbitData.inclinationRightAnglePosition).add(tempVector);
    }
  };

  const updateSprite = ({ tempVector2 }: UpdateSpriteProps) => {
    if (sprite && handle) {
      const orbitData = getOrbitData();

      tempVector.copy(orbitData.inclinationRightAnglePosition).normalize().multiplyScalar(0.5);

      sprite.position.copy(handle.position).add(tempVector2).add(tempVector);
    }
  };

  const updateTransform = () => {
    if (handle && plane) {
      const orbitData = getOrbitData();

      // offset handle from perigee position
      tempVector
        .copy(orbitData.inclinationRightAnglePosition)
        .normalize()
        .multiplyScalar(config.incHandleOffset);

      // move handle to offset position
      handle.position.copy(orbitData.inclinationRightAnglePosition).add(tempVector);

      // set orientation using matrix
      tempVector2 // x-axis
        .copy(orbitData.orbitalPlaneVector)
        .negate()
        .normalize();

      tempVector // y-axis
        .copy(orbitData.inclinationRightAnglePosition)
        .sub(orbitData.ellipseCenterPosition)
        .normalize();

      tempVector3 // z-axis
        .copy(tempVector2)
        .cross(tempVector)
        .normalize();

      tempMatrix.makeBasis(tempVector2, tempVector, tempVector3);

      handle.quaternion.setFromRotationMatrix(tempMatrix);

      // rotate the plane for the active handle
      plane.updateTransform(camera, handle);
    }
  };

  return (
    <MarkerManipulator
      move={move}
      textSpriteMessage="inc: "
      textSpriteProps={textSpriteProps}
      makeSpriteName={makeSpriteName}
      markerName="INC"
      setHandle={setHandle}
      handle={handle}
      setGroup={setGroup}
      setBounds={setBounds}
      setSprite={setSprite}
      setPlane={setPlane}
      plane={plane}
      group={group}
      scale={0.02}
      updateBounds={updateBounds}
      updateSprite={updateSprite}
      updateTransform={updateTransform}
      manipulatorPlaneRotation
    >
      <extrudeGeometry args={[arcShape, extrudeSettings]} />
      <meshBasicMaterial
        color={0x00ffff}
        depthTest={false}
        depthWrite={false}
        transparent={true}
        opacity={0.5}
        toneMapped={false}
        blending={AdditiveBlending}
      />
    </MarkerManipulator>
  );
};

export default IncMarkerHandle;
