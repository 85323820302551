import { useCallback } from 'react';
import { Button, Dialog, DialogActions, DialogContent, Typography } from '@mui/material';
import { DeleteRounded } from '@mui/icons-material';
import { partial } from 'lodash';

import { useDeleteAllCopyCapabilitiesMutation } from 'src/hooks/ObjectHooks';
import { SpaceGlassContainerBase } from 'src/components/SpaceGlassControl';
import { ModalHeader } from 'src/pages/Shared/ModalSpaceGlass';
import { Capability } from 'src/types';

interface IModalRemoveAllUsersFromShareProps {
  open: boolean;
  show: (show: boolean) => void;
  capabilities: Capability[] | null | undefined;
}

export const ModalRemoveAllUsersFromShare = ({
  open,
  show,
  capabilities,
}: IModalRemoveAllUsersFromShareProps) => {
  const deleteAllCopyCapabilitiesMutation = useDeleteAllCopyCapabilitiesMutation();
  const hide = partial(show, false);

  const deleteItem = useCallback(() => {
    if (capabilities) {
      const ids = capabilities.map((capability: Capability) => capability.id);
      deleteAllCopyCapabilitiesMutation.mutate({ ids });
    }
    hide();
  }, [deleteAllCopyCapabilitiesMutation, capabilities, hide]);

  return (
    <Dialog
      fullWidth
      maxWidth="sm"
      open={open}
      onClose={hide}
      PaperComponent={SpaceGlassContainerBase}
    >
      <ModalHeader
        title="Are You Sure?"
        onClose={hide}
        icon={<DeleteRounded />}
      />
      <DialogContent>
        <Typography variant="body1">All users with shared access will be removed.</Typography>
      </DialogContent>
      <DialogActions>
        <Button
          type="button"
          onClick={hide}
        >
          CANCEL
        </Button>
        <Button
          color="error"
          variant="contained"
          onClick={deleteItem}
          aria-label="Delete Item"
        >
          Remove Users
        </Button>
      </DialogActions>
    </Dialog>
  );
};
