import {
  selectCurrentTime,
  selectPlaySpeed,
  selectPlayState,
  selectUpdateTimelineRange,
} from './selectors';
import useAppStore from './store';

export function getUpdateTimelineRange() {
  return selectUpdateTimelineRange(useAppStore.getState());
}

export function getPlaySpeed() {
  return selectPlaySpeed(useAppStore.getState());
}

export function getPlayState() {
  return selectPlayState(useAppStore.getState());
}

export function getCurrentTime() {
  return selectCurrentTime(useAppStore.getState());
}
