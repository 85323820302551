import { useEffect } from 'react';

/** A useEffect that only has a single dependency that triggers the effect. This prevents from having to use the eslint rule everywhere. */
function useSingleDependencyEffect<Dep = any>(
  callback: (dependency: Dep) => void | (() => void),
  dependency: Dep,
) {
  useEffect(() => {
    return callback(dependency);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dependency]);
}

export default useSingleDependencyEffect;
