import { AddRounded, DeleteRounded, EditRounded } from '@mui/icons-material';
import { Button, Dialog, DialogActions, DialogContent, Input, InputLabel } from '@mui/material';
import addDays from 'date-fns/addDays';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { SpaceGlassContainerBase } from 'src/components/SpaceGlassControl';
import { LABModalAction, LABModalActionType } from 'src/enums';
import { onChangeObjectName } from 'src/hooks/ModalObjectOps';
import { useCurrentNotebook } from 'src/hooks/ObjectHooks';
import {
  useCreatePageMutation,
  useCurrentPage,
  useCurrentPages,
  useUpdatePageMutation,
} from 'src/hooks/PageHooks';
import DeletePageModal from 'src/pages/Notebook/components/DeletePageModal';
import { CreateEditForm, DeleteForm } from 'src/pages/Notebook/components/ModelNotebookPage.styled';
import { ModalHeader } from 'src/pages/Shared/ModalSpaceGlass';
import { Page } from 'src/types';

import { useNavigate } from 'react-router-dom';

type ModalNotebookPageProps = {
  editingPage?: Page;
  action: LABModalActionType;
  onClose: VoidFunction;
};

/**
 * Modal popup for notebook pages
 */
export default function ModalNotebookPage(props: ModalNotebookPageProps) {
  const currentNotebook = useCurrentNotebook();
  const currentPage = useCurrentPage();
  const currentPages = useCurrentPages();

  const [pageModalVisible, setPageModalVisibility] = useState(false);
  const [title, setTitle] = useState('None');
  const [submitTitle, setSubmitText] = useState('Submit');
  const [objName, setName] = useState('');
  const [showDelete, setShowDelete] = useState<boolean>(false);
  const [confirmDeleteModalVisible, setConfirmDeletePageModalVisible] = useState(false);

  const createPage = useCreatePageMutation();
  const updatePage = useUpdatePageMutation();

  const navigate = useNavigate();

  const isDirty = useMemo(() => {
    return props.editingPage?.name !== objName;
  }, [props.editingPage, objName]);

  const nameError = useMemo(() => {
    return objName?.length === 0;
  }, [objName]);

  /**
   * Initialises the modal state based on the action type
   */
  useEffect(() => {
    const pageCount = (currentPages.length || 0) + 1;

    switch (props.action) {
      case LABModalAction.CREATE:
        setTitle(props.action);
        setName(`Page ${pageCount}`);
        setSubmitText(props.action);
        setPageModalVisibility(true);
        setShowDelete(false);
        break;

      case LABModalAction.EDIT:
        setTitle(props.action);
        setName(props.editingPage?.name || ' ');
        setSubmitText('Save');
        setPageModalVisibility(true);
        setShowDelete(true);
        break;

      default:
        break;
    }
  }, [props.action, currentPages, props.editingPage?.name]);

  /**
   * Submits a page to be created or edited
   */
  const onSubmit = () => {
    if (currentNotebook && currentPage) {
      const startTime = new Date();

      switch (props.action) {
        case LABModalAction.CREATE:
          createPage.mutate(
            {
              ...currentPage,
              folderId: currentNotebook.id,
              name: objName,
              startTime: startTime,
              endTime: addDays(startTime, 1),
            },
            {
              onSuccess: (newPage: Page) => {
                // redirect to the newly created page once it has finished being created.
                // only owned notebook pages can be created,
                // therefore shared pages currently do not need this check.
                if (newPage) navigate(`/notebook/${currentNotebook?.id}/${newPage.id}`);
              },
            },
          );
          break;
        case LABModalAction.EDIT:
          if (props.editingPage) {
            updatePage.mutate({
              ...props.editingPage,
              name: objName,
            });
          }
          break;
        default:
          break;
      }
    }
    onClosePageModal();
  };

  /**
   * Opens confirmation modal
   */
  const onDelete = useCallback(() => {
    setConfirmDeletePageModalVisible(true);
    setPageModalVisibility(false);
  }, []);

  /**
   * Closes this modal
   */
  const onClosePageModal = () => {
    props.onClose();
    setPageModalVisibility(false);
  };

  return (
    <>
      <DeletePageModal
        open={confirmDeleteModalVisible}
        show={setConfirmDeletePageModalVisible}
        onClose={props.onClose}
        editingPage={props.editingPage}
      />
      <Dialog
        open={pageModalVisible}
        onClose={onClosePageModal}
        fullWidth={true}
        aria-labelledby="max-width-dialog-title"
        PaperComponent={SpaceGlassContainerBase}
        onKeyPress={(event) => {
          if (event.key === 'Enter' && !nameError) {
            onSubmit();
          }
        }}
      >
        <ModalHeader
          title={title}
          onClose={onClosePageModal}
          icon={props.action === LABModalAction.CREATE ? <AddRounded /> : <EditRounded />}
        />

        <DialogContent>
          {/* Name */}
          <CreateEditForm
            fullWidth
            variant="standard"
          >
            <InputLabel htmlFor="objectName">Name</InputLabel>
            <Input
              id="objectName"
              value={objName}
              onChange={(event) => {
                onChangeObjectName(event, setName);
              }}
            />
          </CreateEditForm>
        </DialogContent>

        <DialogActions>
          {/* Delete */}
          {showDelete && (
            <DeleteForm>
              <Button
                onClick={onDelete}
                startIcon={<DeleteRounded />}
              >
                DELETE
              </Button>
            </DeleteForm>
          )}
          <Button onClick={onClosePageModal}>CANCEL</Button>
          <Button
            onClick={onSubmit}
            variant="contained"
            color="primary"
            disabled={nameError || (props.action === LABModalAction.EDIT && !isDirty)}
          >
            {submitTitle}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
