import { Grid, Slide } from '@mui/material';
import { ReactNode, useRef } from 'react';
import { PanelHeader } from 'src/components/PanelHeader';
import { SpaceGlassContainerBase } from 'src/components/SpaceGlassControl';
import { useOnClickOutside } from 'usehooks-ts';

interface SlideOutPanelProps {
  children: ReactNode;
  open: boolean;
  title: string;
  onClickOutside?: () => void;
  ignoreClassName?: string;
}
export const SlideOutPanel = ({
  children,
  open,
  title,
  onClickOutside,
  ignoreClassName,
}: SlideOutPanelProps) => {
  const containerRef = useRef(null);
  const contentsRef = useRef(null);

  const handleClickOutside = (event: MouseEvent) => {
    if (
      onClickOutside &&
      ignoreClassName &&
      !(event.target as Element).classList.contains(ignoreClassName)
    ) {
      onClickOutside();
    }
  };

  useOnClickOutside(contentsRef, handleClickOutside);

  return (
    <div ref={containerRef}>
      <Slide
        direction="right"
        in={open}
        container={containerRef.current}
        mountOnEnter
        unmountOnExit
      >
        <div ref={contentsRef}>
          <SpaceGlassContainerBase
            sx={{
              borderBottomLeftRadius: 0,
              borderTopLeftRadius: 0,
            }}
          >
            <PanelHeader
              draggable={false}
              title={title}
              style={{
                fontSize: 12,
              }}
            />
            <Grid
              display="grid"
              gridAutoFlow="row"
              px={1.5}
              pb={1.5}
            >
              {children}
            </Grid>
          </SpaceGlassContainerBase>
        </div>
      </Slide>
    </div>
  );
};
