import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { PanelHeader } from 'src/components/PanelHeader';
import { SpaceGlassPanel } from 'src/components/SpaceGlassPanel';
import { ACCORDION_STATES } from 'src/enums';
import { getActiveSpecialId, useRouteStore } from 'src/pages/App/routes/store';
import { ExpandCollapseHeadersSwitch } from '../Settings/ExpandCollapseHeadersSwitch';
import { InspectorTabs } from './InspectorTabs';

export const InspectorPanel = () => {
  const specialId = useRouteStore(getActiveSpecialId);
  let panelHeaderRideSide: React.ReactNode;

  // If we are viewing the earth inspector panel, we need to add an accordion control for it.
  if (specialId && specialId === 'earth') {
    panelHeaderRideSide = (
      <ExpandCollapseHeadersSwitch
        accordionList={[
          ACCORDION_STATES.ACCORDION_EXPANDED_EARTH_LIGHTING,
          ACCORDION_STATES.ACCORDION_EXPANDED_EARTH_VISUAL,
        ]}
      />
    );
  }

  return (
    <SpaceGlassPanel
      header={
        <PanelHeader
          title="Inspector"
          rightSide={panelHeaderRideSide}
        />
      }
    >
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <InspectorTabs />
      </LocalizationProvider>
    </SpaceGlassPanel>
  );
};
