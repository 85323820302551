import { Mark } from '@mui/base';
import {
  ArrowLeftRounded,
  ArrowRightRounded,
  BookmarkRounded,
  BookmarksRounded,
  RocketLaunchRounded,
} from '@mui/icons-material';
import { SvgIcon, Tooltip } from '@mui/material';
import { MouseEvent, useCallback, useEffect, useMemo, useState } from 'react';
import { ReactComponent as ManeuverIcon } from 'src/assets/maneuvers.svg';
import {
  LAUNCH_FLYOUT_STATUS,
  LAUNCH_WINDOWS_COLOR_ACTIVE,
  MANEUVER_FLYOUT_STATUSES,
} from 'src/constants';
import { useClearLoadingPropagation, useTimeline } from 'src/core/hooks';
import useAppStore from 'src/core/store';
import { useBookmarks } from 'src/hooks/BookmarkHooks';
import { useCurrentLaunchEvents } from 'src/hooks/LaunchHooks';
import { useCurrentOrbits, useIsPropagating } from 'src/hooks/OrbitHooks';
import { useCurrentPage } from 'src/hooks/PageHooks';
import PropagatedCacheManager from 'src/models/PropagatedCacheManager';
import TimelineEventManager from 'src/models/TimelineEventManager';
import {
  getActiveGroundObjectId,
  getActiveObjectId,
  useRouteStore,
} from 'src/pages/App/routes/store';
import { SETTINGS_NAMES, useSettingsStore } from 'src/pages/Settings/store';
import { useGroundObjectLaunchEditStore } from 'src/threejs/components/GroundObjects/GroundObjectLaunchEditStore';
import { getMaxAvailableTime } from 'src/threejs/components/OrbitManager/store/getters';
import {
  Bookmark,
  Launch,
  Maneuver,
  OrbitObject,
  ViewingWindow,
  ViewingWindowSelection,
} from 'src/types';
import { millisToUTC } from 'src/utilities/DateTimeUtils';
import { Slider } from './TimelineView.styled';
import { useViewingWindowsStore } from './ViewingWindowsStore';

interface ValueLabelProps {
  children: React.ReactElement;
  open: boolean;
  value: number;
}

function ValueLabelComponent(props: ValueLabelProps) {
  const { children, open, value } = props;

  const date = millisToUTC(value);

  return (
    <Tooltip
      open={open}
      enterTouchDelay={0}
      placement="top"
      title={date}
    >
      {children}
    </Tooltip>
  );
}

export function TimelineSlider() {
  const currentPage = useCurrentPage();
  const currentOrbits = useCurrentOrbits();
  const currentTime = useAppStore((state) => state.timelines.timelineRange.currentTime);
  const currentTimePreviewMode = useAppStore(
    (state) => state.timelines.timelineRange.currentTimePreviewMode,
  );
  const clearCurrentLoadingPropagation = useClearLoadingPropagation();
  const updateTimelineRange = useAppStore((state) => state.timelines.updateTimelineRange);
  const [progress, setProgress] = useState<number>(PropagatedCacheManager.getPropagationProgress());
  const [isScrubbing, setIsScrubbing] = useState(false);

  const currentBookmarks = useBookmarks(currentPage?.id);
  const currentLaunchEvents = useCurrentLaunchEvents();
  const [marks, setMarks] = useState<Array<Mark>>([]);

  const timelineState = useTimeline();
  const { isSeeking, isLoading, isPlaying } = timelineState.timelineRange;
  const isPropagating = useIsPropagating();

  const maneuverFlyoutStatus = useSettingsStore(
    (state) => state.custom[SETTINGS_NAMES.MANEUVERS_FLYOUT_STATUS],
  );

  const launchFlyoutStatus = useGroundObjectLaunchEditStore((state) => state.isLaunchEditMode);

  //  while not propagating, clicking bookmark/maneuver or finalizing scrub on timeline
  // will begin loading propagation from notebook page start time
  const setLoadingFromPreview = useCallback(
    (value: number) => {
      timelineState.updateTimelineRange({
        currentTime: value,
        playState: 'seeking',
      });
      if (currentPage && currentOrbits) {
        PropagatedCacheManager.propagateTimeline(currentPage, currentOrbits, true);
      }
    },
    [currentOrbits, currentPage, timelineState],
  );

  const handleIconSelection = useCallback(
    async (event: MouseEvent, timestamp: string) => {
      event.stopPropagation();
      if (!isPropagating) {
        setLoadingFromPreview(Number(timestamp));
      } else if (Number(timestamp) <= getMaxAvailableTime() * 1000) {
        updateTimelineRange({ currentTime: Number(timestamp) });
        TimelineEventManager.publish('scrub', timestamp);
        if (isPlaying) {
          timelineState?.updateTimelineRange({
            playState: 'stopped',
          });
        }
      } else {
        // progress was not 100% and the bookmark/maneuver was positioned beyond the currently loaded propagation
        updateTimelineRange({ currentTimePreviewMode: Number(timestamp) });
        await clearCurrentLoadingPropagation();
        setLoadingFromPreview(Number(timestamp));
      }
    },
    [
      isPlaying,
      timelineState,
      isPropagating,
      updateTimelineRange,
      setLoadingFromPreview,
      clearCurrentLoadingPropagation,
    ],
  );

  const activeLaunchWindows = useGroundObjectLaunchEditStore(
    (state) => state.launchEditActiveWindows,
  );

  const launchEditActiveWindows = useGroundObjectLaunchEditStore(
    (state) => state.launchEditActiveWindows,
  );
  const activeLaunchWindow = useGroundObjectLaunchEditStore((state) => state.activeLaunchWindow);
  const setActiveLaunchWindow = useGroundObjectLaunchEditStore(
    (state) => state.setActiveLaunchWindow,
  );

  const viewingWindows = useViewingWindowsStore();
  const activeInspectorTab = useAppStore((state) => state.activeInspectorTab);
  const activeGroundObjId = useRouteStore(getActiveGroundObjectId);
  const activeSpaceObjId = useRouteStore(getActiveObjectId);

  const viewingWindowsToDisplay = useMemo(() => {
    let viewingWindowsToDisplay: ViewingWindow[] = [];

    if (activeInspectorTab === 'sensors') {
      viewingWindowsToDisplay = viewingWindows.spaceSensorWindows[activeSpaceObjId]?.windows ?? [];
    } else if (activeInspectorTab === 'contact') {
      viewingWindowsToDisplay =
        viewingWindows.groundObjectWindows[activeGroundObjId]?.windows ?? [];
    }

    const viewingWindowStartTimes: ViewingWindowSelection[] = viewingWindowsToDisplay.map(
      (window) => ({
        timestamp: new Date(window.startTime),
        windowType: 'start',
        color: window.color,
      }),
    );
    const viewingWindowEndTimes: ViewingWindowSelection[] = viewingWindowsToDisplay.map(
      (window) => ({ timestamp: new Date(window.endTime), windowType: 'end', color: window.color }),
    );

    return { viewingWindowStartTimes, viewingWindowEndTimes };
  }, [
    activeGroundObjId,
    activeInspectorTab,
    activeSpaceObjId,
    viewingWindows.groundObjectWindows,
    viewingWindows.spaceSensorWindows,
  ]);

  useEffect(() => {
    let newMarks: Mark[] = [];

    const { viewingWindowStartTimes, viewingWindowEndTimes } = viewingWindowsToDisplay;

    const currentManeuvers = currentOrbits
      ?.filter(
        // Redundant to display maneuvers that are members of an orbit that is a payload orbit for a launch event
        (orb) => !currentLaunchEvents?.some((event) => event.payloadOrbitId === orb.id) ?? true,
      )
      .flatMap((orbit: OrbitObject) => orbit.maneuvers);
    const currentLaunches: Launch[] =
      currentLaunchEvents
        ?.filter((obj) => currentOrbits?.find((orb) => orb.id === obj.payloadOrbitId))
        ?.map((obj) => {
          const payloadOrbit = currentOrbits?.find((orb) => orb.id === obj.payloadOrbitId);

          return {
            timestamp: payloadOrbit!.maneuvers[0].timestamp,
            isLaunch: true,
          };
        }) ?? [];

    let timelineSelections: (Maneuver | Bookmark | Launch | ViewingWindowSelection)[] = [];
    if (currentBookmarks) timelineSelections = [...timelineSelections, ...currentBookmarks];
    if (currentManeuvers) timelineSelections = [...timelineSelections, ...currentManeuvers];
    if (currentLaunches) timelineSelections = [...timelineSelections, ...currentLaunches];
    if (viewingWindowStartTimes)
      timelineSelections = [...timelineSelections, ...viewingWindowStartTimes];
    if (viewingWindowEndTimes)
      timelineSelections = [...timelineSelections, ...viewingWindowEndTimes];

    const markQuantities: Record<number, { qty: number; type: string; color?: string }> = {};

    timelineSelections.forEach((item) => {
      const isManeuver = 'deltaVelocity' in item;
      const isLaunch = 'isLaunch' in item;
      const isViewingWindowStart = (item as ViewingWindowSelection)?.windowType === 'start';
      const isViewingWindowEnd = (item as ViewingWindowSelection)?.windowType === 'end';
      let markType = 'bookmark';
      if (isManeuver) {
        markType = 'maneuver';
      } else if (isLaunch) {
        markType = 'launch';
      } else if (isViewingWindowStart) {
        markType = 'viewingWindowStart';
      } else if (isViewingWindowEnd) {
        markType = 'viewingWindowEnd';
      }

      const ts = isManeuver || isLaunch ? new Date(item.timestamp) : item.timestamp;
      markQuantities[ts.getTime()] = {
        qty: (markQuantities[ts.getTime()]?.qty || 0) + 1,
        type: markType,
        color: (item as ViewingWindowSelection)?.color,
      };
    });

    if (maneuverFlyoutStatus !== MANEUVER_FLYOUT_STATUSES.CLOSED) {
      markQuantities[currentTime] = {
        qty: (markQuantities[currentTime]?.qty || 0) + 1,
        type: 'maneuver',
      };
    } else if (launchFlyoutStatus !== LAUNCH_FLYOUT_STATUS.CLOSED && activeLaunchWindows) {
      activeLaunchWindows.forEach((window) => {
        const windowLaunchTime = new Date(window.launchTime).getTime();
        markQuantities[windowLaunchTime] = {
          qty: (markQuantities[windowLaunchTime]?.qty || 0) + 1,
          type: 'launch',
        };
      });
    }

    newMarks = Object.entries(markQuantities).map(([timestamp, { qty, type, color }]) => {
      let label;
      if (type === 'bookmark') {
        label =
          qty > 1 ? (
            <BookmarksRounded
              color="primary"
              onMouseDown={(e) => handleIconSelection(e, timestamp)}
            />
          ) : (
            <BookmarkRounded
              color="primary"
              onMouseDown={(e) => handleIconSelection(e, timestamp)}
            />
          );
      } else if (type === 'launch') {
        const currentLaunchWindow = launchEditActiveWindows?.find(
          (win) => new Date(win.launchTime).getTime() === parseInt(timestamp),
        );

        const isActiveLaunchWindow =
          activeLaunchWindow &&
          (launchFlyoutStatus === LAUNCH_FLYOUT_STATUS.CLOSED ||
            parseInt(timestamp) === new Date(activeLaunchWindow.launchTime).getTime());

        label = (
          <RocketLaunchRounded
            sx={{
              opacity: isActiveLaunchWindow ? 1 : 0.3,
              color: LAUNCH_WINDOWS_COLOR_ACTIVE,
              cursor: 'pointer',
            }}
            onMouseDown={(e) => {
              if (currentLaunchWindow) setActiveLaunchWindow(currentLaunchWindow);
              handleIconSelection(e, timestamp);
            }}
          />
        );
      } else if (type === 'viewingWindowStart') {
        label = (
          <ArrowRightRounded
            sx={{
              fontSize: 48,
              ml: 1,
              mt: -2,
              color,
            }}
            onMouseDown={(e) => handleIconSelection(e, timestamp)}
          />
        );
      } else if (type === 'viewingWindowEnd') {
        label = (
          <ArrowLeftRounded
            sx={{
              fontSize: 48,
              mr: 1,
              mt: -2,
              color,
            }}
            onMouseDown={(e) => handleIconSelection(e, timestamp)}
          />
        );
      } else {
        label = (
          <SvgIcon
            fontSize="medium"
            color="primary"
            onMouseDown={(e) => handleIconSelection(e, timestamp)}
          >
            <ManeuverIcon fill="primary" />
          </SvgIcon>
        );
      }

      return {
        label: label,
        value: Number(timestamp),
      };
    });

    setMarks(newMarks);
  }, [
    isPlaying,
    currentTime,
    isPropagating,
    timelineState,
    currentOrbits,
    currentBookmarks,
    launchFlyoutStatus,
    activeLaunchWindows,
    currentLaunchEvents,
    updateTimelineRange,
    handleIconSelection,
    maneuverFlyoutStatus,
    activeLaunchWindow,
    launchEditActiveWindows,
    setActiveLaunchWindow,
    viewingWindowsToDisplay,
  ]);

  const handleTimelineSelectionFinish = async (_e: any, value: any) => {
    if (isSeeking || isLoading) {
      // loading from initial scrub still but selected somewhere else instead
      await clearCurrentLoadingPropagation();
      setLoadingFromPreview(value);
    } else if (!isPropagating && isScrubbing) {
      // initially scrubbed timeline
      setLoadingFromPreview(value);
      setIsScrubbing(false);
    }
  };

  const handleScrub = async (_e: any, value: any) => {
    if (!isPropagating) {
      updateTimelineRange({ currentTimePreviewMode: value });
      setIsScrubbing(true);
    } else {
      const maxAvailableTime = getMaxAvailableTime();
      if (value <= maxAvailableTime * 1000) {
        // selected position was within currently loaded propagation window
        updateTimelineRange({ currentTime: value });
        TimelineEventManager.publish('scrub', value);
      } else if (currentPage !== undefined && value >= currentPage.endTime.getTime()) {
        updateTimelineRange({ currentTime: value });
      } else {
        // selected somewhere beyond currently loaded data during propagation
        updateTimelineRange({ currentTimePreviewMode: value });
        await clearCurrentLoadingPropagation();
        updateTimelineRange({ playState: 'seeking' });
      }
    }
  };

  const updateProgress = useCallback(() => {
    const percentage = PropagatedCacheManager.getPropagationProgress();
    setProgress(percentage);
  }, []);

  useEffect(() => {
    PropagatedCacheManager.addEventListener('orbitschange', updateProgress);
    PropagatedCacheManager.addEventListener('canplay', updateProgress);
    PropagatedCacheManager.addEventListener('seeked', updateProgress);
    PropagatedCacheManager.addEventListener('canplaythrough', updateProgress);
    PropagatedCacheManager.addEventListener('clearpropagations', updateProgress);
    return function cleanup() {
      PropagatedCacheManager.removeEventListener('orbitschange', updateProgress);
      PropagatedCacheManager.removeEventListener('seeked', updateProgress);
      PropagatedCacheManager.removeEventListener('canplay', updateProgress);
      PropagatedCacheManager.removeEventListener('canplaythrough', updateProgress);
      PropagatedCacheManager.removeEventListener('clearpropagations', updateProgress);
    };
  }, [updateProgress]);

  return (
    <>
      <Slider
        key="timeSlider"
        value={!isPropagating ? currentTimePreviewMode : currentTime}
        min={currentPage?.startTime.getTime()}
        max={currentPage?.endTime.getTime()}
        onChangeCommitted={handleTimelineSelectionFinish}
        onChange={handleScrub}
        progress={progress}
        components={{
          ValueLabel: ValueLabelComponent,
        }}
        size="small"
        marks={marks}
      />
    </>
  );
}
