import { AddRounded } from '@mui/icons-material';
import { Button, Dialog, DialogActions, DialogContent } from '@mui/material';
import { useState } from 'react';
import { SpaceGlassContainerBase } from 'src/components/SpaceGlassControl';
import { useCopyFolderOrNotebook } from 'src/hooks/ObjectHooks';
import { LabelWithTooltip } from 'src/pages/Shared/LabelWithTooltip';
import { ModalHeader } from 'src/pages/Shared/ModalSpaceGlass';
import { router } from '../routes/Router';
import { getActiveCapabilityId, getActiveNotebookId, useRouteStore } from '../routes/store';
import { CopyButton, CopyContainer, MakeCopyContainer } from './MakeCopyButton.styled';

type ConfirmCopyModalProps = {
  open: boolean;
  onClose: () => void;
  onSubmit: () => Promise<void>;
};

export function ConfirmCopyModal({ open, onClose, onSubmit }: ConfirmCopyModalProps) {
  const mutation = useCopyFolderOrNotebook();

  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullWidth={true}
      aria-labelledby="max-width-dialog-title"
      data-test="confirm-copy-modal"
      transitionDuration={0}
      PaperComponent={SpaceGlassContainerBase}
    >
      <ModalHeader
        title={'Copy Shared Notebook'}
        onClose={onClose}
        icon={<AddRounded />}
      />
      <DialogContent>
        {!mutation.isError &&
          'This will make an editable copy of this read-only Notebook on your root folder.'}
        {mutation.isError && 'Something went wrong. Try again later.'}
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          color="primary"
          onClick={() => {
            onSubmit();
            onClose(); // Dismiss after save modal.
          }}
          disabled={mutation.isError || mutation.isLoading}
        >
          SAVE
        </Button>
        <Button
          variant="text"
          onClick={onClose}
        >
          CANCEL
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default function MakeCopyButton() {
  const capabilityId = useRouteStore(getActiveCapabilityId);
  const notebookId = useRouteStore(getActiveNotebookId);

  const mutation = useCopyFolderOrNotebook();

  const [copyModalOpen, setCopyModalOpen] = useState(false);

  if (!capabilityId) return <></>;

  const onSubmit = async () => {
    if (!notebookId) return;
    try {
      const result = await mutation.mutateAsync(notebookId);
      // TODO: handle redirecting to something else than root.
      console.log('result', result);
      router.navigate(`/notebook/${result.id}`);
    } catch (err) {
      // Error copying notebook.
      console.error(err);
    }
  };

  return (
    <MakeCopyContainer>
      <CopyContainer>
        <LabelWithTooltip
          label="READ_ONLY"
          title="This is a Read-Only file, make a copy for yourself to edit"
        />
        <CopyButton onClick={() => setCopyModalOpen(true)}>MAKE A COPY</CopyButton>
      </CopyContainer>

      {copyModalOpen && (
        <ConfirmCopyModal
          onSubmit={onSubmit}
          open={copyModalOpen}
          onClose={() => setCopyModalOpen(false)}
        />
      )}
    </MakeCopyContainer>
  );
}
