import { geoPath } from 'd3-geo';
import { LATLONG_COLOR } from 'src/constants';
import * as topojson from 'topojson-client';
import { Topology } from 'topojson-specification';
import topology from 'world-atlas/countries-50m.json';
import { geoProjectionEarth2D } from '../GroundTrackTexture/utils/useEarthTexture';

interface DrawCountriesProps {
  context: CanvasRenderingContext2D;
}

export const drawCountries = ({ context }: DrawCountriesProps) => {
  const pathGeo = geoPath(geoProjectionEarth2D, context);

  const topologyTyped = topology as unknown as Topology;
  const countries = topojson.feature(topologyTyped, topologyTyped.objects.countries);

  context.save();

  context.lineWidth = 0.5;
  context.strokeStyle = LATLONG_COLOR;

  context.beginPath();
  pathGeo(countries);
  context.stroke();
  context.closePath();

  context.restore();
};
