import { PersonRemoveRounded } from '@mui/icons-material';
import { Button, Dialog, DialogActions, DialogContent, Typography } from '@mui/material';
import { SpaceGlassContainerBase } from 'src/components/SpaceGlassControl';
import { useOnboardingMeQuery, useOnboardingRemoveUserFromOrg } from 'src/hooks/OnboardingHooks';
import { ModalHeader } from 'src/pages/Shared/ModalSpaceGlass';
import { OrganizationUser } from 'src/types';

type ModalRemoveUserFromOrgProps = {
  open: boolean;
  show: (show: boolean) => void;
  orgId: string;
  user?: OrganizationUser;
};
export const ModalRemoveUserFromOrg = ({
  open,
  orgId,
  show,
  user,
}: ModalRemoveUserFromOrgProps) => {
  const currentOrganizationRoles = useOnboardingMeQuery();
  const removeUserFromOrg = useOnboardingRemoveUserFromOrg();

  const currentOrganization = currentOrganizationRoles?.organizations.find(
    (org) => (org.id = orgId),
  );

  const onConfirmDelete = () => {
    if (user) {
      removeUserFromOrg.mutate({
        orgId: orgId,
        idpUserId: user.id,
      });
    }
    onClose();
  };

  const onClose = () => {
    show(false);
  };

  return (
    <Dialog
      fullWidth
      maxWidth="sm"
      open={open}
      onClose={onClose}
      PaperComponent={SpaceGlassContainerBase}
    >
      <ModalHeader
        title="Are You Sure?"
        onClose={onClose}
        icon={<PersonRemoveRounded />}
      />
      <DialogContent>
        <Typography variant="body1">
          Remove <b>{user?.name}</b> from <b>{currentOrganization?.name}</b>?
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button
          type="button"
          onClick={onClose}
        >
          CANCEL
        </Button>
        <Button
          color="error"
          variant="contained"
          onClick={onConfirmDelete}
          aria-label="Delete Bookmark"
        >
          Remove User
        </Button>
      </DialogActions>
    </Dialog>
  );
};
