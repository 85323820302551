import { Box, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import { COE_PARAMETERS, ORBIT_TYPES } from 'src/constants';
import { useCreateOrbitMutation, useCurrentOrbits, useSelectOrbit } from 'src/hooks/OrbitHooks';
import { useCurrentPage } from 'src/hooks/PageHooks';
import { OrbitObject, OrbitObjectStateVector } from 'src/types';
import { TLETextDisableInput } from './components/InspectorPanel.styled';
import { ModalObjectDuplicate } from './ModalObjectDuplicate';
import { getIterativeNameFromList } from '../../utilities/StringUtils';

type ModalProps = {
  show: boolean;
  onClose: VoidFunction;
  orbit: OrbitObject;
};

export function ModalOrbitDuplicate(props: ModalProps) {
  const currentOrbits = useCurrentOrbits();
  const currentPage = useCurrentPage();
  const { orbit } = props;

  const setCurrentOrbit = useSelectOrbit();
  const createOrbitMutation = useCreateOrbitMutation();

  const onCloseModal = () => {
    props.onClose();
  };

  const onSubmit = async () => {
    if (orbit && currentPage) {
      const newOrbit = {
        ...orbit,
        name: getIterativeNameFromList(
          orbit.name,
          currentOrbits?.map((currentOrbit) => currentOrbit.name),
          'copy',
        ),
        orbitType: orbit?.orbitType === ORBIT_TYPES.LAUNCH ? ORBIT_TYPES.COE : orbit.orbitType,
      };
      const result = await createOrbitMutation.mutateAsync(newOrbit);
      setCurrentOrbit(result.orbits[0].id);
    }

    onCloseModal();
  };

  return (
    <ModalObjectDuplicate
      show={props.show}
      title={'Duplicate ' + orbit.name}
      onSubmitFn={onSubmit}
      onCloseFn={onCloseModal}
    >
      {[ORBIT_TYPES.COE, ORBIT_TYPES.STATE_VECTORS, ORBIT_TYPES.TLE, ORBIT_TYPES.LAUNCH].includes(
        orbit?.orbitType,
      ) && (
        <p>
          Duplicate <b>{orbit.name}</b> with the following parameters:
        </p>
      )}

      <Box
        component="div"
        sx={{
          mt: 1,
        }}
      >
        {(orbit?.orbitType === ORBIT_TYPES.COE || orbit?.orbitType === ORBIT_TYPES.LAUNCH) && (
          <RenderOrbitTableCOE orbit={orbit} />
        )}

        {orbit?.orbitType === ORBIT_TYPES.TLE && <RenderOrbitTableTLE orbit={orbit} />}

        {orbit?.orbitType === ORBIT_TYPES.STATE_VECTORS && (
          <RenderOrbitTableCartesian orbit={orbit} />
        )}

        {orbit?.orbitType === ORBIT_TYPES.EPHEMERIS && (
          <div>
            Duplicate orbit: <b>{orbit.name}</b>
          </div>
        )}
      </Box>
    </ModalObjectDuplicate>
  );
}

const RenderOrbitTableCOE = ({ orbit }: { orbit: OrbitObject }) => {
  return (
    <Table
      size="small"
      sx={{
        margin: 'auto',
        width: 'auto',
      }}
    >
      <TableHead>
        <TableRow>
          <TableCell>Property</TableCell>
          <TableCell>Value</TableCell>
        </TableRow>
      </TableHead>

      <TableBody>
        {COE_PARAMETERS.map((param) => {
          const coe: any = orbit.orbit?.[0];
          const coeValue = param.selector(coe);
          return (
            <TableRow key={param.name}>
              <TableCell>{param.displayName}</TableCell>
              <TableCell>
                {coeValue} {param.unit}
              </TableCell>
            </TableRow>
          );
        })}
      </TableBody>
    </Table>
  );
};

const RenderOrbitTableTLE = ({ orbit }: { orbit: OrbitObject }) => {
  return (
    <TLETextDisableInput
      fullWidth
      disabled
      multiline
      rows={3}
      id="text-field-TLE-input-display"
      value={orbit?.orbitTLE ? Object.values(orbit.orbitTLE).join('\n') : 'Invalid TLE'}
    />
  );
};

const RenderOrbitTableCartesian = ({ orbit }: { orbit: OrbitObject }) => {
  return (
    <Table
      size="small"
      sx={{
        margin: 'auto',
        width: 'auto',
      }}
    >
      <TableHead>
        <TableRow>
          <TableCell>Property</TableCell>
          <TableCell>Value</TableCell>
        </TableRow>
      </TableHead>

      <TableBody>
        {(
          [
            ['xPosition', 'km'],
            ['yPosition', 'km'],
            ['zPosition', 'km'],
            ['xVelocity', 'km/s'],
            ['yVelocity', 'km/s'],
            ['zVelocity', 'km/s'],
          ] as [keyof OrbitObjectStateVector, string][]
        ).map(([param, unit]) => {
          const orbitSv = orbit.stateVectors;
          const svVal = orbitSv?.[param];
          const label = `${param[0].toUpperCase()} ${param.slice(1)}`;
          return (
            <TableRow key={param}>
              <TableCell>{label}</TableCell>
              <TableCell align="right">
                {svVal?.toFixed(2)} {unit}
              </TableCell>
            </TableRow>
          );
        })}
      </TableBody>
    </Table>
  );
};
