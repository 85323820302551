import { TabPanel } from '@mui/lab';
import { ReactNode } from 'react';

interface InspectorTabPanelProps {
  value: string;
  children: ReactNode;
}
export const InspectorTabPanel = ({ value, children }: InspectorTabPanelProps) => {
  return (
    <TabPanel
      value={value}
      sx={{
        p: 1,
      }}
    >
      {children}
    </TabPanel>
  );
};
