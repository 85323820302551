import { ChevronRightRounded, ExpandMoreRounded } from '@mui/icons-material';
import { Collapse, SvgIcon } from '@mui/material';
import { CSSProperties, DragEvent, ReactElement, ReactNode, useEffect, useState } from 'react';
import theme, { COLOR_BORDER } from 'src/pages/App/Theme';

const COLOR_BACKGROUND_ACTIVE = 'rgba(13,54,78, 0.6)';
const COLOR_BACKGROUND_DROPPABLE = 'rgba(0, 255, 0, 0.25)';

export interface TreeBranchDrop {
  dragId: number | string;
  dragType: string;
  dropId: number | string;
  dropType: string;
}

interface TreeBranchProps {
  children?: ReactNode;
  id: number | string;
  type: string;
  main: ReactNode;
  icon?: ReactElement;
  draggable?: boolean;
  droppable?: boolean;
  expandable?: boolean;
  expanded?: boolean;
  active?: boolean;
  style?: CSSProperties;
  onDrop?: (onDropProps: TreeBranchDrop) => void;
  onExpand?: (id: number) => void;
  onSelect?: () => void;
}

export const TreeBranch = ({
  type,
  active,
  children,
  draggable = false,
  droppable = false,
  expandable = false,
  expanded = true,
  icon,
  id,
  main,
  style,
  onDrop,
  onExpand,
  onSelect,
}: TreeBranchProps) => {
  const [isExpanded, setIsExpanded] = useState<boolean>(true);
  const [backgroundColor, setBackgroundColor] = useState<string | null>(null);
  const [hovered, setHovered] = useState<boolean>(false);

  const styleOverrides: CSSProperties = {
    ...style,
  };
  if (backgroundColor) {
    styleOverrides.background = backgroundColor;
  }

  useEffect(() => {
    if (expanded !== isExpanded) {
      setIsExpanded(expanded);
    }
  }, [expanded, isExpanded, setIsExpanded]);

  const dataTransferKeyForMyself = `dragging-${id}`;

  let iconToUse = icon;
  if (expandable && (hovered || !icon)) {
    if (expanded) {
      iconToUse = <ExpandMoreRounded />;
    } else {
      iconToUse = <ChevronRightRounded />;
    }
  }

  return (
    <div
      style={{
        userSelect: 'none',
        width: '100%',
        fontSize: 12,
        fontWeight: 500,
        ...styleOverrides,
      }}
      onDragOver={(event: DragEvent) => {
        const isOverMyself = event.dataTransfer.types.includes(dataTransferKeyForMyself);

        if (droppable && !isOverMyself) {
          event.stopPropagation();
          event.preventDefault();
          setBackgroundColor(COLOR_BACKGROUND_DROPPABLE);
        }
      }}
      onDragLeave={(event: DragEvent) => {
        setBackgroundColor(null);
      }}
      onDrop={(event: DragEvent) => {
        if (!droppable) {
          return;
        }

        event.preventDefault();
        event.stopPropagation();
        setBackgroundColor(null);

        const isOverMyself = event.dataTransfer.types.includes(dataTransferKeyForMyself);

        if (droppable && !isOverMyself) {
          const dragId = event.dataTransfer.getData('id');
          const dragType = event.dataTransfer.getData('type');

          if (onDrop) {
            onDrop({
              dropId: id,
              dropType: type,
              dragId: dragId,
              dragType: dragType,
            });
          }
        }
      }}
    >
      <div
        draggable={draggable}
        onDragStart={(event: DragEvent) => {
          event.dataTransfer.setData('id', `${id}`);
          event.dataTransfer.setData('type', type);
          event.dataTransfer.setData(dataTransferKeyForMyself, '');
        }}
        style={{
          display: 'grid',
          gridAutoFlow: 'column',
          alignItems: 'center',
          justifyContent: 'start',
          gap: theme.spacing(1),
          paddingLeft: theme.spacing(1),
          width: '100%',
          gridTemplateColumns: iconToUse ? 'min-content 1fr' : '1fr',
          backgroundColor: active ? COLOR_BACKGROUND_ACTIVE : 'transparent',
          cursor: onSelect ? 'pointer' : 'inherit',
        }}
        onMouseOver={() => {
          setHovered(true);
        }}
        onMouseLeave={() => {
          setHovered(false);
        }}
        onClick={() => {
          if (onSelect) {
            onSelect();
          }
        }}
      >
        {/* ICON - to the left of the row */}
        {iconToUse && (
          <div
            onClick={(event) => {
              event.stopPropagation();
              if (expandable) {
                setIsExpanded(!isExpanded);
                if (onExpand) {
                  onExpand(parseInt(`${id}`));
                }
              }
            }}
            style={{
              cursor: expandable ? 'pointer' : 'inherit',
            }}
          >
            <SvgIcon
              sx={{
                display: 'grid',
                fontSize: 16,
              }}
            >
              {iconToUse}
            </SvgIcon>
          </div>
        )}

        <div>{main}</div>
      </div>

      {children && (
        <Collapse
          in={isExpanded}
          mountOnEnter
          unmountOnExit
        >
          <div
            style={{
              marginLeft: expandable ? theme.spacing(2) : 0,
              paddingLeft: expandable ? theme.spacing(1) : 0,
              borderLeft: `1px solid ${COLOR_BORDER}`,
            }}
          >
            {children}
          </div>
        </Collapse>
      )}
    </div>
  );
};
