import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useEffect } from 'react';
import {
  LayoutEngineItemState,
  useLayoutStore,
} from 'src/pages/Notebook/components/LayoutEngine/layoutStore';
import { fetchApi } from 'src/services/api';
import { useApiSnackbarError } from './SnackbarHooks';

const API_ENDPOINT = '/v2/preset-layouts';
const QUERY_CACHE_KEY = 'layoutsForUser';

export interface LayoutFromDB {
  id: number;
  name: string;
  properties: {
    panels: Record<string, LayoutEngineItemState>;
  };
}

const useLayoutsQuery = () => {
  const apiSnackbarError = useApiSnackbarError();

  return useQuery<LayoutFromDB[]>(
    [QUERY_CACHE_KEY],
    async () => {
      const layouts = await fetchApi(API_ENDPOINT);

      return layouts;
    },
    {
      onError: () => {
        apiSnackbarError('Failed to get layouts');
      },
    },
  );
};

export const useLayouts = () => {
  const { data } = useLayoutsQuery();

  const setLayout = useLayoutStore((state) => state.setLayout);
  const setApiDataLoaded = useLayoutStore((state) => state.setApiDataLoaded);

  useEffect(() => {
    if (data) {
      data?.forEach((layout) => {
        setLayout(layout);
      });
      setApiDataLoaded(true);
    }
  }, [data, setApiDataLoaded, setLayout]);

  return data;
};

export const useCreateLayout = () => {
  const apiSnackbarError = useApiSnackbarError();

  const queryClient = useQueryClient();

  return useMutation(
    ({ name, properties }: Partial<LayoutFromDB>) => {
      return fetchApi(API_ENDPOINT, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          name,
          properties,
        }),
      });
    },
    {
      onError: () => {
        apiSnackbarError('Failed to create layout.');
      },
      onSuccess: async () => {
        await queryClient.invalidateQueries([QUERY_CACHE_KEY]);
      },
    },
  );
};

export const useUpdateLayout = () => {
  const apiSnackbarError = useApiSnackbarError();

  const queryClient = useQueryClient();

  return useMutation(
    ({ id, name, properties }: LayoutFromDB) => {
      return fetchApi(`${API_ENDPOINT}/${id}`, {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          id,
          name,
          properties,
        }),
      });
    },
    {
      onError: () => {
        apiSnackbarError('Failed to update layout.');
      },
      onSuccess: async () => {
        await queryClient.invalidateQueries([QUERY_CACHE_KEY]);
      },
    },
  );
};

export const useDeleteLayout = () => {
  const apiSnackbarError = useApiSnackbarError();

  const queryClient = useQueryClient();
  const deleteLayoutInStore = useLayoutStore((state) => state.deleteLayout);

  return useMutation(
    (id: number) => {
      return fetchApi(`${API_ENDPOINT}/${id}`, {
        method: 'DELETE',
        headers: { 'Content-Type': 'application/json' },
      });
    },
    {
      onError: () => {
        apiSnackbarError('Failed to delete layout.');
      },
      onSuccess: async (_, id) => {
        await queryClient.invalidateQueries([QUERY_CACHE_KEY]);
        deleteLayoutInStore(id);
      },
    },
  );
};
