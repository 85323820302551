import { AxesColors } from '../Notebook/components/Settings/AxesColors';
import { ToggleFeatures } from './ToggleFeatures';
import { ToggleVectorHelpers } from './ToggleVectorHelpers';

export function DeveloperPreReleaseFeatures() {
  const features = [];

  features.push(<ToggleFeatures key="ToggleFeatures" />);

  features.push(<ToggleVectorHelpers key="ToggleVectorHelpers" />);
  features.push(<AxesColors key="AxesColors" />);

  return <>{features.length === 0 ? <div>No dev features at this time.</div> : features}</>;
}
