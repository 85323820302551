import { OrbitObject } from 'src/types';

export function removeOrbit(
  orbits: OrbitObject[],
  orbitId: number,
): [OrbitObject[], OrbitObject | null] {
  const remainder = orbits.filter((orbit) => orbit.id !== orbitId);
  const nextOrbit = remainder.length > 0 ? remainder[0] : null;

  return [remainder, nextOrbit];
}
