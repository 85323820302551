import { AddRounded, FileUploadRounded } from '@mui/icons-material';
import { Box, Button, CircularProgress, Grid, Tab, Tabs, Typography } from '@mui/material';
import { DataGrid, GridCellParams, GridSortItem } from '@mui/x-data-grid';
import { SyntheticEvent, useCallback, useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  DATAGRID_ROWS_PER_PAGE_DEFAULT,
  DATAGRID_ROWS_PER_PAGE_OPTIONS,
  ORBIT_TYPES,
} from 'src/constants';
import { LABModalAction, LABModalActionType, LABObject } from 'src/enums';
import { useSetGroups } from 'src/hooks/GroupHooks';
import {
  useCreateObjectMutation,
  useGetCapabilities,
  useObjectHierarchy,
  useUpdateCapability,
} from 'src/hooks/ObjectHooks';
import { useCreateOrbitMutation, useSetOrbits } from 'src/hooks/OrbitHooks';
import { RouteStore } from 'src/pages/App/routes/RouteStore';
import { getActiveFolderId, useRouteStore } from 'src/pages/App/routes/store';
import { Folder, Notebook, OrbitObject, Page, SharedFolder, SharedNotebook } from 'src/types';
import { usePagesForNotebookQuery, useUpdatePageMutation } from '../../hooks/PageHooks';
import theme from '../App/Theme';
import { ModalEphemTLEPage } from '../Notebook/ModalEphemTLEPage';
import {
  SnackbarStyled as Snackbar,
  SnackbarContentStyled as SnackbarContent,
} from './FolderView.styled';
import { FolderBreadcrumbs } from './components/FolderBreadcrumbs';
import { folderColumns } from './components/FolderColumns';
import { FolderEmptyDataGrid } from './components/FolderEmptyDataGrid';
import { FolderModal } from './components/FolderModal';
import { ShareObjectModal } from './components/ShareObjectModal';

const FOLDER_VIEW_TABS = {
  PERSONAL: 'personal',
  SHARED: 'shared',
};

export default function FolderView() {
  const location = useLocation();
  const navigate = useNavigate();
  const folderId = useRouteStore(getActiveFolderId);

  const setOrbits = useSetOrbits();
  const setGroups = useSetGroups();

  const [selectedObject, setSelectedObject] = useState<Folder | Notebook | null>(null);

  const inSharedFolder = location.pathname.startsWith('/shared');
  const tabActive = inSharedFolder ? FOLDER_VIEW_TABS.SHARED : FOLDER_VIEW_TABS.PERSONAL;

  const data = useObjectHierarchy(folderId);
  const capabilitiesReq = useGetCapabilities();
  const updateCapability = useUpdateCapability();
  // useObjectHierarchy can return both Notebooks and Folders,
  // but we know that here it will return a Folder.

  const currentFolder = data as Folder;
  const [modalTLEOpen, setModalTLEOpen] = useState(false);
  const toggleImportModal = useCallback(() => {
    setModalTLEOpen(!modalTLEOpen);
  }, [modalTLEOpen]);
  const createNotebook = useCreateObjectMutation();
  const createOrbitMutation = useCreateOrbitMutation();
  const getPages = usePagesForNotebookQuery();
  const updatePage = useUpdatePageMutation();
  const [showShareObjectModal, setShowShareObjectModal] = useState<boolean>(false);

  const createNotebookWithOrbit = async (page: Partial<Page>, orbit: Partial<OrbitObject>) => {
    if (!currentFolder?.id) return;

    let nameBase = `${orbit.name}`;
    if (orbit.orbitType === ORBIT_TYPES.TLE) {
      nameBase = `${orbit?.orbitTLE?.titleLine}`;
    }

    const newNotebook = await createNotebook.mutateAsync({
      parentId: currentFolder.id,
      type: LABObject.NOTEBOOK,
      name: `Imported ${nameBase} Notebook`,
      notes: 'Notes',
    });

    if (newNotebook?.updates) {
      const [update] = newNotebook.updates;
      const [newPage] = await getPages.mutateAsync(update.objectId);
      if (!newPage) return;

      updatePage.mutate({
        ...newPage,
        name: `Imported ${nameBase} Page`,
        ...page,
      });

      const newPageId = newPage.id;
      const result = await createOrbitMutation.mutateAsync({
        ...orbit,
        name: `${nameBase}`,
        newPageId: newPageId,
      });

      navigate(`/notebook/${update.objectId}/${newPageId}/${result.orbits[0].id}`);
    }

    setModalTLEOpen(false);
  };

  const { sharedFolder, pendingNotifications } = useMemo(() => {
    const sharedFolder: any[] = [];
    const pendingNotifications: any[] = [];

    if (!capabilitiesReq?.data?.length) return { sharedFolder, pendingNotifications };

    capabilitiesReq.data.forEach((pair) => {
      if (pair.capability.status === 'ACCEPTED') {
        sharedFolder.push(pair);
      }
      if (pair.capability.status === 'PENDING') {
        pendingNotifications.push(pair);
      }
    });
    return { sharedFolder, pendingNotifications };
  }, [capabilitiesReq.data]);

  const handleAcceptNotebook = (capabilityId: number) => {
    return updateCapability.mutate({ capabilityId, statusUpdate: 'ACCEPTED' });
  };

  const handleDeclineNotebook = (capabilityId: number) => {
    return updateCapability.mutate({ capabilityId, statusUpdate: 'DECLINED' });
  };

  // Sorting and pagination
  const sortModel: Array<GridSortItem> = [
    {
      field: 'createdTimestamp',
      sort: 'desc',
    },
  ];

  const [modalAction, setModalAction] = useState<LABModalActionType>(LABModalAction.NULL);

  const selectFolder = (id: number) => {
    if (inSharedFolder) {
      navigate(`/shared/folders/${id}`);
    } else {
      navigate(`/folders/${id}`);
    }
  };

  const onCellDoubleClick = (params: GridCellParams) => {
    const row = params.row as Folder | Notebook | SharedFolder | SharedNotebook;
    if (row.type === LABObject.FOLDER) {
      selectFolder(row.id);
    }
    if (row.type === LABObject.NOTEBOOK) {
      onNotebookOpen(row);
    }
    if (row.type === LABObject.SHARED_NOTEBOOK) {
      const parentCapabilitiesReq = capabilitiesReq?.data?.find(
        (item) => item.object.id === folderId,
      );
      onSharedNotebookOpen({
        ...row,
        capabilityId: parentCapabilitiesReq?.capability.id,
      });
    }
    if (row.type === LABObject.SHARED_FOLDER) {
      selectFolder(row.id);
    }
  };

  const getItems = useCallback(
    (folder: Folder) => {
      const items = currentFolder
        ? currentFolder.children.map((object: Folder | Notebook) => ({
            ...object,
            onEdit: () => {
              setModalAction(LABModalAction.EDIT);
              setSelectedObject(object);
            },
            onShare: () => {
              setShowShareObjectModal(true);
              setSelectedObject(object);
            },
          }))
        : [];
      return items;
    },
    [currentFolder],
  );

  const datagridRows = useMemo(() => {
    if (location.pathname === '/shared') {
      if (!sharedFolder) return [];
      return sharedFolder.map((pair) => {
        return {
          ...pair.object,
          type: pair.object.type,
          onEdit: () => {},
          onShare: () => {},
          onCopy: () => {
            setModalAction(LABModalAction.COPY);
            setSelectedObject(pair.object);
          },
          capabilityId: pair.object.id,
        };
      });
    } else if (inSharedFolder) {
      const items = getItems(currentFolder).map((item) => {
        return {
          ...item,
          type: item.type === 'NOTEBOOK' ? 'SHARED-NOTEBOOK' : 'SHARED-FOLDER',
          onCopy: () => {
            setModalAction(LABModalAction.COPY);
            setSelectedObject(item);
          },
        };
      });
      return items;
    } else {
      const items = getItems(currentFolder);

      return items;
    }
  }, [currentFolder, getItems, inSharedFolder, sharedFolder, location.pathname]);

  /**
   * Handle opening notebooks
   */
  const onNotebookOpen = (notebook: Pick<Notebook, 'id'>) => {
    setOrbits(undefined);
    setGroups(undefined);
    navigate(`/notebook/${notebook.id}`);
  };

  /**
   * Handle opening shared notebooks.
   */
  const onSharedNotebookOpen = (notebook: SharedNotebook) => {
    setOrbits(undefined);
    setGroups(undefined);
    navigate(`/shared/${notebook.capabilityId}/notebook/${notebook.id}`);
  };

  /**
   * Open folder modal
   */
  const onCreateFolderModal = () => {
    setModalAction(LABModalAction.CREATE);
  };

  /**
   * Opens the newly created folder or notebook after creation.
   */
  const onFolderModalSuccess = (data: { updates?: any[] }) => {
    setModalAction(LABModalAction.NULL);

    if (data?.updates && modalAction === LABModalAction.CREATE) {
      const isNoteBook = data.updates.some((u: any) => u.type === LABObject.NOTEBOOK);
      const [update] = data.updates;
      if (isNoteBook) {
        onNotebookOpen({ id: update.objectId });
      } else {
        selectFolder(update.objectId);
      }
    }
  };

  /**
   * Close folder modal
   */
  const onCloseFolderModal = () => {
    setModalAction(LABModalAction.NULL);
  };

  /**
   * handle tab change
   */
  const handleTabChange = (_event: SyntheticEvent, value: string) => {
    if (value === FOLDER_VIEW_TABS.SHARED) {
      navigate('/shared');
    } else {
      navigate('/');
    }
  };

  return (
    <>
      <RouteStore />

      {capabilitiesReq?.status !== 'loading' &&
        capabilitiesReq?.data &&
        pendingNotifications.map((pair) => {
          return (
            <Snackbar
              key={`pending-notification-${pair.capability.id}`}
              anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
              open={true}
              sx={{
                p: '0.5px', // for some reason the default 12px in a centered snackbar is blurry on non-hiDPI displays
              }}
            >
              <SnackbarContent
                message={`${pair.capability.sourceEmail} sent you a Read-Only file "${pair.object.name}"`}
                action={
                  <Grid
                    display="grid"
                    gridAutoFlow="column"
                    gap={1}
                  >
                    <Button onClick={() => handleDeclineNotebook(pair.capability.id)}>
                      decline
                    </Button>
                    <Button
                      variant="contained"
                      onClick={() => handleAcceptNotebook(pair.capability.id)}
                    >
                      accept
                    </Button>
                  </Grid>
                }
              />
            </Snackbar>
          );
        })}

      <Box
        component="div"
        sx={{
          p: '20px 5vw',
          overflow: 'auto',
        }}
      >
        {!data && (
          <Box
            component="div"
            display="flex"
            justifyContent="center"
          >
            <CircularProgress />
          </Box>
        )}

        {data && (
          <>
            <Grid
              display="grid"
              gridAutoFlow="column"
              justifyContent="space-between"
              pt={2}
              pb={4}
            >
              <Typography variant="h3">Slingshot Laboratory</Typography>

              {!inSharedFolder && (
                <Grid
                  display="grid"
                  gridAutoFlow="column"
                  gap={1}
                  alignItems="center"
                >
                  <Button
                    variant="contained"
                    onClick={toggleImportModal}
                    startIcon={<FileUploadRounded />}
                  >
                    Import TLE/Ephemeris
                  </Button>

                  {currentFolder && (
                    <Button
                      variant="contained"
                      onClick={onCreateFolderModal}
                      startIcon={<AddRounded />}
                    >
                      Create
                    </Button>
                  )}
                </Grid>
              )}
            </Grid>

            <Box component="div">
              {currentFolder &&
                (modalAction === LABModalAction.EDIT ||
                  modalAction === LABModalAction.CREATE ||
                  modalAction === LABModalAction.COPY) && (
                  <FolderModal
                    action={modalAction}
                    onClose={onCloseFolderModal}
                    onSuccess={onFolderModalSuccess}
                    parent={currentFolder}
                    selected={selectedObject}
                  />
                )}
              {currentFolder && showShareObjectModal && (
                <ShareObjectModal
                  onClose={() => setShowShareObjectModal(false)}
                  selected={selectedObject}
                />
              )}

              <ModalEphemTLEPage
                isOpen={modalTLEOpen}
                close={toggleImportModal}
                onSubmit={createNotebookWithOrbit}
              />

              <Grid
                display="grid"
                gridAutoFlow="row"
                justifyContent="start"
                gridTemplateColumns="1fr"
                px={2}
                style={{
                  background: theme.palette.background.paper,
                }}
              >
                <Grid
                  display="grid"
                  justifyContent="center"
                  sx={{ width: '100%' }}
                >
                  <Tabs
                    value={tabActive}
                    onChange={handleTabChange}
                  >
                    <Tab
                      value={FOLDER_VIEW_TABS.PERSONAL}
                      label="PERSONAL"
                      sx={{ px: 8 }}
                    />
                    <Tab
                      value={FOLDER_VIEW_TABS.SHARED}
                      label="SHARED"
                      sx={{ px: 8 }}
                    />
                  </Tabs>
                </Grid>

                <Grid display="grid">
                  {!isNaN(folderId) && (
                    <FolderBreadcrumbs
                      folder={currentFolder}
                      onChangeFolder={selectFolder}
                    />
                  )}
                </Grid>
              </Grid>

              <DataGrid
                columns={folderColumns}
                rows={datagridRows}
                loading={!data || capabilitiesReq.status === 'loading'}
                columnVisibilityModel={{
                  // any shared-notebook found = shared folder; display the source email column
                  sourceEmail: inSharedFolder,
                }}
                pageSizeOptions={DATAGRID_ROWS_PER_PAGE_OPTIONS}
                hideFooterSelectedRowCount={true}
                onCellDoubleClick={onCellDoubleClick}
                components={{
                  NoRowsOverlay: FolderEmptyDataGrid,
                }}
                autoHeight={true}
                initialState={{
                  pagination: {
                    paginationModel: {
                      pageSize: DATAGRID_ROWS_PER_PAGE_DEFAULT,
                    },
                  },
                  sorting: {
                    sortModel: sortModel,
                  },
                }}
                disableColumnMenu={true}
              />
            </Box>
          </>
        )}
      </Box>
    </>
  );
}
