import { DeleteRounded } from '@mui/icons-material';
import { Button, Dialog, DialogActions, DialogContent, Typography } from '@mui/material';
import { partial } from 'lodash';
import { SpaceGlassContainerBase } from 'src/components/SpaceGlassControl';
import { useDeleteBookmark } from 'src/hooks/BookmarkHooks';
import { ModalHeader } from 'src/pages/Shared/ModalSpaceGlass';
import { Bookmark } from 'src/types';

type ModalDeleteBookmarkProps = {
  editingBookmark?: Bookmark;
  open: boolean;
  onClose: VoidFunction;
  show: (show: boolean) => void;
};

export function ModalDeleteBookmark(props: ModalDeleteBookmarkProps) {
  const deleteBookmark = useDeleteBookmark();

  const hide = partial(props.show, false);

  const onConfirmDelete = () => {
    if (props.editingBookmark) {
      deleteBookmark.mutate(props.editingBookmark);
    }
    onClose();
  };

  const onClose = () => {
    props.onClose();
    hide();
  };

  return (
    <Dialog
      fullWidth
      maxWidth="sm"
      open={props.open}
      onClose={hide}
      PaperComponent={SpaceGlassContainerBase}
    >
      <ModalHeader
        title="Are You Sure?"
        onClose={onClose}
        icon={<DeleteRounded />}
      />
      <DialogContent>
        <Typography variant="body1">This Bookmark will be deleted forever.</Typography>
      </DialogContent>
      <DialogActions>
        <Button
          type="button"
          onClick={onClose}
        >
          CANCEL
        </Button>
        <Button
          color="error"
          variant="contained"
          onClick={onConfirmDelete}
          aria-label="Delete Bookmark"
        >
          Delete Forever
        </Button>
      </DialogActions>
    </Dialog>
  );
}
