import { FormControl, styled } from '@mui/material';

export const CreateEditForm = styled(FormControl)`
  margin-top: ${({ theme }) => theme.spacing(1)};
  margin-bottom: ${({ theme }) => theme.spacing(2)};
`;

export const DeleteForm = styled(FormControl)`
  margin-right: auto;
`;
