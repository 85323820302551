import { Grid } from '@mui/material';
import { useEffect, useState } from 'react';
import { ReactComponent as RainbowSwatchIcon } from 'src/assets/rainbow_swatch_icon.svg';
import { ReactComponent as SwatchIcon } from 'src/assets/swatchIcon.svg';
import {
  ColorPickerCover,
  ColorPickerWrapper,
  OrbitColorPicker,
  SvgIconWrapper,
  SwatchSelectorBtn,
} from '../ObjectColorPicker.styled';

interface SwatchBtnPresetProps {
  isSelected: boolean;
  hex: string;
  setHex: any;
}

const SwatchSelector = ({ isSelected, hex, setHex }: SwatchBtnPresetProps) => {
  return (
    <Grid
      container
      item
      width="auto"
    >
      <SwatchSelectorBtn
        style={{ color: hex }}
        onClick={setHex}
      >
        <SvgIconWrapper selected={isSelected}>
          <SwatchIcon />
        </SvgIconWrapper>
      </SwatchSelectorBtn>
    </Grid>
  );
};

interface ColorSelectorProps {
  currentColor: string;
  presets?: { hex: string }[];
  setColor: (color: string) => void;
}

export const ColorSelector = ({ currentColor, presets, setColor }: ColorSelectorProps) => {
  const [pickingColor, setPickingColor] = useState(false);
  const [isCustom, setCustomMode] = useState(false); // tracks if custom color was previously selected

  useEffect(() => {
    const presetColors = presets?.map((preset) => preset.hex);
    const color = currentColor || '';
    setCustomMode(!presetColors?.includes(color));
  }, [currentColor, presets]);

  return (
    <Grid
      container
      gap={2}
      px={1}
    >
      {presets?.map((preset, i) => (
        <SwatchSelector
          key={`${preset}${i}`}
          isSelected={currentColor === preset.hex}
          setHex={() => {
            setCustomMode(false);
            setColor(preset.hex);
          }}
          {...preset}
        />
      ))}

      <Grid
        container
        item
        width="auto"
      >
        <SwatchSelectorBtn
          style={{ color: currentColor }}
          onClick={() => {
            setPickingColor(true);
            setCustomMode(true);
          }}
        >
          <SvgIconWrapper
            selected={isCustom}
            selectedcolor={currentColor}
          >
            <RainbowSwatchIcon />
          </SvgIconWrapper>
        </SwatchSelectorBtn>
      </Grid>

      {pickingColor && (
        <ColorPickerWrapper>
          <ColorPickerCover onClick={() => setPickingColor(false)} />
          <OrbitColorPicker
            color={currentColor}
            onChange={(rgba) => {
              setColor(rgba.hex as string);
            }}
          />
        </ColorPickerWrapper>
      )}
    </Grid>
  );
};
