import { InfoRounded } from '@mui/icons-material';
import { Button, Dialog, DialogActions, DialogContent, Typography } from '@mui/material';
import { partial } from 'lodash';
import React from 'react';
import { SpaceGlassContainerBase } from 'src/components/SpaceGlassControl';
import { ModalHeader } from 'src/pages/Shared/ModalSpaceGlass';
import { buildVersion } from 'src/utilities/buildInfo/buildInfo';

interface Props {
  open: boolean;
  show: (show: boolean) => void;
}

export const ModalAbout: React.ComponentType<Props> = (props) => {
  const hide = partial(props.show, false);

  return (
    <Dialog
      fullWidth
      maxWidth="sm"
      open={props.open}
      onClose={hide}
      PaperComponent={SpaceGlassContainerBase}
    >
      <ModalHeader
        title={'About Slingshot Laboratory'}
        onClose={hide}
        icon={<InfoRounded />}
      />
      <DialogContent>
        <Typography variant="h6">Version: {process.env.REACT_APP_VERSION}</Typography>
        <Typography
          variant="body2"
          pt={1}
        >
          Build: {buildVersion}
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button
          color="secondary"
          variant="contained"
          onClick={hide}
        >
          CLOSE
        </Button>
      </DialogActions>
    </Dialog>
  );
};
