import { Grid } from '@react-three/drei';
import { useEffect, useRef } from 'react';
import { COLOR_EARTH_REFERENCE_GRID } from 'src/constants';
import { DoubleSide } from 'three';
import { useViewportReferenceFrame } from './ViewportManager/store';

export const InfinitePlanarGrid = () => {
  const { isRIC } = useViewportReferenceFrame();
  const sectionSize = isRIC ? 0.5 : 2;
  const fadeStrength = isRIC ? 2.5 : 1.9;
  const sectionThickness = isRIC ? 1.1 : 1.2;

  const { x, y, ...gridConfig } = {
    x: 1000,
    y: 1000,
    sectionSize,
    cellSize: 0,
    cellThickness: 0,
    cellColor: '#000000' as string,
    sectionThickness,
    sectionColor: COLOR_EARTH_REFERENCE_GRID,
    fadeDistance: 200,
    fadeStrength,
    followCamera: false,
    infiniteGrid: false,
  };

  const ref = useRef<any>();

  useEffect(() => {
    if (ref.current?.material) {
      ref.current.material.depthWrite = false;
    }
  }, []);

  return (
    <Grid
      ref={ref}
      side={DoubleSide}
      position={[0, 0, 0]}
      args={[x, y]}
      {...gridConfig}
    />
  );
};
