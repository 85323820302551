import { Box, styled } from '@mui/material';

export const GRID_TEMPLATES = [
  `
    grid-template-columns: 1fr;
  `,
  `
    grid-template-columns: minmax(0, 1fr) minmax(0, 1fr);
  `,
  `
    grid-template-columns: minmax(0, 1fr) minmax(0, 1fr);
    grid-template-rows: minmax(0, 1fr) minmax(0, 1fr);

    > :last-child {
      grid-column: span 2;
    }
  `,
];

interface ContainerProps {
  numberofviewports: number;
  transparent?: boolean;
}
export const Container = styled(Box)<ContainerProps>(
  ({ theme, numberofviewports, transparent }) => `
  position: absolute;
  height: 100%;
  width: 100%;
  display: grid;
  grid-gap: 2px;
  z-index: 1;
  ${
    transparent
      ? 'background-color: transparent;'
      : `background-color: ${theme.palette.background.default};`
  }
  ${GRID_TEMPLATES[numberofviewports - 1]}
`,
);
