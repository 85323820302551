import { FormControl, FormControlLabel, FormGroup, Switch, Typography } from '@mui/material';
import { SETTINGS_NAMES, useSettingsStore } from '../Settings/store';

export const ToggleVectorHelpers = () => {
  const namePosition = SETTINGS_NAMES.VECTOR_HELPER_POSITION_ENABLED;
  const nameVelocities = SETTINGS_NAMES.VECTOR_HELPER_VELOCITIES_ENABLED;

  const toggleSetting = useSettingsStore((state) => state.toggleSetting);

  const vectorHelperPositionEnabled =
    useSettingsStore((state) => state.settings[namePosition]) || false;

  const vectorHelperVelocitiesEnabled =
    useSettingsStore((state) => state.settings[nameVelocities]) || false;

  return (
    <FormControl
      variant="standard"
      fullWidth
      sx={{
        pt: 2,
      }}
    >
      <Typography
        variant="h6"
        pb={1}
      >
        Maneuver Helper Vectors
      </Typography>
      <FormGroup
        sx={{
          pl: 2,
        }}
      >
        <FormControlLabel
          control={
            <Switch
              size="small"
              checked={vectorHelperPositionEnabled}
              onChange={() => toggleSetting(namePosition)}
            />
          }
          label="Maneuver Position Vector"
        />
        <FormControlLabel
          control={
            <Switch
              size="small"
              checked={vectorHelperVelocitiesEnabled}
              onChange={() => toggleSetting(nameVelocities)}
            />
          }
          label="Maneuver Velocity Vectors"
        />
      </FormGroup>
    </FormControl>
  );
};
