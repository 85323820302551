import { useGLTF } from '@react-three/drei';
import { useMemo } from 'react';
import { Color } from 'three';
import { DEFAULT_ADDITIONAL_PROPERTIES_GROUND_OBJECTS } from '../../../constants';
import { GroundObject } from '../../../types';

interface GroundObjectLocationProps {
  scale?: number;
  locationObject: GroundObject;
}

export const GroundObjectLocation = ({ scale = 1, locationObject }: GroundObjectLocationProps) => {
  const model = useGLTF('/models/pin.glb') as any;

  const modelPinStem = useMemo(() => model.nodes['Cylinder'].clone(), [model]);
  const modelPinHead = useMemo(() => model.nodes['Sphere'].clone(), [model]);

  const color = locationObject?.additionalProperties
    ? locationObject.additionalProperties.locationColor
    : DEFAULT_ADDITIONAL_PROPERTIES_GROUND_OBJECTS.locationColor;
  const material = modelPinHead.material.clone();
  material.color = new Color(color);
  modelPinHead.material = material;

  return (
    <group
      dispose={null}
      scale={scale * 0.002}
      rotation={[Math.PI / 2, Math.PI / 2, 0]}
    >
      <primitive object={modelPinStem} />
      <primitive object={modelPinHead} />
    </group>
  );
};
