import { Button, Grid, Typography } from '@mui/material';
import { useCallback, useEffect } from 'react';
import { LAUNCH_WINDOWS_COLOR_ACTIVE } from 'src/constants';
import { useCurrentTime } from 'src/core/hooks';
import useAppStore from 'src/core/store';
import { useCurrentPage } from 'src/hooks/PageHooks';
import { useGroundObjectLaunchEditStore } from 'src/threejs/components/GroundObjects/GroundObjectLaunchEditStore';
import { LaunchWindow } from 'src/types';
import { millisToLocale } from 'src/utilities/DateTimeUtils';
import { useEffectOnce } from 'usehooks-ts';
import { ObjectPanelDisplay } from '../ObjectPanelDisplay';

export const LaunchWindows = ({
  onBack,
  launchWindows,
  onApplyLaunch,
}: {
  onBack: () => void;
  launchWindows: LaunchWindow[];
  onApplyLaunch: (selectedWindow: LaunchWindow) => void;
}) => {
  const setActiveLaunchWindow = useGroundObjectLaunchEditStore(
    (state) => state.setActiveLaunchWindow,
  );
  const activeLaunchWindow = useGroundObjectLaunchEditStore((state) => state.activeLaunchWindow);

  const currentTime = useCurrentTime();

  // handle when currentTime is changed but doesn't match a launch window (i.e. scrub/seeking)
  useEffect(() => {
    const timeMatchesLaunchWindow = launchWindows.some((launchWindow) => {
      return currentTime === new Date(launchWindow.launchTime).getTime();
    });
    if (!timeMatchesLaunchWindow) {
      setActiveLaunchWindow(null);
    }
  }, [currentTime, launchWindows, setActiveLaunchWindow]);

  const onClickApply = useCallback(() => {
    if (activeLaunchWindow) {
      onApplyLaunch(activeLaunchWindow);
    }
  }, [onApplyLaunch, activeLaunchWindow]);

  const currentPage = useCurrentPage();
  const launchWindowsChronological = [...launchWindows]?.sort(
    (a: LaunchWindow, b: LaunchWindow) =>
      new Date(a.launchTime).getTime() - new Date(b.launchTime).getTime(),
  );

  const updateTimelineRange = useAppStore((state) => state.timelines.updateTimelineRange);

  const noLaunchWindows = launchWindows.length < 1 && (
    <Grid p={2}>
      <Typography>
        No Launch Windows were computed for this launch and between the time range:{'\n'}
      </Typography>
      <Typography fontWeight="bold">
        {millisToLocale(currentPage!.startTime.getTime(), 'utc')} -{' '}
        {millisToLocale(currentPage!.endTime.getTime(), 'utc')}
      </Typography>
    </Grid>
  );

  const onLaunchWindowChange = (win: LaunchWindow) => {
    updateTimelineRange({
      currentTime: new Date(win.launchTime).getTime(),
      playState: 'seeking',
    });
    setActiveLaunchWindow(win);
  };

  // for initial load, auto select first launch window
  useEffectOnce(() => {
    if (launchWindowsChronological?.[0]?.launchTime)
      onLaunchWindowChange(launchWindowsChronological[0]);
  });

  return (
    <Grid mt={2}>
      <Typography
        pb={2}
        pl={2}
        variant="body1"
        fontWeight="bold"
      >
        Launch Windows
      </Typography>

      {launchWindowsChronological?.map((launchWindow: LaunchWindow, idx: number) => {
        const isActive =
          !!activeLaunchWindow && launchWindow.launchTime === activeLaunchWindow.launchTime;

        return (
          <Grid
            container
            key={launchWindow.launchTime}
            onClick={() => onLaunchWindowChange(launchWindow)}
            style={{ opacity: isActive ? 1 : 0.6, cursor: 'pointer' }}
          >
            <ObjectPanelDisplay
              isActive={isActive}
              accentColor={LAUNCH_WINDOWS_COLOR_ACTIVE}
            >
              <Grid
                container
                style={{ fontFeatureSettings: '"tnum"' }}
              >
                <Typography>Time:</Typography>
                <Typography
                  color={LAUNCH_WINDOWS_COLOR_ACTIVE}
                  fontWeight="bold"
                >
                  &nbsp;{millisToLocale(new Date(launchWindow.launchTime).getTime(), 'utc')}
                </Typography>
              </Grid>
              <Grid
                container
                style={{ fontFeatureSettings: '"tnum"' }}
              >
                <Typography>Length:</Typography>
                <Typography
                  color={LAUNCH_WINDOWS_COLOR_ACTIVE}
                  fontWeight="bold"
                >
                  &nbsp;{launchWindow.launchLength} seconds
                </Typography>
              </Grid>
              <Grid
                container
                style={{ fontFeatureSettings: '"tnum"' }}
              >
                <Typography>Azimuth:</Typography>
                <Typography
                  color={LAUNCH_WINDOWS_COLOR_ACTIVE}
                  fontWeight="bold"
                >
                  &nbsp;{launchWindow.launchAzimuth}°,{' '}
                  {launchWindow.launchType[0] + launchWindow.launchType.substring(1).toLowerCase()}
                </Typography>
              </Grid>
              <Grid
                container
                style={{ fontFeatureSettings: '"tnum"' }}
              >
                <Typography>Total DeltaV:</Typography>
                <Typography
                  color={LAUNCH_WINDOWS_COLOR_ACTIVE}
                  fontWeight="bold"
                >
                  &nbsp;{launchWindow.totalDeltaV} km/s
                </Typography>
              </Grid>
            </ObjectPanelDisplay>
          </Grid>
        );
      })}

      {noLaunchWindows}

      <Grid
        container
        justifyContent="center"
        alignItems="center"
      >
        <Grid item>
          <Button
            disabled={!activeLaunchWindow}
            sx={{ m: 2 }}
            variant="contained"
            aria-label="Apply Launch"
            onClick={onClickApply}
          >
            APPLY LAUNCH
          </Button>
        </Grid>
        <Grid item>
          <Button
            color="secondary"
            variant="contained"
            onClick={onBack}
            aria-label="Back"
          >
            Back
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
};
