import { useMutation } from '@tanstack/react-query';
import { fetchApi } from 'src/services/api';
import { useApiSnackbarError } from './SnackbarHooks';

export type EphemUpload = {
  file: string;
};

export function useUploadEphemMutation() {
  const apiSnackbarError = useApiSnackbarError();

  return useMutation(
    ({ file }: EphemUpload) => {
      return fetchApi('/v2/platform/ephem', {
        method: 'POST',
        headers: { 'Content-Type': 'text/plain' },
        body: file,
      });
    },
    {
      onError: () => {
        apiSnackbarError('Failed to upload Ephemeris file.');
      },
    },
  );
}
