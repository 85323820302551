import { styled } from '@mui/material';
import { clamp } from 'lodash';
import {
  CSSProperties,
  forwardRef,
  PointerEvent,
  ReactElement,
  SyntheticEvent,
  useCallback,
  useEffect,
  useState,
} from 'react';
import { DraggableCore, DraggableData, DraggableEventHandler } from 'react-draggable';
import { ResizableBox, ResizeCallbackData } from 'react-resizable';
import theme from 'src/pages/App/Theme';
import { GAP_SIZE } from './LayoutEngine';
import { LayoutEngineItemState } from './layoutStore';

interface LayoutEngineItemProps {
  children: ReactElement;
  itemLayout: LayoutEngineItemState;
  minConstraints: [number, number];
  maxConstraints: [number, number];
  name: string;
  onLayoutChange: (updates: Record<string, Partial<LayoutEngineItemState>>) => void;
  nextZIndex: number;
  parentHeight: number;
  parentWidth: number;
}

export const LayoutEngineItem = ({
  children,
  itemLayout,
  minConstraints,
  maxConstraints,
  name,
  onLayoutChange,
  nextZIndex,
  parentHeight,
  parentWidth,
}: LayoutEngineItemProps) => {
  const [zIndex, setZIndex] = useState(itemLayout.zIndex || 0);

  const [top, setTop] = useState(0);
  const [left, setLeft] = useState(0);

  const [width, setWidth] = useState(0);
  const [height, setHeight] = useState(0);

  const getDimensionInPercentage = useCallback(
    (value: number | undefined, dimension = 'x') => {
      if (value === undefined) {
        return 0;
      } else if (value <= 1) {
        return value;
      } else {
        if (dimension === 'x') {
          return value / parentWidth;
        }
        return value / parentHeight;
      }
    },
    [parentHeight, parentWidth],
  );

  useEffect(() => {
    let heightDesired = getDimensionInPercentage(itemLayout.height, 'y');
    if (itemLayout.top > 1) {
      heightDesired -= getDimensionInPercentage(itemLayout.top, 'y');
    }
    if (itemLayout.bottom && itemLayout.bottom > 1) {
      heightDesired -= getDimensionInPercentage(itemLayout.bottom, 'y');
    }
    const heightUsable = Math.max(heightDesired, getDimensionInPercentage(minConstraints[1], 'y'));
    const topMax = 1 - heightUsable - getDimensionInPercentage(itemLayout.bottom || 0, 'y');
    const topUsable = Math.min(getDimensionInPercentage(itemLayout.top, 'y'), topMax);

    setTop(topUsable);
    setHeight(heightUsable);

    let widthDesired = getDimensionInPercentage(itemLayout.width, 'x');
    if (itemLayout.left > 1) {
      widthDesired -= getDimensionInPercentage(itemLayout.left, 'x');
    }
    if (itemLayout.right && itemLayout.right > 1) {
      widthDesired -= getDimensionInPercentage(itemLayout.right, 'x');
    }
    const widthUsable = Math.max(widthDesired, getDimensionInPercentage(minConstraints[0]));
    const leftMax = 1 - widthUsable - getDimensionInPercentage(itemLayout.right || 0);
    const leftUsable = Math.min(getDimensionInPercentage(itemLayout.left), leftMax);

    setLeft(leftUsable);
    setWidth(widthUsable);
  }, [getDimensionInPercentage, itemLayout, minConstraints, name, parentHeight, parentWidth]);

  const updateZIndex = () => {
    setZIndex(nextZIndex);
    onLayoutChange({
      layout: {
        [name]: {
          ...itemLayout,
          zIndex: nextZIndex,
        },
      },
    });
  };

  const handleOnPointerDown = (e: PointerEvent<HTMLDivElement>) => {
    updateZIndex();
  };

  const handleOnDrag: DraggableEventHandler = (event, data: DraggableData) => {
    let newTop = top + data.deltaY / parentHeight;
    let newLeft = left + data.deltaX / parentWidth;

    const topMax = 1 - height;
    newTop = clamp(newTop, 0, topMax);

    const leftMax = 1 - width;
    newLeft = clamp(newLeft, 0, leftMax);

    setTop(newTop);
    setLeft(newLeft);
  };

  const handleOnStop: DraggableEventHandler = (event, data: DraggableData) => {
    const newLayout: LayoutEngineItemState = {
      top: top,
      left: left,
      width: width,
      height: height,
      zIndex,
    };

    onLayoutChange({
      layout: {
        [name]: newLayout,
      },
    });
  };

  const handleOnResizeStop = (event: SyntheticEvent, data: ResizeCallbackData) => {
    const newLayout: LayoutEngineItemState = {
      top: top,
      left: left,
      width: data.size.width / parentWidth,
      height: data.size.height / parentHeight,
      zIndex,
    };

    onLayoutChange({
      layout: {
        [name]: newLayout,
      },
    });
  };

  const getPixelValue = (value: number, parentValue: number) => {
    if (value <= 1) {
      return value * parentValue;
    }
    return value;
  };

  // collect all styles for actual usage
  const styles: CSSProperties = {
    top: `${top * 100}%`,
    left: `${left * 100}%`,
    zIndex: zIndex,
  };

  const minConstraintsToUse: [number, number] = [
    getPixelValue(minConstraints[0], parentWidth),
    getPixelValue(minConstraints[1], parentHeight),
  ];
  const maxConstraintsToUse: [number, number] = [
    getPixelValue(maxConstraints[0], parentWidth),
    getPixelValue(maxConstraints[1], parentHeight),
  ];

  return (
    <DraggableCore
      handle=".handle"
      onDrag={handleOnDrag}
      onStop={handleOnStop}
    >
      <ResizableBoxWrapper
        style={{
          position: 'absolute',
          pointerEvents: 'all',
          ...styles,
        }}
        onPointerDown={handleOnPointerDown}
      >
        <ResizableBox
          handle={<ResizeHandle />}
          width={width * parentWidth}
          height={height * parentHeight}
          onResizeStop={handleOnResizeStop}
          minConstraints={minConstraintsToUse}
          maxConstraints={maxConstraintsToUse}
          resizeHandles={['se']}
        >
          <div
            style={{
              padding: GAP_SIZE,
              height: '100%',
              width: '100%',
            }}
          >
            {children}
          </div>
        </ResizableBox>
      </ResizableBoxWrapper>
    </DraggableCore>
  );
};

const ResizableBoxWrapper = styled('div')`
  div[class^='handle-'] {
    opacity: 0;
  }
  :hover {
    div[class^='handle-'] {
      opacity: 1;
    }
  }
`;

interface ResizeHandleProps {
  handleAxis?: string;
}

const ResizeHandle = forwardRef<HTMLDivElement, ResizeHandleProps>((props, ref) => {
  const { handleAxis, ...restProps } = props;

  const OFFSET = parseFloat(GAP_SIZE) * 0.5;

  const stylesContainer: CSSProperties = {};
  const stylesHandle: CSSProperties = {};
  if (handleAxis === 'se') {
    stylesContainer.right = OFFSET;
    stylesContainer.cursor = 'se-resize';

    stylesHandle.right = OFFSET;
    stylesHandle.borderRight = `2px solid ${theme.palette.primary.main}`;
    stylesHandle.borderBottomRightRadius = '3px';
  } else if (handleAxis === 'sw') {
    stylesContainer.left = OFFSET;
    stylesContainer.cursor = 'sw-resize';

    stylesHandle.left = OFFSET;
    stylesHandle.borderLeft = `2px solid ${theme.palette.primary.main}`;
    stylesHandle.borderTopRightRadius = '3px';
  }

  return (
    <div
      ref={ref}
      className={`handle-${handleAxis}`}
      {...restProps}
      style={{
        position: 'absolute',
        bottom: OFFSET,
        width: 16,
        height: 16,
        ...stylesContainer,
      }}
    >
      <div
        style={{
          position: 'absolute',
          bottom: OFFSET,
          width: 6,
          height: 6,
          borderBottom: `2px solid ${theme.palette.primary.main}`,
          ...stylesHandle,
        }}
      />
    </div>
  );
});
