import Denque from 'denque';
import getUVCoordsFromLatLon from 'src/threejs/math/getUVCoordsFromLatLon';
import getUVCoordsFromPointProjection from 'src/threejs/math/getUVCoordsFromPointProjection';
import { GroundTrackPoint } from 'src/types';
import { Vector2, Vector3 } from 'three';
import { MaxGroundTrackBufferSize } from '../../OrbitManager/store/utils';

type AddUvTrackPointPayload =
  | { pos: GroundTrackPoint }
  | { pos: Vector3; earthPos: Vector3; earthRot: number };

export class UvPointsStore {
  store: Record<number, Denque<Vector2>>;

  constructor() {
    this.store = {};
  }

  getQueue(id: number): Denque<Vector2> {
    if (!(id in this.store)) {
      this.store[id] = new Denque();
    }
    return this.store[id];
  }

  addPoint(id: number, payload: AddUvTrackPointPayload) {
    const queue = this.getQueue(id);

    const { uv } =
      'earthPos' in payload
        ? getUVCoordsFromPointProjection(payload.pos, payload.earthPos, payload.earthRot)
        : getUVCoordsFromLatLon(payload.pos.lon, payload.pos.lat);

    if (queue.length < MaxGroundTrackBufferSize) {
      queue.push(uv);
    } else {
      // keep size by removing oldest
      queue.shift();
      queue.push(uv);
    }
  }

  clear(id: number) {
    this.getQueue(id).clear();
  }

  replacePoints(id: number, points: Vector2[]) {
    const queue = this.getQueue(id);
    const len = queue.length;

    // erase the queue and copy the last set of elements from the array
    queue.splice(0, len, ...points.slice(-MaxGroundTrackBufferSize));
  }

  asArray(id: number) {
    return this.getQueue(id).toArray();
  }
}
