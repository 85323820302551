import { WarningRounded } from '@mui/icons-material';
import {
  Divider,
  FormControl,
  Grid,
  MenuItem,
  Select,
  SelectChangeEvent,
  SvgIcon,
  Tooltip,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { LAYOUT_ACTIVE_ID_DEFAULT, useLayoutStore } from './layoutStore';

export const LayoutSelection = () => {
  const [layoutSelected, setLayoutSelected] = useState<string | number>(LAYOUT_ACTIVE_ID_DEFAULT);

  const layouts = useLayoutStore((state) => state.layouts);

  const apiDataLoaded = useLayoutStore((state) => state.apiDataLoaded);

  const layoutActiveId = useLayoutStore((state) => state.layoutActiveId);
  const setLayoutActiveId = useLayoutStore((state) => state.setLayoutActiveId);

  useEffect(() => {
    if (apiDataLoaded && !layouts[layoutActiveId]) {
      setLayoutActiveId(LAYOUT_ACTIVE_ID_DEFAULT);
    }
  }, [apiDataLoaded, layoutActiveId, layouts, setLayoutActiveId]);

  useEffect(() => {
    if (layouts[layoutActiveId]) {
      setLayoutSelected(layoutActiveId);
    }
  }, [layoutActiveId, layouts]);

  const handleLayoutChange = (event: SelectChangeEvent<unknown>) => {
    setLayoutActiveId(event.target.value as string);
  };

  // group the ids into predefined and custom to show separately in the select menu
  const layoutIdsPredefined: Array<number | string> = [];
  const layoutIdsCustom: Array<number | string> = [];
  Object.entries(layouts).forEach(([id, layout]) => {
    if (layout.predefined) {
      layoutIdsPredefined.push(id);
    } else {
      layoutIdsCustom.push(id);
    }
  });
  layoutIdsCustom.sort((a, b) =>
    layouts[a].name.toLowerCase() > layouts[b].name.toLowerCase() ? 1 : -1,
  );

  return (
    <>
      <FormControl variant="standard">
        <Select
          value={layoutSelected}
          onChange={handleLayoutChange}
        >
          {layoutIdsPredefined.map((id) => {
            return (
              <MenuItem
                key={id}
                value={id}
              >
                {layouts[id].name}
              </MenuItem>
            );
          })}

          {layoutIdsCustom.length > 0 && <Divider />}
          {layoutIdsCustom.map((id) => {
            return (
              <MenuItem
                key={id}
                value={id}
              >
                <Grid
                  display="grid"
                  gridAutoFlow="column"
                  alignItems="center"
                  gap={1}
                >
                  {layouts[id].name}

                  {!layouts[id].saved && (
                    <Tooltip
                      arrow
                      placement="top"
                      title="Layout has unsaved changes"
                    >
                      <SvgIcon
                        fontSize="inherit"
                        color="warning"
                      >
                        <WarningRounded />
                      </SvgIcon>
                    </Tooltip>
                  )}
                </Grid>
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
    </>
  );
};
