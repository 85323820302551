import { AddRounded, SearchRounded, WarningRounded } from '@mui/icons-material';
import {
  Autocomplete,
  Button,
  CircularProgress,
  FilterOptionsState,
  Grid,
  IconButton,
  InputAdornment,
  SvgIcon,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import { differenceInMilliseconds } from 'date-fns';
import { DateTime } from 'luxon';
import { useCallback, useEffect, useRef, useState } from 'react';
import { ReactComponent as SatelliteIcon } from 'src/assets/satellite.svg';
import {
  DATE_TIME_DISPLAY_FORMAT,
  DEFAULT_ADDITIONAL_PROPERTIES_ORBIT,
  MAX_SEARCH_RESULTS,
  ORBIT_TYPES,
  PERTURBATIONS_DEFAULTS,
  TEXT_TLE_WARNING,
  WEEK_IN_MILLISECONDS,
} from 'src/constants';
import { useFetchNewPropagation } from 'src/core/hooks';
import { useSearchObjectShortNameOrId } from 'src/hooks/ObjectSearchHooks';
import { useCreateOrbitMutation, useIsPropagating, useSelectOrbit } from 'src/hooks/OrbitHooks';
import { useCurrentPage } from 'src/hooks/PageHooks';
import { useDebouncedCallback } from 'src/hooks/useDebouncedCallback';
import { ModalImportCatalogObject } from 'src/pages/Notebook/ModalImportCatalogObject';
import { OrbitObject, SpaceObject } from 'src/types';
import { getTleEpochDate } from 'src/utilities/DateTimeUtils';
import theme from '../Theme';

const removeArrayDuplicatesByObjKey = function <T>(array: T[], key: keyof T) {
  return Array.from(new Map(array.map((item) => [item[key], item])).values());
};

export const ObjectSearch = () => {
  const [options, setOptions] = useState<SpaceObject[]>([]);
  const [loading, setLoading] = useState(false);
  const [isPaginated, setIsPaginated] = useState(false);
  const [objToImport, setObjToImport] = useState<SpaceObject | null>(null);

  const inputRef = useRef<HTMLInputElement>(null);
  const [open, setOpen] = useState(false);
  const [value, setValue] = useState('');

  const currentPage = useCurrentPage();

  const previousController = useRef<AbortController>();

  const focusMe = useCallback(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, [inputRef]);

  const formattedCurrentDate = DateTime.fromJSDate(currentPage?.startTime || new Date(), {
    zone: 'utc',
  }).toFormat(DATE_TIME_DISPLAY_FORMAT);

  const noResultsStr = `No results found for page start time of ${formattedCurrentDate}`;

  const searchObjectShortNameOrId = useSearchObjectShortNameOrId();
  const onObjectSearch = useCallback(
    async (_event: React.SyntheticEvent, value: string) => {
      setLoading(true);
      setValue(value);
      if (!value) {
        setOptions([]);
        setIsPaginated(false);
        setLoading(false);
        return;
      }
      // Depending on the speed of the API, requests may not return in the order they are sent.
      // cancel previous requests when user types something new
      if (previousController.current) {
        previousController.current.abort();
      }
      const controller = new AbortController();
      const signal = controller.signal;
      previousController.current = controller;

      let streamedResults: SpaceObject[] = [];
      // stream in results with norad id initially then finish populating with slower short name regex search
      for await (const result of searchObjectShortNameOrId(
        value,
        signal,
        currentPage?.startTime || new Date(),
      )) {
        // sort results alphabetically
        result.sort((a, b) => a.OBJECT_NAME.localeCompare(b.OBJECT_NAME));

        // remove dupicate results between norad ID and short name regex queries
        streamedResults = removeArrayDuplicatesByObjKey<SpaceObject>(
          [...streamedResults, ...result],
          'NORAD_CAT_ID',
        );

        // TODO: LAB-1870 remove isPaginated check and instead utilize pagination when querying
        // rather than splicing off results >100
        setIsPaginated(streamedResults.length > MAX_SEARCH_RESULTS);
        streamedResults = streamedResults.splice(0, MAX_SEARCH_RESULTS);

        setOptions(streamedResults);
        focusMe();
      }
      if (!signal.aborted)
        // diregard effects from canceled query in favor or newer inputs
        setLoading(false);
    },
    [currentPage, focusMe, searchObjectShortNameOrId],
  );

  const onPageStartTimeChange = useCallback(() => {
    onObjectSearch({} as React.SyntheticEvent, value);
  }, [value, onObjectSearch]);

  // when the page or page start time changes re-run the search in the catalog
  useEffect(() => {
    if (value && currentPage?.id) onPageStartTimeChange();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage?.id, currentPage?.startTime.getTime()]);

  const [debouncedObjectSearch] = useDebouncedCallback(
    async (...params: [React.SyntheticEvent, string]) => onObjectSearch(...params),
    500,
  );

  const getFilterOptions = useCallback(
    (options: SpaceObject[], { inputValue }: FilterOptionsState<SpaceObject>) =>
      // search must match either norad ID or object short name to be displayed for autocomplete
      options?.filter(
        (item: SpaceObject) =>
          item?.NORAD_CAT_ID.toString().includes(inputValue) ||
          item?.OBJECT_NAME.match(new RegExp(inputValue, 'i')),
      ),
    [],
  );

  const setCurrentOrbit = useSelectOrbit();
  const createOrbitMutation = useCreateOrbitMutation();

  const createNewOrbit = useCallback(
    async (spaceObject: SpaceObject, newPageId?: number) => {
      const orbitName = spaceObject.USER_DEFINED_TLE_LINE0.substring(2);

      const tleEpochDate = getTleEpochDate(spaceObject.USER_DEFINED_TLE_LINE1);

      const result = await createOrbitMutation.mutateAsync({
        name: orbitName,
        notes: 'Notes',
        orbitType: ORBIT_TYPES.TLE,
        orbitTLE: {
          catalogId: spaceObject.NORAD_CAT_ID,
          titleLine: orbitName.trim(),
          line1: spaceObject.USER_DEFINED_TLE_LINE1,
          line2: spaceObject.USER_DEFINED_TLE_LINE2,
        },
        perturbations: PERTURBATIONS_DEFAULTS.TLE,
        groupId: null,
        additionalProperties: DEFAULT_ADDITIONAL_PROPERTIES_ORBIT,
        initialEpoch: tleEpochDate.getTime(),
        newPageId,
      });
      const newOrbit = result.orbits[0];
      return newOrbit;
    },
    [createOrbitMutation],
  );

  const fetchNewPropagation = useFetchNewPropagation();
  const isPropagating = useIsPropagating();

  const onCreateNewOrbit = async (spaceObject: SpaceObject) => {
    if (currentPage) {
      const newOrbit: OrbitObject = await createNewOrbit(spaceObject);
      setCurrentOrbit(newOrbit.id);
      if (isPropagating) {
        fetchNewPropagation(newOrbit);
      }
    } else {
      setObjToImport(spaceObject);
    }
  };

  return (
    <div>
      {objToImport && (
        <ModalImportCatalogObject
          show={!!objToImport}
          objectToImport={objToImport}
          importTLE={createNewOrbit}
          onClose={() => setObjToImport(null)}
        />
      )}
      <Autocomplete
        sx={{ width: 275, padding: 1 }}
        size="small"
        open={open}
        onBlur={() => setOpen(false)}
        onFocus={() => setOpen(true)}
        onOpen={() => setOpen(true)}
        onClose={() => setOpen(false)}
        freeSolo={value === '' || loading} // work around for showing the 'no results' text only after querying
        noOptionsText={noResultsStr}
        selectOnFocus
        clearOnEscape
        forcePopupIcon
        disableClearable
        clearOnBlur={false}
        popupIcon={<SearchRounded />}
        handleHomeEndKeys
        options={options}
        onInputChange={debouncedObjectSearch}
        getOptionLabel={(option: string | SpaceObject) => {
          if ((option as SpaceObject).OBJECT_NAME !== undefined) {
            return (option as SpaceObject).OBJECT_NAME;
          } else {
            return option.toString();
          }
        }}
        groupBy={(option) => option.QUERY_TYPE}
        filterOptions={getFilterOptions}
        renderGroup={({ group, children, key }) => (
          <li key={key}>
            <Typography
              variant="overline"
              noWrap
              sx={{ m: 1, fontWeight: 'bold' }}
              color={theme.palette.grey[400]}
            >
              {group}
            </Typography>
            {children}
          </li>
        )}
        renderOption={(_props, option) => {
          const tleEpochDate = getTleEpochDate(option.USER_DEFINED_TLE_LINE1);
          const outdatedTLE =
            currentPage?.startTime &&
            Math.abs(differenceInMilliseconds(tleEpochDate, currentPage.startTime)) >
              WEEK_IN_MILLISECONDS;
          const tleEpochDisplayedDate = DateTime.fromJSDate(tleEpochDate, {
            zone: 'utc',
          }).toFormat(DATE_TIME_DISPLAY_FORMAT);
          return (
            <Grid
              pl={1}
              pr={1}
              pb={0.5}
              pt={0.5}
              columnGap={1}
              display="grid"
              key={option.OBJECT_ID}
              gridTemplateColumns="min-content 1fr min-content"
            >
              <Grid
                display="flex"
                alignItems="center"
                justifyContent="center"
              >
                <SvgIcon
                  component={SatelliteIcon}
                  inheritViewBox
                  fontSize="small"
                />
              </Grid>
              <Grid
                display="flex"
                flexWrap="wrap"
                alignItems="center"
                flexDirection="row"
              >
                <TextField
                  sx={{
                    margin: 0,
                    flexBasis: '100%',
                    '& .MuiInputBase-input.Mui-disabled': {
                      padding: 0,
                      WebkitTextFillColor: theme.palette.text.primary,
                    },
                  }}
                  disabled
                  fullWidth
                  size="small"
                  margin="dense"
                  value={option.OBJECT_NAME}
                  variant="standard"
                  InputProps={{
                    disableUnderline: true,
                    endAdornment: outdatedTLE && (
                      <Tooltip
                        placement="top"
                        title={TEXT_TLE_WARNING + ` - TLE Epoch: ${tleEpochDisplayedDate}`}
                      >
                        <WarningRounded
                          sx={{ width: '16px', marginRight: 0.5 }}
                          color="warning"
                        />
                      </Tooltip>
                    ),
                  }}
                />
                <Typography
                  noWrap
                  variant="caption"
                  sx={{ fontWeight: 'bold' }}
                  color={theme.palette.grey[400]}
                >
                  NORAD ID: {option.NORAD_CAT_ID}
                </Typography>
              </Grid>
              <Grid
                display="flex"
                justifyContent="center"
                alignItems="center"
              >
                <Tooltip
                  placement="top"
                  title="Import as TLE space object"
                  disableInteractive
                >
                  <Button
                    color="primary"
                    variant="rounded"
                    onClick={async () => onCreateNewOrbit(option)}
                  >
                    <AddRounded />
                  </Button>
                </Tooltip>
              </Grid>
            </Grid>
          );
        }}
        renderInput={(params) => (
          <TextField
            inputRef={inputRef}
            {...params}
            variant="outlined"
            sx={{
              '& .MuiAutocomplete-popupIndicatorOpen': {
                transform: 'rotate(0deg)',
                color: theme.palette.primary.main,
              },
            }}
            label="Search for space objects"
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <>
                  <InputAdornment position="end">
                    {loading && (
                      <IconButton>
                        <CircularProgress
                          color="primary"
                          size={20}
                        />
                      </IconButton>
                    )}
                    {!loading && value !== '' && (
                      <Typography variant="body2">
                        ({`${isPaginated ? '>' : ''}${options.length}`} results)
                      </Typography>
                    )}
                  </InputAdornment>
                  {params.InputProps.endAdornment}
                </>
              ),
            }}
          />
        )}
      />
    </div>
  );
};
