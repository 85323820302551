import { useFrame } from '@react-three/fiber';
import { useRef } from 'react';
import { DEFAULT_ADDITIONAL_PROPERTIES_GROUND_OBJECTS } from 'src/constants';
import { GroundObject } from 'src/types';
import { degToRad } from 'three/src/math/MathUtils';
import { SensorCone } from '../Common/SensorCone';
import { useGroundObjectLaunchEditStore } from './GroundObjectLaunchEditStore';

export const GroundObjectCone = ({ groundObject }: { groundObject: GroundObject }) => {
  const minElevationRef = useRef(-999); // used to check if cone needs to be recomputed in useFrame
  const sensorRangeRef = useRef(-999); // used to check if cone needs to be recomputed in useFrame

  useFrame(() => {
    const { groundObjectEdit } = useGroundObjectLaunchEditStore.getState();
    let minElevationAngle = groundObject.minElevationAngle;
    let sensorRange = groundObject.sensorRange;

    // if actively editing ground object, use the ground object from edit store
    if (groundObjectEdit && groundObjectEdit.id === groundObject.id) {
      minElevationAngle = groundObjectEdit.minElevationAngle;
      sensorRange = groundObjectEdit.sensorRange;
    }

    minElevationRef.current = minElevationAngle;
    sensorRangeRef.current = sensorRange;
  });

  const getBaseRadius = () => {
    return sensorRangeRef.current * Math.cos(degToRad(minElevationRef.current));
  };

  const getHeight = () => {
    return sensorRangeRef.current * Math.sin(degToRad(minElevationRef.current));
  };

  const getConeOptions = () => {
    const { groundObjectEdit } = useGroundObjectLaunchEditStore.getState();
    if (groundObjectEdit?.id === groundObject.id) {
      return groundObjectEdit?.additionalProperties;
    }
    return groundObject.additionalProperties;
  };

  const getConeWireframeOpacity = () =>
    (getConeOptions()?.coneWireframeOpacity ??
      groundObject?.additionalProperties?.coneWireframeOpacity ??
      DEFAULT_ADDITIONAL_PROPERTIES_GROUND_OBJECTS.coneWireframeOpacity) / 100;

  const getConeShaderOpacity = () =>
    (getConeOptions()?.coneShaderOpacity ??
      groundObject?.additionalProperties?.coneShaderOpacity ??
      DEFAULT_ADDITIONAL_PROPERTIES_GROUND_OBJECTS.coneShaderOpacity) / 100;

  const getConeShaderColor = () =>
    getConeOptions()?.coneShaderColor ??
    groundObject?.additionalProperties?.coneShaderColor ??
    DEFAULT_ADDITIONAL_PROPERTIES_GROUND_OBJECTS.coneShaderColor;

  const getConeShaderEnabled = () =>
    getConeOptions()?.visGroundObjConeShader ??
    groundObject?.additionalProperties?.visGroundObjConeShader ??
    DEFAULT_ADDITIONAL_PROPERTIES_GROUND_OBJECTS.visGroundObjConeShader;

  const getConeWireframeColor = () =>
    getConeOptions()?.coneWireframeColor ??
    groundObject?.additionalProperties?.coneWireframeColor ??
    DEFAULT_ADDITIONAL_PROPERTIES_GROUND_OBJECTS.coneWireframeColor;

  const getConeWireframeEnabled = () =>
    getConeOptions()?.visGroundObjConeWireframe ??
    groundObject?.additionalProperties?.visGroundObjConeWireframe ??
    DEFAULT_ADDITIONAL_PROPERTIES_GROUND_OBJECTS.visGroundObjConeWireframe;

  return (
    <SensorCone
      getBaseRadius={getBaseRadius}
      getHeight={getHeight}
      getConeShaderColor={getConeShaderColor}
      getConeShaderEnabled={getConeShaderEnabled}
      getConeShaderOpacity={getConeShaderOpacity}
      getConeWireframeColor={getConeWireframeColor}
      getConeWireframeEnabled={getConeWireframeEnabled}
      getConeWireframeOpacity={getConeWireframeOpacity}
      domeEnabled={true}
      rotation={[Math.PI * 0.5, 0, 0]}
    />
  );
};
