import { PersonRemoveRounded } from '@mui/icons-material';
import { IconButton } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { useState } from 'react';
import { DATAGRID_ROWS_PER_PAGE_DEFAULT, DATAGRID_ROWS_PER_PAGE_OPTIONS } from 'src/constants';
import { useOnbaordingUsersByOrgIdQuery } from 'src/hooks/OnboardingHooks';
import { OrganizationUser } from 'src/types';
import { ModalRemoveUserFromOrg } from './ModalRemoveUserFromOrg';

interface UsersProps {
  orgId: string;
}

export const Users = ({ orgId }: UsersProps) => {
  const [modalOpen, setModalOpen] = useState(false);
  const [activeUser, setActiveUser] = useState<OrganizationUser>();

  const usersInOrg = useOnbaordingUsersByOrgIdQuery(orgId);

  const handleRemoveClick = (user: OrganizationUser) => {
    setActiveUser(user);
    setModalOpen(true);
  };

  return (
    <>
      <ModalRemoveUserFromOrg
        open={modalOpen}
        show={setModalOpen}
        orgId={orgId}
        user={activeUser}
      />
      <DataGrid
        columns={[
          {
            field: 'name',
            headerName: 'Name',
            flex: 1,
          },
          {
            field: 'email',
            headerName: 'Email',
            flex: 2,
          },
          {
            field: 'delete',
            headerName: ' ',
            width: 100,
            renderCell: (params) => {
              return (
                <IconButton
                  onClick={() => {
                    handleRemoveClick(params.row);
                  }}
                >
                  <PersonRemoveRounded />
                </IconButton>
              );
            },
          },
        ]}
        rows={usersInOrg?.users || []}
        autoHeight={true}
        disableColumnMenu={true}
        hideFooterSelectedRowCount={true}
        pageSizeOptions={DATAGRID_ROWS_PER_PAGE_OPTIONS}
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: DATAGRID_ROWS_PER_PAGE_DEFAULT,
            },
          },
          sorting: {
            sortModel: [{ field: 'email', sort: 'asc' }],
          },
        }}
      />
    </>
  );
};
