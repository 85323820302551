export const coeInfo = {
  SMA: {
    notation: '(a)',
    text: 'SMA stands for semi-major axis, a quantity related to how large an orbit is. On a circular orbit the semi-major axis is the radial distance from the center of the Earth to any point along the orbit. For an elliptical orbit, the semi-major axis is half of the distance between the point along the orbit that is closest to the Earth  (perigee) and the point along the orbit that is farthest from the Earth (apogee).',
  },
  ECC: {
    notation: '(e)',
    text: "ECC stands for eccentricity,  a measure of how much an orbit deviates from a perfect circle. An eccentricity of zero means the orbit is circular. As you increase the value of the orbit's eccentricity, the shortest width of the ellipse decreases.",
  },
  INC: {
    notation: '(i)',
    text: "INC refers to the orbit's inclination, a measure of how tilted the plane of the orbit is relative to the reference plane, e.g. the Earth's equatorial plane",
  },
  RAAN: {
    notation: '(Ω)',
    text: 'RAAN stands for right ascension of the ascending node, an angle that locates where the orbital plane intersects the equatorial plane.  This line of intersection is called the "line of nodes" and the points along the orbit that fall on this line are the ascending and descending nodes. The terms "ascending" and "descending" simply indicate the direction in which the spacecraft moves in relation to the reference plane. Ascending means the spacecraft is moving upwards towards the northern half of the equatorial plane. Descending means the opposite, the spacecraft is moving towards the southern part of the equatorial plane.',
  },
  AP: {
    notation: '(ω)',
    text: "AP stands for Argument of periapsis, an angle that locates the point along the orbit that is closest to the Earth. The angle is measured in the plane of the orbit and relative to the ascending node's location.",
  },
  TA: {
    notation: '(ν)',
    text: 'TA is the true anomaly, an angle used to locate the spacecraft along the orbit. It is an angle measured in the plane of the orbit and relative to the point that is closest to the Earth, perigee.',
  },
  EPOCH: {
    notation: '',
    text: "The epoch of a spacecraft is the time and date corresponding to a specified orbit state.   In LAB, it is related to the initial state defined by the COE's.",
  },
};
