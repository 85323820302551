import { SvgIcon, Tab, Tooltip } from '@mui/material';
import { grey } from '@mui/material/colors';
import { ReactNode, SyntheticEvent, useCallback } from 'react';
import theme from 'src/pages/App/Theme';

interface InspectorTabProps {
  label: string;
  value: string;
  valueCurrentTab: string;
  isExpanded: boolean;
  icon: ReactNode;
  onChange: (_event: SyntheticEvent, newValue: string) => void;
}

export const InspectorTab = ({
  label,
  value,
  valueCurrentTab,
  isExpanded,
  icon,
  onChange,
}: InspectorTabProps) => {
  const handleOnClick = useCallback(
    (_event: SyntheticEvent) => {
      onChange(_event, value);
    },
    [onChange, value],
  );

  return (
    <Tab
      value="properties"
      label={isExpanded && label}
      onClick={handleOnClick}
      icon={
        <Tooltip
          arrow
          disableInteractive
          title={label}
          placement="left"
          disableHoverListener={isExpanded} // don't show tooltip on hover if label is expanded
        >
          <SvgIcon
            sx={{
              color: valueCurrentTab === value ? theme.palette.text.primary : grey[700],
              fill: valueCurrentTab === value ? theme.palette.text.primary : grey[700],
            }}
          >
            {icon}
          </SvgIcon>
        </Tooltip>
      }
      sx={{
        minWidth: 'initial',
        opacity: 1,
      }}
    />
  );
};
