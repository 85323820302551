import { ExpandMoreRounded as ExpandMoreIcon } from '@mui/icons-material';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
} from '@mui/material';
import { SPACE_OBJECT_SIZE_DEFAULT } from 'src/constants';
import { useCurrentOrbit, useUpdateOrbitAdditionalProperties } from 'src/hooks/OrbitHooks';
import { SpaceObjectSize } from 'src/types';

export function SpaceObjectSizeOptions() {
  const updateOrbitAdditionalProperties = useUpdateOrbitAdditionalProperties();
  const currentOrbit = useCurrentOrbit();

  const handleChange = (event: SelectChangeEvent) => {
    if (currentOrbit) {
      const changes = { spaceObjectSize: event.target.value as SpaceObjectSize };
      updateOrbitAdditionalProperties(currentOrbit, changes);
    }
  };

  if (!currentOrbit) {
    return null;
  }

  const currentSpaceObjectSize =
    currentOrbit.additionalProperties?.spaceObjectSize || SPACE_OBJECT_SIZE_DEFAULT;

  return (
    <Accordion defaultExpanded>
      <AccordionSummary
        style={{
          flexDirection: 'row-reverse',
        }}
        expandIcon={<ExpandMoreIcon />}
        aria-controls="inspector-panel-visual"
        id="inspector-panel-visual"
      >
        Space Object Size
      </AccordionSummary>
      <AccordionDetails>
        <Grid
          container
          px={2}
        >
          <FormControl
            variant="standard"
            fullWidth
          >
            <InputLabel id="orbit-track-length-label">Size</InputLabel>
            <Select
              labelId="demo-simple-select-standard-label"
              id="demo-simple-select-standard"
              value={currentSpaceObjectSize}
              onChange={handleChange}
              label="Space Object Sizes"
              fullWidth
            >
              <MenuItem value={SpaceObjectSize.SM}>Small</MenuItem>
              <MenuItem value={SpaceObjectSize.MD}>Medium</MenuItem>
              <MenuItem value={SpaceObjectSize.LG}>Large</MenuItem>
            </Select>
          </FormControl>
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
}
