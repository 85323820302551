import { OrbitObject, StateVectorType } from 'src/types';
import { DoubleSide, Mesh, Vector3 } from 'three';
import { useIsPropagating } from '../../../hooks/OrbitHooks';
import {
  DEFAULT_ADDITIONAL_PROPERTIES_ORBIT,
  GROUND_TRACK_OBJECT_SCALE_FACTOR,
  ORBIT_TYPES,
} from '../../../constants';
import use3DOrbitStore from '../OrbitManager/store/store';
import { useCallback, useState } from 'react';
import { useCurrentPage } from '../../../hooks/PageHooks';
import getEarthRotation from '../../math/getEarthRotation';
import getTrueAnomalyPosition from '../../math/getTrueAnomalyPosition';
import { useFrame } from '@react-three/fiber';
import { convertLatLongToCartesian } from '../../math/convertLatLongToCartesian';

const GROUND_TRACK_DISTANCE_FROM_ORIGIN = 1;

interface SubsatellitePointProps {
  orbit: OrbitObject;
}

export const SubsatellitePoint = ({ orbit }: SubsatellitePointProps) => {
  const isPropagating = useIsPropagating();
  const currentPage = useCurrentPage();
  const orbitState = use3DOrbitStore((state) => state.orbits[orbit.id]);
  const activeSV = use3DOrbitStore((state) => state.orbits[orbit.id]?.activeStateVector || null);

  const [refMesh, setRefMesh] = useState<Mesh | null>(null);

  const updatePointsPreview = useCallback(() => {
    if (refMesh) {
      const { x, y, z } = getTrueAnomalyPosition({ ...orbitState.coe.toRad() });
      const currentVert = new Vector3(x, y, z);
      currentVert
        .applyAxisAngle(new Vector3(0, 1, 0), -getEarthRotation(currentPage!.startTime.getTime()!))
        .setLength(GROUND_TRACK_DISTANCE_FROM_ORIGIN);
      refMesh.position.copy(currentVert);
    }
  }, [currentPage, orbitState.coe, refMesh]);

  const updatePoints = useCallback(
    (activeSV: StateVectorType) => {
      if (refMesh) {
        const point = convertLatLongToCartesian(
          activeSV.groundTrack.lat,
          activeSV.groundTrack.lon,
        ).setLength(GROUND_TRACK_DISTANCE_FROM_ORIGIN);
        refMesh.position.set(point.x, point.y, point.z);
      }
    },
    [refMesh],
  );

  useFrame(() => {
    if (isPropagating) {
      if (activeSV) {
        updatePoints(activeSV);
      }
    } else {
      updatePointsPreview();
    }
  });

  // launch orbits are used for the payloads, so when not propagating, it's only used for position, no track to see
  if (!isPropagating && orbit.orbitType === ORBIT_TYPES.LAUNCH) {
    return null;
  }

  const shapeSize: any = [0.33, 16, 8];
  const currentSpaceObjectColor =
    orbit?.additionalProperties?.spaceObjectColor ||
    DEFAULT_ADDITIONAL_PROPERTIES_ORBIT.spaceObjectColor;

  return (
    <>
      <mesh
        ref={setRefMesh}
        renderOrder={isPropagating ? 1 : 999}
        scale={GROUND_TRACK_OBJECT_SCALE_FACTOR}
      >
        <sphereGeometry args={shapeSize} />
        <meshBasicMaterial
          side={DoubleSide}
          opacity={0.85}
          depthWrite={false}
          transparent
          color={currentSpaceObjectColor}
        />
      </mesh>
    </>
  );
};
