import { CircularProgress, Tooltip } from '@mui/material';
import {
  PlayArrowRounded as PlayArrowIcon,
  SkipNextRounded as SkipNextIcon,
  SkipPreviousRounded as SkipPreviousIcon,
  PauseRounded as PauseIcon,
  EventAvailableRounded,
} from '@mui/icons-material';
import {
  formatDateWithMs,
  shiftDateToUTC,
  unshiftDateFromUTC,
  validDate,
} from 'src/utilities/DateTimeUtils';
import { useClearPropagations, useFetchPropagations, useTimeline } from 'src/core/hooks';
import { useIsReadOnly } from 'src/hooks/SharedNotebookHooks';
import { useIsPropagating } from 'src/hooks/OrbitHooks';
import { useCurrentPage, useIsValidTimelineRange } from 'src/hooks/PageHooks';
import {
  ControlsContainer,
  PlayContainer,
  Wrapper,
  ClearPropagationButton,
  ControlButton,
} from 'src/pages/Notebook/components/TimelineControls.styled';
import TimelineEventManager from 'src/models/TimelineEventManager';
import { useCallback, useEffect, useMemo, useState } from 'react';
import WebSocketManager from 'src/services/WebSocketManager';
import { useSnackbar } from 'notistack';
import useAppStore from 'src/core/store';
import { DateTimePicker } from './DateTimePicker';
import { ModalStartPropagation } from '../ModalStartPropagation';

function SelectedTimePicker() {
  const timelineState = useTimeline();
  const updateTimelineRange = useAppStore((state) => state.timelines.updateTimelineRange);

  const currentPage = useCurrentPage();

  const isReadOnly = useIsReadOnly();

  const onTimelineTimeChange = useCallback(
    (date: Date) => {
      if (!validDate(date) || !currentPage) {
        return;
      }

      const timeLocal = unshiftDateFromUTC(date);

      updateTimelineRange({ currentTime: timeLocal.getTime() });
    },
    [currentPage, updateTimelineRange],
  );

  const startDateTimeUTC: Date = useMemo(
    () => shiftDateToUTC(new Date(currentPage?.startTime || Date.now())),
    [currentPage?.startTime],
  );

  const timelineCurrentDateTime = useMemo(
    () => shiftDateToUTC(new Date(timelineState.timelineRange.currentTime)),
    [timelineState.timelineRange.currentTime],
  );

  const currentDateTimeUTC = useMemo(
    () =>
      timelineCurrentDateTime instanceof Date && !isNaN(timelineCurrentDateTime.getTime())
        ? timelineCurrentDateTime
        : startDateTimeUTC,
    [timelineCurrentDateTime, startDateTimeUTC],
  );
  const currentUTCDateTime = useMemo(
    () => formatDateWithMs(currentDateTimeUTC),
    [currentDateTimeUTC],
  );

  const endDateTimeUTC: Date = useMemo(
    () => shiftDateToUTC(new Date(currentPage?.endTime || Date.now())),
    [currentPage?.endTime],
  );

  const isPropagating = useIsPropagating();
  const [modalStartPropVisible, setModalStartPropVisible] = useState(false);
  const startPropagationModal = useCallback(() => {
    if (!isPropagating) {
      setModalStartPropVisible(true);
    }
  }, [isPropagating]);

  const {
    timelineRange: { isStopped },
  } = useTimeline();

  const onEditMode = useCallback(() => {
    if (!isPropagating) {
      startPropagationModal();
      return false;
    } else {
      updateTimelineRange({ playState: 'stopped' });
    }
    return true;
  }, [isPropagating, startPropagationModal, updateTimelineRange]);

  return (
    <>
      <ModalStartPropagation
        show={modalStartPropVisible}
        onClose={() => setModalStartPropVisible(false)}
      />
      <div onClick={onEditMode}>
        <DateTimePicker
          label="Current Time (UTC)"
          disabled={isReadOnly || (isPropagating && !isStopped) || !isPropagating}
          minDate={startDateTimeUTC}
          maxDate={endDateTimeUTC}
          inputVal={currentUTCDateTime}
          onChange={onTimelineTimeChange}
          pickerIcon={<EventAvailableRounded style={{ color: 'white' }} />}
        />
      </div>
    </>
  );
}

export default function TimelineControls() {
  const timelineState = useTimeline();
  const isPropagating = useIsPropagating();
  const isValidTimlineRange = useIsValidTimelineRange();
  const currentPage = useCurrentPage();
  const isReadOnly = useIsReadOnly();
  const { closeSnackbar } = useSnackbar();

  const { updateTimelineRange } = timelineState;
  const { isSeeking, isLoading, isPlaying, isStopped } = timelineState.timelineRange;
  const fetchPropagations = useFetchPropagations();

  const togglePropForward = () => {
    if (isStopped) {
      if (!isPropagating) {
        fetchPropagations();
      } else {
        timelineState.updateTimelineRange({
          playState: 'playing',
        });
      }
    } else {
      onStop();
    }
  };

  const clearPropagations = useClearPropagations();

  const onStop = () => {
    timelineState?.updateTimelineRange({
      playState: 'stopped',
    });
  };

  const onSetTimeToStart = () => {
    const startTime = currentPage?.startTime.getTime();
    timelineState?.updateTimelineRange({
      currentTime: startTime,
    });
    TimelineEventManager.publish('jumpToStart', startTime);
  };

  const onSetTimeToEnd = () => {
    const endTime = currentPage?.endTime.getTime();
    timelineState?.updateTimelineRange({
      currentTime: endTime,
    });
    TimelineEventManager.publish('jumpToEnd', endTime);
  };

  useEffect(() => {
    const unsubscribeFromStompError = WebSocketManager.subscribeToStompError(() => {
      updateTimelineRange({
        playState: 'stopped',
      });
    });
    return unsubscribeFromStompError;
  }, [updateTimelineRange]);

  return (
    <Wrapper>
      <ControlsContainer>
        <ControlButton
          disabled={!isPropagating || isLoading}
          aria-label="Skip to Beginning"
          onClick={() => onSetTimeToStart()}
        >
          <SkipPreviousIcon />
        </ControlButton>
        <ControlButton
          disabled={!isPropagating || isLoading}
          aria-label="Skip to End"
          onClick={() => onSetTimeToEnd()}
        >
          <SkipNextIcon />
        </ControlButton>
        <PlayContainer>
          {isLoading || isSeeking ? (
            <CircularProgress
              size={20}
              thickness={5}
              color={isSeeking ? 'info' : 'primary'}
              sx={{
                mr: 0.5,
              }}
            />
          ) : (
            <ControlButton
              aria-label={isPlaying ? 'Stop' : 'Play'}
              onClick={togglePropForward}
              disabled={!isValidTimlineRange}
            >
              {isPlaying ? (
                <PauseIcon data-test="timeline-controls-stop-button" />
              ) : (
                <PlayArrowIcon data-test="timeline-controls-play-button" />
              )}
            </ControlButton>
          )}
          <Tooltip title="Clears propagation data and enables configuration">
            <span>
              <ClearPropagationButton
                disabled={!isPropagating || isReadOnly}
                onClick={() => {
                  closeSnackbar('propagationerror');
                  clearPropagations();
                  updateTimelineRange({ currentTimePreviewMode: currentPage!.startTime.getTime() });
                }}
              >
                Clear propagation
              </ClearPropagationButton>
            </span>
          </Tooltip>
        </PlayContainer>
      </ControlsContainer>

      <SelectedTimePicker />
    </Wrapper>
  );
}
