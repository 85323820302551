import { useCurrentPage } from 'src/hooks/PageHooks';
import useSingleDependencyEffect from 'src/hooks/useSingleDependencyEffect';

/** Triggers a callback whenever the current page changes. */
function useOnPageChange(callback: (pageId?: number) => void) {
  const currentPage = useCurrentPage();
  useSingleDependencyEffect(callback, currentPage?.id);
}

export default useOnPageChange;
