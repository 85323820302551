import { ExpandMoreRounded as ExpandMoreIcon } from '@mui/icons-material';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
} from '@mui/material';
import { SPACE_OBJECT_SHAPE_DEFAULT } from 'src/constants';
import { useCurrentOrbit, useUpdateOrbitAdditionalProperties } from 'src/hooks/OrbitHooks';
import { SpaceObjectShape } from 'src/types';

export function SpaceObjectShapeOptions() {
  const updateOrbitAdditionalProperties = useUpdateOrbitAdditionalProperties();
  const currentOrbit = useCurrentOrbit();

  const handleChange = (event: SelectChangeEvent) => {
    if (currentOrbit) {
      const changes = { spaceObjectShape: event.target.value as SpaceObjectShape };
      updateOrbitAdditionalProperties(currentOrbit, changes);
    }
  };

  if (!currentOrbit) {
    return null;
  }

  const currentSpaceObjectShape =
    currentOrbit.additionalProperties?.spaceObjectShape || SPACE_OBJECT_SHAPE_DEFAULT;

  return (
    <Accordion defaultExpanded>
      <AccordionSummary
        style={{
          flexDirection: 'row-reverse',
        }}
        expandIcon={<ExpandMoreIcon />}
        aria-controls="inspector-panel-visual"
        id="inspector-panel-visual"
      >
        Space Object Shape
      </AccordionSummary>
      <AccordionDetails>
        <Grid
          container
          px={2}
        >
          <FormControl
            variant="standard"
            fullWidth
          >
            <InputLabel id="orbit-track-length-label">Shapes</InputLabel>
            <Select
              labelId="demo-simple-select-standard-label"
              id="demo-simple-select-standard"
              value={currentSpaceObjectShape}
              onChange={handleChange}
              label="Space Object Shape"
              fullWidth
            >
              <MenuItem value={SpaceObjectShape.BALL}>Ball</MenuItem>
              <MenuItem value={SpaceObjectShape.CUBE}>Cube</MenuItem>
              <MenuItem value={SpaceObjectShape.CONE}>Cone</MenuItem>
            </Select>
          </FormControl>
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
}
