import { ExpandMoreRounded as ExpandMoreIcon } from '@mui/icons-material';
import { Accordion, AccordionDetails, AccordionSummary } from '@mui/material';
import { ORBIT_COLOR_DEFAULT, SWATCH_PRESETS } from 'src/constants';
import { useCurrentOrbit, useUpdateOrbitAdditionalProperties } from 'src/hooks/OrbitHooks';
import { ColorSelector } from './Settings/ColorSelector';

export function ObjectColorPicker() {
  const currentOrbit = useCurrentOrbit();

  const updateOrbitAdditionalProperties = useUpdateOrbitAdditionalProperties();

  const setObjectColor = (color: string) => {
    if (currentOrbit) {
      const changes = { color };
      updateOrbitAdditionalProperties(currentOrbit, changes);
    }
  };

  if (!currentOrbit) {
    return null;
  }

  return (
    <Accordion defaultExpanded>
      <AccordionSummary
        style={{
          flexDirection: 'row-reverse',
        }}
        expandIcon={<ExpandMoreIcon />}
        aria-controls="inspector-panel-visual"
        id="inspector-panel-visual"
      >
        Orbit Trail
      </AccordionSummary>
      <AccordionDetails>
        <ColorSelector
          currentColor={currentOrbit.additionalProperties?.color || ORBIT_COLOR_DEFAULT}
          presets={SWATCH_PRESETS}
          setColor={setObjectColor}
        />
      </AccordionDetails>
    </Accordion>
  );
}
