import { EventRounded, TodayRounded } from '@mui/icons-material';
import { Box, Grid } from '@mui/material';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import addDays from 'date-fns/addDays';
import { useCallback, useMemo } from 'react';
import Webcam from 'react-webcam';
import { PanelHeader } from 'src/components/PanelHeader';
import { SpaceGlassPanel } from 'src/components/SpaceGlassPanel';
import useAppStore from 'src/core/store';
import { useIsPropagating } from 'src/hooks/OrbitHooks';
import { useCurrentPage, useUpdatePageMutation } from 'src/hooks/PageHooks';
import { useIsReadOnly } from 'src/hooks/SharedNotebookHooks';
import TimelineControls from 'src/pages/Notebook/components/TimelineControls';
import { SETTINGS_NAMES, useSettingsStore } from 'src/pages/Settings/store';
import {
  ensureValidRange,
  formatDateWithMs,
  shiftDateToUTC,
  unshiftDateFromUTC,
  validDate,
} from 'src/utilities/DateTimeUtils';
import { DateTimePicker } from './DateTimePicker';
import { StoryModeSelection } from './StoryTelling/StoryModeSelection';
import { TimelineSlider } from './TimelineSlider';
import { TimelineSpeedSelect } from './TimelineSpeedSelect';

function StartTimePicker() {
  const currentPage = useCurrentPage();
  const updatePage = useUpdatePageMutation();
  const updateTimelineRange = useAppStore((state) => state.timelines.updateTimelineRange);

  const isReadOnly = useIsReadOnly();
  const isPropagating = useIsPropagating();

  const onTimelineStartDateChange = useCallback(
    (date: Date) => {
      if (!validDate(date)) {
        return;
      }

      if (currentPage && date) {
        const endDate = currentPage.endTime;
        const startTimeLocal = unshiftDateFromUTC(new Date(date));
        const endTime = ensureValidRange(startTimeLocal, endDate)('end');

        updatePage.mutate({
          ...currentPage,
          startTime: startTimeLocal,
          endTime,
        });

        updateTimelineRange({
          currentTime: startTimeLocal.getTime(),
          currentTimePreviewMode: startTimeLocal.getTime(),
        });
      }
    },
    [currentPage, updatePage, updateTimelineRange],
  );

  const startDateTimeUTC: Date = useMemo(
    () => shiftDateToUTC(new Date(currentPage?.startTime || Date.now())),
    [currentPage?.startTime],
  );
  const startTimeUTCDateTime = useMemo(
    () => formatDateWithMs(startDateTimeUTC),
    [startDateTimeUTC],
  );

  return (
    <DateTimePicker
      label="Start (UTC)"
      onChange={onTimelineStartDateChange}
      disabled={isReadOnly || isPropagating}
      inputVal={startTimeUTCDateTime || formatDateWithMs(new Date())}
      pickerIcon={<TodayRounded style={{ color: 'white' }} />}
    />
  );
}

function EndTimePicker() {
  const currentPage = useCurrentPage();
  const updatePage = useUpdatePageMutation();
  const updateTimelineRange = useAppStore((state) => state.timelines.updateTimelineRange);

  const isReadOnly = useIsReadOnly();
  const isPropagating = useIsPropagating();

  const onTimelineEndDateChange = useCallback(
    (date: Date) => {
      if (!validDate(date)) {
        return;
      }

      const endDate = date;

      if (currentPage && endDate) {
        const startDate = currentPage.startTime;
        const endTimeLocal = unshiftDateFromUTC(new Date(endDate));
        const startTime = ensureValidRange(startDate, endTimeLocal)('start');

        updatePage.mutate({
          ...currentPage,
          endTime: endTimeLocal,
          startTime,
        });

        updateTimelineRange({
          currentTime: startTime.getTime(),
          currentTimePreviewMode: startTime.getTime(),
        });
      }
    },
    [currentPage, updatePage, updateTimelineRange],
  );

  const startDateTimeUTC: Date = useMemo(
    () => shiftDateToUTC(new Date(currentPage?.startTime || Date.now())),
    [currentPage?.startTime],
  );

  const endDateTimeUTC: Date = useMemo(
    () => shiftDateToUTC(new Date(currentPage?.endTime || Date.now())),
    [currentPage?.endTime],
  );
  const endTimeUTCDateTime = useMemo(() => formatDateWithMs(endDateTimeUTC), [endDateTimeUTC]);

  return (
    <DateTimePicker
      label="End (UTC)"
      minDate={startDateTimeUTC}
      disabled={isReadOnly || isPropagating}
      inputVal={endTimeUTCDateTime || formatDateWithMs(addDays(new Date(), 1))}
      onChange={onTimelineEndDateChange}
      pickerIcon={<EventRounded style={{ color: 'white' }} />}
    />
  );
}

interface TimelineViewProps {
  variant?: string;
}

export default function TimelineView(props: TimelineViewProps) {
  const cameraEnabled = useSettingsStore(
    (state) => state.settings[SETTINGS_NAMES.VIDEO_CAMERA_ENABLED] || false,
  );

  return (
    <Grid
      display="grid"
      gridAutoFlow="column"
      alignItems="end"
      gridTemplateColumns={cameraEnabled ? 'min-content 1fr' : ''}
      gap="10px"
      height="100%"
    >
      {cameraEnabled && (
        <Webcam
          style={{
            borderRadius: '10px',
            objectFit: 'cover',
            width: 'calc(16.67vw - 15px)',
            height: '190px',
          }}
        />
      )}
      <Box
        component="div"
        sx={{
          height: props.variant === 'simple' ? 'min-content' : '100%',
        }}
      >
        <SpaceGlassPanel header={<PanelHeader title="Timeline" />}>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <Box
              component="div"
              display="flex"
              flexDirection="column"
              justifyContent="space-between"
              style={{
                height: '100%',
                padding: '12px 24px',
              }}
            >
              <Grid
                container
                justifyContent="space-between"
              >
                <TimelineControls />
                <StoryModeSelection />
              </Grid>

              {props.variant !== 'simple' && (
                <Box
                  component="div"
                  display="flex"
                  flexDirection="column"
                >
                  <Box
                    component="div"
                    sx={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      spacing: 2,
                    }}
                  >
                    <Grid
                      container
                      spacing={2}
                    >
                      <Grid item>
                        <StartTimePicker />
                      </Grid>
                      <Grid item>
                        <EndTimePicker />
                      </Grid>
                    </Grid>

                    <TimelineSpeedSelect />
                  </Box>
                </Box>
              )}

              <TimelineSlider />
            </Box>
          </LocalizationProvider>
        </SpaceGlassPanel>
      </Box>
    </Grid>
  );
}
