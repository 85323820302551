import { Grid, SxProps, Theme } from '@mui/material';
import { forwardRef } from 'react';
import { useIsPropagating } from 'src/hooks/OrbitHooks';
import theme from 'src/pages/App/Theme';
import { LabelWithTooltip } from 'src/pages/Shared/LabelWithTooltip';

type LiveDataTableItemProps = {
  /** The header name to be displayed to the user. */
  name: string;
  /** The unit to be displayed accross from the header. */
  headerUnit: string;
  /** Whether to only display the current value as a header field or just a normal display field */
  headerOnly?: boolean;
  tooltip?: string;
};

interface TableItemNumberProps {
  styles?: SxProps<Theme>;
  children?: React.ReactNode;
}

const TableItemNumber = forwardRef((props: TableItemNumberProps, ref: any) => {
  return (
    <Grid
      sx={{
        fontSize: 12,
        fontFeatureSettings: '"tnum"',
        ...props.styles,
      }}
      ref={ref}
    >
      {props.children}
    </Grid>
  );
});

export const LiveDataRelativeItem = forwardRef<HTMLDivElement | null, LiveDataTableItemProps>(
  ({ name, tooltip, headerOnly, headerUnit }, ref) => {
    const isPropagating = useIsPropagating();

    return (
      <Grid
        display="grid"
        gridAutoFlow="column"
        alignItems="center"
        justifyContent="space-between"
        pt={1}
      >
        <Grid
          gap={1}
          fontSize={12}
          fontWeight={headerOnly ? 'bold' : 'initial'}
          color={headerOnly ? 'white' : theme.palette.text.secondary}
        >
          {!tooltip && <span>{name}</span>}

          {tooltip && (
            <LabelWithTooltip
              label={name}
              title={tooltip}
            />
          )}
        </Grid>

        <Grid
          display="grid"
          gridAutoFlow="column"
        >
          <TableItemNumber
            ref={ref}
            styles={{
              color: isPropagating ? theme.palette.primary.main : theme.palette.grey[400],
            }}
          />

          {headerOnly && (
            <Grid
              fontSize={12}
              color={theme.palette.text.secondary}
            >
              {`\u00A0${headerUnit}`}
            </Grid>
          )}
        </Grid>
      </Grid>
    );
  },
);
