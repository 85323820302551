import { Box, Grid, InputAdornment, Slider, TextField } from '@mui/material';
import { capitalize } from 'lodash';
import { ChangeEvent, memo, useCallback, useEffect, useState } from 'react';
import { COE_PROP_Type, LABCoePropType } from 'src/enums';
import { useIsPropagating } from 'src/hooks/OrbitHooks';
import { LabelWithTooltip } from 'src/pages/Shared/LabelWithTooltip';
import {
  useCurrent3DOrbitCOEValue,
  useCurrent3DOrbitCoeUpdater,
} from 'src/threejs/components/OrbitManager/store/hooks';
import { COE } from 'src/threejs/models/COE';
import { number } from 'yup';
import { COEHeaderTypography, COENotationTypography } from './InspectorPanel.styled';

type CoeParameterProps = {
  name: string;
  displayName: string;
  notation: string;
  tooltipText: string;
  type: LABCoePropType;
  unit: string;
  step: number;
  min: number;
  max: number;
  selector: (coe?: COE) => number | undefined;
  saveCurrentOrbit: () => void;
};

export const CoeParameter = memo(
  ({
    name,
    displayName,
    tooltipText,
    notation,
    type,
    unit,
    step,
    min,
    max,
    selector,
    saveCurrentOrbit,
  }: CoeParameterProps) => {
    const isPropagating = useIsPropagating();
    const [errorMessage, setErrorMessage] = useState<string>();
    const updateCOE = useCurrent3DOrbitCoeUpdater();
    const coeValue = useCurrent3DOrbitCOEValue(selector);

    const [isValid, setIsValid] = useState(true);

    const validation = number().required().min(min).max(max);

    const [textFieldValue, setTextFieldValue] = useState(String(coeValue));
    const [sliderVal, setSliderVal] = useState(Number(coeValue) || 0);

    useEffect(() => {
      if (!isNaN(Number(coeValue))) {
        // if coeValue isNaN, don't use
        if (Number(textFieldValue) !== Number(coeValue)) {
          setTextFieldValue(String(coeValue));
        }
        if (Number(sliderVal) !== Number(coeValue)) {
          setSliderVal(Number(coeValue));
        }
      }
      // Only sync internal state when value prop changes
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [coeValue]);

    const onChange = useCallback(
      (val: string | number) => {
        try {
          validation.validateSync(val);
          setIsValid(true);
          setErrorMessage('');
          if (updateCOE) {
            updateCOE({
              [name]: Number(val),
            });
            saveCurrentOrbit();
          }
        } catch (error: any) {
          setIsValid(false);
          setErrorMessage(error.errors);
        }
      },
      [name, saveCurrentOrbit, updateCOE, validation],
    );

    const onSliderInputChange = (event: Event, newValue: number | number[]) => {
      onChange(newValue as number);
    };

    const onTextInputChange = (event: ChangeEvent<HTMLInputElement>) => {
      // as long as it's a number update state, prevents alpha chars
      const valueToTest = `0${event.target.value}`; // adding the leading 0 makes '0.' valid
      if (!isNaN(Number(valueToTest))) {
        setTextFieldValue(event.target.value); // show the raw value in the text field
        onChange(valueToTest);
      }
    };

    const onTextInputBlur = () => {
      if (Number(textFieldValue) < min) {
        onChange(min);
      } else if (Number(textFieldValue) > max) {
        onChange(max);
      }
    };

    if (isNaN(Number(coeValue))) {
      return null;
    }

    return (
      <Grid
        container
        direction="column"
        key={name}
        sx={{
          pt: 2,
          pl: 1,
          pr: 2, // prevents  slider from creating a horizontal scroll
        }}
      >
        <Grid item>
          <Box
            component="div"
            display="flex"
            alignItems="center"
          >
            <LabelWithTooltip
              label={
                <Box
                  component="div"
                  display="flex"
                >
                  <COEHeaderTypography variant="body2">{displayName}</COEHeaderTypography>
                  <COENotationTypography variant="body2">{notation}</COENotationTypography>
                </Box>
              }
              title={tooltipText}
            />
          </Box>
        </Grid>
        {type === COE_PROP_Type.SLIDER && (
          <Grid item>
            <TextField
              variant="standard"
              disabled={isPropagating}
              error={!isValid}
              helperText={capitalize(errorMessage)}
              value={textFieldValue}
              onChange={onTextInputChange}
              onBlur={onTextInputBlur}
              fullWidth
              inputProps={{
                'aria-label': `${name}`,
                'data-test': `coe-input-${name}`,
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment
                    disableTypography
                    position="end"
                  >
                    {unit}
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
        )}
        <Grid
          item
          sx={{
            pt: 0.5,
          }}
        >
          {type === COE_PROP_Type.SLIDER && (
            <Slider
              disabled={isPropagating}
              value={Number(sliderVal)}
              onChange={onSliderInputChange}
              step={step}
              track={false}
              min={min}
              max={max}
              size="small"
              tabIndex={-1}
              sx={{ p: 0 }}
            />
          )}
        </Grid>
      </Grid>
    );
  },
);
