import { ORBIT_TYPES } from 'src/constants';
import PropagatedCacheManager from 'src/models/PropagatedCacheManager';
import { ReferenceFrameType } from 'src/types';
import {
  createAddStateVectorsSelector,
  createOrbitActiveSvSelector,
  createOrbitalPlaneVectorSelector,
  createOrbitAOPSelector,
  createOrbitCOESelector,
  createOrbitDataStateSelector,
  createOrbitECCSelector,
  createOrbitINCSelector,
  createOrbitPathDataPointsSelector,
  createOrbitPathTimePointsSelector,
  createOrbitRAANSelector,
  createOrbitStateSelector,
  createOrbitTimeStateSelector,
  createOrbitVerticesStateSelector,
  createOrbitVertsStateSelector,
  selectOrbits,
} from './selectors';
import use3DOrbitStore from './store';

export function getOrbits() {
  return selectOrbits(use3DOrbitStore.getState());
}

export function createGetOrbitState(id: number) {
  const selector = createOrbitStateSelector(id);
  return function getOrbitCOE() {
    return selector(use3DOrbitStore.getState());
  };
}

export function createGetOrbitCOE(id: number) {
  const selector = createOrbitCOESelector(id);
  return function getOrbitCOE() {
    return selector(use3DOrbitStore.getState());
  };
}

export function createGetOrbitTimeState(id: number) {
  const selector = createOrbitTimeStateSelector(id);
  return function getOrbitOrbitTimeState() {
    return selector(use3DOrbitStore.getState());
  };
}

export function createGetOrbitData(id: number) {
  const selector = createOrbitDataStateSelector(id);
  return function getOrbitData() {
    return selector(use3DOrbitStore.getState());
  };
}

export function createGetAddStateVectors(id: number) {
  const selector = createAddStateVectorsSelector(id);
  return function getAddStateVectors() {
    return selector(use3DOrbitStore.getState());
  };
}

export function createGetOrbitPathDataPoints(id: number, referenceFrame: ReferenceFrameType) {
  const selector = createOrbitPathDataPointsSelector(id, referenceFrame);
  return function getOrbitPathDataPoints() {
    return selector(use3DOrbitStore.getState());
  };
}

export function createGetOrbitPathTimePoints(id: number) {
  const selector = createOrbitPathTimePointsSelector(id);
  return function getOrbitPathDataPoints() {
    return selector(use3DOrbitStore.getState());
  };
}

export function createSetOrbitEllapsedTimeMilliSec(id: number) {
  const { set } = use3DOrbitStore.getState();

  return function setOrbitEllapsedTimeMilliSec(ellapsedTimeMilliSec: number) {
    set((state) => {
      state.orbits[id].orbitTime.ellapsedTimeMilliSec = ellapsedTimeMilliSec;
    });
  };
}

export function createSetOrbitAnimDateTime(id: number) {
  const { set } = use3DOrbitStore.getState();

  return function setOrbitAnimDateTime(animDateTime: number) {
    set((state) => {
      state.orbits[id].orbitTime.animDateTime.setTime(animDateTime);
    });
  };
}

export function createSetOrbitTA(id: number) {
  const { set } = use3DOrbitStore.getState();

  return function setOrbitTAPosition(x: number, y: number, z: number) {
    set((state) => {
      state.orbits[id].orbitData.trueAnomalyPosition.set(x, y, z);
    });
  };
}

export function createSetOrbitTAAnimationPosition(id: number) {
  const { set } = use3DOrbitStore.getState();

  return function setOrbitTAPosition(x: number, y: number, z: number) {
    set((state) => {
      state.orbits[id].orbitData.trueAnomalyAnimationPosition.set(x, y, z);
    });
  };
}

export function createUpdateOrbitCOE(id: number) {
  const { set } = use3DOrbitStore.getState();

  return function setOrbitTAPosition(x: number, y: number, z: number) {
    set((state) => {
      state.orbits[id].orbitData.trueAnomalyAnimationPosition.set(x, y, z);
    });
  };
}

export function createUpdateOrbitGroupId(id: number) {
  const { set } = use3DOrbitStore.getState();

  return function setOrbitGroupId(groupId: number) {
    set((state) => {
      state.orbits[id].groupId = groupId;
    });
  };
}

export function createGetOrbitECC(id: number) {
  const selector = createOrbitECCSelector(id);
  return function getOrbitECC() {
    return selector(use3DOrbitStore.getState());
  };
}

export function createGetOrbitRAAN(id: number) {
  const selector = createOrbitRAANSelector(id);
  return function getOrbitRAAN() {
    return selector(use3DOrbitStore.getState());
  };
}

export function createGetOrbitAOP(id: number) {
  const selector = createOrbitAOPSelector(id);
  return function getOrbitAOP() {
    return selector(use3DOrbitStore.getState());
  };
}

export function createGetOrbitINC(id: number) {
  const selector = createOrbitINCSelector(id);
  return function getOrbitINC() {
    return selector(use3DOrbitStore.getState());
  };
}

export function createGetOrbitSMA(id: number) {
  const selector = createOrbitAOPSelector(id);
  return function getOrbitSMA() {
    return selector(use3DOrbitStore.getState());
  };
}

export function createGetOrbitVertices(id: number) {
  const selector = createOrbitVerticesStateSelector(id);
  return function getOrbitVertices() {
    return selector(use3DOrbitStore.getState());
  };
}

export function createGetOrbitVerts(id: number) {
  const selector = createOrbitVertsStateSelector(id);
  return function getOrbitVerts() {
    return selector(use3DOrbitStore.getState());
  };
}

export function createGetOrbitalPlaneVector(id: number) {
  const selector = createOrbitalPlaneVectorSelector(id);
  return function getOrbitOrbitalPlane() {
    return selector(use3DOrbitStore.getState());
  };
}

export function getMaxAvailableTime() {
  const currentOrbitsCache = PropagatedCacheManager.getOrbitsCache();
  const currentOrbits = PropagatedCacheManager.getPropagatingOrbits();
  const { orbits } = use3DOrbitStore.getState();

  const timePointsForOrbits = currentOrbits.map((orbit) => {
    if (orbits[orbit.id].orbitType === ORBIT_TYPES.EPHEMERIS) {
      if (
        currentOrbitsCache[orbit.id] &&
        currentOrbitsCache[orbit.id].length >= currentOrbitsCache[orbit.id].totalSteps
      ) {
        return [Infinity];
      }
    }
    return orbits[orbit.id]?.orbitPropagationPath.timePoints ?? [];
  });

  const lastTimePoints = timePointsForOrbits
    .map((timePoints) => timePoints[timePoints.length - 1] ?? 0)
    .filter((timePoint) => typeof timePoint === 'number');

  return lastTimePoints.length ? Math.min(...lastTimePoints) : 0;
}

export function createGetOrbitActiveSv(id: number) {
  const selector = createOrbitActiveSvSelector(id);
  return function getOrbitActiveSv() {
    return selector(use3DOrbitStore.getState());
  };
}
