import { IconButton, styled } from '@mui/material';

export const Wrapper = styled('div')`
  display: flex;
  align-items: center;
  gap: 16px;
`;

export const ControlsContainer = styled('div')`
  display: flex;
  align-items: center;
  gap: 4px;
  width: 215px;
`;

export const PlayContainer = styled('div')(
  ({ theme }) => `
    background: ${theme.palette.background.default};
    border-radius: 16px;
    display: flex;
    align-items: center;
    padding: 4px;
    gap: 4px;
  `,
);

interface ClearPropagationButtonProps {
  disabled?: boolean;
}
export const ClearPropagationButton = styled('button')<ClearPropagationButtonProps>(
  ({ theme, disabled }) => `
    background: ${theme.palette.primary.main};
    border-radius: 12px;
    color: #ffffff;
    text-transform: uppercase;
    padding: 5px 10px;
    font-weight: 600;
    font-size: 9px;
    line-height: 14px;
    border: none;
    cursor: pointer;
    ${
      disabled &&
      `
      background: ${theme.palette.grey['600']};
      color: ${theme.palette.grey['500']};
      cursor: initial;
    `
    }
  `,
);

export const ControlButton = styled(IconButton)`
  margin: 0;
  padding: 0;
  min-width: 0;
`;

export const Monospaced = styled('span')`
  font-feature-settings: 'tnum';
`;
