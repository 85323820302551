import { Grid } from '@mui/material';
import { ReactNode } from 'react';
import { ShowOnHover } from 'src/components/SpaceGlassList';
import theme from 'src/pages/App/Theme';

interface ObjectItemProps {
  name: string | ReactNode;
  itemOptions?: any[];
  autoHideItemOptions?: boolean;
}

export const ObjectItem = ({ autoHideItemOptions, name, itemOptions }: ObjectItemProps) => {
  return (
    <ShowOnHover>
      <Grid
        display="grid"
        gridAutoFlow="column"
        justifyContent="space-between"
        alignItems="center"
        padding={theme.spacing(1)}
        paddingLeft={0}
      >
        <Grid
          display="grid"
          gridAutoFlow="column"
          alignItems="center"
        >
          {name}
        </Grid>

        <div className={autoHideItemOptions ? 'showOnHover' : ''}>
          <Grid
            display="grid"
            gridAutoFlow="column"
            alignItems="center"
            gap={1.5}
          >
            {itemOptions}
          </Grid>
        </div>
      </Grid>
    </ShowOnHover>
  );
};
