import { ToggleSettingItem, ToggleSettings } from './ToggleSettings';

/**
 * Example:
 * {
 *   name: 'Feature name',
 *   settingName: SETTINGS_NAMES.FEATURE_VARIABLE_NAME_HERE,
 * },
 */
const featureToggles: Array<ToggleSettingItem> = [];

export const ToggleFeatures = () => {
  return (
    <ToggleSettings
      title="Features"
      settings={featureToggles}
    />
  );
};
