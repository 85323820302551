import type {} from '@mui/lab/themeAugmentation';
import { common, grey } from '@mui/material/colors';
import { alpha, createTheme } from '@mui/material/styles';
import type {} from '@mui/x-data-grid/themeAugmentation';

const BUTTON_CREATE_WIDTH = 26;
declare module '@mui/material/Button' {
  interface ButtonPropsVariantOverrides {
    rounded: true;
  }
}

export const COLOR_BORDER = alpha('#fff', 0.12);

const theme = createTheme({
  palette: {
    mode: 'dark',
    primary: {
      main: '#29B0F7',
      contrastText: '#fff',
    },
    secondary: {
      main: '#FF9800',
      contrastText: '#fff',
    },
    background: {
      default: '#031B28',
      paper: '#00111A',
    },
  },
  typography: {
    fontFamily: ['Montserrat', 'Helvetica', 'Arial', 'sans-serif'].join(','),
    h1: {
      fontSize: 72,
      fontWeight: 400,
      lineHeight: '76px',
    },
    h2: {
      fontSize: 45,
      fontWeight: 400,
      lineHeight: '50px',
    },
    h3: {
      fontSize: 36,
      fontWeight: 400,
      lineHeight: '36px',
    },
    h4: {
      fontSize: 25.5,
      fontWeight: 700,
      lineHeight: '26px',
    },
    h5: {
      fontSize: 18,
      fontWeight: 500,
      lineHeight: '24px',
    },
    h6: {
      fontSize: 15,
      fontWeight: 400,
      lineHeight: '20px',
    },
    body1: {
      fontSize: 12,
      fontWeight: 400,
      lineHeight: '16px',
    },
    body2: {
      fontSize: 10.5,
      fontWeight: 400,
      lineHeight: '18px',
    },
    subtitle1: {
      fontSize: 12,
      fontWeight: 700,
      lineHeight: '20px',
    },
    subtitle2: {
      fontSize: 10.5,
      fontWeight: 500,
      lineHeight: '20px',
    },
    caption: {
      fontSize: 9,
      fontWeight: 600,
      lineHeight: '14px',
    },
    overline: {
      fontSize: 9,
      fontWeight: 400,
      lineHeight: '12px',
      letterSpacing: '1px',
      textTransform: 'uppercase',
    },
  },
  components: {
    MuiTabs: {
      styleOverrides: {
        root: {
          minHeight: 'auto',
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: ({ theme }) => ({
          textTransform: 'none',
          padding: theme.spacing(1.5),
          minHeight: 'auto',
          fontSize: 14,
          fontVariationSettings: "'wght' 600",
        }),
      },
    },
    MuiSnackbarContent: {
      styleOverrides: {
        root: ({ theme }) => ({
          color: theme.palette.text.primary,
          fontSize: 12,
        }),
      },
    },
    MuiButton: {
      variants: [
        {
          props: { variant: 'rounded' },
          style: ({ theme }) => {
            return {
              color: 'black',
              borderRadius: BUTTON_CREATE_WIDTH / 2,
              minHeight: BUTTON_CREATE_WIDTH,
              minWidth: BUTTON_CREATE_WIDTH,
              padding: 0,
            };
          },
        },
        {
          props: { variant: 'text' },
          style: {
            background: 'none',
            boxShadow: 'none',
          },
        },
      ],
      styleOverrides: {
        root: ({ ownerState, theme }) => {
          let mainColorHex = '#000';
          if (ownerState.color && ownerState.color !== 'inherit') {
            mainColorHex = theme.palette[ownerState.color].main;
          }

          let alphaNormal = 0.3;
          let alphaHover = 0.8;

          if (ownerState.variant === 'rounded') {
            alphaNormal = 1;
            alphaHover = 1;
          }

          return {
            fontVariationSettings: "'wght' 500",
            color: theme.palette.text.primary,
            backgroundColor: alpha(mainColorHex, alphaNormal),
            boxShadow: `0px 1px 20px ${alpha(
              mainColorHex,
              0.7,
            )}, inset 0px 0px 5px ${mainColorHex}`,
            borderRadius: 4,

            '&:hover': {
              backgroundColor: alpha(mainColorHex, alphaHover),
              boxShadow: `0px 1px 20px ${alpha(
                mainColorHex,
                0.7,
              )}, inset 0px 0px 5px ${mainColorHex}`,
            },
            '&:disabled': {
              opacity: 0.4,
              color: 'black',
            },
          };
        },
      },
    },
    MuiDataGrid: {
      styleOverrides: {
        root: ({ theme }) => {
          return {
            backgroundColor: theme.palette.background.paper,
            border: 'none',
            borderRadius: 4,
            fontSize: 12,
          };
        },
        iconSeparator: {
          display: 'none',
        },
        columnHeaders: {
          borderColor: COLOR_BORDER,
        },
        columnHeader: {
          '&:focus': {
            outlineOffset: '-2px',
          },
        },
        cell: {
          borderColor: COLOR_BORDER,
        },
        footerContainer: {
          borderColor: COLOR_BORDER,
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          fontVariationSettings: "'wght' 500",
          'input[type=number]::-webkit-inner-spin-button': {
            WebkitAppearance: 'none',
            margin: 0,
          },
          'input[type=number]::-webkit-outer-spin-button': {
            WebkitAppearance: 'none',
            margin: 0,
          },
          'input[type=number]': {
            MozAppearance: 'textfield',
          },
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          backdropFilter: 'blur(7.5px)',
        },
      },
    },
    MuiListItem: {
      styleOverrides: {
        root: {
          '&.Mui-selected, &:hover': {
            backgroundColor: grey[700],
          },
          '&.Mui-selected:hover': {
            backgroundColor: grey[600],
          },
        },
      },
    },
    MuiFilledInput: {
      styleOverrides: {
        root: {
          borderColor: 'white',
        },
      },
    },
    MuiAccordion: {
      defaultProps: {
        disableGutters: true,
      },
      styleOverrides: {
        root: {
          background: 'transparent',
          boxShadow: 'none',
          backdropFilter: 'none',
        },
      },
    },
    MuiAccordionSummary: {
      styleOverrides: {
        root: {
          flexDirection: 'row-reverse',
          padding: 0,
          '&$expanded': {
            padding: 0,
            margin: 0,
          },
          fontSize: 12,
          lineHeight: '20px',
          minHeight: 'auto',
        },
      },
    },
    MuiAccordionDetails: {
      styleOverrides: {
        root: {
          padding: 0,
        },
      },
    },
    MuiInput: {
      styleOverrides: {
        input: {
          '&:disabled': {
            pointerEvents: 'none', // Firefox hides pointer events on disabled inputs
          },
        },
      },
    },
    MuiAutocomplete: {
      styleOverrides: {
        noOptions: {
          color: common.white,
        },
      },
    },
  },
});

// TODO: Replace with general use of dark theme for better contrast.
export const highContrast = {
  palette: {
    text: {
      secondary: '#b3b3b3',
    },
  },
};

export default theme;
