import { Vector3 } from 'three';
import { create } from 'zustand';
import { immer } from 'zustand/middleware/immer';

export const RIC_ORBIT_DATA_STATUS = {
  LOADED: 'loaded',
};
export type RicOrbitDataStatus = (typeof RIC_ORBIT_DATA_STATUS)[keyof typeof RIC_ORBIT_DATA_STATUS];

export interface RicStateVector {
  time: number;
  sv: Vector3;
}

interface RicData {
  [key: number]: {
    status: RicOrbitDataStatus;
    stateVectors: Array<RicStateVector>;
  };
}

interface OrbitRicStoreState {
  originOrbitId: Record<number, number>;
  setOriginOrbitId: (viewportId: number, orbitId: number) => void;

  orbitData: Record<number, RicData>;
  setOrbitData: (
    orbitIdOrigin: number,
    orbitIdTarget: number,
    stateVectors: Array<RicStateVector>,
  ) => void;

  clearStore: () => void;
}

export const useOrbitRicStore = create<OrbitRicStoreState>()(
  immer((set, get) => ({
    originOrbitId: {},
    setOriginOrbitId: (viewportId, orbitId) => {
      set((state) => {
        state.originOrbitId[viewportId] = orbitId;
      });
    },

    orbitData: {},
    setOrbitData: (orbitIdOrigin, orbitIdTarget, stateVectors) => {
      set((state) => {
        if (!state.orbitData[orbitIdOrigin]) {
          state.orbitData[orbitIdOrigin] = {};
        }

        if (!state.orbitData[orbitIdOrigin][orbitIdTarget]) {
          state.orbitData[orbitIdOrigin][orbitIdTarget] = {
            status: RIC_ORBIT_DATA_STATUS.LOADED,
            stateVectors: [],
          };
        }

        state.orbitData[orbitIdOrigin][orbitIdTarget].stateVectors = stateVectors;
      });
    },

    clearStore: () => {
      set((state) => {
        state.orbitData = {};
      });
    },
  })),
);
