import { MANEUVER_FLYOUT_STATUSES } from 'src/constants';
import { useIsPropagating } from 'src/hooks/OrbitHooks';
import { getActiveObjectId, useRouteStore } from 'src/pages/App/routes/store';
import { SETTINGS_NAMES, useSettingsStore } from 'src/pages/Settings/store';
import use3DOrbitStore from 'src/threejs/components/OrbitManager/store/store';
import { ManeuverPreviewHelpers } from './ManeuverPreviewHelpers';
import { ManeuverPreviewOrbit } from './ManeuverPreviewOrbit';
import { useManeuverStore } from './store';

export const ManeuverPreview = () => {
  const orbitId = useRouteStore(getActiveObjectId);

  const isPropagating = useIsPropagating();

  const maneuverFlyoutStatus = useSettingsStore(
    (state) => state.custom[SETTINGS_NAMES.MANEUVERS_FLYOUT_STATUS],
  );

  const maneuverEditRIC = useManeuverStore((state) => state.maneuverEditRIC);

  const hasErrorPropagationData = useManeuverStore((state) => state.hasErrorPropagationData);

  const activeSV = use3DOrbitStore((state) => state.orbits[orbitId]?.activeStateVector || null);

  if (maneuverFlyoutStatus === MANEUVER_FLYOUT_STATUSES.CLOSED) {
    return null;
  }

  if (!activeSV || !isPropagating || hasErrorPropagationData) return null;

  return (
    <>
      {maneuverEditRIC && (
        <ManeuverPreviewHelpers
          stateVector={activeSV}
          maneuverVelocities={maneuverEditRIC}
        />
      )}

      {maneuverEditRIC && (
        <ManeuverPreviewOrbit
          stateVector={activeSV}
          maneuverVelocities={maneuverEditRIC}
        />
      )}
    </>
  );
};
