import { useCurrentGroundObjects } from 'src/hooks/GroundObjectHooks';
import { GroundObjectItem } from './GroundObjectItem';

export const GroundObjects = () => {
  const currentGroundObjects = useCurrentGroundObjects();

  return (
    <group>
      {currentGroundObjects?.map((groundObject) => {
        return (
          <GroundObjectItem
            key={groundObject.id}
            groundObject={groundObject}
          />
        );
      })}
    </group>
  );
};
