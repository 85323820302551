import { format } from 'date-fns';
import addDays from 'date-fns/addDays';
import addMinutes from 'date-fns/addMinutes';
import subDays from 'date-fns/subDays';
import subMinutes from 'date-fns/subMinutes';
import { DateTime } from 'luxon';
import { DATE_TIME_DISPLAY_FORMAT } from 'src/constants';

/**
 * Returns a number with the ordinal attached (1st, 2nd, 3rd, 4th, ...)
 */
export function getNumberWithOrdinal(n: number) {
  const s = ['th', 'st', 'nd', 'rd'],
    v = n % 100;
  return n + (s[(v - 20) % 10] || s[v] || s[0]);
}

/**
 * Converts a numeral month to the three letter month abbreviation
 */
export function getAbbreviatedMonth(n: number) {
  const shortMonths = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ];
  return shortMonths[n];
}

export const getTimeAsUTC = (date: string) => {
  const dt = DateTime.fromFormat(date, 'yyyy-MM-dd HH:mm:ss', {
    zone: 'utc',
  });

  return dt;
};

const formatFactory = (format: string) => (lxDate: DateTime, zone: string) => {
  return lxDate.setZone(zone).toFormat(format);
};

const toStandardDisplay = formatFactory('MM-dd-yyyy HH:mm');
const toStandardDisplayWithSeconds = formatFactory('MM-dd-yyyy HH:mm:ss');
const toStandardFilenameWithSeconds = formatFactory('yyyy-MM-dd_HH:mm:ss');

export const getDateTimeFormat = (date: string, zone?: string) => {
  const timeZone = zone ?? 'local';
  return toStandardDisplay(getTimeAsUTC(date), timeZone);
};

export const millisToLocale = (value: number, zone?: string) => {
  const timeZone = zone ?? 'local';
  return toStandardDisplayWithSeconds(DateTime.fromMillis(value), timeZone);
};

export const millisToLocaleFilename = (value: number, zone?: string) => {
  const timeZone = zone ?? 'local';
  return toStandardFilenameWithSeconds(DateTime.fromMillis(value), timeZone);
};

const DAY = 86400000; // number of milliseconds in 24 hours
const HALF_DAY = DAY / 2;
const UNIX_EPOCH_JULIAN_DATE = 2440587.5;
const UNIX_EPOCH_JULIAN_DAY = 2440587;

/**
 * Convert a Date object to Julian date in string format (Julian date is the number of days and fractional days)
 */
export function dateToJulianString(date: any) {
  return dateToJulian(date).toFixed(6);
}

/**
 * Convert a Date object to Julian date (Julian date is the number of days and fractional days)
 */
export function dateToJulian(date: any) {
  return dateToJulianDay(date) + dateToMillisecondsInJulianDay(date) / DAY;
}

/**
 * Convert Julian date as a string to JS Date object
 */
export function julianConvertToDate(julian: any) {
  return new Date((Number(julian) - UNIX_EPOCH_JULIAN_DATE) * DAY);
}

/**
 * Convert date object to integer Julian day
 */
export function dateToJulianDay(date: any) {
  return ~~((+date + HALF_DAY) / DAY) + UNIX_EPOCH_JULIAN_DAY;
}

/**
 * Convert date object to Julian days in milliseconds - number of milliseconds since the start of the Julian day
 * where a Julian day starts at noon
 */
export function dateToMillisecondsInJulianDay(date: any) {
  return (+date + HALF_DAY) % DAY;
}

/**
 * Converts a julian day and milliseconds back to a JS timestamp
 */
export function fromJulianDayAndMilliseconds(day: any, ms: any) {
  return (day - UNIX_EPOCH_JULIAN_DATE) * DAY + ms;
}

/** Converts local time in milliseconds to milliseconds in utc time */
export function millisInUTC(timeInMs: number): number {
  return DateTime.fromMillis(timeInMs, { zone: 'utc' }).toMillis();
}

export function shiftDateToUTC(date: Date) {
  return addMinutes(date, date.getTimezoneOffset());
}

export function unshiftDateFromUTC(date: Date) {
  return subMinutes(date, date.getTimezoneOffset());
}

export function validDate(date: Date) {
  return date && !isNaN(date.getTime());
}

export const millisToUTC = (value: number | typeof NaN) => {
  if (!validDate(new Date(value))) {
    return 'Invalid Date';
  }

  return format(shiftDateToUTC(new Date(value)), 'MM-dd-yyyy HH:mm:ss');
};

export function ensureValidRange(start: Date, end: Date) {
  return (target: 'start' | 'end') => {
    const isStart = target === 'start';
    const timeTarget = isStart ? start : end;
    const rangeValid = start.getTime() < end.getTime();

    if (rangeValid) {
      return timeTarget;
    } else {
      return isStart ? subDays(end, 1) : addDays(start, 1);
    }
  };
}

export function formatDateWithMs(date: Date): string {
  const newDate = DateTime.fromJSDate(date).toFormat(DATE_TIME_DISPLAY_FORMAT);
  return newDate;
}

export function getDateFromDateStrMs(dateStr: string): Date {
  const newDateStr = padMilliseconds(dateStr);
  const dateTime = DateTime.fromFormat(newDateStr, DATE_TIME_DISPLAY_FORMAT).toJSDate();

  return dateTime;
}

export function padMilliseconds(dateStr: string): string {
  const parts = dateStr.split('.');
  if (parts.length < 2) {
    return parts[0] + '.000';
  }
  return parts[0] + '.' + parts[1].padEnd(3, '0');
}

// Creates a date from the sum a year and number of milliseconds into a year
function dateFromYearAndMS(year: number, millis: number) {
  // initializing with zero sets the base time and date off of current locale
  const date = unshiftDateFromUTC(new Date(year, 0)); // initialize a date in `year-01-01`
  date.setMilliseconds(millis);
  return date;
}

export function getTleEpochDate(tleLine1: string): Date {
  const epochSubstrYear = tleLine1.substring(18, 20);

  const epochSubstrFractionalDay = tleLine1.substring(20, 32);

  if (epochSubstrYear.length < 1 || epochSubstrFractionalDay.length < 1)
    return new Date('Invalid Date');

  const parsedEpochFractionalDay = parseFloat(epochSubstrFractionalDay);

  // subtract one to account for first day of year not being zero based
  const epochFractionalYearMS = (parsedEpochFractionalDay - 1) * 1000 * 60 * 60 * 24;

  let epochYear = parseInt(epochSubstrYear, 10);

  // this will break in the year 2057
  if (epochYear > 57) {
    epochYear += 1900;
  } else {
    epochYear += 2000;
  }

  return dateFromYearAndMS(epochYear, epochFractionalYearMS);
}
