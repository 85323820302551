import { useTexture } from '@react-three/drei';
import { useFrame } from '@react-three/fiber';
import { mapValues } from 'lodash';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { DEFAULT_ADDITIONAL_PROPERTIES_PAGE, earthradius, VISUAL_AID_HEX } from 'src/constants';
import { FONTS } from 'src/constants-fonts';
import { getCurrentTime, getPlayState } from 'src/core/getters';
import { useIsPropagating } from 'src/hooks/OrbitHooks';
import { useCurrentPage } from 'src/hooks/PageHooks';
import { useSunPosition } from 'src/hooks/SunPositionHooks';
import PropagatedCacheManager from 'src/models/PropagatedCacheManager';
import { EARTH_LIGHTING } from 'src/pages/Notebook/components/EarthLightingOptions';
import { getNextFrameTime } from 'src/threejs/utils/selectors';
import { Position } from 'src/types';
import { Clock, Color, DirectionalLight, Group, Vector3 } from 'three';
import { Lensflare, LensflareElement } from 'three/examples/jsm/objects/Lensflare.js';
import { ArrowVector } from '../ArrowVector';

const isValidSunPosition = (sunPosition?: Position) => {
  if (!sunPosition) {
    return false;
  }

  const allAxisAreZero = Object.values(sunPosition).every((axis) => axis === 0);

  if (allAxisAreZero) {
    const error = new Error('sunPosition endpoint tried to set sun to 0,0,0');
    console.error(error);

    return false;
  }

  return true;
};

const Sun = () => {
  const [sunGroupRef, setSunGroupRef] = useState<Group | null>(null);
  const [sunLightRef, setSunLightRef] = useState<DirectionalLight | null>(null);

  const [textureFlareMain, textureFlareSecondary, textureFlareSA] = useTexture([
    '/textures/lensflare/lensflare_main.png',
    '/textures/lensflare/lensflare_secondary.png',
    '/textures/lensflare/lensflare_sa.png',
  ]);

  useEffect(() => {
    if (sunLightRef) {
      const lensflare = new Lensflare();
      lensflare.addElement(new LensflareElement(textureFlareMain, 250, 0, sunLightRef.color));
      lensflare.addElement(
        new LensflareElement(textureFlareSecondary, 100, 0.3, new Color(1, 0, 0)),
      );
      lensflare.addElement(new LensflareElement(textureFlareSA, 50, 0.6));
      lensflare.addElement(
        new LensflareElement(textureFlareSecondary, 30, 0.7, new Color(0, 1, 1)),
      );
      lensflare.addElement(new LensflareElement(textureFlareSecondary, 50, 0.9));
      lensflare.addElement(new LensflareElement(textureFlareSecondary, 30, 1));
      sunLightRef.clear();
      sunLightRef.add(lensflare);
    }
  }, [sunLightRef, textureFlareMain, textureFlareSecondary, textureFlareSA]);

  const currentPage = useCurrentPage();

  const additionalProperties =
    currentPage?.additionalProperties || DEFAULT_ADDITIONAL_PROPERTIES_PAGE;

  const pageSunVectorVisibility = additionalProperties.visSunVector;

  const lightingEnabled = additionalProperties.lightingEnabled;
  const lightingSun = additionalProperties.lightingSun;

  const direction = useMemo(() => new Vector3(), []);
  const origin = useMemo(() => new Vector3(0, 0, 0), []);
  const clock = useMemo(() => new Clock(), []);

  const [visible, setVisible] = useState(false);

  const currentTime = getCurrentTime();
  const startTimeSunPos = useSunPosition(
    new Date(currentTime),
    {
      enabled: getPlayState() === 'stopped',
    },
    currentTime === 0,
  );

  const getScaledSunPosition = useCallback((sunPosition: Position) => {
    const scaleFactor = 1 / earthradius; // 1e-4;
    // the actual position of the sun doesn't really matter, what is important
    // is the relative direction because we are using a directional light.
    // 0.000001 is combination of meter conversion and extra downscaling to
    // bring the sun geo close to earth
    const { x_position, y_position, z_position } = mapValues(
      sunPosition,
      (ordinate) => ordinate * scaleFactor * 0.000004,
    );

    return new Vector3(x_position, y_position, z_position);
  }, []);

  const initialSunPos = useMemo(
    () => (startTimeSunPos ? getScaledSunPosition(startTimeSunPos) : origin),
    [getScaledSunPosition, startTimeSunPos, origin],
  );

  const sunArrowDirectionRef = useRef<Vector3>(initialSunPos);

  const setCurrentPosition = useCallback(
    (sunPosition: Position) => {
      const sunPos = getScaledSunPosition(sunPosition);

      if (sunGroupRef && sunLightRef) {
        sunLightRef.castShadow = true;
        sunLightRef.intensity = EARTH_LIGHTING.SUN_INTENSITY.DEFAULT;
        if (lightingEnabled) {
          const mSun =
            (EARTH_LIGHTING.SUN_INTENSITY.MAXIMUM - EARTH_LIGHTING.SUN_INTENSITY.MINIMUM) / 100; // slider delta is 100
          sunLightRef.intensity = mSun * lightingSun + EARTH_LIGHTING.SUN_INTENSITY.MINIMUM;
        }

        // [SWIZZLE] we need to perform swizzling of x,y,z because backend
        // uses Z-up coordinate system while three.js uses Y-up. XYZ->YZX
        sunGroupRef.position.set(sunPos.y, sunPos.z, sunPos.x);
        sunArrowDirectionRef.current = new Vector3(sunPos.x, sunPos.y, sunPos.z);

        direction.copy(origin).sub(sunGroupRef.position).normalize();
      }
    },
    [
      origin,
      sunGroupRef,
      direction,
      sunLightRef,
      lightingSun,
      lightingEnabled,
      getScaledSunPosition,
    ],
  );

  const sunAnimation = () => {
    const playState = getPlayState();
    const delta = clock.getDelta();

    if (playState === 'playing') {
      const targetTime = getNextFrameTime(delta);

      const stateVector = PropagatedCacheManager.findClosestStateVectorForTime(targetTime);

      if (stateVector) {
        setCurrentPosition(stateVector.stateVectors[0].sunPosition);
      }
    } else if (startTimeSunPos && isValidSunPosition(startTimeSunPos)) {
      sunGroupRef?.position.set(initialSunPos.y, initialSunPos.z, initialSunPos.x);
    }
  };

  useEffect(() => {
    const playState = getPlayState();
    if (startTimeSunPos && isValidSunPosition(startTimeSunPos) && playState !== 'playing') {
      setCurrentPosition(startTimeSunPos);
    }
    if (!origin.equals(sunGroupRef?.position ?? origin)) setVisible(true);
  }, [startTimeSunPos, setCurrentPosition, origin, sunGroupRef?.position]);

  useFrame(sunAnimation);

  const isPropagating = useIsPropagating();
  const getSunDirection = () => {
    if (isPropagating && sunArrowDirectionRef.current) {
      return sunArrowDirectionRef.current.clone().normalize();
    }
    return new Vector3(initialSunPos.x, initialSunPos.y, initialSunPos.z).normalize();
  };

  return (
    <group
      name="Sun"
      visible={visible}
    >
      <group ref={setSunGroupRef}>
        <directionalLight
          color="white"
          name="SunLight"
          ref={setSunLightRef}
        />
      </group>

      <group visible={pageSunVectorVisibility}>
        {pageSunVectorVisibility && (
          <ArrowVector
            staticLength={2}
            labelShort="☀"
            label="Sun Vector"
            color={VISUAL_AID_HEX}
            getDestination={getSunDirection}
            labelTextProps={{
              font: FONTS.StaticInterBoldItalic,
            }}
          />
        )}
      </group>
    </group>
  );
};

export default Sun;
