/**
 * Returns a random number within a range
 * @param {number} from
 * @param {number} to
 */
export function randomInRange(from: number, to: number) {
  const x = Math.random() * (to - from);
  return x + from;
}

/**
 * Generates linear range and returns an array (Matlab analogous except default n = 2 while in matlab = 100)
 * @param {number} x1
 * @param {number} x2
 * @param {number} n
 */
export function linspace(x1: number, x2: number, n = 2): number[] {
  const arr = [];

  if (n < 2) throw new Error('Invalid parameter n');

  const interval = (x2 - x1) / (n - 1);
  const sum = x1;
  for (let i = 0; i < n; i++) {
    arr[i] = sum + i * interval;
  }
  return arr;
}

/**
 * Converts a number from radians to degrees
 * @param {number} number
 */
export function radToDeg(number: number): number {
  return number * (180 / Math.PI);
}

/**
 * lerp between x and y with input a
 */
export const lerp = (x: number, y: number, a: number): number => x * (1 - a) + y * a;

/**
 * clamp a to min and max
 */
export const clamp = (a: number, min = 0, max = 1): number => Math.min(max, Math.max(min, a));

/**
 * return ratio between x and y with value a.  *** return 0 if x and y are the same value
 */
export const invlerp = (x: number, y: number, a: number): number =>
  x !== y ? clamp((a - x) / (y - x)) : 0;

/**
 * remap input a between input x1(min), y1(max) to output x2(min), y2(max)
 */
export const range = (x1: number, y1: number, x2: number, y2: number, a: number): number =>
  lerp(x2, y2, invlerp(x1, y1, a));

/**
 * convert input angle in degrees to range to positive [0, 360]
 */
export const positiveDegAngle = (angle: number): number => {
  const t = angle % 360;
  return Math.sign(angle) === -1 ? (360 + t) % 360 : t;
};

/**
 * left bitwise shift for performant conversion of floats to int
 */
export const float2int = (value: number) => {
  return ~~(0.5 + value);
};

/**
 * return value normalized from 0 to max
 */
export const positiveNumInRange = (value: number, max: number) => {
  return Math.sign(value) === -1
    ? (value % max) + max // add one more after to make sure always +
    : value % max;
};
