import PubSub from './PubSub';

type TimelineEventManagerEvents = 'scrub' | 'jumpToStart' | 'jumpToEnd';

class TimelineEventManagerModel {
  private pubsub: PubSub;

  constructor() {
    this.pubsub = new PubSub();
  }

  public addEventListener = (
    event: TimelineEventManagerEvents,
    listener: (...args: any[]) => void,
  ) => {
    this.pubsub.subscribe(event, listener);
  };

  removeEventListener = (event: TimelineEventManagerEvents, listener: (...args: any[]) => void) => {
    this.pubsub.unsubscribe(event, listener);
  };

  publish = (event: TimelineEventManagerEvents, data: any) => {
    this.pubsub.publish(event, data);
  };
}

const TimelineEventManager = new TimelineEventManagerModel();

export default TimelineEventManager;
