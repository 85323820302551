import { DeleteRounded, PeopleAltRounded } from '@mui/icons-material';
import {
  Avatar,
  Button,
  Dialog,
  DialogContent,
  Grid,
  IconButton,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import { enqueueSnackbar } from 'notistack';
import { useState } from 'react';
import { SpaceGlassContainerBase } from 'src/components/SpaceGlassControl';
import { ModalHeader } from 'src/pages/Shared/ModalSpaceGlass';
import { Capability, Folder, Notebook } from 'src/types';
import { EMAIL_REGEX } from '../../../constants';
import {
  useCheckNotebookCapabilities,
  useGrantCopyCapabilityMutation,
  useObjectHierarchy,
} from '../../../hooks/ObjectHooks';
import { ModalRemoveUserFromShare } from './ModalRemoveUserFromShare';
import { ModalRemoveAllUsersFromShare } from './ModalRemoveAllUsersFromShare';

type ShareObjectModalProps = {
  onClose: () => void;
  selected: Folder | Notebook | null;
};

export function ShareObjectModal({ onClose, selected }: ShareObjectModalProps) {
  const [shareEmail, setShareEmail] = useState<string>('');
  const [activeModelItem, setActiveModalItem] = useState<Capability | null>();
  const [deleteAllModalOpen, setDeleteAllModelOpen] = useState(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const grantCopyMutation = useGrantCopyCapabilityMutation();
  const capabilitiesCheck = useCheckNotebookCapabilities(selected!.id, true);
  const currentFolder = useObjectHierarchy(selected!.id) as Folder;

  let disable = false;
  let title = '';
  if (selected) {
    if (
      currentFolder?.children &&
      currentFolder.children.filter((child) => child.type === 'FOLDER').length > 0
    ) {
      disable = true;
    }
    title = selected.name;
  }
  let capabilities: Capability[] = [];
  if (capabilitiesCheck?.data) {
    capabilities = capabilitiesCheck.data;
  }
  title += ` (${capabilities.length})`;

  const onSubmit = () => {
    if (selected) {
      const emails = shareEmail.split(/[\s,]+/).filter((token) => {
        return EMAIL_REGEX.test(token);
      });
      if (emails.length === 0) {
        enqueueSnackbar('Invalid email address', {
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'center',
          },
          variant: 'error',
        });
      } else {
        grantCopyMutation
          .mutateAsync({
            id: selected.id,
            emails: emails,
          })
          .catch((reason) => {
            console.error(reason);
            enqueueSnackbar('Unable to share ' + selected.name + ' with ' + shareEmail, {
              anchorOrigin: {
                vertical: 'top',
                horizontal: 'center',
              },
              variant: 'info',
            });
          })
          .then((value) => {
            if (value) {
              // email was added
              setShareEmail('');
            }
          });
      }
    }
  };

  return (
    <>
      <Dialog
        open={true}
        onClose={onClose}
        fullWidth={true}
        aria-labelledby="max-width-dialog-title"
        data-test="folder-modal"
        PaperComponent={SpaceGlassContainerBase}
        onKeyPress={(event) => {
          if (event.key === 'Enter') {
            onSubmit();
          }
        }}
      >
        <ModalHeader
          title={title}
          onClose={onClose}
          icon={<PeopleAltRounded />}
        />

        <DialogContent>
          <Grid container>
            <Grid
              item
              xs={12}
            >
              <Typography variant="h5">Invite</Typography>
            </Grid>
            <Grid
              item
              xs={10}
              style={{ paddingRight: 20, paddingBottom: 10 }}
            >
              <TextField
                id="outlined-share-emails"
                onChange={(evt) => setShareEmail(evt.target.value)}
                value={shareEmail}
                fullWidth
                margin="dense"
                size="small"
                disabled={disable}
              />
            </Grid>
            <Grid
              item
              xs={2}
              style={{ marginTop: 5, paddingBottom: 10, alignItems: 'center' }}
            >
              <Button
                onClick={onSubmit}
                variant="contained"
                color="primary"
                data-test="folder-modal-confirm-button"
                disabled={disable}
              >
                INVITE
              </Button>
            </Grid>
          </Grid>

          <Grid container>
            <Grid
              display="grid"
              gridAutoFlow="row"
              gap={2}
              sx={{
                flexGrow: 1,
                overflow: 'auto',
                maxHeight: '50vh',
              }}
            >
              {capabilities.map((capability) => (
                <Grid
                  key={capability.userId}
                  gap={1}
                  display="flex"
                  flexDirection="column"
                >
                  <Grid
                    display="flex"
                    flexDirection="row"
                    gap={2}
                  >
                    <Avatar sx={{ width: 24, height: 24, flexGrow: 0 }} />
                    <Typography
                      alignContent="left"
                      variant="h6"
                      sx={{ flexGrow: 1 }}
                    >
                      {capability.userEmail}
                    </Typography>
                    <Tooltip title="Remove user">
                      <IconButton
                        sx={{ width: 24, height: 24, flexGrow: 0 }}
                        onClick={() => {
                          setDeleteModalOpen(true);
                          setActiveModalItem(capability);
                        }}
                      >
                        <DeleteRounded fontSize="small" />
                      </IconButton>
                    </Tooltip>
                  </Grid>
                </Grid>
              ))}
            </Grid>
          </Grid>

          {capabilities.length > 0 && (
            <Grid
              display="flex"
              flexDirection="row"
              justifyContent="flex-end"
              pt={2}
            >
              <Button
                color="error"
                variant="contained"
                onClick={() => setDeleteAllModelOpen(true)}
                startIcon={<DeleteRounded fontSize="small" />}
              >
                Remove All
              </Button>
            </Grid>
          )}
        </DialogContent>
      </Dialog>
      {deleteModalOpen && (
        <ModalRemoveUserFromShare
          capability={activeModelItem}
          open={deleteModalOpen}
          show={setDeleteModalOpen}
        />
      )}
      {deleteAllModalOpen && (
        <ModalRemoveAllUsersFromShare
          capabilities={capabilities}
          open={deleteAllModalOpen}
          show={setDeleteAllModelOpen}
        />
      )}
    </>
  );
}
