import { Route, Routes } from 'react-router-dom';
import FolderView from 'src/pages/Folders/FolderView';
import NotebookView from 'src/pages/Notebook/NotebookView';
import { VideosAstrodynamics } from 'src/pages/Shared/VideosAstrodynamics';
import { VideosUI } from 'src/pages/Shared/VideosUI';
import { PDFViewer } from 'src/pages/Shared/common/PDFViewer';
import { NotFound, ServerDown } from 'src/pages/Statuses';
import { Organizations } from '../Settings/Organizations';

/**
 * Defines the private pages to load
 */
const createRoutes = () => (
  <Routes>
    <Route
      path="/shared/folders/:folderId"
      element={<FolderView />}
    />
    <Route
      path="/shared/:capabilityId/notebook/:id/:pageid/special/:specialId"
      element={<NotebookView />}
    />
    <Route
      path="/shared/:capabilityId/notebook/:id/:pageid/group/:groupId"
      element={<NotebookView />}
    />
    <Route
      path="/shared/:capabilityId/notebook/:id/:pageid/ground-object/:groundObjectId"
      element={<NotebookView />}
    />
    <Route
      path="/shared/:capabilityId/notebook/:id/:pageid/:objid"
      element={<NotebookView />}
    />
    <Route
      path="/shared/:capabilityId/notebook/:id/:pageid"
      element={<NotebookView />}
    />
    <Route
      path="/shared/:capabilityId/notebook/:id"
      element={<NotebookView />}
    />
    <Route
      path="/shared/:capabilityId/notebook/:id/:pageid/:objid"
      element={<NotebookView />}
    />
    <Route
      path="/shared/:capabilityId/notebook"
      element={<NotebookView />}
    />

    <Route
      path="/notebook/:id/:pageid/special/:specialId"
      element={<NotebookView />}
    />
    <Route
      path="/notebook/:id/:pageid/group/:groupId"
      element={<NotebookView />}
    />
    <Route
      path="/notebook/:id/:pageid/ground-object/:groundObjectId"
      element={<NotebookView />}
    />
    <Route
      path="/notebook/:id/:pageid/:objid"
      element={<NotebookView />}
    />
    <Route
      path="/notebook/:id/:pageid"
      element={<NotebookView />}
    />
    <Route
      path="/notebook/:id"
      element={<NotebookView />}
    />
    <Route
      path="/notebook"
      element={<NotebookView />}
    />

    <Route
      path="/settings/"
      element={<Organizations />}
    />

    <Route
      path="/folders/:folderId"
      element={<FolderView />}
    />
    <Route
      path="/folders"
      element={<FolderView />}
    />

    <Route
      path="/docs/userGuide"
      element={
        <PDFViewer
          title="User Guide"
          src="/docs/user_guide_v1.2.0.pdf"
        />
      }
    />
    <Route
      path="/docs/FAQs"
      element={
        <PDFViewer
          title="Frequently Asked Questions"
          src="/docs/frequently_asked_questions.pdf"
        />
      }
    />
    <Route
      path="/docs/SystemRequirements"
      element={
        <PDFViewer
          title="Minimum System Requirements"
          src="/docs/minimum_system_requirements.pdf"
        />
      }
    />
    <Route
      path="/docs/TermsOfService"
      element={
        <PDFViewer
          title="Terms of Service"
          src="/docs/terms_of_service.pdf"
        />
      }
    />
    <Route
      path="/docs/EULA"
      element={
        <div
          style={{
            width: '100%',
            maxWidth: '800px',
            height: '100%',
            margin: 'auto',
          }}
        >
          <PDFViewer
            title="End User License Agreement"
            src="/Laboratory-EULA-2023-08-06.html"
          />
        </div>
      }
    />
    <Route
      path="/docs/videosAstrodynamics"
      element={<VideosAstrodynamics />}
    />
    <Route
      path="/docs/videosUI"
      element={<VideosUI />}
    />

    <Route
      path="/"
      element={<FolderView />}
    />
    <Route
      path="/shared"
      element={<FolderView />}
    />
    <Route
      path="/errors/ServerDown"
      element={<ServerDown />}
    />
    <Route
      path="*"
      element={<NotFound />}
    />
  </Routes>
);

const privateRoutes = createRoutes();
export default privateRoutes;
