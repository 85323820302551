import { useCallback, useMemo, useState } from 'react';
import MarkerManipulator, { MarkerMoveProps } from 'src/threejs/components/MarkerManipulator';
import {
  createGetOrbitCOE,
  createGetOrbitData,
} from 'src/threejs/components/OrbitManager/store/getters';
import useCamera from 'src/threejs/hooks/useCamera';
import { COE } from 'src/threejs/models/COE';
import ManipulatorPlaneModel from 'src/threejs/models/ManipulatorPlane';
import { MarkerHandleProps } from 'src/types';
import { positiveDegAngle } from 'src/utilities/MathUtils';
import { AdditiveBlending, Group, Matrix4, Mesh, Sprite, Vector3 } from 'three';
import { use3DOrbitContext } from '../../context';

const config = {
  aopHandlePerigeeOffset: 0, // 0.1
  aopHandleRotationSpeed: 5,
};

const AopMarkerHandle = ({ handle, setHandle }: MarkerHandleProps) => {
  const { id } = use3DOrbitContext();
  const camera = useCamera();

  const tempVector = useMemo(() => new Vector3(0, 0, 0), []);
  const tempVector2 = useMemo(() => new Vector3(0, 0, 0), []);
  const tempVector3 = useMemo(() => new Vector3(0, 0, 0), []);
  const tempMatrix = useMemo(() => new Matrix4(), []);

  const getOrbitCOE = useMemo(() => createGetOrbitCOE(id), [id]);
  const getOrbitData = useMemo(() => createGetOrbitData(id), [id]);

  const textSpriteProps = useMemo(
    () => ({
      fontsize: 40,
      borderColor: { r: 255, g: 255, b: 255, a: 1.0 },
      backgroundColor: { r: 255, g: 255, b: 255, a: 0.1 },
      textColor: { r: 252, g: 136, b: 0, a: 0.75 },
      borderThickness: 0,
      sizeAttenuation: false,
    }),
    [],
  );

  const [group, setGroup] = useState<Group | null>(null);
  const [bounds, setBounds] = useState<Mesh | null>(null);
  const [sprite, setSprite] = useState<Sprite | null>(null);
  const [plane, setPlane] = useState<ManipulatorPlaneModel | null>(null);

  const makeSpriteName = (coe: COE) => `ω: ${coe.argumentOfPeriapsis.toFixed(3)}`;

  const move = useCallback(
    ({ pointOnPlaneOffset, tempVector, tempVector2, positionStart }: MarkerMoveProps) => {
      const coe = getOrbitCOE();
      const orbitData = getOrbitData();

      tempVector.copy(orbitData.orbitalPlaneVector).normalize();

      // Get tangent vector
      tempVector2.copy(orbitData.aopPosition).sub(orbitData.primeFocusPosition).normalize();
      tempVector2.cross(tempVector).negate().normalize();

      // project along tangent
      tempVector.copy(pointOnPlaneOffset).projectOnVector(tempVector2);

      let rotationangle = tempVector.length() * config.aopHandleRotationSpeed;

      rotationangle *= tempVector.dot(tempVector2) >= 0 ? 1 : -1;

      const argumentOfPeriapsis = positiveDegAngle(coe.argumentOfPeriapsis + rotationangle);

      return { argumentOfPeriapsis };
    },
    [getOrbitCOE, getOrbitData],
  );

  const updateBounds = () => {
    if (bounds) {
      const orbitData = getOrbitData();

      tempVector
        .copy(orbitData.aopPosition)
        .normalize()
        .multiplyScalar(config.aopHandlePerigeeOffset);

      bounds.position.copy(orbitData.aopPosition).add(tempVector);
    }
  };

  const updateSprite = () => {
    if (sprite && handle) {
      sprite.position.copy(handle.position);
    }
  };

  const updateTransform = () => {
    if (handle && group && plane) {
      const orbitData = getOrbitData();

      tempVector
        .copy(orbitData.aopPosition)
        .normalize()
        .multiplyScalar(config.aopHandlePerigeeOffset);

      handle.position.copy(orbitData.aopPosition).add(tempVector);

      // set handle orientation
      tempVector.copy(orbitData.aopPosition).sub(orbitData.primeFocusPosition).normalize();

      tempVector2.copy(orbitData.orbitalPlaneVector);
      tempVector3.copy(tempVector).cross(tempVector2).normalize();

      tempMatrix.makeBasis(tempVector3, tempVector, tempVector2);

      handle.quaternion.setFromRotationMatrix(tempMatrix);

      // rotate the plane for the active handle
      plane.updateTransform(camera, handle);
    }
  };

  return (
    <MarkerManipulator
      move={move}
      textSpriteMessage="aop: "
      textSpriteProps={textSpriteProps}
      makeSpriteName={makeSpriteName}
      markerName="AOP"
      setHandle={setHandle}
      handle={handle}
      setGroup={setGroup}
      setBounds={setBounds}
      setSprite={setSprite}
      setPlane={setPlane}
      plane={plane}
      group={group}
      scale={0.25}
      updateBounds={updateBounds}
      updateSprite={updateSprite}
      updateTransform={updateTransform}
      manipulatorPlaneRotation
    >
      <sphereGeometry args={[0.1, 16, 12]} />
      <meshBasicMaterial
        color={0xfc8800}
        depthTest={false}
        depthWrite={false}
        transparent={true}
        opacity={0.5}
        toneMapped={false}
        blending={AdditiveBlending}
      />
    </MarkerManipulator>
  );
};

export default AopMarkerHandle;
