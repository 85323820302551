import { useCallback, useEffect, useMemo } from 'react';
import { useCurrentGroundObjects } from 'src/hooks/GroundObjectHooks';
import { useCurrentOrbits } from 'src/hooks/OrbitHooks';
import { useStomp } from 'src/hooks/useStomp';
import { API_STORE } from 'src/services/API_STORE';
import { GroundObject, OrbitObject, ViewingWindowsResponseWS } from 'src/types';
import { v4 as uuidv4 } from 'uuid';
import { WINDOWS_STATUS, WINDOWS_TYPES, useViewingWindowsStore } from './ViewingWindowsStore';

const WS_URL_SUFFIX = '/viewing-windows';

const SERVER_STOMP_URL = `${process.env.REACT_APP_LAB_WSAPI_URL_ROOT}${WS_URL_SUFFIX}`;

// To help with debugging, switch to true for console output
const LOG_OUTPUT = false;
let logger = (...data: any[]) => {
  return;
};
if (LOG_OUTPUT) {
  logger = console.log;
}

export const ViewingWindowsManager = () => {
  const currentGroundObjects = useCurrentGroundObjects();
  const currentOrbits = useCurrentOrbits();

  const groundObjectWindows = useViewingWindowsStore((state) => state.groundObjectWindows);
  const spaceSensorWindows = useViewingWindowsStore((state) => state.spaceSensorWindows);

  const initWindows = useViewingWindowsStore((state) => state.initWindows);
  const setWindows = useViewingWindowsStore((state) => state.setWindows);
  const invalidateWindowsAll = useViewingWindowsStore((state) => state.invalidateWindowsAll);

  /**
   * WebSocket Connection management
   */
  const sessionKey = useMemo(() => {
    return uuidv4();
  }, []);

  const handleOnSubscribe = useCallback(
    ({ body }: { body: string }) => {
      const { data: response } = JSON.parse(body) as { data: ViewingWindowsResponseWS };

      if (response) {
        if (response.requestedGroundObjectId) {
          logger(
            '<--- received for ground object',
            response.requestedGroundObjectId,
            response.windows,
          );
          setWindows(
            WINDOWS_TYPES.GROUND_OBJECT,
            response.requestedGroundObjectId,
            response.windows,
          );
        } else if (response.requestedOrbitId) {
          logger('<--- received for orbit', response.requestedOrbitId, response.windows);
          setWindows(WINDOWS_TYPES.SPACE_SENSOR, response.requestedOrbitId, response.windows);
        }
      }
    },
    [setWindows],
  );

  const handleOnConnect = useCallback(() => {
    // invalidate viewing windows for each when initially connecting or reconnecting
    invalidateWindowsAll(WINDOWS_TYPES.GROUND_OBJECT);
    invalidateWindowsAll(WINDOWS_TYPES.SPACE_SENSOR);
  }, [invalidateWindowsAll]);

  const { send, isSubscribed } = useStomp(
    {
      brokerURL: SERVER_STOMP_URL,
      connectHeaders: {
        'X-Authorization': 'Bearer ' + API_STORE.token,
      },
    },
    [
      {
        destination: `/secured/user/queue/specific-user${sessionKey}`,
        onSubscribeCallback: handleOnSubscribe,
        onConnectCallback: handleOnConnect,
      },
    ],
  );

  /**
   * Ground Object Windows
   */
  const requestWindowsForGroundObject = useCallback(
    (groundObject: GroundObject) => {
      if (!isSubscribed) return;

      if (
        !groundObjectWindows[groundObject.id] ||
        groundObjectWindows[groundObject.id]?.status === WINDOWS_STATUS.INVALIDATED
      ) {
        logger('---> requesting for ground object:', groundObject.id);
        initWindows(
          WINDOWS_TYPES.GROUND_OBJECT,
          groundObject.id,
          [groundObject.targetGroupId],
          [groundObject.targetOrbitId],
        );

        send(
          '/app/viewing-windows',
          {
            groundObjectId: groundObject.id,
          },
          {
            user: API_STORE.userProfile,
            key: sessionKey,
          },
        );
      }
    },
    [groundObjectWindows, initWindows, send, sessionKey, isSubscribed],
  );

  // Ground Object requests
  useEffect(() => {
    if (isSubscribed) {
      currentGroundObjects?.forEach((groundObject) => {
        requestWindowsForGroundObject(groundObject);
      });
    }
  }, [isSubscribed, currentGroundObjects, requestWindowsForGroundObject]);

  /**
   * Orbit / Space Sensor Windows
   */
  const requestWindowsForOrbit = useCallback(
    (orbit: OrbitObject) => {
      if (!isSubscribed) return;
      if (
        !spaceSensorWindows[orbit.id] ||
        spaceSensorWindows[orbit.id]?.status === WINDOWS_STATUS.INVALIDATED
      ) {
        logger('---> requesting for orbit:', orbit.id);

        const targetGroupIds = orbit.spaceSensors.map((spaceSensor) => spaceSensor.targetGroupId);
        const targetObjectIds = orbit.spaceSensors.map(
          (spaceSensor) => spaceSensor.targetGroundObjectId,
        );

        initWindows(WINDOWS_TYPES.SPACE_SENSOR, orbit.id, targetGroupIds, targetObjectIds);

        send(
          '/app/viewing-windows',
          {
            orbitId: orbit.id,
          },
          {
            user: API_STORE.userProfile,
            key: sessionKey,
          },
        );
      }
    },
    [spaceSensorWindows, initWindows, send, sessionKey, isSubscribed],
  );

  // Space Sensor requests
  useEffect(() => {
    if (isSubscribed) {
      currentOrbits?.forEach((orbit) => {
        if (orbit.spaceSensors.length > 0) {
          requestWindowsForOrbit(orbit);
        }
      });
    }
  }, [isSubscribed, currentOrbits, requestWindowsForOrbit]);

  return null;
};
