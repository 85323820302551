import { createFFmpeg, fetchFile } from '@ffmpeg/ffmpeg';
import { DownloadRounded, ImportExportRounded } from '@mui/icons-material';
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  Typography,
} from '@mui/material';
import { useCallback, useEffect, useState } from 'react';
import { SpaceGlassContainerBase } from 'src/components/SpaceGlassControl';
import { ModalHeader } from 'src/pages/Shared/ModalSpaceGlass';

type ModalVideoDownloadProps = {
  isOpen: boolean;
  close: () => void;
  downloadName: string;
  mediaUrl: string;
  duration: number;
  mimeType: string;
};

export const ModalVideoDownload = ({
  isOpen,
  close,
  downloadName,
  mediaUrl,
  duration,
  mimeType,
}: ModalVideoDownloadProps) => {
  const [ffmpeg] = useState(createFFmpeg());
  const [mediaUrlConverted, setMediaUrlConverted] = useState('');
  const [transcodeProgress, setTranscodeProgress] = useState(0);
  const [error, setError] = useState('');
  const [status, setStatus] = useState('');

  const onClose = useCallback(() => {
    close();
  }, [close]);

  useEffect(() => {
    if (duration === 0) {
      //reset local state
      if (ffmpeg.isLoaded()) {
        ffmpeg.exit();
      }
      setMediaUrlConverted('');
      setTranscodeProgress(0);
      setError('');
      setStatus('');
    }
  }, [duration, ffmpeg]);

  const doTranscode = async () => {
    setStatus('Loading video transcoder');
    ffmpeg.setLogging(true);
    ffmpeg.setProgress((progress: any) => {
      if (progress.time) {
        setTranscodeProgress(progress.time);
      }
    });
    await ffmpeg.load();

    setStatus('Converting video');
    ffmpeg.FS('writeFile', 'test.webm', await fetchFile(mediaUrl));

    // use -vf to correct for width/height with odd numbers
    await ffmpeg.run(
      '-i',
      'test.webm',
      '-vsync',
      'vfr',
      '-vf',
      'scale=1920:1080:force_original_aspect_ratio=decrease,' + // helps prevent OOM errors
        'pad=ceil(iw/2)*2:ceil(ih/2)*2',
      'test.mp4',
    );
    const data = ffmpeg.FS('readFile', 'test.mp4');

    const newUrl = URL.createObjectURL(new Blob([data.buffer], { type: 'video/mp4;codecs="265"' }));

    setMediaUrlConverted(newUrl);

    setTranscodeProgress(0);

    ffmpeg.exit();
  };

  const isMP4 = mimeType.startsWith('video/mp4');
  const extension = isMP4 ? 'mp4' : 'webm';

  const onConvertClick = async () => {
    try {
      setStatus('Loading video transcoder...');
      await doTranscode();
    } catch (error) {
      // this only seems to catch errors that occur outside of the ffmpeg.run command
      // if OOM or something happens inside of the worker, unable to catch for now
      if (error !== 'ffmpeg has exited') {
        setError('Error encountered during conversion, please download the original.');
      }
    }
  };

  return (
    <Dialog
      fullWidth
      maxWidth="sm"
      onClose={onClose}
      open={isOpen}
      PaperComponent={SpaceGlassContainerBase}
    >
      <ModalHeader
        title="Download Video Recording"
        onClose={onClose}
        icon={<DownloadRounded />}
      />
      <DialogContent>
        <Typography
          variant="body1"
          mb={1}
        >
          Download original recording content.
        </Typography>
        <Button
          size="small"
          variant="contained"
          startIcon={<DownloadRounded />}
          href={mediaUrl}
          download={`${downloadName}.${extension}`}
        >
          Download
        </Button>

        {!isMP4 && !mediaUrlConverted && (
          <>
            <Typography
              variant="body1"
              mt={2}
            >
              Convert video to mp4 format.
            </Typography>
            <Typography
              variant="body2"
              mb={1}
            >
              Note: this process will take a while.
            </Typography>
            {status === '' && (
              <Button
                size="small"
                variant="contained"
                startIcon={<ImportExportRounded />}
                onClick={onConvertClick}
              >
                Convert
              </Button>
            )}
          </>
        )}

        {transcodeProgress !== 0 && (
          <Grid
            display="grid"
            gridAutoFlow="column"
            alignItems="center"
            justifyContent="start"
            gap={1}
            p={1}
          >
            <CircularProgress
              variant="determinate"
              size={20}
              value={Math.min(Math.round((100 * transcodeProgress) / duration), 100)}
            />
            <Typography variant="body1">{status}</Typography>
          </Grid>
        )}

        {mediaUrlConverted && (
          <Grid mt={2}>
            <Button
              size="small"
              variant="contained"
              startIcon={<DownloadRounded />}
              href={mediaUrlConverted}
              download={downloadName}
            >
              Download MP4
            </Button>
          </Grid>
        )}

        {error !== '' && (
          <Typography
            variant="h6"
            mt={1}
          >
            {error}
          </Typography>
        )}
      </DialogContent>
      <DialogActions>
        <Button
          color="secondary"
          variant="contained"
          onClick={onClose}
        >
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};
