import { Button } from '@mui/base';
import { Box, Grid, styled } from '@mui/material';
import { ReactComponent as LogoIcon } from 'src/assets/Slingshot_Logo_2020_with_text.svg';

export const EulaContainer = styled(Grid)`
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #000000;
`;

export const EulaContent = styled(Grid)`
  background-color: #121212;
  border-radius: 0.5rem;
  padding: 2rem;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  height: 680px;
  width: 600px;
  color: #fff;
`;

export const EulaLogoContainer = styled(Box)`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 60px;
  box-sizing: border-box;
`;

export const EulaLogo = LogoIcon;

export const EulaSubmitButton = styled(Button)`
  background-color: #c98e4b;
  width: 48%;
  min-height: 52px;
  padding: 4px 16px;
  font-size: 16px;
  border: 1px solid #c98e4b;
  border-radius: 0.25rem;
  color: #ffffff;
  cursor: pointer;
  :hover {
    background-color: #c08139;
  }
`;

export const EulaCancelButton = styled(Button)`
  background: transparent;
  width: 48%;
  min-height: 52px;
  font-size: 16px;
  padding: 4px 16px;
  border: 1px solid #74777b;
  border-radius: 0.25rem;
  color: #74777b;
  cursor: pointer;
  :hover {
    background-color: #e5e5e5;
    border-color: #e5e5e5;
    color: #121212;
  }
`;
