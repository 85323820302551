import { useAuth0 } from '@auth0/auth0-react';
import { Box, Fade } from '@mui/material';
import { Stats } from '@react-three/drei';
import { IMessage } from '@stomp/stompjs';
import { useSnackbar } from 'notistack';
import { useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { LAUNCH_FLYOUT_STATUS, MANEUVER_FLYOUT_STATUSES } from 'src/constants';
import { useCheckNotebookCapabilities, useUpdateCapability } from 'src/hooks/ObjectHooks';
import { useCurrentPages } from 'src/hooks/PageHooks';
import PropagatedCacheManager, {
  PropagatorErrorMessageType,
} from 'src/models/PropagatedCacheManager';
import { SETTINGS_NAMES, useSettingsStore } from 'src/pages/Settings/store';
import WebSocketManager from 'src/services/WebSocketManager';
import { usePlayTimeline, ViewportManager } from 'src/threejs';
import { useGroundObjectLaunchEditStore } from 'src/threejs/components/GroundObjects/GroundObjectLaunchEditStore';
import {
  DismissErrorNotification,
  DismissNotificationAction,
  useNotifications,
} from 'src/threejs/hooks/useNotifications';
import { RouteStore } from '../App/routes/RouteStore';
import {
  getActiveCapabilityId,
  getActiveNotebookId,
  getActivePageId,
  useRouteStore,
} from '../App/routes/store';
import theme from '../App/Theme';
import { InspectorPanel } from './components/InspectorPanel/InspectorPanel';
import { LaunchFlyoutContainer } from './components/Launch/LaunchFlyoutContainer';
import { LayoutEngine } from './components/LayoutEngine/LayoutEngine';
import { LAYOUT_CONSTRAINTS, useLayoutStore } from './components/LayoutEngine/layoutStore';
import { ManeuverFlyoutContainer } from './components/ManeuverFlyoutContainer';
import { ObjectsPanel } from './components/ObjectsPanel/ObjectsPanel';
import PagesListPanel from './components/PagesListPanel';
import TimelineView from './components/TimelineView';
import { OverlayStatsContainer } from './NotebookView.styled';
import { ViewingWindowsManager } from './components/ViewingWindowsManager';
import { useClearPropagations } from 'src/core/hooks';
import { useIsPropagating } from 'src/hooks/OrbitHooks';
import useAppStore from 'src/core/store';

function NotebookView() {
  const capabilityId = useRouteStore(getActiveCapabilityId);
  const notebookId = useRouteStore(getActiveNotebookId);
  const pageId = useRouteStore(getActivePageId);
  const navigate = useNavigate();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const currentPages = useCurrentPages();

  const isLayoutMinified = useSettingsStore(
    (state) => state.settings[SETTINGS_NAMES.LAYOUT_MINIFIED],
  );

  const maneuverFlyoutStatus = useSettingsStore(
    (state) => state.custom[SETTINGS_NAMES.MANEUVERS_FLYOUT_STATUS],
  );
  const isManeuverFlyoutOpen = maneuverFlyoutStatus !== MANEUVER_FLYOUT_STATUSES.CLOSED;

  const launchFlyoutStatus = useGroundObjectLaunchEditStore((state) => state.isLaunchEditMode);
  const isLaunchFlyoutOpen = launchFlyoutStatus !== LAUNCH_FLYOUT_STATUS.CLOSED;

  const capabilitiesCheck = useCheckNotebookCapabilities(notebookId, isNaN(capabilityId));
  const updateCapability = useUpdateCapability();
  const { user } = useAuth0();

  // On read-only mode, check if the user has capability granted and redirect for link-sharing.
  useEffect(() => {
    if (capabilityId || !capabilitiesCheck?.data?.length) return;

    const capability = capabilitiesCheck.data[0];

    // Do nothing if capability is self-granted.
    if (capability.sourceEmail === user?.email) return;

    if (capability.accessType === 'COPY') {
      if (capability.status === 'PENDING') {
        updateCapability.mutate({ capabilityId: capability.id, statusUpdate: 'ACCEPTED' });
      }
      navigate(`/shared/${capability.id}/notebook/${notebookId}`);
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [capabilitiesCheck, capabilityId, notebookId, user]);

  // Notebooks auto-page selection.
  useEffect(() => {
    const noPageSelected = pageId === undefined;
    const hasChildren = currentPages.length !== 0;
    if (hasChildren && noPageSelected) {
      // auto select the first page if there is no page in the URL or if the current page is deleted
      // if it has a capability id, redirect to shared id page, otherwise redirect to owned notebook page
      if (capabilityId) {
        // auto select the first page if there is no page in the URL
        navigate(`/shared/${capabilityId}/notebook/${notebookId}/${currentPages[0].id}`);
      } else {
        navigate(`/notebook/${notebookId}/${currentPages[0].id}`);
      }
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPages]);

  useEffect(() => {
    return function cleanup() {
      PropagatedCacheManager.reset();
    };
  }, []);

  const clearPropagation = useClearPropagations();
  const isPropagating = useIsPropagating();

  const onPropagationReconnect = () => {
    const {
      timelines: {
        timelineRange: { isLoading },
      },
    } = useAppStore.getState();
    if (!isLoading && isPropagating) {
      clearPropagation();
    }
    WebSocketManager.connectSocket();
    closeSnackbar();
  };

  useEffect(() => {
    WebSocketManager.configure({
      onMessageRecieved: (messageOutput: IMessage) => {
        const { data } = JSON.parse(messageOutput.body);

        if (
          data.errorMessage &&
          data.errorMessage === PropagatorErrorMessageType.ATMOSPHERIC_REENTRY
        ) {
          const orbit = PropagatedCacheManager.getCacheForOrbit(data.id);
          const message = `Propagation Complete for ${orbit?.name} and reentry was detected`;

          enqueueSnackbar(message, {
            action: DismissNotificationAction,
            anchorOrigin: {
              vertical: 'top',
              horizontal: 'right',
            },
            persist: true,
            preventDuplicate: true,
            variant: 'success',
          });
        }

        PropagatedCacheManager.addStateVectorToCache(data);
      },
      onUnrecoverableError: () => {
        const message =
          'A propagation error has occurred and we cannot animate this page. Please refresh and try again. If this problem persists, please contact us at lab-feedback@slingshotaerospace.com';

        enqueueSnackbar(message, {
          action: (key) => DismissErrorNotification(key, onPropagationReconnect),
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'center',
          },
          persist: true,
          preventDuplicate: true,
          variant: 'error',
        });
      },
    });

    WebSocketManager.connectSocket();

    const unsubscribeFromStompError = WebSocketManager.subscribeToStompError((frame) => {
      const message = 'Error occured during propagation';

      enqueueSnackbar(message, {
        action: (key) => DismissErrorNotification(key, onPropagationReconnect),
        key: 'propagationerror',
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'center',
        },
        preventDuplicate: true,
        variant: 'error',
        persist: true,
      });
    });

    const unsubscribeFromConnectionError = WebSocketManager.subscribeToConnectError(() => {
      const message =
        'We are currently unable to connect. Please try reconnecting or reloading the page.';

      enqueueSnackbar(message, {
        action: (key) => DismissErrorNotification(key, onPropagationReconnect),
        key: 'connectionerror',
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'center',
        },
        preventDuplicate: true,
        variant: 'error',
      });
    });

    return function cleanup() {
      WebSocketManager.disconnectClient();
      unsubscribeFromStompError();
      unsubscribeFromConnectionError();
    };
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  usePlayTimeline();
  useNotifications();

  const name = SETTINGS_NAMES.STATS_OVERLAY_ENABLED;

  const isStatsOverlayEnabled = useSettingsStore((state) => state.settings[name]);

  const overlayRef = useRef<HTMLDivElement>(null);

  const layoutToUse = useLayoutStore((state) => {
    return state.layouts[state.layoutActiveId]?.layout || {};
  });

  const updateLayout = useLayoutStore((state) => state.updateLayout);

  const handleLayoutChange = (updates: any) => {
    updateLayout(updates);
  };

  return (
    <div
      style={{
        position: 'relative',
        height: '100%',
        overflow: 'hidden',
      }}
    >
      <RouteStore />

      <ViewingWindowsManager />

      {isStatsOverlayEnabled && (
        <OverlayStatsContainer ref={overlayRef}>
          <Stats parent={overlayRef} />
        </OverlayStatsContainer>
      )}

      <ViewportManager />

      <ManeuverFlyoutContainer />

      <LaunchFlyoutContainer />

      <LayoutEngine
        layout={layoutToUse}
        onLayoutChange={handleLayoutChange}
        isLayoutMinified={isLayoutMinified || isManeuverFlyoutOpen || isLaunchFlyoutOpen}
      >
        <PagesListPanel
          key="pages"
          data-maxConstraints={LAYOUT_CONSTRAINTS.pages.maxConstraints}
          data-minConstraints={LAYOUT_CONSTRAINTS.pages.minConstraints}
        />

        <ObjectsPanel
          key="objects"
          data-maxConstraints={LAYOUT_CONSTRAINTS.objects.maxConstraints}
          data-minConstraints={LAYOUT_CONSTRAINTS.objects.minConstraints}
        />

        <InspectorPanel
          key="inspector"
          data-maxConstraints={LAYOUT_CONSTRAINTS.inspector.maxConstraints}
          data-minConstraints={LAYOUT_CONSTRAINTS.inspector.minConstraints}
        />

        <TimelineView
          key="timeline"
          data-maxConstraints={LAYOUT_CONSTRAINTS.timeline.maxConstraints}
          data-minConstraints={LAYOUT_CONSTRAINTS.timeline.minConstraints}
        />
      </LayoutEngine>

      <Fade
        in={isLayoutMinified || isManeuverFlyoutOpen || isLaunchFlyoutOpen}
        mountOnEnter
        unmountOnExit
      >
        <Box
          component="div"
          sx={{
            position: 'absolute',
            left: '0',
            bottom: '0',
            width: '100%',
            padding: theme.spacing(1),
            zIndex: 1,
          }}
        >
          <TimelineView variant="simple" />
        </Box>
      </Fade>
    </div>
  );
}

export default NotebookView;
