import { CanvasTexture, NearestFilter, SpriteMaterial } from 'three';

export type TextSpriteParameters = {
  fontsize: number;
  borderColor: { r: number; g: number; b: number; a: number };
  backgroundColor: { r: number; g: number; b: number; a: number };
  textColor: { r: number; g: number; b: number; a: number };
  borderThickness: number;
  sizeAttenuation: boolean;
  fontface?: string;
};

function makeTextSprite(message: string, parameters?: TextSpriteParameters): SpriteMaterial {
  if (parameters === undefined) {
    parameters = {
      fontface: 'Arial',
      fontsize: 18,
      borderThickness: 1,
      borderColor: { r: 0, g: 0, b: 0, a: 1.0 },
      backgroundColor: { r: 255, g: 255, b: 255, a: 1.0 },
      textColor: { r: 0, g: 0, b: 0, a: 1.0 },
      sizeAttenuation: true,
    };
  }

  const {
    fontface,
    fontsize,
    borderThickness,
    borderColor,
    backgroundColor,
    textColor,
    sizeAttenuation,
  } = parameters;

  const canvas = document.createElement('canvas');
  const context = canvas.getContext('2d');

  if (!context) {
    throw new Error('Text Sprite Context not found');
  }

  //context.textBaseline = "alphabetic";
  //context.textAlign = "left";
  canvas.width = 300;
  canvas.height = 150;

  context.font = 'Bold ' + fontsize + 'px ' + fontface;

  // get size data (height depends only on font size)
  const metrics = context.measureText(message);
  const textWidth = metrics.width;

  context.fillStyle =
    'rgba(' +
    backgroundColor.r +
    ',' +
    backgroundColor.g +
    ',' +
    backgroundColor.b +
    ',' +
    backgroundColor.a +
    ')';

  context.strokeStyle =
    'rgba(' + borderColor.r + ',' + borderColor.g + ',' + borderColor.b + ',' + borderColor.a + ')';

  context.lineWidth = borderThickness;

  if (borderThickness !== 0) {
    roundRect(
      context,
      borderThickness / 2,
      borderThickness / 2,
      (textWidth + borderThickness) * 1.1,
      fontsize * 1.4 + borderThickness,
      8,
    );
    // 1.4 is extra height factor for text below baseline: g,j,p,q.
  }

  context.fillStyle =
    'rgba(' + textColor.r + ', ' + textColor.g + ', ' + textColor.b + ', ' + textColor.a + ')';

  context.fillText(message, borderThickness, fontsize + borderThickness);

  //var texture = new Texture(canvas)
  const texture = new CanvasTexture(canvas);
  texture.needsUpdate = true;

  const spriteMaterial = new SpriteMaterial({
    map: texture,
    depthTest: false,
    depthWrite: false,
    sizeAttenuation: sizeAttenuation,
  });

  // spriteMaterial.sizeAttenuation = false;
  if (spriteMaterial.map) {
    spriteMaterial.map.minFilter = NearestFilter;
  }

  //sprite.scale.set(0.5 * fontsize, 0.25 * fontsize, 0.75 * fontsize);

  return spriteMaterial;
}

// function for drawing rounded rectangles
function roundRect(
  ctx: CanvasRenderingContext2D,
  x: number,
  y: number,
  w: number,
  h: number,
  r: number,
) {
  ctx.beginPath();
  ctx.moveTo(x + r, y);
  ctx.lineTo(x + w - r, y);
  ctx.quadraticCurveTo(x + w, y, x + w, y + r);
  ctx.lineTo(x + w, y + h - r);
  ctx.quadraticCurveTo(x + w, y + h, x + w - r, y + h);
  ctx.lineTo(x + r, y + h);
  ctx.quadraticCurveTo(x, y + h, x, y + h - r);
  ctx.lineTo(x, y + r);
  ctx.quadraticCurveTo(x, y, x + r, y);
  ctx.closePath();
  ctx.fill();
  ctx.stroke();
}

export { makeTextSprite };
