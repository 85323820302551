import { ORBIT_TYPES } from 'src/constants';
import { create } from 'zustand';
import { subscribeWithSelector } from 'zustand/middleware';
import { immer } from 'zustand/middleware/immer';
import { OrbitStoreState } from './types';
import { createInitialOrbitState } from './utils';

const use3DOrbitStore = create<OrbitStoreState>()(
  subscribeWithSelector(
    immer((set, get) => ({
      orbits: {},
      createOrbitState: (orbit, size) => {
        set((state) => {
          // if its a TLE and it was back-propagated since prior fetch or orbit doesn't exist in store then re-init
          // TLE COE props are updated on backend if page times change
          if (
            !state.orbits[orbit.id] ||
            (orbit.orbitType === ORBIT_TYPES.TLE &&
              !state.orbits[orbit.id].coe.isEqual(orbit.orbit[0]))
          ) {
            state.orbits[orbit.id] = createInitialOrbitState(orbit, size, set, get);
          }
        });
      },
      deleteOrbitState: (id) => {
        set((state) => {
          delete state.orbits[id];
        });
      },
      deleteAllOrbits: () => {
        set((state) => {
          state.orbits = {};
        });
      },
      set,
    })),
  ),
);

export default use3DOrbitStore;
