import { cloneDeep } from 'lodash';
import { LayoutFromDB } from 'src/hooks/LayoutHooks';
import { create } from 'zustand';
import { persist } from 'zustand/middleware';
import { immer } from 'zustand/middleware/immer';

const TIMELINE_HEIGHT = 195;

export const LAYOUT_CUSTOM = {
  id: 'custom',
  name: 'Custom',
};

const LAYOUTS_PREDEFINED: Record<number | string, LayoutStoreItem> = {
  cinematic: {
    name: 'Cinematic',
    predefined: true,
    layout: {
      pages: {
        top: 0,
        left: 0,
        zIndex: 4,
        width: 0.2,
        height: 0.35,
      },
      objects: {
        top: 0.35,
        bottom: TIMELINE_HEIGHT,
        left: 0,
        zIndex: 3,
        width: 0.2,
        height: 0.65,
      },
      inspector: {
        top: 80,
        left: 1,
        bottom: TIMELINE_HEIGHT,
        zIndex: 2,
        width: 0.2,
        height: 1,
      },
      timeline: {
        top: 1,
        left: 0,
        zIndex: 1,
        width: 1,
        height: TIMELINE_HEIGHT,
      },
    },
  },
  orbitConfiguration: {
    name: 'Orbit Configuration',
    predefined: true,
    layout: {
      pages: {
        top: 0,
        left: 0,
        zIndex: 1,
        width: 0.2,
        height: 0.35,
      },
      objects: {
        top: 0.35,
        left: 0,
        zIndex: 1,
        width: 0.2,
        height: 0.65,
      },
      inspector: {
        top: 80,
        left: 1,
        zIndex: 1,
        width: 0.2,
        height: 1,
      },
      timeline: {
        top: 1,
        left: 0.2,
        right: 0.2,
        zIndex: 1,
        width: 0.6,
        height: TIMELINE_HEIGHT,
      },
    },
  },
};

export const LAYOUT_ACTIVE_ID_DEFAULT = Object.keys(LAYOUTS_PREDEFINED)[0];

export const LAYOUT_CONSTRAINTS = {
  inspector: {
    minConstraints: [300, 250],
    maxConstraints: [1, 1],
  },
  objects: {
    minConstraints: [250, 200],
    maxConstraints: [1, 1],
  },
  pages: {
    minConstraints: [250, 150],
    maxConstraints: [1, 1],
  },
  timeline: {
    minConstraints: [350, TIMELINE_HEIGHT],
    maxConstraints: [1, TIMELINE_HEIGHT],
  },
};

export interface LayoutEngineItemState {
  top: number;
  left: number;
  bottom?: number;
  right?: number;
  zIndex: number;
  width: number;
  height: number;
}

export interface LayoutStoreItem {
  name: string;
  predefined: boolean;
  saved?: boolean;
  layout: Record<string, LayoutEngineItemState>;
  layoutOriginal?: LayoutFromDB;
}

interface LayoutStoreState {
  apiDataLoaded: boolean;
  setApiDataLoaded: (isLoaded: boolean) => void;

  layouts: Record<number | string, LayoutStoreItem>;
  setLayout: (layout: LayoutFromDB) => void;

  layoutActiveId: number | string;
  setLayoutActiveId: (id: number | string) => void;

  updateLayout: (updates: Partial<LayoutStoreItem>) => void;

  deleteLayout: (id: number | string) => void;
}

export const useLayoutStore = create<LayoutStoreState>()(
  persist(
    immer((set, _get) => ({
      apiDataLoaded: false,
      setApiDataLoaded: (isLoaded) => {
        set((state) => {
          state.apiDataLoaded = isLoaded;
        });
      },

      layouts: LAYOUTS_PREDEFINED,
      setLayout: (layout) => {
        set((state) => {
          state.layouts[layout.id] = {
            name: layout.name,
            predefined: false,
            saved: true,
            layout: layout.properties.panels,
            layoutOriginal: layout,
          };
        });
      },

      layoutActiveId: LAYOUT_ACTIVE_ID_DEFAULT,
      setLayoutActiveId: (id) => {
        set((state) => {
          state.layoutActiveId = id;
        });
      },

      updateLayout: (updates) => {
        set((state) => {
          // if predefined layout, convert to new custom
          if (state.layouts[state.layoutActiveId].predefined) {
            const newName = getNewName(
              LAYOUT_CUSTOM.name,
              Object.values(state.layouts).map((layout) => layout.name),
            );
            state.layouts[newName] = cloneDeep(state.layouts[state.layoutActiveId]);
            state.layouts[newName].name = newName;
            state.layouts[newName].predefined = false;
            state.layoutActiveId = newName;
          }

          state.layouts[state.layoutActiveId].saved = false;
          if (updates.name) {
            state.layouts[state.layoutActiveId].name = updates.name;
          }
          if (updates.layout) {
            Object.entries(updates.layout).forEach(([key, value]) => {
              state.layouts[state.layoutActiveId].layout[key] = value;
            });
          }
        });
      },

      deleteLayout: (id) => {
        set((state) => {
          delete state.layouts[id];
        });
      },
    })),
    {
      name: 'layout',
      partialize: (state) => ({
        layoutActiveId: state.layoutActiveId,
      }),
    },
  ),
);

const getNewName = (nameOriginal: string, existingNames: string[]) => {
  let iteration = 1;
  let nameNew = nameOriginal + ' ' + iteration;
  while (existingNames.includes(nameNew)) {
    iteration++;
    nameNew = nameOriginal + ' ' + iteration;
  }

  return nameNew;
};
