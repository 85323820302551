import { LABEL_SIZE, VISUAL_AID_HEX, scaleFactor } from 'src/constants';
import { getCurrentTime, getPlayState } from 'src/core/getters';
import { useIsPropagating } from 'src/hooks/OrbitHooks';
import { useCurrentPage } from 'src/hooks/PageHooks';
import { ViewingWindow } from 'src/types';
import binarySearchForClosestValue from 'src/utilities/binarySearchForClosestValue';
import { convertKeplarianToCartesian } from 'src/utilities/Keplerian';
import { Group, Vector3 } from 'three';
import { ArrowVector } from '../ArrowVector';
import { createGetOrbitActiveSv, createGetOrbitCOE } from '../OrbitManager/store/getters';
import { useCallback, useMemo, useRef } from 'react';

interface OrbitLoSArrowProps {
  orbitId: number;
  groundObjectGroup: Group;
  orbitViewingWindows: ViewingWindow[];
}

export const OrbitLoSArrow = ({
  orbitId,
  groundObjectGroup,
  orbitViewingWindows,
}: OrbitLoSArrowProps) => {
  const currentPage = useCurrentPage();
  const isPropagating = useIsPropagating();

  const targetViewingWindow = useRef<ViewingWindow | null>(null);
  const distanceToTarget = useRef<number | null>(null);
  const groundObjPos = useMemo(() => new Vector3(0, 0, 0), []);

  const getOrbitActiveSv = createGetOrbitActiveSv(orbitId);
  const getOrbitCOE = createGetOrbitCOE(orbitId);

  const getLoSArrowTarget = () => {
    const to = getCurrentSv();
    groundObjectGroup?.worldToLocal(to);
    const newVec = new Vector3(to.z, to.x, to.y);
    return newVec;
  };

  // on frame, check if the current time is within any of the viewing windows for this ground track
  const getCurrentSv = () => {
    const currentTime = getCurrentTime();
    let currentSv = new Vector3();

    // use the current time to locate the correct viewing window to fall within (if there is one)
    if (orbitViewingWindows.length < 1) return currentSv;
    const foundValues = binarySearchForClosestValue<ViewingWindow>(
      orbitViewingWindows,
      currentTime,
      (item: ViewingWindow) => item.startTime.getTime(),
    );

    const { low, high } = foundValues;

    const closestViewingWindow = low.item || high.item;

    targetViewingWindow.current = closestViewingWindow as ViewingWindow | null;

    const currentPlayState = getPlayState();
    const orbitCOEParams = getOrbitCOE();
    const orbitActiveSv = getOrbitActiveSv();

    if (
      isPropagating &&
      orbitActiveSv &&
      (currentPlayState === 'playing' || currentPlayState === 'stopped')
    ) {
      const propagationStateVector = {
        x_velocity: orbitActiveSv.x_velocity / 1000,
        y_velocity: orbitActiveSv.y_velocity / 1000,
        z_velocity: orbitActiveSv.z_velocity / 1000,
        x_position: orbitActiveSv.x_position / 1000,
        y_position: orbitActiveSv.y_position / 1000,
        z_position: orbitActiveSv.z_position / 1000,
      };

      currentSv = new Vector3(
        propagationStateVector.y_position! * scaleFactor,
        propagationStateVector.z_position! * scaleFactor,
        propagationStateVector.x_position! * scaleFactor,
      );
    } else {
      const previewModeSv = convertKeplarianToCartesian(orbitCOEParams);
      currentSv = new Vector3(
        previewModeSv.y_position! * scaleFactor,
        previewModeSv.z_position! * scaleFactor,
        previewModeSv.x_position! * scaleFactor,
      );
    }
    return currentSv;
  };

  const getGroundObjectLoSVisibility: () => boolean = useCallback(() => {
    if (!targetViewingWindow.current) return false;
    if (isPropagating) {
      const currentTime = getCurrentTime();
      // check if the current time falls within any viewing windows
      const isViewable =
        targetViewingWindow.current.startTime.getTime() <= currentTime &&
        targetViewingWindow.current.endTime.getTime() >= currentTime;
      return !!isViewable;
    }

    const previewModeOrbitStartTime = currentPage?.startTime;
    if (!orbitViewingWindows || orbitViewingWindows.length === 0) return false;

    return orbitViewingWindows[0].startTime.getTime() === previewModeOrbitStartTime?.getTime();
  }, [currentPage?.startTime, isPropagating, orbitViewingWindows]);

  const getDistanceToTargetKm = () => {
    groundObjectGroup.getWorldPosition(groundObjPos);
    const distance = groundObjPos.distanceTo(getCurrentSv());
    distanceToTarget.current = Math.floor(distance / scaleFactor);
    return `${new Intl.NumberFormat().format(distanceToTarget.current)} km`;
  };

  const distanceLabelNum = distanceToTarget?.current
    ? new Intl.NumberFormat().format(distanceToTarget.current)
    : 0;

  return (
    <ArrowVector
      labelPosition="stem"
      labelTextProps={{
        fontSize: LABEL_SIZE.LINE_OF_SIGHT_KM,
        renderOrder: 1,
      }}
      getLabelContent={getDistanceToTargetKm}
      label={`${distanceLabelNum} km`}
      labelShort={`${distanceLabelNum} km`}
      color={VISUAL_AID_HEX}
      getVisibility={getGroundObjectLoSVisibility}
      getDestination={getLoSArrowTarget}
    />
  );
};
