import { SvgIcon, Tooltip } from '@mui/material';
import { ReactNode } from 'react';

type Icon = {
  icon: ReactNode;
};
type Icons = {
  iconOn: ReactNode;
  iconOff: ReactNode;
};

interface ToggleObjectAdditionalPropertyProps {
  enabled: boolean;
  icon: Icon | Icons;
  tooltip: string;
  onChange: (enabled: boolean) => void;
}

export const ToggleObjectAdditionalProperty = ({
  enabled,
  icon,
  tooltip,
  onChange,
}: ToggleObjectAdditionalPropertyProps) => {
  return (
    <Tooltip
      title={tooltip}
      placement="top"
      disableInteractive
    >
      <span>
        <SvgIcon
          onClick={(event) => {
            event.stopPropagation();
            onChange(!enabled);
          }}
          color={enabled ? 'action' : 'disabled'}
          sx={{
            display: 'grid',
            fontSize: 15,
            cursor: 'pointer',
          }}
        >
          {(icon as Icon).icon}

          {(icon as Icons).iconOn && enabled ? (icon as Icons).iconOn : (icon as Icons).iconOff}
        </SvgIcon>
      </span>
    </Tooltip>
  );
};
