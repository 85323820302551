import { useEffect, useState } from 'react';
import { notProd } from 'src/utilities/envHelper';
import { useEventListener } from 'usehooks-ts';
import { SETTINGS_NAMES, useSettingsStore } from './store';

const SETTINS_ACTIONS = {
  TOGGLE_LAYOUT_PANELS: {
    allowed: true,
    name: SETTINGS_NAMES.LAYOUT_MINIFIED,
    keyString: [' +alt'], // Space + alt/option key
  },
  TOGGLE_DEVELOPER_MENU: {
    allowed: notProd(),
    name: SETTINGS_NAMES.DEVELOPER_MENU_ENABLED,
    keyString: ['saDev'],
  },
  TOGGLE_DEVELOPER_MENU_OPENED: {
    allowed: notProd(),
    name: SETTINGS_NAMES.DEVELOPER_MENU_OPENED,
    keyString: ['D+ctrl'], // d with ctrl and shift keys
  },
};

const KEY_MODIFIERS = ['Shift', 'Control', 'Alt', 'Meta'];

export const SettingsManager = () => {
  const toggleSetting = useSettingsStore((state) => state.toggleSetting);

  const [keysPressed, setKeysPressed] = useState<string>('');

  useEventListener('keyup', (event) => {
    // ignore key modifier up events
    if (!KEY_MODIFIERS.includes(event.key)) {
      const keyString = [event.key];
      if (event.altKey) keyString.push('alt');
      if (event.ctrlKey) keyString.push('ctrl');
      if (event.metaKey) keyString.push('meta');

      if (event.code !== `Key${event.key}`) {
        if (event.shiftKey) keyString.push('shift');
      }

      setKeysPressed(keysPressed + keyString.join('+'));
    }
  });

  useEffect(() => {
    // check if current combos match any of our keyCombo's
    if (keysPressed !== '') {
      let hasPotentialMatch = false;

      Object.values(SETTINS_ACTIONS).every((action) => {
        if (action.allowed) {
          if (action.keyString.join('') === keysPressed) {
            // we have a match, flip the setting and reset the keys
            toggleSetting(action.name);
            setKeysPressed('');

            hasPotentialMatch = true; // set so the check below doesn't reset as well
            return false;
          } else if (action.keyString.join('').startsWith(keysPressed)) {
            hasPotentialMatch = true;
          }
        }
        return true;
      });

      if (!hasPotentialMatch && keysPressed.length > 0) {
        setKeysPressed('');
      }
    }
  }, [keysPressed, toggleSetting]);

  return null;
};
