import { useCallback, useMemo, useState } from 'react';
import { earthradius } from 'src/constants';
import MarkerManipulator, { MarkerMoveProps } from 'src/threejs/components/MarkerManipulator';
import {
  UpdateSpriteProps,
  UpdateTransformProps,
} from 'src/threejs/components/MarkerManipulator/MarkerManipulator';
import {
  createGetOrbitCOE,
  createGetOrbitData,
} from 'src/threejs/components/OrbitManager/store/getters';
import useCamera from 'src/threejs/hooks/useCamera';
import { COE } from 'src/threejs/models/COE';
import ManipulatorPlaneModel from 'src/threejs/models/ManipulatorPlane';
import { MarkerHandleProps } from 'src/types';
import { AdditiveBlending, Group, Mesh, Sprite, Vector3 } from 'three';
import { use3DOrbitContext } from '../../context';

const config = {
  eccHandleOffset: 0.5,
};

const ECCMarkerHandle = ({ handle, setHandle }: MarkerHandleProps) => {
  const { id } = use3DOrbitContext();
  const camera = useCamera();

  const tempVector = useMemo(() => new Vector3(0, 0, 0), []);
  const upVector = useMemo(() => new Vector3(0, 1, 0), []);

  const getOrbitData = useMemo(() => createGetOrbitData(id), [id]);
  const getOrbitCOE = useMemo(() => createGetOrbitCOE(id), [id]);

  const textSpriteProps = useMemo(
    () => ({
      fontsize: 40,
      borderColor: { r: 255, g: 255, b: 255, a: 1.0 },
      backgroundColor: { r: 255, g: 255, b: 255, a: 0.1 },
      textColor: { r: 255, g: 0, b: 0, a: 0.75 },
      borderThickness: 0,
      sizeAttenuation: false,
    }),
    [],
  );

  const [group, setGroup] = useState<Group | null>(null);
  const [bounds, setBounds] = useState<Mesh | null>(null);
  const [sprite, setSprite] = useState<Sprite | null>(null);
  const [plane, setPlane] = useState<ManipulatorPlaneModel | null>(null);

  const makeSpriteName = (coe: COE) => `e: ${coe.eccentricity.toFixed(3)}`;

  const move: any = useCallback(
    ({ pointOnPlaneOffset, tempVector, tempVector2, positionStart }: MarkerMoveProps) => {
      const coe = getOrbitCOE();
      const orbitData = getOrbitData();
      if (!handle) return;
      // modify offset so along the minor axis vector
      pointOnPlaneOffset.projectOnVector(orbitData.minorAxisVector);

      const handleObjPosition = new Vector3().copy(handle.position);

      // delta move the handle along the new line vector
      handleObjPosition.copy(pointOnPlaneOffset).add(positionStart);

      // handle offset vector from orbit
      tempVector2.copy(orbitData.minorAxisVector).multiplyScalar(config.eccHandleOffset);

      // ** update orbit eccentricity
      //tempVector.copy(handleObj.position).sub(orbitData.ellipseCenterPosition);
      tempVector.copy(handleObjPosition).projectOnVector(orbitData.minorAxisVector);
      tempVector.sub(tempVector2); // account for the offset

      const semiMinorRadius = tempVector.length();

      // TODO: consolidate constants
      const scaleFactor = earthradius;

      const b = semiMinorRadius * scaleFactor;
      const aSqr = coe.semiMajorAxis * coe.semiMajorAxis;

      // calculate new eccentricity
      const x = Math.max(1 - (b * b) / aSqr, 0);

      const eccentricity = Math.sqrt(x);

      // Only update handle position if eccentricity actually changed.
      if (coe.eccentricity !== eccentricity) {
        handle.position.copy(handleObjPosition);
      }

      // Only update handle position if eccentricity actually changed.
      handle.position.copy(handleObjPosition);

      return { eccentricity };
    },
    [getOrbitCOE, getOrbitData, handle],
  );

  const updateBounds = () => {
    if (bounds) {
      const orbitData = getOrbitData();
      // update bounds position
      bounds.position.copy(orbitData.semiMinorAxisPosition1);
    }
  };

  const updateSprite = ({ tempVector2 }: UpdateSpriteProps) => {
    if (sprite && handle) {
      const orbitData = getOrbitData();

      tempVector.copy(orbitData.minorAxisVector).normalize().multiplyScalar(0.5);

      sprite.position.copy(handle.position).add(tempVector2).add(tempVector);
    }
  };

  const updateTransform = ({ dragging }: UpdateTransformProps) => {
    if (handle && group && plane) {
      const orbitData = getOrbitData();

      // offset handle from perigee position
      tempVector.copy(orbitData.minorAxisVector).multiplyScalar(config.eccHandleOffset);

      // Only move the handle to the orbit if we aren't dragging it
      if (!dragging) {
        // move handle to offset position on the orbit
        handle.position.copy(orbitData.semiMinorAxisPosition1).add(tempVector);
      }

      // use the y-up vector because the handle is a y-up cone and align to sma
      handle.quaternion.setFromUnitVectors(upVector, orbitData.minorAxisVector.clone().normalize());

      // rotate the plane for the active handle
      plane.updateTransform(camera, handle);
    }
  };

  return (
    <MarkerManipulator
      move={move}
      textSpriteMessage="ecc: "
      textSpriteProps={textSpriteProps}
      makeSpriteName={makeSpriteName}
      markerName="ECC"
      setHandle={setHandle}
      handle={handle}
      setGroup={setGroup}
      setBounds={setBounds}
      setSprite={setSprite}
      setPlane={setPlane}
      plane={plane}
      group={group}
      scale={0.2}
      updateBounds={updateBounds}
      updateSprite={updateSprite}
      updateTransform={updateTransform}
    >
      <coneGeometry args={[0.1, 0.2, 4, 1]} />
      <meshBasicMaterial
        color={0xff0000}
        depthTest={false}
        depthWrite={false}
        transparent={true}
        opacity={0.5}
        toneMapped={false}
        blending={AdditiveBlending}
      />
    </MarkerManipulator>
  );
};

export default ECCMarkerHandle;
