import { RocketLaunchRounded } from '@mui/icons-material';
import { Html } from '@react-three/drei';
import gsap from 'gsap';
import { MeshLineGeometry, MeshLineMaterial } from 'meshline';
import { useEffect, useState } from 'react';
import {
  DEFAULT_ADDITIONAL_PROPERTIES_ORBIT,
  muEarth,
  PROPAGATION_STEPS_PER_REVOLUTION,
  scaleFactor,
} from 'src/constants';
import { useOrbit } from 'src/hooks/OrbitHooks';
import { getActiveObjectId, useRouteStore } from 'src/pages/App/routes/store';
import keplerianOrbitMath from 'src/threejs/math/keplerianOrbit';
import { StateVectorType } from 'src/types';
import { computeManeuverVelocity, convertCartesianToKeplerian } from 'src/utilities/Keplerian';
import { Group, Mesh, Vector3 } from 'three';
import tinycolor from 'tinycolor2';
import { useManeuverStore } from './store';

const COLOR_ERROR = '#ff0000';

const ESCAPE_VECTOR_LENGTH_THRESHOLD = 200;

interface ManeuverHelpersProps {
  stateVector: StateVectorType;
  maneuverVelocities: Vector3;
}

export const ManeuverPreviewOrbit = ({ stateVector, maneuverVelocities }: ManeuverHelpersProps) => {
  const orbitId = useRouteStore(getActiveObjectId);
  const additionalProps =
    useOrbit(orbitId)?.additionalProperties || DEFAULT_ADDITIONAL_PROPERTIES_ORBIT;

  const colorManeuver = tinycolor(additionalProps.color).brighten(25).toString();

  const [meshRef, setMeshRef] = useState<Mesh | null>(null);
  const [iconRef, setIconRef] = useState<Group | null>(null);

  const hasErrorEarth = useManeuverStore((state) => state.hasErrorEarth);
  const setHasErrorEarth = useManeuverStore((state) => state.setHasErrorEarth);
  const hasErrorEscape = useManeuverStore((state) => state.hasErrorEscape);
  const setHasErrorEscape = useManeuverStore((state) => state.setHasErrorEscape);

  useEffect(() => {
    const velocityCombined = computeManeuverVelocity(stateVector, maneuverVelocities);

    const positionDir = new Vector3(
      stateVector.y_position / 1000,
      stateVector.z_position / 1000,
      stateVector.x_position / 1000,
    );

    const keplerianElements = convertCartesianToKeplerian(
      new Vector3(
        stateVector.x_position / 1000,
        stateVector.y_position / 1000,
        stateVector.z_position / 1000,
      ),
      new Vector3(velocityCombined.z, velocityCombined.x, velocityCombined.y),
    );

    const data = keplerianOrbitMath(keplerianElements, PROPAGATION_STEPS_PER_REVOLUTION);

    setHasErrorEarth(data.altitudeOfPerigee <= 100);

    // formula from https://en.wikipedia.org/wiki/Escape_velocity#Overview
    const velocityEscapeLimit = Math.sqrt((2 * muEarth) / positionDir.length());
    const velocityEscaped = velocityCombined.length() >= velocityEscapeLimit;
    setHasErrorEscape(velocityEscaped);

    const verts: Vector3[] = [];
    let addPoints = true;
    data.rArray.forEach((radius, index) => {
      const rhat = new Vector3(0, 0, 0);
      const theta = data.trueAnomalyRangeArray[index];
      data.angleToPositionXform(theta, rhat);
      rhat.multiplyScalar(radius).multiplyScalar(scaleFactor);

      // if we've reached escape velocity only add new points that are within threshold distance
      if (addPoints && index > 0 && velocityEscaped) {
        if (verts[index - 1].distanceTo(rhat) > ESCAPE_VECTOR_LENGTH_THRESHOLD) {
          addPoints = false;
        }
      }
      if (addPoints) {
        verts[index] = rhat;
      }
    });

    if (iconRef) {
      const iconPosition = positionDir.multiplyScalar(scaleFactor);

      iconRef.position.set(iconPosition.x, iconPosition.y, iconPosition.z);
    }

    if (meshRef) {
      meshRef.geometry.dispose();
      const geometry = new MeshLineGeometry();
      geometry.setPoints(verts);
      meshRef.geometry = geometry;

      const gsapContainer = {
        dashOffset: 1,
      };

      if (meshRef.geometry.index) {
        gsap.fromTo(
          gsapContainer,
          {
            dashOffset: 1,
          },
          {
            dashOffset: 0,
            duration: 60,
            repeat: -1,
            ease: 'none',
            onUpdate: () => {
              const material: MeshLineMaterial = meshRef.material as MeshLineMaterial;
              material.dashOffset = gsapContainer.dashOffset;
            },
          },
        );
      }
    }
  }, [
    hasErrorEscape,
    iconRef,
    meshRef,
    stateVector,
    maneuverVelocities,
    setHasErrorEarth,
    setHasErrorEscape,
  ]);

  return (
    <group>
      <mesh
        name={'Maneuver Preview Orbit'}
        ref={setMeshRef}
        renderOrder={2}
      >
        <meshLineMaterial
          lineWidth={0.025}
          color={hasErrorEarth || hasErrorEscape ? COLOR_ERROR : colorManeuver}
          dashArray={1 / 2 ** 6}
          transparent={true}
        />
      </mesh>

      <group ref={setIconRef}>
        <Html
          center
          zIndexRange={[0, 0]}
        >
          <div>
            <RocketLaunchRounded
              htmlColor={hasErrorEarth || hasErrorEscape ? COLOR_ERROR : colorManeuver}
              sx={{
                fontSize: 48,
                background: '#000',
                borderRadius: '50%',
                padding: 1,
              }}
            />
          </div>
        </Html>
      </group>
    </group>
  );
};
