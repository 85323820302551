import { CloseRounded, ContentCopyRounded } from '@mui/icons-material';
import { Button, Grid, InputAdornment, SvgIcon, TextField, Typography } from '@mui/material';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { ReactComponent as ManeuverIcon } from 'src/assets/maneuvers.svg';
import { PanelHeader } from 'src/components/PanelHeader';
import { ScrollableContainer } from 'src/components/ScrollableContainer';
import { SpaceGlassContainerBase } from 'src/components/SpaceGlassControl';
import {
  CONTENT,
  MANEUVER_FLYOUT_STATUSES,
  RIC_VELOCITY_RANGE_MAX,
  RIC_VELOCITY_RANGE_MIN,
} from 'src/constants';
import { useCurrentTime } from 'src/core/hooks';
import { useCreateManeuver, useUpdateManeuver } from 'src/hooks/ManeuverHooks';
import { useCurrentOrbit } from 'src/hooks/OrbitHooks';
import PropagatedCacheManager from 'src/models/PropagatedCacheManager';
import { SETTINGS_NAMES, useSettingsStore } from 'src/pages/Settings/store';
import use3DOrbitStore from 'src/threejs/components/OrbitManager/store/store';
import { Maneuver } from 'src/types';
import { Vector3 } from 'three';
import { useManeuverStore } from './Maneuvers/store';
import { InputNameEditor } from './NameInputEditor';
import { ObjectPropertySlider } from './ObjectPropertySlider';

export const ManeuverEditor = () => {
  const currentOrbit = useCurrentOrbit();

  const createManeuver = useCreateManeuver();
  const updateManeuver = useUpdateManeuver();

  const activeSV = use3DOrbitStore(
    (state) => currentOrbit && state.orbits[currentOrbit.id].activeStateVector,
  );

  const currentTime = useCurrentTime();

  const setCustom = useSettingsStore((state) => state.setCustom);
  const maneuverFlyoutStatus = useSettingsStore(
    (state) => state.custom[SETTINGS_NAMES.MANEUVERS_FLYOUT_STATUS],
  );

  const handleClose = useCallback(() => {
    setCustom(SETTINGS_NAMES.MANEUVERS_FLYOUT_STATUS, MANEUVER_FLYOUT_STATUSES.CLOSED);
  }, [setCustom]);

  // if the curentOrbit changes, close the flyout, this also occurs when page refreshed
  useEffect(() => {
    if (!currentOrbit) {
      handleClose();
    }
  }, [currentOrbit, handleClose]);

  const editManeuver: Maneuver | undefined =
    maneuverFlyoutStatus <= 0
      ? undefined
      : currentOrbit?.maneuvers.find((maneuver) => maneuver.id === maneuverFlyoutStatus);

  const hasErrorEarth = useManeuverStore((state) => state.hasErrorEarth);
  const hasErrorEscape = useManeuverStore((state) => state.hasErrorEscape);
  const hasErrorPropagationData = useManeuverStore((state) => state.hasErrorPropagationData);
  const setHasErrorPropagationData = useManeuverStore((state) => state.setHasErrorPropagationData);

  const [name, setName] = useState(editManeuver?.name ? editManeuver.name : 'New Maneuver');

  const [radialComponent, setRadialComponent] = useState(
    editManeuver?.radialComponent ? editManeuver.radialComponent.toString() : '0',
  );
  const [inTrackComponent, setInTrackComponent] = useState(
    editManeuver?.inTrackComponent ? editManeuver.inTrackComponent.toString() : '0',
  );
  const [crossTrackComponent, setCrossTrackComponent] = useState(
    editManeuver?.crossTrackComponent ? editManeuver.crossTrackComponent.toString() : '0',
  );

  const [rNum, iNum, cNum] = useMemo(
    () => [
      parseFloat(radialComponent),
      parseFloat(inTrackComponent),
      parseFloat(crossTrackComponent),
    ],
    [radialComponent, inTrackComponent, crossTrackComponent],
  );

  // synchronize maneuver store RIC values with new text field/slider values
  // for an existing maneuver being edited
  const { setManeuverRIC } = useManeuverStore();
  useEffect(() => {
    if (!isNaN(rNum) && !isNaN(iNum) && !isNaN(cNum)) {
      setManeuverRIC(new Vector3(rNum, iNum, cNum));
    }
  }, [rNum, iNum, cNum, setManeuverRIC]);

  const deltaVMagnitude = parseFloat(new Vector3(rNum, iNum, cNum).length().toFixed(2));

  const submitManeuver = () => {
    if (name === '') return;
    if (activeSV && currentOrbit?.id) {
      const newManeuver: Maneuver = {
        name,
        radialComponent: rNum,
        inTrackComponent: iNum,
        crossTrackComponent: cNum,
        orbitId: currentOrbit.id,
        deltaVelocity: deltaVMagnitude,
        timestamp: new Date(currentTime),
        currentSpeed: 0,
      };
      if (editManeuver) {
        newManeuver.id = editManeuver.id;
        updateManeuver.mutate(newManeuver);
      } else {
        createManeuver.mutate(newManeuver);
      }
      handleClose();
    }
  };

  const checkPropagationData = useCallback(() => {
    const progress = PropagatedCacheManager.getPropagationProgress();
    setHasErrorPropagationData(progress < 100);
  }, [setHasErrorPropagationData]);

  useEffect(() => {
    checkPropagationData();
    PropagatedCacheManager.addEventListener('seeked', checkPropagationData);
    PropagatedCacheManager.addEventListener('clearpropagations', handleClose);
    return function cleanup() {
      PropagatedCacheManager.removeEventListener('seeked', checkPropagationData);
      PropagatedCacheManager.removeEventListener('clearpropagations', handleClose);
    };
  }, [checkPropagationData, handleClose]);

  return (
    <SpaceGlassContainerBase
      style={{
        display: 'grid',
        gridTemplateRows: 'min-content 1fr',
        overflow: 'hidden',
        maxHeight: 'calc(100vh - 400px)',
        width: '350px',
        borderBottomRightRadius: 0,
        borderTopRightRadius: 0,
      }}
    >
      <PanelHeader
        draggable={false}
        icon={
          <SvgIcon fontSize="large">
            <ManeuverIcon fill="#F1592B" />
          </SvgIcon>
        }
        title="Maneuver Designer"
        rightSide={
          <Grid
            display="flex"
            p={1}
            container
          >
            <CloseRounded
              cursor="pointer"
              onClick={handleClose}
            />
          </Grid>
        }
      />
      <ScrollableContainer>
        <Grid
          container
          overflow="auto"
        >
          <Grid
            item
            p={2}
            xs={12}
          >
            <InputNameEditor
              name={name ?? null}
              setName={setName}
              label="Maneuver Name"
              placeholder="Maneuver Name"
              saveName={() => void 0}
            />
          </Grid>
        </Grid>

        <Grid
          item
          px={2}
          pb={1}
        >
          {hasErrorEarth && (
            <Typography
              color="error"
              marginBottom={2}
            >
              {CONTENT.ERRORS.ORBIT.EARTH_INTERSECT}
            </Typography>
          )}

          {hasErrorEscape && (
            <Typography
              color="error"
              marginBottom={2}
            >
              {CONTENT.ERRORS.ORBIT.ESCAPE_VELOCITY}
            </Typography>
          )}

          {hasErrorPropagationData && (
            <Typography
              color="error"
              marginBottom={2}
            >
              {CONTENT.STATUS.ORBIT.LOADING_PROPAGATION}
            </Typography>
          )}

          <TextField
            disabled
            fullWidth
            size="small"
            type="text"
            variant="standard"
            placeholder="0.00"
            label="DeltaV Magnitude"
            value={Number.isNaN(deltaVMagnitude) ? '0' : `${deltaVMagnitude}`}
            InputProps={{
              endAdornment: (
                <InputAdornment
                  disableTypography
                  position="end"
                >
                  km/s
                </InputAdornment>
              ),
            }}
          />
        </Grid>

        <Grid px={1}>
          {[
            { propertyName: 'R: Radial', value: radialComponent, setValue: setRadialComponent },
            { propertyName: 'I: In-Track', value: inTrackComponent, setValue: setInTrackComponent },
            {
              propertyName: 'C: Cross-Track',
              value: crossTrackComponent,
              setValue: setCrossTrackComponent,
            },
          ].map(({ propertyName, value, setValue }, idx) => {
            return (
              <ObjectPropertySlider
                key={`${propertyName}`}
                propertyName={propertyName}
                value={value}
                textErrState={(fieldNum) =>
                  fieldNum < RIC_VELOCITY_RANGE_MIN || fieldNum > RIC_VELOCITY_RANGE_MAX
                }
                textFieldUnits="km/s"
                textFieldProps={{
                  disabled: hasErrorPropagationData,
                  helperText: `Number ranging from ${RIC_VELOCITY_RANGE_MIN} to ${RIC_VELOCITY_RANGE_MAX}`,
                }}
                sliderProps={{
                  disabled: hasErrorPropagationData,
                  min: RIC_VELOCITY_RANGE_MIN,
                  max: RIC_VELOCITY_RANGE_MAX,
                  marks: [
                    {
                      value: RIC_VELOCITY_RANGE_MIN,
                      label: `${RIC_VELOCITY_RANGE_MIN}`,
                    },
                    { value: 0, label: '0' },
                    {
                      value: RIC_VELOCITY_RANGE_MAX,
                      label: `${RIC_VELOCITY_RANGE_MAX}`,
                    },
                  ],
                }}
                hasError={hasErrorEarth || hasErrorEscape}
                onChange={setValue}
              />
            );
          })}
        </Grid>

        <Grid
          container
          direction="column"
          alignItems="center"
        >
          <Button
            sx={{ m: 2 }}
            variant="contained"
            aria-label="Add Maneuver"
            startIcon={<ContentCopyRounded />}
            disabled={hasErrorPropagationData}
            onClick={submitManeuver}
          >
            {'APPLY DELTA-V >>'}
          </Button>
        </Grid>
      </ScrollableContainer>
    </SpaceGlassContainerBase>
  );
};
