import { Avatar as AvatarMUI, Box } from '@mui/material';
import theme from 'src/pages/App/Theme';

export const AVATAR_SIZE = 64;

interface AvatarProps {
  children?: React.ReactNode;
  selected?: boolean;
  src?: string;
}

export function Avatar({ children, selected = false, src }: AvatarProps) {
  const borderColor = selected ? theme.palette.primary.main : theme.palette.text.primary;

  return (
    <Box
      component="div"
      sx={{
        border: `3px solid ${borderColor}`,
        borderRadius: '50%',
        padding: '6px',
      }}
    >
      <AvatarMUI
        src={src}
        sx={{
          color: theme.palette.text.primary,
          bgcolor: '#00000000',
          width: AVATAR_SIZE,
          height: AVATAR_SIZE,
        }}
      >
        {children}
      </AvatarMUI>
    </Box>
  );
}
