import { earthAngularVelocity } from 'src/constants';
import { getTimeAsUTC, millisInUTC } from 'src/utilities/DateTimeUtils';

const referenceTime = getTimeAsUTC('2000-01-01 17:17:28').toMillis();

function getEarthRotation(currentTime: number) {
  // Assuming input is local time, converting to utc
  const currentTimeToUTC = millisInUTC(currentTime);
  const difference = currentTimeToUTC - referenceTime;
  return difference * earthAngularVelocity;
}

export default getEarthRotation;
