import {
  ClearRounded,
  DoneRounded,
  FileOpenRounded,
  PriorityHighRounded,
  UploadFileRounded,
} from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import {
  Button,
  CircularProgress,
  DialogContent,
  Grid,
  IconButton,
  TextField,
  Typography,
} from '@mui/material';
import { Dispatch, SetStateAction, useCallback, useRef, useState } from 'react';
import { EPHEM_MAX_FILE_SIZE, EPHEM_MAX_FILE_SIZE_MB } from 'src/constants';
import { useUploadEphemMutation } from 'src/hooks/EphemerisHooks';
import { EphemerisUploadResponse } from 'src/types';
import { isFileList } from 'src/utilities/TypeUtils';
import theme from '../App/Theme';
import {
  EphemTLETextInputStyles,
  EphemFileBorder,
  EphemUploadBorderStyle,
} from './ModalEphemTLEPage.styled';

function readFileAsText(file: File): Promise<string> {
  // Always return a Promise
  return new Promise((resolve, reject) => {
    let content = '';
    const reader = new FileReader();
    // Wait till complete
    reader.onloadend = function (e: any) {
      content = e.target.result;
      resolve(content);
    };
    // Make sure to handle error states
    reader.onerror = function (e: any) {
      reject(e);
    };
    reader.readAsText(file);
  });
}

interface IEphemModalDisplay {
  ephemInputState: [string, Dispatch<SetStateAction<string>>];
  ephemFileState: [FileList | null, Dispatch<SetStateAction<FileList | null>>];
  success: boolean;
  ephemResponseState: [
    EphemerisUploadResponse | null,
    Dispatch<SetStateAction<EphemerisUploadResponse | null>>,
  ];
}

export const ModalEphemDisplay = ({
  ephemInputState,
  ephemFileState,
  success,
  ephemResponseState,
}: IEphemModalDisplay) => {
  const [ephemPosVelInput, setEphemInput] = ephemInputState;
  const [ephemFile, setEphemFile] = ephemFileState;
  const [ephemResponse, setEphemResponse] = ephemResponseState;

  const fileRef = useRef<HTMLInputElement | null>(null);

  const handleDrop = useCallback(
    (event: React.DragEvent<HTMLDivElement>) => {
      event.preventDefault();
      if (ephemPosVelInput !== '') return;
      if (event.dataTransfer.files) {
        setEphemFile(event.dataTransfer.files);
      }
    },
    [setEphemFile, ephemPosVelInput],
  );

  const handleFileSelection = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      event.preventDefault();
      if (event.target.files) {
        setEphemFile(event.target.files);
      }
    },
    [setEphemFile],
  );

  const handleDragOver = useCallback((event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
  }, []);

  const [isLoading, setLoading] = useState(false);
  // tracks if there is an error with validation
  const [fileErr, setFileErr] = useState(false);
  // tracks if file size is greater than EPHEM_MAX_FILE_SIZE
  const [fileSizeErr, setFileSizeErr] = useState(false);
  // if Ephem upload succeeds, than this will be set
  const [uploadSuccess, setUploadSuccess] = useState(false);

  const uploadEphemMutation = useUploadEphemMutation();

  const onClickFileCancel = () => {
    setLoading(false);
    setEphemFile(null);
    setEphemResponse(null);
    setFileErr(false);
    setFileSizeErr(false);
    setUploadSuccess(false);
    if (fileRef?.current) fileRef.current.value = '';
  };

  const onEphemFileUpload = async () => {
    setFileErr(false);
    setFileSizeErr(false);

    if (!ephemFile?.[0]) return;

    if (ephemFile[0].size >= EPHEM_MAX_FILE_SIZE) {
      setFileSizeErr(true);
      return;
    }
    setLoading(true);

    try {
      const fileContent = await readFileAsText(ephemFile[0]);

      const uploadResponse = await uploadEphemMutation.mutateAsync({
        file: fileContent,
      });
      setEphemResponse(uploadResponse);
      setUploadSuccess(true);
    } catch (e: any) {
      setFileErr(true);
      setEphemResponse(null);
      console.error(e);
    }

    setLoading(false);
  };

  let fileBorderColor = {};
  if (fileErr || fileSizeErr) {
    fileBorderColor = {
      borderColor: theme.palette.error.main,
      '&:hover': { borderColor: theme.palette.error.main },
    };
  } else if (uploadSuccess) {
    fileBorderColor = {
      borderColor: theme.palette.success.main,
      '&:hover': { borderColor: theme.palette.success.main },
    };
  }

  return (
    <>
      <DialogContent>
        <Typography padding={1}>Paste your initial position and velocity below</Typography>
        <TextField
          disabled={isFileList(ephemFile)}
          color={ephemInputState[0] !== '' && success ? 'success' : 'primary'}
          error={ephemInputState[0] !== '' && !success}
          rows={3}
          fullWidth
          multiline
          sx={{ padding: 1 }}
          onChange={(event) => {
            setEphemInput(event.target.value);
          }}
          value={ephemPosVelInput}
          inputProps={EphemTLETextInputStyles}
          placeholder="Paste your initial position and velocity here"
        />
        <Grid
          display="grid"
          gridAutoFlow="row"
          padding={1}
        >
          <Grid
            display="grid"
            gridAutoFlow="column"
            justifyContent="start"
            pb={1}
          >
            <Typography>Upload File</Typography>

            {fileErr && (
              <Typography
                pl={1}
                color="error"
              >
                There was an error when uploading this Ephemeris file
              </Typography>
            )}

            {fileSizeErr && (
              <Typography
                pl={1}
                color="error"
              >
                Ephemeris file size must be less than {EPHEM_MAX_FILE_SIZE_MB} megabytes
              </Typography>
            )}
          </Grid>

          <input
            // Dont re-trigger input file selection if a file is already in state;
            //  clear button handles clearing state
            disabled={ephemPosVelInput !== '' || isFileList(ephemFile)}
            hidden
            multiple
            type="file"
            ref={fileRef}
            onChange={handleFileSelection}
          />

          <EphemUploadBorderStyle
            display="grid"
            gridAutoFlow="column"
            alignContent="center"
            gap={2}
            padding={5}
            width="100%"
            onDrop={handleDrop}
            onDragOver={handleDragOver}
            sx={fileBorderColor}
            onClick={() => {
              if (!isFileList(ephemFile)) {
                fileRef.current?.click();
              }
            }}
          >
            {isFileList(ephemFile) ? (
              <>
                <Grid
                  display="grid"
                  gridAutoFlow="column"
                  alignItems="center"
                  gap={2}
                >
                  <EphemFileBorder
                    display="grid"
                    gridAutoFlow="column"
                    alignItems="center"
                    padding={1}
                    pl={2}
                    border="solid 2px"
                    borderRadius={1}
                    sx={fileBorderColor}
                  >
                    {ephemFile[0].name}

                    <IconButton onClick={onClickFileCancel}>
                      <ClearRounded fontSize="small" />
                    </IconButton>
                  </EphemFileBorder>

                  {!ephemResponse && (
                    <>
                      <LoadingButton
                        variant="contained"
                        loading={isLoading}
                        onClick={onEphemFileUpload}
                        loadingPosition="end"
                        endIcon={<UploadFileRounded />}
                        loadingIndicator={
                          <CircularProgress
                            color="primary"
                            size={16}
                          />
                        }
                      >
                        Upload
                      </LoadingButton>

                      {(fileErr || fileSizeErr) && <PriorityHighRounded color="error" />}
                    </>
                  )}

                  {uploadSuccess && <DoneRounded color="success" />}
                </Grid>
              </>
            ) : (
              <Button
                disabled={ephemPosVelInput !== ''}
                variant="contained"
                component="label"
                style={{ textTransform: 'none' }}
                onClick={(e) => e.preventDefault()}
                endIcon={<FileOpenRounded />}
              >
                Browse
              </Button>
            )}
          </EphemUploadBorderStyle>
        </Grid>
      </DialogContent>
    </>
  );
};
