import { MaximizeRounded, MinimizeRounded } from '@mui/icons-material';
import { Toggle } from 'src/pages/App/components/Toggle';
import { SETTINGS_NAMES, useSettingsStore } from 'src/pages/Settings/store';

export function LayoutMinMax() {
  const name = SETTINGS_NAMES.LAYOUT_MINIFIED;

  const toggleSetting = useSettingsStore((state) => state.toggleSetting);

  const isLayoutMinified = useSettingsStore((state) => state.settings[name]);

  const stylesIcon = {
    border: '1px solid #fff',
    borderRadius: '4px',
    padding: '2px',
  };

  return (
    <Toggle
      checked={isLayoutMinified}
      onChange={() => toggleSetting(name)}
      offIcon={
        <MinimizeRounded
          color="primary"
          sx={stylesIcon}
        />
      }
      onIcon={
        <MaximizeRounded
          color="primary"
          sx={stylesIcon}
        />
      }
    />
  );
}
