import { muEarth } from 'src/constants';
import { Vector3 } from 'three';

/**
 * Calculate the RIC position and velocity vectors from a target and chaser position and velocity
 *
 * - default units on everything are in terms of km
 *
 * @param positionTarget
 * @param velocityTarget
 * @param positionChaser
 * @param velocityChaser
 * @param gravitationalParam
 * @returns
 */
export const getPositionAndVelocityChaserInTargetRIC = (
  positionTarget: Vector3,
  velocityTarget: Vector3,
  positionChaser: Vector3,
  velocityChaser: Vector3,
  gravitationalParam = muEarth,
): { position: Vector3; velocity: Vector3 } => {
  const positionDelta = new Vector3().subVectors(positionChaser, positionTarget);
  const velocityDelta = new Vector3().subVectors(velocityChaser, velocityTarget);

  const rUnit = positionTarget.clone().normalize();
  const cUnit = new Vector3().crossVectors(positionTarget, velocityTarget).normalize();
  const iUnit = new Vector3().crossVectors(cUnit, rUnit);

  // need to calculate the angularVelocity of the target object to remove from the relative velocity
  const energy = 0.5 * velocityTarget.length() ** 2 - gravitationalParam / positionTarget.length();
  const targetSMA = -gravitationalParam / (2 * energy);
  const angularVelocityTarget = Math.sqrt(gravitationalParam / targetSMA ** 3);

  return {
    position: new Vector3(
      positionDelta.clone().dot(rUnit),
      positionDelta.clone().dot(iUnit),
      positionDelta.clone().dot(cUnit),
    ),
    velocity: new Vector3(
      velocityDelta.clone().dot(rUnit) + angularVelocityTarget * positionDelta.clone().dot(iUnit),
      velocityDelta.clone().dot(iUnit) - angularVelocityTarget * positionDelta.clone().dot(rUnit),
      velocityDelta.clone().dot(cUnit),
    ),
  };
};
