import { grey } from '@mui/material/colors';
import { IconButton, styled } from '@mui/material';

export const MenuBarIconButton = styled(IconButton)`
  color: ${grey[700]};
`;

export const StyledLink = styled('a')`
  color: white;
  text-decoration: none;
`;
