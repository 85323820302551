import { Client, StompConfig, StompSubscription } from '@stomp/stompjs';
import { useSnackbar } from 'notistack';
import { useCallback, useEffect, useState } from 'react';
import { WEBSOCKET_HEARTBEAT_INTERVAL_INCOMING_OUTGOING_MS } from 'src/constants';
import { DismissErrorNotification } from 'src/threejs/hooks/useNotifications';
import { useEffectOnce } from 'usehooks-ts';

interface ObjectType {
  [key: string]: any;
}

export function useStomp(
  config: StompConfig,
  subscriptions: {
    destination: string;
    onSubscribeCallback: ({ body }: { body: string }) => void;
    onConnectCallback: () => void;
  }[],
  maxRetryAttempts = 5,
) {
  const [stompClient, setStompClient] = useState<Client | null>(null);
  const [retryAttempts, setRetryAttempts] = useState(0);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  // when the subscription is created, store it
  const [managedSubscriptions, setManagedSubscriptions] = useState<
    Record<string, StompSubscription>
  >({});
  // subscribed to all supplied subscriptions
  const [isSubscribed, setSubscribedStatus] = useState(false);

  const handleMaxReconnectionError = useCallback(
    (reconnect: () => void) => {
      const message =
        'Exceeded max reconnection attempts. If this problem persists, please contact us at lab-feedback@slingshotaerospace.com';
      enqueueSnackbar(message, {
        action: (key) =>
          DismissErrorNotification(key, () => {
            closeSnackbar();
            reconnect();
          }),
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'center',
        },
        persist: true,
        preventDuplicate: true,
        variant: 'error',
      });
    },
    [enqueueSnackbar, closeSnackbar],
  );

  useEffect(() => {
    if (Object.keys(managedSubscriptions).length === subscriptions.length) {
      setSubscribedStatus(true);
    } else {
      setSubscribedStatus(false);
    }
  }, [managedSubscriptions, subscriptions.length]);

  const connect = useCallback(() => {
    setManagedSubscriptions({});

    const client = new Client({
      ...config,
      reconnectDelay: 0,
      heartbeatIncoming: WEBSOCKET_HEARTBEAT_INTERVAL_INCOMING_OUTGOING_MS,
      heartbeatOutgoing: WEBSOCKET_HEARTBEAT_INTERVAL_INCOMING_OUTGOING_MS,
    });
    setStompClient(client);

    if (client) {
      client.onConnect = (_frame) => {
        setRetryAttempts(0); // Reset retry attempts on successful connection

        subscriptions.forEach(({ destination, onSubscribeCallback, onConnectCallback }) => {
          onConnectCallback(); // when connecting or reconnecting
          const subscription = client.subscribe(destination, (message) => {
            onSubscribeCallback(message); // called only when a new message is received for this subscription
          });
          setManagedSubscriptions({ ...managedSubscriptions, [destination]: subscription });
        });
      };

      client.onStompError = (frame) => {
        console.warn(`[STOMP Error] Broker reported error: ${frame.headers['message']}`);

        const isActive = document.visibilityState === 'visible';
        if (isActive && retryAttempts < maxRetryAttempts) {
          // Exponential backoff to avoid avoid flooding the server with requests.
          setTimeout(connect, 2 ** retryAttempts * 1000);
          setRetryAttempts(retryAttempts + 1);
        } else if (retryAttempts >= maxRetryAttempts) {
          console.error(`Failed to reconnect after ${maxRetryAttempts} attempts.`);
          setManagedSubscriptions({});
          handleMaxReconnectionError(connect);
        }
        if (!isActive) {
          // disconnect when browser tab is not active if the connection closes
          client.deactivate();
        }
      };

      client.onWebSocketClose = (event) => {
        const isTabActive = document.visibilityState === 'visible';
        if (isTabActive && !event.wasClean && retryAttempts < maxRetryAttempts) {
          setRetryAttempts(retryAttempts + 1);
          client?.activate(); // attempt to reconnect
        } else if (retryAttempts >= maxRetryAttempts) {
          client?.deactivate();
          console.warn('WebSocket closed. Exceeded max retry attempts.');

          handleMaxReconnectionError(connect);
        }
      };

      client.onWebSocketError = (event) => {
        console.warn('[WebSocket Error]: ', event);
      };
    }
    client?.activate();
  }, [
    config,
    maxRetryAttempts,
    retryAttempts,
    handleMaxReconnectionError,
    managedSubscriptions,
    subscriptions,
  ]);

  // Heartbeat functionality in STOMP helps in detecting such situations as it expects regular incoming pings.
  // If it doesn't receive a ping for a certain interval, it realizes the connection may be broken.
  // However, this will only help for detecting incoming connection issues.
  // For outgoing connection issues, check the connection state before sending a message.
  // If the connection is not active, attempt to reconnect
  const send = useCallback(
    (path: string, body: ObjectType, headers: ObjectType) => {
      const isTabActive = document.visibilityState === 'visible';
      if (!stompClient?.connected && isTabActive) {
        connect();
        return;
      }
      stompClient?.publish({
        destination: path,
        headers,
        body: JSON.stringify(body),
      });
    },
    [stompClient, connect],
  );

  useEffect(() => {
    const handleVisibilityChange = () => {
      const isActive = document.visibilityState === 'visible';
      // attempt reconnection when tab is active
      if (isActive && !stompClient?.connected) {
        console.log('attemping STOMP client reconnection');
        connect();
      }
    };
    document.addEventListener('visibilitychange', handleVisibilityChange);
    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, [connect, stompClient?.connected]);

  useEffectOnce(() => {
    connect();
    return () => {
      Object.values(managedSubscriptions).forEach((subscription) => subscription.unsubscribe());
      stompClient?.deactivate();
    };
  });

  return {
    send,
    isSubscribed,
  };
}
