import { Text } from '@react-three/drei';
import { createPortal, useFrame } from '@react-three/fiber';
import { Suspense, useEffect, useMemo, useRef, useState } from 'react';
import {
  LABEL_SIZE,
  ORBIT_OBJECT_LABEL_TEXT_POS_BOTTOM_SCREEN,
  ORBIT_OBJECT_LABEL_TEXT_POS_LEFT_SCREEN,
  ORBIT_OBJECT_LABEL_TEXT_POS_RIGHT_SCREEN,
  ORBIT_OBJECT_LABEL_TEXT_POS_TOP_SCREEN,
} from 'src/constants';
import useViewportStore, {
  useViewportId,
  useViewportReferenceFrame,
} from 'src/threejs/components/ViewportManager/store';
import { TextLabelXAnchorPos, TextLabelYAnchorPos } from 'src/threejs/types';
import { shortenStringNextSpace } from 'src/utilities/StringUtils';
import { Group, Quaternion, Vector3 } from 'three';

type TextProps = Partial<React.ComponentProps<typeof Text>>;
type GroupProps = React.ComponentProps<'group'>;

interface ObjectLabelProps {
  name?: string;
  labelRef: Group | null;
  setLabelRef: (group: Group) => void;
  getLabelContent?: () => string;
  textProps: TextProps;
  groupProps: GroupProps;
}

export const ObjectLabel = ({
  name,
  labelRef,
  setLabelRef,
  getLabelContent,
  textProps,
  groupProps,
}: ObjectLabelProps) => {
  const viewportId = useViewportId();
  const { cameraRIC, cameraECEF, baseSceneMesh } =
    useViewportStore.getState().viewports[viewportId];

  const { isECEF, isRIC, isECI } = useViewportReferenceFrame();

  const labelXPosRef = useRef<TextLabelXAnchorPos>(ORBIT_OBJECT_LABEL_TEXT_POS_LEFT_SCREEN);
  const labelYPosRef = useRef<TextLabelYAnchorPos>(ORBIT_OBJECT_LABEL_TEXT_POS_TOP_SCREEN);

  const rightHalfLabelPos = useMemo(
    () =>
      isRIC ? ORBIT_OBJECT_LABEL_TEXT_POS_RIGHT_SCREEN : ORBIT_OBJECT_LABEL_TEXT_POS_LEFT_SCREEN,
    [isRIC],
  );
  const leftHalfLabelPos = useMemo(
    () =>
      isRIC ? ORBIT_OBJECT_LABEL_TEXT_POS_LEFT_SCREEN : ORBIT_OBJECT_LABEL_TEXT_POS_RIGHT_SCREEN,
    [isRIC],
  );

  const topHalfLabelPos = useMemo(
    () =>
      isRIC ? ORBIT_OBJECT_LABEL_TEXT_POS_TOP_SCREEN : ORBIT_OBJECT_LABEL_TEXT_POS_BOTTOM_SCREEN,
    [isRIC],
  );
  const bottomHalfLabelPos = useMemo(
    () =>
      isRIC ? ORBIT_OBJECT_LABEL_TEXT_POS_BOTTOM_SCREEN : ORBIT_OBJECT_LABEL_TEXT_POS_TOP_SCREEN,
    [isRIC],
  );

  const [xPos, setXPos] = useState<TextLabelXAnchorPos>(ORBIT_OBJECT_LABEL_TEXT_POS_LEFT_SCREEN);
  const [yPos, setYPos] = useState<TextLabelYAnchorPos>(ORBIT_OBJECT_LABEL_TEXT_POS_TOP_SCREEN);

  const [orbitObjectLabelFontSize, setOrbitObjectLabelFontSize] = useState(
    isRIC ? LABEL_SIZE.RIC_ACTIVE : LABEL_SIZE.DEFAULT,
  );

  useFrame(() => {
    if (isRIC && cameraRIC && labelRef) {
      //for RIC prevent the label size from scaling to camera distance
      const cameraWorldPosition = cameraRIC.getWorldPosition(new Vector3());

      const labelWorldPosition = labelRef.getWorldPosition(new Vector3());

      const labelDistanceFromCamera = cameraWorldPosition.sub(labelWorldPosition).length();

      const labelSize = LABEL_SIZE.RIC_ACTIVE * labelDistanceFromCamera;

      if (orbitObjectLabelFontSize !== labelSize) setOrbitObjectLabelFontSize(labelSize);
    }
  });

  // if going from RIC to ECI view, reset font size of label
  useEffect(() => {
    if (isECI) {
      setOrbitObjectLabelFontSize(LABEL_SIZE.DEFAULT);
    }
  }, [isECI]);

  useFrame(({ camera: cameraECI }) => {
    if (labelRef) {
      const clonedLabelPosition = labelRef.position.clone();

      // Make text label always face the camera associated with the current viewport reference frame type
      let quaternion = new Quaternion();
      if (isECI) {
        quaternion = cameraECI.quaternion;
        cameraECI.worldToLocal(clonedLabelPosition);
      } else if (isECEF && cameraECEF) {
        cameraECEF.getWorldQuaternion(quaternion);
        cameraECI.worldToLocal(clonedLabelPosition);
      } else if (isRIC && cameraRIC) {
        cameraRIC.getWorldQuaternion(quaternion);
        cameraRIC.localToWorld(clonedLabelPosition);
      }
      labelRef.quaternion.copy(quaternion);

      if (clonedLabelPosition.x > 0) {
        // object is on right half of screen
        labelXPosRef.current = rightHalfLabelPos;
      } else {
        // otherwise object is on left half of screen
        labelXPosRef.current = leftHalfLabelPos;
      }

      if (clonedLabelPosition.y > 0) {
        // object is on top half of screen
        labelYPosRef.current = topHalfLabelPos;
      } else if (clonedLabelPosition.y < 0) {
        // otherwise object is on bottom half of screen
        labelYPosRef.current = bottomHalfLabelPos;
      }

      // only set state when anchor needs to change rather than every frame
      if (xPos !== labelXPosRef.current) {
        setXPos(labelXPosRef.current);
      }
      if (yPos !== labelYPosRef.current) {
        setYPos(labelYPosRef.current);
      }
    }
  });

  // drei component missing text prop for setting text
  type DreiTextProps = Text & { text: string };
  const [txtRef, setText] = useState<DreiTextProps | null>(null);
  useFrame(() => {
    if (getLabelContent && txtRef) {
      txtRef.text = getLabelContent();
    }
  });

  return baseSceneMesh
    ? createPortal(
        <group
          {...groupProps}
          ref={setLabelRef}
        >
          <Suspense fallback={null}>
            <Text
              ref={setText}
              maxWidth={300}
              anchorX={xPos}
              anchorY={yPos}
              whiteSpace="overflowWrap"
              overflowWrap="break-word"
              fontSize={textProps.fontSize || orbitObjectLabelFontSize}
              {...textProps}
            >
              {name && shortenStringNextSpace(name)}
            </Text>
          </Suspense>
        </group>,
        baseSceneMesh,
      )
    : null;
};
